










































































import { defineComponent, computed, ref, PropType, set, watch } from "@vue/composition-api";
import { pascalToSentenceCase } from "@/utils/text";
import { generateGUID } from "@/utils/guid";
import { computeSources as sources } from "../../ts/compute-sources";
import { WizardContext } from "../../ts/types/WizardContext";
import { default as PropertyDrawer } from "../property-drawer.vue";
import { autoBindProps } from "../../ts/utils/autoBindProps";
import { useTranslate } from "@lang";

type Dictionary<T> = { [k:string]:T }; 

const getSourceArgs = (source:any) => {
	const params:Dictionary<any>|null = source ? source.parameters : null;
	if(!params){ return; }
	const args:any = { };
	Object.keys(params).forEach(k => {
		const p:any = params[k];

		args[k] = p.default !== undefined ? JSON.parse(JSON.stringify(p.default)) : null;
	});
	return args;
};

export default defineComponent({
	emits: [],
	props: {
		context: {
			type: Object as PropType<WizardContext>,
			required: true,
		},
	},
	components: {
		PropertyDrawer
	},
	setup(props, context) {

		const computeSources = computed(() => {
			return sources
			.filter((s:any) => {
				if(s.isUsable && typeof(s.isUsable) === "function"){
					return Boolean(s.isUsable(props.context));
				}

				if(s.isUsable && typeof(s.isUsable) === "boolean"){
					return Boolean(s.isUsable);
				}
				return true;
			});
		});
		

		const index = ref(computeSources.value.findIndex(ds => ds === props.context.dataSource));

		const drawers = ref<any>({});


		const {
			currentLang,
			translate,
		} = useTranslate();
		

		watch(index, (v) => {
			set(props.context, "dataSource", computeSources.value[v]);
			set(props.context, "dataArgs", getSourceArgs(computeSources.value[v]));
			set(props.context, "dataBindings", autoBindProps(props.context));
		});

		const translateFromMap = (m:string|Record<string,string>) => {
			if(typeof(m) === "string"){
				return m;
			}
			return m[currentLang.value] || "?";
		};

		const getItemClass = (name: string) => {
			return {
				"selected":selectedType.value ? selectedType.value.name === name : false
			};
		};

		const selectType = (name:string) => {

			if(selectedType.value){
				if(selectedType.value.name === name){
					return;
				}
			}

			const i = computeSources.value.findIndex(s => s.name === name);
			if(i < 0){ return; }
			index.value = i;
			set(props.context, "dataArgs", getSourceArgs(selectedType.value));
			set(props.context, "dataBindings", autoBindProps(props.context));
		};

		const title = computed(() => {
			return selectedType.value ? selectedType.value.name : "";
		});

		const description = computed(() => {
			const description = selectedType.value?.description || null;
			if(!description){ return ""; }
			if(typeof(description) === "object"){
				return description[currentLang.value];
			}
			return description
		});

		const outputs = computed(() => {
			const output:Dictionary<any> = selectedType.value ? selectedType.value.output : {};

			return Object.keys(output)
			.map(k => ({
				name:k,
				type:output[k].type
			}));
		});

		const parameters = computed(() => {
			const params:Dictionary<any>|null = selectedType.value ? selectedType.value.parameters : null;
			if(!params){ return []; }
			return Object.keys(params)
			.map(k => {
				const p = params[k];
				let drawer:any = getPropertyDrawer(p);
				
				
				const title = translateFromMap(p.title);


				return {
					title,
					name:k,
					type:p.type,
					key:generateGUID()
				}
			});
		});

		const dataArgs = computed(() => {
			return props.context.dataArgs;
		})

		const selectedType = computed(() => {
			return props.context.dataSource;
		});

		const refreshDrawers = () => {
			const ob:any = {};
			const params:Dictionary<any> = selectedType.value ? selectedType.value.parameters : {};
			Object.keys(params)
			.forEach(k => {
				ob[k] = getPropertyDrawer(params[k]);
			});
			drawers.value = ob;
		};

		const getPropertyDrawer = (p:any) => {
			if(p.drawer){
				if(typeof(p.drawer) === "object"){
					return p.drawer;
				}
				if(typeof(p.drawer) === "function"){
					return p.drawer(props.context);
				}
			}
			return null;
		};

		const emitState = () => {

			let s = true;
			const ks = Object.keys(dataArgs.value);

			for(var i = 0; i < ks.length; i++){

				const v = dataArgs.value[ks[i]]
				if(v === undefined || v === null){
					s = false;
					break;
				}
			}
			context.emit("state", s);
		};


		const getTypeLabel = (t:string) => {
			return translate(`widgets.label.${t.charAt(0).toLowerCase() + t.slice(1)}`);
		};
		
		watch(dataArgs, v => {
			refreshDrawers();
			emitState();
		}, { deep: true });

		context.emit("state", index.value > -1);

		refreshDrawers();

		return {
			title,
			description,
			index,
			parameters,
			outputs,
			selectedType,
			computeSources,
			getItemClass,
			selectType,
			pascalToSentenceCase,
			drawers,
			generateGUID,
			translate,
			getTypeLabel,
		};
	},
});
