// project
import { Graph } from "@/psychlab/types/graph";

export const getLeftMostNode = (data:Graph) => {
	let nks = Object.keys(data.nodes);
	if(nks.length === 0){ return null; }
	let cn = nks[0];
	Object.keys(data.nodes).map((nk) => {
		if(data.nodes[nk].x < data.nodes[cn].x){
			cn = nk;
		}
	});
	return cn;
};

// find all nodes the given node can exit to
export const getNodeExits = (nodeId:string, graph:Graph) => {
	let result:string[] = [];
	let node = graph.nodes[nodeId];
	for(var key in graph.transitions){
		let parsed = parseTransitionPath(graph.transitions[key].path);
		if(parsed.fromNode === nodeId && parsed.fromPort === "exit"){
			result.push(parsed.toNode);
		}
	}

	if(node.type !== "Select" && result.length > 1){
		result = [ result[0] ] // first connection
	}

	return result;
};

const parseTransitionPath = (transitionPath:string) => {
	let nodePaths = transitionPath.split(",");
	let node1 = nodePaths[0].split(".");
	let node2 = nodePaths[1].split(".");
	return {
		fromNode: node1[0],
		fromPort: node1[1],
		toNode: node2[0],
		toPort: node2[1],
	};
};