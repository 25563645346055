import { defineComponent, PropType } from "@vue/composition-api";
import { RawLocation } from "vue-router";

export const RouteButton = defineComponent({
	props:{
		route:{
			type:Object as PropType<RawLocation>,
			required:true,
		},
	},
	render(){
		return (
			<router-link
			to={this.route}
			style={linkStyle}
			/>
		);
	}
});

const linkStyle = `
	all:unset;
	position:absolute;
	width:100%;
	height:100%;
	background:transparent;
	cursor:pointer;
`;