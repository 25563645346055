import { ref, watch } from "@vue/composition-api";

const load = (k:string, d:boolean):boolean => {
	const v = localStorage.getItem(k);
	return v === "1" ? true : false;
};

const save = (k:string, v:boolean) => {
	localStorage.setItem(k, v ? '1' : '0');
};

export const usePersistentBool = (key:string, defaultValue?:boolean) => {
	const lastTouch = ref(0);
	const val = ref(load(key, defaultValue||false));
	const persistDelay = 100;

	const touch = () => {
		lastTouch.value = Date.now();
		setTimeout(() => {
			if((Date.now() - lastTouch.value) < persistDelay){ return; }
			save(key, val.value);
		}, persistDelay);
	};

	watch(val, touch);

	return {
		val
	};
};