























import { defineComponent, computed } from "@vue/composition-api";
import { matchWildcard } from "@utils/regex";
import { useTranslate } from "@lang";

const options = [
	{
		title: "label.home",
		route:"home",
		icon:"mdi.home",
		match:"/home*"
	},
	{
		title: "assets.label.assets",
		route:"assets",
		icon:"mdi.layers",
		match:"/assets*"
	},
	{
		title: "studies.label.studies",
		route:"studies",
		icon:"mdi.rocket",
		match:"/studies*"
	},
	{
		title: "tags.label.tags",
		route:"view-tags",
		icon:"mdi.tag-multiple",
		match:"/tags*"
	}
];

export default defineComponent({
	emits:[
		"close-menu"
	],
	setup(_, context){

		const { translate } = useTranslate();

		const navOptions = computed(() => options);

		const getRoute = (index:number) => {
			const option = navOptions.value[index];
			const q:any = {};

			[ "t" ].forEach(qk => {
				if(context.root.$route.query[qk]){
					q[qk] = context.root.$route.query[qk];
				}
			});

			return {
				name:option.route,
				query:q
			}
		};

		const isRoute = (option:any) => {
			return matchWildcard(context.root.$route.path, option.match);
		};
	
		const isExactRoute = (option:any) => {
			return option.route === context.root.$route.name;
		};

		const hideMenu = () => {
			context.emit("close-menu");
		};

		return {
			navOptions,
			isRoute,
			isExactRoute,
			hideMenu,
			getRoute,
			translate,
		};

	}
});
