

































// vendor
import { defineComponent, computed, ref, PropType } from "@vue/composition-api";

type RangeConfig = {
	min:number;
	max:number;
	step?:number;
};

export default defineComponent({

	emits:[ "input" ],
	props:{
		value:{
			type:Number
		},
		label:{
			type:String
		},
		config:{
			type:Object as PropType<RangeConfig>,
			required:true
		}
	},
	setup(props, context){
		
		const min = computed(() => props.config.min || 0);
		const max = computed(() => props.config.max || 100);
		const step = computed(() => props.config.step || 1);

		const val = computed({
			get: () => props.value,
			set:v => context.emit("input", v)
		});

		return {
			min,
			max,
			step,
			val,
		};
	}
});

