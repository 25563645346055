






































































































































































import { defineComponent, ref, computed, PropType, watch, SetupContext } from "@vue/composition-api";
import { Asset } from "@/psychlab/types";
import { getAssetTypes } from "@/psychlab/meta";
import { useAssets } from "@/hooks/useAssets";
import { useAssetListDisplay } from "@/hooks/useAssetListDisplay";
import { useTags } from "@/hooks/useTags";
import * as AppModal from "@/AppModal";
import { useUserPrefs } from "@/hooks/useUserPrefs";
import { useTranslate } from "@lang";
import {
	AbsBox,
	Pagination,
	Divider,
	DateLabel,
	Heading,
	TipIcon,
	ContextButton,
} from "@ui";
import { default as Router } from "vue-router";

enum RouteNames {
	ViewAsset = "view-asset",
}

const AssetTypes = getAssetTypes().reduce<Record<string,any>>((r, t) => {
	r[t.name] = t;
	return r;
}, {});


const openAssetRoute = (aid:string, r:Router) => r.push({ name: RouteNames.ViewAsset, params:{ assetId: aid } });

const loadQuerySort = (ctx:SetupContext) => {
	const v = getQueryValue<string|null>("al_sort", ctx, null);
	if(!v){ return { desc:true, prop:"lastModified" }; }
	const [
		p1,
		p2,
	] = v.split("-");
	if(!p2){ return { desc:true, prop:p1 }};
	return { desc: true, prop:p2 };
};

const setQueryValue = (k:string, v:any, context:SetupContext) => {
	const q:any = { ...context.root.$route.query };
	if(v !== undefined && v !== null){
		q[k] = v;
	}
	else {
		delete q[k];
	}
	context.root.$router.push({ query: q})
	.catch(() => {});
};


const getQueryValue = <T>(k:string, context:SetupContext, def:T):T => {
	return ((context.root.$route.query[k] as any) as T) || def;
};

const createAsset = (type:string, context:SetupContext) => {
	const assetHooks = useAssets();
	const tagHooks = useTags();
	AppModal.createAsset({ type }, async (r) => {
		const { name, tags, openEditor } = r;
		const a = await assetHooks.createAsset(type, name);
		await Promise.all(tags.map(t => tagHooks.tagItem(t, a._id, "asset")));
		if(openEditor){
			context.root.$router.push({
				name:"asset.edit",
				params:{
					assetId:a._id,
				}
			})
		}
	});
};

export default defineComponent({
	props:{
		exclude:{
			type:Array as PropType<string[]>,
			default:() => []
		},
		include:{
			type:Array as PropType<string[]>,
			default:() => []
		},
		defaultTag:{
			type:String,
		},
		searchFilter:{
			type:String,
			default:"",
		},
		title:{
			type:String,
			default:"",
		},
		titleTip:{
			type:String,
			default:"",
		},
	},
	components:{
		Pagination,
		Divider,
		DateLabel,
		AbsBox,
		Heading,
		TipIcon,
		ContextButton,
	},
	setup(props, context){

		const router = context.root.$router;

		const loading = ref(false);
		const draggedItem = ref<string|null>(null);
		const displayFields = computed(() => tableFields);
		const activeTag = computed(() => props.defaultTag || null);

		const qs = loadQuerySort(context);

		const sortProp = ref(qs.prop);
		const sortDesc = ref(qs.desc);

		const { translate } = useTranslate();

		const sortQuery = computed(() => {
			return (sortDesc.value ? "-" : "") + sortProp.value;
		});

		watch([sortProp, sortDesc], () => {
			setQueryValue("al_sort", sortQuery.value, context);
		});
		
		const onSortChanged = (ctx:{ sortBy:string, sortDesc:boolean }) => {
			sortDesc.value = ctx.sortDesc;
			sortProp.value = ctx.sortBy;
		};

		const { hasTag } = useTags();

		const assetHooks = useAssets();

		const {
			pagination,
			openAssetContext,
			getAssetTags,
		} = useAssetListDisplay({
			activeTag,
			context,
			queryPrefix:"al_",
		});

		const { assetListLimit } = useUserPrefs();

		pagination.limit = assetListLimit.value;

		watch(() => pagination.limit, () => {
			assetListLimit.value = pagination.limit;
		})

		const filterAsset = (a:Asset) => {
			if(props.exclude.includes(a.type)){ return false; }
			if(!props.searchFilter || props.searchFilter.length < 2){ return true; }
			return a.name.toLowerCase().includes(props.searchFilter.toLowerCase());
		};

		const assets = computed(() => {
			const tagged = assetHooks.assets.value.filter(a => (props.defaultTag ? hasTag(props.defaultTag, a._id) : true));
			if(!props.exclude.length){ return tagged }
			return tagged.filter(filterAsset);
		});

		const displayTypes = computed(() => {
			if(props.include.length > 0){
				return props.include;
			}
			return Object.keys(AssetTypes).filter(t => !props.exclude.includes(t));
		});

		const getTypeColor = (t:string) => AssetTypes[t]?.color || "white";

		const openContext = (e:any, asset:Asset) => openAssetContext(e, asset._id);

		const openAsset = (a:Asset) => openAssetRoute(a._id, router);

		const addType = (type:string) => createAsset(type, context);

		const canAddType = (t:string) => !AssetTypes[t].disabled;

		const addAsset = (e:any) => {
			const options:any[] = displayTypes.value
			.filter(canAddType)
			.map(t => {
				return {
					name:translate(`assets.label.${t.toLowerCase()}`),
					icon:getTypeIcon(t),
					fn: ()=> {
						addType(t);
					}
				}
			});
			AppModal.context(e, options);
		};

		const getTypeIcon = (type:string) => AssetTypes[type]?.icon || "alert-rhombus";

		return {
			assets,
			loading,
			displayFields,
			draggedItem,
			sortProp,
			sortDesc,
			pagination,
			openAsset,
			openContext,
			addAsset,
			getTypeIcon,
			getTypeColor,
			onSortChanged,
			getAssetTags,
			translate,
		};

	}

});

const tableFields:any[] = [
	{ key:"type", sortable:true, size:"xs", tdClass:"align-middle", },
	{ key:"name", sortable:true, },
	{ key:"lastModified", sortable:true, size:"md", },
	{ key:"tags", size:"md", },
	{ key:"controls", size:"xs", }
];

tableFields.forEach(f => {
	f.label = "";
	f.thClass = "noselect th-txt font-weight-light text-light";
	if(f.size){
		f.class = `col-${f.size}`;
	}
});



