import { FeatureFlags } from "./FeatureFlags";
import { ISingleFlagLoader } from "./types";
import { ConfigVarLoader } from "./loaders";
import { loadVar } from "./helpers";

export const loadFlags = ():string[] => {
	return Array.from(new Set([
		...loadSingles(),
		...loadFlagList(),
	]));
};

const loadFlagList = () => {
	const v = loadVar("FLAGS");
	if(typeof(v) !== "string"){ return []; }
	return v.split(" ").filter(v => v.length).join("").split(";").filter(v => v.length);
};

const loadSingles = () => singleLoaders.filter(l => l.active).map(l => l.flag);

// mostly used for legacy reasons
const singleLoaders:ISingleFlagLoader[] = [
	new ConfigVarLoader("CAN_DELETE_STUDIES", FeatureFlags.StudiesDeletable),
	new ConfigVarLoader("STUDIES_DELETABLE", FeatureFlags.StudiesDeletable),
	new ConfigVarLoader("CAN_REBUILD_SESSIONS", FeatureFlags.SessionsRebuildable),
	new ConfigVarLoader("SESSIONS_REBUILDABLE", FeatureFlags.StudiesDeletable),
];