









import { defineComponent } from "@vue/composition-api";
import { HTML } from "@psychlab/types/form";
import { RenderHTML } from "@form-ui";
import { getItemProps } from "./getItemProps";
import { useTranslate } from "@lang";
import { editText } from "@/AppModal";


export default defineComponent({
	props:{
		...getItemProps<HTML>()
	},
	components:{
		RenderHTML,
	},
	setup(props){

		const {} = useTranslate();
		
		const editContent = () => {
			editText({
				title:"HTML",
				variant:"light",
				value:props.data?.settings.content || "",
				size:"lg",
				rows:10,
				textarea:true,
			}, (v) => {
				if(!props.data){ return; }
				props.data.settings.content = v;
			})
		};

		return {
			editContent
		};
	}
});

