






















import { defineComponent, computed, ref, onBeforeUnmount } from "@vue/composition-api";
import { AbsBox } from "../../boxes";
import { Chrome as ChromePicker } from 'vue-color';

export default defineComponent({

	emits:[ "input" ],
	props:{
		value:{
			type:String,
			default:"#ffffff"
		},
	},
	components:{
		AbsBox,
		ChromePicker,
	},
	setup(props, ctx){

		const pickerGroup = ref<HTMLElement>();
		const showPicker = ref(false);
		
		const val = computed({
			get:() => props.value,
			set:(v:any) => {
				// console.log(v);
				ctx.emit("input", v.hex8)
			}
		});


		const hidePicker = () => {
			document.removeEventListener("click", onClickDocument);
			showPicker.value = false;
		};
		
		const togglePicker = () => {
			if(showPicker.value){
				hidePicker();
			}
			else {
				document.addEventListener("click", onClickDocument);
				showPicker.value = true;
			}
		};


		const onClickDocument = (e:Event) => {
			const el = pickerGroup.value;
			if(!el) { return; }
			const target = e.target;
			
			if(!el.contains(target as any)) {
				hidePicker();
			}
		};

		return {
			val,
			showPicker,
			togglePicker,
			pickerGroup,
		};
	}
});

