






































import { defineComponent, computed } from "@vue/composition-api";
import { useTranslate } from "@lang";
import { Icon, openContextMenu } from "@ui";

export default defineComponent({
	components:{
		Icon
	},
	setup(_, ctx){

		const { translate } = useTranslate();

		const email = computed(() => ctx.root.$store.getters["email"]);


		const toggle = (e:Event) => {

			openContextMenu(e, [
				{
					name:email.value,
					icon:"mdi.account",
					disabled:true,
					fn(){}
				},
				{
					name:translate("nav.action.logOut"),
					order:1,
					icon:"mdi.logout",
					fn(){

						ctx.root.$router.push({
							name:"Logout"
						})


					}
				}


			])

		};

		return {
			email,
			toggle,
			translate
		};
	}
})
