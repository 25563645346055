// vendor
import { ref, Ref, ComputedRef, watch } from "@vue/composition-api";


type RT = Ref<HTMLElement|undefined>|ComputedRef<HTMLElement|undefined>;

export const useElementResize = (el:RT) => {

	const width = ref(0);
	const height = ref(0);

	const tick = ref<any>(null);

	const refreshSize = () => {
		if(!el.value){ return; }
		width.value = el.value.offsetWidth;
		height.value = el.value.offsetHeight;
	};

	const init = () => {
		if(!el.value){ return; }
		window.addEventListener("resize", refreshSize, true);
		el.value.addEventListener("resize", refreshSize, true);

		tick.value = setInterval(() => {
			if(!el.value){ return; }
			if(width.value !== el.value.clientWidth){
				width.value = el.value.clientWidth
			}
		}, 100);
	};

	const dispose = () => {
		window.removeEventListener("resize", refreshSize);
		if(el.value){
			el.value.removeEventListener("resize", refreshSize);
		}

		if(tick.value){
			clearInterval(tick.value);
			tick.value = null;
		}
	};

	watch(el, refreshSize);
	
	refreshSize();

	return {
		width,
		height,
		dispose,
		init,
	}
};