import { PhraseDictionary } from "../types";
import { mergeDictionaries } from "../mergeDictionaries";

const message:PhraseDictionary = {
	"pageNotFound":{
		en:"Page not found",
		is:"Síða ekki fundin"
	}
};

export default mergeDictionaries("errors", { message });