import { defineComponent, computed, ref, watch } from "@vue/composition-api";
import * as SessionAPI from "@/services/api/sessions";
import { AbsBox, Icon } from "@ui";
import { default as Router } from "vue-router";
import { Gradient } from "@/errors/Backgrounds";
import { StudyInfo } from "@psychlab/types";
import * as StudyAPI from "@/services/api/studies";

enum ScreenType {
	NotFound = "not-found",
	SurveyVisited = "survey-visited",
	SurveyAuthenticationFailed = "survey-authentication-failed",
}

enum RouteNames {
	InfoScreen = "Error",
	OpenSession = "Run.Session"
}

const openScreen = (e:ScreenType, r:Router, q?:Record<string,string>) => {
	r.push({
		name:RouteNames.InfoScreen,
		params:{ slug:e, },
		query:q || {}
	})
};

const openSessionRoute = (id:string, l:string, store:any, router:Router) => {
	// store.commit("localization/setPreferredLanguage", l);
	router.push({
		name:RouteNames.OpenSession,
		params:{
			sessionId:id
		},
		query:{ l }
	});
};

export const VerifySurveyAuth = defineComponent({
	setup(_, ctx){

		const store = ctx.root.$store;
		const router = ctx.root.$router;
		const route = ctx.root.$route;

		const ready = ref(false);
		const loading = ref(true);
		const error = ref(false);

		const info = ref<StudyInfo>();

		const payload = computed(() => {
			let ctx = route.query["ctx"];
			if(typeof(ctx) !== "string"){ return null; }
			return decodePayload(ctx);
		});

		const studyID = computed(() => route.params["studyId"]);

		const used = computed(() => Boolean(payload.value?.used));
		const auth = computed(() => payload.value?.auth);

		const preferredLanguage = computed(() => {

			if(info.value){
				return info.value.language;
			}

			const l = route.query["l"];
			if(!l || typeof(l) !== "string"){
				return "en";
			}
			return l;
		});

		const canConfirm = computed(() => !loading.value && ready.value);

		const openSession = (sid:string) => {
			openSessionRoute(sid, preferredLanguage.value, store, router);
		};

		const saveAuth = (auth:PayloadAuth) => {
			const { study, session, token } = auth;
			SessionAPI.saveSessionToken(study, session, token);
		};

		const init = async () => {

			try {
				info.value = await StudyAPI.getStudyInfo(studyID.value);
			}
			catch{}


			if(used.value){
				return openScreen(ScreenType.SurveyVisited, router, {
					l:preferredLanguage.value,
				});
			}

			loading.value = true;
			try {
				if(!auth.value){ throw new Error("Auth not set"); }
				saveAuth(auth.value);
				ready.value = true;
			}
			catch {
				error.value = true;
			}
			loading.value = false;

		};

		watch(error, v => {
			if(!v){ return; }
			openScreen(ScreenType.SurveyAuthenticationFailed, router, {
				survey:studyID.value,
				l:preferredLanguage.value,
			});
		});

		watch(canConfirm, v => {
			if(!v || !auth.value){ return; }
			openSession(auth.value.session);
		});

		init();

		return {
			loading,
		};
	},
	render(){
		return <Loading/>;
	}
});

type PayloadAuth = {
	study:string;
	session:string;
	token:string;
};

type Payload = {
	used?:boolean;
	auth?:PayloadAuth;
};

const decodePayload = (s:string):Payload|null => {
	try {
		s = decodeURIComponent(s);
		const { used, study, session, token } = JSON.parse(Buffer.from(s, "base64").toString());

		if(used){ return { used }; }
		if(!study || !session || !token) { return null; }

		return {
			auth:{ study, session, token }
		};
	}
	catch{}
	return null;
};

export const Loading = defineComponent({
	render(){
		return (
			<AbsBox>
				<Gradient/>
				<AbsBox class="d-flex">
					<h1 class="m-auto text-light" >
						<Icon name="cog" spin style="font-size:6rem"/>
					</h1>
				</AbsBox>
			</AbsBox>
		);
	}
});