



















































































































// vendor
import { defineComponent, computed, ref, PropType, set } from "@vue/composition-api";
// project
import { GalleryItem } from "@/psychlab/types/gallery";
// import { EditText, IEditTextModal } from "@/components.generic/utility-modal";
import * as AppModal from "@/AppModal";
// local
import { default as ImgThumbnail } from "./img-thumbnail.vue";
import * as ImgUtils from "../ts/img-utils";
import { default as EditThumbnailModal, IEditThumbnailModal } from "./edit-thumbnail-modal.vue";
import { default as EditSourcesModal, IEditSourcesModal } from "./edit-sources-modal.vue";
import { default as SourceButton } from "./source-button.vue";
import { useTranslate } from "@lang";

export default defineComponent({
	emits:[
		"delete",
		"up",
		"down",
	],
	props:{
		panorama:{
			type:Boolean,
			default:false,
		},
		data:{
			type:Object as PropType<GalleryItem>,
			required:true,
		},
		index:{
			type:Number,
			required:true,
		},
		count:{
			type:Number,
			required:true,
		}
	},
	components:{
		// EditText,
		ImgThumbnail,
		EditThumbnailModal,
		EditSourcesModal,
		SourceButton,
	},
	setup(props, context){


		const {
			translate
		} = useTranslate();

		const loadingUrl = ref(false);

		const root = ref<HTMLElement>();
		// const editTextModal = ref<IEditTextModal>();
		const editSourcesModal = ref<IEditSourcesModal>();
		const editThumbnailModal = ref<IEditThumbnailModal>();

		const title = computed(() => {
			return props.data.label ? props.data.label : `(${translate("label.untitled").toLowerCase()})`;
		});

		const sources = computed(() => {
			// console.log(props.data);
			return props.data.sources || [];
		});

		const mobileSources = computed(() => {
			return props.data.mobileSources || [];
		});

		const setText = (title:string, v:string, fn:(nv:string) => void) => {
			AppModal.editText({
				title,
				value:v,
				validator(nv){
					return nv !== v;
				},
			}, fn);
		};
		
		const editSources = () => {
			if(!editSourcesModal.value){ return; }
			editSourcesModal.value.open({
				title:translate("assets.label.sources"),
				infoText:translate("assets.message.aboutGallerySources"),
				sources:sources.value,
			}, (r) => {
				set(props.data, "sources", r.sources);
			});
		};

		const editMobileSources = () => {
			if(!editSourcesModal.value){ return; }
			editSourcesModal.value.open({
				title:translate("assets.label.mobileSources"),
				infoText:translate("assets.message.aboutGalleryMobileSources"),
				sources:mobileSources.value,
			}, (r) => {
				set(props.data, "mobileSources", r.sources);
			});
		};

		const setName = () => {
			setText(translate("assets.action.setName"), props.data.label, v => props.data.label = v);
		};

		const setUrl = () => {

			if(!editThumbnailModal.value){ return; }

			const sources = props.data.sources ? props.data.sources : (props.data.url ? [ props.data.url] : []);

			editThumbnailModal.value.open({
				sources,
				thumbnail:props.data.thumbnail,
			}, (r) => {
				props.data.thumbnail = r.thumbnail;
			});
		};

		const move = (dir:number) => {
			context.emit(dir > 0 ? "down" : "up");
		};

		return {
			title,
			sources,
			mobileSources,
			// editTextModal,
			editSourcesModal,
			setName,
			setUrl,
			move,
			root,
			loadingUrl,
			editThumbnailModal,
			editSources,
			editMobileSources,
		};
	}
});

