


























































































import { defineComponent, computed, ref, PropType, onMounted, onUnmounted } from "@vue/composition-api";
import { PreviewAssetForm } from "@/components.assets/preview-asset-form";
import { PreviewAssetGallery } from "@/components.assets/preview-asset-gallery";
import { DisplayGraph } from "./ts/DisplayGraph";
import { default as RenderDisplayNode } from "./render-display-node.vue";
import { AbsBox, Icon } from "@ui";
import { useTranslate } from "@lang";	

type Dictionary<T> = { [k:string]:T }; 


const getVector2D = (ax:number, ay:number, bx:number, by:number) => {
	const v = {
		x:bx-ax,
		y:by-ay,
	};
	const l = Math.sqrt((v.x * v.x) + (v.y * v.y));
	v.x = v.x / l;
	v.y = v.y / l;
	return v;
};

const getDistance = (x1:number, y1:number, x2:number, y2:number) => {
	const x = (x2 - x1);
	const y = (y2 - y1);
	return Math.sqrt(x * x + y * y)
};

const getPathLine = (ax:number, ay:number, bx:number, by:number) => {
	return `M${ax} ${ay} L ${bx} ${by}`;
};

const getAngle = (x1:number, y1:number, x2:number, y2:number) => {
	const p1 = x1 * x2 + y1 * y2;
	const p2 = Math.sqrt(x1 * x1 + y1 * y1) * Math.sqrt(x2 * x2 + y2 * y2);
	const a = Math.acos(p1 / p2);
	const ad = a * (180 / Math.PI);
	return ad;
};

const config = {
	nodeRadius:30,
	edgeDistance:50
};

export default defineComponent({
	props:{
		data:{
			type:Object as PropType<DisplayGraph>,
			required:true,
		},
	},
	components:{
		AbsBox,
		Icon,
		RenderDisplayNode,
		PreviewAssetForm,
		// PreviewAssetCognitiveTest,
		PreviewAssetGallery,
	},
	setup(props){

		const canvas = ref<HTMLElement>();

		const {
			translate,
		} = useTranslate();


		const nestedPreview = ref<{
			type:string;
			data:any;
		}|null>(null)


		const canvasHeight = ref(0);
		const canvasWidth = ref(0);

		const nodeRadius = computed(() => config.nodeRadius);
		const edgeDistance = computed(() => config.edgeDistance);

		const graphOffset = computed(() => {
			const bounds = graphBounds.value;
			let x = ((canvasWidth.value * 0.5) - bounds.x * 0.5) + config.nodeRadius;
			const y = 50;

			const bl = canvasWidth.value - bounds.x;

			if(bl < 0){
				x += Math.abs(bl) * 0.5;
			}

			return {
				x,
				y
			};
		});

		const graphBounds = computed(() => {

			const x = (() => {
				const nc = props.data.nodes.length;
				if(nc === 0){ return 0; }
				const xs = props.data.nodes.map(n => n.x);

				const min = Math.min(...xs);
				const max = Math.max(...xs);

				const l = Math.abs(max - min) + 1;
				const w = l * (nodeRadius.value * 2);
				const p = edgeDistance.value * (l - 1);

				return w + p;
			})();

			const y = (() => {
				if(props.data.nodes.length === 0){ return 0; }
				const n = Math.max(...props.data.nodes.map(n => n.depth)) + 1;
				const h = n * (config.nodeRadius * 2);
				const p = (n - 1) * config.edgeDistance;
				return h + p;
			})();

			return { x, y };
		});

		const levelMax = computed(() => {
			const count:Dictionary<number> = {};
			props.data.nodes.forEach(n => {
				const lk = n.depth.toString();
				if(count[lk] === undefined){
					count[lk] = 0;
				}
				count[lk] = count[lk] + 1;
			});
			
			return Object.keys(count).reduce((hv, cv) => {
				return count[cv] > hv ? count[cv] : hv;
			},0)
		});

		const canvasEdges = computed(() => {
			const edges:any[] = [];
			canvasNodes.value.forEach(n => {
				const nedges = props.data.edges[n.id];
				nedges.forEach(ne => {
					const cn = canvasNodes.value.find(tn => tn.id === ne);
					if(!cn){ return; }

					const altPath = cn.owner !== n.id;

					const dir = getVector2D(n.x, n.y, cn.x, cn.y);

					const dist = getDistance(n.x, n.y, cn.x, cn.y) - nodeRadius.value;

					let angle = getAngle(dir.x, dir.y, 1,0) + 90;
					
					if(dir.x < 0){
						// angle += 90;
					}
					
					const end = {
						x: n.x + dir.x * dist,
						y: n.y + dir.y * dist,
						angle
					};


					edges.push({
						data:getPathLine(n.x, n.y, cn.x, cn.y),
						color:altPath ? 'white' : 'white',
						end
					});
				});
			});
			return edges;
		});

		const countDepth = (l:number) => {
			return props.data.nodes.reduce((t, c) => {
				return c.depth === l ? (t + 1) : t;
			}, 0);
		};

		const canvasNodes = computed(() => {
			const levelOffsets:Dictionary<number> = {};
			const ns = nodeRadius.value * 2;
			const lm = levelMax.value;
			return props.data.nodes.map(n => {
				
				const lk = n.depth.toString();
				if(levelOffsets[lk] === undefined){
					levelOffsets[lk] = 
					0;
				}
				const lc = countDepth(n.depth);
				const lo = (lm - lc) * 0.5;
				const xo = lo + levelOffsets[lk];
				const yo = n.depth;
				
				const x = (n.x * ns) + (edgeDistance.value * n.x);
				const y = (yo * ns) + (edgeDistance.value * yo);
				levelOffsets[lk] = levelOffsets[lk] + 1;

				return {
					id:n.id,
					type:n.type,
					x,
					y,
					r:nodeRadius.value,
					owner:n.owner,
					fn(){
						// todo: something useful
						// console.log(n);
					},
					itemFn(item:any){
						if(item.type === "empty"){ return; }
						
						nestedPreview.value = {
							type:item.type,
							data:item.data
						};


					},
					items:n.items
				};
			});
		});

		const onResize = () => {
			if(!canvas.value){ return; }
			canvasHeight.value = canvas.value.clientHeight;
			canvasWidth.value = canvas.value.clientWidth;
		};

		onMounted(() => {
			onResize();
			window.addEventListener("resize", onResize);
		});

		onUnmounted(() => {
			window.removeEventListener("resize", onResize);
		});

		return {
			canvas,
			canvasWidth,
			canvasHeight,
			graphBounds,
			canvasNodes,
			canvasEdges,
			nodeRadius,
			graphOffset,
			nestedPreview,
			translate,
		};
	}
});

