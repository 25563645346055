






















































// vendor
import { defineComponent, computed, ref, watch, PropType } from "@vue/composition-api";
import { AssetVersion } from "@psychlab/types/assets";
import { Toggle } from "@/components.generic/scriptable-form";
import { default as VersionListItem } from "./version-list-item.vue";
import { useTranslate } from "@lang";

export default defineComponent({

	emits:[
		"input",
		"select",
		"restore",
	],
	props:{
		asset:{
			type:String,
			required:true,
		},
		activeVersion:{
			type:String,
		},
		disabled:{
			type:Boolean,
			default:false,
		},
		versions:{
			type:Array as PropType<AssetVersion<any>[]>,
			default:() => [],
		},
		showContext:{
			type:Boolean,
			default:true,
		},
	},
	components:{
		VersionListItem,
		Toggle,
	},
	setup(props, context){

		const namedOnly = ref(false);

		const { translate } = useTranslate();

		const displayVersions = computed(() => {
			if(!namedOnly.value){ return props.versions; }
			return props.versions.filter(v => Boolean(v.name));
		});

		const pickVersion = (e:Event, vid:string) => {
			context.emit("select", vid);
		};

		return {
			translate,
			pickVersion,
			namedOnly,
			displayVersions,
		};
	}
});

