


















import { defineComponent, computed, ref, PropType, onMounted, watch, onUnmounted } from "@vue/composition-api";
import { Gallery } from "@/psychlab/types/gallery";
import { processUrls } from "../ts/utils/processUrls";
import { VideoPanorama } from "@panorama";
import { AbsBox } from "@ui";
import { generateGUID } from "@utils/guid";
import { getDelay } from "@utils/time";

export default defineComponent({

	props:{
		gallery:{
			type:Object as PropType<Gallery>,
			required:true,
		},
		sources:{
			type:Array as PropType<string[]>,
			required:true
		},
		accelerometer:{
			type:Boolean,
			default:false,
		},
	},
	components:{
		AbsBox,
		VideoPanorama,
	},
	setup(props, context){

		const loading = ref(false);

		const refreshKey = ref(generateGUID());

		const sources = computed(() => processUrls(props.sources));

		const panoramaConfig = computed(() => ({
			sources:sources.value,
		}));

		const viewerLoaded = () => {
			// if(!loading.value){ return; }
			// loading.value = false;
		};
		

		const handleSourceChange = async () => {
			
			loading.value = true;

			try {
				await getDelay(1100);
				refreshKey.value = generateGUID();
				loading.value = false;
				
				// setTimeout(() => {
					
				// }, 1000);

				await getDelay(1000);

			}
			catch{}
			
			// context.emit("load-end");
		};
		

		watch(() => props.sources, v => {
			handleSourceChange();
		});

		watch(loading, (v, ov) => {
			context.emit(`load-${ v ? "start" : "end" }`);
		});


		return {
			// viewerRoot,
			// panoramaLoading,
			panoramaConfig,
			refreshKey,
			loading,
			viewerLoaded,
		};
	}
});

