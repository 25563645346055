// ========================================
// ========== Global Plugins ==============
// ========================================

import Vue from "vue";
import Router from "vue-router";
import PortalVue from "portal-vue";
import VueShowdown from 'vue-showdown';
import CompositionAPI from "@vue/composition-api";
import { UIPlugin } from "@ui";
import { LangPlugin } from "@lang";

import "./global.scss";

Vue.use(UIPlugin);
Vue.use(Router);
Vue.use(LangPlugin);
Vue.use(CompositionAPI);
Vue.use(PortalVue);
Vue.use(VueShowdown)

// ========================================
// ============== App Init ================
// ========================================

import { router } from '@/router';
import { store } from "@/store";
import { App } from "./App";

new Vue({
	el: "#app",
	router,
	store,
	components: { App },
	template: "<App/>"
});