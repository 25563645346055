/** @format */

const tabHandler = (event: KeyboardEvent, el: HTMLTextAreaElement) => {
	if (event.key === "Tab") {
		const v = el.value;
		const s = el.selectionStart;
		const e = el.selectionEnd;
		el.value = v.substring(0, s) + "\t" + v.substring(e);
		el.selectionStart = el.selectionEnd = s + 1;
		event.preventDefault();
	}
};

export const insertTab = {
	bind(el: HTMLTextAreaElement) {
		el.addEventListener("keydown", (e) => tabHandler(e, el));
	},
};
