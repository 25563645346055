import { PhraseDictionary } from "../types";
import { mergeDictionaries } from "../mergeDictionaries";

const label:PhraseDictionary = {
	"attention":{
		en:"Attention",
		is:"Athugið"
	},
	"study":{
		en:"Study",
		is:"Könnun"
	},
	"studies":{
		en:"Studies",
		is:"Kannanir"
	},
	"task":{
		en:"Task",
		is:"Verk"
	},
	"tasks":{
		en:"Tasks",
		is:"Verk"
	},
	"live":{
		en:"Live",
		is:"Virkt"
	},
	"session":{
		en:"Session",
		is:"Keyrsla"
	},
	"sessions":{
		en:"Sessions",
		is:"Keyrslur"
	},
	"widget":{
		en:"Widget",
		is:"Skjától"
	},
	"widgets":{
		en:"Widgets",
		is:"Skjától"
	},
	"labels":{
		en:"Labels",
		is:"Merki"
	},
	"label":{
		en:"Label",
		is:"Merki"
	},
	"newLabel":{
		en:"New Label",
		is:"Nýtt merki"
	},
	"responseData":{
		en:"Response Data",
		is:"Gögn"
	},
	"requestHeaders":{
		en:"Request Headers",
		is:"Beiðnishaus"
	},
	"requestLog":{
		en:"Request Log",
		is:"Kladdi"
	},
	"studiesWithRecentSessions":{
		en:"Studies with Recent Sessions",
		is:"Kannanir með nýlegri virkni"
	},
	"runningSessions":{
		en:"Running Sessions",
		is:"Virkar keyrslur"
	},
	"blueprintVersion":{
		en:"Blueprint @ Version",
		is:"Snið @ Útgáfa"
	},
	
	"studyCreationDate":{
		en:"Study Creation Date",
		is:"Þegar könnun var búin til"
	},
	"displayLanguage":{
		en:"UI Display Language",
		is:"Viðmótstungumál"
	},
	"studyAuth":{
		en:"User Authentication",
		is:"Auðkenning notenda",
	},
	"closed":{
		en:"Closed",
		is:"Lokað"
	},
	"noDescription":{
		en:"No Description",
		is:"Engin lýsing"
	},
	"noStudies":{
		en:"No Studies",
		is:"Engar kannanir"
	},
	"existingStudies":{
		en:"Existing Studies",
		is:"Kannanir til staðar"
	},
	"newStudy":{
		en:"New Study",
		is:"Ný könnun",
	},
	"advanced":{
		en:"Advanced",
		is:"Ítarlegt"
	},
	"taskFiles":{
		en:"Task Files",
		is:"Verkskrár"
	},
	"taskArguments":{
		en:"Task Arguments",
		is:"Verkfæribreytur"
	},
	"noAuthSet":{
		en:"None",
		is:"Engin",
	},
};

const action:PhraseDictionary = {
	"editLabels":{
		en:"Edit Labels",
		is:"Breyta merkjum"
	},
	"expandValue":{
		en:"Expand Value",
		is:"Afþjappa"
	},
	"viewChannel":{
		en:"View Channel",
		is:"Skoða rás"
	},
	"createStudy":{
		en:"Create Study",
		is:"Búa til könnun"
	},
	"backToSelection":{
		en:"Back to Selection",
		is:"Aftur í val"
	},
	"copyShareLink":{
		en:"Copy Share Link",
		is:"Afrita deilihlekk"
	},
	"openStudy":{
		en:"Open Study",
		is:"Opna könnun"
	},
	"closeStudy":{
		en:"Close Study",
		is:"Loka könnun"
	},
	"deleteStudy":{
		en:"Delete Study",
		is:"Eyða könnun"
	},
	"deleteStudyLabel":{
		en:"Delete Label",
		is:"Eyða merki"
	},
	"rebuildSessions":{
		en:"Rebuild Sessions",
		is:"Endurbyggja keyrslu"
	},
	"showProgressBar":{
		en:"Show Progress Bar",
		is:"Sýna framvindustiku"
	},
	"startNewStudy":{
		en:"Start New Study...",
		is:"Byrja nýja könnun..."
	},
	"renameStudy":{
		en:"Rename Study",
		is:"Endurnefna könnun"
	},
	"openStudyDashboard":{
		en:"Open Study Dashboard",
		is:"Opna könnunaryfirlit"
	},
	"openDashboard":{
		en:"Open Dashboard",
		is:"Opna yfirlitssíðu"
	},
	"createStudyLabel":{
		en:"Create Label",
		is:"Búa til merki",
	},
	"updateLabels":{
		en:"Update Labels",
		is:"Uppfæra merki",
	}
};

const message:PhraseDictionary = {
	"onOpeningStudy":{
		en:"This will start the Study and allow more participants to join. Are you sure?",
		is:"Þetta mun opna könnunina og leyfa fleiri þátttakendur. Ertu viss?"
	},
	"onClosingStudy":{
		en:"This will end the Study and prevent more participants from joining. Are you sure?",
		is:"Þetta mun loka könnuninni og stöðva fleiri þátttakendur. Ertu viss?"
	},
	"aboutAdvancedSettings":{
		en:"Advanced admin settings. Can be safely ignored by most users.",
		is:"Ýtarlegar ábyrgðarstillingar. Geta verið hunsaðar af flestum notendum."
	},
	"aboutSessionSettings":{
		en:"Settings pertaining to session users.",
		is:"Stillingar fyrir keyrslur"
	},
	"aboutProgressBarSetting":{
		en:"If enabled, a progress bar will be displayed at the top of the screen for participants, showing their progress.",
		is:"Ef virkt, mun sýna framvindustiku efst á skjá þátttakennda."
	},
	"aboutLanguageSetting":{
		en:`Controls the language of global UI elements such as buttons.<br/>NOTE: This setting will NOT affect content displayed, such as Forms.`,
		is:"Stjórnar tungumáli staka í viðmóti eins og takka. <b>Taka fram</b>: Þessi stilling hefur ekki áhrif á efni, eins og spurningalista."
	},
	"onRebuildSessions":{
		en:`<p>
		Rebuilding a session will refresh computed properties, and
		fill in missing values. This is useful if the database
		schema has changed and properties need to be regenerated or
		added for older data.
		</p>
		<p>
			<strong>Note:</strong> Only use this if you know what you're
			doing.
		</p>`.replaceAll("\t", "").replace("\n", ""),
		is:"N/A",
	},
	"onDeletingStudy":{
		en:`<p>
		This will permanently delete the Study, and all data
		associated with it, including user sessions.
		</p>`.replaceAll("\t", "").replace("\n", ""),
		is:`<p>
		Þetta mun eyða rannsókn og öllum gögnum sem henni fylgja, þar með talið notendakeyrslum.
		</p>`.replaceAll("\t", "").replace("\n", "")
	},
	"onDeletingTask":{
		en:"Are you sure? Results and files will be purged.",
		is:"Ertu viss? Niðurstöður og skrár verða hreinsaðar."
	},
	
	"onOutdatedReferences":{
		en:"The chosen Blueprint has outdated references ($n). Would you like to update them before you continue?",
		is:"Valið snið inniheldur úreltar vísanir ($n). Viltu uppfæra þær áður en þú heldur áfram?"
	},
	"noFiltersActive":{
		en:"No filters are active.<br/> Press $icon to add filters.",
		is:"Engar síur eru virkar. <br/> Smelltu á $icon til að bæta við síum."
	},
	"aboutStudyName":{
		en:"Give your Study a helpful identifier, at least 2 characters in length. This can be changed later in settings.",
		is:"Gefðu könnuninni þinni hjálplegt nafn, a.m.k. 2 stafir í lengd. Hægt er að breyta þessu seinna.",
	},
	"aboutStudyBlueprint":{
		en:"Choose the blueprint and version you wish to use. <br/> <b>NOTE: </b>This cannot be changed later.",
		is:"Veldu sniðið og útgáfuna sem þú villt nota. <b>Mikilvægt</b>: Ekki er hægt að breyta þessu seinna.",
	},
	"aboutStudyLanguage":{
		en:"Set the interface language shown to participants joining the Study. <br/> <b>Note:</b> This will NOT affect the contents of your Study, only interface elements such as buttons. This can be changed later in settings.",
		is:"Stjórnar tungumáli staka í viðmóti eins og takka. <b>Taka fram</b>: Þessi stilling hefur ekki áhrif á efni, eins og spurningalista.",
	},
	"aboutStudyAuth":{
		en:"Sets authentication required to participate in Study. Authenticating prevents double participation. <br/> <b>NOTE: </b>This cannot be changed later.",
		is:"Stjórnar hvers konar auðkenningu er krafist til að taka þátt í könnun. Notkun auðkennis kemur í veg fyrir tvítekna þáttöku. <br/><b>Mikilvægt</b>: Ekki er hægt að breyta þessu seinna.",
	},
	"noStudyLabels":{
		en:"No labels",
		is:"Engin merki",
	},
	"unableToSaveLabels":{
		en:"Unable to save labels",
		is:"Náðist ekki að vista merki",
	},
	"noSessions":{
		en:"No sessions",
		is:"Engar keyrslur"
	},
	"noExistingStudies":{
		en:"No existing studies",
		is:"Engar kannanir til staðar"
	},
	"noWidgets":{
		en:"No widgets",
		is:"Engin skjától"
	},
	"noTasks":{
		en:"No tasks",
		is:"Engin verk",
	},
	"authenticationRequired":{
		en:"Please authenticate to open this Survey",
		is:"Vinsamlegast auðkenndu þig til að taka þátt í þessari könnun"
	},
	"studyAlreadyVisited":{
		en:"You have already answered this Survey.",
		is:"Þú hefur svarað þessari könnun nú þegar."
	},
	"studyAuthenticationFailed":{
		en:"Authentication failed for Survey",
		is:"Auðkenning fyrir könnun mistókst"
	},
	"pressButtonToAuthenticate":{
		en:"Press the button below to continue",
		is:"Smelltu á takkann hér fyrir neðan til að halda áfram"
	},
	"aboutIcelandAuth":{
		en:"Athentication through island.is is required to open this survey. Authentication is solely used to prevent the same individual from submitting the survey twice. This type of authentication logs no user-identifiable information and matching submitted responses to your SSN is not possible.",
		is:`Til að taka þátt í könnuninni þarf að skrá sig gegnum island.is. Skráningin er einungis til að koma í veg fyrir að sami aðili taki oftar en einu sinni þátt í könnuninni. Með skráningu gegnum island.is er engum persónugreinanlegum gögnum safnað og ekki verður hægt að rekja saman svör og kennitölu.`
	},
	"surveyCanBeOpenedOnce":{
		en:"You can only open this survey once so it is important that you do not close your browser window until you have completed the survey.",
		is:"Aðeins er hægt að opna þessa könnun einu sinni og því er mikilvægt að vafraglugganum sé ekki lokað fyrr en þátttöku er lokið."
	},
	"pleaseAuthenticateSurvey":{
		en:"Please authenticate",
		is:"Vinsamlegast auðkennið"
	}
};

export const studies = mergeDictionaries("studies", { label, action, message });