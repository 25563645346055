import { IStudyProvider, ISessionProvider } from "./interface";
import { StudyProvider } from "./providers";

export class Provide {

	static get studies():IStudyProvider{
		return Provide.getInstance()._studies;
	}

	static studySessions(sid:string):ISessionProvider {
		throw new Error("not implemented");
	}


	private constructor(){
		this._studies = new StudyProvider();
	}

	private static getInstance(){
		if(!Provide._instance){
			Provide._instance = new Provide();
		}
		return Provide._instance;
	}
	
	private static _instance:Provide|null = null;

	private _studies:IStudyProvider;

}