import { defineComponent, computed } from "@vue/composition-api";
import { ErrorScreen } from "@/errors/ErrorScreen";
import { useTranslate } from "@lang";
import { RawLocation } from "vue-router";

enum Translations {
	SurveyClosed = "session.message.surveyHasBeenClosed",
	PageNotFound = "errors.message.pageNotFound",
	SurveyComplete = "session.message.procedureHasFinished",
	SurveySaved = "session.message.answersSaved",
	SurveyVisited = "studies.message.studyAlreadyVisited",
	SurveyAuthFailed = "studies.message.studyAuthenticationFailed",
	ErrorOccurred = "message.errorOccurred"
}

enum RouteNames {
	StartStudy = "Run.StartStudy",
}


const ErrorPage = defineComponent({

	setup(_, ctx){
		
		const { translate } = useTranslate();

		const route = ctx.root.$route;

		const slug = route.params["slug"];

		const error = computed(() => {
			const e = errors[slug];
			if(!e){ return null; }

			return {
				icon:e.icon,
				text:translate(e.text),
				subtext:translate(e.subtext || ""),
				backText:translate(e.backText || ""),
				backResolver:e.backResolver,
			}
		});

		const routeContext = computed(() => {
			return (route.query as any) || {};
		})


		return {
			slug,
			error,
			routeContext,
		}

	},
	render(){

		const e = this.error;

		if(!e){
			return <DefaultError/>
		}
		
		const backRoute = e.backResolver ? e.backResolver(this.routeContext) : null; 

		return (
			<ErrorScreen
			text={e.text}
			icon={e.icon}
			subtext={e.subtext}
			backRoute={backRoute}
			backText={e.backText}
			/>
		);
	}
});


const DefaultError = defineComponent({

	setup(){
		const { translate } = useTranslate();
		return { translate }
	},
	render(){
		return (
			<ErrorScreen
			text={ this.translate(Translations.ErrorOccurred) }
			icon="alert-rhombus"
			/>
		);
	}
});

type ErrorConfig = {
	text:string;
	subtext?:string;
	icon?:string;
	backText?:string;
	backResolver?:BackRouteResolver;
}

type RouteContext = Record<string,any>;

type BackRouteResolver = (ctx:RouteContext) => RawLocation|null;

const errors:Record<string,ErrorConfig|undefined> = {
	"survey-closed":{
		text:Translations.SurveyClosed,
		icon:"lock",
	},
	"not-found":{
		text:Translations.PageNotFound,
		icon:"close-circle-outline"
	},
	"survey-complete":{
		text:Translations.SurveyComplete,
		subtext:Translations.SurveySaved,
		icon:"check-bold",
	},
	"survey-visited":{
		text:Translations.SurveyVisited,
		icon:"account-lock"
	},
	"survey-authentication-failed":{
		text:Translations.SurveyAuthFailed,
		icon:"alert-rhombus",
		backText:"action.back",
		backResolver(ctx){
			const { survey } = ctx;
			if(!survey){ return null; }
			return {
				name:RouteNames.StartStudy,
				params:{
					proxyId:survey,
				}
			};

		}
	}
}






export default ErrorPage;