/** @format */

import { computed, SetupContext} from "@vue/composition-api";

const getParam = (k:string, ctx:SetupContext) => computed(() => ctx.root.$route.params[k]);

export const useStudyRouteParams = (ctx:SetupContext) => {

	const sessionId = getParam("sessionId", ctx);
	const studyId = getParam("groupId", ctx);

	return {
		sessionId,
		studyId,
	};
};
