import { computed, Ref, ComputedRef, ref } from "@vue/composition-api";

type Dictionary<T> = { [k:string]:T };

export type GridCell = {
	x:number;
	y:number;
	time:number;
	count:number;
};

type RT<T> = Ref<T>|ComputedRef<T>;

const getDateDiff = (d1:Date, d2:Date) => {
	const diff = Math.abs(d1.getTime() - d2.getTime() + 0);
	return Math.ceil(diff / (1000 * 3600 * 24));
};


// const getDayMS = (v:number) => v * 86400000; 

export const useCalendarGrid = (timestamps:RT<number[]>) => {

	const cells = ref<GridCell[]>([]);

	const dates = computed(() => {
		return timestamps.value.map(ts => {
			const d = new Date(ts);
			d.setHours(0,0,0,0);
			// console.log(d);
			return d;
		})
	});

	// const regenerateCells = async():Promise<GridCell[]> => {
	// 	const latest = timestamps.value.length > 0 ? Math.max(...timestamps.value) : 0;
	// 	const ld = new Date(latest);
	// 	const cy = ld.getDay() - 1;
	// 	const firstDay = latest + getDayMS(6 - cy) - getDayMS(7 * 53);
	// 	let spos = (52 * 7) + cy;
	// 	const cells:GridCell[] = [];
	// 	const map:Dictionary<GridCell>= {};

	// 	dates.value.forEach(d => {
	// 		const p = getDateDiff(ld, d);
	// 		const pos = spos - p;
	// 		const x = Math.floor(pos / 7);
	// 		const y = pos % 7;
	// 		const pindex = 1 + (x * 7 + y);

	// 		if(!map[`${x}-${y}`]){
	// 			const time = firstDay + getDayMS(pindex);
	// 			map[`${x}-${y}`] = {
	// 				x,
	// 				y,
	// 				time,
	// 				count:0
	// 			}
	// 			cells.push(map[`${x}-${y}`]);
	// 		}
	// 		map[`${x}-${y}`].count++;
			
	// 	});
	// 	return cells;
	// };

	const regenerateCells = async():Promise<GridCell[]> => {
		const firstTime = timestamps.value.length > 0 ? Math.min(...timestamps.value) : 0;

		const firstDate = new Date(firstTime);
		firstDate.setHours(0,0,0,0);
		
		const startY = firstDate.getDay();

		const cells:GridCell[] = [];
		const map:Dictionary<GridCell>= {};


		dates.value.forEach(d => {

			const diff = getDateDiff(firstDate, d);
			const pos = startY + diff;
			const x = Math.floor(pos / 7);
			const y = Math.floor(pos % 7);

			if(!map[`${x}-${y}`]){
				const time = d.getTime();
				map[`${x}-${y}`] = {
					x,
					y,
					time,
					count:0
				}
				cells.push(map[`${x}-${y}`]);
			}
			map[`${x}-${y}`].count++;
			
		});
		return cells;
	};

	const init = async() => {
		cells.value = await regenerateCells();
	};

	return {
		cells,
		init,
	};
};