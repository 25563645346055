import { Port } from "../editor/Port";
import { GraphData } from "../types/GraphData";
import { TransitionValidator } from "../types/TransitionValidator";
import { ViewportTransform } from "../types/ViewportTransform";

// import * as SnapSVG from "snapsvg";

// export const formatTransform = (px = 1, py = 1, scale = 1) => `translate(${px} ${py}) scale(${scale} ${scale})`;


export const formatTransform = (vt:ViewportTransform) => {

	// console.log(`translate(${vt.offsetX} ${vt.offsetY}) scale(${vt.zoom} ${vt.zoom})`);
	return `translate(${vt.offsetX} ${vt.offsetY}) scale(${vt.zoom} ${vt.zoom})`;
}

export const getTransformCenter = (el:HTMLElement, vt:ViewportTransform) => {
	let x = -vt.offsetX * (1.0/vt.zoom);
	let y = -vt.offsetY * (1.0/vt.zoom);
	let w = el.scrollWidth * (1.0/vt.zoom);
	let h = el.scrollHeight * (1.0/vt.zoom);
	x += w * 0.5;
	y += h * 0.5;
	return { x, y };
};

export const parseTransitionPath = (path:string) => {
	var res = path.split(",");
	var a = res[0].split(".");
	var b = res[1].split(".");
	return {
		a: {
			node: a[0],
			port: a[1]
		},
		b: {
			node: b[0],
			port: b[1]
		}
	};
};


export const validateGraphData = (graph:GraphData) => {
	return true;
}

const packPortData = (p:Port) => {
	return {
		node: {
			id: p.getNodeID(),
			type: p.getNodeType(),
		},
		port: {
			key: p.getKey(),
			type: p.getType()
		}
	};
};

export const validateTransition = (portA:Port, portB:Port, rules:TransitionValidator[]):boolean => {
	if(!portA || !portB){ return false; }
	const data = {
		from: packPortData(portA),
		to: packPortData(portB)
	};
	for(var i = 0; i < rules.length; i++){
		if(!rules[i].validator(data)){ return false; }
	}
	return true;
};