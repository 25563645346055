import { defineComponent, computed, PropType } from "@vue/composition-api";
import { Icon } from "../icons";

type ContextType = "horizontal"|"vertical"|"chevron";
type ContextSize = "sm"|"md"|"lg";

export const ContextButton = defineComponent({
	emits:[ "click" ],
	props:{
		type:{
			type:String as PropType<ContextType>,
			default:"horizontal",
		},
		size:{
			type:String as PropType<ContextSize>,
			default:"md",
		},
		variant:{
			type:String,
			default:"light",
		},
	},
	setup(props){

		const sizes:Record<ContextSize,number> = {
			"sm":1.5,
			"md":2,
			"lg":3
		};

		const icons:Record<ContextType,string> = {
			"horizontal":"dots-horizontal",
			"vertical":"dots-vertical",
			"chevron":"chevron-down",
		};

		const config = computed(() => {
			const bs = sizes[props.size] || sizes["md"]
			const fs = bs * 0.6;
			return { bs,fs };
		});

		const icon = computed(() => {
			return icons[props.type] || icons["horizontal"];
		});

		return {
			icon,
			config,
		}
	},
	render(){
		const v = this.variant;

		const fontStyle = `font-size:${this.config.fs}em`;
		const btnSize = `width:${this.config.bs}em;height:${this.config.bs}em;`

		return (
			<button
			v-on:click={ (e:Event) => this.$emit("click", e) }
			class={ `btn btn-sm btn-outline-${v} rounded-circle p-0 border-0 shadow-none` }
			style={ `pointer-events:all;${btnSize}` }
			>
				<Icon
				name={ this.icon }
				class="m-auto"
				style={ fontStyle }
				/>
			</button>
		);
	}
});

