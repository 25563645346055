







// vendor
import { defineComponent, computed, ref } from "@vue/composition-api";

export default defineComponent({
	props:[ "variant" ],
	components:{
	},
	setup(){
		return {};
	}
});

