



























// vendor
import { defineComponent, computed, ref } from "@vue/composition-api";
import { AbsBox } from "../../boxes";

import { IEditText as IUrlModal, default as UrlModal } from "./url-modal.vue";
import { useTranslate } from "@lang";
import { getUrlInfo } from "@utils/url";

export default defineComponent({

	emits:[ "input" ],
	props:{
		value:{
			type:String,
			default:""
		},
	},
	components:{
		AbsBox,
		UrlModal,
	},
	setup(props, ctx){
		
		const urlModal = ref<IUrlModal>();

		const {
			translate
		} = useTranslate();


		const label = computed(() => {

			try {
				const u = new URL(val.value);

				const path = val.value.split("/");

				return path.length ? path[path.length - 1] : u.hostname.replace("www.", "");;


				// return u.hostname.replace("www.", "");
			}
			catch{}

			return val.value;
		})

		
		const val = computed({
			get:() => props.value,
			set:v => ctx.emit("input", v)
		});


		const edit = () => {
			if(!urlModal.value){ return; }

			urlModal.value.open({
				title:translate("forms.label.backgroundImage"),
				value:val.value,
				size:"md"
			}, (nv) => {
				val.value = nv;
			})

		};

		return {
			val,
			urlModal,
			label,
			edit,
		};
	}
});

