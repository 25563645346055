import ErrorPage from "@pages/error/[slug]";

export const Error = {
	path:"/info/:slug",
	name:"Error",
	component:ErrorPage,
	meta:{
		title:"Info",
		public:true,
	}
};