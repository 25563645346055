import { defineComponent } from "@vue/composition-api";
import { Icon, AbsBox } from "@ui";

export const DefaultNav = defineComponent({
	emits:[ "previous", "next" ],
	props:{
		showBack:{
			type:Boolean,
			default:true
		},
		isLast:{
			type:Boolean,
			default:false
		}
	},
	setup(_, context){
		const onPrevious = () => context.emit("previous");
		const onNext = () => context.emit("next");
		return {
			onPrevious,
			onNext
		};
	},
	render(){
		const btnSize = 5;
		const nextVariant = !this.isLast ? "secondary" : "success";
		const nextIcon = !this.isLast ? "arrow-right-thick" : "check-bold";
		const btnClass = "shadow btn";
		const iconStyle = `
			font-size:2em;
		`;
		const btnStyle = `
			width:${btnSize}em;
			height:${btnSize}em;
			pointer-events: all;
			border-radius:50%;
		`;

		const backButton = this.showBack ? (
			<button
			v-on:click={ this.onPrevious }
			style={ btnStyle }
			class={ `${btnClass}` }
			>
				<Icon name="arrow-left-thick" style={ iconStyle }/>
			</button>
		) : null;

		const nextButton = (
			<button
			variant={ nextVariant }
			v-on:click={ this.onNext }
			class={ `${btnClass} btn-${nextVariant}` }
			style={ btnStyle }
			>
				<Icon name={ nextIcon } style={ iconStyle }/>
			</button>
		);

		return (
			<AbsBox class="d-flex"
			style="pointer-events:none;"
			>
				<div class="d-flex w-100 justify-content-between p-3 mt-auto">
					{ backButton }
					{ nextButton }
				</div>
			</AbsBox>
		);
	}
});
