import { PropType } from "@vue/composition-api";
import { IFormTheme, BaseFormItem } from "@psychlab/types/form";

export const getBaseProps = <T extends BaseFormItem>() => {
	return {
		theme:{
			type:Object as PropType<IFormTheme>,
			default:() => {},
		},
	};

};