






















































	// vendor
	import { useTranslate } from "@lang";
import { defineComponent, computed, ref, PropType } from "@vue/composition-api";
	
	type Pos2D = {
		x:number;
		y:number;
	};

	export default defineComponent({
		emits:[ "delete" ],
		props:{
			from:{
				type:Object as PropType<Pos2D>,
				default:() => ({ x:0, y:0 })
			},
			to:{
				type:Object as PropType<Pos2D>,
				default:() => ({ x:0, y:0 })
			},
			color:{
				type:String,
				default:"white"
			},
			width:{
				type:Number,
				default:1
			},
			canDelete:{
				type:Boolean,
				default:true
			},
			start:{
				type:Boolean,
				default:true
			},
			end:{
				type:Boolean,
				default:true
			},
		},
		setup(props, context){

			const {
				translate,
			} = useTranslate();

			const pathData = computed(() => {
				
				if(!props.from || !props.to){ return undefined; }
				return `M ${props.from.x} ${props.from.y} L ${props.to.x} ${props.to.y}`;
			});

			const midPoint = computed(() => {
				if(!props.from || !props.to){ return { x:0, y:0 }; }
				return {
					x: (props.to.x + props.from.x) * 0.5,	
					y: (props.to.y + props.from.y) * 0.5
				};
			});

			const onDelete = () => {
				context.emit("delete");
			};
			
			return {
				pathData,
				midPoint,
				onDelete,
				translate,
			};
		}
	});

