// local
import { Timer } from "../utils/Timer";
import { IBehaviour } from "../core/IBehaviour";
import { SceneContext } from "../core/SceneContext";

export interface IApp {
	timer:Timer;
	kill():void;
	registerBehaviour(b:IBehaviour):void;
};

export const useApp = (ctx:SceneContext):IApp => {

	let updateFrame:number = -1;

	const behaviours:IBehaviour[] = [];

	const timer = new Timer();

	const onFrame = (time:number) => {
		if(!timer){ return; }
		timer.tick(time);
		behaviours.forEach(b => {
			if(b.enabled && b.update){
				b.update(ctx);
			}
		});
		updateFrame = requestAnimationFrame(onFrame);
	};

	const kill = () => {
		cancelAnimationFrame(updateFrame);
		window.removeEventListener("resize", onResize);
		behaviours.forEach(b => {
			if(b.destroy){ b.destroy(ctx); }
		});
		// App._instance = null;
	};

	const onResize = () => {
		behaviours.forEach(b => {
			if(b.resize){
				b.resize(ctx)
			}
		});
	};

	const registerBehaviour = (b:IBehaviour) => {
		behaviours.push(b);
	};

	onFrame(0);
	window.addEventListener("resize", onResize);

	return {
		timer,
		kill,
		registerBehaviour,
	}
};
