

















import { defineComponent, PropType } from "@vue/composition-api";
import { IToolbarInputOption } from "./IToolbarInputOption";
import { Icon } from "../../icons";

export default defineComponent({

	emits:[ "input" ],
	props:{
		value:null,
		options:{
			type:Array as PropType<IToolbarInputOption[]>,
			default:() => [],
		},
	},
	components:{
		Icon,
	},
	setup(_, ctx){
		
		const pickOption = (o:IToolbarInputOption) => {
			ctx.emit("input", o.value);
		};

		return {
			pickOption,
		};
	}
});

