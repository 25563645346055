













import { defineComponent, computed, ref, watch } from "@vue/composition-api";
import { generateGUID } from "@/utils/guid";
import * as Drawers from "./drawers";

const defaultDrawer = {
	props:[ "label", "config", "type" ],
	template:`<div
	class="border bg-danger p-1 rounded text-light" style="font-size:0.9rem">
		{{label}}: {{config.type}}
	</div>`
};

const drawerMap:any = {
	"range":Drawers.Range,
	"dropdown":Drawers.Dropdown,
	"color":Drawers.Color,
	"string":Drawers.String,
	"number":Drawers.Number,
	"string[]":Drawers.StringArray,
	"icon":Drawers.Icon,
	"session-filter":Drawers.SessionFilter
};

export default defineComponent({

	emits:[ "input" ],
	props:{
		type:{
			type:String,
			required:true
		},
		name:{
			type:String,
			required:true
		},
		drawer:{
			type:Object,
			required:true
		},
		value:{

		}
	},
	setup(props, context){

		const drawerKey = ref(generateGUID());

		const drawerComponent = computed(() => {
			const k = props.drawer.type ? props.drawer.type : props.type;
			return drawerMap[k] ? drawerMap[k] : defaultDrawer;
		});

		const val = computed({
			get:() => props.value,
			set:(v:any) => context.emit("input", v)
		});

		watch(drawerComponent, () => drawerKey.value = generateGUID());

		return {
			drawerComponent,
			val,
			drawerKey
		};
	}
});

