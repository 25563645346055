












import { defineComponent, computed } from "@vue/composition-api";
import { AbsBox } from "../../boxes";

export default defineComponent({

	emits:[ "input" ],
	props:{
		value:{
			type:Number,
			default:0,
		},
		min:{
			type:Number,
			default:0,
		},
		max:{
			type:Number,
			default:1,
		},
		step:{
			type:Number,
			default:0.1,
		},
	},
	components:{
		AbsBox,
	},
	setup(props, ctx){

		const val = computed({
			get:() => props.value,
			set:v => ctx.emit("input", v)
		});

		return {
			val,
		};
	}
});

