

export const Node = {
	selection: {
		color: "#007bff",
		width: 15,
		// blur: 8
	},
	shadow: {
		opacity: 1,
		blur: 1,
		offset: 5,
		color: "red"
	},
	background: {
		root: {
			attributes: {
				x: -50,
				y: -50
			}
		},
		shape: {
			attributes: {
				width: 100,
				height: 100
			}
		}
	},
		// icon: {
	// 	root: {
	// 		attributes: {
	// 			y: 0,
	// 			"shape-rendering":"geometricPrecision"
	// 		}
	// 	},
	// 	image: {
	// 		attributes: {
	// 			width: 90,
	// 			height: 90,
	// 			x: -45,
	// 			y: -45
	// 		}
	// 	},
	// 	background: {
	// 		attributes: {
	// 			fill: "transparent",
	// 			stroke: "transparent",
	// 			strokeWidth: 2,
	// 			r: 30
	// 		}
	// 	}
	// },
};