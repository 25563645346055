import { Dictionary } from "./Dictionary";

export const getElbowPath = (a:Point2D, b:Point2D) => PathUtility.getPath("elbow", a, b);

export const getStraightPath = (a:Point2D, b:Point2D) => PathUtility.getPath("straight", a, b);

export const getPathString = (type:PathType|string, a:Point2D, b:Point2D) => {
	let s = "";
	PathUtility.getPath(type as PathType, a, b).forEach((p:any) => s += (p.x + "," + p.y + " "));
	return s;
};

const computeStraightPath = (a:Point2D, b:Point2D):Point2D[] => {
	return [ a, b ];
};

const computeElbowPath = (a:Point2D, b:Point2D):Point2D[] => {
	const result:Point2D[] = [];
	const diffx =  b.x - a.x;
	const diffy =  b.y - a.y;
	const distx = Math.abs(b.x - a.x);
	const disty = Math.abs(b.y - a.y);
	const offset = 25;

	if((distx * 0.5) > offset && diffx >= 0) {
		const mid = { x: (a.x + b.x) * 0.5, y: (a.y + b.y) * 0.5 };
		result.push(a);
		result.push({ x: mid.x, y: a.y });
		result.push({ x: mid.x, y: b.y });
		result.push(b);
		return result;
	}
	const ymul = diffy > 0 ? - 1 : 1;
	const xmul = 1;
	result.push(a);
	result.push({ x: a.x + offset * xmul, y: a.y });
	result.push({ x: a.x + offset * xmul, y: a.y + disty * 0.5 * (-ymul) });
	result.push({ x: b.x + offset * -xmul, y: b.y + disty * 0.5 * ymul });
	result.push({ x: b.x + offset * -xmul, y: b.y });
	result.push(b);
	return result;
}

type PathType = "elbow"|"straight";

type Point2D = {
	x:number,
	y:number
}

class PathUtility {
	public static getPath(type:PathType, a:Point2D, b:Point2D){
		var handler:any = PathUtility._fn[type];
		if(handler && a && b) { return handler(a, b); }
		return [];
	}

	private static _fn:Dictionary<(a:Point2D, b:Point2D) => Point2D[]> = {
		"elbow":computeElbowPath,
		"straight":computeStraightPath
	};
}