





















































import { defineComponent, computed } from "@vue/composition-api";
import { generateGUID } from "@utils/guid";
import { useTranslate } from "@lang";
import {  } from "@ui";

type CustomAction = {
	icon:string,
	tooltip:string,
	variant:string;
	fn:() => any
};

const testActions:CustomAction[] = [
	{
		tooltip:"assets.action.updateReferences",
		variant:"info",
		icon:"mdi.update",
		fn(){
			console.log("Update stuff!");
		}
	},
	{
		icon:"mdi.subdirectory-arrow-right",
		tooltip:"action.open",
		variant:"light",
		fn(){
			console.log("Open stuff!");
		}
	}
];


export default defineComponent({

	props:{
		isStart:{
			type:Boolean,
			default:false
		},
		iconSize:{
			type:Number,
			default:0.7
		},
		warningCount:{
			type:Number,
			default:0
		},
		
	},
	components:{
	},
	setup(props, context){
		

		const { translate } = useTranslate();
		
		const buttonSize = computed(() => {
			return props.iconSize + 0.6;
		});
		
		const actions = computed(() => {

			const r:any[] = [];
			
			if(props.isStart){
				r.push({
					key:"start-node",
					tooltip:translate("assets.label.startNode"),
					variant:"success",
					tooltipVariant:"success",
					icon:"mdi.flag-variant"
				});
			}

			if(props.warningCount > 0){
				r.push({
					key:"warnings",
					tooltip:`${translate("assets.label.warnings")} (${props.warningCount})`,
					variant:"warning",
					tooltipVariant:"warning",
					txtVariant:"danger",
					icon:"mdi.alert-circle"
				});
			}

			testActions.forEach(a => {
				r.push({
					key:generateGUID(),
					tooltip:translate(a.tooltip),
					variant:`outline-${a.variant}`,
					tooltipVariant:a.variant,
					icon:a.icon,
					fn:a.fn
					
				})
			});
			return r;

		});

		const runAction = (a:any) => {
			if(a.fn){
				a.fn();
			}
		};
		

		return {
			actions,
			buttonSize,
			runAction,
			translate,
		};
	}
});

