





























// vendor
import { defineComponent, computed, ref, PropType, watch } from "@vue/composition-api";
// project
import { formatDuration } from "@/utils/text";

type TimeConfig = {
	min?:number;
	max:number;
	step?:number;
	labelFn?(min:number, max:number):string;
};

export default defineComponent({
	emits: [
		"input",
	],
	props: {
		value: {
			// type: String,
			type:Array as PropType<number[]>,
			default:() => [ 0, 100 ],
		},
		size:{
			type:String,
			default:"md",
		},
		config: {
			type: Object as PropType<TimeConfig>,
			default:() => ({ min:0, step:1, max:100 }),
		},
	},
	setup(props, context) {
		const range = computed(() => {

			// const s = props.value.split(",");
			// if(s.length === 0){ return [ props.config.min || 0, props.config.max || 100 ]; };
			// if(s.length > 1){
			// 	return [ Number(s[0]), Number(s[1]) ];
			// }
			// return [ 0, Number(props.value) ];

			return props.value;
		});

		const min = computed({
			get(){
				return range.value[0];
			},
			set(v:number){
				const min = Math.min(v, range.value[1]);
				const max = Math.max(v, range.value[1]);
				// context.emit("input", `${min},${max}`);
				context.emit("input", [ min, max ]);

			}
		});

		const max = computed({
			get(){
				return range.value[1];
			},
			set(v:number){
				const min = Math.min(v, range.value[0]);
				const max = Math.max(v, range.value[0]);
				// context.emit("input", `${min},${max}`);
				context.emit("input", [ min, max ]);
			}
		});

		const label = computed(() => {
			const mn = Math.min(...range.value);
			const mx = Math.max(...range.value);
			if(props.config.labelFn){
				return props.config.labelFn(mn, mx);
			}
			return `${mn} - ${mx}`;
		});

		return {
			min,
			max,
			range,
			label,
			formatDuration,
		};
	},
});
