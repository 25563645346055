import TagsPage from "@pages/tags/index";
import TagPage from "@pages/tags/[id]";

export const ViewTag = {
	path: "/tags/:tagId",
	name: "view-tag",
	component: TagPage,
	meta: {
		title: "Tag"
	},
};

export const TagsHome = {
	path: "/tags",
	name: "view-tags",
	component: TagsPage,
	meta: {
		title: "Tags"
	},
};