// vendor
import * as THREE from "three";
// local
import { ICameraController } from "../core/ICameraController";
import { getApp } from "../App";
import { FlightControls } from "../utils/FlightControls";

export interface IFlightCamera extends ICameraController {
	getLookMatrix():THREE.Matrix4;
};

export const useFlightCamera = (el:HTMLElement):IFlightCamera => {

	const camera = getCamera(el);
	const flight:FlightControls = new FlightControls(camera, el);
	flight.setRotationY(-45);
	flight.setRotationX(-15);
	flight.setPosition({ x:-10, y:7, z:10 });

	const getLookMatrix = () => {
		return camera.matrixWorld;
	};

	const resize = () => {
		const size = getSize(el);
		camera.aspect = size.x/size.y;
		camera.updateProjectionMatrix();
	};

	const update = () => {
		// console.log("Moo");
		flight.update();
	};

	const destroy = () => {
		flight.dispose();
	};

	const r:IFlightCamera = {
		type:"FlightCamera",
		enabled:true,
		camera,
		resize,
		update,
		destroy,
		getLookMatrix,
	};

	getApp().registerBehaviour(r);

	return r;
};

const getSize = (el:HTMLElement) => {
	let x:number = el.offsetWidth;
	let y:number = el.offsetHeight;
	return { x, y };
};

const getCamera = (el:HTMLElement) => {
	const size = getSize(el);
	return new THREE.PerspectiveCamera(75, size.x/size.y, 0.1, 1000);
};