import { getSupportedLanguages, isLanguageSupported } from "@lang";

import { Module } from "vuex";

type State = {
	preferredLanguage:string
}

export const Localization:Module<State, {}> = {
	namespaced:true,
	state: {
		preferredLanguage: "en"
	},
	getters: {
		languages(){
			return getSupportedLanguages();
		},
		preferredLanguage(state:any){
			return state.preferredLanguage;
		}
	},
	mutations:{
		setPreferredLanguage(state:any, v:string){
			if(isLanguageSupported(v)){
				state.preferredLanguage = v;
			}
		}
	}
}