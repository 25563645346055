












// vendor
import { defineComponent, computed, ref } from "@vue/composition-api";

import { getBaseProps, getBaseEmits } from "./getButtonBase";

export default defineComponent({
	emits:[ ...getBaseEmits() ],
	props:{
		...getBaseProps(),
		active:{
			type:Boolean,
			default:false,
		}
	},
	setup(){
		
		

		return {

		};
	}
});

