import LoginPage from "@pages/login";
import LogoutPage from "@pages/logout";

export const Login = {
	path:"/login",
	name:"Login",
	component: LoginPage,
	meta:{
		title:"Log In",
		public:true
	}
};

export const Logout = {
	path:"/logout",
	name:"Logout",
	component:LogoutPage,
	meta:{
		title:"Log Out",
		public:false
	}
};