import { defineComponent, computed, inject } from "@vue/composition-api";
import { SessionContext } from "./SessionContext";

export const SessionProgressBar = defineComponent({
	setup(){

		const getSessionContext = inject<() => SessionContext>("getSessionContext");

		if(!getSessionContext){
			throw Error("Session Progress Bar: Session context missing!")
		}

		const {
			progressStepIndex,
			progressStepCount,
		} = getSessionContext();

		const steps = computed<boolean[]>(() => {
			const steps:any = [];
			for(let i = 0; i < progressStepCount.value; i++){
				steps.push(i <= progressStepIndex.value);
			}
			return steps;
		});

		return {
			steps,
		};
	},
	render(){

		if(!this.steps.length){ return <span/>; }

		const dsteps = this.steps.map((s,i) => <Step filled={s} key={i} last={i === this.steps.length - 1}/>)

		return (
			<Bar>
				{dsteps}
			</Bar>
		);
	}
});

const colors = {
	filled:"#a7e9f4",
	unfilled:"#dadada",
}

const bstyle = `
	height:1rem;
	width:100%;
	display:flex;
	position:relative;
`;

const Bar = defineComponent({
	render(){
		return (
			<div style={bstyle} class="border-bottom border-dark">
				{ this.$slots.default }
			</div>
		);
	}
});

const Step = defineComponent({
	props:{
		filled:Boolean,
		last:Boolean,
	},
	render(){
		const c = this.filled ? colors.filled : colors.unfilled;
		const s = `background:${c}`;
		return (
			<div
			class={ `w-100 h-100 ${ !this.last ? 'border-right border-dark' : '' }` }
			style={ s }
			/>
		);
	}
});