export const Connector = {
	type: "elbow",
	// type: "straight",

	normal: {
		attributes: {
			stroke: "white",
			strokeWidth: 5
		}
	},
	collider: {
		attributes: {
			stroke: "transparent",
			strokeWidth: 12
		}
	},
	selected: {
		attributes: {
			stroke: "#007bff",
			strokeWidth: 8
		}
	},
	hovered: {
		attributes: {
			stroke: "#b4d0ee",
			strokeWidth: 7
		}
	}
};