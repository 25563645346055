import Router from "vue-router";

export const setFavIcon = (name:string) =>{
	const el = (document.querySelector("link[rel*='icon']") || document.createElement("link")) as HTMLLinkElement
	el.type = "image/x-icon";
	el.rel = "shortcut icon";
	el.href = `/favicon/${name}.ico`;
	document.getElementsByTagName("head")[0].appendChild(el);
};

export const copyToClipboard = (id:string) => {
	const element = document.getElementById(id) as HTMLInputElement;
	try{
		element.select();
		document.execCommand("copy");
	}catch(e){
		throw `Error copying element '${id}' to clipboard`;
	}
};

export const openRouterTab = (router:Router, name:string, params:Dictionary<string>) => {
	window.open((router.resolve({ name, params })).href, "_blank");
};

type Dictionary<T> = { [k:string]:T }