// project
import { Session } from "@/psychlab/types";

export const isSessionExpired = (s:Session) => {
	if(!s.expires){ return true; } // missing expiration
	if((s.expires - Date.now()) < 0){ return true; }	
	return false;
};

export const computeSessionRunState = (s:Session) => {
	let expired = isSessionExpired(s);
	if(s.done){ return "done"; }
	else if(!s.done && expired){ return "expired"; }
	return "running";
};