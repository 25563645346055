import { HTTPEndpoint, IGet } from "@http";
import { IStudyHandler } from "../interface/IStudyHandler";
import { IStudyPeek } from "../interface/IStudyPeek";
import { IStudyProvider } from "../interface/IStudyProvider";
import { IStudy } from "../interface/IStudy";
import { StudyHandler } from "../handlers/StudyHandler";

import {
	ReadRecentStudiesQuery,
	ReadRecentStudiesResult,
	ReadStudiesQuery,
} from "../queries";


export class StudyProvider implements IStudyProvider {

	constructor(){}

	async readRecentStudies(q:ReadRecentStudiesQuery):Promise<ReadRecentStudiesResult> {
		return await this._recentStudies.send({ days:q.days })
	}

	async readStudyByID(id:string):Promise<IStudyHandler> {
		const s = await this._study.send({ id });
		return new StudyHandler(s);
	}

	async readStudies(q:ReadStudiesQuery):Promise<IStudyHandler[]> {
		const l = await this._studies.send({});
		return l.map(s => new StudyHandler(s));
	}

	async countStudies(q:ReadStudiesQuery):Promise<number> {
		throw new Error("Method not implemented.");
	}

	async peekStudyByID(id:string):Promise<Readonly<IStudyPeek>> {
		throw new Error("Method not implemented.");
	}

	private _study:IGet<{ id:string },IStudy> = HTTPEndpoint.get("studies/:id");
	private _studies:IGet<{},IStudy[]> = HTTPEndpoint.get("studies");
	private _recentStudies:IGet<{ days:number },ReadRecentStudiesResult> = HTTPEndpoint.get("studies/recent?days=:days");

}