/** @format */

import { http } from "@/http";

export const updateSessionLabels = async (
	study: string,
	session: string,
	labels: string[],
): Promise<void> => {
	await http.patch(`studies/${study}/sessions/${session}/labels`, labels);
};
