import { NodeTemplate } from "@/components.edit/edit-graph";
import { AssetPointer } from "@/psychlab/types";

export type GalleryParams = {
	asset:AssetPointer<any>|null;
}

export const Gallery:NodeTemplate<GalleryParams> = {
	name: "Gallery",
	icon: "image-multiple text-black-50",
	parameters:{
		asset:null
	},
	ports: {
		start: {
			type: "input",
			label: {
				text: "",
				attributes: {
					x: 10
				}
			},
			x: -50
		},
		exit: {
			type: "output",
			label: {
				text: "",
				style: {
					"text-anchor": "end"
				},
				attributes: {
					x: -10
				}
			},
			x: 50
		}
	},
	template:`
	<svg style="overflow:visible" x="-50" y="-50">
		<rect rx="40" ry="40" width="100" height="100" strokeWidth="1" stroke="white" fill="#f07705"></rect>
	</svg>
	`
};