// vendor
import { defineComponent, ref, computed } from "@vue/composition-api";
// project
import * as TaskAPI from "@/services/api/studies/tasks";
import { sortByProperty } from "@/utils/array";
import { capitalize } from "@/utils/text";
import { Task, TaskStatus, TaskType } from "@/psychlab/types";
import * as AppModal from "@/AppModal";
import { ContextOption } from "@/components.generic/context-menu";
// local
import { default as CreateTaskModal } from "../vue/create-task-modal.vue";
import { default as TaskFilesModal } from "../vue/task-files-modal.vue";

import { useTranslate } from "@lang";
import { Date as DisplayDate, Duration } from "@ui";

type Modal = {
	open(v:any):void
}

export const ListStudyTasks = defineComponent({
	props:{
		studyId:{
			type:String,
			required:true
		}
	},
	components: {
		TaskFilesModal,
		CreateTaskModal,
		DisplayDate,
		Duration,
	},
	setup(props){

		// modal refs
		const taskFilesModal = ref<Modal>();
		const createTaskModal = ref<Modal>();
		// state
		const tasks = ref<Task[]>([]);
		const loaded = ref(false);

		const loading = ref(false);

		const { translate } = useTranslate();

		const displayTasks = computed(() => {
			let c = ([] as Task[]).concat(tasks.value);
			sortByProperty(c, "created", (d:string) => new Date(d));
			c.sort((a:any, b:any) => b.lastUpdated-a.lastUpdated);
			return c;
		});

		const taskTypes = ref<TaskType[]>([]);

		const addTask = (e:Event) => {
			const options = taskTypes.value.map(tt => {
				
				const o:ContextOption = {
					name:tt.name.split("-").map(w => capitalize(w)).join(" "),
					icon:"mdi.cube",
					fn:() => runTask(tt.name)
				};
				return o;
			});
			AppModal.context(e, options);
		};

		const deleteTask = async(task:Task) => {
			try{
				await TaskAPI.deleteTask(props.studyId, task._id);
				let i = tasks.value.findIndex(t => t._id === task._id);
				if(i > -1){
					tasks.value.splice(i, 1);
				}
			}
			catch(err){
				console.error(err);
			}
		}

		const loadData = async() => {
			loading.value = true;
			try{
				tasks.value = await TaskAPI.getTasks(props.studyId);
				taskTypes.value = await TaskAPI.getTaskTypes(props.studyId);
			}
			catch(err:any){
				console.error(err.message);
			}
			loading.value = false;
			loaded.value = true;
		};

		const refresh = () => {
			loadData();
		};

		const getStatusTheme = (status:TaskStatus) => {
			return statusConfig[status] ? statusConfig[status].theme : "";
		};

		const getStatusIcon = (status:TaskStatus) => {
			return statusConfig[status] ? statusConfig[status].icon : "";
		};

		const viewFiles = (task:Task) => {
			if(taskFilesModal.value){
				taskFilesModal.value.open(task);
			}
		};

		const openContext = (e:Event, task:Task) => {
			AppModal.context(e, [
				{
					name:translate("action.delete"),
					icon:"mdi.trash-can",
					fn(){
						AppModal.confirm({
							variant:"info",
							title:translate("studies.label.deleteTask"),
							description:translate("studies.message.deleteTask"),
							okText:translate("prompts.action.understood"),
							cancelText:translate("prompts.action.cancel")
						}, () => deleteTask(task))
					}
				}
			]);
		};

		const runTask = (type:string) => {
			if(createTaskModal.value){
				
				createTaskModal.value.open(async(result:{ args:any }) => {
					
					try {
						const task = await TaskAPI.createTask(props.studyId, {
							type,
							args:result.args
						});
						tasks.value.push(task);
					}
					catch(err:any){
						console.error(err.message);
					}
				});
			}
			else {
				console.error(`Unrecognized task type: ${type}`);
			}
		}

		loadData();

		return {
			displayTasks,
			tableFields,
			taskFilesModal,
			createTaskModal,
			loading,
			deleteTask,
			runTask,
			viewFiles,
			openContext,
			getStatusTheme,
			getStatusIcon,
			refresh,
			addTask,
			translate,
		};
	}

});

const statusConfig = {
	failed:{ icon:"mdi.close-circle", theme:"danger" },
	done:{ icon:"mdi.check-circle", theme:"success" },
	running:{ icon:"mdi.circle-slice-3", theme:"primary" },
	inactive:{ icon:"mdi.stop-circle", theme:"secondary" }
};

const formatNameCol = (v:string) => {
	return v.split("-").map(w => capitalize(w)).join(" ");
};

const tableFields = [
	{
		key:"status",
		sortable:true,
		thClass:"sm-col font-weight-light text-light noselect txt-80",
		tdClass:"align-middle pl-2",
		sortByFormatted:true,
		formatter(v:string){
			if(v === "failed"){ return -1; }
			else if(v === "done"){ return 1; }
			else if(v === "running"){ return 0; }
			return -2;
		}
	},
	{
		key:"type",
		label: "Type",
		sortable:true,
		thClass:"lg-cold font-weight-light text-light noselect txt-80",
		tdClass:"align-middle pr-3d px-5d txt-80",
		formatter:formatNameCol,
	},
	{
		key:"created",
		label:"Started",
		sortable:true,
		thClass:"xl-col font-weight-light text-light noselect txt-80",
		tdClass:"align-middle pr-3d px-5d txt-80"
	},
	{
		key:"updated",
		label:"Updated",
		sortable:true,
		thClass:"xl-col font-weight-light text-light noselect txt-80",
		tdClass:"align-middle pr-3d px-5d txt-80"
	},
	{
		key:"duration",
		sortable:true,
		thClass:"sm-col font-weight-light text-light noselect txt-80",
		tdClass:"align-middle txt-80"
	},
	
	{
		key:"files",
		label:"Results",
		sortable:false,
		thClass:"md-col font-weight-light txt-80 noselect",
		tdClass:"align-middle"
	},
	{
		key:"controls",
		label:"",
		sortable:false,
		thClass:"xxs-col font-weight-light noselect txt-80",
		tdClass:"align-middle"
	}
];