import { defineComponent, computed } from "@vue/composition-api";
import { DropdownInput } from "@ui";

const options = [
	{ value:"en", text:"English", },
	{ value:"is", text:"Íslenska", },
];

export const SelectLang = defineComponent({
	emits:[ "input" ],
	props:{
		value:{ type:String, }
	},
	setup(props, ctx){

		const val = computed({
			get:() => props.value,
			set:v => ctx.emit("input", v),
		});

		return { val, };
	},
	render(){
		return (
			<DropdownInput
			v-model={ this.val }
			options={ options }
			size="md"
			/>
		)
	}
});