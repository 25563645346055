







// vendor
import { defineComponent, computed } from "@vue/composition-api";
import { format as formatDate } from "date-fns";

export default defineComponent({

	props:{
		date:{
			type:[ String, Number ],
			required:true,
		},
		short:{
			type:Boolean,
			default:false,
		},
		xshort:{
			type:Boolean,
			default:false,
		},
		dayOnly:{
			type:Boolean,
			default:false,
		}
	},
	setup(props){
		const ddate = computed(() => {

			let s = props.short ? "EEE MMM. d y" : "EEEE LLLL d Y";
			if(props.xshort){ s = "MMM. d y"; }
			
			if(!props.dayOnly){
				s += " @ HH:mm";
			}

			return formatDate(new Date(props.date), s)
		});
		return {
			ddate,
		};
	}
});

