export class TouchInput {

	get deltaX(){
		const v = this._moveDelta.x;
		this._moveDelta.x = 0;
		return v;
	}

	get deltaY(){
		const v = this._moveDelta.y;
		this._moveDelta.y = 0;
		return v;
	}

	constructor(el:EventTarget){
		this._el = el;
		this._moveFn = this.positionHandler.bind(this) as EventListener;
		this._startFn = this.positionHandler.bind(this) as EventListener;
		this._endFn = this.end.bind(this) as EventListener;

		el.addEventListener("touchmove", this._moveFn, false);
		el.addEventListener("touchstart", this._startFn, false);
		el.addEventListener("touchend", this._endFn, false);
	}

	public dispose(){
		if(!this._el){ return; }
		this._el.removeEventListener("touchmove", this._moveFn);
		this._el.removeEventListener("touchstart", this._startFn);
		this._el.removeEventListener("touchend", this._endFn);
	}

	private _el:EventTarget|null = null;
	private _moveFn:EventListener;
	private _startFn:EventListener;
	private _endFn:EventListener;
	private _cachedPos = { x:0, y:0 };
	private _moveDelta = { x:0, y:0 };


	private positionHandler(e:any){

		const pos = { x:0, y:0 };

		if ((e.clientX) && (e.clientY)) {
			
			pos.x = e.clientX;
			pos.y = e.clientY;
		} else if (e.targetTouches && e.targetTouches.length > 0) {
			this._moveDelta.x = pos.x - this._cachedPos.x;
			this._moveDelta.y = pos.y - this._cachedPos.y;
			this._cachedPos = pos;
			e.preventDefault();
		}
	}

	private end(e:TouchEvent){
		console.log(this._moveDelta);
	}
}