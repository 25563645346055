import { defineComponent, computed, ref, watch, SetupContext } from "@vue/composition-api";
import { ViewAssetHistory } from "@/components.assets/view-asset-history";
import { AbsBox } from "@ui";

export default defineComponent({
	components:{
		ViewAssetHistory,
		AbsBox,
	},
	setup(_, context){
		
		const currentVersion = ref<string>(getQueryValue("version", context));

		const assetId = computed(() => {
			return context.root.$route.params["assetId"];
		});

		watch(currentVersion, (v, old) => {
			if(context.root.$route.query["version"] !== currentVersion.value){
				setQueryValue("version", currentVersion.value, context);
			}
		});

		watch(() => context.root.$route.query["version"], () => {
			if(context.root.$route.query["version"] !== currentVersion.value){
				currentVersion.value = getQueryValue("version", context);
			}
		});

		return {
			assetId,
			currentVersion,
		};
	},
	render(){
		return (
			<AbsBox>
				<ViewAssetHistory
				assetId={ this.assetId }
				v-model={ this.currentVersion }
				save-route
				/>
			</AbsBox>
		);
	}
});

// vendor

const setQueryValue = (k:string, v:any, context:SetupContext) => {
	const q:any = { ...context.root.$route.query };
	if(v !== undefined && v !== null){
		q[k] = v;
	}
	else {
		delete q[k];
	}
	context.root.$router.push({ query: q})
	.catch(() => {});
};

const getQueryValue = (k:string, context:SetupContext) => {
	return context.root.$route.query[k] as any;
};