/** @format */

type Dictionary<T> = { [k:string]:T }

export const json = (json: string, colors:Dictionary<string>) => {
	json = json
		.replace(/&/g, "&amp;")
		.replace(/</g, "&lt;")
		.replace(/>/g, "&gt;");
	json = json.replace(
		/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
		function (match) {
			let key = "number";
			if (/^"/.test(match)) {
				if (/:$/.test(match)) {
					key = "key";
				} else {
					key = "string";
				}
			} else if (/true|false/.test(match)) {
				key = "boolean";
			} else if (/null/.test(match)) {
				key = "null";
			}
			else if(/{/.test(match)){
				key = "brace"
				console.log("BRACE");
			}
			// console.log(key);
			const style = {
				color:colors[key]
			};
			return `<span style='${flattenStyle(style)}'>${match}</span>`;
		},
	);

	// curly braces
	return json.replace(/{|}|\[|\]|\,/g, (m) => {
		return `<span style='color:${colors["default"]}'>${m}</span>`;
	});
};

const flattenStyle = (ob:Dictionary<any>):string => {
	let rs = "";
	Object.keys(ob).forEach((k) => rs += `${k}:${ob[k]};`);
	return rs;
}