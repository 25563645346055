































// vendor
import {
	defineComponent,
	computed,
	ref,
	PropType,
} from "@vue/composition-api";
// project
import { ButtonBase } from "@ui";

export default defineComponent({
	emits: ["previous", "next", "save"],
	props: {
		activeStep: {
			type: Number,
			default: 0,
		},
		steps: {
			type: Number,
			default: 1,
		},
		buttonSize: {
			type: String as PropType<"sm" | "md" | "lg">,
			default: "md",
		},
		nextDisabled: {
			type: Boolean,
			default: false
		}
	},
	components: {
		ButtonBase,
	},
	setup(props, context) {
		const previous = () => {
			context.emit("previous");
		};

		const next = () => {
			context.emit("next");
		};

		const save = () => {
			context.emit("save");
		};

		return {
			previous,
			next,
			save,
		};
	},
});
