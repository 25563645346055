import { render, staticRenderFns } from "./study-description.vue?vue&type=template&id=db9e7c74&scoped=true&"
import script from "./study-description.vue?vue&type=script&lang=ts&"
export * from "./study-description.vue?vue&type=script&lang=ts&"
import style0 from "./study-description.vue?vue&type=style&index=0&id=db9e7c74&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db9e7c74",
  null
  
)

export default component.exports