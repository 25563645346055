import DebugTranslationsPage from "@pages/debug/translations";
import SplashScreenPage from "@pages/debug/splash-screen";
import DebugPanoramaVideoPage from "@pages/debug/video-panorama";

export const DebugTranslations = {
	path:"/debug/translations",
	name:"Debug.Translations",
	component:DebugTranslationsPage,
	meta:{
		title:"Translations",
		public:true,
		navHidden:true,
	}
};

export const DebugSplashScreen = {
	path:"/debug/splash",
	name:"debug-splash-screen",
	component:SplashScreenPage,
	meta:{
		title:"Splash Screen",
		public:true,
		navHidden:true,
	}
};

export const DebugPanorama = {
	path:"/debug/panorama/video",
	name:"debug-panorama-video",
	component:DebugPanoramaVideoPage,
	meta:{
		title:"Panorama Viewer",
		public:true,
		navHidden:true,
	}
};