import { PropMap } from "../utils/PropMap";
import { setDOMAttributes } from "../utils/DOM";
import { Dictionary } from "../utils/Dictionary";
import * as SnapSVG from "snapsvg";
declare var Snap: typeof SnapSVG

export class SnapShadow {

	constructor(targetGroup:SnapSVG.Paper){
		this._targetGroup = targetGroup;
		this.refresh();
	}

	public configure(ob:Dictionary<any>){
		this._props.configure(ob);
		this.regenerateShadow();
		this.refreshChildren();
		this.refreshAttributes();
	};

	public refresh(){
		if(this._outlineRoot){ this._outlineRoot.remove(); }
		this._outlineRoot = this._targetGroup.clone() as SnapSVG.Paper;
		
		this.regenerateShadow();
		this._outlineRoot.prependTo(this._targetGroup);
		this.refreshChildren();
		this.refreshAttributes();
	};

	private _targetGroup:SnapSVG.Paper;
	private _outlineRoot:SnapSVG.Paper|null = null;
	private _shadowFilter:SnapSVG.Element|null = null;

	private _props:PropMap = new PropMap({
		"opacity": {
			value: 0.3,
			validator: v => v <= 1 && v >= 0
		},
		"blur": {
			value: 0
		},
		"xOffset": {
			value: 2,
			validator: v => typeof(v) === "number"
		},
		"yOffset": {
			value: 2,
			validator: v => typeof(v) === "number"
		},
		"color": {
			value: "black",
			validator: v => typeof(v) === "string"
		},
		"offset": {
			proxyFor: [ "xOffset", "yOffset" ]
		}
	});

	private refreshChildren(){
		loopChildren(this._outlineRoot, (e:any) => {
			if(!e.node.style){
				return;
			}
			let currentFill = e.node.getAttribute("fill");
			if(currentFill === "rgba(0,0,0,0)"){
				e.node.style["display"] = "none";
			}
			e.attr({
				fill: undefined,
				stroke: undefined
			})
		});
	};

	private refreshAttributes(){
		if(!this._outlineRoot){ return; }
		this._outlineRoot.attr({
			fill: this._props.getValue("color"),
			filter: this._shadowFilter
		});
	};

	private regenerateShadow(){
		if(!this._shadowFilter || !this._outlineRoot){ return; }
		const xOffset = this._props.getValue("xOffset");
		const yOffset = this._props.getValue("yOffset");
		const blur = this._props.getValue("blur");
		const color = this._props.getValue("color");
		const opacity = this._props.getValue("opacity");
		this._shadowFilter = this._outlineRoot.filter(Snap.filter.shadow(xOffset, yOffset, blur, color, opacity));

		setDOMAttributes(this._shadowFilter.node, {
			filterUnits: "objectBoundingBox",
			x: "-50%",
			y: "-50%",
			width: "200%",
			height: "200%"
		});
	};
}

const loopChildren = (element:any, fn:Function) => {
	fn(element);
	var children = element.children();
	for(var i = 0; i < children.length; i++){
		loopChildren(children[i], fn);
	}
};