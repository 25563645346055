











import { defineComponent, computed, PropType } from "@vue/composition-api";
import { Icon } from "../icons";

export default defineComponent({
	emits:[ "click" ],
	props:{
		icon:{
			type:String,
			default:"",
		},
		variant:{
			type:String as PropType<"dark"|"light">,
			default:"light"
		},
		size:{
			type:String as PropType<"xs"|"sm"|"md"|"lg">,
			default:"md"
		}
	},
	components:{
		Icon
	},
	setup(props, ctx){
		
		const cls = computed(() => {
			const { variant, size } = props;
			return `v-${variant} s-${size}`;
		});

		return {
			cls,
			handleClick:(e:Event) => ctx.emit("click", e)
		};
	}
});

