import { defineComponent, PropType } from "@vue/composition-api";
import { ref, computed, watch } from "@vue/composition-api";
import * as StudyAPI from "@/services/api/studies";
import { useAssets } from "@/hooks/useAssets";
import { useTags } from "@/hooks/useTags";
import { Icon } from "@ui";
import { useTranslate } from "@lang";

type Tag = {
	_id:string;
};

enum Translations {
	Blueprints = "assets.label.blueprints",
	Elements = "assets.label.elements",
	Studies = "studies.label.studies",
}

export const TagInfo = defineComponent({

	props:{
		tag:{
			type:Object as PropType<Tag>,
			required:true,
		},
		refreshKey:{
			type:String,
			default:"",
		}
	},

	setup(props){

		const info = useInfo(props.tag._id);
		
		const { translate } = useTranslate();

		watch(() => props.refreshKey, info.refresh);

		return {
			translate,
			...info,
		};
	},
	render(){

		const fields = [
			{ v:this.blueprints, l:Translations.Blueprints },
			{ v:this.elements, l:Translations.Elements },
			{ v:this.studies, l:Translations.Studies },
		];

		const ditems = fields.map((f, fi) => {
			return (
				<span>
					<span class="mr-1">{ f.v }</span>
					<span>{ this.translate(f.l) }</span>
					{ fi < fields.length - 1 ? <Icon name="rhombus-medium" class="mx-1"/> : <span/> }
				</span>
			);
		})

		return (
			<small
			style="opacity:0.6"
			class="text-light font-wxeight-light mb-auto noglow">
				{ditems}
			</small>
		);
	}

});

const useInfo = (tagId:string) => {

	const { assets } = useAssets();

	const { hasTag } = useTags();

	const studyCount = ref(0);

	const blueprintAssets = computed(() => {
		return assets.value.filter(a => a.type === "Graph" && hasTag(tagId, a._id));
	});

	const elements = computed(() => {
		return assets.value.filter(a => a.type !== "Graph" && hasTag(tagId, a._id));
	});

	const blueprintCount = computed(() => blueprintAssets.value.length);

	const elementCount = computed(() => elements.value.length);

	const refresh = async () => {

		if(blueprintCount.value === 0){
			studyCount.value = 0;
			return;
		}

		const q = {
			"target.study[in]":blueprintAssets.value.map(bp => bp._id).join(","),
			page:1,
			limit:1,
		};

		try {
			const { count } = await StudyAPI.getStudiesPaginated(q);
			studyCount.value = count;
		}
		catch{}
	};

	refresh();

	return {
		blueprints:blueprintCount,
		elements:elementCount,
		studies:studyCount,
		refresh,
	};


};