












// vendor
import { defineComponent, computed, ref, PropType } from "@vue/composition-api";
import { ColorVariant } from "../bootstrap/ColorVariant";

export default defineComponent({
	props:{
		size:{
			type:Number as PropType<1|2|3|4|5|6>,
			default:1,
		},
		text:{
			type:String,
			required:false,
		},
		center:{
			type:Boolean,
			default:false,
		},
		nats:{
			type:Boolean,
			default:false,
		},
		v:{
			type:String as PropType<ColorVariant>,
			default:"light",
		}
	},
	components:{
	},
	setup(props){

		const tag = computed(() => {
			return `h${props.size}`;
		});

		const cls = computed(() => {
			const nats = `${props.nats ? 'font-nats' : ''}`;
			const center = `${props.center ? 'text-center' : ''}`;
			const variant = `text-${props.v}`;
			return `${variant} ${center} ${nats}`
		});

		return {
			tag,
			cls,
		};
	}
});

