import { PropType } from "@vue/composition-api";

export type NodeData<T>= {
	x:number,
	y:number,
	type:string,
	parameters:T
}

export function getProps<T>(){
	return {
		nodeId:{
			type:String,
			required:true
		},
		node:{
			type:Object as PropType<NodeData<T>>,
			required:true
		},
		showBack:{
			type:Boolean,
			default:false
		},
		isLast:{
			type:Boolean,
			default:false
		}
	}
};