











































































































// vendor
import {
	defineComponent,
	computed,
	ref,
	PropType,
	onMounted,
} from "@vue/composition-api";
// project
import { generateGUID } from "@/utils/guid";
import { range } from "@/utils/random";
// local
import ChartLegend from "./legend.vue";

const percentToCoordinates = (p: number) => {
	const x = Math.cos(2 * Math.PI * p);
	const y = Math.sin(2 * Math.PI * p);
	return [x, y];
};

const getPathData = (percent: number, offset: number = 0) => {
	const sx = percentToCoordinates(offset)[0];
	const sy = percentToCoordinates(offset)[1];
	const ex = percentToCoordinates(offset + percent)[0];
	const ey = percentToCoordinates(offset + percent)[1];
	const arcFlag = percent > 0.5 ? 1 : 0;
	return [
		`M ${sx} ${sy}`,
		`A 1 1 0 ${arcFlag} 1 ${ex} ${ey}`,
		`L 0 0`,
		`L ${sx} ${sy}`,
	].join(" ");
};

const getRangeColor = (t: number) => {
	const v = 100 + t * 120;
	return `rgb(${0}, ${v}, ${v})`;
};

export default defineComponent({
	props: {
		title: {
			type: String,
			default: "",
		},
		labels: {
			type: Array as PropType<string[]>,
			default: () => [],
		},
		values: {
			type: Array as PropType<number[]>,
			default: () => [],
		},
		colors: {
			type: Array as PropType<string[]>,
			default: () => [],
		},
		sort: {
			type: String as PropType<"label"|"none"|"value">,
			default: "label",
		},
	},
	components: {
		ChartLegend,
	},
	setup(props) {
		const hovered = ref<string>();

		const hoveredItem = computed(() => {
			const i = displayItems.value.findIndex(
				(it) => it.id === hovered.value,
			);
			if (i < 0) {
				return undefined;
			}
			return {
				item: displayItems.value[i],
				index: i,
			};
		});

		const displayItems = computed(() => {

			if(props.values.length === 0){
				return [];
			}

			const total = props.values.reduce((a, b) => a + b, 0);

			if(total === 0){
				return [];
			}

			let to = 0;
		

			const ls = props.values.map((v, i) => {
				const t = total > 0 ? v / total : 0;

				const offset = to;
				const color =
					i < props.colors.length
						? props.colors[i]
						: getRangeColor(i / (props.values.length - 1));
				const label =
					i < props.labels.length
						? props.labels[i]
						: i.toString();
				to += t;

				
				return {
					id: `${generateGUID()}`,
					t,
					offset,
					color,
					label,
					value: v,
				};
			});

			if(props.sort === "label"){
				ls.sort((a, b) => a.label.localeCompare(b.label));
			}

			if(props.sort === "value"){
				ls.sort((a, b) => a.value - b.value);
			}

			return ls;
		});

		return {
			hoveredItem,
			displayItems,
			getPathData,
			hovered,
		};
	},
});
