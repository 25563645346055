















import { defineComponent, computed, PropType, watch, ref } from "@vue/composition-api";
import * as SessionAPI from "@/services/api/sessions";
import * as ItemComponents from "./items";
import { ResponseData } from "./ResponseData";
import { FormItem, IFormTheme } from "@psychlab/types/form";
import { RenderMarkdown, AlertBox } from "@ui";

type Dictionary<T> = { [k:string]:T }; 

type ItemComponent = {
	isValid?:() => boolean,
	getChannels?:(prefix:string) => ResponseData<any>[]
};

export default defineComponent({
	emits:[ "input", "touch" ],
	props:{
		cacheKey:{
			type:String,
			required:true
		},
		value:{
			type:null
		},
		config:{
			type:Object as PropType<FormItem<any>>,
			required:true
		},
		cache:{
			type:Object as PropType<Dictionary<any>>,
			required:true
		},
		theme:{
			type:Object as PropType<IFormTheme>,
			default:() => {},
		}
	},
	components:{
		AlertBox,
		RenderMarkdown,
	},
	setup(props, context){

		const component = ref<ItemComponent>();
		
		const sessionId = computed(() => context.root.$route.params["sessionId"]);

		const val = computed({
			get:() => props.value,
			set:v => context.emit("input", v)
		});

		const name = computed(() => props.config.name);
		const type = computed(() => props.config.type);
		const hideName = computed(() => props.config.hideName);
		const itemComponent = computed(() => (ItemComponents as any)[props.config.type]);

		const body = computed(() => props.config.body);

		watch(() => props.value, (v) => {
			if(sessionId.value){
				SessionAPI.cacheSessionValue(sessionId.value, props.cacheKey, v);
			}
			setTimeout(() => {
				context.emit("touch", { id:props.config.id });
			},1);
		});

		const getChannels = (prefix:string):ResponseData<any>[] => {
			const p = component.value;
			if(!p){ return []; }
			const c = p.getChannels ? p.getChannels(`${prefix}/${props.config.id}`) : [];
			return c;
		};

		const isValid = () => {
			if(props.config.optional){ return true; }
			let c = component.value;
			if(!c){ return false; }
			return c.isValid ? c.isValid() : true;
		};

		return {
			name,
			body,
			type,
			hideName,
			component,
			val,
			itemComponent,
			isValid,
			getChannels
		};
	}
});
