import { defineComponent } from "@vue/composition-api";
import { useTranslate } from "@lang";
import { ConfirmBox } from "./ConfirmBox";

enum Translations {
	Continue = "session.action.continue",
	SessionInProgress = "session.message.sessionInProgress",
}

export const Resume = defineComponent({
	emits:[ "resume" ],
	setup(){
		const { translate } = useTranslate();
		return { translate };
	},
	render(){

		const title = this.translate(Translations.SessionInProgress);
		const btext = this.translate(Translations.Continue);

		return (
			<ConfirmBox
			v-on:confirm={ () => this.$emit("resume") }
			title={ title }
			buttonText={ btext }
			variant={ "success" }
			/>
		);
	}
});