import { getDeepProperty } from "@utils/object"

type AssetTypes = "Form"|"Graph"|"Gallery";

type Dictionary<T> = { [k:string]:T }
type DataFn = (data:{ [k:string]:any }) => void;

export interface IDataProcessor {
	onBeforeSave?:DataFn;
	onBeforeUse?:DataFn;
};

const extractFormThemes = (data:Dictionary<any>):Dictionary<any>[] => {
	try {
		const profiles:any[] = getDeepProperty(data, "themeProfiles", []);
		const themes = profiles.map(p => p["theme"]);
		return themes;
	}
	catch{}
	return [];
};

const formProcessor:IDataProcessor = {
	onBeforeUse(data){
		extractFormThemes(data).forEach(insertDotKeys);
	},
	onBeforeSave(data){
		extractFormThemes(data).forEach(removeDotKeys);
	},
};

export const getProcessor = (type:AssetTypes):IDataProcessor => {
	switch(type){
		case "Form": return formProcessor;
	}
	return {};
};

const removeDotKeys = (ob:Dictionary<any>) => replaceKeyChar(ob, ".", "#");

const insertDotKeys = (ob:Dictionary<any>) => replaceKeyChar(ob, "#", ".");

const replaceKeyChar = (ob:Dictionary<any>, oc:string, nc:string) => {
	const keys = Object.keys(ob).filter(k => k.includes(oc));
	keys.forEach(k => {
		const nk = k.replaceAll(oc, nc);
		ob[nk] = ob[k];
		delete ob[k];
	});
};