

















// vendor
import { defineComponent, ref, computed } from "@vue/composition-api";
// project
import { pascalToSentenceCase } from "@/utils/text";
// local
// import * as ArgEditors from "./arg-editors";

const displayOptions:any[] = [
	{
		key:"refresh",
		label:"Refresh",
		variant:"dark",
		icon: "mdi.refresh",
	},
	{
		key:"edit",
		label:"Edit",
		variant:"info",
		icon: "mdi.pencil",
	},
	{
		key:"delete",
		label:"Delete",
		variant:"danger",
		icon: "mdi.trash-can",
	}
];

type ContextMenu = {
	open(v: any): void;
};

export default defineComponent({
	setup(props, context) {
		const contextMenu = ref<ContextMenu>();

		const select = (key: string) => {
			context.emit(key);
		};

		const open = (e: any) => {
			const ctx = contextMenu.value;
			if (!ctx) {
				return;
			}
			setTimeout(() => ctx.open(e), 0);
		};

		return {
			displayOptions,
			contextMenu,
			pascalToSentenceCase,
			select,
			open,
		};
	},
});
