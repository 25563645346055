





































































































import { defineComponent, computed, ref, PropType } from "@vue/composition-api";
import { NodeItem } from "./ts/DisplayGraph";
import { useTranslate } from "@lang";

type Dictionary<T> = { [k:string]:T }; 

const config = {
	itemRadius:7,
	itemPadding:4,
	colors:{
		"Form":"#9471fe",
		"Select":"white",
		"Scene":"#0a9931",
		"CognitiveTest":"#00bdd6",
		"Gallery":"#f07705",
		// "Start":"#1d2124",
		"Start":"white",
		"empty":"black",
	} as Dictionary<string>,
	icons:{
		"Form":"mdi.file-document-edit text-dark",
		"Select":"mdi.arrow-decision mdi-rotate-180 text-dark",
		"Scene":"mdi.rotate-3d text-dark",
		"CognitiveTest":"mdi.head-cog mdi-flip-h text-dark",
		"Gallery":"mdi.image-multiple text-dark",
		"Start":"mdi.flash text-dark",
	} as Dictionary<string>
};

export default defineComponent({

	emits:[],
	props:{
		type:{
			type:String,
			default:"",
		},
		x:{
			type:Number,
			default:0,
		},
		y:{
			type:Number,
			default:0,
		},
		r:{
			type:Number,
			default:1,
		},
		items:{
			type:Array as PropType<NodeItem[]>,
			default:() => []
		}
	},
	components:{
	},
	setup(props, context){


		const { translate } = useTranslate();


		const icon = computed(() => {
			return config.icons[props.type] || "mdi.circle-outline";
		});

		const bgColor = computed(() => {
			return config.colors[props.type] || "mdi.circle-outline";
		});

		const displayItems = computed(() => {
			const iw = (config.itemRadius ) + (config.itemPadding);
			return props.items.map((item, index) => {
				const x = iw + (config.itemPadding + config.itemRadius * 2) * index;
				return {
					x,
					y: config.itemPadding + config.itemRadius,
					r:config.itemRadius,
					c:config.colors[item.type] || "black",
					empty:item.type === "empty",
					fn(){
						context.emit("click-item", item);
					},
					type:item.type
				};
			});
		});

		const drawerSize = computed(() => {
			if(props.items.length === 0){ return { x:0, y:0 }; }
			const xw = props.items.length * (config.itemRadius * 2);
			const xp = (props.items.length + 1) * config.itemPadding;
			const x = xw + xp;
			const yh = (config.itemRadius * 2)
			const yp = config.itemPadding * 2;
			const y = yh + yp;
			return { x, y };
		});


		const getTypeTooltip = (type:string) => {
			return `${translate(`assets.action.open${type}`)}`
		};


		const onClick = (e:any) => {
			context.emit("click", e);
		};

		return {
			icon,
			bgColor,
			displayItems,
			config,
			drawerSize,
			onClick,
			translate,
			getTypeTooltip,
		};
	}
});

