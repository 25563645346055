






































// vendor
import { defineComponent, PropType, computed } from "@vue/composition-api";
// local
import * as NodeInspectors from "./inspectors";

const embeddedTypes = [ "Scene" ];

type Template = {
	name:string
}

export default defineComponent({
	// emits:[ "open-node" ],
	props:{
		nodeId:{
			type:String,
			required:true
		},
		// nodeName:{
		// 	type:String,
		// },
		parameters:{
			type:Object as PropType<any>,
			required:true
		},
		template:{
			type:Object as PropType<Template>,
			required:true
		}
	},
	setup(props, context){

		const type = computed(() => props.template.name);
		const component = computed(() => (NodeInspectors as any)[type.value]);
		const canOpenEmbedded = computed(() => embeddedTypes.indexOf(type.value) > -1);

		// const name = computed({
		// 	get: () => props.nodeName || "",
		// 	set: (v:string) => {
		// 		context.emit("name-changed", v)
		// 	}
		// });

		// const openNode = () => context.emit("open-node", props.nodeId);

		return {
			type,
			// name,
			component,
			canOpenEmbedded,
			// openNode,
		};
	}
});

