
















// vendor
import { defineComponent, computed, PropType } from "@vue/composition-api";
// local
import { ToggleConfig } from "../../ts/Config";

export default defineComponent({
	emits: [
		"input"
	],
	props: {
		value: {
			type: Boolean,
		},
		config:{
			type: Object as PropType<ToggleConfig>,
			default:() => {},
		},
		size:{
			type: String,
			default: "md",
		},
		disabled:{
			type:Boolean,
			default:false,
		}
	},
	setup(props, context) {
		const val = computed({
			get: () => props.value,
			set: v => context.emit("input", v),
		});

		const labels = computed(() => {
			return [
				props.config.offLabel !== undefined ? props.config.offLabel : "Off",
				props.config.onLabel !== undefined ? props.config.onLabel : "On",
			];
		})

		const variant = computed(() => props.config.variant || "dark");
		
		const label = computed(() => (props.value ? labels.value[1] : labels.value[0]).toUpperCase());

		const toggleValue = () => val.value = !val.value;

		return {
			val,
			label,
			toggleValue,
			variant,
		};
	},
});
