// vendor
import { ref, watch, Ref } from "@vue/composition-api";

export const useLazyValue = <T>(delay:number, value:Ref<T>) => {

	const lastEdit = ref(0);

	const lazyValue:Ref<T> = ref<T>(value.value) as any;

	const applyLazy = () => {
		setTimeout(() => {
			if(Date.now() - lastEdit.value > delay){
				lazyValue.value = value.value;
			}
			else {
				applyLazy();
			}
		}, delay);
	};

	const touch = () => {
		lastEdit.value = Date.now();
		applyLazy();
	};

	watch(value, touch, {
		deep:true,
	});

	return {
		lazyValue,
	};
	
};