
export const parseBinding = (s:string) => {
	const m = s.match(getBindingRegex());

	if(!m || !m.groups){
		throw new Error("Error parsing binding");
	}
	
	return {
		from:m.groups["from"],
		to:m.groups["to"]
	}
};


const getBindingRegex = () => `${getPartial("from")}->${getPartial("to")}`;
const getPartial = (name:string) => `(?<${name}>[a-zA-Z0-9]+)`;