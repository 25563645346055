













































































































































































// vendor
import { defineComponent, computed, ref, PropType, set } from "@vue/composition-api";
// project
import { GalleryFeedback } from "@/psychlab/types/gallery";
// import { EditText, IEditTextModal } from "@/components.generic/utility-modal";
import * as AppModal from "@/AppModal";
import { Toggle } from "@/components.generic/scriptable-form";
import { useTranslate } from "@lang";

// import { Toggle } from "./form";

export default defineComponent({

	emits:[],
	props:{
		data:{
			type:Object as PropType<GalleryFeedback>,
			required:true,
		},
		editMinLabel:{
			type:Boolean,
			default:false,
		},
		editMaxLabel:{
			type:Boolean,
			default:false,
		}
	},
	components:{
		// EditText,
		Toggle,
	},
	setup(props){
		
		// const editTextModal = ref<IEditTextModal>();

		const {
			translate,
		} = useTranslate();

		const optional = computed({
			get:() => Boolean(props.data.optional),
			set:(v) => set(props.data, "optional", v),
		});

		const perItem = computed({
			get:() => Boolean(props.data.perItem),
			set:(v) => set(props.data, "perItem", v),
		});

		const label = computed(() => {
			return props.data.label || `(${translate("label.untitled")})`;
		});

		const minLabel = computed(() => {
			const text = props.data.minLabel || "";
			return {
				text,
				tooltip:{
					title:text,
					interactive:false,
					animation:false,
					delay:0,
					placement:"left",
				}
			};
		});
		const maxLabel = computed(() => {
			const text = props.data.maxLabel || "";
			return {
				text,
				tooltip:{
					title:text,
					interactive:false,
					animation:false,
					delay:0,
					placement:"right",
				}
			};
		});

		const columns = computed(() => {
			return props.data.scale.map((colLabel, i) => {
				const key = "c" + i.toString();
				return {
					key,
					label:colLabel,
					rename(){
						setText("Column", colLabel, v => set(props.data.scale, i, v));
					},
					delete(){
						props.data.scale.splice(i, 1);
					},
					tooltip:{
						title:colLabel,
						interactive:false,
						animation:false,
						delay:0,
					}
				}
			});
		});

		const setMinLabel = () => {
			setText("Min Label", props.data.minLabel || "", v => set(props.data, "minLabel", v));
		};

		const setMaxLabel = () => {
			setText("Max Label", props.data.maxLabel || "", v => set(props.data, "maxLabel", v));
		};

		const addColumn = () => {
			props.data.scale.push("");
		};

		const setText = (title:string, v:string, fn:(nv:string) => void) => {
			// if(!editTextModal.value){ return; }
			AppModal.editText({
				title,
				value:v,
				validator(){
					return true;
				}
			}, fn);
		};

		const setLabel = () => {
			// if(!editTextModal.value){ return; }
			setText("Set Label", props.data.label, v => props.data.label = v);
		};

		return {
			// editTextModal,
			label,
			columns,
			setLabel,
			minLabel,
			maxLabel,
			addColumn,
			setMinLabel,
			setMaxLabel,
			translate,
			optional,
			perItem,
		};
	}
});

