// vendor
import { AxiosInstance } from "axios";
// project
import { http } from "@/http";
import { stringifyQuery } from "@/utils/http";
const HTTP:AxiosInstance = http;

export const rebuildDataChannels = async (query = {}):Promise<void> => {
	let route = `ops/studies/rebuild-channels${stringifyQuery(query)}`;
	await HTTP.post(route);
};