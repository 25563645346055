import { HTTPEndpoint, IPost } from "@http";
import { IAuthProvider } from "./interfaces";
import { EmailAuthQuery, EmailAuthResult } from "./queries";

export class UserAuthProvider implements IAuthProvider {
	
	async authenticateWithEmail(q:EmailAuthQuery):Promise<EmailAuthResult> {
		return await this._emailLogin.send({}, q);
	}

	private _emailLogin:IPost<{}, EmailAuthQuery, EmailAuthResult> = HTTPEndpoint.post("auth/users/login");

}