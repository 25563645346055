import { Ref } from "@vue/composition-api";
import { useAppModals } from "@/hooks/useAppModals";
import * as UM from "@/components.generic/utility-modal";
import { ContextOption, ContextConfig } from "@ui";
import { StudyWizardConfig, StudyWizardHandler } from "@components/studies";
import { CreateAssetConfig, CreateAssetHandler } from "@/components.assets/create-asset-wizard";
import { ModalConfig as FindAssetConfig, ResultFn as FindAssetHandler } from "@/components.assets/browse-asset-modal";
import { TagAssetModal, ITagAssetModal } from "@components/tags";

export type { ContextOption }

type EditTextConfig = Partial<UM.EditTextConfig>;

export const editText = (c:EditTextConfig, fn:UM.EditTextHandler) => {
	const { editText } = useAppModals();
	if(!editText.value){
		return;
	}
	const cfg:UM.EditTextConfig = {
		value:c.value || "",
		...c,
	}
	editText.value.open(cfg, fn);
};

export const editColor = (c:UM.EditColorConfig, fn:UM.EditColorHandler) => {
	const { editColor } = useAppModals();
	if(!editColor.value){ return; }
	editColor.value.open(c, fn);
};

export const confirm = (c:UM.ConfirmConfig, fn:UM.ConfirmHandler) => {
	const { confirm } = useAppModals();
	if(!confirm.value){ return; }
	confirm.value.open(c, fn);
};

export const context = (event:Event, options:ContextOption[], c?:ContextConfig) => {
	const { context } = useAppModals();
	if(!context.value){ return; }
	context.value.open(event, options, c);
};

export const createStudy = (c:StudyWizardConfig, fn:StudyWizardHandler) => {
	const { createStudy } = useAppModals();
	if(!createStudy.value){ return; }
	createStudy.value.open(c, fn);
};

export const findIcon = (c:UM.FindIconConfig, fn:UM.FindIconHandler) => {
	const { findIcon } = useAppModals();
	if(!findIcon.value){ return; }
	findIcon.value.open(c, fn);
};

export const uploadFile = (c:UM.UploadFileConfig, fn:UM.UploadFileHandler) => {
	const { uploadFile } = useAppModals();
	if(!uploadFile.value){ return; }
	uploadFile.value.open(c, fn);
};

export const findAsset = (c:FindAssetConfig, fn:FindAssetHandler) => {
	const { findAsset } = useAppModals();
	if(!findAsset.value){ return; }
	findAsset.value.open(c, fn);
};

export const createAsset = (c:CreateAssetConfig, fn:CreateAssetHandler) => {
	open(useAppModals().createAsset, c, fn);
};

export const renderRaw = (c:UM.RenderRawConfig) => {
	openNoHandler(useAppModals().renderRaw, c);
};

export const editAssetTags = (aid:string) => {
	openNoHandler(useAppModals().tagAsset, aid);
};

const open = <CT, HT>(m:Ref<{ open:(c:CT, fn:HT) => void }|undefined>, c:CT, fn:HT) => {
	if(!m.value){ return; }
	m.value.open(c, fn);
};

const openNoHandler = <CT, HT>(m:Ref<{ open:(c:CT) => void }|undefined>, c:CT) => {
	if(!m.value){ return; }
	m.value.open(c);
};