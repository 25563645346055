import { WizardContext } from "../types/WizardContext";
import * as Utils from "./utils";

export const CountChannelValue = {
	name: "CountChannelValue",
	description: {
		en:"Counts the occurrences of an option in data.",
		is:"Telur hve oft ákveðinn svarmöguleiki hefur verið valinn.",
	},
	isUsable(ctx:WizardContext){
		return ctx.channels.findIndex(Utils.isCountableChannel) > -1;
	},
	parameters: {
		channel: {
			type:"string",
			title:{
				en:"Field",
				is:"Dálkur",
			},
			drawer(context:WizardContext){
				return {
					type:"dropdown",
					options:Utils.getChannelDropdownOptions(context.channels.filter(Utils.isCountableChannel))
				}
			},
			default:null
		},
		value:{
			type:"any",
			title:{
				en:"Value",
				is:"Gildi",
			},
			dependencies:[ "channel" ],
			drawer(context:WizardContext){

				const channelKey = context.dataArgs["channel"];
				if(!channelKey){ return null; }
				const channel = context.channels.find(c => c.key === channelKey);
				if(!channel){ return null; }
				return Utils.getChannelDrawer(channel);
			}
		}
	},
	output:{
		n:{
			type:"number"
		}
	}
};