import { RouteConfig } from "vue-router";
import { ManageAsset as ManageAssetComponent } from "@/components.assets/manage-asset";
import ViewAssetPage from "@pages/assets/[id]/index";
import { EditAsset } from "@/components.edit/edit-asset";
import AssetHistoryPage from "@pages/assets/[id]/history";

const viewKey = "manage-asset-$assetId";

export const ManageAsset:RouteConfig  = {
	path: "/assets/:assetId",
	component: ManageAssetComponent,
	meta: {
		title: "Asset"
	},
	children:[
		{
			path: "",
			name:"view-asset",
			component:ViewAssetPage,
			meta: {
				viewKey,
			},
		},
		{
			path: "edit",
			name:"asset.edit",
			component:EditAsset,
			meta: {
				viewKey,
			},
		},
		{
			path: "history",
			name:"asset-history",
			component:AssetHistoryPage,
			meta: {
				viewKey,
			},
		},
	],
};