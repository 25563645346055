import { computed, Ref, ComputedRef } from "@vue/composition-api";
import { dictionary } from "../dictionaries";

type LangItem = {
	key:string;
	label:string;
	type:string;
	group:string;
	translations:{ [k:string]:string }
};

type LangGroup = {
	key:string;
	label:string;
	items:LangItem[];
	empties:number;
};


const getKeyGroup = (path:string[]) => {
	return path.length === 3 ? path[0] : ".global";
};

const getKeyType = (path:string[]) => {
	return path.length === 3 ? path[1] : path[0];
};

const getKeyLabel = (path:string[]) => {
	let type = getKeyType(path);
	let name = path[path.length - 1];
	return `${type}.${name}`
};

const hasEmpties = (it:LangItem) => {
	return Object.keys(it.translations).find(k => !it.translations[k]);
};

const countEmpties = (items:LangItem[]) => {
	return items.reduce((sum, it) => sum + (hasEmpties(it) ? 1 : 0), 0);
};


const replacements:{[k:string]:string} = {
	"á":"a",
	"ú":"u",
	"ö":"o",
	"í":"i",
};


const normalizeSearchString = (s:string) => {
	let x = s.toLocaleLowerCase();
	Object.keys(replacements)
	.forEach(k => x = x.replace(k, replacements[k]))
	return x;
};


let langKeys:string[] = [];





type SearchInfo = {
	keywords:string[];
}

const searchKeywords:{ [k:string]:SearchInfo } = {};


let hasInit = false;



const init = () => {
	Object.keys(dictionary)
	.forEach(k => {

		const entry = dictionary[k];
		const keywords:string[] = [
			...normalizeSearchString(k).split("."),
		];
		Object.keys(entry).forEach(tk => {
			keywords.push(normalizeSearchString(((entry as any)[tk] as string)));
		});
		searchKeywords[k] = {
			keywords,
		};

	});


	Object.keys(dictionary)
	.forEach(k => {
		Object.keys(dictionary[k]).forEach(tk => langKeys.push(tk))
	});


	langKeys = [ ...(new Set(langKeys)) ];

}



type Config = {
	filterEmpties:Ref<boolean>|ComputedRef<boolean>;
	search:Ref<string>|ComputedRef<string>;
};

const matchesSearch = (key:string, s:string) => {
	if(s.length < 2){ return true; }
	return searchKeywords[key].keywords.findIndex(kw => kw.includes(s)) > -1;
};

export const useDebug = (config:Config) => {

	if(!hasInit){
		init();
	}

	// todo: filter by empty
	
	const { filterEmpties, search } = config;

	const allItems = computed<LangItem[]>(() => {


		
		return Object.keys(dictionary).map(k => {

			const p = k.split(".");
			const group = getKeyGroup(p);
			const type = getKeyType(p);
			const label = getKeyLabel(p);

			return {
				key:k,
				label,
				group,
				type,
				translations:dictionary[k] as any,
			}
		});
	});
	
	const items = computed<LangItem[]>(() => {
		let x =  allItems.value;

		if(filterEmpties.value){
			x = x.filter(it => hasEmpties(it));
		}

		if(search.value.length > 2){
			const s = normalizeSearchString(search.value);
			x = x.filter(it => matchesSearch(it.key, s));
		}

		return x;
	});
	

	const groups = computed<LangGroup[]>(() => {
		const names = [ ...(new Set(items.value.map(it => it.group))) ];
		names.sort((a,b) => a.localeCompare(b));

		return names.map(name => {

			const its = items.value.filter(it => it.group === name);
			
			// const label = camelToSentenceCase(name);
			const label = name;

			return {
				key:name,
				label,
				items:its,
				empties:countEmpties(its),
			};
		});
	});

	return {
		langKeys,
		items,
		groups,
		hasEmpties,
	};
}