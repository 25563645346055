import { LoginForm } from "@components/auth";
import { defineComponent } from "@vue/composition-api";
import { Container } from "@ui";

const LoginPage = defineComponent({
	render(){
		return (
			<FormContainer class="mt-3">
				<LoginForm/>
			</FormContainer>
		);
	},
});

const FormContainer = defineComponent({
	render(){
		return (
			<Container>
				<div class="row">
					<div class="col-md-6 offset-md-3">
						{this.$slots.default}
					</div>
				</div>
			</Container>
		);
	}
});

export default LoginPage;