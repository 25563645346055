import { http } from "@/http";
import { AxiosInstance } from "axios";
import { Asset, AssetVersion } from "@/psychlab/types";
import { useAssets } from "@/hooks/useAssets";

type Dictionary<T> = { [k:string]:T };

const HTTP:AxiosInstance = http;

export interface VersionPayload {
	base?:string,
	data:any
}

export const getAssets = async (query?:Dictionary<any>):Promise<Asset[]> => {
	const { data } = await HTTP.get(`assets${query ? buildQuery(query) : ""}`);
	return data;
};

export const createAsset = async (type:string, name:string):Promise<Asset> => {
	const { data } = await HTTP.post("assets", { type, name });
	return data;
};

export const deleteAsset = async (objectId:string) => {
	await HTTP.delete(`assets/${objectId}`);
};

export const getAsset = async (objectId:string, token?:string):Promise<Asset> => {
	let { data } = await HTTP.get(`assets/${objectId}`);
	return data;
};

export const setAssetName = async (assetId:string, name:string) => {
	await HTTP.patch(`assets/${assetId}`, {
		name
	});
};

export const getAssetVersion = async (assetId:string, versionId:string, select?:string):Promise<AssetVersion<any>> => {
	let q = select ? `?select=${select}` : "";
	let { data } = await HTTP.get(`assets/${assetId}/versions/${versionId}${q}`);
	return data;
};

export const saveAssetVersion = async(assetId:string, payload:VersionPayload):Promise<AssetVersion<any>> => {
	const { data } = await HTTP.post(`assets/${assetId}/`, payload);
	const {
		refreshAsset,
	} = useAssets();
	refreshAsset(assetId);
	return data;
};

export const loadVersionList = async (assetId:string):Promise<AssetVersion<any>[]> => {
	let { data } = await HTTP.get(`assets/${assetId}/versions`);
	const l:AssetVersion<any>[] = data;
	l.sort((b,a) => (new Date(a.created)).getTime() - (new Date(b.created)).getTime());
	return l;
};

export const setAssetVersionName = async (assetId:string, versionId:string, name:string) => {
	await HTTP.patch(`assets/${assetId}/versions/${versionId}`, {
		name
	});
};

const buildQuery = (q:Dictionary<any>):string => {
	let rs = "";
	Object.keys(q).forEach((key, i) => {
		rs += `${i === 0 ? "?" : "&"}${key}=${q[key].toString()}`;
	});
	return rs;
}