import { http } from "@/http";
import { Tag, TaggedItem } from "@/psychlab/types/tags";

type TagProps = {
	name?:string;
	color?:string;
};



export const getTags = async():Promise<Tag[]> => {
	const { data } = await http.get(`tags`);
	return data;
};

export const getTaggedItems = async():Promise<TaggedItem[]> => {
	const { data } = await http.get(`tags`);
	return data;
};

export const getAllTaggedItems = async():Promise<TaggedItem[]> => {
	const { data } = await http.get(`tags/items`);
	return data;
};

export const createTag = async(args:TagProps):Promise<Tag> => {
	const { data } = await http.post(`tags`, args);
	return data;
};

export const deleteTag = async(id:string):Promise<void> => {
	await http.delete(`tags/${id}`);
};

export const editTag = async(id:string, args:TagProps):Promise<void> => {
	await http.patch(`tags/${id}`, args);
};


export const assignTag = async(id:string, item:string, type:"asset"|"study"):Promise<TaggedItem> => {
	const { data } = await http.post(`tags/${id}/items?type=${type}&item=${item}`);
	return data;
};

export const unassignTag = async(id:string, item:string):Promise<void> => {
	await http.delete(`tags/${id}/items?item=${item}`);
};
