
export const hexColorToRGB = (v:string) => {
	if(v[0] === "#"){
		v = v.substr(1, v.length - 1);
	}
	var parsed = v.match(/.{1,2}/g);
	if(!parsed){ return null; }
	return [
		parseInt(parsed[0], 16),
		parseInt(parsed[1], 16),
		parseInt(parsed[2], 16)
	]
};

export const getColorBrightnessRGB = (r:number, g:number, b:number) => {
	return Math.sqrt(
		r * r * .241 +
		g * g * .691 +
		b * b * .068
	) / 255;
};

export const getInverseColor = (v:string) => {
	const c = hexColorToRGB(v);
	if(!c){ return "black"; }
	const b = getColorBrightnessRGB(c[0], c[1], c[2]);
	if(b > 0.5){
		return "black";
	}
	return "white";
};