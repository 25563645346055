


























































































import { defineComponent, ref, computed, reactive } from "@vue/composition-api";

import { ReorderableList } from "@ui";
import { useTranslate } from "@lang";
import {
	isDropboxLink,
	isYouTubeLink,
	isVimeoLink,
} from "@utils/url";


export interface IEditVideoModal {
	open:(c:Config, fn:DoneFn) => void;
}

type Config = {
	url:string;
	width:number;
};

type Result = {
	url?:string;
	width?:number;
};

type DoneFn = (r:Result) => void;

type Modal = {
	hide():void
};

const getLinkInfo = (url:string) => {
	let type = "";

	const typeCheckers:{ [k:string]:(url:string) => boolean } = {
		"dropbox":isDropboxLink,
		"youtube":isYouTubeLink,
		"vimeo":isVimeoLink,
	};

	const ks = Object.keys(typeCheckers);

	for(let i = 0; i < ks.length; i++){
		if(typeCheckers[ks[i]](url)){
			type = ks[i];
			break;
		}
	}
	let icon = type ? `mdi.${type}` : "mdi.language-html5";
	return {
		type:type || "generic",
		icon,
	}
}

export default defineComponent({
	props:{
		title:{
			type:String,
			default:""
		}
	},
	components: {
		ReorderableList,
	},
	setup(){

		const { translate } = useTranslate();
		
		const modal = ref<Modal>();
		const onDone = ref<DoneFn>();
		const show = ref(false);

		const values = reactive({
			url:"",
			width:100,
		});

		const linkInfo = computed(() => getLinkInfo(values.url));

		const ok = () => {
			if(onDone.value){
				onDone.value(values);
			}
			
			if(modal.value){
				modal.value.hide();
			}
		};

		const hide = () => {
			onDone.value = undefined;
			show.value = false;
			values.url = "";
			values.width = 100;
		};

		const open = (c:Config, fn:DoneFn) => {
			onDone.value = fn;
			show.value = true;
			values.url = c.url;
			values.width = c.width;
		};

		const onUrlBlur = () => {
			// if(linkInfo.value.type === "dropbox"){
			// 	values.url = parseDropboxLink(values.url);
			// }
		};

		return {
			modal,
			onDone,
			show,
			values,
			linkInfo,
			ok,
			hide,
			open,
			translate,
			onUrlBlur,
		};
	}
});



