

































// vendor
import { defineComponent, computed, ref } from "@vue/composition-api";

export default defineComponent({

	emits:[],
	props:{
	},
	components:{
	},
	setup(props, context){
		
		const show = ref(false);

		const open = () => {
			show.value = true;
		};

		const close = () => {
			show.value = false;
		};

		const hide = () => {

		};

		return {
			show,
			open,
			hide,
			close,
		};
	}
});



