















import { defineComponent, PropType, computed, ref } from "@vue/composition-api";
import { ResponseData } from "../ResponseData";
import { Answer } from "@psychlab/types/form";
import { default as ErrorOverlay } from "./error-overlay.vue";
import { ErrorBox } from "@ui";
import { RenderAnswer } from "@form-ui";
import { getBaseProps } from "./getBaseProps";
import { useRouteTranslate } from "@lang";

export default defineComponent({

	emits:[ "input" ],
	props:{
		value:{
			type:String
		},
		...getBaseProps<Answer>(),
		config:{
			type:Object as PropType<Answer>,
			required:true
		}
	},
	components:{
		RenderAnswer,
		ErrorOverlay,
		ErrorBox
	},
	setup(props, context){

		const { translate } = useRouteTranslate(context);

		const pristine = ref(true);

		const val = computed({
			get:() => props.value,
			set:v => context.emit("input", v)
		});

		const state = computed(() => val.value !== undefined && val.value.length > 0);

		const args = computed(() => ({
			title:!props.config.hideName ? props.config.name : '',
			body:props.config.body,
			theme:props.theme,
		}));

		const isValid = () => {
			pristine.value = false;
			return Boolean(state.value);
		};

		const getChannels = (prefix:string):ResponseData<string>[] => {
			if(!val.value){ return []; }
			return [
				{
					key: prefix,
					data:val.value
				}
			];
		};

		const init = () => {
			if(val.value === undefined){ val.value = ""; }
		};

		init();

		return {
			pristine,
			state,
			val,
			isValid,
			args,
			getChannels,
			translate,
		};
		
	}
});
