









































// vendor
import { defineComponent, computed, ref, inject, PropType, set, del } from "@vue/composition-api";
// project
import { Gallery } from "@/psychlab/types/gallery";
// local
import { initGalleryFeedback } from "../ts/gallery-utils";
import { default as EditFeedbackScale } from "./edit-feedback-scale.vue";
import { default as InfoTooltip } from "./info-tooltip.vue";
import { useTranslate } from "@lang";

export default defineComponent({
	props:{
		data:{
			type:Object as PropType<Gallery>,
			required:true,
		}
	},
	components:{
		EditFeedbackScale,
		InfoTooltip,
	},
	setup(props){

		const {
			translate,
		} = useTranslate();

		const feedback = computed(() => props.data.feedback);

		const initFeedback = () => set(props.data, "feedback", initGalleryFeedback());

		const removeFeedback = () => del(props.data, "feedback");

		return {
			feedback,
			initFeedback,
			removeFeedback,
			translate,
		};
	}
});

