import { WizardContext } from "../types/WizardContext";
import * as Utils from "./utils";

export const FindMinChannelAverage = {
	name: "FindMinChannelAverage",
	description:{
		en:"Finds the numeric data channel with the lowest computed average in responses. Useful if all numeric fields are compatible with each other (linear scale rows osfr.).",
		is:"Finnur talnadálk með lægsta meðalgildi í svörum. Gagnlegt ef allir talnadálkar í rannsókn eru sambærilegir (raðir í línulegum skala etc.).",
	},
	isUsable(ctx:WizardContext){
		return Utils.hasNumericChannels(ctx);
	},
	parameters: {},
	output:{
		channelName:{
			type:"string"
		},
		avg:{
			type:"number"
		},
		n:{
			type:"number"
		},
		min:{
			type:"number"
		},
		max:{
			type:"number"
		},
		channel:{
			type:"string"
		}
	}
};