












import { defineComponent, computed, ref, PropType } from "@vue/composition-api";
import { Study } from "@psychlab/types";

export default defineComponent({

	emits:[],
	props:{
		study:{
			type: Object as PropType<Study>,
			required:true,
		}
	},
	components:{
	},
	setup(props){
		
		const authMode = computed(() => props.study.auth);
		

		return {
			authMode,
		};
	}
});

