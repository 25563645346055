













// vendor
import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
	emits:[
		"input",
	],
	props: {
		value: {
			type: String,
			default:"",
		},
		size:{
			type:String,
			default:"sm",
		},
	},
	setup(props, context) {

		const val = computed({
			get:() => {
				return props.value;
			},
			set:(v) => {
				if(v !== undefined && v.length === 0){
					context.emit("input", undefined);
					return;
				}
				context.emit("input", v);
			},
		});

		return {
			val,
		};
	},
});
