



















import { defineComponent, computed } from "@vue/composition-api";
import { AbsBox } from "@ui";
import { useTranslate } from "@lang";

export default defineComponent({
	emits: ["input"],
	props: {
		value: {
			type: Boolean,
			default: true,
		},
	},
	components:{
		AbsBox,
	},
	setup(props, context) {

		const { translate } = useTranslate();

		const val = computed({
			get() {
				return props.value;
			},
			set(v) {
				context.emit("input", v);
			},
		});

		const label = computed(() => {
			return translate(`label.${props.value ? "yes" : "no"}`);
		});

		const toggleValue = () => {
			val.value = !val.value;
		}

		return {
			val,
			label,
			toggleValue,
			translate,
		};
	},
});
