import {
	getUrlInfo,
	isYouTubeLink,
	parseVimeoLink,
	isDropboxLink,
	parseDropboxLink
} from "@utils/url";



type VideoEmbedConfig = {
	url:string;
	loop?:boolean;
	autoplay?:boolean;
	width?:number;
}


export const getDefaultVideoEmbed = (props:VideoEmbedConfig) => {
	const { loop, autoplay, width } = props;

	let url = props.url;
	
	if(isDropboxLink(url)){
		url = parseDropboxLink(url);
	}

	const args:any = { loop, autoplay, controls:true, preload:autoplay };
	const flags = [ "preload", "autoplay", "loop", "controls" ].filter(f => args[f]);
	const f = flags.join(" ");
	const cstyle = `width:100%;display:flex;`
	const vstyle = `cursor:pointer;width:${width || 100}%;margin:auto;border:0;`;
	return `
	<div style="${cstyle}">
		<video style="${vstyle}" src="${url}" ${f}>
	</div>
	`;
};



// https://developers.google.com/youtube/player_parameters
type YouTubeOptions = {
	hideControls?:boolean;
	width?:number;
};

export const getYoutubeEmbed = (url:string, options:YouTubeOptions = {}):string => {

	if(!isYouTubeLink(url)){ return ""; }

	const width = options.width || 100;

	const info = getUrlInfo(url);

	if(!info){ return ""; }

	const v = info.query["v"] || "";
	
	const id = typeof(v) === "string" ? v : v[0];

	const flags:any = {
		"modestbranding":"1",
		"iv_load_policy":"3"
	};

	if(options.hideControls){
		flags["controls"] = "0";
	}

	let qstring = "?" + Object.keys(flags).map(k => `${k}=${flags[k]}`).join("&");

	const embedUrl = `https://www.youtube.com/embed/${id}${qstring}`;

	const frame = getEmbedFrame({
		url:embedUrl,
		allow:[
			"accelerometer",
			"clipboard-write",
			"encrypted-media",
			"gyroscope",
			"picture-in-picture",
			"allowfullscreen"
		]
	});


	return `
	<div
	style="width:100%;display:flex;"
	>
		<div
		style="
		width:${width}%;
		margin:auto;
		position:relative;
		"
		>

			<div
			style="
			position:relative;
			width:100%;
			padding-top:56.25%;
			"
			>
				${frame}
			</div>
		</div>
	</div>
	`;

};


type VimeoOptioms = {
	width?:number;
};


export const getVimeoEmbed = (url:string, options:VimeoOptioms = {}):string => {
	
	const info = parseVimeoLink(url);

	if(!info){
		return "";
	}

	const width = options.width || 100;


	const id = info.id;

	const embedUrl = `https://player.vimeo.com/video/${id}?h=b45d22b86b&title=0&byline=0&portrait=0`;

	const frame = getEmbedFrame({
		url:embedUrl,
		allow:[
			"autoplay",
			"fullscreen",
			"picture-in-picture",
		]
	});



	return `
	<div
	style="width:100%;display:flex;"
	>
		<div
		style="
		width:${width}%;
		margin:auto;
		position:relative;
		"
		>

			<div
			style="
			position:relative;
			width:100%;
			padding-top:56.25%;
			"
			>
				${frame}
			</div>
		</div>
		<script src="https://player.vimeo.com/api/player.js"></script>
	</div>
	
	`;


};



const getEmbedFrame = (options:{
	url:string
	allow?:string[],
}) => {

	const {
		url,
	} = options;

	const fs = `
	width:100%;
	height:100%;
	top:0;
	left:0;
	position:absolute;
	border:0;
	`;

	const allow = (options.allow || []).join(" ;");

	return `<iframe src="${url}" style="${fs}" frameborder="0" allow="${allow}"></iframe>`;

}