
















































































































































































































































// vendor
import { defineComponent, ref, computed, onMounted } from "@vue/composition-api";
// project
import { generateGUID } from "@/utils/guid";

const isValidAbsoluteUrl = (s:string) => {
	var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
	'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
	'((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
	'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
	'(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
	'(\\#[-a-z\\d_]*)?$','i'); // fragment locator
	return !!pattern.test(s);
};

const isValidRelativeUrl = (s:string) => {
	var pattern = new RegExp('((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
	'((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
	'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
	'(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
	'(\\#[-a-z\\d_]*)?$','i'); // fragment locator
	return !!pattern.test(s);
};

const isValidUrl = (s:string) => {
	return isValidAbsoluteUrl(s) || isValidRelativeUrl(s);
};

type OpenConfig = {
	index:number,
	urls:string[],
	labels:any[]
}

export default defineComponent({
	props:{
	},
	setup(props, context){

		const show = ref(false);
		const modal = ref<any>(null);
		const config = ref<OpenConfig|null>(null);

		const elements = ref<HTMLElement[]>([]);

		const imgWrapper = ref<HTMLElement>();

		const index = ref(0);

		const wrapperId = computed(() => generateGUID());

		const labels = computed(() => {
			return config.value ? config.value.labels || [] : [];
		});

		const displayItems = computed(() => {
			if(!config.value){ return []; }
			let items = config.value.urls.map((url, i) => ({
				url,
				label: i < labels.value.length ? labels.value[i].toString() : ""
			}));
			items = items.filter(it => isValidUrl(it.url));
			return items;
		});

		const currentItem = computed(() => {
			if(index.value < 0 || index.value >= displayItems.value.length){
				return null;
			}
			return displayItems.value[index.value];
		});

		const close = () => {
			if(!modal.value){ return; }
			modal.value.hide();
		};

		const hide = () => {
			show.value = false;
			config.value = null;
		};

		const open = (c:OpenConfig) => {
			show.value = true;
			config.value = c;
			index.value = c.index;
		};

		const setIndex = (i:number, scroll:boolean = true) => {
			index.value = i;
			if(scroll){
				scrollToElement(elements.value[i]);
			}
		};

		const scrollToElement = (el:HTMLElement) => {
			const container = el.parentElement;
			if(!container || !el){ return; }
			var pos = el.offsetLeft;
			container.scrollLeft = pos;
		};

		onMounted(() => {
			const el = document.getElementById(wrapperId.value);
			if(!el){ return; }
			el.addEventListener("wheel", e => {
			el.scrollLeft  += Math.sign(e.deltaY) * 50 * 0.5;
			e.preventDefault();
			return false;
			}, { passive:false });
		});


		return {
			show,
			hide,
			open,
			modal,
			displayItems,
			currentItem,
			index,
			setIndex,
			close,
			imgWrapper,
			elements,
			wrapperId,
			
		};
	}
});
