import { Timer } from "./utils/Timer";
// import { SceneBehaviour } from "./_old/SceneBehaviour";
import { useApp, IApp } from "./hooks/useApp";

// let timer:Timer|null = null;

let app:IApp|null = null;


export const killApp = () => {
	if(app){ app.kill(); app = null; }
};

export const getApp = () => {
	if(!app){ app = useApp({}); }
	return app;
};


export class Time {
	static get time():number {
		return app ? app.timer.getTime() : 0;
		// return timer ? timer.getTime() : 0;
	}
	static get deltaTime():number {
		// return timer ? timer.getDelta() : 0;
		return app ? app.timer.getDelta() : 0;
	}
}


// export class App {

// 	static get instance():App{
// 		if(!this._instance){ this._instance = new App(); }
// 		return this._instance;
// 	}

// 	public registerBehaviour(b:SceneBehaviour){
// 		this._behaviours.push(b);
// 	}

// 	public kill(){
// 		cancelAnimationFrame(this._updateFrame);
// 		window.removeEventListener("resize", this._resizeFn);
// 		this._behaviours.forEach(b =>b.destroy());
// 		App._instance = null;
// 	}

// 	private static _instance:App|null;
// 	private _behaviours:SceneBehaviour[] = [];
// 	private _updateFrame:number = -1;
// 	private _resizeFn:() => void;

// 	private constructor(){
// 		timer = new Timer();
// 		this._resizeFn = this.onResize.bind(this);
// 		window.addEventListener("resize", this._resizeFn);
// 		this.onFrame(0);
// 	}

// 	private onFrame(time:number){
// 		if(!timer){ return; }
// 		timer.tick(time);
// 		this._behaviours.forEach(b => {
// 			if(b.enabled){
// 				b.update();
// 			}
// 		});
// 		this._updateFrame = requestAnimationFrame(this.onFrame.bind(this));
// 	}

// 	private onResize(){
// 		this._behaviours.forEach(b =>b.resize());
// 	}
// }
