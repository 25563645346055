export const stringifyQuery = (q:Dictionary<any>) => {
	let r = "";
	let i = 0;
	Object.keys(q).forEach((k) => {
		let v = q[k];
		if(v === undefined) { return; }
		let prefix = i === 0 ? "?" : "&";
		r += `${prefix}${k}=${v}`;
		i++;
	});
	return r;
};


type Dictionary<T> = { [k:string]:T }