import { defineComponent } from "@vue/composition-api";
import { CompanyLogo, ProductVersion, ProductName } from "@ui";

export const TitleHeader = defineComponent({
	render(){
		return (
			<TitleWrapper>
				<Heading>
					<HomeLink>
						<CompanyLogo style="width:0.7em;height:0.7em;" class="mr-1"/>
						<ProductName class="my-auto text-light"/>
					</HomeLink>
				</Heading>
				<ProductVersion class="ml-auto mb-auto text-light" style="opacity:0.8"/>
			</TitleWrapper>
		);
	}
});

const homeRoute = { name:"home" };

const HomeLink = defineComponent({
	render(){
		return (
			<router-link
			to={homeRoute}
			class="text-decoration-none"
			>
				{this.$slots.default}
			</router-link>
		);
	}
});


const Heading = defineComponent({
	render(){
		return (
			<h6 class="text-light m-auto" style="font-size:1.9rem;opacity:1" >
				{this.$slots.default}
			</h6>
		);
	}
});

const TitleWrapper = defineComponent({
	render(){
		return (
			<div class="d-flex w-100 h-100 flex-column">
				<div class="m-auto d-flex flex-column">
					{this.$slots.default}
				</div>
			</div>
		)
	}
});