import { IParams, IDelete } from "../interfaces";
import { HTTP } from "../HTTP";

export class Delete<PT extends IParams> extends HTTP implements IDelete<PT> {
	
	constructor(path:string){
		super(""); // todo: base path
		this._path = path;
	}

	async send(p:PT): Promise<void> {
		return await this.get(this._path, p);
	}
	
	private _path:string;
}