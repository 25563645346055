// vendor
import { defineComponent, ref, computed, inject, ComputedRef, provide, PropType } from "@vue/composition-api";
// project
import * as SessionAPI from "@/services/api/sessions";
import * as ObjUtils from "@utils/object";
import { Session, SessionLabel } from "@psychlab/types";
import { SavedChannel } from "@psychlab/types/session";
import { StudyContext } from "@/components.studies/manage-study";
import { StudyLabelSelect } from "@/components.studies/study-label-select";
import { default as DisplayChannels } from "../vue/display-channels.vue";
import { SessionContext } from "./SessionContext";


import { FoldoutCard, DisplayJSON } from "@ui";
import { useTranslate } from "@lang";

type Dictionary<T> = { [k:string]:T };

export const ViewSessionData = defineComponent({

	props:{
		sessionId:{
			type:String,
			required:true
		},
		labels: {
			type: Array as PropType<SessionLabel[]>,
			default:() => [],
		},
	},
	components:{
		DisplayJSON,
		FoldoutCard,
		DisplayChannels,
		StudyLabelSelect,
	},
	setup(props, context){

		const studyContext = inject<ComputedRef<StudyContext>>("studyContext");

		if(!studyContext){ throw Error("View Session: Study context missing!"); }

		const loading = ref(false);

		const { translate } = useTranslate();

		const {
			channels,
			blueprint,
			blueprintVersion,
		} = studyContext.value;

		const foldoutState = ref<Dictionary<boolean>>({
			requestHeaders:false,
			requestLog:false,
			dataChannels:true
		});

		const savedChannels = ref<SavedChannel[]>([]);
		const session = ref<Session>();

		provide<SessionContext>("sessionContext", {
			session,
			channels,
			savedChannels,
			blueprint,
			blueprintVersion,
		});

		const requestHeaders = computed(() => {
			return ObjUtils.getDeepProperty(session.value, "log.request.headers", null);
		});

		const requestLog = computed(() => {
			const l = ObjUtils.getDeepProperty(session.value, "log.request", null);
			if(!l){ return null; }
			const lc = JSON.parse(JSON.stringify(l));
			delete lc["headers"];
			return lc;
		});

		const toggleFoldout = (name:string) => {
			foldoutState.value[name] = !Boolean(foldoutState.value[name]);
		};

		const loadData = async() => {
			loading.value = true;
			try {
				session.value = await SessionAPI.loadSession(props.sessionId, true);
				savedChannels.value = await SessionAPI.loadSessionData(props.sessionId);
			}
			catch(err:any){
				console.log(err.message);
			}
			loading.value = false;
		};

		loadData();

		return {
			session,
			requestHeaders,
			requestLog,
			foldoutState,
			loading,
			channels,
			toggleFoldout,
			translate,
		};

	}

});