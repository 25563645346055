import { StudyDataChannel } from "@/psychlab/types/study";
import { WizardContext } from "../types/WizardContext";

export const canAverageChannel = (c:any) => {
	if(c.value !== "number"){ return false; }
	return [
		"form/linearscale/row",
		"gallery/feedback/scale",
	].findIndex(t => t === c.type) > -1;
};

export const getGroupOptions = (channels:any[], filterFn:(c:any) => boolean) => {
	const supported = channels.filter(filterFn);
	const groups:any[] = [];
	supported.forEach(channel => {

		if(groups.findIndex(g => g.value === channel.group) > -1){
			return;
		}
		groups.push({
			value:channel.group,
			text:channel.groupName
		});
	});
	return groups;
};


const countableTypes = [
	"form/dropdown",
	"form/multiplechoice",
	"form/linearscale/row",
	"gallery/feedback/scale",
];

export const getOptions = (channels:any[], filterFn:(c:any) => boolean) => {
	const countable = channels.filter(filterFn);
	return countable
	.map(channel => {
		return {
			text: channel.name,
			value: channel.key,
		};
	});
};


export const isCountableChannel = (channel:any) => {
	return countableTypes.includes(channel.type);
};

export const getChannelDrawer = (channel:StudyDataChannel) => {

	switch(channel.type){
		case "form/dropdown":
		case "form/multiplechoice":
		case "form/linearscale/row":
		case "gallery/feedback/scale":
			return {
				type:"dropdown",
				options:(channel.options || []).map((o, i) => {
					return {
						text: o ? `${i} | ${o}` : i,
						value: i,
					};
				})
			}
		case "form/checkboxes/option":
			return {
				type:"dropdown",
				options:[
					{ value: 0, text: "False" },
					{ value: 1, text: "True" },
				]
			}
	}

	return {
		type:"text"
	};
};

export const getChannelDropdownOptions = (channels:any[]) => {
	return channels.map(c => {
		return {
			text:c.name,
			value:c.key,
			group: c.group,
			groupName: c.groupName,
		};
	});
};

export const isCheckbox = (c:any) => {
	return c.type === "form/checkboxes/option";
};


export const hasNumericChannels = (ctx:WizardContext) => {
	return ctx.channels.findIndex(c => c.value === "number") > -1;
};