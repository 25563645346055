





import { defineComponent } from "@vue/composition-api";
import { EditScene } from "@/components.edit/edit-scene";

export default defineComponent({
	components: {
		EditScene
	},
	props:{
		parameters:{
			type:Object,
			required:true
		}
	}
});

