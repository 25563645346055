// bootstrap
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import BootstrapVue from "bootstrap-vue";
// icons
import "@mdi/font/css/materialdesignicons.min.css";
// import "@/theme/mdi.scss";
import "font-awesome/css/font-awesome.css";
import { Icon } from "./icons";
// context menu
import { ContextMenu } from "./context-menu";
// snapsvg hack
import "snapsvg-cjs-ts";

export const UIPlugin = {
	install(vue:any, options:any){
		vue.use(BootstrapVue)
		vue.component("icon", Icon);
		vue.component("Icon", Icon);
		vue.component("vue-context", ContextMenu);
	}
};