import { PhraseDictionary } from "../types";
import { mergeDictionaries } from "../mergeDictionaries";

const label:PhraseDictionary  = {}

const action:PhraseDictionary  = {
	"understood":{
		en:"I Understand",
		is:"Ég skil"
	},
	"cancel":{
		en:"Cancel",
		is:"Hætta við"
	},
	"submit":{
		en:"Submit",
		is:"Staðfesta"
	},
	"done":{
		en:"Done",
		is:"Búin(n)"
	},
	"confirm":{
		en:"Confirm",
		is:"Staðfesta"
	},
	"confirmAndCreate":{
		en:"Confirm & Create",
		is:"Staðfesta og búa til"
	},
	"confirmAndClose":{
		en:"Confirm and Close",
		is:"Staðfesta og loka",
	},
}

const message:PhraseDictionary  = {
	"irreversible":{
		en:"Are you sure? This is irreversible.",
		is:"Ertu viss? Þetta getur ekki verið endurkallað."
	},
};

export const prompts = mergeDictionaries("prompts", { label, action, message });