






























































import { defineComponent, computed, ref } from "@vue/composition-api";

import { useTranslate } from "@lang";

export default defineComponent({

	emits:[ "settings", "comment" ],
	props:{
		disableDrag:{
			type:Boolean,
			default:false
		},
		active:{
			type:Boolean,
			default:false,
		},
		comment:{
			type:String,
			default:"",
		},
	},
	components:{
	},
	setup(_, ctx){

		const { translate } = useTranslate();
		
		const openSettings = (e:Event) => {
			ctx.emit("settings", e);
		};

		const openComment = (e:Event) => {
			ctx.emit("comment", e);
		};

		return {
			openSettings,
			openComment,
			translate,
		};
	}
});

