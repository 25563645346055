


















































// vendor
import { defineComponent, computed, ref } from "@vue/composition-api";
// project
import { useLang } from "@lang";

const getBoolProp = (d: boolean = false) => ({ type: Boolean, default: d });

export default defineComponent({
	emits: [
		"previous",
		"next",
		"toggle",
	],
	props: {
		hasPrevious: getBoolProp(true),
		hasNext: getBoolProp(true),
		showPrevious: getBoolProp(false),
		showNext: getBoolProp(true),
		canContinue: getBoolProp(true),
		open: getBoolProp(true),
		disabled: getBoolProp(false),
		onLast: getBoolProp(false),
		showTooltips: getBoolProp(false),
	},
	setup(props, context) {

		const { translate } = useLang(context);

		const prevBtn = computed(() => {

			const title = translateKey("back");
			const disabled = props.disabled || !props.showPrevious;
			const hide = !props.showPrevious;
			const variant = "info";
			const icon = "mdi.arrow-left-thick";

			return {
				title,
				disabled,
				variant,
				icon,
				tooltip:getTooltip(title, variant, disabled),
				click(){
					context.emit("previous");
				},
			};
		});

		const nextBtn = computed(() => {
			const title = translateKey(props.onLast ? "finish" : "continue");
			const disabled = props.disabled || !props.canContinue || !open;
			const variant = props.canContinue ? (props.onLast ? "success" : "light") : "secondary";
			const icon = props.onLast ? "mdi.check-bold" : "mdi.arrow-right-thick";
			return {
				title,
				disabled,
				variant,
				icon,
				tooltip:getTooltip(title, variant, disabled),
				click(){
					context.emit("next");
				},
			};
		});

		const toggleBtn = computed(() => {

			const title = translateKey(props.open ? 'hide' : 'show');
			const icon = props.open ? 'mdi.chevron-down' : 'mdi.chevron-up';

			return {
				title,
				icon,
				tooltip:getTooltip(title, "light"),
				click(){
					context.emit("toggle");
				},
			};
		});

		const translateKey = (k:string) => translate(k).toUpperCase();

		const getTooltip = (title:string, variant:string, disabled:boolean = false) => {
			return {
				disabled:!props.showTooltips || disabled,
				title,
				interactive:false,
				delay:0,
				animation:false,
				variant,
				customClass:"font-nats"
			};
		};

		return {
			prevBtn,
			nextBtn,
			toggleBtn,
		};
	},
});
