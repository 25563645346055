












































































































































































// vendor
import { computed, defineComponent, ref, PropType } from "@vue/composition-api";
import { CustomCheckbox, CustomCheckboxState } from "@/components.generic/custom-checkbox";
import { deleteStudyLabel, editSessionLabel } from "@/services/api/studies";
import { updateSessionLabels, updateBatchSessionLabels } from "@/services/api/studies";
import { SessionLabel, Session } from "@psychlab/types";
import { LabelBadge, Icon } from "@ui";
import { useTranslate } from "@lang";
import { default as CreateLabel } from "./create-label.vue";
import { useStudyRouteParams } from "../hooks/useStudyRouteParams";


type Dictionary<T> = { [k:string]:T }

enum EditingOperation {
	CHANGE = "change",
	DELETE = "delete",
};

export default defineComponent({
	emits: [
		"onClick",
		"onRemoveClick",
		"onReloadData",
	],
	props: {
		sessionsList: {
			type: Array as PropType<Session[]>,
			default:() => [],
		},
		selectedSessionIds: {
			type: Array as PropType<string[]>,
			default:() => [],
		},
		showLabel: {
			type: Boolean,
			default: () => false,
		},
		labelsDictionaryInitial: {
			type: Object as PropType<Dictionary<SessionLabel>>,
			default:() => {},
		},
		singleSessionUpdate: {
			type: Boolean,
			default: () => true,
		},
	},
	components: {
		CustomCheckbox,
		CreateLabel,
		LabelBadge,
		Icon,
	},
	setup(props, ctx) {
		const { studyId } = useStudyRouteParams(ctx);

		if(!studyId){ throw Error("Session Label Add: Study ID missing!"); }


		const { translate } = useTranslate();

		const isInitialLoading = ref(false);

		const isLoadingUpdate = ref(false);
		const hasErrorUpdate = ref(false);

		const labelsDictionary = ref<Dictionary<SessionLabel>>(
			props.labelsDictionaryInitial,
		);
		const modalElement = ref<any>(null);

		const selection = ref<any[]>([]);
		const intermediateSelection = ref<any[]>([]);

		const editingLabel = ref<null | string>(null);
		const editingOperation = ref<EditingOperation | null>(null);
		const editingLabelText = ref("");
		const editingLabelColor = ref("#fff");
		const editingLoadingResponse = ref(false);
		const editingErrorMessage = ref<string | null>(null);

		const isEditing = (labelId: string) =>
			editingLabel.value === labelId;
		const setEditing = (
			labelId: string,
			operation: EditingOperation,
		) => {
			editingLabel.value = labelId;
			editingOperation.value = operation;
			editingLabelText.value = labelsDictionary.value[labelId].name;
			editingLabelColor.value = labelsDictionary.value[labelId].color;
		};
		const confirmEditing = async () => {
			const labelId = editingLabel.value;
			if (!studyId || !labelId) {
				return;
			}
			const labelObject = {
				name: editingLabelText.value,
				color: editingLabelColor.value,
			};
			editingLoadingResponse.value = true;
			try {
				if (editingOperation.value === EditingOperation.CHANGE) {
					await editSessionLabel(studyId.value, labelId, labelObject);
					labelsDictionary.value = {
						...labelsDictionary.value,
						[labelId]: {
							...labelObject,
							_id: labelId,
						},
					} as any;
				} else if (
					editingOperation.value === EditingOperation.DELETE
				) {
					await deleteStudyLabel(studyId.value, labelId);
					const {
						[labelId]: remove,
						...nextLabelsDictionary
					} = labelsDictionary.value;
					labelsDictionary.value = nextLabelsDictionary;
					selection.value = selection.value.filter(
						(i) => i !== labelId,
					);
					intermediateSelection.value = intermediateSelection.value.filter(
						(i) => i !== labelId,
					);
				}
			} catch {
				editingErrorMessage.value = "Unable to save change";
			}
			editingLoadingResponse.value = false;
			editingLabel.value = null;
		};
		const discardEditing = () => {
			editingLabel.value = null;
		};

		const resetChanges = () => {
			labelsDictionary.value = props.labelsDictionaryInitial;

			if (props.singleSessionUpdate) {
				// single session update
				if (!props.selectedSessionIds[0]) {
					console.error(
						"Missing session ID for single session update",
					);
					return;
				}
				const sessionId = props.selectedSessionIds[0];
				const session = props.sessionsList.find(
					(session) => session._id === sessionId,
				);
				selection.value = (session ? session.labels : []);
				intermediateSelection.value = [];
			} else {
				// multiple sessions update
				const allLabelIds = Object.keys(
					props.labelsDictionaryInitial,
				);
				const labelsState: { [key: string]: number } = {};
				allLabelIds.forEach(
					(labelId) => (labelsState[labelId] = 0),
				);
				const sessionsCount = props.selectedSessionIds.length;

				props.selectedSessionIds.map((sessionId) => {
					const session = props.sessionsList.find(
						(session) => session._id === sessionId,
					);
					(session ? session.labels : []).forEach((labelId:string) => {
						labelsState[labelId] += 1;
					});
				});
				const selectedLabels:string[] = [];
				const intermediateLabels:string[] = [];
				allLabelIds.forEach((labelId) => {
					const count = labelsState[labelId];
					if (count === sessionsCount) {
						selectedLabels.push(labelId);
					} else if (count < sessionsCount && count > 0) {
						intermediateLabels.push(labelId);
					}
				});
				selection.value = selectedLabels;
				intermediateSelection.value = intermediateLabels;
			}
		};

		const handleReloadData = () => ctx.emit("onReloadData");

		const handleModalShown = () => resetChanges();

		const handleModalHide = () => handleReloadData();

		const open = (event:Event) => {
			if(!modalElement.value){ return; }
			modalElement.value.show();
		};

		const handleNewLabel = (newLabel:SessionLabel) => {
			labelsDictionary.value = {
				...labelsDictionary.value,
				[newLabel._id]: newLabel,
			};
			selection.value = [...selection.value, newLabel._id];
		};

		const sortLabelsComparator = (
			a: { _id: string },
			b: { _id: string },
		): number => (a._id < b._id ? -1 : 1);

		const handleConfirm = async () => {
			isLoadingUpdate.value = true;
			try {
				if (
					props.singleSessionUpdate &&
					props.selectedSessionIds[0]
				) {
					// single session update
					await updateSessionLabels(
						studyId.value,
						props.selectedSessionIds[0],
						selection.value.sort(sortLabelsComparator),
					);
				} else if (!props.singleSessionUpdate) {
					// multiple sessions update
					const assignSelection = selection.value;

					const labelIds = Object.keys(labelsDictionary.value);
					const unassignSelection:any[] = labelIds
						.filter(
							(labelId) => !assignSelection.includes(labelId),
						)
						.filter(
							(labelId) =>
								!intermediateSelection.value.includes(
									labelId,
								),
						);
					await updateBatchSessionLabels(
						studyId.value,
						props.selectedSessionIds,
						assignSelection.sort(sortLabelsComparator),
						unassignSelection.sort(sortLabelsComparator),
					);
				}
			} catch {
				hasErrorUpdate.value = true;
			}
			handleReloadData();
			isLoadingUpdate.value = false;
		};

		const allLabels = computed(() => {
			const keys = Object.keys(labelsDictionary.value).sort();
			return keys.map((id) => labelsDictionary.value[id]);
		});

		const handleCheckboxClick = (
			labelId: string,
			nextState: CustomCheckboxState,
		) => {
			if (nextState === CustomCheckboxState.FALSE) {
				selection.value = selection.value.filter(
					(i) => i !== labelId,
				);
				intermediateSelection.value = intermediateSelection.value.filter(
					(i) => i !== labelId,
				);
			} else if (nextState === CustomCheckboxState.TRUE) {
				selection.value = [...selection.value, labelId];
				intermediateSelection.value = intermediateSelection.value.filter(
					(i) => i !== labelId,
				);
			}
		};

		return {
			open,
			modalElement,
			selection,
			intermediateSelection,
			handleNewLabel,
			isInitialLoading,
			isLoadingUpdate,
			hasErrorUpdate,
			handleConfirm,
			handleReloadData,
			allLabels,
			handleModalHide,
			handleModalShown,
			handleCheckboxClick,
			isEditing,
			editingLabel,
			setEditing,
			confirmEditing,
			discardEditing,
			editingLabelText,
			editingLabelColor,
			editingLoadingResponse,
			editingErrorMessage,
			editingOperation,
			EditingOperation,
			translate,
		};
	},
});
