import { PhraseDictionary } from "../types";
import { mergeDictionaries } from "../mergeDictionaries";

const label:PhraseDictionary  = {
	"noAuthSet":{
		en:"None",
		is:"Ekkert",
	},
};

const action:PhraseDictionary  = {};

const message:PhraseDictionary  = {};

export default mergeDictionaries("auth", { label, action, message });