export const getDeepProperty = function(ob:any, path:string, defaultValue:any){
	let segments = path.split('.');
	for (var i=0, len = segments.length; i < len; i++){
		if(!ob){
			return defaultValue;
		}
		ob = ob[segments[i]];
	};
	if(!ob){ return defaultValue; } // edge case
	return ob;
};