























// vendor
import { defineComponent, PropType } from "@vue/composition-api";
// project
import { SessionLabel } from "@/psychlab/types/studies";
import { Session } from "@/psychlab/types/sessions";
import { default as SessionList } from "../list/session-list.vue";

export default defineComponent({
	emits: [
		"change-sort",
		"change-limit",
		"change-page",
		"reload",
	],
	props: {
		studyId: {
			type: String,
			required: true,
		},
		sessions: {
			type: Array as PropType<Session[]>,
			required: true,
		},
		page: {
			type: Number,
			required: true,
		},
		limit: {
			type: Number,
			required: true,
		},
		rows: {
			type: Number,
			required: true,
		},
		sortKey: {
			type: String,
			required: true,
		},
		sortAsc: {
			type: Boolean,
			required: true,
		},
		labels: {
			type: Array as PropType<SessionLabel[]>,
			default:() => {},
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		SessionList,
	},
	setup(props, ctx) {

		const onChangeSort = (sortKey: string) => ctx.emit("change-sort", sortKey);

		const onChangeLimit = (value: number) => ctx.emit("change-limit", value);

		const onRowClicked = (sessionId: string) => {
			if (props.studyId) {
				ctx.root.$router.push({
					name: "Run.Group.Sessions.SingleSession",
					params: {
						groupId: props.studyId as string,
						sessionId: sessionId as string,
					},
					query:{
						...ctx.root.$route.query,
					}
				});
			}
		};

		const onChangePage = (page: number) => ctx.emit("change-page", page);

		const onReload = () => ctx.emit("reload");

		return {
			onChangeSort,
			onRowClicked,
			onChangeLimit,
			onChangePage,
			onReload,
		};
	},
});
