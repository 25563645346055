import { PropMap } from "./PropMap";
import { setDOMAttributes } from "./DOM";
import { Dictionary } from "vue-router/types/router";

import * as SnapType from "snapsvg"
declare let Snap: typeof SnapType;

export class SnapOutline {

	constructor(target:SnapType.Paper){
		this._target = target;
		this.refresh();
	}

	public toggleShow(state:boolean){
		this._show = state;
		this.refreshAttributes();
	};
	
	public configure(ob:Dictionary<any>){
		this._props.configure(ob);
		this.refreshFilter();
		this.refreshChildren();
		this.refreshAttributes();
	};

	public refresh(){
		if(this._outlineRoot){
			this._outlineRoot.remove();
		}
		this._outlineRoot = this._target.clone() as SnapType.Paper;
		this._outlineRoot.prependTo(this._target);

		this.refreshFilter();
		this.refreshChildren();
		this.refreshAttributes();
	};

	private _target:SnapType.Paper;
	private _outlineRoot:SnapType.Paper|null = null;
	private _blurFilter:SnapType.Element|null = null;
	private _show:boolean = false;
	private _props:PropMap = new PropMap({
		width: {
			value: 2,
			validator: v =>  typeof(v) === "number" && v >= 0
		},
		color: {
			value: "cyan",
			validator: v => typeof(v) === "string"
		},
		blur: {
			value: 0,
			validator: v => typeof(v) === "number"
		}
	});

	private refreshChildren(){
		if(!this._outlineRoot){ return; }
		loopChildren(this._outlineRoot, e => {
			if(!e.node.style){
				return;
			}
			let currentFill = e.node.getAttribute("fill");
			if(currentFill === "rgba(0,0,0,0)"){
				e.node.style["display"] = "none";
			}
			e.attr({
				fill: undefined,
				stroke: undefined,
				strokeWidth: this._props.getValue("width")
			})
		});
	};

	private refreshAttributes(){
		if(!this._outlineRoot){ return; }
		const color = this._props.getValue("color");
		this._outlineRoot.attr({
			fill: color,
			stroke: color,
			style: `display: ${this._show ? "?" : "none"}`
		});
	};

	private refreshFilter(){
		if(!this._outlineRoot){ return; }
		const blur = this._props.getValue("blur");
		if(this._blurFilter){
			this._blurFilter.remove();
		}
		this._blurFilter = this._outlineRoot.filter(Snap.filter.blur(blur, blur));
		setDOMAttributes(this._blurFilter.node, {
			filterUnits: "objectBoundingBox",
			x: "-50%",
			y: "-50%",
			width: "200%",
			height: "200%"
		});
		this._outlineRoot.attr({
			filter: this._blurFilter
		});
	};
}

const loopChildren = (el:SnapType.Element, fn:(el:SnapType.Element) => void) => {
	fn(el);
	el.children().forEach(c => loopChildren(c, fn));
};