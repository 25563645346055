// vendor
import * as THREE from "three";
// local
import { getInverseSphere } from "./getInverseSphere";
import { imageToCanvas } from "./toCanvas";

const sphereGeometry = getInverseSphere(1.5);

type Result = {
	img:HTMLImageElement;
	sphere:THREE.Object3D;
	texture:THREE.Texture;
	canvas:HTMLCanvasElement;
};

const IMG_CSS = `
position:absolute;
pointer-events:none;
width:1px;
height:1px;
opacity:0;
`;

export const createImageSphere = async(sources:string[], el:HTMLElement):Promise<Result> => {

	let i = 0;
	const img = new Image();

	img.style.cssText = IMG_CSS;
	img.crossOrigin = "anonymous";

	return new Promise((resolve, reject) => {
		img.onerror = () => {
			i++;
			if(i >= sources.length){ return reject(); }
			img.src = sources[i];
		};
		img.onload = async () => {
			const canvas = await imageToCanvas(img);
			const texture = new THREE.CanvasTexture(canvas);
			const material = new THREE.MeshBasicMaterial( { map: texture, color:"white"} );
			const sphere = new THREE.Mesh(sphereGeometry, material);
			resolve({
				img,
				sphere,
				texture,
				canvas,
			});
		};
		img.src = sources[0];
	});
};