





import { defineComponent, computed, PropType } from "@vue/composition-api";
import { Information } from "@psychlab/types/form";
import { RenderInformation } from "@form-ui";
import { getBaseProps } from "./getBaseProps";

export default defineComponent({
	props:{
		...getBaseProps<Information>(),
		config:{
			type:Object as PropType<Information>,
			required:true
		}
	},
	components:{
		RenderInformation,
	},
	setup(props){

		const args = computed(() => ({
			title:!props.config.hideName ? props.config.name : '',
			body:props.config.body,
			content:props.config.settings.text,
			theme:props.theme,
		}));

		return {
			args,
		}
	}
});

