/** @format */

import { http } from "@/http";
import { SessionLabel } from "@/psychlab/types";

export const getStudyLabels = async (
	study: string,
): Promise<SessionLabel[]> => {
	const { data } = await http.get(`studies/${study}/labels`);
	return data;
};
