// vendor
import { defineComponent, onMounted, computed, ref, watch, PropType, onUnmounted  } from "@vue/composition-api";
import * as THREE from "three";
// project
import { diff as diffArray } from "@/utils/array";
// local 
// import { SceneManager } from "./behaviours/SceneManager";
import { useSceneManager, ISceneManager } from "./hooks/useSceneManager";
import * as ThreeUtils from "./utils/Three";

export const Viewer = defineComponent({
	props:{
		scenes:{
			type:Array as PropType<string[]>,
			default:() => [] as string[]
		}
	},
	setup(props){

		const viewerRoot = ref<HTMLElement|null>();
		// let manager:SceneManager|null = null;

		let manager:ISceneManager|null = null;
		
		watch(() => props.scenes, (v:string[], o:string[]) => {
			if(!manager){ return; }
			const loadedScenes = diffArray(v, o, (i1,i2) => i1 === i2);
			const unloadedScenes = diffArray(o, v, (i1,i2) => i1 === i2);
			loadScenePaths(loadedScenes);
			unloadScenePaths(unloadedScenes);
		});

		onMounted(() => {
			if(!viewerRoot.value){ return; }
			// manager = new SceneManager(viewerRoot.value);
			manager = useSceneManager(viewerRoot.value);
			// const light = new THREE.HemisphereLight("white", "grey", 1);
			// manager.add(light);
			if(props.scenes){ loadScenePaths(props.scenes); }
		});

		onUnmounted(() => {
			manager?.dispose();
			manager = null;
		});

		const getCamera = () => {
			// return manager ? manager.camera : null;
			return manager?.getCamera() || null;
		};

		const setCamera = (v:THREE.Camera|null) => {
			if(!manager){ return; }
			// manager.camera = v;
			manager?.setCamera(v);
		};

		const getDOMElement = () => {
			return viewerRoot.value;
		};

		const add = (ob:THREE.Object3D) => {
			if(!manager){ return; }
			manager.add(ob);
		};

		const loadScenePaths = (scenes:string[]) => {
			const m = manager;
			if(!m){ return; }
			scenes.forEach(async s => {
				const ob = await loadGLTF(s);
				ob.name = `assets_${encodeURIComponent(s)}`;
				m.add(ob);
			});
		};

		const unloadScenePaths = (scenes:string[]) => {
			const m = manager;
			if(!m){ return; }
			scenes.forEach(s => m.remove(`assets_${encodeURIComponent(s)}`));
		};

		return {
			viewerRoot,
			getDOMElement,
			getCamera,
			setCamera,
			add,
			loadScenePaths,
			unloadScenePaths
		};
	}
});


const loadGLTF = async (path:string) => {
	try {
		return await ThreeUtils.loadGLTF(path);
	}
	catch(err:any){
		if(err.message){ throw err; }
		throw { message: "Not Found" }
	}
};