































































// vendor
import { defineComponent, computed, ref, PropType, watch, set, del } from "@vue/composition-api";
// project
import { Icon } from "@ui";
import { generateGUID } from "@/utils/guid";
import { EditText } from "@/components.generic/utility-modal";
// local
import { default as PropBindingCanvas } from "../shared/prop-binding-canvas.vue";
import { WizardContext } from "../../ts/types/WizardContext";
import { parseBinding } from "../../ts/utils/parseBinding";
import { checkBindingCompatibility } from "../../ts/utils/checkBindingCompatibility";
import { useTranslate } from "@lang";

export default defineComponent({

	emits:[ "state" ],
	props:{
		context: {
			type: Object as PropType<WizardContext>,
			required: true,
		},
	},
	components:{
		PropBindingCanvas,
		Icon,
		EditText,
	},
	setup(props, context){

		const addPropModal = ref<any>();
		const editPropModal = ref<any>();

		const {
			translate
		} = useTranslate();

		const bindings = computed(() => {
			return props.context.dataBindings;
		});

		const sourceProps = computed(() => {
			if(!props.context.dataSource){ return []; }
			const dynamicProps = Object.keys(props.context.dataSource.output)
			.map(k => {
				const p = props.context.dataSource.output[k];
				return {
					name:k,
					type:p.type,
					optional:p.optional,
				}
			});

			
			

			return [
				...dynamicProps,
				...props.context.staticProps
			]
		});

		const targetProps = computed(() => {
			const t = props.context.renderTemplate;
			if(!t){ return []; }
			return Object.keys(t.props)
			.map(k => {
				const p = t.props[k];
				return {
					name:k,
					type:p.type,
					optional:p.optional
				}
			})
		});
		
		const isIncompatible = computed(() => {
			return !checkBindingCompatibility(sourceProps.value as any, targetProps.value as any);
		});

		const canAddStatic = computed(() => false);

		const addStaticProp = () => {

			if(!addPropModal.value){ return; }

			addPropModal.value.open({ value:"My Field" }, (v:string) => {
				
				const prop = {
					label: v,
					name:generateGUID(),
					type:"string",
					static:true
				};
				set(props.context.staticArgs, prop.name, "");
				props.context.staticProps.push(prop);

			});

			
		};
		
		const deleteStaticProp = (name:string) => {
			
			const pi = props.context.staticProps.findIndex(p => p.name === name);
			if(pi < 0){ return; }
			
			for(var i = props.context.dataBindings.length - 1; i >= 0; i--){

				const binding = parseBinding(props.context.dataBindings[i]);

				if(binding.from === name || binding.to === name){
					props.context.dataBindings.splice(i, 1);
				}
			}
			props.context.staticProps.splice(pi, 1);
			del(props.context.staticArgs, name);
		};
		
		const editStaticProp = (name:string) => {
			if(!editPropModal.value){ return; }

			const current = props.context.staticArgs[name];

			editPropModal.value.open({ value:current }, (v:string) => {
				
				set(props.context.staticArgs, name, v);

			});


		};

		const propBound = (p:string) => {
			for(var i = 0; i < bindings.value.length; i++){
				const b = parseBinding(bindings.value[i]);
				if(b.to === p){
					return true;
				}
			}
			return false;
		}

		const emitState = () => {

			let s = true;
			for(var i = 0; i < targetProps.value.length; i++){
				const p = targetProps.value[i];
				if(!p.optional && !propBound(p.name)){
					s = false;
					break;
				}
			};
			context.emit("state", s);

		};
		

		watch(bindings, (v) => {
			emitState();
		}, { deep:true })
		
		emitState();

		return {
			canAddStatic,
			bindings,
			isIncompatible,
			sourceProps,
			targetProps,
			addPropModal,
			editPropModal,
			addStaticProp,
			deleteStaticProp,
			editStaticProp,
			translate,
		};
	}
});

