
export const getUnitPos = (rot:number, r:number = 10) => {
	return {
		x:round(cos(360 - rot), r),
		y:round(sin(360 - rot), r)
	};
};

export const cos = (a:number) => Math.cos(a*Math.PI/180);
export const sin = (a:number) => Math.sin(a*Math.PI/180);
export const round = (v:number, d:number) => Number((v).toFixed(d));