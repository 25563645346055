// import { formatDateStringRecent, formatDuration } from "@/utils/text";

export const pageLimitOptions = [ 5, 10, 20, 50];

export const tableFields = [
	{
		key:"_id",
		label:"ID",
		thClass:"font-weight-light tcol-xl txt-80",
		tdClass:"consolas txt-60 align-middle",
		class:"text-light"
	},
	{
		key:"ip",
		label:"Request IP",
		thClass:"font-weight-light tcol-xl noselect txt-80",
		tdClass: "txt-80 align-middle",
		class:"text-light",
		sortable:false
	},
	{
		key:"created",
		label:"Started",
		// formatter: formatDateStringRecent,
		thClass:"font-weight-light tcol-mdd noselect txt-80",
		tdClass: "txt-80 align-middle",
		class:"text-light",
		sortable:true
	},
	{
		key:"completed",
		// formatter(v:string){
		// 	return v ? formatDateStringRecent(v) : "-";
		// },
		thClass:"font-weight-light tcol-xl noselect txt-80",
		tdClass: "txt-80 align-middle",
		class:"text-light",
		sortable:true
	},
	{
		key:"duration",
		// formatter(v:number){
		// 	return v ? formatDuration(v) : "-";
		// },
		thClass:"font-weight-light tcol-md noselect txt-80",
		tdClass: "txt-80 align-middle",
		class:"text-light",
		sortable:true
	},
	{
		key:"expires",
		// formatter(v:number){
		// 	return v ? formatDateStringRecent(v) : "-";
		// },
		thClass:"font-weight-light tcol-xl noselect txt-80",
		tdClass: "txt-80 align-middle",
		class:"text-light",
		sortable:true
	},
	{
		key:"progress",
		label:"Progress",
		thClass:"xtext-muted font-weight-light tcol-xl bg-warningd noselect txt-80",
		tdClass: "align-middle",
		sortable:true
	},
	{
		key:"optionalProgress",
		label:"Opt. Progress",
		thClass:"xtext-muted font-weight-light tcol-xl bg-warningd noselect txt-80",
		tdClass: "align-middle",
		sortable:true
	},
	// {
	// 	key:"runState",
	// 	label:"",
	// 	thClass:"xtext-muted font-weight-light tcol-xs bg-warningd noselect pr-3 txt-80",
	// 	tdClass: "txt-80 align-middle",
	// 	class:"text-light",
	// 	sortable:false
	// },
	// {
	// 	key:"controls",
	// 	label:"",
	// 	thClass:"text-muted font-weight-light tcol-xs bg-warningd noselect pr-3 txt-80",
	// 	sortable:false
	// }
	
];