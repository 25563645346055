import Router from "vue-router";
import { NavigationGuard } from "vue-router";
import * as Routes from "@/routes";
import * as BeforeEach from "@/router-guards/before-each";

import * as BeforeResolve from "@/router-guards/before-resolve";


// router instance
const router = new Router({
	routes: Object.keys(Routes).map(k => (Routes as any)[k]),
	mode:"history"
});

const be:{ [k:string]:NavigationGuard<any> } = BeforeEach;

const br:{ [k:string]:NavigationGuard<any> } = BeforeResolve;


// before-each guards
Object.keys(BeforeEach).forEach(k => router.beforeEach(be[k]))
// Object.keys(BeforeResolve).forEach(k => router.beforeResolve(br[k]))

export { router }