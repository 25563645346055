


























// vendor
import { defineComponent, computed, ref, PropType } from "@vue/composition-api";
// project
import { matchWildcard } from "@/utils/regex";

export default defineComponent({
	props:{
		sources:{
			type:Array as PropType<string[]>,
			default:() => [],
		},
		thumbnail:{
			type:String,
			required:true,
		},
		panorama:{
			type:Boolean,
			default:false,
		}
	},
	setup(props){
		const isEmpty = computed(() => props.sources.length === 0);
		return {
			isEmpty
		};
	}
});

