

























import { defineComponent, computed } from "@vue/composition-api";

export default defineComponent({
	props:{
		text:{
			type:String,
		}
	},
	setup(props, context){
		
		const text = computed(() => props.text || "" );

		const label = computed(() => {
			if(!text.value){ return ""; }
			const [
				p1, p2
			] = text.value.split(";");
			if(!p2){ return p1; }
			return p2;
		});

		const variant = computed(() => {
			if(!text.value){ return ""; }
			const [
				p1, p2
			] = text.value.split(";");
			if(!p2){ return "light"; }
			return p1;
		});

		const textVariant = computed(() => {
			return ({
				"light":"dark",
				"warning":"dark",
			} as any)[variant.value] || "light";
		});

		const rename = () => {
			context.emit("rename");
		};

		return {
			rename,
			variant,
			textVariant,
			label,
		};
	}
});

