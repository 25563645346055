











































import { defineComponent, ref, computed } from "@vue/composition-api";
import * as OpsAPI from "@/services/api/ops";
import * as StudyAPI from "@/services/api/studies";
import { Study } from "@/psychlab/types";
import * as AppModal from "@/AppModal";
import { FoldoutCard, Heading, Container, Divider, TipIcon } from "@ui"
import { useTranslate } from "@lang"
import { FeatureFlags, isFlagEnabled } from "@app";

enum Translations {
	DeleteStudy = "studies.action.deleteStudy",
	Irreversible = "prompts.message.irreversible",
	Understood = "prompts.action.understood",
	Cancel = "prompts.action.cancel",
	RebuildSessions = "studies.action.rebuildSessions",
	AboutSessionRebuilding = "studies.message.onRebuildSessions",
	AboutStudyDeletion = "studies.message.onDeletingStudy",
}

export default defineComponent({
	components: {
		FoldoutCard,
		Heading,
		Container,
		Divider,
		TipIcon,
	},
	setup(_, context) {

		const foldoutState = ref<Record<string,boolean>>({});
		const study = ref<Study>();
		const routeId = computed(() => context.root.$route.params["groupId"]);

		const { translate } = useTranslate();

		const toggleFoldout = (name: string) => {
			const d = foldoutState.value;
			d[name] = !Boolean(d[name]);
			foldoutState.value = JSON.parse(JSON.stringify(d));
		};

		const openBlueprintRoute = () => {
			if (!study.value) {
				return;
			}
			context.root.$router.push({
				name: "studies",
				params: {  },
			});
		};

		const rebuildSessions = async () => {
			try {
				await OpsAPI.rebuildSessionState({ group: routeId.value });
				console.log("Rebuilt sessions.");
			} catch (err:any) {
				console.error(err.message);
			}
		};

		const rebuildChannels = async () => {
			try {
				await OpsAPI.rebuildDataChannels({ study: routeId.value });
				console.log("Rebuilt channels.");
			} catch (err:any) {
				console.error(err.message);
			}
		};

		const deleteStudy = async () => {
			AppModal.confirm(
				{
					title: translate("studies.action.deleteStudy"),
					description: translate("prompts.message.irreversible"),
					variant:"danger",
					okText:translate("prompts.action.understood"),
					cancelText:translate("prompts.action.cancel")
				},
				async () => {
					try {
						await StudyAPI.deleteStudy(routeId.value);
						openBlueprintRoute();
					} catch (err) {
						console.log(err);
					}
				},
			);
		};

		const init = async () => {
			try {
				study.value = await StudyAPI.getStudy(routeId.value);
			} catch (err) {
				console.log(`Error loading study, id:'${routeId.value}'`);
			}
		};

		const runOperation = (name: string) => {
			switch (name) {
				case "rebuild-sessions":
					rebuildSessions();
					break;
				case "rebuild-channels":
					rebuildChannels();
					break;
				case "delete-study":
					deleteStudy();
					break;
			}
		};

		const ops = computed(() => {

			const foldouts: FoldoutContent[] = [];

			if(isFlagEnabled(FeatureFlags.SessionsRebuildable)){
				foldouts.push({
					name: "rebuild-sessions",
					icon: "refresh",
					title: translate(Translations.RebuildSessions),
					variant: "info",
					content: translate(Translations.AboutSessionRebuilding),
					buttonText: translate(Translations.RebuildSessions),
				});
			}

			if(isFlagEnabled(FeatureFlags.StudiesDeletable)){
				foldouts.push({
					name: "delete-study",
					icon: "trash-can",
					title: translate(Translations.DeleteStudy),
					variant: "danger",
					content: translate(Translations.AboutStudyDeletion),
					buttonText: translate(Translations.DeleteStudy),
				})
			}
			return foldouts;
		});

		init();

		return {
			foldoutState,
			ops,
			toggleFoldout,
			rebuildSessions,
			rebuildChannels,
			deleteStudy,
			runOperation,
			translate,
		};
	},
});

type FoldoutContent = {
	name: string;
	icon: string;
	title: string;
	variant: string;
	buttonText: string;
	content: string;
};


