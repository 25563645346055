

































































































































import { defineComponent, computed, ref, PropType, inject, ComputedRef, watch } from "@vue/composition-api";
import { SessionLabel } from "@/psychlab/types/studies";
import { StudyContext } from "@/components.studies/manage-study";
import { default as RouteFilter } from "./route-filter.vue";
import { useSessionFilters } from "../hooks/useSessionFilters";
import { useTranslate } from "@lang";

const getFoldout = ():boolean => {
	const v = localStorage.getItem("study/filter-sidebar-foldout");
	return v ? JSON.parse(v) : false;
};

const setFoldout = (v:boolean) => {
	localStorage.setItem("study/filter-sidebar-foldout", v.toString());
};

export default defineComponent({
	props:{
		studyId:{
			type:String,
			required:true,
		},
		width:{
			type:String,
			default:"13rem",
		},
		disabled:{
			type:Boolean,
			default:false,
		},
		labels: {
			type: Array as PropType<SessionLabel[]>,
			default:() => [],
		},
	},
	components:{
		RouteFilter,
		// ContextMenu,
	},
	setup(props, context){

		const studyContext = inject<ComputedRef<StudyContext>>("studyContext");

		if(!studyContext){ throw Error("Filter Sidebar: Study context missing!"); }

		const {
			study,
		} = studyContext.value;

		const s = study.value;

		if(!s){ throw Error("Filter Sidebar: Study missing!"); }

		const { translate } = useTranslate();

		const filterInfo = computed(() => {

			const txt = translate("studies.message.noFiltersActive");

			const parts = txt.replace("$icon", "_").split("_");

			if(parts.length !== 2){
				return ["",""];
			}
			return parts;

		})


		// const contextMenu = ref<IContextMenu>();

		const open = ref(getFoldout());

		// const labels = ref<SessionLabel[]>([]);

		const labels = computed(() => {
			return props.labels;
		});

		const {
			activeFilters,
			unusedFilters,
			addFilter,
			removeFilter,
			clearFilters,
		} = useSessionFilters({
			study:s,
			// contextMenu,
			labels,
		}, context)
		

		const toggleVariant = computed(() => {
			return open.value ? "outline-light" : "secondary";
		});

		const toggle = () => {
			open.value = !open.value;
		};

		const init = async () => {
			// labels.value = await StudyAPI.getStudyLabels(props.studyId);
		};

		watch(open, (v) => {
			setFoldout(v);
		});

		init();

		return {
			// contextMenu,
			open,
			toggleVariant,
			toggle,
			activeFilters,
			unusedFilters,
			addFilter,
			removeFilter,
			clearFilters,
			translate,
			filterInfo,
		};
	}
});

