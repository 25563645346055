import { defineComponent, ref, computed, watch, PropType } from "@vue/composition-api";
import * as SessionAPI from "@/services/api/sessions";
import * as StudyAPI from "@/services/api/studies";
import { StudyInfo } from "@psychlab/types";
import * as Platform from "@utils/platform";
import { AbsBox } from "@ui";
import { default as Router } from "vue-router";
import { FacebookWarning } from "@/errors";
import { Loading } from "./Loading";
import { Start } from "./Start";
import { Resume } from "./Resume";
import { StudyAuth } from "./Auth";
import { ProvideStudyLanguage } from "../ProvideStudyLanguage";
import { CompanySplashScreen } from "@components/misc";

enum ScreenType {
	NotFound = "not-found",
	SurveyClosed = "survey-closed",
	UnknownError = "unknown-error",
}

enum RouteNames {
	OpenSession = "Run.Session"
}

const openScreen = (e:ScreenType, r:Router, q?:any) => r.push({
	name:"Error",
	params:{ slug:e, },
	query:q
});

const openSession = (sessionId:string, router:Router, l:string) => {
	router.push({
		name:RouteNames.OpenSession,
		params:{ sessionId },
		query:{ l }
	});
};

export const OpenSurvey = defineComponent({
	setup(_, context){

		const router = context.root.$router;
		const route = context.root.$route;

		const hasError = ref(false);
		const loading = ref(false);
		const notFound = ref(false);
		const info = ref<StudyInfo>();
		const started = ref(false);
		const existingSession = ref<string>();
		
		const isFacebook = computed(() => Platform.isFacebookApp());

		const studyId = computed(() => route.params["proxyId"]);
		
		const authMode = computed(() => info.value?.auth);

		const disabled = computed(() => info.value ? !info.value.enabled : false);

		const routeLanguage = computed(() => {
			if(!info.value){ return ""; }
			let l = info.value.language || "en";
			if(route.query["l"]){
				l = route.query["l"] as string;
			}
			return l;
		});

		const preferredLanguage = computed(() => {
			if(info.value){
				return info.value.language;
			}
			return routeLanguage.value;
		});

		const start = async() => {
			started.value = true;
			await requestSession();
		};

		const resume = () => {
			started.value = true;
			if(!existingSession.value){ return; }
			openSession(existingSession.value, router, routeLanguage.value);
		};

		const requestSession = async() => {
			loading.value = true;
			try{
				const { token, session } = await StudyAPI.runStudy(studyId.value);
				SessionAPI.saveSessionToken(studyId.value, session, token);
				openSession(session, router, routeLanguage.value);
			}
			catch({ response }){
				hasError.value = true;
			}
			loading.value = false;
		};

		const init = async() => {
			loading.value = true;

			try {
				info.value = await StudyAPI.getStudyInfo(studyId.value);
				existingSession.value = SessionAPI.findActiveStudySession(studyId.value);
			}
			catch(err){
				notFound.value = true;
			}
			loading.value = false;
		};

		watch(notFound, v => {
			if(!v){ return; }
			openScreen(ScreenType.NotFound, router, {
				l:preferredLanguage.value,
			});
		});

		watch(disabled, v => {
			if(!v){ return; }
			openScreen(ScreenType.SurveyClosed, router, {
				l:preferredLanguage.value,
			});
		});

		watch(hasError, v => {
			if(!v){ return; }
			openScreen(ScreenType.UnknownError, router, {
				l:preferredLanguage.value,
			});
		});

		init();

		return {
			loading,
			notFound,
			info,
			started,
			existingSession,
			disabled,
			routeLanguage,
			isFacebook,
			authMode,
			studyId,
			preferredLanguage,
			start,
			resume,
		};
	},
	render(){

		
		let content = null;

		if(this.loading){
			content = <Loading/>;
		}
		else if(this.info && this.isFacebook){
			content = <FacebookWarning/>
		}
		
		else if(this.existingSession){
			content = (
				<AbsBox>
					<Resume v-on:resume={ this.resume }/>
				</AbsBox>
			);
		}

		if(this.authMode && !this.existingSession){
			content = <StudyAuth study={this.studyId} mode={this.authMode}/>
		}

		if(!content){
			content = <Start v-on:start={ this.start }/>;
		}

		return (
			<ProvideStudyLanguage lang={ this.preferredLanguage }>
				<AbsBox>
					{content}
				</AbsBox>
				<CompanySplashScreen show={ this.loading }/>
			</ProvideStudyLanguage>
		);
	}
});


const ScreenRedirect = defineComponent({

	props:{
		screen:{
			type:String as PropType<ScreenType>,
			required:true,
		}
	},
	setup(){

	},
	render(){
		return <div/>
	}
})