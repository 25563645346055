import { SetupContext } from "@vue/composition-api";

export const setQueryValue = (k:string, v:any, context:SetupContext) => {
	const q:any = { ...context.root.$route.query };
	if(v !== undefined && v !== null){
		q[k] = v;
	}
	else {
		delete q[k];
	}
	context.root.$router.push({ query: q})
	.catch(() => {});
};


export const getQueryValue = <T>(k:string, context:SetupContext, def:T):T => {
	return ((context.root.$route.query[k] as any) as T) || def;
};


export const loadQuerySort = (ctx:SetupContext) => {
	const v = getQueryValue<string|null>("sl_sort", ctx, null);
	if(!v){ return { desc:true, prop:"lastVisited" }; }
	const [
		p1,
		p2,
	] = v.split("-");
	if(!p2){ return { desc:false, prop:p1 }};
	return { desc: true, prop:p2 };
};

export const clampNumber = (v:number, min:number, max:number) => v < min ? min : (v > max ? max : v);