import { render, staticRenderFns } from "./display-channels.vue?vue&type=template&id=8ffa9fda&scoped=true&"
import script from "./display-channels.vue?vue&type=script&lang=ts&"
export * from "./display-channels.vue?vue&type=script&lang=ts&"
import style0 from "./display-channels.vue?vue&type=style&index=0&id=8ffa9fda&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ffa9fda",
  null
  
)

export default component.exports