import { IAuthProvider } from "./interfaces";
import { UserAuthProvider } from "./UserAuthProvider";

export class Provide {

	static get userAuth():IAuthProvider{
		return Provide.getInstance()._userAuth;
	}

	private constructor(){
		this._userAuth = new UserAuthProvider();
	}

	private static getInstance(){
		if(!Provide._instance){
			Provide._instance = new Provide();
		}
		return Provide._instance;
	}
	
	private static _instance:Provide|null = null;

	private _userAuth:IAuthProvider;

}