








// vendor
import {
	defineComponent,
	computed,
	ref,
	PropType,
} from "@vue/composition-api";
// local
import { WizardStep } from "../ts/types/WizardStep";
import { WizardContext } from "../ts/types/WizardContext";

export default defineComponent({
	emits: [ "state" ],
	props: {
		step: {
			type: Object as PropType<WizardStep>,
			required: true,
		},
		context: {
			type: Object as PropType<WizardContext>,
			required: true,
		},
	},
	setup(props, context) {

		const onState = (v:boolean) => {
			context.emit("state", v);
		};

		return {
			onState
		};
	},
});
