// import * as Config from "../config";
import { Connector as ConnectorSettings } from "../config/Connector";


import { EventBus } from "../utils/EventBus";
import { Connector2D } from "../utils/Connector2D";
import { getDeepObjectValue } from "../utils/Object";
import { getPathString } from "../utils/Path2D";
import { Dictionary } from "../utils/Dictionary";
import * as SnapSVG from "snapsvg";
import { Port } from "./Port";

export class Transition {

	

	constructor(id:string, snapRoot:SnapSVG.Paper, portA:Port, portB:Port){

		const scope = this;

		this._id = id;
		this._portA = portA;
		this._portB = portB;

		if(this._rootElement){ this._rootElement.remove(); } // cleanup
		
		this._rootElement = snapRoot.group(); // connector parent
		this._connectors = {
			normal: new Connector2D(this._rootElement),
			hovered: new Connector2D(this._rootElement),
			selected: new Connector2D(this._rootElement),
			collider: new Connector2D(this._rootElement)
		};

		Object.keys(this._connectors)
		.forEach(k => {
			configureAttributes(this._connectors[k], getDeepObjectValue(ConnectorSettings, `${k}.attributes`, {}));
		})

		this._connectors.normal.configure({
			useEndArrow: true
		});

		this._connectors.selected.configure({
			blur: 0,
			useEndArrow: true
		});

		this._connectors.hovered.configure({
			useEndArrow: true
		});

		this._connectors.collider.configure({
			useEvents: true
			
		});

		this._connectors.selected.toggleVisible(false);
		this._connectors.collider.on("press", e => {
			if(e.buttons === 1){
				scope.sendToFront();
				scope._events.emit("press", e);
			}
		});

		this._connectors.collider.on("hover", () => scope.setHovered(true));
		this._connectors.collider.on("unhover", () => scope.setHovered(false));

		this._moved = this.onPortMovement.bind(this);

		portA.on("nodeMoved", this._moved);
		portB.on("nodeMoved", this._moved);

		this.setHovered(false);
		this.refresh();
	}

	public addMovement(x:number, y:number){
		// nada
	}

	public setHighlighted(state:boolean){
		this._connectors["selected"].toggleVisible(state);
		if(!state){
			this.setHovered(false);
		}
		this._connectors["collider"].configure({
			useEvents: !state
		});
	};

	public destroy(){
		this._portA.off("nodeMoved", this._moved);
		this._portB.off("nodeMoved", this._moved);
		if(this._rootElement){
			this._rootElement.remove();
		}
	};

	public intersectsBBox(bbox:SnapSVG.BBox){
		return this._connectors.collider.intersectsBBox(bbox);
	};

	public hasNodeConnection(nodeId:string){
		return this._portA.getNodeID() === nodeId || this._portB.getNodeID() === nodeId;
	}

	public getID(){
		return this._id;
	}

	public on<N extends keyof TransitionEvents>(name:N, fn:TransitionEvents[N]){
		this._events.on(name, fn);
	}

	public off<N extends keyof TransitionEvents>(name:N, fn:TransitionEvents[N]){
		this._events.off(name, fn);
	}

	private _moved:() => void;

	private _id:string;
	private _portA:Port;
	private _portB:Port;

	private _events = new EventBus<TransitionEvents>();
	private _rootElement?:SnapSVG.Paper;
	private _connectors:Dictionary<Connector2D> = {};

	// sends transition to top of parent
	private sendToFront(){
		if(!this._rootElement){ return; }
		this._rootElement.appendTo(this._rootElement.parent());
	}

	private setPath(path:string){
		Object.keys(this._connectors).forEach(k => this._connectors[k].setPoints(path));
	};

	// refreshes position
	private refresh(){
		let ax = this._portA.getGlobalX();
		let ay = this._portA.getGlobalY();
		let bx = this._portB.getGlobalX();
		let by = this._portB.getGlobalY();
		let pathstring = getPathString(ConnectorSettings.type, { x: ax, y: ay}, { x: bx, y: by });
		this.setPath(pathstring);
	};

	private onPortMovement(){
		this.refresh();
	};
	
	private setHovered(state:boolean){
		this._connectors.hovered.toggleVisible(state);
	};

}

const configureAttributes = (c:Connector2D, ob:Dictionary<any>) => c.configureAttributes(ob);

type EventHandler<T, R> = (p:T) => R;
interface TransitionEvents {
	"press":EventHandler<any,void>
}