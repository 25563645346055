/** @format */

import { defineComponent, computed, ref, PropType } from "@vue/composition-api";
import { Widget } from "@/psychlab/types/widget";
import { EditWizard } from "@/components.studies/study-widget-wizard";
import * as StudyAPI from "@/services/api/studies";
import { RenderWidget } from "./RenderWidget";
import { default as WidgetContext } from "../vue/widget-context.vue";
import * as AppModal from "@/AppModal";
import { useTranslate } from "@lang";
import { Icon } from "@ui";

const getBindingRegex = () => `${getPartial("from")}->${getPartial("to")}`;
const getPartial = (name:string) => `(?<${name}>[a-zA-Z0-9]+)`;

const parseBinding = (s:string) => {
	const m = s.match(getBindingRegex());

	if(!m || !m.groups){
		throw new Error("Error parsing binding");
	}
	
	return {
		from:m.groups["from"],
		to:m.groups["to"]
	}
};



export const DisplayWidget = defineComponent({
	emits: ["deleted", "refreshed"],
	props: {
		widget: {
			type: Object as PropType<Widget>,
			required: true,
		},
		editing:{
			type:Boolean
		},
	},
	components: {
		// MiniIconButton,
		// ConfirmDelete,
		Icon,
		RenderWidget,
		WidgetContext,
		EditWizard,
	},
	setup(props, context) {


		const hovered = ref(false);

		// const confirmDeleteModal = ref<any>();
		const loading = ref(false);
		

		const {
			translate,
		} = useTranslate();

		// const contextMenu = ref<any>();

		const editWidgetModal = ref<any>();

		const displayLabel = computed(() => {
			return props.widget.name;
		});

		const renderTemplate = computed(() => props.widget.template);
		const templateData = computed(() => {

			const bindings = props.widget.templateBindings.map(parseBinding);

			const data:any = {
				...props.widget.templateArgs
			};


			bindings.forEach(b => {
				data[b.to] = (props.widget.computeResult as any)[b.from];
			});

			
			return {
				...props.widget.templateArgs,
				...data
			};
		});

		const openContext = (e:Event) => {
			// if(!contextMenu.value){ return; }
			// contextMenu.value.open(e);
			
			AppModal.context(e, [
				{
					name:translate("action.rename"),
					icon: "mdi.pencil-box",
					fn:rename,
					order:-1,
				},
				{
					name:translate("action.edit"),
					icon: "mdi.pencil",
					fn:edit,
					order:-1,
				},
				{
					name:translate("action.refresh"),
					icon: "mdi.refresh",
					fn:refresh,
				},
				
				{
					name:translate("action.delete"),
					icon: "mdi.trash-can",
					fn:onDelete,
					order:5,
				}
			])
			
		};

		const rename = () => {
			AppModal.editText({
				title:"Rename Widget",
				value:props.widget.name,
			}, async (v) => {
				try {
					await StudyAPI.editWidget(props.widget.study, props.widget._id, {
						name:v,
					});
					props.widget.name = v;
				}
				catch(err:any){
					console.log(err.message);
				}
			})
		};

		const refresh = async() => {
			// handle refresh
			loading.value = true;
			try {
				props.widget.computeResult = await StudyAPI.refreshWidget(props.widget.study, props.widget._id);
			} catch(err){
				console.error(err);
			}
			loading.value = false;
			context.emit("refreshed", props.widget.computeResult);
		};

		const deleteWidget = async () => {
			loading.value = true;
			try {
				await StudyAPI.deleteWidget(props.widget.study, props.widget._id);
				context.emit("deleted", props.widget._id);
			} catch(err){
				console.error(err);
			}
			loading.value = false;
		};

		const onDelete = () => {
			// if(!confirmDeleteModal.value){ return; }
			// confirmDeleteModal.value.open({ title:"Delete Widget" }, deleteWidget);

			AppModal.confirm({
				title:translate("widgets.action.deleteWidget"),
				variant:"danger",
				description:translate("prompts.message.irreversible"),
				okText:translate("prompts.action.understood"),
				// cancelText:,
			}, deleteWidget)
		};

		// const onEdit = () => context.emit("edit");

		const edit = async () => {
			if(!editWidgetModal.value){ return; }

			const dataChannels = await StudyAPI.getStudyDataChannels(props.widget.study);

			editWidgetModal.value.open({
				computeType:props.widget.computeType,
				computeResult:props.widget.computeResult,
				computeArgs:props.widget.computeArgs,
				template:props.widget.template,
				templateArgs:props.widget.templateArgs,
				templateBindings:props.widget.templateBindings,
				metadata:props.widget.metadata,
				dataChannels

			}, async (r:any) => {
				await StudyAPI.editWidget(props.widget.study, props.widget._id, r);

				if(r.templateArgs){
					props.widget.templateArgs = r.templateArgs;
				}

				if(r.computeArgs){
					props.widget.computeArgs = r.computeArgs;
					refresh();
				}

			});

			// console.log(editWidgetModal.value);

		};





		return {
			// confirmDeleteModal,
			loading,
			renderTemplate,
			templateData,
			hovered,
			// contextMenu,
			displayLabel,
			editWidgetModal,
			refresh,
			onDelete,
			openContext,
			edit,
			translate,
		};
	},
});
