// vendor
import { defineComponent, computed, ref, inject } from "@vue/composition-api";
// project
import { SessionChannel } from "@/psychlab/types";
import { Graph } from "@/psychlab/types/graph";
import { SessionContext } from "./SessionContext";
import { DisplayJSON, Icon } from "@ui";
import { useTranslate } from "@lang";

type Dictionary<T> = { [k:string]:T };

export const DisplayChannels = defineComponent({
	props:{
		loading:{
			type:Boolean,
			default:false,
		},
	},
	components:{
		"display-json":DisplayJSON,
		DisplayJSON,
		Icon,
	},
	setup(props){

		const sessionContext = inject<SessionContext>("sessionContext");

		if(!sessionContext){ throw Error("Display channels: Session context missing!"); }


		const { translate } = useTranslate();

		const {
			session,
			channels,
			savedChannels,
			blueprintVersion,
		} = sessionContext;

		const rawPreview = ref<any>(null);
		const valuePreview = ref<any>(null);

		const graph = computed<Graph|null>(() => {
			if(!blueprintVersion.value){ return null; }
			return blueprintVersion.value.data;
		})

		const usedChannels = computed(() => {
			const s = session.value;
			if(!s){ return channels.value; }
			return channels.value.filter(channel => {
				const nid = channel.key.split("/")[0];
				return s.path.includes(nid);
			});
		});

		const displayChannels = computed(() => {
			return usedChannels.value.map(channel => {
				const name = nameGenerators[channel.type] ? nameGenerators[channel.type](channel) : channel.name;
				const node = extractNodeId(channel.key);
				const saved = savedChannels.value.findIndex(c => c.path === channel.key) > -1;
				const typeIcon = getTypeIcon(channel.type);
				const v = savedChannels.value.find(cv => cv.path === channel.key);
				const typeLabel = typeLabels[channel.type] ? typeLabels[channel.type] : channel.type;
				let value:any = null;
				if(v){
					value = valueTransformers[channel.type] ? valueTransformers[channel.type](channel, v.value) : v.value;
				}
				return {
					key:channel.key,
					name,
					node,
					saved,
					typeIcon,
					value,
					typeLabel:translate(typeLabel),
					channel
				};
			})
		});

		const displayGroups = computed(() => {
			const groups:Dictionary<{
				id:string,
				channels:any[],
				typeName:string|undefined,
				typeIcon:string|undefined,
			}> = {};

			displayChannels.value
			.forEach(channel => {
				if(!groups[channel.node]){

					let typeName = "";
					let typeIcon = "";

					if(graph.value && graph.value.nodes[channel.node]){
						typeName = graph.value.nodes[channel.node].type;
						typeIcon = "mdi." + iconMap[typeName];
					}

					if(typeName){
						typeName = translate(`asset.${typeName.toLowerCase()}`)
					}


					groups[channel.node] = {
						id:channel.node,
						channels:[],
						typeName,
						typeIcon
					}
				}
				groups[channel.node].channels.push(channel);
			});
			return Object.keys(groups).map(k => groups[k]);
		});

		return {
			displayChannels,
			displayGroups,
			rawPreview,
			valuePreview,
			translate,
		};
	}
});


const nameGenerators:any = {
	"form/checkboxes/option":(channel:SessionChannel):string => {
		return `${channel.groupName} > ${channel.name}`;
	},
	"form/linearscale/row":(channel:SessionChannel):string => {
		return `${channel.groupName} > ${channel.name}`;
	}
};

const valueTransformers:any = {
	"form/checkboxes/option":(channel:SessionChannel, value:number):string => {

		return value ? "✔️" : "❌";
	},
	"form/multiplechoice":(channel:SessionChannel, value:number):string => {
		const options = channel.options || [];
		if(value > -1 && value < options.length){ return options[value]; }
		return "";
	},
	"form/dropdown":(channel:SessionChannel, value:number):string => {
		const options = channel.options || [];
		if(value > -1 && value < options.length){ return options[value]; }
		return "";
	},
	"form/linearscale/row":(channel:SessionChannel, value:number):string => {
		const options = channel.options || [];
		if(value < 0 || options.length === 0){ return ""; }
		return `${value} / ${options.length - 1}`;
	},
	"gallery/feedback/scale":(channel:SessionChannel, value:number):string => {
		const options = channel.options || [];
		if(value < 0 || options.length === 0){ return ""; }
		return `${value} / ${options.length - 1}`;
	},
};

const iconMap:Dictionary<string> = {
	"form":"mdi.clipboard-text",
	"form/answer":"mdi.card-text",
	"form/checkboxes/option":"mdi.check-box-outline",
	"form/multiplechoice":"mdi.radiobox-marked",
	"form/dropdown":"mdi.chevron-down-box",
	"form/linearscale/row":"mdi.electric-switch-closed",
	"CognitiveTest":"head-cog mdi-flip-h",
	"Form":"file-document-edit",
	"Scene":"nature-people",
	"Gallery":"image-multiple",
	"gallery/feedback/scale":"mdi.electric-switch-closed",
};


const typeLabels:Dictionary<string> = {
	"form/answer":"studies.label.textAnswer",
	"form/checkboxes/option":"studies.label.checkboxOption",
	"form/multiplechoice":"studies.label.multipleChoice",
	"form/dropdown":"studies.label.dropdown",
	"form/linearscale/row":"studies.label.linearScaleRow",
	"gallery/feedback/scale":"studies.label.galleryFeedback",
};

const extractNodeId = (channelKey:string) => {
	return channelKey.split("/")[0];
};

const extractTypeCategory = (channelType:string) => {
	return channelType.split("/")[0];
};

const getTypeIcon = (channelType:string) => {
	const c = extractTypeCategory(channelType);
	if(iconMap[channelType]){ return iconMap[channelType]; }
	return iconMap[c];
};