import { CardGrid } from "@components/misc";
import { AbsBox, Date as DateLabel, Icon } from "@ui";
import { defineComponent, ref, computed, watch, PropType } from "@vue/composition-api";
import { format as formatDate } from "date-fns";
import { Asset } from "@/psychlab/types";
import * as StudyAPI from "@/services/api/studies";
import { getInverseColor } from "@/utils/color";
import { useTags } from "@/hooks/useTags";
import { useAssets } from "@/hooks/useAssets";
import { sortByDate } from "@/utils/array";
import { getAssetTypes } from "@/psychlab/meta";
import { useTranslate } from "@lang";
import { createStudy as openStudyWizard } from "@/AppModal";
import { default as Router } from "vue-router";

enum RoutePaths {
	StudyDashboard = "Run.Group",
}

enum Translations {
	NoAvailableBlueprints = "assets.message.noAvailableBlueprints",
}

const openStudyDashboard = (id:string, r:Router) => {
	r.push({
		name:RoutePaths.StudyDashboard,
		params:{
			groupId:id,
		}
	});
};

export const BlueprintGrid = defineComponent({
	emits:[ "created" ],
	props:{
		searchFilter:{
			type:String,
			default:"",
		},
	},
	setup(props, context){

		const router = context.root.$router;

		const { translate } = useTranslate();

		const { assets } = useAssets();

		const { sortedTags, hasTag, } = useTags();

		const iconColor = getAssetTypes().find(a => a.name === "Graph")?.color || "white";

		const filterAsset = (a:Asset) => {
			if(a.type !== "Graph"){ return false; }
			if(!props.searchFilter || props.searchFilter.length < 2){ return true; }
			return a.name.toLowerCase().includes(props.searchFilter.toLowerCase());
		};

		const blueprints = computed(() => {
			const tag = currentTagId.value;
			const blueprints = assets.value.filter(filterAsset);
			sortByDate(blueprints, a => a.lastModified);
			if(!tag){ return blueprints; }
			return blueprints.filter(bp => hasTag(tag, bp._id));
		});

		const currentTagId = ref<string|null>(null);

		const currentTag = computed(() => {
			return sortedTags.value.find(t => t._id === currentTagId.value);
		});
		
		const setCurrentTag = (v:string) => currentTagId.value = v;

		const createStudy = (blueprintId:string) => {
			
			openStudyWizard({
				blueprint:blueprintId,
			}, async (r:any) => {
				const { name, study, version, language, openDashboard, auth } = r;
				const s = await StudyAPI.createStudy({ name, blueprint:`${study}@${version}`, language, auth });
				if(openDashboard){ openStudyDashboard(s._id, router); }
				else {
					context.emit("created");
				}

			});
		};

		const getFormattedDate = (d:string) => formatDate(new Date(d), "EEE MMM. d y @ HH:mm")

		const init = async() => {
			const t = context.root.$route.query["t"];
			if(t && typeof(t) === "string"){
				currentTagId.value = t;
			}
		};

		watch(() => context.root.$route.query["t"], v => {
			if(currentTagId.value !== v){
				setCurrentTag(v as any);
			}
		});

		init();

		return {
			blueprints,
			iconColor,
			currentTagId,
			currentTag,
			getInverseColor,
			createStudy,
			getFormattedDate,
			translate,
		};

	},
	render(){

		if(this.blueprints.length === 0){
			return (
				<div class="d-flex p-3 border border-secondary rounded">
					<span class="m-auto text-light font-weight-light">
						{ this.translate(Translations.NoAvailableBlueprints) }
					</span>
				</div>
			);
		}


		const cards = this.blueprints.map(bp => {
			return (
				<BlueprintCard
				key={bp._id}
				blueprint={bp}
				v-on:click={ () => this.createStudy(bp._id) }
				/>
			);
		});

		return (
			<CardGrid.Grid>
				{cards}
			</CardGrid.Grid>
		);
	}

});



const BlueprintCard = defineComponent({
	emit:[ "click" ],
	props:{
		blueprint:{
			type:Object as PropType<Asset>,
			required:true,
		}
	},
	setup(){

		const hovered = ref(false);

		return {
			hovered,
		};
	},
	render(){

		const hovered = this.hovered ? (

			<CreateButton
			v-on:click={ (e:Event) => this.$emit("click", e) }
			blueprint={ this.blueprint }/>

		) : <span/>

		return (
			<CardGrid.Card
			class="shadow"
			style="border-radius:15%;background:#117a8b"
			v-on:hovered={ () => this.hovered = true }
			v-on:unhovered={ () => this.hovered = false }
			>

				<AbsBox
				class="d-flex flex-column"
				>
					<h2 class="m-1 mx-auto"
					style="opacity:0.5"
					>
						<Icon name="state-machine" class="m-axuto text-light" shadow/>
					</h2>

					<div
					class="flex-fill d-flex p-2"
					style="position:relative"
					>
						<span
						class="text-center mx-auto mb-auto text-light"
						style="font-size:0.9em;"
						>
							{ this.blueprint.name }
						</span>
					</div>

					<AbsBox class="d-flex p-2">
				
						<small style="font-size:0.7em;opacity:0.7" class="text-light mt-auto mx-auto">
							<DateLabel date={this.blueprint.lastModified} xshort/>
						</small>
					
					</AbsBox>
				</AbsBox>

				{hovered}

			</CardGrid.Card>
		)
	}
});


const CreateButton = defineComponent({

	props:{
		blueprint:{
			type:Object as PropType<Asset>,
			required:true,
		}
	},
	setup(){

		const { translate } = useTranslate();

		return {
			translate,
		};
	},
	render(){

		const s = `
		position:absolute;
		transform:translate(-50%,-50%);
		top:0;
		left:50%;
		width:3em;
		height:3em;
		border-width:4px !important;
		`;

		return (
			<AbsBox class="bg-info"
			style="border-radius:15%;"
			>
				<button
				class="btn bg-inxfo shadow-none text-light d-flex w-100 h-100 p-0"
				style="border-radius:15px !important;cursor:pointer"
				v-on:click={(e:Event) => this.$emit("click", e)}
				>
					<div
					style={s}
					class="bg-dark text-light border border-info rounded-circle d-flex"
					>
						<Icon
						name="atom-variant"
						class="m-auto"
						style="font-size:1.5rem;"
						shadow
						spin
						glow
						/>
					</div>


					<div class="title m-auto d-flex p-2">
						<h3 class="my-auto" style="font-family:'NATS'">
							{ this.translate("studies.action.startNewStudy") }
						</h3>
					</div>
				</button>
			</AbsBox>
		);
	}
})