import { defineComponent, computed, ref, watch } from "@vue/composition-api";
import { LoadingBar, AlertBox } from "@ui";
import { useTranslate } from "@lang";
import { UpdateReport, getUpdateReport, applyUpdate as applyReport, parseRef } from "./getUpdateReport";
import * as AssetAPI from "@/services/api/assets";

enum Translations {
	OutdatedReferences = "studies.message.onOutdatedReferences",
	Updating = "label.updating",
	Update = "assets.action.update",
	UpToDate = "label.upToDate"
}

export const BlueprintUpdate = defineComponent({
	emit:[ "updated" ],
	props:{
		reference:{
			type:[Object,String],
			required:true,
		},
	},
	setup(props, ctx){

		const { translate } = useTranslate();

		const loading = ref(false);
		const updating = ref(false);
		const didUpdate = ref(false);
		const outdatedRefs = computed(() => report.value ? report.value.entries.length : 0)
		const report = ref<UpdateReport|null>(null);
		const assetRef = computed(() => parseRef(props.reference));
		const assetId = computed(() => assetRef.value?.asset);
		const versionId = computed(() => assetRef.value?.version);

		const refreshReport = async () => {
			if(!assetId.value || !versionId.value ){ return; }
			const v = await AssetAPI.getAssetVersion(assetId.value, versionId.value, "+data");
			if(!v.data){ return; }
			loading.value = true;
			try {
				report.value = await getUpdateReport(assetId.value, versionId.value, v.data);
			}
			catch{}
			loading.value = false;
		};

		const updateMessage = computed(() => {
			return translate(Translations.OutdatedReferences)
			.replace("$n", outdatedRefs.value.toString());
		});

		const showUpdateStatus = computed(() => {
			return outdatedRefs.value === 0 && didUpdate.value;
		});

		const showDialog = computed(() => {
			return updating.value === false && outdatedRefs.value > 0;;
		});

		const update = async () => {
			updating.value = true;
			try {
				await applyUpdate();
			}
			catch(err:any){
				console.log(err.message);
			}
			updating.value = false;
			didUpdate.value = true;
		};

		const applyUpdate = async () => {
			if(!report.value){ return; }
			const ref = await applyReport(report.value);
			ctx.emit("updated", ref);
		};

		watch(versionId, () => {
			didUpdate.value = false;
			refreshReport();
		}, { deep:true })

		refreshReport();

		return {
			updating,
			updateMessage,
			showUpdateStatus,
			showDialog,
			translate,
			update,
			
		};
	},
	render(){

		if(this.updating){
			return <LoadingBar label={ this.translate(Translations.Updating) }/>;
		}

		if(this.showDialog){
			return (
				<div
				class="p-2 bg-warning rounded text-light">
					<p class="m-0 text-dark">
						<small>
							{ this.updateMessage }
						</small>
					</p>
					<hr class="my-1"/>
					<div class="justify-content-end d-flex">
						<button
						v-on:click={ this.update }
						class="btn btn-sm btn-light ml-auto my-auto">
							{ this.translate(Translations.Update) }
						</button>
					</div>
				</div>
			)
		};

		if(this.showUpdateStatus){
			return (
				<AlertBox
				round outline sm
				variant="success"
				size={ 6 }
				icon="check"
				text={ this.translate(Translations.UpToDate) }
				/>
			);
		}


		return <span/>;
		



		
	}
});