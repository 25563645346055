





























































import { defineComponent, computed, ref } from "@vue/composition-api";
import { useTranslate } from "@lang";

enum Translations {
	Back = "session.action.back",
	Continue = "session.action.continue",
	Hide = "session.action.hide",
	Show = "session.action.show",
}

export default defineComponent({
	emits: ["previous", "next"],
	props: {
		hasPrevious: {
			type:Boolean,
			default:true,
		},
		hasNext: {
			type:Boolean,
			default:true,
		},
		showPrevious: {
			type:Boolean,
			default:false,
		},
		showNext: {
			type:Boolean,
			default:true,
		},
		canContinue: {
			type:Boolean,
			default:true,
		},
	},
	setup(_, context) {
		const open = ref(true);

		const previous = () => context.emit("previous");
		const next = () => context.emit("next");

		const { translate } = useTranslate();

		const getTooltip = (text:string, variant:string = "dark") => {
			return {
				title:text.toUpperCase(),
				interactive:true,
				delay:0,
				animation:false,
				variant,
				customClass:"font-weight-light"
			};
		};

		const phrases = computed(() => ({
			back:translate(Translations.Back),
			continue:translate(Translations.Continue),
			hide:translate(Translations.Hide),
			show:translate(Translations.Show),
		}))

		return {
			open,
			phrases,
			previous,
			next,
			getTooltip,
		};
	},
});
