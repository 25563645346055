import { ComputeChannelAverage } from "./ComputeChannelAverage";
import { ComputeGroupAverage } from "./ComputeGroupAverage";
import { CountChannelOptions } from "./CountChannelOptions";
import { CountChannelValue } from "./CountChannelValue";
import { CountStudySessions } from "./CountStudySessions";
import { FindMaxChannelAverage } from "./FindMaxChannelAverage";
import { FindMinChannelAverage } from "./FindMinChannelAverage";
import { ComputeChannelAverages } from "./ComputeChannelAverages";
import { CountGroupBooleans } from "./CountGroupBooleans";

export const computeSources = [
	ComputeChannelAverage,
	ComputeGroupAverage,
	CountChannelOptions,
	CountChannelValue,
	CountStudySessions,
	FindMaxChannelAverage,
	FindMinChannelAverage,
	ComputeChannelAverages,
	CountGroupBooleans,
];
// .filter((s:any) => {

// 	if(s.isUsable && typeof(s.isUsable) === "function"){
// 		return Boolean(s.isUsable());
// 	}

// 	if(s.isUsable && typeof(s.isUsable) === "boolean"){
// 		return Boolean(s.isUsable);
// 	}

// 	return true;
// })