import { defineComponent, PropType, ref, computed, set } from "@vue/composition-api";
import PointList from "../vue/widgets/point-list.vue";
import EditPoint from "../vue/edit-point.vue";
import { SceneData } from "./types/SceneData";
import { useTranslate } from "@lang";

export const Inspector = defineComponent({

	components:{
		PointList,
		EditPoint
	},
	props:{
		data:{
			type:Object as PropType<SceneData>,
			required:true
		},
		activePoint:{
			type:String
		}
	},
	setup(props){


		const { translate } = useTranslate();

		const points = computed({
			get(){
				if(!props.data.points){
					set(props.data, "points", []);
				}
				return props.data.points ? props.data.points : [];
			},
			set: v => set(props.data, "points", v)
		});

		const currentPoint = computed(() => {
			return props.data.points.find(p => p.guid === props.activePoint);
		});

		return {
			points,
			currentPoint,
			translate,
		};
	}

});