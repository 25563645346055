














// vendor
import { defineComponent, computed, ref } from "@vue/composition-api";
// project
import { matchWildcard } from "@/utils/regex";

export default defineComponent({
	props:{
		src:{
			type:String,
		},
	},
	setup(props){
		return {};
	}
});

