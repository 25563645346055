// import * as Config from "../config";
import { Node as NodeSettings } from "../config/Node";

import { SnapMovementDrag } from "../utils/SnapMovementDrag";
import { SnapShadow } from "../utils/SnapShadow";
import { SnapOutline } from "../utils/SnapOutline";
import { EventBus } from "../utils/EventBus";
import { getDeepObjectValue } from "../utils/Object";
// import { NodeLabel } from "./NodeLabel";
import { Port } from "./Port";
import { NodeIcon, NodeBackground } from "./NodeGUI";
import { NodeTemplate, Vector2D } from "../types";
import { Dictionary } from "../utils/Dictionary";
import onChange from "on-change";


import * as SnapType from "snapsvg"
declare let Snap: typeof SnapType;


type TransformInfo = {
	zoom:number
}

type NodeParams = {

}

export class Node {

	constructor(id:string, root:SnapType.Paper, template:NodeTemplate<any>, transformInfo:TransformInfo, parameters:NodeParams){

		const nScope = this;
		this._id = id;
		this._parameters = parameters;
		this._template = template;
		this._root = root;
		this._transformInfo = transformInfo;

		this._element = this._root.group();

		this._position = onChange(this._positionData, () => {
			if(this._element){
				nScope._element.attr({
					transform: "t" + [nScope._positionData.x, nScope._positionData.y]
				});
			}
			nScope._events.emit("position-updated", nScope._positionData);
		});

		const nb = new NodeBackground({ template:template.template });
		nb.created(this._element);

		const portRoot = this._element.group();
		
		for(var portKey in this._template.ports){
			let port = new Port(portRoot, this, portKey, this._template.ports[portKey]);

			port.on("press", () => nScope.portPressed(port));
			port.on("release", () => nScope.portReleased(port));
			port.on("hover", () => nScope.portHovered(port));
			port.on("unhover", () => nScope.portUnhovered(port));
			this._ports[portKey] = port;
		}

		this._drag = new SnapMovementDrag(this._element);
		this._drag.configure({
			buttons: 1
		});
		this._drag.on("drag", (event) => {
			let zoom = 1.0 / nScope._transformInfo.zoom;
			let x = event.x * zoom;
			let y = event.y * zoom;
			nScope.move(x, y);
			nScope._events.emit("drag", { x, y });
		});
		this._drag.on("end", (event) => {
			nScope._events.emit("newPosition", { x: nScope._position.x, y: nScope._position.y });
		});
		
		this._outline = new SnapOutline(this._element);
		
		this._outline.configure(getDeepObjectValue(NodeSettings, "selection", {}));
		this._shadow = new SnapShadow(this._element);	
		
		this._shadow.configure(getDeepObjectValue(NodeSettings, "shadow", {}));
		// this._label = new NodeLabel(this._element);
		// this.setLabelText("");
		this._element.dblclick(this.onDoubleClick.bind(this))
		this._element.mousedown(this.onMousedown.bind(this));
		this._element.mouseup(this.onMouseup.bind(this));
		this._element.mousedown(this.onClick.bind(this));
		this._element.node.addEventListener("contextmenu", this.onContext.bind(this));
		this.setPosition(0, 0);

		const ni = new NodeIcon({
			icon: this._template.icon,
			size:"3.75em"
		});

		ni.created(this._element);
		// this.setHighlighted(false);
	}

	public on<N extends keyof NodeEvents>(name:N, fn:NodeEvents[N]){
		this._events.on(name, fn);
	}

	public off<N extends keyof NodeEvents>(name:N, fn:NodeEvents[N]){
		this._events.off(name, fn);
	}

	public getPosition(){
		return this._position;
	}

	public getX(){
		return this._position.x;
	}

	public getY(){
		return this._position.y;
	}

	public getTemplate(){
		return this._template;
	}

	public setData(d:any){
		this._data = d;
	}

	public getData(){
		return this._data;
	}

	public getParameterData(){
		return this._parameters;
	}

	public getID(){
		return this._id;
	}

	public addMovement(x:number, y:number){
		this.move(x, y);
	}

	public intersectsBBox(bbox:Snap.BBox){
		return Snap.path.isBBoxIntersect(bbox, this._element.getBBox());
	}

	public getPort(portKey:string){
		return this._ports[portKey];
	}

	// public setLabelText(v:string){
	// 	this._label.setText(v);
	// };
	
	public destroy(){
		this._element.remove();
		this._events.emit("destroy");
	};

	public setPosition(x:number, y:number){
		this._position.x = x;
		this._position.y = y;
	};

	public setHighlighted(state:boolean){
		this._outline.toggleShow(state);
	};

	public enableDrag(){
		this._drag.setActive(true);
	}

	public disableDrag(){
		this._drag.setActive(false);
	}

	private _id:string;
	private _root:SnapType.Paper;
	private _template:NodeTemplate<any>;
	private _transformInfo:TransformInfo;
	private _events = new EventBus<NodeEvents>();
	private _positionData = { x: 0, y: 0 };
	private _position:any;
	private _data:any = {};
	private _parameters:any = {};
	private _element:SnapType.Paper;
	// private _guiMap:GUIMap;
	private _ports:Dictionary<Port> = {};
	private _drag:SnapMovementDrag;
	private _outline:SnapOutline;
	private _shadow:SnapShadow;
	// private _label:NodeLabel;

	private portPressed(port:any){
		this._events.emit("portPressed", port);
	}

	private portReleased(port:any){
		this._events.emit("portReleased", port);
	}

	private portHovered(port:any){
		this._events.emit("portHovered", port);
	}

	private portUnhovered(port:any){
		this._events.emit("portUnhovered", port);
	}
 

	// mousedown handler
	private onMousedown(e:any){
		if(e.button === 0) { this._events.emit("press", e); }
	};

	private onMouseup(e:any){
		if(e.button === 0) { this._events.emit("release", e); }
	}

	private onClick(e:any){
		if(e.button === 0) { this._events.emit("click", e); }
	};

	private onDoubleClick(e:any){
		if(e.button === 0) { this._events.emit("dblclick", e); }
	};

	private move(x:number, y:number){
		this._position.x += x;
		this._position.y += y;
		this._events.emit("move", { x, y });
	};

	// context click handler
	private onContext(e:any){
		e.preventDefault();
		this._events.emit("context", e);
	};
}

type EventHandler<T, R> = (p:T) => R;
interface NodeEvents {
	"portPressed":EventHandler<Port,void>,
	"portReleased":EventHandler<Port,void>,
	"portHovered":EventHandler<Port,void>,
	"portUnhovered":EventHandler<Port,void>,
	"press":EventHandler<any,void>,
	"release":EventHandler<any,void>,
	"click":EventHandler<any,void>,
	"dblclick":EventHandler<any,void>,
	"context":EventHandler<any,void>,
	"newPosition":EventHandler<Vector2D,void>,
	"drag":EventHandler<Vector2D,void>,
	"move":EventHandler<Vector2D,void>,
	"destroy":() => void,
	"position-updated":EventHandler<Vector2D,void>
}