


















// vendor
import { defineComponent, computed, ref, PropType } from "@vue/composition-api";

type Config = {

};

export default defineComponent({

	emits:[ "input" ],
	props:{
		value:{
			// type:String
		},
		label:{
			type:String
		},
		config:{
			type:Object as PropType<Config>,
			required:true
		}
	},
	components:{
	},
	setup(props, context){

		return {
		};
	}
});

