import { generateGUID } from "@utils/guid";
import {
	FormItem,
	BaseFormItem,
	FormItemType
} from "@psychlab/types/form";

import * as ItemSettings from "@psychlab/types/form/ItemSettings";

export const getFormItem = (type:FormItemType):FormItem<any> => {
	return {
		...getDefaultBase(),
		type,
		settings: createItemSettings(type),
	}
};

const getDefaultBase = ():BaseFormItem => {
	return {
		id: `${generateGUID()}`,
		name: "",
		hideName:false,
		optional:false,
		comment:"",
		platforms:[],
		body:"",
	}
};

const createItemSettings = (type:string):any => {
	return settingsFactories[type]();
}

const Answer = ():ItemSettings.Answer => {
	return {};
};

const Checkboxes = ():ItemSettings.Checkboxes => {
	return {
		options: [ "A", "B", "C" ]
	};
};

const Dropdown = ():ItemSettings.Dropdown => {
	return {
		options: [ "A", "B", "C" ]
	};
};

const MultipleChoice = ():ItemSettings.MultipleChoice => {
	return {
		options: [ "A", "B", "C" ]
	};
};

const LinearScale = ():ItemSettings.LinearScale => {
	return {
		columns: [ "Min", "", "", "", "Max" ],
		rows: [ "A", "B" ]
	};
};

const Information = ():ItemSettings.Information => {
	return {
		title:"",
		text:""
	}
};

const HTML = ():ItemSettings.HTML => {
	return {
		content:""
	}
};

const Image = ():ItemSettings.EmbedImage => {
	return {
		url:"",
		width: 100
	}
};

const Video = ():ItemSettings.EmbedVideo => {
	return {
		url:"",
		width: 50
	}
};

const settingsFactories:{ [k:string]:() => void } = {
	Answer,
	Checkboxes,
	Dropdown,
	MultipleChoice,
	LinearScale,
	Information,
	HTML,
	Image,
	Video,
};