




























































import { useTranslate } from "@lang";
import { defineComponent, ref, computed } from "@vue/composition-api";
import { ConfirmConfig } from "./IConfirm";

type CallbackHandler = () => void;

const defaults:ConfirmConfig = {
	title:"Confirm",
	description:"",
	variant:"dark",
	okText:"Confirm",
	cancelText:"",
	size:"sm"
};

const colorMap:any = {
	"light":"dark",
}

const getValue = (c:ConfirmConfig|null, k:keyof(ConfirmConfig)) => {
	return c ? c[k] || defaults[k]: defaults[k];
};

export default defineComponent({

	setup(){

		const modal = ref<any>();

		const fn = ref<CallbackHandler|null>(null);
		const show = ref(false);

		const config = ref<ConfirmConfig|null>(null);

		const {
			translate
		} = useTranslate();

		const showCancel = computed(() => {
			return Boolean(cancelText.value);
			// if(!config.value){ return false; }
			// return config.value.cancelText !== undefined;
		});

		const cancelText = computed(() => {
			return getValue(config.value, "cancelText") || translate("prompts.action.cancel");
		});

		const ok = () => {
			if(fn.value){ fn.value(); }
			if(modal.value){
				modal.value.hide();
			}
		};

		const cancel = () => {
			if(!modal.value){ return; }
			modal.value.hide();
		};

		const hide = () => {
			fn.value = null;
			show.value = false;
			config.value = null;
		};

		const open = (c:ConfirmConfig, f:CallbackHandler) => {
			fn.value = f;
			config.value = c;
			show.value = true;
		};

		const textVariant = computed(() => {
			return config.value ? colorMap["danger"] || "light" : "light";
		});
		

		return {
			modal,
			show,
			config,
			title:computed(() => getValue(config.value, "title")),
			description:computed(() => getValue(config.value, "description")),
			noCenterDescription:computed(() => getValue(config.value, "noCenterDescription")),
			okText:computed(() => getValue(config.value, "okText")),
			cancelText,
			variant:computed(() => getValue(config.value, "variant")),
			size:computed(() => getValue(config.value, "size")),
			textVariant,
			showCancel,
			ok,
			cancel,
			hide,
			open,
		};
	}
});
