import * as StudyAPI from "@/services/api/studies";

type Config = {
	filter:{
		enabled?:boolean;
		tag?:string;
		blueprints:string[];
	};
	sort:{
		field:string;
		desc?:boolean;
	};
	pagination:{
		page:number;
		limit:number;
	};
	onStart:() => void;
	onDone:() => void;
}


export const loadStudies = async(cfg:Config) => {

	const { tag, blueprints, enabled } = cfg.filter;

	if(tag && blueprints.length === 0){
		return { studies:[], count:0 };
	}
	
	const { page, limit } = cfg.pagination;
	const { field:sortField, desc:sortDesc } = cfg.sort;

	const sort = `${sortDesc ? '-' : ''}${sortField}`;

	const fields = [ "name", "target", "lastVisited", "created", "enabled", "description" ];

	const q:any = {
		...{ limit, page, sort },
		select: fields.join(" ")
	};

	if(enabled !== undefined){ q["enabled"] = enabled; }

	if(blueprints.length > 0){
		q["target.study[in]"] = blueprints.join(",");

	}

	const { onStart, onDone } = cfg;

	onStart();
	try {
		return await StudyAPI.getStudiesPaginated(q);
	}
	catch(err:any){ console.log(err.message); }

	onDone();

	return { studies:[], count:0 };
};