
type UrlType = "img"|"vid"|"?";

type Dictionary<T> = { [k:string]:T };

export const getUrlType = (sources:string[]|string):UrlType => {
	const arr = Array.isArray(sources) ? sources : [ sources ];
	const types:UrlType[] = Array.from(new Set(arr.map(s => getUrlSourceType(s))));
	if(types.length === 1){ return types[0]; }
	return "?";
};

const getUrlSourceType = (source:string):UrlType => {
	const ext = getUrlExtension(source);
	if(!ext){ return "?"; }
	return typeMap[ext] || "?";
};

export const getUrlExtension = (url:string) => {
	const x = url.split(/[#?]/)[0].split('.').pop();
	return x ? x.trim() : null;
};

/*
https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types
*/
const typeMap = (() => {
	const r:Dictionary<UrlType|undefined> = {};
	const types:Dictionary<string[]> = {
		
		"img":[ "jpg", "jpeg", "png", "gif", "svg", "webp", "apng", "avif" ],
		"vid":[
			"mp4", "mkv", "webm", "ts", // file types
			"mpd", "m3u8", // abr manifests
		]
	};
	Object.keys(types).forEach(k => types[k].forEach(t => r[t] = k as UrlType));
	return r;
})();