import { IAppConfig } from "./IAppConfig";

const envKeys = [
	"API_URL",
	"TUTORIAL_URL",
	"PANORAMA_URL",
] as const;

type EnvKey = typeof envKeys[number];

const sensibleDefaults:Record<EnvKey, string> = {
	API_URL:"http://localhost:5000",
	TUTORIAL_URL:"https://envalys.is/vrpsychlab",
	PANORAMA_URL:"https://panorama.viewer.envalys.is"
};

export const getConfig = ():IAppConfig => {
	return {
		apiURL:loadVar("API_URL"),
		tutorialURL:loadVar("TUTORIAL_URL"),
		panoramaURL:loadVar("PANORAMA_URL")
	}
};

const getWindow = () => (window as any).config || {};
const getEnv = () => (process as any).env || {};

const loadVar = (name: EnvKey) => {
	const v = getEnv()[`VUE_APP_${name}`] || getWindow()[name] || sensibleDefaults[name];
	if (!v) {
		throw new Error(`Config: Unset value for '${name}'.`);
	}
	return v;
};