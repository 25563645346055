

















































// vendor
import {
	defineComponent,
	PropType,
	computed,
	ref,
} from "@vue/composition-api";

export default defineComponent({
	emits:[ "input" ],
	props: {
		options: {
			type: Array as PropType<string[]>,
			required: true,
		},
		value: {
			type: Number,
		},
		showTooltips:{
			type:Boolean,
			default:true,
		},
	},
	setup(props, context) {

		const hovered = ref(-1);

		const labels = computed(() => props.options);

		const setIndex = (v: number) => context.emit("input", v);

		const getTooltip = (index: number) => {
			return {
				title: props.options[index] || (index + 1).toString(),
				interactive: false,
				delay: 0,
				animation:false,
				disabled:!props.showTooltips,
			};
		};

		return {
			setIndex,
			hovered,
			getTooltip,
			labels,
		};
	},
});
