











































// vendor
import {
	defineComponent,
	PropType,
	computed,
	ref,
} from "@vue/composition-api";
// local
import { Answer } from "../../ts/types/DisplayForm";
import DockedFoldout from "../shared/docked-foldout.vue";
import ErrorOverlay from "../shared/error-overlay.vue";

export default defineComponent({
	props: {
		value: {
			type: String,
		},
		showBack: {
			type: Boolean,
			default: true,
		},
		config: {
			type: Object as PropType<Answer>,
			required: true,
		},
	},
	components: {
		DockedFoldout,
		ErrorOverlay,
	},
	setup(props, context) {
		
		const error = ref(false);

		const previous = () => context.emit("previous");
		const next = () => {
			if(!canContinue.value){
				error.value = true;
				return;
			}
			context.emit("next");
		};

		const val = computed({
			get: () => props.value,
			set: (v) => context.emit("input", v),
		});

		const canContinue = computed(() =>
			props.config.optional ? true : isValid(),
		);

		const isValid = () => {
			return (
				Boolean(val.value) &&
				val.value !== undefined &&
				val.value.length > 0
			);
		};

		const getChannels = () => {
			if (val.value === undefined) {
				return [];
			}
			return [
				{
					key: props.config.key,
					data: val.value,
				},
			];
		};

		return {
			error,
			previous,
			next,
			val,
			isValid,
			getChannels,
			canContinue,
		};
	},
});
