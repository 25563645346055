import { defineComponent } from "@vue/composition-api";
import { AbsBox } from "@ui";

export const FadeOverlay = defineComponent({
	components:{
		AbsBox,
	},
	props:{
		opacity:{
			type:Number,
			default:0
		},
		color:{
			type:String,
			default:"#ffffff"
		}
	},
	render(){
		const style = `
			background-color:${ this.color };
			opacity:${ this.opacity };
			z-index:2;
			pointer-events:${ this.opacity === 0 ? "none" : "inherit" }
		`;
		return (
			<AbsBox style={ style }/>
		);
	}
})