



























































// vendor
import { defineComponent, computed, ref, PropType, watch, set } from "@vue/composition-api";
// project
import { Icon, ButtonBase } from "@ui";
import { StudyWidget } from "@/components.studies/study-widget";
// local
import { widgetTemplates as allTemplates } from "@/assets/widget-templates";
import { WizardContext } from "../../ts/types/WizardContext";
import { checkBindingCompatibility } from "../../ts/utils/checkBindingCompatibility";
import { autoBindProps } from "../../ts/utils/autoBindProps";

const getTemplateOptions = (dataOutput:any):any[] => {
	const sourceProps = Object.keys(dataOutput)
	.map(k => {
		const p = dataOutput[k];
		return {
			key:k,
			type:p.type,
			noAutoBind:p.noAutoBind
		}
	});

	const results = allTemplates.map(wt => {
		const targetProps = Object.keys(wt.props)
		.map(k => {
			const p = wt.props[k];
			return {
				key:k,
				type:p.type,
				optional:p.optional,
				noAutoBind:p.noAutoBind
			}
		});
		return {
			compatible:checkBindingCompatibility(sourceProps, targetProps),
			template:wt
		}
	});
	const filtered = results.filter(r => r.compatible.compatible);
	filtered.sort((a, b) => b.compatible.count - a.compatible.count);
	return filtered.map(r => r.template);
};


export default defineComponent({

	emits:[ "state" ],
	props:{
		context: {
			type: Object as PropType<WizardContext>,
			required: true,
		},
	},
	components:{
		Icon,
		ButtonBase,
		StudyWidget,
	},
	setup(props, context){


		const widgetTemplates = getTemplateOptions(props.context.dataSource ? props.context.dataSource.output : {});

		const index = ref(widgetTemplates.findIndex(wt => wt === props.context.renderTemplate));

		const next = () => {
			index.value++;
			if(index.value >= widgetTemplates.length){
				index.value = 0;
			}
		};

		const previous = () => {
			index.value--;
			if(index.value < 0){
				index.value = widgetTemplates.length - 1;
			}
		};

		const selectedTemplate = computed(() => {

			const t = widgetTemplates[index.value];

			const data:any = {};
			
			Object.keys(t.props)
			.forEach(p => {
				data[p] = t.props[p].default;
			});

			return {
				title:t.title,
				template:t.template,
				data
			};
		});


		const templatecount = computed(() => widgetTemplates.length);

		watch(index, (v) => {
			set(props.context, "renderTemplate", widgetTemplates[v]);
			set(props.context, "overrideArgs", {});
			context.emit("state", index.value > -1);
		});
	

		watch(index, v => {
			set(props.context, "dataBindings", autoBindProps(props.context));
		});


		if(index.value < 0){ index.value = 0; }

		return {
			index,
			templatecount,
			selectedTemplate,
			previous,
			next,
		};
	}
});

