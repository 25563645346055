

















import { defineComponent, computed, PropType } from "@vue/composition-api";


export default defineComponent({
	emits: [
		"input"
	],
	props: {
		value: {
			type: Boolean,
		},
		variant:{
			type:String,
			default:"dark",
		},
		onLabel:{
			type:String,
			default:"",
		},
		offLabel:{
			type:String,
			default:"",
		},
		size:{
			type: String,
			default: "md",
		},
		disabled:{
			type:Boolean,
			default:false,
		}
	},
	setup(props, context) {
		const val = computed({
			get: () => props.value,
			set: v => context.emit("input", v),
		});

		const labels = computed(() => {
			return [
				props.offLabel,
				props.onLabel
			];
		})

		const label = computed(() => (props.value ? labels.value[1] : labels.value[0]));

		const toggleValue = () => val.value = !val.value;

		return {
			val,
			label,
			toggleValue,
		};
	},
});
