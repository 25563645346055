








































































// vendor
import { defineComponent, computed, ref, reactive } from "@vue/composition-api";
// project
import { Icon, ButtonBase } from "@ui";
// local
import { WizardResult, WizardConfig, ResultReceiver, WizardStep } from "../ts/types";
import { WizardContext } from "../ts/types/WizardContext";
import { default as WizardFooter } from "./footer.vue";
import { default as StepPath } from "./step-path.vue";
import { default as WizardBody } from "./wizard-body.vue";
import * as Pages from "./pages";
import { useTranslate } from "@lang";


const typeDefaults:any = {
	"string[]":[]
};


const steps:WizardStep[] = [
	{
		name:"widgets.label.configureDataSource",
		component:Pages.ConfigureType,
		icon:"mdi.database-settings"
	},
	{
		name:"widgets.label.chooseRenderTemplate",
		component:Pages.ConfigureUI,
		icon:"mdi.palette"
	},
	{
		name:"widgets.label.bindDataToTemplate",
		component:Pages.BindData,
		icon:"mdi.video-input-component mdi-rotate-270"
	},
	{
		name:"widgets.label.confirmConfiguration",
		component:Pages.Confirm,
		icon:"mdi.check-bold"
	}
];

const initStepState = () => {
	const state:any = {};
	steps.forEach((s, i) => {
		state[i.toString()] = false;
	});
	return state;
};

const getContext = async(config:WizardConfig):Promise<WizardContext> => {
	return {
		name:"New Widget",
		dataArgs:{},
		dataBindings:[],
		channels:config.dataChannels,
		staticProps:[],
		staticArgs:{},
		overrideArgs:{}
	};
};

const extractResult = (context:WizardContext):WizardResult|null => {

	if(!context.renderTemplate){ return null; }
	if(!context.dataSource){ return null; }

	const boundValues = context.dataBindings.map(b => b.split("->")[1]);

	const templateArgs:any = {};

	const oa = context.overrideArgs;
	const t = context.renderTemplate;

	const metadata:any = {};
	
	// drawer metadata
	Object.keys(t.props)
	.forEach(k => {
		const prop = t.props[k];
		metadata[`template/${k}/type`] = prop.type;
		metadata[`template/${k}/optional`] = Boolean(prop.optional);
		if(prop.drawer){
			metadata[`template/${k}/drawer`] = prop.drawer.type;
			if(prop.drawer.options){
				metadata[`template/${k}/drawer/options`] = prop.drawer.options;
			}
		}
	});

	// default values
	Object.keys(oa)
	.forEach(k => {
		const prop = t.props[k];
		if(prop && typeDefaults[prop.type]) {
			templateArgs[k] = typeDefaults[prop.type];
		}
		else {
			templateArgs[k] = oa[k];
		}
	});
	return {
		name:context.name,
		dataType:context.dataSource.name,
		dataArgs:context.dataArgs,
		template:context.renderTemplate.template,
		templateBindings:context.dataBindings,
		templateArgs,
		metadata
	};

};

export default defineComponent({

	components:{
		Icon,
		ButtonBase,
		WizardFooter,
		StepPath,
		WizardBody,
	},
	setup(){

		const activeStepIndex = ref(-1);

		const wizardContext = ref<WizardContext|null>(null);
		
		const show = ref(false);
		const config = ref<WizardConfig|null>(null);
		const receiver = ref<ResultReceiver|null>(null);
		const stepState = reactive<any>(initStepState());

		const {
			translate,
		} = useTranslate();

		

		const open = async (c:WizardConfig, onResult:ResultReceiver) => {
			wizardContext.value = await getContext(c);
			activeStepIndex.value = 0;
			config.value = c;
			receiver.value = onResult;
			show.value = true;
		};

		const onHide = () => {
			wizardContext.value = null;
			show.value = false;
			config.value = null;
			receiver.value = null;
		};

		const onSubmit = () => {
			if(!receiver.value){ return; }
			if(!wizardContext.value){ return; }
			const result = extractResult(wizardContext.value);
			if(result){ receiver.value(result); }
			onHide();
		};

		const previousStep = () => {
			activeStepIndex.value -= 1;
		};

		const nextStep = () => {
			activeStepIndex.value += 1;
		};

		const activeStep = computed(() => {
			return activeStepIndex.value >= 0 ? steps[activeStepIndex.value] : null;
		});

		const canContinue = computed(() => {
			// return true;
			return stepState[activeStepIndex.value.toString()];
		});

		const onState = (v:boolean) => {
			// console.log(v);
			stepState[activeStepIndex.value.toString()] = v;
		};


		return {
			steps,
			activeStep,
			activeStepIndex,
			wizardContext,
			show,
			open,
			onHide,
			onSubmit,
			previousStep,
			nextStep,
			onState,
			translate,
			canContinue,
		};
	}
});

