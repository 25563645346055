import { defineComponent, provide } from "@vue/composition-api";


export const ProvideStudyLanguage = defineComponent({
	props:{
		lang:{
			type:String,
			required:false,
		}
	},
	setup(props){
		provide("lang-resolver", {
			getLanguage:() => props.lang
		});
		return {};
	},
	render(){
		return (
			<span>
				{this.$slots.default}
			</span>
		)
	}
})