// vendor
import { ref, Ref } from "@vue/composition-api";
// project
import { IEditText, IConfirm, IEditColor, IFindIcon, IUploadFile, IRenderRaw } from "@/components.generic/utility-modal";
import { ICreateStudyWizard } from "@components/studies";
import { IContextMenu } from "@/components.generic/context-menu";
import { IBrowseAssetModal } from "@/components.assets/browse-asset-modal";
import { ICreateAssetWizard } from "@/components.assets/create-asset-wizard";
import { ITagAssetModal } from "@components/tags";

type RT<T> = Ref<T|undefined>;

type Context = {
	editText:RT<IEditText>;
	editColor:RT<IEditColor>;
	confirm:RT<IConfirm>;
	context:RT<IContextMenu>;
	findIcon:RT<IFindIcon>;
	uploadFile:RT<IUploadFile>;
	renderRaw:RT<IRenderRaw>;
	createStudy:RT<ICreateStudyWizard>;
	findAsset:RT<IBrowseAssetModal>;
	createAsset:RT<ICreateAssetWizard>;
	tagAsset:RT<ITagAssetModal>;
};

let cached:Context|null = null;

export const useAppModals = ():Context => {
	if(!cached){
		cached = createContext();
	}
	return cached;
};

const createContext = ():Context => {
	const editText = ref<IEditText>();
	const editColor = ref<IEditColor>();
	const confirm = ref<IConfirm>();
	const context = ref<IContextMenu>();
	const findIcon = ref<IFindIcon>();
	const uploadFile = ref<IUploadFile>();
	const renderRaw = ref<IRenderRaw>();
	const createStudy = ref<ICreateStudyWizard>();
	const findAsset = ref<IBrowseAssetModal>();
	const createAsset = ref<ICreateAssetWizard>();
	const tagAsset = ref<ITagAssetModal>();

	return {
		editText,
		editColor,
		confirm,
		createStudy,
		context,
		findIcon,
		uploadFile,
		findAsset,
		createAsset,
		renderRaw,
		tagAsset,
	};
};