import { computed, set, ComputedRef } from "@vue/composition-api";
import { ImageInput, ColorInput, DropdownInput, RangeInput, ToolbarInput } from "@ui";
import { IFormTheme } from "@psychlab/types/form";

type PropDrawer = {
	key:string;
	label:string;
	config:any;
	drawer:any;
	group:string;
	get:() => any;
	set:(v:any) => void;
};

type PropGroup = {
	key:string;
	label:string;
	props:PropDrawer[];
};

const drawerComponents:{ [k in DrawerType]:any } = {
	"dropdown":DropdownInput,
	"color":ColorInput,
	"image":ImageInput,
	"range":RangeInput,
	"toolbar":ToolbarInput,
};

export const useThemeDrawers = (theme:ComputedRef<IFormTheme>) => {

	const props = computed(() => {
		const keys = Object.keys(themePropConfig);
		const r = keys.map(k => {
			const prop = themePropConfig[k as PropKeys];
			if(!prop){ return null; }
			const config:any = JSON.parse(JSON.stringify(prop.drawer));
			delete config["type"];

			return {
				key:k,
				label:k,
				group:prop.group || "",
				drawer:drawerComponents[prop.drawer.type],
				config,
				get(){
					return theme.value[k as PropKeys] || prop.default;
				},
				set(v:any){
					set(theme.value, k,  v);
				},
			}

		}).filter(p => p !== null) as PropDrawer[];
		return r;
	});


	const groups = computed<PropGroup[]>(() => {
		const keys = [ ...(new Set(props.value.map(p => p.group))) ];
		return keys.map(k => {
			const pr = props.value.filter(p => p.group === k);
			return {
				key:k,
				label:k,
				props:pr,
			}
		})

	});

	return {
		groups,
	};
};

type DrawerType = "color"|"dropdown"|"range"|"image"|"toolbar";

type DropdownOption = {
	text?:string;
	icon?:string;
	value:any;
};

type PropDrawerConfig = {
	type:DrawerType;
	options?:DropdownOption[];
	min?:number;
	max?:number;
	step?:number;
};

type PropConfig = {
	label?:string;
	group?:string;
	order?:number;
	default?:any;
	drawer:PropDrawerConfig;
};

type PropKeys = (keyof IFormTheme);

type ThemeConfig = { [k in PropKeys]?:PropConfig }


const drawers:{ [k:string]:PropDrawerConfig } = {
	fontWeight:{
		type:"range",
		min:100,
		step:100,
		max:1000,
	},
	fontFamily:{
		type:"dropdown",
		options:[
			{
				value:undefined,
				text:"default"
			},
			{
				value:"NATS",
				text:"NATS",
			}
		]
	},
	color:{
		type:"color",
	},
	textAlignment:{
		type:"toolbar",
		options:["left", "center", "right", "justify"].map(v => ({
			value:v,
			icon:`mdi.format-align-${v}`,
		}))
	},
	textHeading:{
		type:"toolbar",
		options:[1,2,3,4,5,6].map(o => ({
			value:o,
			icon:`mdi.format-header-${o}`
		}))
	},
	textCase:{
		type:"toolbar",
		options:[
			{
				value:undefined,
				icon:"mdi.format-letter-case",
			},
			{
				value:"upper",
				icon:"mdi.format-letter-case-upper",
			},
			{
				value:"lower",
				icon:"mdi.format-letter-case-lower",
			}
		]
	}
}

const options = {
	toggle:["mdi.checkbox-blank-outline", "mdi.box-shadow" ].map((o, i) => ({
		value:i === 1 ? true : false,
		icon:o,
	})),
	imageFit:[
		{
			value:"cover",
			icon:"mdi.card"
		},
		{
			value:"contain",
			icon:"mdi.contain"
		}
	],
};

enum PropGroups {
	Window = "background",
	Title = "title",
	Box = "box",
	Text = "text",
	Input = "input",
	Radio = "radio",
	Likert = "likert",
	Media = "media",
};

export const themePropConfig:ThemeConfig = {
	// window
	"window.background.color":{
		group:PropGroups.Window,
		default:"#ffffff",
		drawer:drawers.color,
	},
	"window.background.image.url":{
		group:PropGroups.Window,
		drawer:{
			type:"image"
		}
	},
	"window.background.image.opacity":{
		group:PropGroups.Window,
		default:0,
		drawer:{
			type:"range",
			min:0,
			max:1,
			step:0.005,
		}
	},
	"window.background.image.repeat":{
		group:PropGroups.Window,
		default:1,
		drawer:{
			type:"range",
			min:1,
			step:1,
			max:20,
		}
	},
	"window.background.image.fit":{
		group:PropGroups.Window,
		default:"contain",
		drawer:{
			type:"toolbar",
			options:options.imageFit
		},
	},
	// item title
	"item.title.size":{
		group:PropGroups.Title,
		default:4,
		drawer:drawers.textHeading
	},
	"item.title.fontWeight":{
		group:PropGroups.Title,
		default:400,
		drawer:drawers.fontWeight,
	},
	"item.title.fontFamily":{
		group:PropGroups.Title,
		drawer:drawers.fontFamily,
	},
	"item.title.alignment":{
		group:PropGroups.Title,
		default:"left",
		drawer:drawers.textAlignment,
	},
	"item.title.fontCase":{
		group:PropGroups.Title,
		drawer:drawers.textCase,
	},
	"item.title.color":{
		group:PropGroups.Title,
		default:"#ffffff",
		drawer:drawers.color,
	},
	"item.title.background.color":{
		group:PropGroups.Title,
		default:"#ffffff00",
		drawer:drawers.color,
	},
	// item text
	"item.text.fontWeight":{
		group:PropGroups.Text,
		default:400,
		drawer:drawers.fontWeight,
	},
	"item.text.fontFamily":{
		group:PropGroups.Text,
		drawer:drawers.fontFamily,
	},
	"item.text.color":{
		group:PropGroups.Text,
		default:"#000000",
		drawer:drawers.color,
	},
	// box
	"item.box.background.color":{
		group:PropGroups.Box,
		default:"#ffffff00",
		drawer:drawers.color,
	},
	"item.box.border.color":{
		group:PropGroups.Box,
		default:"#ffffff00",
		drawer:drawers.color,
	},
	"item.box.border.width":{
		group:PropGroups.Box,
		default:1,
		drawer:{
			type:"range",
			min:0,
			step:0.5,
			max:6,
		},
	},
	"item.box.divider.color":{
		group:PropGroups.Box,
		default:"#ffffff",
		drawer:drawers.color,
	},
	"item.box.rounding":{
		group:PropGroups.Box,
		default:0,
		drawer:{
			type:"range",
			min:0,
			max:2,
			step:0.05,
		}
	},
	"item.box.shadow":{
		group:PropGroups.Box,
		default:false,
		drawer:{
			type:"toolbar",
			options:options.toggle,
		}
	},
	"input.likert.hover.color":{
		group:PropGroups.Likert,
		default:"#ffffff",
		drawer:drawers.color,
	},
	"input.likert.selected.color":{
		group:PropGroups.Likert,
		default:"#ffffff",
		drawer:drawers.color,
	},
	"input.likert.border.color":{
		group:PropGroups.Likert,
		default:"#ffffff",
		drawer:drawers.color,
	},
	// media
	"media.video.background.color":{
		group:PropGroups.Media,
		default:"#000000",
		drawer:drawers.color,
	},
	"media.image.background.color":{
		group:PropGroups.Media,
		default:"#00000000",
		drawer:drawers.color,
	},
};