













































































// vendor
import { defineComponent, PropType, computed, watch, set, ref } from "@vue/composition-api";
import { Draggable } from "../draggable";
import { generateGUID  } from "@utils/guid";
import { move as moveItem } from "@utils/array";
import { SizeVariant } from "../bootstrap/SizeVariant";
import { ColorVariant } from "../bootstrap/ColorVariant";
import { Icon } from "../icons";

const dragSettings = {
	animation: 200,
	disabled: false,
	ghostClass: "ghost",
};

export default defineComponent({

	emits:[ "input" ],
	props:{
		size:{
			type:String as PropType<SizeVariant>,
			default:"sm"
		},
		variant:{
			type:String as PropType<ColorVariant>,
			default:"dark",
		},
		value: {
			type:Array as PropType<string[]>,
			default:() => []
		}
	},
	components: {
		Draggable,
		Icon,
	},
	setup(props, ctx){

		const inputs = ref([]);

		const displayItems = computed({
			get:() => props.value,
			set:v => { } // handled by drag
		});
		
		const markupId = computed(() => generateGUID());

		watch(() => displayItems.value.length, (v, o) => {
			if(v > o){ focusInput(v-1); }
		});

		const getControlId = (index:number) => {
			return `${markupId.value}_${index}`;
		}

		const onInputChange = (index:number, $event:any) => {
			set(props.value, index, $event);
			ctx.emit("input", props.value);
		};

		const moveToOption = (index:number, $event:any) => {
			if(index >= 0 && index < displayItems.value.length){
				focusInput(index);
			}
		};

		const onBackspace = (index:number, $event:any) => {
			let v = displayItems.value[index];
			if(!v || v.length === 0){
				setTimeout(() => {
					deleteOption(index);
					if(index > 0){
						focusInput(index - 1);
					}
				}, 1);
				return;
			}
		};

		const confirmInput = (index:number, $event:any) => {
			if(index === displayItems.value.length - 1){
				addOption();
			}else{
				focusInput(index + 1);
			}
		};

		const getPlaceholder = (_:number) => {
			return "";
		};

		const focusInput = (index:number) => {
			setTimeout(() => {
				const e = document.getElementById(getControlId(index)) as HTMLInputElement;
				if(!e){ return; }
				e.focus();
			}, 1);
		};

		const addOption = () => {
			props.value.push("");
			ctx.emit("input", props.value);
		};

		const deleteOption = (index:number) => {
			props.value.splice(index, 1);
			ctx.emit("input", props.value);
		};

		const onDragged = (state:{ moved:any }) => {
			const { moved } = state;
			if(moved){
				moveItem(props.value, moved.oldIndex, moved.newIndex);
			}
		};

		return {
			inputs,
			dragSettings,
			displayItems,
			markupId,
			addOption,
			onInputChange,
			moveToOption,
			onBackspace,
			confirmInput,
			getPlaceholder,
			deleteOption,
			onDragged,
			getControlId
		};
	}


});
