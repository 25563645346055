import { defineComponent } from "@vue/composition-api";
import { RunGallery } from "@/components.run/run-gallery";
import { getProps } from "./getProps";

type GalleryParams = {};

export const Gallery = defineComponent({
	emits:[
		"end", "previous"
	],
	components:{
		RunGallery,
	},
	props:getProps<GalleryParams>(),
	setup(_, ctx){
		return {
			onEnd:() => ctx.emit("end"),
			onBack:() => ctx.emit("previous")
		};
	},
	render(){

		const data = this.node?.parameters as any; 
		
		return (
			<RunGallery
			dataNamespace={this.nodeId || ""}
			data={data}
			showBack={this.showBack}
			isLast={this.isLast}
			v-on:back={this.onBack}
			v-on:end={this.onEnd}
			/>
		);
	}
});