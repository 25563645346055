































































































import { defineComponent, computed, ref, watch, reactive } from "@vue/composition-api";
import { Study } from "@/psychlab/types/studies";
import { useTags } from "@/hooks/useTags";
import { useAssets } from "@/hooks/useAssets";
import { usePagination, useStudyContextMenu } from "./hooks";
import { useUserPrefs } from "@/hooks/useUserPrefs";
import { useTranslate } from "@lang";
import { Date as DisplayDate } from "@ui";
import {
	StatusBadge,
	StudySharing,
	BlueprintBadge,
	SessionCount,
} from "./columns";
import { SortableHeader } from "./headers";
import { EmptyMessage } from "./EmptyMessage";
import { Footer } from "./Footer";
import { Header } from "./Header";
import {
	ContextButton,
	DescriptionButton,
} from "./buttons";
import * as Utils from "./utils";
import { default as Router } from "vue-router";

import * as Data from "./data";

enum TableEvents {
	Refreshed = "refreshed",
}

enum RouteNames {
	StudyDashboard = "Run.Group",
}

enum Translations {
	OpenStudyDashboard = "studies.action.openDashboard",
	Rename = "action.rename",
	RenameStudy = "studies.label.renameStudy",
	SetFavourite = "action.favourite",
	RemoveFavourite = "action.unfavourite",
	BlueprintVersion = "studies.label.blueprintVersion",
	ClosingStudy = "studies.message.onClosingStudy",
	OpeningStudy = "studies.message.onOpeningStudy",
	CloseStudy = "studies.action.closeStudy",
	OpenStudy = "studies.action.openStudy",
	Understood = "prompts.action.understood",
	Cancel = "prompts.action.cancel",
	Sharing = "label.sharing",
	Sessions = "studies.label.sessions",
	Close = "action.close",
	Open = "action.open",
	Name = "label.name",
	Created = "label.created",
	LastVisited = "label.lastVisited",
}

const Divider = defineComponent({
	template:`<hr class="bg-secondary m-0"/>`,
});

const cconfig:Record<string,any> = {
	"name":{ sort:true, tsize:"80", },
	"target.study":{ },
	"sessions":{ tsize:"70" },
	"created":{ tsize:"60", sort:true },
	"lastVisited":{ tsize:"60", sort:true },
	"enabled":{ cls: "tcol-01", tsize:"80" },
	"controls":{ cls:"tcol-01", }
}

const columns = Object.keys(cconfig).map(k => ({
	key:k,
	label:"",
	sortable:cconfig[k].sort || false,
	thClass:`text-light noselect txt-80 font-weight-light`,
	tdClass:`align-middle txt-${cconfig[k].tsize || "80"} ${cconfig[k].cls || ""}`
}));

const openStudyRoute = (studyId:string, router:Router) => {
	router.push({
		name:RouteNames.StudyDashboard,
		params:{ groupId:studyId }
	});
};

export default defineComponent({
	props:{
		tag:{
			type:String
		},
		refreshKey:{
			type:String,
			default:"",
		}
	},
	components:{
		StatusBadge,
		StudySharing,
		BlueprintBadge,
		SessionCount,
		DisplayDate,
		SortableHeader,
		EmptyMessage,
		Footer,
		Divider,
		ContextButton,
		DescriptionButton,
		Header,
	},
	setup(props, context){

		const router = context.root.$router;

		const loading = ref(false);

		const { total, currentPage, perPage, footerText } = usePagination({
			context,
			queryPrefix:"sl_",
		});

		const { translate } = useTranslate();
		const { studyListLimit } = useUserPrefs();

		const table = ref<any>();
		const filterBlueprints = ref<string[]>([]);
		const qe = Utils.getQueryValue("enabled", context, "1");
		const qs = Utils.loadQuerySort(context);

		const filter = reactive({
			enabled:qe === "1" ? true : false,
		});

		const sort = reactive({
			field:qs.prop,
			desc:qs.desc,
		});

		let initialPage = currentPage.value;

		const { open:openStudyContext } = useStudyContextMenu({
			sort,
			router,
			refresh:() => refresh()
		});

		perPage.value = studyListLimit.value;

		watch(() => filter.enabled, v => Utils.setQueryValue("enabled", v ? "1" : "0", context));

		const { getTaggedItems } = useTags();

		const { assets } = useAssets();

		const blueprints = computed(() => {
			if(!props.tag){
				return assets.value
				.filter(asset => asset.type === "Graph");
			}

			const tagged = getTaggedItems(props.tag);
			return assets.value
			.filter(asset => {
				return asset.type === "Graph" // is blueprint
				&& tagged.findIndex(ti => ti.item === asset._id) > -1; // is tagged
			});
		});

		const blueprintIds = computed(() => {
			if(filterBlueprints.value.length === 0){
				return blueprints.value.map(bp => bp._id);
			}
			return filterBlueprints.value;
		});

		const tableConfig = computed(() => {
			return {
				fields:columns,
				items:async () => {
					const { studies, count } = await Data.loadStudies({
						filter:{
							enabled:filter.enabled,
							tag:props.tag,
							blueprints:blueprintIds.value,
						},
						pagination:{
							page:currentPage.value,
							limit:perPage.value
						},
						sort:{
							field:sort.field,
							desc:sort.desc,
						},
						onStart:() => loading.value = true,
						onDone:() => loading.value = false,
					});
					total.value = count;
					return studies;

				},
				primaryKey:'_id',
				hover:true,
				borderless:true,
				showEmpty:true,
				noSortReset:true,
				tbodyTrClass:"pointer",
				tableVariant:'dark',
				headVariant:'dark',
				theadClass:'border-bottom border-secondary',
			}
		});

		const refresh = () => table.value?.refresh();

		watch([ perPage, currentPage, blueprintIds, () => filter.enabled ], refresh);

		watch(perPage, () => studyListLimit.value = perPage.value);

		watch(() => props.refreshKey, refresh);

		return {
			table,
			tableConfig,
			filter,
			loading,
			footerText,
			phrases:computed(() => ({
				sessions:translate(Translations.Sessions),
				blueprintVersion:translate(Translations.BlueprintVersion),
				sharing:translate(Translations.Sharing),
			})),
			pagination:computed(() => ({
				page:currentPage.value,
				limit:perPage.value,
				rows:total.value,
				setPage(v:number){
					currentPage.value = v;
				},
				setLimit(v:number){
					perPage.value = v;
				},
			})),
			sortableHeaders:computed(() => {
				return [ "name", "created", "lastVisited" ].map(prop => ({
					name:prop,
					label:translate(`label.${prop}`),
					active:sort.field === prop,
					desc:sort.desc
				}));
			}),
			onRefreshed(){
				if(initialPage >= 0){
					currentPage.value = initialPage;
					initialPage = -1;
				}
				context.emit(TableEvents.Refreshed);
			},
			onSortChanged(ctx:{ sortBy:string, sortDesc:boolean }){
				sort.field = ctx.sortBy;
				sort.desc = ctx.sortDesc;
				if(!sort.field){
					Utils.setQueryValue("sl_sort", undefined, context);
					return;
				}
				const qs = `${sort.desc ? '-' : ''}${sort.field}`;
				Utils.setQueryValue("sl_sort", qs, context);
			},
			openStudyDashboard(sid:string){
				openStudyRoute(sid, router);
			},
			openContext(e:Event, study:Study){
				openStudyContext(e, study);
			},
			refresh,
			setSort(v:string, desc?:boolean){
				sort.field = v;
				if(desc !== undefined){
					sort.desc = desc;
				}
			}
		};
	}
});
