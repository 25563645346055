










// vendor
import { defineComponent, computed, ref } from "@vue/composition-api";
import { Icon } from "@ui";

export default defineComponent({
	props:{
		icon:{
			type:String,
			default:"",
		}
	},
	components:{
		Icon,
	},
	setup(props, ctx){

		return {
		};
	}
});

