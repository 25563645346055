











































































// vendor
import { computed, defineComponent, PropType } from "@vue/composition-api";
// project
import { StudyLabelSelect } from "@/components.studies/study-label-select";
import { Session, SessionLabel } from "@psychlab/types";
import { useTranslate } from "@lang";
import { Icon } from "@ui";

export const SELECTION_TAB_NAME = "Selection";

export default defineComponent({
	emits: [
		"back",
		"update-session-labels",
	],
	props: {
		count: {
			type: Number,
			default: 0,
		},
		page: {
			type: Number,
			default: 1,
		},
		selecting:{
			type:Boolean,
			default:false,
		},
		index:{
			type:Number,
			default:-1,
		},
		session:{
			type:Object as PropType<Session>,
		},
		labels: {
			type: Array as PropType<SessionLabel[]>,
			default:() => [],
		},
	},
	components:{
		StudyLabelSelect,
		Icon,
	},
	setup(props, ctx) {


		const { translate } = useTranslate();

		const cycleLabel = computed(() => {
			return translate("label.indexOfLength")
			.replace("$i", (props.index + 1).toString())
			.replace("$l", props.count.toString());
		});


		const prevDisabled = computed(() => {
			return props.page === 1 && props.index === 0;
		});

		const nextDisabled = computed(() => {
			return props.index >= props.count - 1
		});
		const onBack = () => {
			ctx.emit("back");
		};

		const onPrev = () => {
			ctx.emit("prev");
		};

		const onNext = () => {
			ctx.emit("next");
		};

		const setSessionLabels = (labels:string[]) => {
			ctx.emit("update-session-labels", labels)
		};

		return {
			prevDisabled,
			nextDisabled,
			cycleLabel,
			onBack,
			onPrev,
			onNext,
			setSessionLabels,
			translate,
		};
	},
});
