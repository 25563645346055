import { Graph, GraphNode } from "@/psychlab/types/graph";
import { Scene } from "@/psychlab/types/scene";
import * as AssetAPI from "@/services/api/assets";

export type UpdateReport = {
	entries:UpdateEntry[];
};

export type UpdateEntry = {
	description:string;
	handler:UpdateHandler;
}

type UpdateHandler = () => Promise<string|null>;

type NodeProcessor = (node:GraphNode<any>, deps:Record<string,string|null>) => Promise<UpdateEntry[]>;

export const getUpdateReport = async (g:Graph):Promise<UpdateReport> => {

	const nodes = Object.keys(g.nodes)
	.map(k => g.nodes[k])
	.filter(n => Boolean(processors[n.type]));

	const dassets = findGraphDependencies(g, Object.keys(g.nodes)).map(r => r.split("@")[0]);

	const deps = await findLatestVersions(dassets);
	const names = await findAssetNames(dassets);

	const q = nodes.map(node => {
		const p = processors[node.type];
		return p(node, deps);
	});

	const r = await Promise.all(q);
	const entries:UpdateEntry[] = [];
	r.forEach(ee => entries.push(...ee));
	entries.forEach(e => e.description = names[e.description])

	return {
		entries,
	}
};

const getSceneEntry:NodeProcessor = async (node:GraphNode<Scene>, deps):Promise<UpdateEntry[]> => {
	const points = node.parameters.points || [];
	return points.map(p => getRefPropUpdate("form", p, deps)).filter(e => e !== null) as UpdateEntry[];
};

const parseRef = (v:any) => {
	if(!v){ return null; }
	if(typeof(v) === "string"){
		const [ asset, version ] = v.split("@");
		if(asset && version){ return { asset, version }; }
	}
	if(typeof(v) === "object" && v.ref){
		const asset = v.ref.asset as string;
		const version = v.ref.version as string;
		return { asset, version };
	}
	return null;
};

const getRefPropUpdate = (key:string, ob:any, deps:Record<string,string|null>):UpdateEntry|null => {
	const ref = parseRef(ob[key]);
	if(!ref){ return null; }
	const { asset, version } = ref;
	const latestVersion = deps[asset];
	if(!latestVersion){ return null; }
	if(version === latestVersion){ return null; }
	return {
		description:asset,
		async handler(){
			ob[key] = `${asset}@${latestVersion}`;
			return null;
		}
	};
};

const getAssetEntry:NodeProcessor = async (node:any, deps):Promise<UpdateEntry[]> => {
	const e = getRefPropUpdate("asset", node.parameters, deps);
	return e ? [ e ] : [];
};

const processors:{ [k:string]:NodeProcessor } = {
	"Form":getAssetEntry,
	"Gallery":getAssetEntry,
	"Scene":getSceneEntry,
};

const findLatestVersions = async (assets:string[]) => {
	const map:Record<string,string|null> = {};
	assets.forEach(a => map[a] = null);
	await Promise.all(assets.map(a => new Promise<void>(async res => {
		try { map[a] = (await AssetAPI.getAsset(a)).latestVersion; }
		catch {}
		res();
	})));
	return map;
};

const findGraphDependencies = (graph:Graph, path:string[]) => {
	const nodes = path.map(nid => graph.nodes[nid]);
	const formNodes = nodes.filter(n => n.type === "Form");
	const galleryNodes = nodes.filter(n => n.type === "Gallery");
	const scenes = nodes.filter(n => n.type === "Scene").map(n => n.parameters as Scene);
	const rprops:any[] = [];
	// extract ref props
	[ ...formNodes, ...galleryNodes].forEach(n => rprops.push(n.parameters["asset"]));
	scenes.forEach(s => (s.points || []).forEach(p => rprops.push(p["form"])));
	const ids = rprops.map(rp => stringifyAssetRef(rp)).filter(ref => ref !== null) as string[];
	return Array.from(new Set(ids));
};

const stringifyAssetRef = (v:any) => {
	const ref = parseRef(v);
	if(!ref){ return null; }
	const { asset, version } = ref;
	return `${asset}@${version}`;
};


const findAssetNames = async (assets:string[]) => {
	const map:Record<string,string> = {};
	assets.forEach(a => map[a] = "");
	await Promise.all(assets.map(a => new Promise<void>(async res => {
		try { map[a] = (await AssetAPI.getAsset(a)).name; }
		catch {}
		res();
	})));
	return map;
};