












// vendor
import { defineComponent, computed, PropType } from "@vue/composition-api";

type TextConfig = {
	placeholder?:string;
}

export default defineComponent({
	emits: [
		"input"
	],
	props: {
		value: {
			type: String,
		},
		config:{
			type: Object as PropType<TextConfig>,
		},
		size:{
			type: String,
			default: "lg",
		}
	},
	setup(props, context) {
		const val = computed({
			get: () => props.value,
			set: v => context.emit("input", v),
		});

		return {
			val,
		};
	},
});
