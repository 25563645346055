import { defineComponent } from "@vue/composition-api";

export const Footer = defineComponent({
	props:{
		text:{ type:String, default:"", }
	},
	render(){
		return (
			<div class="p-1 d-flex">
				<div class="mr-auto">
					<small class="text-muted m-1">
						{ this.text }
					</small>
				</div>
				<div class="ml-auto"/>
			</div>
		);
	}
});