













// vendor
import { defineComponent, computed, ref, PropType } from "@vue/composition-api";
// project
import { ReorderableList } from "@/components.generic/reorderable-list";
import { StringListConfig } from "../../ts/Config";

export default defineComponent({
	props:{
		value:{
			type:Array as PropType<string[]>,
			required:true,
		},
		config:{
			type:Object as PropType<StringListConfig>,
			default:() => {},
		},
		size:{
			type:String,
		}
	},
	components:{
		ReorderableList,
	},
	setup(){
		return {};
	}
});

