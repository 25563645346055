
const keys = [ "en", "is" ] as const;

export type LangKey = typeof keys[number];

type LangInfo = {
	readonly label:string;
	readonly icon:string;
}

export const languages:Readonly<Record<LangKey, LangInfo>> = {
	"en":{
		label:"English",
		icon:"/icons/flag-gb.svg"
	},
	"is":{
		label:"Íslenska",
		icon:"/icons/flag-is.svg"
	},
};