import { NodeTemplate } from "@/components.edit/edit-graph";

export type SceneParams = {
	paths:string|null,
	points:any[]
}

export const Scene:NodeTemplate<SceneParams> = {
	name: "Scene",
	// icon: "/img/graph/3d.png",
	// icon: "city-variant",
	icon: "rotate-3d text-black-50",
	parameters:{
		paths:null,
		points:[]
	},
	ports: {
		start: {
			type: "input",
			label: {
				text: "",
				attributes: {
					x: 10
				}
			},
			x: -50
		},
		exit: {
			type: "output",
			label: {
				text: "",
				style: {
					"text-anchor": "end"
				},
				attributes: {
					x: -10
				}
			},
			x: 50
		}
	},
	template:`
	<svg style="overflow:visible" x="-50" y="-50">
		<rect rx="100" ry="100" width="100" height="100" strokeWidth="1" stroke="white" xfill="#5ace92" fill="#0a9931"></rect>
	</svg>
	`
};