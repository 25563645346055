









import { defineComponent, ref } from "@vue/composition-api";
import { RenderDropdown } from "@form-ui";
import { Dropdown } from "@psychlab/types/form";
import { getItemProps } from "./getItemProps";

export default defineComponent({
	props:{
		...getItemProps<Dropdown>()
	},
	components:{
		RenderDropdown
	},
	setup(){
		return {
			val:ref(0)
		};
	},
});

