









































import { defineComponent, computed } from "@vue/composition-api";
import { Icon } from "../icons";

export default defineComponent({
	props:{
		open:{
			type:Boolean,
		},
		variant:{
			type:String,
			default:"dark"
		},
		title:{
			type:String,
			default:"Menu"
		},
		icon:{
			type:String,
			required:false,
		}
	},
	components:{
		Icon,
	},
	setup(props){
		const textVariant = computed(() => {


			return ({
				"light":"dark",
			} as any)[props.variant] || "light"


		});


		return {
			textVariant,
		};
	}
});

