



































// vendor
import { defineComponent, computed, ref, PropType } from "@vue/composition-api";
import { format as formatDate } from "date-fns";
// project
import { Asset, AssetVersion } from "@/psychlab/types/assets";
import { formatDateStringRecent } from "@/utils/text";
import { getAssetTypes } from "@/psychlab/meta";

export default defineComponent({

	emits:[
		"click",
	],
	props:{
		asset:{
			type:Object as PropType<Asset>,
			required:true,
		},
		active:{
			type:Boolean,
			default:false,
		},
		disabled:{
			type:Boolean,
			default:false,
		},
	},
	setup(props, context){

		const dateLabel = computed(() => {
			// return formatDateStringRecent(props.asset.lastModified)
			const l = formatDate(new Date(props.asset.lastModified), "EEEE LLL d, Y");
			return l;
		});

		const typeIcon = computed(() => {
			const tem = getAssetTypes().find(t => t.name === props.asset.type);
			return tem ? tem.icon : "";
		});

		const onClick = () => {
			context.emit("click");
		};

		return {
			typeIcon,
			dateLabel,
			onClick,
		};
	}
});

