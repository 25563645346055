























































































































// vendor
import { defineComponent, ref, computed } from "@vue/composition-api";
// local
import { EditColorConfig, EditColorHandler } from "./IEditColor";
import { useTranslate } from "@lang";


export default defineComponent({

	setup(props){

		const { translate } = useTranslate();

		const value = ref("");
		const fn = ref<EditColorHandler|null>(null);
		const show = ref(false);
		const config = ref<EditColorConfig|null>(null);
		const modal = ref(null);

		const palette = computed(() => {
			if(!config.value){ return []; }
			if(config.value.palette){ return config.value.palette; }
			return [
				"#f6f8f9",
				"#e8384f",
				"#fd817d",
				"#fdae33",
				"#eecc16",
				"#a4c61a",
				"#62bb35",
				"#37a862",
				"#208ea3",
				"#4178bc",
				"#7a71f6",
				"#aa71ff",
				"#e37cff",
				"#ea4e9d",
				"#fca7e4",
				"#8d9f9b",
			];
		});

		const hideBackdrop = computed(() => {
			return config.value ? config.value.hideBackdrop : false;
		});

		const variant = computed(() => {
			return config?.value?.variant || "dark";
		});

		const textVariant = computed(() => {
			return ({
				"light":"dark"
			} as any)[variant.value] || "light";
		});

		const canSave = computed(() => {
			if(config.value && config.value.validator){
				config.value.validator(value.value);
			}
			return true;
		});

		const confirmText = computed(() => config?.value?.confirmText || translate("prompts.action.confirm"));
		const cancelText = computed(() => config?.value?.cancelText || translate("prompts.action.cancel"));

		const title = computed(() => {
			if(!config.value){ return ""; }
			return config.value.title || translate("action.editColor")
		});

		const ok = () => {
			if(fn.value){ fn.value(value.value); }
			(modal.value as any).hide();
		};

		const hide = () => {
			fn.value = null;
			show.value = false;
			value.value = "";
			config.value = null
		};

		const open = (c:EditColorConfig, f:EditColorHandler) => {
			config.value = c;
			fn.value = f;
			show.value = true;
			value.value = c.value;
		};

		return {
			value,
			fn,
			show,
			canSave,
			variant,
			textVariant,
			confirmText,
			cancelText,
			modal,
			title,
			hideBackdrop,
			ok,
			hide,
			open,
			palette,
		};
	}
});
