


















// vendor
import { defineComponent, computed, ref, PropType } from "@vue/composition-api";

type RangeConfig = {
	min:number;
	max:number;
};

export default defineComponent({
	emits: [
		"input",
	],
	props: {
		value: {
			type: Number,
			default:0,
		},
		size:{
			type:String,
			default:"md",
		},
		config: {
			type: Object as PropType<RangeConfig>,
			default:() => ({ min:0, max:1 }),
		},
	},
	setup(props, context) {

		const lastEdit = ref(Date.now());

		const val = computed({
			get:() => {
				return props.value;
			},
			set:(v) => {
				context.emit("input", v);
			},
		});
		
		const setValue = (v:number, time:number) => {
			if(time < lastEdit.value){ return; }
			context.emit("input", v);
		};

		return {
			val,
		};
	},
});
