





































import { defineComponent, computed, PropType } from "@vue/composition-api";
import { FormItem } from "@psychlab/types/form";
import { AbsBox } from "@ui";
import { generateGUID } from "@utils/guid";
import { useTranslate } from "@lang";

type InfoItem = {
	text:string;
	variant:string;
};

export default defineComponent({

	emits:[  ],
	props:{
		data:{
			type:Object as PropType<FormItem<any>>,
			required:true
		}
	},
	components:{
		AbsBox
	},
	setup(props, ctx){

		const { translate } = useTranslate();


		const items = computed(() => {

			const items:InfoItem[] = [];

			if(props.data.optional){
				items.push({
					text:translate("forms.label.optional"),
					variant:"success"
				})
			}

			if(props.data.hideName){
				items.push({
					text:translate("forms.action.hideTitle"),
					variant:"dark"
				})
			}

			items.forEach(it => it.text = it.text.toUpperCase());


			return items;
			


		})
		
		

		return {
			items,
			translate,
			generateGUID,
		};
	}
});

