import { isDropboxLink, parseDropboxLink } from "@utils/url";



const maxThumbWidth = 150;

export const getCanvasThumbnail = (canvas:HTMLCanvasElement) => {
	return canvas.toDataURL("data:image/png;");
};


const imgTypes = [ "jpg", "jpeg", "png", "gif" ];
const vidTypes = [ "mp4" ];

const getUrlType = (url:string) => {
	
	url = url.split(/[?#]/)[0];

	const ext = url.split(".").pop() || "";
	if(imgTypes.includes(ext)){ return "img"; }
	if(vidTypes.includes(ext)){ return "vid"; }
	return "?";
};

const processUrls = (urls:string[]):string[] => {

	

	return urls.map(u => {

		if(isDropboxLink(u)){
			return parseDropboxLink(u);
		}

		return u;


	})
	

};


export const getUrlThumbnail = async (sources:string[], el:HTMLElement) => {

	sources = processUrls(sources);
	
	const types = Array.from(new Set(sources.map(src => getUrlType(src))));

	if(types.length !== 1){
		throw Error("Unable to generate thumbnail from mixed sources.");
	}
	
	switch(types[0]){
		case "vid": return await getVideoThumbnail(sources, el);
		case "img": return await getImageThumbnail(sources, el);
	}
	return "";
};


const getImageThumbnail = async (sources:string[], el:HTMLElement):Promise<string> => {


	// const sources = processUrls(urls);

	const url = sources[0];

	const root = document.createElement("div");
	root.style.overflow = "hidden";
	root.style.width = "1px";
	root.style.height = "1px";
	root.style.opacity = "0";
	root.style.pointerEvents = "none";
	root.style.position = "absolute";
	const img = new Image();
	img.crossOrigin = "Anonymous";
	root.appendChild(img);
	el.appendChild(root);

	return new Promise((resolve, reject) => {


		img.onload = () => {
			const s = maxThumbWidth / Math.max(img.width, img.height);
			const w = img.width * s;
			const h = img.height * s;
			const canvas = document.createElement("canvas");
			canvas.width = w;
			canvas.height = h;
			const ctx = canvas.getContext("2d");
			if(ctx){ ctx.drawImage(img, 0, 0, w, h); }
			root.remove();
			resolve(getCanvasThumbnail(canvas));
		};
		
		img.onerror = () => {
			root.remove();
			reject();
		};

		img.src = url;
		

	});
};


export const readFile = (file:File):Promise<string|ArrayBuffer|null> => {
	return new Promise((res, reject) => {
		const reader = new FileReader();
		reader.onload = () => res(reader.result);
		reader.onerror = reject;
		reader.readAsDataURL(file);
	});
};

export const fileToThumbnail = (file:File):Promise<string|null> => {

	return new Promise(async (res, reject) => {

		const data = await readFile(file);

		if(!data){ return reject("Error reading file"); }
		const img = new Image();

		img.src = data + "";

		img.onload = () => {
			const s = maxThumbWidth / Math.max(img.width, img.height);
			const w = img.width * s;
			const h = img.height * s;
			const canvas = document.createElement("canvas");
			canvas.width = w;
			canvas.height = h;
			const ctx = canvas.getContext("2d");
			if(ctx){ ctx.drawImage(img, 0, 0, w, h); }
			res(getCanvasThumbnail(canvas));
		};

		img.onerror = reject;
	});
};


const getVideoThumbnail = (sources:string[], el:HTMLElement):Promise<string> => {

	// const sources = processUrls(urls);

	const root = document.createElement("div");
	root.style.overflow = "hidden";
	root.style.width = "1px";
	root.style.height = "1px";
	root.style.opacity = "0";
	root.style.pointerEvents = "none";
	root.style.position = "absolute";

	el.appendChild(root);

	const video = document.createElement("video");
	sources.forEach(src => {
		const stag = document.createElement("source");
		stag.src = src;
		video.append(stag);
	});


	root.appendChild(video);
	video.setAttribute("muted", "true");
	// video.src = url;
	video.muted = true;
	video.load();
	video.play();
	(video as any).playsInline = true;
	video.crossOrigin = "Anonymous";
	
	return new Promise((resolve, reject) => {
		video.addEventListener("loadeddata", function() {
			video.currentTime = 1;
			video.addEventListener("seeked", () => {
				try {
					const s = maxThumbWidth / Math.max(video.videoWidth, video.videoHeight);
					
		
					const w = video.videoWidth * s;
					const h = video.videoHeight * s;
					const canvas = document.createElement("canvas");
					canvas.width = w;
					canvas.height = h;
					const ctx = canvas.getContext("2d");
					if(ctx){ ctx.drawImage(video, 0, 0, w, h); }
					renderVideoFrame(video, canvas);
					resolve(getCanvasThumbnail(canvas));
				}
				catch(err){
					console.log(err);
					reject();
				}
				root.remove();

			});
		}, false);
	});
}


const renderVideoFrame = (video:HTMLVideoElement, canvas:HTMLCanvasElement) => {
	const ctx = canvas.getContext("2d");
	if(!ctx){ return null; }
	const cw = canvas.width;
	const ch = canvas.height;
	const ratio = video.videoHeight / video.videoWidth;
	
	let tw = cw;
	let th = ch * ratio;
	const s = ch / th;
	th *= s;
	tw *= s;

	let ox = 0;
	let oy = 0;

	if(cw > ch){ ox = (-cw * computeOffset(cw, ch)); }
	else if(ch > cw){ oy = (-ch * computeOffset(ch, cw)); }

	ctx.drawImage(video, ox, oy, tw, th);
};


const computeOffset = (x:number, y:number) => {
	const z = x - y;
	return z / x;
};