import { PhraseDictionary } from "../types";
import { mergeDictionaries } from "../mergeDictionaries";

const label:PhraseDictionary  = {
	"form":{
		en:"Form",
		is:"Spurningalisti",
	},
	"items":{
		en:"Items",
		is:"Stök",
	},
	"shuffleSettings":{
		en:"Shuffle Settings",
		is:"Slembistillingar"
	},
	"themeSettings":{
		en:"Theme Settings",
		is:"Þemustillingar"
	},
	"theme":{
		en:"Theme",
		is:"Þema"
	},
	"themes":{
		en:"Themes",
		is:"Þemu"
	},
	"section":{
		en:"Section",
		is:"Síða"
	},
	"sections":{
		en:"Sections",
		is:"Síður"
	},
	"sectionIndexOfLength":{
		"en": "Section $i of $l",
		"is": "Síða $i af $1"
	},
	"information":{
		"en": "Information",
		"is": "Upplýsingar"
	},
	"answer":{
		"en": "Answer",
		"is": "Svar"
	},
	"checkboxes":{
		"en": "Checkboxes",
		"is": "Valkostir"
	},
	"multiplechoice":{
		"en": "Multiple Choice",
		"is": "Krossaspurning"
	},
	"dropdown":{
		"en": "Dropdown",
		"is": "Fellilisti"
	},
	"linearscale":{
		"en": "Linear Scale",
		"is": "Línulegur skali"
	},
	"html":{
		en:"HTML",
		is:"HTML"
	},
	"image":{
		en:"Image",
		is:"Mynd"
	},
	"video":{
		en:"Video",
		is:"Myndband"
	},
	"title":{
		"en": "Title",
		"is": "Titill"
	},
	"body":{
		"en": "Body",
		"is": "Megintexti"
	},
	"content":{
		en:"Content",
		is:"Efni"
	},
	"text":{
		en:"Text",
		is:"Texti"
	},
	"options":{
		en:"Options",
		is:"Valmöguleikar"
	},
	"range":{
		"en": "Range",
		"is": "Svið"
	},
	"rows":{
		en:"Rows",
		is:"Raðir"
	},
	"scales":{
		en:"Scales",
		is:"Skalar"
	},
	"columns":{
		en:"Columns",
		is:"Dálkar"
	},
	"itemShuffling":{
		"en": "Item Shuffling",
		"is": "Atriðastokkun"
	},
	"shuffle":{
		"en": "Shuffle",
		"is": "Stokkun"
	},
	"pickOne":{
		"en": "Pick One",
		"is": "Velja eitt"
	},
	"none":{
		"en": "None",
		"is": "Ekkert"
	},
	"notes":{
		"en": "Notes",
		"is": "Athugasemdir"
	},
	"comment":{
		"en": "Notes",
		"is": "Athugasemdir"
	},
	"item":{
		"en": "Item",
		"is": "Atriði"
	},
	"platform":{
		"en": "Platform",
		"is": "Grunnur"
	},
	"all":{
		en:"All",
		is:"Allt"
	},
	"desktop":{
		"en": "Desktop",
		"is": "Skjáborð"
	},
	"mobile":{
		en:"Mobile",
		is:"Snjalltæki"
	},
	"responseOptional":{
		en:"Response Optional",
		is:"Valfrjálst svar"
	},
	"responseRequired":{
		en:"Response Required",
		is:"Krafist svars"
	},
	"optional":{
		en:"Optional",
		is:"Valfrjálst"
	},
	"required":{
		en:"Required",
		is:"Krafist"
	},
	"shuffleOff":{
		en:"Shuffling Off",
		is:"Engin slembivirkni"
	},
	"sectionName":{
		en:"Section Name",
		is:"Síðunafn"
	},
	"newSection":{
		en:"New Section",
		is:"Ný síða"
	},
	"flow":{
		en:"Flow",
		is:"Flæði"
	},
	"copyOf":{
		en:"Copy of $n",
		is:"Afrit af $n"
	},
	"newAnswer":{
		en:"New Answer",
		is:"Nýtt textasvar"
	},
	"newCheckboxes":{
		en:"New Checkboxes",
		is:"Nýir valkostir"
	},
	"newDropdown":{
		en:"New Dropdown",
		is:"Nýr fellilisti"
	},
	"newMultipleChoice":{
		en:"New Multiple Choice",
		is:"Ný krossaspurning"
	},
	"newLinearScale":{
		en:"New Linear Scale",
		is:"Nýr línulegur skali"
	},
	"newHTML":{
		en:"New HTML",
		is:"Nýtt HTML"
	},
	"newInformation":{
		en:"New Information",
		is:"Nýjar upplýsingar"
	},
	"newImage":{
		en:"New Image",
		is:"Ný mynd"
	},
	"newVideo":{
		en:"New Video",
		is:"Nýtt myndband"
	},
	"profileName":{
		en:"Profile Name",
		is:"Þemanafn"
	},
	"darkTheme":{
		en:"Dark Theme",
		is:"Dökkt þema"
	},
	"lightTheme":{
		en:"Light Theme",
		is:"Ljóst þema"
	},
	"lightThemeMinimal":{
		en:"Light Theme (minimal)",
		is:"Ljóst þema (einfalt)"
	},
	"dark":{
		en:"Dark",
		is:"Dökkt"
	},
	"light":{
		en:"Light",
		is:"Ljóst"
	},
	"lightMinimal":{
		en:"Simple",
		is:"Einfalt"
	},
	"legend":{
		en:"Legend",
		is:"Tákn"
	},
	"allPlatforms":{
		en:"All Platforms",
		is:"Öll tæki"
	},
	"previewMode":{
		en:"Preview Mode",
		is:"Skoðunarhamur",
	},
	"backgroundImage":{
		en:"Background Image",
		is:"Backgrunnsmynd"
	},
};

const action:PhraseDictionary = {
	"setDefault":{
		en:"Set Default",
		is:"Stilla sjálfvalið"
	},
	"addBody":{
		en:"Add Body",
		is:"Bæta við megintexti"
	},
	"removeBody":{
		en:"Remove Body",
		is:"Fjarlægja megintexta"
	},
	"setOptional":{
		en:"Set Optional",
		is:"Seta valfrjálst"
	},
	"setRequired":{
		en:"Set Required",
		is:"Krefjast svars"
	},
	"deleteItem":{
		en:"Delete Item",
		is:"Eyða atriði"
	},
	"duplicateItem":{
		en:"Duplicate Item",
		is:"Afrita atriði"
	},
	"duplicateSection":{
		en:"Duplicate Section",
		is:"Afrita síðu"
	},
	"showName":{
		en:"Show Name",
		is:"Sýna nafn"
	},
	"hideTitle":{
		en:"Hide Title",
		is:"Fela titil"
	},
	"showTitle":{
		en:"Show Title",
		is:"Sýna titil"
	},
	"editTitle":{
		en:"Edit Title",
		is:"Breyta titli"
	},
	"setComment":{
		en:"Set Notes",
		is:"Seta nótur"
	},
	"shuffle":{
		en:"Shuffle",
		is:"Slembiröðun"
	},
	"selectOne":{
		en:"Select One",
		is:"Slembival"
	},
	"initThemes":{
		en:"Initialize Themes",
		is:"Ræsa þemu"
	},
	"deleteTheme":{
		en:"Delete Theme",
		is:"Eyða Þema",
	},
	"duplicateTheme":{
		en:"Duplicate Theme",
		is:"Afrita Þema",
	},
	"renameTheme":{
		en:"Rename Theme",
		is:"Endurskýra Þema",
	},
	"enablePreview":{
		en:"Enable Preview",
		is:"Virkja skoðunarham",
	},
	"showSections":{
		en:"Show Sections",
		is:"Sýna síður"
	},
	"copySettings":{
		en:"Copy Current Theme Settings<br/><b>Note</b>: Currently not supported in Firefox",
		is:"Afrita Núverandi Þemu Stillingar<br/><b>Ath</b>: Ekki stutt í ekki Firefox eins og er"
	},
	"pasteSettings":{
		en:"Paste Settings onto Current Theme<br/><b>Note</b>: Currently not supported in Firefox",
		is:"Líma Þemu stillingar á Núverandi<br/><b>Ath</b>: Ekki stutt í ekki Firefox eins og er"
	},
	
};


const message:PhraseDictionary = {
	"displayAllSections":{
		en:"Display all sections",
		is:"Sýna allar síður"
	},
	"shuffleSections":{
		en:"Display sections in random order",
		is:"Slembiraða síðum",
	},
	"pickOneSection":{
		en:"Pick one section to show at random",
		is:"Slembivelja aðeins eina síðu til að sýna"
	},
	"noContent":{
		en:"No content",
		is:"Ekkert efni"
	},
	"noText":{
		en:"No text",
		is:"Enginn texti"
	},
	"noBody":{
		en:"No body",
		is:"Enginn megintexti"
	},
	"emptyImage":{
		en:"Image",
		is:"Mynd"
	},
	"emptyVideo":{
		en:"Video",
		is:"Myndband"
	},
	"emptyBody":{
		en:"Body",
		is:"Megintexti"
	},
	"emptyHTML":{
		en:"HTML",
		is:"HTML"
	},
	"emptyText":{
		en:"Text",
		is:"Texti"
	},
	"emptyTitle":{
		en:"Title",
		is:"Titill"
	},
	"previewing":{
		en:"Previewing",
		is:"Skoðunarhamur"
	}
};

export const forms = mergeDictionaries("forms", { label, action, message });