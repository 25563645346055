


























































































import { defineComponent, ref, computed } from "@vue/composition-api";
import { useTranslate } from "@lang";
import { isDropboxLink } from "@utils/url";


export type EditTextConfig = {
	value:string;
	textarea?:boolean;
	rows?:number;
	size?:"sm"|"md"|"lg";
	title?:string;
	variant?:string;
	confirmText?:string;
	cancelText?:string;
	placeholder?:string;
	showBackdrop?:boolean;
	validator?:(v:string) => boolean;
};

export type EditTextHandler = (v:string) => void;

export interface IEditText {
	open(c:EditTextConfig, fn:EditTextHandler):void;
};

type CallbackHandler = (v:string) => void;


const getLinkInfo = (url:string) => {
	let type = "";

	const typeCheckers:{ [k:string]:(url:string) => boolean } = {
		"dropbox":isDropboxLink,
	};

	const ks = Object.keys(typeCheckers);

	for(let i = 0; i < ks.length; i++){
		if(typeCheckers[ks[i]](url)){
			type = ks[i];
			break;
		}
	}
	let icon = type ? `mdi.${type}` : "";
	return {
		type:type || "generic",
		icon,
	}
}

export default defineComponent({
	directives:{

	},
	setup(){

		const { translate } = useTranslate();

		const value = ref("");
		const fn = ref<CallbackHandler|null>(null);
		const show = ref(false);
		const oldValue = ref("");
		const config = ref<EditTextConfig|null>(null);

		const modal = ref(null);
		const input = ref<HTMLInputElement|null>(null);

		const isTextarea = computed(() => Boolean(config?.value?.textarea));
		const size = computed(() => config?.value?.size || "sm");

		const variant = computed(() => {
			return config?.value?.variant || "dark";
		});

		const textVariant = computed(() => {
			return ({
				"light":"dark"
			} as any)[variant.value] || "light";
		});

		const confirmText = computed(() => config?.value?.confirmText || translate("prompts.action.confirm"));

		const cancelText = computed(() => config?.value?.cancelText || translate("prompts.action.cancel"));

		const linkInfo = computed(() => {
			return getLinkInfo(value.value);
		})
		
		const canSave = computed(() => {
			if(config.value && config.value.validator){
				return config.value.validator(value.value);
			}
			return true;
		});

		const title = computed(() => config?.value?.title || translate("action.editText"));
		const placeholder = computed(() => config?.value?.placeholder || "");
		const showBackdrop = computed(() => config?.value?.showBackdrop || true);

		const ok = () => {
			if(fn.value){ fn.value(value.value); }
			(modal.value as any).hide();
		};

		const hide = () => {
			fn.value = null;
			show.value = false;
			value.value = "";
			oldValue.value = "";
			config.value = null
		};

		const open = (c:EditTextConfig, f:CallbackHandler) => {
			config.value = c;
			fn.value = f;
			show.value = true;
			value.value = c.value;
			oldValue.value = c.value;
			setTimeout(() => {
				if(input.value){ input.value.select(); }
			}, 50);
		};

		return {
			value,
			fn,
			show,
			oldValue,
			canSave,
			variant,
			textVariant,
			modal,
			input,
			title,
			placeholder,
			showBackdrop,
			confirmText,
			cancelText,
			isTextarea,
			size,
			config,
			linkInfo,
			ok,
			hide,
			open,
		};
	}
});
