import { PropMap } from "./PropMap";
import { EventBus } from "./EventBus";
import * as SnapSVG from "snapsvg";
import { Dictionary } from "./Dictionary";
import { Vector2D } from "../types";

export class SnapMovementDrag {

	constructor(domElement:SnapSVG.Element){
		this._domElement = domElement;
		this.setActive(true);
	}

	public setActive(v:boolean){
		if(v){
			this._domElement.drag(this.onDrag.bind(this), this.onDragStart.bind(this), this.onDragStop.bind(this));
		}else{
			this._domElement.undrag();
		}
	}

	public configure(ob:Dictionary<any>){
		this._props.configure(ob);
	};

	public on<N extends keyof DragEvents>(name:N, fn:DragEvents[N]){
		this._listeners.on(name, fn);
	}

	public off<N extends keyof DragEvents>(name:N, fn:DragEvents[N]){
		this._listeners.off(name, fn);
	}

	private _domElement:SnapSVG.Element;
	private _listeners = new EventBus<DragEvents>();
	private _props:PropMap = new PropMap({
		buttons: {
			value: 1,
			validator: v => typeof(v) === "number"
		}
	})

	private _state = {
		delta: { x: 0, y: 0 },
		active: false
	}

	private setDelta(x:number, y:number){
		this._state.delta.x = x;
		this._state.delta.y = y;
	}

	private onDrag(dx:number, dy:number, e:any) {
		if(!this._state.active) { return; }
		let diffx = dx - this._state.delta.x
		let diffy = dy - this._state.delta.y;
		this._listeners.emit("drag", { x: diffx, y: diffy })
		this.setDelta(dx, dy);
	}

	private onDragStart(x:number, y:number, e:any){
		if(e.buttons !== this._props.getValue("buttons")){ return; }
		this._state.active = true;
		this._domElement.appendTo(this._domElement.parent());
		this.setDelta(0, 0);
		// this.data("originalTransform", this.transform().local );
	}

	private onDragStop(){
		if(this._state.active && this._state.delta.x !== 0 || this._state.delta.y !== 0){

			this._listeners.emit("end", { x: this._state.delta.x, y: this._state.delta.y });
		}
		this.setDelta(0, 0);
		this._state.active = false;
	}

}

type EventHandler<T, R> = (p:T) => R;
interface DragEvents {
	"drag":EventHandler<Vector2D,void>,
	"end":EventHandler<Vector2D,void>
}