





















































// vendor
import { defineComponent, computed, ref, PropType, set } from "@vue/composition-api";
import { Form, ThemeProfile } from "@psychlab/types/form";
import { generateGUID } from "@utils/guid";
import { AbsBox, Draggable, Icon } from "@ui";
import { useTranslate } from "@lang";

import { editText } from "@/AppModal";

import { default as DragButton } from "./drag-btn.vue";


const profileDrag = {
	animation: 0,
	disabled: false,
	ghostClass: "ghost",
	group:"profiles",
	handle:`.profile-drag-handle`,
};


export default defineComponent({

	emits:[],
	props:{
		activeTheme:{
			type:String,
			default:"",
		},
		data:{
			type:Object as PropType<Form>,
			required:true
		},
	},
	components:{
		AbsBox,
		Draggable,
		Icon,
		DragButton,
	},
	setup(props){
		
		const { translate } = useTranslate();

		const profiles = computed(() => {
			return props.data.themeProfiles || [];
		});

		const defaultProfile = computed(() => {
			if(!profiles.value.length){ return null; }
			return props.data.defaultThemeProfile || profiles.value[0].id;
		});

		// const dragKey = computed(() => `drag-${generateGUID()}`);
		// const dragKey = computed(() => `drag-handle`);


		// const dragConfig = computed(() => ({
		// 	animation: 0,
		// 	disabled: false,
		// 	ghostClass: "ghost",
		// 	// handle:`.${dragKey.value}`,
		// 	handle:`.drag-handle`,

		// }));

		const renameProfile = (p:ThemeProfile) => {
		
			editText({
				value:p.label,
				variant:"light",
				title:translate("forms.label.profileName"),
				confirmText:translate("prompts.action.done"),
				validator(v){
					return v.length > 0; 
				}
			}, (v) => {
				set(p, "label", v);
			})
		};


		return {
			// dragKey,
			profileDrag,
			profiles,
			defaultProfile,
			renameProfile,
			generateGUID,
			translate,
		};
	}
});

