/** @format */

// vendor
import { defineComponent, computed } from "@vue/composition-api";
// local
import { json } from "./SyntaxHighlight";

export const DisplayJSON = defineComponent({
	props: {
		data: {
			type: [ Object, Boolean, String, Number ],
			required: true,
		},
		pretty: {
			type: Boolean,
			required: false,
		},
		maxHeight: {
			type: [String, Number],
			default: "500px",
		},
		minHeight: {
			type: [String, Number],
		},
	},
	setup(props) {
		const text = computed(() => {
			const s = props.pretty
				? JSON.stringify(props.data, null, "\t")
				: JSON.stringify(props.data);
			return json(s, colors);
		});
		return {
			text,
		};
	},
});

const colors = {
	"key":"#9cdcfe",
	"string":"#ce9178",
	"null":"#348cd6",
	"boolean":"#348cd6",
	"number":"#b5cea8",
	"default":"white"
};
