import { WizardContext } from "../types/WizardContext";
import * as Utils from "./utils";

export const CountGroupBooleans = {
	name: "CountGroupBooleans",
	description:{
		en:"Counts filled checkboxes in field.",
		is:"Telur fyllta valmöguleika í dálk."
	},
	isUsable(ctx:WizardContext){
		return ctx.channels.findIndex(Utils.isCheckbox) > -1;
	},
	parameters: {
		group: {
			type:"string",
			title:{
				en:"Field Group",
				is:"Dálkahópur",
			},
			drawer(context:WizardContext){
				return {
					type:"dropdown",
					options:Utils.getGroupOptions(context.channels, Utils.isCheckbox)
				}
			},
			default:null
		}
	},
	output:{
		// name:{
		// 	type:"string",
		// },
		values:{
			type:"number[]",
		},
		labels:{
			type:"string[]",
		}
	}
};

const supportedTypes = [
	"form/checkboxes/option",
];