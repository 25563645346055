import { defineComponent, computed, ref } from "@vue/composition-api";
import { AssetVersion } from "@/psychlab/types/assets";
import { Graph } from "@/psychlab/types/graph";
import { getAssetVersion, saveAssetVersion } from "@/services/api/assets";
import * as AppModal from "@/AppModal";
import { getUpdateReport } from "./getUpdateReport";
import { UpdateReport } from "./UpdateReport";
import { useTranslate } from "@lang";
import { Icon } from "@ui";

enum Translations {
	Update = "assets.action.update",
	UpdateReferences = "assets.action.updateReferences",
	UpdateAll = "assets.action.updateAll",
	Cancel = "prompts.action.cancel",
}

enum EventNames {
	Updated = "updated",
}

export const UpdateButton = defineComponent({
	emit:[ EventNames.Updated, ],
	props:{
		assetId:{
			type:String,
			required:true,
		}
	},
	components:{
		Icon,
	},
	setup(props, context){

		const loading = ref(false);
		
		const version = ref<AssetVersion<Graph>>();

		const report = ref<UpdateReport>();

		const { translate } = useTranslate();

		const data = computed(() => version.value?.data);

		const entryCount = computed(() => report.value?.entries.length || 0);

		const getReportDescription = () => {
			if(!report.value){ return ""; }
			let s = "";
			report.value.entries.forEach(e => {
				s += `<li>${e.description}</li>`;
			});
			return `<ul>${s}</ul>`
		};

		const refreshReport = async () => {
			if(!data.value){ return; }
			loading.value = true;
			try {
				report.value = await getUpdateReport(data.value);
			}
			catch{}
			loading.value = false;
		};

		const refresh = async () => {
			loading.value = true;
			try {
				version.value = await getAssetVersion(props.assetId, "latest");
			}
			catch(err:any){
				console.log(err.message);
			}
			loading.value = false;
			refreshReport();
		};

		const applyUpdate = async () => {
			if(!version.value || !data.value || !report.value || !report.value.entries.length){ return; }
			loading.value = true;
			try {
				await Promise.all(report.value.entries.map(e => e.handler()));
				await saveAssetVersion(props.assetId, {
					data:data.value,
					base:version.value._id,
				});
				context.emit(EventNames.Updated);
				refresh();
			}
			catch{}
			loading.value = false;
		};

		const update = () => {
			AppModal.confirm({
				title:translate(Translations.UpdateReferences),
				description:getReportDescription(),
				okText:translate(Translations.UpdateAll),
				size:"md",
				cancelText:translate(Translations.Cancel),
				variant:"info",
				noCenterDescription:true,
			}, applyUpdate);
		};
		
		refresh();

		return {
			report,
			entryCount,
			loading,
			update,
			translate,
		};
	},
	render(){
		if(!this.loading && this.entryCount > 0){

			const tooltip = {
				interactive:false,
				delay:0,
				variant:"warning",
				trigger:"hover",
				title:`${this.translate(Translations.Update)} (${this.entryCount})`,
			};

			return (
				<button
				v-on:click={ this.update }
				class="btn btn-warning btn-lg my-auto shadow mr-3"
				v-b-tooltip={tooltip}
				>
					<Icon name="update"/> ({ this.entryCount })
				</button>
			);
		}
		if(this.loading){
			return <Icon name="cog" class="text-light my-auto mdi-36px" spin/>;
		}
		return <span/>;
	}
});