import { Module } from "vuex";
import Vue from "vue";

type State = {
	storage:Record<string,any>
}

export const Cache:Module<State, {}> = {
	namespaced:true,
	state: {
		storage:{}
	},
	getters: {
		getValue: (state) => (key:string) => {
			return state.storage[key];
		}
	},
	mutations:{
		setValue(state, payload:{ k:string, v:any }){
			Vue.set(state.storage, payload.k, payload.v);
		}
	}
}