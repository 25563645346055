



























import { defineComponent, computed } from "@vue/composition-api";

export default defineComponent({
	emits:[
		"input"
	],
	props:{
		value:Boolean,
		title:{
			type:String,
			default:""
		}
	},
	setup(props, ctx){

		const open = computed<boolean>({
			get:() => Boolean(props.value),
			set:(v) => ctx.emit("input", v)
		});

		const toggle = () => open.value = !open.value;

		return {
			open,
			toggle
		};
	}
});

