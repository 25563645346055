/** @format */

import { http } from "@/http";

export const updateBatchSessionLabels = async (
	study: string,
	sessions: string[],
	assign: string[],
	unassign: string[],
): Promise<void> => {
	await http.patch(`studies/${study}/batch/labels`, {
		sessions,
		assign,
		unassign,
	});
};
