



























































// vendor
import { defineComponent, computed, ref, watch, onMounted } from "@vue/composition-api";
// local
import * as Drawers from "./drawers";
import * as Utils from "../utils"
import { useLazyValue } from "../hooks/useLazyValue";

const LAZY_DELAY = 200;

export default defineComponent({
	emits:[
		"remove",
	],
	props:{
		queryKey:{
			type:String,
			required:true,
		},
		title:{
			type:String,
			default:"My Filter",
		},
		type:{
			type:String,
			required:true,
		},
		icon:{
			type:String,
			default:"mdi.filter",
		},
		config:{
			type:Object,
			default:() => ({}),
		},
		foldable:{
			type:Boolean,
			default:true,
		},
		disabled:{
			type:Boolean,
			default:false,
		},
	},
	setup(props, context){

		const open = ref(true);

		const value = ref<any>();

		const {
			lazyValue
		} = useLazyValue(LAZY_DELAY, value);

		const drawer = computed(() => {
			return (Drawers as any)[props.type];
		});

		const toggle = () => {
			if(!props.foldable){ return; }
			open.value = !open.value;
		};

		const remove = () => {
			value.value = undefined;
			saveQuery();
			context.emit("remove");
		};
		
		const loadQuery = () => {
			const qv = context.root.$route.query[props.queryKey];
			const tr = Utils.routeConverters[props.type];
			if(!tr || !qv){ return; }
			value.value = tr.from(qv);
		};

		const saveQuery = () => {
			const v = value.value;
			const q:any = { ...context.root.$route.query };
			if(v !== undefined && v !== null && v !== ""){
				const tr = Utils.routeConverters[props.type];
				if(tr){
					q[props.queryKey] = tr.to(v);
				}
			}
			else {
				delete q[props.queryKey];
			}
			context.root.$router.push({ query: q})
			.catch(() => {});
		};

		watch(() => context.root.$route.query[props.queryKey], (v, o) => {
			loadQuery();
		});

		watch(lazyValue, saveQuery, {
			deep:true,
		});

		onMounted(() => {
			loadQuery();
		});

		return {
			open,
			toggle,
			remove,
			drawer,
			value,
		};
	}
});

