import { render, staticRenderFns } from "./render-likert.vue?vue&type=template&id=7828e9f2&scoped=true&"
import script from "./render-likert.vue?vue&type=script&lang=ts&"
export * from "./render-likert.vue?vue&type=script&lang=ts&"
import style0 from "./render-likert.vue?vue&type=style&index=0&id=7828e9f2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7828e9f2",
  null
  
)

export default component.exports