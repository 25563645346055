














import { defineComponent  } from "@vue/composition-api";
import { Icon } from "./icons";

export default defineComponent({
	props:{
		icon:{
			type:String,
			default:"mdi.information"
		},
		variant:{
			type:String,
			default:"secondary",
		},
		tip:{
			type:String,
			default:"",
		},
	},
	components:{
		Icon,
	},
});

