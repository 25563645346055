var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-row bg-dark",staticStyle:{"border-radius":"50rem","overflow":"hidden"}},_vm._l((_vm.displayActions),function(action,index){return _c('div',{key:action.key,staticClass:"p-1 bg-dark"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
			title:action.tooltip,
			boundary:'window',
			boundaryPadding:0,
			placement:'top',
			delay:0,
			animation:false,
			interactive:false,
			variant:action.tooltipVariant
		}),expression:"{\n\t\t\ttitle:action.tooltip,\n\t\t\tboundary:'window',\n\t\t\tboundaryPadding:0,\n\t\t\tplacement:'top',\n\t\t\tdelay:0,\n\t\t\tanimation:false,\n\t\t\tinteractive:false,\n\t\t\tvariant:action.tooltipVariant\n\t\t}",modifiers:{"hover":true}}],staticClass:"rounded-circle d-flex p-0 shadow border-0",staticStyle:{"pointer-events":"all"},style:(("width:" + _vm.buttonSize + "em; height:" + _vm.buttonSize + "em;")),attrs:{"disabled":action.disabled,"variant":("" + (action.variant))},on:{"click":function($event){return _vm.runAction($event, action)}}},[_c('Icon',{staticClass:"m-auto",style:(("font-size:" + _vm.iconSize + "em")),attrs:{"name":action.icon}})],1)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }