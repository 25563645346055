import { AxiosInstance } from "axios";
import { default as io } from "socket.io-client";
import { http } from "@/http";
import { SavedChannel } from "@/psychlab/types/session";
import { Session, SessionState } from "@/psychlab/types";
import * as TextUtils from "@utils/text";
import * as JWT from "@utils/jwt";
import { getConfig } from "@config";

type Dictionary<T> = { [k:string]:T };

const HTTP:AxiosInstance = http;

export type ChannelValue = {
	key:string,
	data:any
};

export type SessionListResult = {
	sessions:Session[],
	count:number
};

export const findActiveStudySession = (studyId:string):string|undefined => {
	clearExpiredSessions();
	const sessions = getCachedStudySessions(studyId);
	return sessions.length > 0 ? sessions[sessions.length - 1].id : undefined;
};

export const getSessionToken =  (sessionId:string) => {
	clearExpiredSessions();
	const session = findCachedSession(sessionId);
	return session ? `bearer ${session.token}` : null;	
};

export const saveSessionToken = (studyId:string, sessionId:string, token:string) => {
	const key = `studies/${studyId}/sessions/${sessionId}`;
	const tbody = JWT.parse<TokenBody>(token);
	const info:CachedSession = {
		key,
		id:sessionId,
		study:studyId,
		expires:tbody.exp,
		token:token,
		cache:{}
	}
	localStorage.setItem(key, JSON.stringify(info));
};

export const getSessionCount = async (query = {}):Promise<number> => {
	let route = `sessions${buildQueryString(query)}`;
	let res = await HTTP.head(route);
	if(res.headers["pagination-count"]){
		return Number(res.headers["pagination-count"]);
	}
	return 0;
};

export const loadSessions = async (query = {}):Promise<SessionListResult> => {
	let route = `sessions${buildQueryString(query)}`;
	let res = await HTTP.get(route);
	const { data:sessions } = res;
	const count = Number(res.headers["pagination-count"]);
	return { sessions, count };
};

export const loadSession = async (sessionId:string, useAdmin:boolean = false):Promise<Session> => {
	let q = "?select=+path,+graph,+dataChannels,+parameters,+events,+log,+data,+state,+labels"
	.replace(/\+/g, "%2B"); // escape +
	const headers:Dictionary<any> = {};
	if(!useAdmin){ headers["Authorization"] = getSessionToken(sessionId) || "nil"; }
	let { data } = await HTTP.get(`sessions/${sessionId}${q}`, { headers });
	return data;
};

export const getSession = async (sessionId:string, query?:any):Promise<Session> => {
	let q = buildQueryString(query)
	.replace(/\+/g, "%2B"); // escape +
	let { data } = await HTTP.get(`sessions/${sessionId}${q}`);
	return data;
};

export const modifySessionState = async(sessionId:string, state:any):Promise<SessionState> => {
	let t = getSessionToken(sessionId);
	let options:any = { headers: {}};
	if(t){ options.headers.Authorization = t; }
	const { data } = await HTTP.patch(`sessions/${sessionId}/state`, state, options);
	return data;
};

export const saveSessionCache = (sessionId:string, cache:{ [k:string]:any }) => {
	const s = findCachedSession(sessionId);
	if(!s){
		console.error(`Cannot save cache for session '${sessionId}'`)
		return;
	}
	s.cache = cache;
	localStorage.setItem(s.key, JSON.stringify(s));
};

export const cacheSessionValue = (sessionId:string, key:string, value:any) => {
	const s = findCachedSession(sessionId);
	if(!s){
		console.error(`Cannot cache value for session '${sessionId}'`)
		return;
	}
	s.cache[key] = value;
	localStorage.setItem(s.key, JSON.stringify(s));
};

export const saveDataChannels = async(sessionId:string, channels:ChannelValue[]):Promise<boolean[]> => {
	let t = getSessionToken(sessionId);
	let options:any = { headers: {}};
	if(t){ options.headers.Authorization = t; }
	const { data } = await HTTP.post(`sessions/${sessionId}/data?list=1`, channels, options);
	return data;
};

export const getSessionCache = (sessionId:string) => {
	const s = findCachedSession(sessionId);
	return s ? s.cache : null;
}

export const readSessionData = async(sessionId:string, channelId:string) => {
	const { data } = await HTTP.get(`sessions/${sessionId}/data/${encodeURIComponent(channelId)}`);
	return data;
}

export const deleteSession = async(sessionId:string) => {
	return await HTTP.delete(`sessions/${sessionId}`);
};

export const loadSessionState = async (sessionId:string) => {
	let { data } = await HTTP.get(`sessions/${sessionId}?select=state`);
	return data.state;
};

export const loadSavedSessionChannels = async(sessionId:string):Promise<string[]> => {
	let { data:channels } = await HTTP.get(`sessions/${sessionId}/data/list`);
	return channels;
};

export const loadSessionData = async(sessionId:string):Promise<SavedChannel[]> => {
	const { data } = await HTTP.get(`sessions/${sessionId}/data`);
	return data;
};

export const createSessionSocket = (sessionId:string, authToken:string) => getEventsSocket("single-session", {
	token: authToken,
	session: sessionId
});

type CachedSession = {
	key:string,
	id:string,
	study:string,
	expires:number,
	token:string,
	cache:Dictionary<any>
};

type TokenBody = {
	exp:number
};

const getEventsSocket = (namespace:string, query?:Dictionary<any>) => {
	const url = getConfig().apiURL;
	const socket = io(`${url}/${namespace}`, {
		transports: ["websocket"],
		query,
		path: "/events/",
		reconnection:true
	});
	return socket;
};

const buildQueryString = (queryObject:any) => {
	let r = "";
	let i = 0;
	Object.keys(queryObject).forEach((k) => {
		let v = queryObject[k];
		if(v === undefined) { return; }
		let prefix = i === 0 ? "?" : "&";
		r += `${prefix}${k}=${v}`;
		i++;
	});
	return r;
};

const clearExpiredSessions = () => {
	const sessions:CachedSession[] = findCachedObjects("*/sessions/*");
	sessions.forEach(s => {
		if(Date.now() >= s.expires * 1000){
			localStorage.removeItem(s.key);
		}
	});
};

const findCachedObjects = <T>(pattern:string):T[] => {
	return Object.keys(localStorage)
	.filter(k => TextUtils.matchWildcard(k, pattern))
	.map(k => {
		const it = localStorage.getItem(k);
		return it ? JSON.parse(it) : null;
	})
	.filter(it => it !== null) as T[];
};

const getCachedStudySessions = (studyId:string):CachedSession[] => {
	return findCachedObjects<CachedSession>(`studies/${studyId}/sessions/*`);
};

const findCachedSession = (sessionId:string):CachedSession|null => {
	const matches = findCachedObjects<CachedSession>(`*/sessions/${sessionId}`);
	return matches.length > 0 ? matches[0] : null;
};

// export const createSessionsSocket = (authToken:string) => getEventsSocket("all-sessions", {
// 	token: authToken
// });
