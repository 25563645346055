































import { defineComponent, computed, ref, watch } from "@vue/composition-api";
import * as AssetAPI from "@/services/api/assets";
import { Asset, AssetVersion } from "@psychlab/types/assets";
import { default as FallbackPreview } from "@/components.assets/preview-asset-data/fallback-preview.vue"
import { PreviewAssetForm } from "@/components.assets/preview-asset-form";
import { PreviewAssetGraph } from "@/components.assets/preview-asset-graph";
import { PreviewAssetData } from "@/components.assets/preview-asset-data"
import { PreviewAssetGallery } from "@/components.assets/preview-asset-gallery";
import { generateGUID } from "@utils/guid";
import { default as VersionList } from "./version-list.vue";
import { useTranslate } from "@lang";
import { AbsBox } from "@ui";

export default defineComponent({
	emits:[
		"input"
	],
	props:{
		assetId:{
			type:String,
			required:true
		},
		value:{
			type:String
		},
		showContext:{
			type:Boolean,
			default:true,
		},
	},
	components:{
		VersionList,
		PreviewAssetData,
		AbsBox,
	},
	setup(props, context){

		const asset = ref<Asset>();

		const loading = ref(false);

		const activeVersion = ref<AssetVersion<any>|null>();

		const activeVersionId = computed(() => {
			if(props.value){ return props.value; }
			if(versions.value.length > 0){ return versions.value[0]._id; }
			return null;
		});

		const currentQuery = ref<string>();

		const versions = ref<AssetVersion<any>[]>([]);

		const { translate } = useTranslate();

		const typeComponent = computed(() => {
			if(!asset.value){ return; }
			return ({
				"Form":PreviewAssetForm,
				"Graph":PreviewAssetGraph,
				"Gallery":PreviewAssetGallery,
			} as any)[asset.value.type] || FallbackPreview;
		});

		const init = async () => {
			asset.value = await AssetAPI.getAsset(props.assetId);
			versions.value = await AssetAPI.loadVersionList(props.assetId);
			loadVersion();
		};

		const restoreVersion = async (vid:string) => {
			const d = await AssetAPI.getAssetVersion(props.assetId, vid, "+vIndex +data");
			const v = await AssetAPI.saveAssetVersion(props.assetId, {
				base:vid,
				data:d.data,
			});
			versions.value.unshift(v);
		};

		const loadVersion = async () => {
			const qid = generateGUID();

			currentQuery.value = qid;

			if(!activeVersionId.value){
				activeVersion.value = null;
				return;
			}
			loading.value = true;
			try {
				const r = await AssetAPI.getAssetVersion(props.assetId, activeVersionId.value, "+data");
				if(currentQuery.value === qid){
					activeVersion.value = r;
				}
			}
			catch(err){}
			if(currentQuery.value === qid){
				loading.value = false;
			}
		};

		const selectVersion = async(vid:string) => {
			context.emit("input", vid);
		};

		watch(() => activeVersionId.value, async (v) => {
			if(activeVersion.value && activeVersion.value._id === v){
				return;
			}
			loadVersion();
		});

		init();

		return {
			asset,
			activeVersionId,
			activeVersion,
			typeComponent,
			loading,
			versions,
			selectVersion,
			restoreVersion,
			translate,
		};
	}
});

