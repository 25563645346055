import { defineComponent, computed } from "@vue/composition-api";
import { useTranslate } from "../hooks/useTranslate";

export const Translate = defineComponent({
	props:{
		text:{
			type:String,
			required:true,
		},
	},
	setup(props){
		const { translate } = useTranslate();
		const translated = computed(() => translate(props.text))
		return { translated };
	}
});