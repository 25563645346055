import { Ref, SetupContext, reactive, watch } from "@vue/composition-api";
import { ContextOption } from "@/components.generic/context-menu";
import { useFavourites } from "@/hooks/useFavourites";
import { useTags } from "@/hooks/useTags";
import { useAssets } from "@/hooks/useAssets";
import { Asset } from "@/psychlab/types/assets";
import * as AppModal from "@/AppModal";
import { useTranslate } from "@lang";

const initPagination = (prefix:string, ctx:SetupContext) => {
	const qPage = getQueryValue(`${prefix}_page`, ctx, 1);
	const qLimit = getQueryValue(`${prefix}_limit`, ctx, 10);
	return reactive({
		page:Number(qPage),
		limit:Number(qLimit),
		total:0
	});
}

type Config = {
	activeTag:Ref<string|null>;
	context:SetupContext;
	queryPrefix?:string;
	contextFn?:(a:Asset) => ContextOption[];
};


const typeAlias:{ [k:string]:string } = {
	"Graph":"Blueprint"
};

export const useAssetListDisplay = (c:Config) => {

	const assetHooks = useAssets();

	const {
		assets
	} = assetHooks;

	const {
		untagItem,
		hasTag,
		sortedTags,
	} = useTags();

	const {
		isFavourite,
		addFavourite,
		removeFavourite
	} = useFavourites();

	const {
		activeTag,
		contextFn,
	} = c;

	const { translate } = useTranslate();

	const pagination = initPagination(c.queryPrefix || "", c.context);

	let initialPage = pagination.page;

	pagination.total = assets.value.length;
	
	watch(() => pagination.total, v => {
		if(initialPage >= 0){
			pagination.page = initialPage;
			initialPage = -1;
		}
	});

	watch(() => assets.value.length, v => pagination.total = v);

	watch(() => pagination.limit, v => setQueryValue(`${c.queryPrefix || ''}_limit`, v, c.context));

	watch(() => pagination.page, v => setQueryValue(`${c.queryPrefix || ''}_page`, v, c.context));


	const findAsset = (id:string) => {
		return assets.value.find(a => a._id === id);
	};

	const getAssetTags = (assetId:string) => {
		return sortedTags.value.filter(t => hasTag(t._id, assetId));
	};

	const renameAsset = (id:string) => {
		const a = findAsset(id);

		if(!a){ return; }

		const tn = (typeAlias[a.type] || a.type).toLowerCase();
		
		const title = `${translate("action.rename")} ${translate(`assets.${tn}`)}`;
		

		AppModal.editText({
			value: a.name,
			title,
			confirmText:translate("action.save"),
			validator(v){
				return v.length > 2 && v !== a.name;
			}
		},
		(newName:string) => {
			assetHooks.renameAsset(a._id, newName);
		});
	};

	const deleteAsset = (id:string) => {
		const a = findAsset(id);
		if(!a){ return; }
		AppModal.confirm({
			title: translate("action.delete"),
			variant: "danger",
			description: translate("prompts.message.irreversible"),
			okText: translate("prompts.action.understood"),
		}, async () => {
			assetHooks.deleteAsset(a._id);
		});
	};

	const duplicateAsset = (id:string) => {
		const a = findAsset(id);
		if(!a){ return; }

		const nn = translate("label.copyOf").replace("$n", a.name);

		AppModal.editText({
			title:translate("action.duplicate"),
			value: nn,
			validator(){
				return true;
			},
			confirmText:translate("prompts.action.submit")
		},
		async (newName:string) => {
			assetHooks.duplicateAsset(a._id, newName, activeTag.value);
		});
	};

	const editAsset = (id:string) => {
		const ctx = c.context;
		if(!ctx){ return; }
		ctx.root.$router.push({
			name:"asset.edit",
			params:{
				assetId:id,
			}
		});
	};

	const viewAssetHistory = (id:string) => {
		const ctx = c.context;
		if(!ctx){ return; }
		ctx.root.$router.push({
			name:"asset-history",
			params:{
				assetId:id,
			}
		});
	};

	const getAssetContextOptions = (assetId:string) => {
		const a = assets.value.find(aa => aa._id === assetId);
		if(!a){ return []; }
		
		const options = [
			...(contextFn ? contextFn(a) : [])
		];

		if(c.context){
			options.push({
				name:translate("action.edit"),
				icon:"mdi.pencil",
				fn:() => {
					editAsset(assetId);
				}
			});
		}
		
		options.push({
			name:translate("action.viewHistory"),
			icon:"mdi.history",
			fn:() => {
				viewAssetHistory(assetId);
			}
		});

		if(!isFavourite(assetId)){
			options.push({
				name:translate("action.addToFavourites"),
				icon:"mdi.star",
				order:4,
				fn:() => addFavourite(assetId, "asset")
			});
		}
		else {
			options.push({
				name:translate("action.removeFromFavourites"),
				icon:"mdi.star-outline",
				order:4,
				fn:() => removeFavourite(assetId)
			});
		}

		options.push({
			name:translate("action.rename"),
			icon:"mdi.pencil-box-outline",
			order:0,
			fn:() => renameAsset(assetId)
		});

		options.push({
			name:translate("action.duplicate"),
			icon:"mdi.content-copy",
			order:1,
			fn:() => duplicateAsset(assetId)
		});

		// if(activeTag.value && activeTag.value !== "all"){

		// 	const tag = activeTag.value;

		// 	options.push({
		// 		name:translate("tags.action.removeTag"),
		// 		icon:"mdi.tag-remove",
		// 		order:5,
		// 		fn:() => {
		// 			untagItem(tag, assetId);
		// 		}
		// 	});
		// }


		options.push({
			name:translate("tags.action.editTags"),
			icon:"tag-multiple-outline",
			order:5,
			fn:() => AppModal.editAssetTags(assetId),
		});



		options.push({
			name:translate("action.delete"),
			icon:"mdi.trash-can",
			order:10,
			fn:() => deleteAsset(assetId)
		});
		return options;
	}

	const openAssetContext = (e:Event, assetId:string) => {
		AppModal.context(e, getAssetContextOptions(assetId));
	};


	return {
		pagination,
		openAssetContext,
		getAssetTags,
		getAssetContextOptions,
	};
};



export const setQueryValue = (k:string, v:any, context:SetupContext) => {
	const q:any = { ...context.root.$route.query };
	if(v !== undefined && v !== null){
		q[k] = v;
	}
	else {
		delete q[k];
	}
	context.root.$router.push({ query: q})
	.catch(() => {});
};


export const getQueryValue = <T>(k:string, context:SetupContext, def:T):T => {
	return ((context.root.$route.query[k] as any) as T) || def;
};