
































// vendor
import {
	defineComponent,
	PropType,
	ref,
	computed,
} from "@vue/composition-api";
// local
import { MultipleChoice } from "../../ts/types/DisplayForm";
import Scale from "../shared/scale.vue";
import DockedFoldout from "../shared/docked-foldout.vue";
import ErrorOverlay from "../shared/error-overlay.vue";

export default defineComponent({
	props: {
		value: {
			type: Number,
		},
		showBack: {
			type: Boolean,
			default: true,
		},
		config: {
			type: Object as PropType<MultipleChoice>,
			required: true,
		},
	},
	components: {
		Scale,
		DockedFoldout,
		ErrorOverlay,
	},
	setup(props, context) {

		const error = ref(false);
		
		const previous = () => context.emit("previous");

		const next = () => {
			if(!canContinue.value){
				error.value = true;
				return;
			}
			context.emit("next");
		};

		const val = computed({
			get: () => props.value,
			set: (v) => context.emit("input", v),
		});

		const isValid = () => {
			return val.value !== undefined && val.value > -1;
		};

		const getChannels = () => {
			if (val.value === undefined) {
				return [];
			}
			return [
				{
					key: props.config.key,
					data: val.value,
				},
			];
		};

		const canContinue = computed(() =>
			props.config.optional ? true : isValid(),
		);

		return {
			error,
			previous,
			next,
			val,
			isValid,
			getChannels,
			canContinue,
		};
	},
});
