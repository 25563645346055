import { useFavourites } from "@/hooks/useFavourites";
import { useTags } from "@/hooks/useTags";
import * as AppModal from "@/AppModal";
import { useTranslate } from "@lang";

enum Translations {
	Rename = "action.rename",
	Delete = "action.delete",
	DeleteTag = "tags.action.deleteTag",
	RenameTag = "tags.action.renameTag",
	SetTagColor = "tags.action.setTagColor",
	SetColor = "action.setColor",
	AddFavourite = "action.addToFavourites",
	RemoveFavourite = "action.removeFromFavourites",
	Irreversible = "prompts.message.irreversible",
	Understood = "prompts.action.understood",
}

export const useTagContextMenu = () => {

	const { translate } = useTranslate();

	const {
		sortedTags,
		editTag,
		deleteTag,
	} = useTags();

	const {
		isFavourite,
		addFavourite,
		removeFavourite
	} = useFavourites();

	const openContext = (e:any, id:string) => {
		const tag = sortedTags.value.find(t => t._id === id);
		if(!tag){ return; }


		const options:any[] = [];

		if(!isFavourite(id)){
			options.push({
				name:translate(Translations.AddFavourite),
				icon:"mdi.star",
				fn:() => addFavourite(id, "tag"),
				order:0,
			});
		}
		else {
			options.push({
				name:translate(Translations.RemoveFavourite),
				icon:"mdi.star-outline",
				fn:() => removeFavourite(id),
				order:0,
			});
		}

		options.push({
			name:translate(Translations.Rename),
			icon:"pencil",
			fn(){
				AppModal.editText({
					title:translate(Translations.RenameTag),
					value:tag.name,
					validator(v){
						return v !== tag.name && v.length > 2;
					}
				}, (v) => editTag(tag._id, { name:v }));
			},
			order:1,
		});

		options.push({
			name:translate(Translations.SetColor),
			icon:"format-color-fill",
			fn(){
				AppModal.editColor({
					title:translate(Translations.SetTagColor),
					value:tag.color,
					validator:v => tag.color !== v,
				}, (v:string) => editTag(tag._id, { color:v }));
			},
			order:1,
		});

		options.push({
			name:translate(Translations.Delete),
			icon:"trash-can",
			fn(){

				AppModal.confirm({
					title:translate(Translations.DeleteTag),
					variant:"danger",
					description:translate(Translations.Irreversible),
					okText:translate(Translations.Understood),
				}, () => deleteTag(tag._id));
			},
			order:2,
		});

		AppModal.context(e, options);
	};

	return {
		openContext,
	};

};