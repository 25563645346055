import { HTTPEndpoint, IGet } from "@http";

import {
	IAsset,
	IAssetHandler,
	IAssetProvider
} from "../interface";

import { GetRecentAssetsQuery, GetRecentAssetsResult } from "../queries";


export class AssetProvider implements IAssetProvider {

	async readAssetByID(id:string): Promise<IAssetHandler> {
		throw new Error("Method not implemented.");
	}
	async getAssets():Promise<IAsset[]> {
		throw new Error("Method not implemented.");
	}

	async getRecentlyModifiedAssets(q:GetRecentAssetsQuery):Promise<GetRecentAssetsResult> {
		return await this._recentAssets.send({ days:q.days })
	}

	async countAssets():Promise<number> {
		throw new Error("Method not implemented.");
	}


	private _recentAssets:IGet<{ days:number },GetRecentAssetsResult> = HTTPEndpoint.get("assets/recent?days=:days");


	

}