












import { defineComponent, computed, ref, inject, PropType } from "@vue/composition-api";
import { Gallery } from "@/psychlab/types/gallery";
import { ScriptableForm, FormProperty } from "@/components.generic/scriptable-form";
import { useTranslate } from "@lang";
import { FeatureFlags, isFlagEnabled } from "@app";

type GalleryProp = FormProperty&{
	key:keyof(Gallery)
};

export default defineComponent({
	props:{
		data:{
			type:Object as PropType<Gallery>,
			required:true,
		}
	},
	components:{
		ScriptableForm,
	},
	setup(p){


		const {
			translate,
		} = useTranslate();

		const props = computed<GalleryProp[]>(() => {


			const yesLabel = translate("label.yes");
			const noLabel = translate("label.no");

			const onLabel = translate("label.on");
			const offLabel = translate("label.off");


			const r:GalleryProp[] = [
				{
					type:"toggle",
					key:"panorama",
					target:p.data,
					label:translate("assets.label.panorama"),
					tooltip:translate("assets.message.aboutPanoramaToggle"),
					config:{
						onLabel:yesLabel,
						offLabel:noLabel,
					}
				},
				{
					type:"toggle",
					key:"navPerItem",
					target:p.data,
					label:translate("assets.label.perItemNavigation"),
					tooltip:translate("assets.message.aboutPerItemNavigationToggle"),
					config:{
						onLabel:onLabel,
						offLabel:offLabel,
					}
				},
				{
					type:"toggle",
					key:"showLabels",
					target:p.data,
					label:translate("assets.label.showItemLabels"),
					tooltip:translate("assets.message.aboutItemLabelToggle"),
					config:{
						onLabel:yesLabel,
						offLabel:noLabel,
					},
					disabled:false,
				},
			];

			if(isFlagEnabled(FeatureFlags.ExperimentalPanorama)){
				r.push({
					type:"toggle",
					key:"useExperimentalRenderer",
					target:p.data,
					label:translate("assets.action.useExperimentalGalleryRenderer"),
					tooltip:translate("assets.message.aboutExperimentalGalleryRenderer"),
					config:{
						onLabel:yesLabel,
						offLabel:noLabel,
					},
					disabled:false,
				});
			}

			return r;
		});

		return {
			props,
		};
	}
});

