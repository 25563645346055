import { defineComponent } from "@vue/composition-api";
import { useTranslate } from "@lang";
import { ConfirmBox } from "./ConfirmBox";

enum Translations {
	OpenSurvey = "session.action.openStudy",
	Start = "session.action.start"
}

enum EventNames {
	Start = "start",
	Resume = "resume"
}

export const Start = defineComponent({
	emits:[ EventNames.Start ],
	setup(){
		const { translate } = useTranslate();
		return { translate };
	},
	render(){

		const title = this.translate(Translations.OpenSurvey);
		const btext = this.translate(Translations.Start);

		return (
			<ConfirmBox
			v-on:confirm={ () => this.$emit(EventNames.Start) }
			title={ title }
			buttonText={ btext }
			variant={ "success" }
			/>
		);
	}
});