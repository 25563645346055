









import { defineComponent, ref } from "@vue/composition-api";
import { RenderRadio } from "@form-ui";
import { MultipleChoice } from "@psychlab/types/form"
import { getItemProps } from "./getItemProps";

export default defineComponent({
	props:{
		...getItemProps<MultipleChoice>()
	},
	components:{
		RenderRadio,
	},
	setup(){
		return {
			val:ref(-1)
		};
	}
});

