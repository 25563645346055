

















import { defineComponent, onMounted } from "@vue/composition-api";
import { EditText, EditColor, Confirm, UploadFile, FindIcon, RenderRaw } from "@/components.generic/utility-modal";
import { ContextMenu } from "@/components.generic/context-menu";
import { CreateStudyWizard } from "@components/studies";
import { BrowseAssetModal  } from "@/components.assets/browse-asset-modal";
import { CreateAssetWizard } from "@/components.assets/create-asset-wizard";
import { TagAssetModal } from "@components/tags";
import { useAppModals } from "@/hooks/useAppModals";

export default defineComponent({
	emits:[ "ready" ],
	components:{
		EditText,
		EditColor,
		Confirm,
		ContextMenu,
		CreateStudyWizard,
		FindIcon,
		UploadFile,
		CreateAssetWizard,
		RenderRaw,
		"find-asset":BrowseAssetModal,
		TagAssetModal,
	},
	setup(_, ctx){
		onMounted(() => ctx.emit("ready"));

		return {
			...useAppModals()
		};
	}
});

