var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AbsBox',{staticClass:"bg-canvas",staticStyle:{"overflow":"auto"}},[_c('div',{staticClass:"mt-5"}),(_vm.showSettings)?_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"bg-item text-light p-2 border mx-auto border2x boxround mb-4",staticStyle:{"max-width":"600px","width":"90%"}},[_c('icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
				animation:false,
				interactive:false,
				variant:'dark',
				title:_vm.getSectionShuffleTooltip(_vm.data.settings.sectionSelection)
			}),expression:"{\n\t\t\t\tanimation:false,\n\t\t\t\tinteractive:false,\n\t\t\t\tvariant:'dark',\n\t\t\t\ttitle:getSectionShuffleTooltip(data.settings.sectionSelection)\n\t\t\t}",modifiers:{"hover":true}}],staticStyle:{"font-size":"1.5em"},style:({
				opacity:_vm.data.settings.sectionSelection ? 1 : 0
			}),attrs:{"name":_vm.getShuffleIcon(_vm.data.settings.sectionSelection)}})],1)]):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mx-auto",staticStyle:{"max-width":"600px","width":"90%","position":"relative"}},_vm._l((_vm.data.sections),function(section,index){return _c('div',{staticClass:"boxround bg-item text-light border mx-auto border2x mb-4",staticStyle:{"overflow":"hidden"}},[_c('div',{staticClass:"bg-item p-3 d-flex"},[_c('h4',{staticClass:"font-weight-lixght m-0 nats-font"},[_vm._v(" "+_vm._s(_vm.translate("forms.label.section"))+" "+_vm._s(index + 1)+" ")]),(section.settings && section.settings.itemSelection)?_c('div',{staticClass:"ml-auto bg-dark px-2 rounded d-flex"},[_c('icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
							animation:false,
							interactive:false,
							variant:'dark',
							title:_vm.getItemShuffleTooltip(section.settings.itemSelection)
						}),expression:"{\n\t\t\t\t\t\t\tanimation:false,\n\t\t\t\t\t\t\tinteractive:false,\n\t\t\t\t\t\t\tvariant:'dark',\n\t\t\t\t\t\t\ttitle:getItemShuffleTooltip(section.settings.itemSelection)\n\t\t\t\t\t\t}",modifiers:{"hover":true}}],staticClass:"m-auto",attrs:{"name":_vm.getShuffleIcon(section.settings.itemSelection)}})],1):_vm._e()]),_vm._l((section.items),function(item,itemIndex){return _c('span',[_c('b-button',{staticClass:"rounded-0 text-light shadow-none pointer bg-form-theme info p-2 pl-3x p-0 text-left border-bottom border-item d-flex m-0",staticStyle:{"font-size":"0.9em"},attrs:{"variant":"none","block":""},on:{"click":function($event){return _vm.toggleFoldout(item.id)}}},[_c('icon',{staticClass:"mr-2",attrs:{"name":_vm.icons[item.type] ? _vm.icons[item.type] : 'mdi.circle-outline'}}),_c('div',{staticClass:"flex-fill prel"},[_c('div',{staticClass:"abstretch text-truncate nats-font"},[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('div',{staticClass:"d-flex flex-row"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
								interactive:false,
								animation:false,
								title:_vm.translate('forms.action.hideTitle'),
								placement:'bottom',
								variant:'light',
							}),expression:"{\n\t\t\t\t\t\t\t\tinteractive:false,\n\t\t\t\t\t\t\t\tanimation:false,\n\t\t\t\t\t\t\t\ttitle:translate('forms.action.hideTitle'),\n\t\t\t\t\t\t\t\tplacement:'bottom',\n\t\t\t\t\t\t\t\tvariant:'light',\n\t\t\t\t\t\t\t}",modifiers:{"hover":true}}],staticClass:"flag-wrapper ml-2 bg-info"},[_c('icon',{staticClass:"icon",attrs:{"name":"mdi.alphabetical-off"}})],1),(item.optional)?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
								interactive:false,
								animation:false,
								title:_vm.translate('forms.label.optional'),
								placement:'bottom',
								variant:'light',
							}),expression:"{\n\t\t\t\t\t\t\t\tinteractive:false,\n\t\t\t\t\t\t\t\tanimation:false,\n\t\t\t\t\t\t\t\ttitle:translate('forms.label.optional'),\n\t\t\t\t\t\t\t\tplacement:'bottom',\n\t\t\t\t\t\t\t\tvariant:'light',\n\t\t\t\t\t\t\t}",modifiers:{"hover":true}}],staticClass:"flag-wrapper ml-2 bg-success"},[_c('icon',{staticClass:"icon",attrs:{"name":"mdi.ghost"}})],1):_vm._e()]),_c('div',{staticClass:"ml-2"},[_c('icon',{attrs:{"name":_vm.foldouts[item.id] ? 'mdi.chevron-down' : 'mdi.chevron-right'}})],1)],1),(_vm.foldouts[item.id])?_c('div',{},[_c('div',{staticClass:"p-1 d-flex"},[_c('small',{staticClass:"font-weight-light m-auto"},[_c('icon',{staticClass:"mr-1",attrs:{"name":_vm.icons[item.type] ? _vm.icons[item.type] : 'mdi.circle-outline'}}),_vm._v(" "+_vm._s(_vm.translate(("forms.label." + (item.type.toLowerCase()))))+" ")],1)]),(item.name)?_c('span',[_c('hr',{staticClass:"bg-secondary m-0"}),_c('div',{staticClass:"p-3"},[_c('small',{staticClass:"font-weight-light"},[_vm._v(" "+_vm._s(item.name)+" ")])])]):_vm._e(),(item.settings && !_vm.isEmptyObject(item.settings))?_c('span',[_c('hr',{staticClass:"bg-secondary m-0"}),_c('div',{staticClass:"p-3"},[_c('DisplayJSON',{staticStyle:{"font-size":"0.8em"},attrs:{"data":item.settings,"pretty":true}})],1)]):_vm._e()]):_vm._e()],1)}),(section.items.length === 0)?_c('div',{staticClass:"bg-secondary p-1 d-flex",staticStyle:{"opacity":"0.5"}},[_c('span',{staticClass:"m-auto"},[_c('small',[_vm._v(" "+_vm._s(_vm.translate("assets.message.noItems"))+" ")])])]):_vm._e()],2)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }