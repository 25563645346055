




















// vendor
import { defineComponent } from "@vue/composition-api";
import { default as Advanced } from "./advanced.vue";
import { default as SessionSettings } from "./session-settings.vue";
import { default as InfoTooltip } from "./info-tooltip.vue";
import { Heading, Container, Divider, TipIcon } from "@ui";
import { useTranslate } from "@lang";

export default defineComponent({
	components: {
		Advanced,
		SessionSettings,
		TipIcon,
		Container,
		Divider,
		Heading,
	},

	setup() {
		const { translate } = useTranslate();
		return {
			translate,
		};
	},
});
