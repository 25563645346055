




















// vendor
import { defineComponent, computed, ref } from "@vue/composition-api";

// Function to download data to a file
function downloadFile(data:any, filename:string, type:string) {
var file = new Blob([data], {type: type});


	var a = document.createElement("a"),
	url = URL.createObjectURL(file);
	a.href = url;
	a.download = filename;
	document.body.appendChild(a);
	a.click();
	setTimeout(function() {
		document.body.removeChild(a);
		window.URL.revokeObjectURL(url);  
	}, 0); 
}

export default defineComponent({

	emits:[],
	props:{
		tooltip:{
			type:String,
			default:"",
		},
		filename:{
			type:String,
			default:"file.txt",
		},
		filetype:{
			type:String,
			default:"application/txt",
		},
		value:{
			type:String,
			default:"",
		}
	},
	components:{
	},
	setup(props){
		
		const input = ref<HTMLInputElement>()

		const download = () => {
			if(!input.value){ return; }
			// input.value.select();
			// input.value.setSelectionRange(0, 99999); /* For mobile devices */
			// document.execCommand("copy");

			downloadFile(props.value, props.filename, props.filetype);

			// c.download = "user-text.txt";
		};

		return {
			input,
			download,
		};
	}
});

