
















































// vendor
import { defineComponent, ref, computed } from "@vue/composition-api";
// local
import { UploadFileConfig, UploadFileHandler } from "./IUploadFile";

export default defineComponent({
	setup() {
		const show = ref(false);
		const config = ref<UploadFileConfig|null>(null);
		const onDone = ref<UploadFileHandler|null>(null);
		const file = ref<File>();

		const canConfirm = computed(() => Boolean(file.value))

		const title = computed(() => config.value?.title || "Upload File");

		const placeholder = computed(() => config.value?.placeholder || "Choose a file or drop it here...");

		const variant = computed(() => {
			return config?.value?.variant || "dark";
		});

		const textVariant = computed(() => {
			return ({
				"light":"dark"
			} as any)[variant.value] || "light";
		});

		const ok = async () => {
			if (onDone.value && file.value) {
				onDone.value({
					file:file.value,
				});
			}
			show.value = false;
		};

		const hide = () => {
			onDone.value = null;
			config.value = null;
			show.value = false;
			file.value = undefined;
		};

		const open = (c:UploadFileConfig, fn:UploadFileHandler) => {
			config.value = c;
			onDone.value = fn;
			show.value = true;
		};

		return {
			title,
			show,
			file,
			canConfirm,
			ok,
			hide,
			open,
			placeholder,
			variant,
			textVariant,
		};
	},
});

