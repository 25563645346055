import { defineComponent, ref } from "@vue/composition-api";
import * as DeviceUtils from "@utils/device";
import { AbsBox, Icon } from "@ui";
import { useTranslate } from "@lang";

enum Translations {
	DeviceSetup = "session.label.deviceSetup",
	EnableMotion = "session.message.pleaseEnableMotion",
}

export const RequestMotion = defineComponent({
	emits:[ "allowed", "denied" ],
	setup(_, ctx){

		const { translate } = useTranslate();
		
		const motionAllowed = ref(false);
		const done = () => {
			ctx.emit(motionAllowed.value ? "allowed" : "denied");
		};

		const confirm = async () => {
			motionAllowed.value = await DeviceUtils.hasDeviceOrientation();
			done();
		};

		return {
			confirm,
			translate,
		};
	},
	render(){
		return (
			<AbsBox class="bg-dark text-light">
				<AbsBox class="d-flex justify-content-center flex-column align-items-center">
					<div class="text-center">
						<h2 class="text-uppercase font-weight-bold">
							{ this.translate(Translations.DeviceSetup) }
						</h2>
						<h6 class="font-weight-light">
							{ this.translate(Translations.EnableMotion) }
						</h6>
					</div>
					<div class="p-0" style="position:relative;">
						<Icon
						name="cellphone-cog mdi-rotate-45"
						style="font-size:6rem;"
						/>
						
					</div>
					<button
					v-on:click={ this.confirm }
					style="width:4rem;height:4rem;"
					class="btn btn-lg btn-info shadow font-weight-bold p-0 rounded-circle d-flex"
					>
						<Icon
						name="arrow-right-thick"
						style="font-size:2.5rem"
						class="m-auto"
						/>
					</button>
				</AbsBox>


			</AbsBox>
		);
	}
});