import { defineComponent } from "@vue/composition-api";
import { OpenSession } from "@components/sessions";
import { ProvideRouteLang } from "@lang";

const Page = defineComponent({
	render(){
		const { sessionId } = this.$route.params;
		return (
			<ProvideRouteLang>
				<OpenSession sessionId={ sessionId }/>
			</ProvideRouteLang>
		);
	}
});

export default Page;