import { defineComponent, computed, PropType } from "@vue/composition-api";
import { TransformMode } from "@scene-viewer";

const modeOptions = [
	{ value: "translate", icon: "mdi.arrow-all" },
	{ value: "rotate", icon: "mdi.rotate-3d-variant" }
];

export const TransformToolbar = defineComponent({
	emits:[ "input" ],
	props:{
		value:{
			type:String as PropType<TransformMode>,
			required:true
		}
	},
	setup(props, context){
		const currentMode = computed({
			get:() => props.value,
			set:v => context.emit("input", v)
		});

		return {
			currentMode,
			modeOptions
		};
	}
});