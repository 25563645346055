import { render, staticRenderFns } from "./render-markdown.vue?vue&type=template&id=498dc4dd&scoped=true&"
import script from "./render-markdown.vue?vue&type=script&lang=ts&"
export * from "./render-markdown.vue?vue&type=script&lang=ts&"
import style0 from "./render-markdown.vue?vue&type=style&index=0&id=498dc4dd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "498dc4dd",
  null
  
)

export default component.exports