




































// vendor
import { defineComponent, computed, ref, watch, SetupContext, ComputedRef, inject } from "@vue/composition-api";
// local
import { default as BlueprintGraph } from "./blueprint-graph.vue";
import { default as BlueprintChannels } from "./blueprint-channels.vue";
import { StudyContext } from "@/components.studies/manage-study";



export const setQueryValue = (k:string, v:any, context:SetupContext) => {
	const q:any = { ...context.root.$route.query };
	if(v !== undefined && v !== null){
		q[k] = v;
	}
	else {
		delete q[k];
	}
	context.root.$router.push({ query: q})
	.catch(() => {});
};

// const tabs = [
// 	{
// 		icon:"mdi.state-machine",
// 		name:"graph"
// 	},
// 	{
// 		icon:"mdi.database",
// 		name:"channels"
// 	},
// ];

export default defineComponent({

	emits:[],
	props:{
	},
	components:{
		BlueprintGraph,
		BlueprintChannels,
	},
	setup(props, context){

		const studyContext = inject<ComputedRef<StudyContext>>("studyContext");

		if(!studyContext){ throw Error("Channels Preview: Study context missing!"); }

		const {
			channels,
		} = studyContext.value;

		const value = ref("graph");

		const selectTab = (n:string) => {
			setQueryValue("tab", n, context);
		};

		const applyValue = (v:any) => {
			if(tabs.value.findIndex(t => t.name === v) > -1){
				value.value = v as any;
			}
		};

		const tabs = computed(() => {
			const tabs = [
				{
					icon:"mdi.state-machine",
					name:"graph"
				},
			];
			if(channels.value.length){
				tabs.push({
					icon:"mdi.database",
					name:"channels"
				});
			}
			return tabs;
		});

		watch(() => context.root.$route.query["tab"], (v) => {
			applyValue(v);
		});

		
		applyValue(context.root.$route.query["tab"]);


		return {
			tabs,
			value,
			selectTab,
		};
	}
});

