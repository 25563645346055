import { EventBus } from "./EventBus";
import { Dictionary } from "./Dictionary";

export class InputKeys {

	constructor(){
		this._up = this.onKeyUp.bind(this);
		this._down = this.onkeyDown.bind(this);
		document.addEventListener("keydown", this._down, true);
		document.addEventListener("keyup", this._up, true);
	}

	public on<N extends keyof InputEvents>(name:N, fn:InputEvents[N]){
		this._events.on(name, fn);
	}

	public off<N extends keyof InputEvents>(name:N, fn:InputEvents[N]){
		this._events.off(name, fn);
	}

	public isDown(k:string){
		return this._keyStates[k];
	}

	public dispose(){
		document.removeEventListener("keydown", this._down);
		document.removeEventListener("keyup", this._up);
	}

	private _up:(e:KeyboardEvent) => void;
	private _down:(e:KeyboardEvent) => void;

	private _keyStates:Dictionary<boolean> = {};
	private _events = new EventBus<InputEvents>();

	private onkeyDown(e:KeyboardEvent){
		if(!this._keyStates[e.key]){
			this._keyStates[e.key] = true;
			this._events.emit(e.key, e);
		}
	}

	private onKeyUp(e:KeyboardEvent){
		this._keyStates[e.key] = false
	}

}


interface InputEvents {
	[k:string]:Function
}