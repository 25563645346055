import { defineComponent, computed } from "@vue/composition-api";
import { ContextHeader } from "@components/nav";
import { Divider, Heading, Container, AbsBox } from "@ui";
import { useTranslate } from "@lang";
import { RecentStudies } from "./RecentStudies";
import { RecentAssets } from "./RecentAssets";

enum Translations {
	Home = "label.home",
	RecentStudies = "studies.label.studiesWithRecentSessions",
	RecentAssets = "assets.label.recentlyModifiedAssets",
}

export const HomeSummary = defineComponent({
	render(){
		return (
			<PageContainer>
				<HomeNav/>
				<Section title={Translations.RecentStudies}>
					<RecentStudies/>
				</Section>
				<Section title={Translations.RecentAssets}>
					<RecentAssets/>
				</Section>
			</PageContainer>
		);
	}
});

const PageContainer = defineComponent({
	render(){
		return (
			<AbsBox class="pt-3" style="overflow:auto">
				{this.$slots.default}
			</AbsBox>
		);
	}
});

const Section = defineComponent({
	props:[ "title" ],
	setup(props){
		const { translate } = useTranslate();
		return {
			text:computed(() => translate(props.title || ""))
		}
	},
	render(){
		return (
			<Container class="mb-5">
				<Heading
				text={this.text}
				size={4} />
				<Divider/>
				{this.$slots.default}
			</Container>
		);
	}
});

const HomeNav = defineComponent({
	setup(){
		const { translate } = useTranslate();
		return {
			title:computed(() => translate(Translations.Home))
		}
	},
	render(){
		return (
			<ContextHeader>
				<div class="h-100 d-flex px-3">
					<Heading
					text={this.title}
					size={2}
					class="my-auto"/>
				</div>
			</ContextHeader>
		);
	}
})