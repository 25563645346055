





import { defineComponent, computed } from "@vue/composition-api";
import { useAssets } from "@/hooks/useAssets";
import { useFavourites } from "@/hooks/useFavourites";
import { getAssetTypes } from "@/psychlab/meta"
import { useTranslate } from "@lang";
import { default as NavItemList } from "./nav-item-list.vue";

const iconMap:Record<string, string> = {};
const colorMap:Record<string, string> = {};

getAssetTypes().forEach(at => {
	iconMap[`asset.${at.name}`] = at.icon;
	colorMap[`asset.${at.name}`] = at.color;
});

export default defineComponent({
	components:{
		NavItemList
	},
	setup(_, context){

		const {
			assets
		} = useAssets();

		const { translate } = useTranslate();

		const {
			addFavourite,
			removeFavourite,
			isFavourite
		} = useFavourites();


		const navOptions = computed(() => {
			const r = assets.value.map((asset, i) => {

				let name = asset.name;
				let icon = iconMap[`asset.${asset.type}`] || "mdi.circle-outline";
				let iconColor = colorMap[`asset.${asset.type}`];

				return {
					modified:asset.lastModified || "",
					asset,
					name,
					icon,
					iconColor,
					fn(){
					},
					contextFn(){
						const options:any[] = [];
						if(!isFavourite(asset._id)){
							options.push({
								icon:"mdi.star",
								name:translate("action.addToFavourites"),
								fn(){
									addFavourite(asset._id, "asset");
								}
							});
						}
						else {
							options.push({
								icon:"mdi.star-outline",
								name:translate("action.removeFromFavourites"),
								fn(){
									removeFavourite(asset._id);
								}
							})
						}

						return options;
					},
					activeFn(){
						const a = 
						(context.root.$route.name === "view-asset" || context.root.$route.name === "asset.edit") && 
						context.root.$route.params["assetId"] === asset._id
						return a;
					},
					routeFn(){
						return {
							name:"view-asset", 
							params:{
								assetId:asset._id
							}
						}
					}
				}
			});
			r.sort((a, b) => (new Date(b.modified)).getTime() - (new Date(a.modified)).getTime());
			return r;
		});
		return {
			navOptions
		};
	}
});

