export class KeyboardInput {

	constructor(el?:EventTarget){
		this._el = el ? el : window;
		this._keyUpFn = this.onKeyUp.bind(this) as EventListener;
		this._keyDownFn = this.onKeyDown.bind(this) as EventListener;
		this._el.addEventListener("keyup", this._keyUpFn);
		this._el.addEventListener("keydown", this._keyDownFn);
	}

	public getKey(k:string):boolean{
		return Boolean(this._keys[k.toLowerCase()]);
	}

	public dispose(){
		this._el.removeEventListener("keyup", this._keyUpFn);
		this._el.removeEventListener("keydown", this._keyDownFn);
	}

	private _el:EventTarget;
	private _keys:Dictionary<boolean> = {};
	private _keyUpFn:EventListener;
	private _keyDownFn:EventListener;
	private onKeyDown(e:KeyboardEvent){ this._keys[e.key.toLowerCase()] = true; }
	private onKeyUp(e:KeyboardEvent){ this._keys[e.key.toLowerCase()] = false; }
}

interface Dictionary<T> { [k:string]:T }