// vendor
import * as THREE from "three";
// local
import { ICameraController } from "../core/ICameraController";
import { getApp } from "../App";
import { PanningControls } from "../utils/PanningControls";
import { DeviceOrientationControls } from "../utils/DeviceOrientationControls";
import { Vector3 } from "../core/Vector3";

export interface IDeviceCamera extends ICameraController {
	init(c:Config):void;
	setPosition(p:Vector3):void;
	setRotation(r:Vector3):void;
};

export const useDeviceCamera = (el:HTMLElement):IDeviceCamera => {

	const camera:THREE.PerspectiveCamera = getCamera(el);

	let orientation:DeviceOrientationControls|null = null;
	let panning:PanningControls|null = null;

	const setPosition = (pos:Vector3) => {
		if(panning){
			panning.setPosition(pos);
		}
		if(orientation){
			camera.position.set(pos.x, pos.y, pos.z);
		}
	};

	const setRotation = (rot:Vector3) => {
		if(panning){
			panning.setRotationX(rot.x);
			panning.setRotationY(rot.y);
		}
	};

	const init = (c:Config) => {
		if(c.accelerometer){
			orientation = new DeviceOrientationControls(camera);
		}
		else {
			panning = new PanningControls(camera, el);
		}
	};

	const resize = () => {
		const size = getSize(el);
		camera.aspect = size.x/size.y;
		camera.updateProjectionMatrix();
	};

	const update = () => {
		if(orientation){ orientation.update(); }
		if(panning) { panning.update(); }
	};

	const destroy = () => {
		orientation?.dispose();
		panning?.dispose();
	};

	const r:IDeviceCamera = {
		enabled:true,
		type:"DeviceCamera",
		camera,
		init,
		setPosition,
		setRotation,
		resize,
		update,
		destroy,
	};

	getApp().registerBehaviour(r);

	return r;
};

const getSize = (el:HTMLElement) => {
	let x:number = el.offsetWidth;
	let y:number = el.offsetHeight;
	return { x, y };
};

const getCamera = (el:HTMLElement) => {
	const size = getSize(el);
	return new THREE.PerspectiveCamera(75, size.x/size.y, 0.1, 1000);
};

type Config = {
	accelerometer:boolean;
};