// vendor
import { defineComponent, ref, computed, PropType, reactive, onMounted, onUnmounted } from "@vue/composition-api";
// project
import { pascalToSentenceCase } from "@utils/text";
// local
import { NodeTemplate } from "./types";

import { useTranslate } from "@lang";

export const AddNodeOverlay = defineComponent({

	emits:[ "add" ],
	props:{
		templates:{
			type:Array as PropType<NodeTemplate<any>[]>,
			required:true
		}
	},
	setup(props, context){

		const dropElement = ref<HTMLElement>();

		const dropRoot = ref<HTMLElement>();

		const showBar = ref(true);
		const addingType = ref<string>();
		const showDrop = ref(false);
		const dropPos = reactive({ x: 0, y: 0 });

		const isAdding = computed(() => addingType.value !== undefined);

		const { translate } = useTranslate();

		// setTimeout(() => {
		// 	showBar.value = true;
		// }, 100);
		

		const dropNode = computed(() => {
			if(!showDrop.value){ return undefined; }
			const template = props.templates.find(t => t.name === addingType.value);
			if(!template){ return null; }
			return {
				icon: template.icon,
				size: 50,
				template:template.template
			};
		});

		const filteredTemplates = computed(() => props.templates.filter(t => !t.hide));

		const getTypeTooltip = (t:string) => {
			return translate(`assets.label.${t.toLowerCase()}`);
			// return pascalToSentenceCase(t)
		};

		const selectType = (e:any, t:any) => {
			if(!t){ return; }
			// this.dropPos = {
			// 	x:e.layerX,
			// 	y:e.layerY
			// }
			// dropPos.x = e.layerX;
			// dropPos.y = e.layerY;
			addingType.value = t.name;
			showDrop.value = true;
		}

		const onMouseMove = (e:any) => {
			dropPos.x = e.layerX;
			dropPos.y = e.layerY;

			// get proper coordinates if available
			if(dropRoot.value){
				const bounds = dropRoot.value.getBoundingClientRect();
				const posX = e.clientX - bounds.left;
				const posY = e.clientY - bounds.top;
				dropPos.x = posX;
				dropPos.y = posY;
			}
		};

		const onWindowMouseup = (e:any) => {
			addingType.value = undefined;
			showDrop.value = false;
		};

		const onDrop = (e:any) => {
			const data = {
				type: addingType.value,
				x: e.layerX,
				y: e.layerY
			};
			context.emit("add", data);
		};

		const toggleHandle = () => {
			showBar.value = !showBar.value;
		};

		const onHandleTouched = () => {
			showBar.value = true;
		};

		onMounted(() => {
			window.addEventListener("mouseup", onWindowMouseup);
		});

		onUnmounted(() => {
			window.removeEventListener("mouseup", onWindowMouseup);
		});
		

		return {
			dropElement,
			showBar,
			addingType,
			showDrop,
			dropPos,
			isAdding,
			filteredTemplates,
			dropNode,
			dropRoot,
			pascalToSentenceCase,
			selectType,
			toggleHandle,
			onHandleTouched,
			onMouseMove,
			onDrop,
			getTypeTooltip,
		};
	}

});