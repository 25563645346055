import * as SessionAPI from"../sessions";

type Dictionary<T> = { [k:string]:T };

export const countStudySessions = async(studyId:string, q:Dictionary<any> = {}):Promise<number> => {
	const qq = {
		...q,
		group:studyId
	};
	return await SessionAPI.getSessionCount(qq);
};

export const countStudiesSessions = async (studies:string[], q:Dictionary<any> = {}):Promise<Dictionary<number>> => {

	const r:Dictionary<number> = {};

	studies.forEach(sid => r[sid] = 0);

	
	await Promise.all(studies.map(sid => {


		return new Promise<void>(async (resolve, reject) => {

			try {
				r[sid] = await SessionAPI.getSessionCount({
					...q,
					group:sid,
				});
				resolve();
			}
			catch(err){
				reject(err);
			}

		});
	}));
	return r;
};