





















import { defineComponent, computed, ref, PropType } from "@vue/composition-api";

type DropdownConfig = {
	options:{ text:string, value:string, group?:string, groupName?:string }[];
};

export default defineComponent({
	emits:[ "input" ],
	props:{
		value:{
			// type:String
		},
		label:{
			type:String
		},
		config:{
			type:Object as PropType<DropdownConfig>,
			required:true
		}
	},
	setup(props, context){
		
		const options = computed(() => {

			const opts:any[] = [];

			const groups:any = {};


			props.config.options.forEach(o => {

				if(!o.group){
					opts.push(o);
					return;
				}
				
				if(groups[o.group]){ // already added
					return;
				}

				const grouped = props.config.options.filter(go => go.group === o.group);

				groups[o.group] = true;

				if(grouped.length === 1){
					opts.push(o);
					return;
				}
				
				opts.push({
					label: o.groupName,
					options:grouped
				});

			});
			return opts;
		});

		const val = computed({
			get: () => props.value,
			set:v => context.emit("input", v)
		});
		
		if((props.value === null || props.value === undefined) && options.value.length > 0){
			val.value = options.value[0].value;
		};

		return {
			options,
			val
		};
	}
});

