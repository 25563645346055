import { defineComponent, ref, computed, reactive } from "@vue/composition-api";
import { getAssetVersion } from "@/services/api/assets";
import { useTranslate } from "@lang";
import { Icon, Heading } from "@ui";
import { CreateStudyForm, NewStudyFormData } from "./CreateStudyForm";

type WizardResult = {
	study:string;
	version:string;
	name:string;
	language:string;
	auth?:string;
	openDashboard:boolean;
};

export type StudyWizardHandler = (r:WizardResult) => void;

export interface ICreateStudyWizard {
	open(config:StudyWizardConfig, fn:StudyWizardHandler):void;
};

export type StudyWizardConfig = {
	blueprint?:string;
	tag?:string;
};

enum Translations {
	OpenDashboard = "studies.action.openStudyDashboard",
	ConfirmAndCreate = "prompts.action.confirmAndCreate",
	Cancel = "prompts.action.cancel",
	CreateStudy = "studies.action.createStudy",
}

const getFormDefaults = ():NewStudyFormData => ({
	name:"",
	blueprint:"",
	language:"en",
});

const getLatestAssetVersion = async (aid:string) => {
	const v = await getAssetVersion(aid, "latest");
	return `${aid}@${v._id}`;
};

export const CreateStudyWizard = defineComponent({
	setup(){

		const show = ref(false);
		const modal = ref<any>();
		const form = ref<any>();

		const onDone = ref<StudyWizardHandler|null>(null);
		const formData = reactive<NewStudyFormData>(getFormDefaults());
		const openDashboard = ref(true);

		const { translate } = useTranslate();

		const getDefaultName = () => translate("studies.label.newStudy");

		const onHidden = () => {
			show.value = false;
			openDashboard.value = true;
			onDone.value = null;
			Object.assign(formData, getFormDefaults());
		};

		const cancel = () => {
			if(!modal.value){ return; }
			modal.value.hide();
		};

		const getResult = () => {
			const [ asset, version ] = formData.blueprint.split("@");

			const name = formData.name || getDefaultName();
			return {
				study:asset,
				version,
				name,
				language:formData.language,
				auth:formData.auth,
				// 
				openDashboard:openDashboard.value,
			};
		};

		const submit = () => {
			const result = getResult();

			if(result && onDone.value){
				onDone.value(result);
			}

			if(modal.value){
				modal.value.hide();
			}
		};

		const open = async(config:StudyWizardConfig, fn:StudyWizardHandler) => {
			const { blueprint } = config;

			formData.name = getDefaultName();

			if(blueprint){
				formData.blueprint = await getLatestAssetVersion(blueprint); // <aid>@<vid>
			}
			show.value = true;
			onDone.value = fn;
		};


		return {
			show,
			openDashboard,
			modal,
			form,
			formData,
			submit,
			cancel,
			onHidden,
			open,
			canSubmit:computed(() => {
				return formData.name.length >= 2 && Boolean(formData.blueprint);
			}),
			phrases:computed(() => ({
				openDashboard:translate(Translations.OpenDashboard),
				cancel:translate(Translations.Cancel),
				confirm:translate(Translations.ConfirmAndCreate),
			})),
			onShown(){
				// todo: focus form
				form.value?.focus();
			}
		};
	},
	render(){
		return (
			<b-modal
			v-on:hidden={ this.onHidden }
			v-on:shown={ this.onShown }
			v-model={ this.show }
			ref="modal"
			ok-variant="info"
			class="p-0"
			body-class="p-0 bg-light rounded border border-info"
			hide-header
			ok-only
			hide-footer
			centered
			>
				<WizardHeader/>

				<CreateStudyForm
				ref="form"
				data={ this.formData }/>

				<hr class="bg-info m-0"/>

				<div class="p-3 d-flex">
					<div class="custom-control custom-checkbox mr-sm-2">
						<input v-model={ this.openDashboard } type="checkbox" class="custom-control-input" id="open-dash-input"/>
						<label class="custom-control-label" for="open-dash-input">
							{ this.phrases.openDashboard }
						</label>
					</div>
				</div>

				<hr class="bg-info m-0"/>

				<ButtonFooter>
					<FormButton v-on:click={ this.cancel } label={ this.phrases.cancel } variant="dark"/>
					<FormButton v-on:click={ this.submit } disabled={ !this.canSubmit } label={ this.phrases.confirm } variant="outline-info"/>
				</ButtonFooter>

			</b-modal>
		)
	}
});

const ButtonFooter = defineComponent({
	render(){
		return (
			<div class="p-3 d-flex justify-content-between">
				{ this.$slots.default }
			</div>
		);
	}
});

const FormButton = defineComponent({
	emits:[ "click" ],
	props:[ "label", "disabled", "variant" ],
	render(){
		const vcls = `btn-${this.variant}`
		return (
			<button
			disabled={ this.disabled }
			v-on:click={ (e:Event) => this.$emit("click", e) }
			class={ `btn btn-lg ${vcls}` }
			style="font-family:'NATS'"
			>
				{ this.label }
			</button>
		);
	}
});

const WizardHeader = defineComponent({
	setup(){
		const { translate } = useTranslate();
		return { translate };
	},
	render(){
		return (
			<div class="bg-info p-3" style="position:relative;">
				<Heading size={3} center nats text={ this.translate(Translations.CreateStudy) }/>
				<PinnedIcon/>
			</div>
		)
	}
});

const PinnedIcon = defineComponent({
	render(){
		const style = `
			position:absolute;
			width:3rem;
			height:3rem;
			transform:translate(-50%,-50%);
			left:50%;
			top:0;
			border-width:4px !important;
		`;

		return (
			<div
			style={ style }
			class="rounded-circle bg-dark d-flex border border-info"
			>
				<Icon name="rocket mdi-rotate-45" style="font-size:1.5rem" class="m-auto text-light"/>
			</div>
		)
	}
});