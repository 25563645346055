import { http } from "@/http";
import { Widget } from "@/psychlab/types/widget";

type Dictionary<T> = { [k:string]:T };

type CreateParams = {
	name?:string;
	computeType:string;
	computeArgs:Dictionary<any>;
	template:any;
	templateBindings:string[];
	templateArgs:Dictionary<any>;
	metadata?:Dictionary<string>;
};


export const createWidget = async (
	studyId: string,
	params: CreateParams,
): Promise<Widget> => {
	const { data } = await http.post(
		`studies/${studyId}/analytics/widgets`,
		params,
	);
	return data;
};