





import { defineComponent, PropType } from "@vue/composition-api";
import { ColorVariant } from "./bootstrap/ColorVariant";

export default defineComponent({
	props:{
		v:{
			type:String as PropType<ColorVariant>,
			default:"secondary"
		}
	}
});
