<template>
	<div class="overlay"></div>
</template>

<script>
	export default {}
</script>

<style scoped lang="scss">

	$danger:#dc3545;

	.overlay {
		border-left:3px solid $danger;
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		pointer-events: none;
	}
</style>