/** @format */

export const widgetTemplates = [
	require("./icon-value-01.json"),
	require("./icon-value-02.json"),
	require("./chart.json"),
	// require("./pie-chart.json"),
	// require("./bar-chart.json"),
	// require("./line-chart.json"),
	require("./image-01.json"),
	require("./gallery.json"),
];
