import { defineComponent, ref, computed, PropType, set, reactive, watch, onMounted } from "@vue/composition-api";
import { EditGraph } from "@/components.edit/edit-graph";
import { GraphData, NodeTemplate, TransitionValidator } from "@/components.edit/edit-graph";
import { ContextOption } from "@/components.generic/context-menu";
import * as AppModal from "@/AppModal";
import { default as NodeInspector } from "../vue/node-inspector.vue";
import { default as NodeWindow } from "../vue/node-window.vue";
import { default as NodeStatusButtons } from "../vue/node-status-buttons.vue";
import { default as NodeLabel } from "../vue/node-label.vue";
import { computeNodeLabel } from "../ts/utils/computeNodeLabel";
import * as NodeTemplates from "../ts/templates";
import * as TransitionValidators from "../ts/TransitionValidators";
import { getReferencedAssets } from "./utils/getReferencedAssets";
import { getLatestAssetVersions } from "./utils/getLatestAssetVersions";
import { updateNodeReferences } from "./utils/updateNodeReferences";

import { AbsBox } from "@ui";
import { useTranslate } from "@lang";
import { checkNodeRefs } from "./utils/checkNodeRefs";

type Dictionary<T> = { [k:string]:T };

const obToArr = (ob:any) => Object.keys(ob).map(k => ob[k]);

const embeddedTypes = [ "Scene" ];

const canOpenEmbedded = (type:string) => embeddedTypes.indexOf(type) > -1

export const EditBlueprint = defineComponent({

	components:{
		EditGraph,
		NodeInspector,
		NodeWindow,
		NodeStatusButtons,
		NodeLabel,
		AbsBox,
	},
	props:{
		data:{
			type:Object as PropType<GraphData>,
			required:true
		}
	},
	setup(props, context){

		const nodeLabels = reactive<Dictionary<LabelData>>({});
		const currentWindowId = ref<string>();
		const editorViewport = ref<EditorViewport>();
		const graph = computed(() => props.data);
		const labels = ref<Dictionary<string>>({});
		const latestVersions:Dictionary<string|null> = {};
		const { translate } = useTranslate();


		// const refs = ref<Dictionary<string>>({});

		const nodeRefs = reactive<Dictionary<any>>({});

		const config = computed(() => {
			const templates = obToArr(NodeTemplates) as NodeTemplate<any>[];
			const transitionRules = obToArr(TransitionValidators) as TransitionValidator[];
			return {
				templates,
				transitionRules
			}
		});

		const nodeActions = ref<Dictionary<any[]>>({});

		const currentWindowNode = computed(() => {
			return currentWindowId.value ?  props.data.nodes[currentWindowId.value] : undefined; 
		});

		const startNode = computed(() => {

			if(props.data.startNode){
				return props.data.startNode;
			}

			const ids = Object.keys(props.data.nodes);
			if(ids.length === 0){ return null; }

			let current = ids[0];
			
			ids.forEach(k => {
				const cn = props.data.nodes[current];
				const n = props.data.nodes[k];
				if(n.x < cn.x){
					current = k;
				}
			});
			return current;
		});

		const refreshActions = () => {
			Object.keys(props.data.nodes).forEach(async k => {
				// nodeActions.value[k] = await getNodeStatusIcons(k);
				set(nodeActions.value, k, await getNodeStatusIcons(k));

			});
		};

		const refreshRefs = async () => {

			if(!props.data){ return; }

			const ks = Object.keys(props.data.nodes);

			await Promise.all(ks.map(k => {
				return new Promise<void>(async (resolve, reject) => {
					await loadRefInfo(k);
					resolve();
				});

			}))

			refreshActions();

			
		};

		watch(graph, (v) => {
			if(!v){ return; }
			Object.keys(v.nodes).forEach(k => loadLabel(k));
			refreshActions();
			refreshRefs();
		}, { deep:true });

		watch(nodeLabels, v => {
			const vp = editorViewport.value;
			if(!vp){ return; }
			Object.keys(v).forEach(k => {
				let current = v[k];
				if(!current.loading){
					vp.setNodeLabel(k, current.text);
					set(labels.value, k, current.text);
				}
			});
		}, { deep:true });

		const closeNode = () => {
			currentWindowId.value = undefined;;
			(context.root.$router as any).replace({
				...context.root.$router.currentRoute,
				query: {
					n: undefined
				}
			}).catch(()=>{});
		};

		const loadLatestVersions = async(assets:string[]) => {
			const loaded = Object.keys(latestVersions);
			assets = assets.filter(a => !loaded.includes(a));
			const result = await getLatestAssetVersions(assets);
			Object.keys(result)
			.forEach(k => {
				latestVersions[k] = result[k];
			});
		};

		const referencedAssets = computed(() => {
			return getReferencedAssets(graph.value as any);
		});

		const getNodeStatusIcons = async(nid:string) => {

			const node = graph.value.nodes[nid];
			const refs = referencedAssets.value;
			
			const nrefs = refs.nodes[nid];

			await loadLatestVersions(refs.assets);

			let outdated = 0;

			nrefs.forEach(ar => {
				const s = ar.split("@");
				const a = s[0];
				const v = s[1];
				if(latestVersions[a] && latestVersions[a] !== v){
					outdated++;
				}
				
			});

			const actions:any[] = [];

			if(outdated){
				actions.push({
					tooltip: `${translate("assets.action.updateReferences")} (${outdated})`,
					variant:"info",
					icon:"mdi.update",
					disabled:false,
					async fn(){
						await updateNodeReferences(node as any);
					}
				});
			}

			if(canOpenEmbedded(node.type)){
				actions.push({
					icon:"mdi.subdirectory-arrow-right",
					tooltip:translate("action.open"),
					variant:"light",
					fn:() => openNode(nid)
				});
			}
			
			const refInfo = getNodeRefInfo(nid);

			if(refInfo && (!refInfo.valid || refInfo.missing)){

				const { valid, missing } = refInfo;

				let ricon = "mdi.alert"

				let rtp = translate(`assets.message.reference${missing ? 'Missing' : 'Unset'}`);

				let rvariant = "warning";

				if(missing){
					rvariant = "danger";
				}

				actions.push({
					icon:ricon,
					tooltip:rtp,
					variant:rvariant,
					fn(e:Event){
					}
	
				})
			}

			

			actions.push({
				icon:"mdi.dots-horizontal",
				tooltip:`${translate("label.more")}...`,
				variant:"light",
				fn(e:any){

					const options:ContextOption[] = [];
	
					if(props.data.startNode !== nid){
						options.push({
							name:translate("assets.action.setStart"),
							variant:"light",
							icon:"mdi.flag",
							fn:() => {
								set(props.data, "startNode", nid);
							}
						});
					}
	
					if(props.data.startNode === nid){
						options.push({
							name:translate("assets.action.unsetStart"),
							variant:"light",
							icon:"mdi.flag-remove",
							fn:() => {
								set(props.data, "startNode", null);
							}
						});
					}
	
					options.push({
						name:translate("action.delete"),
						variant:"light",
						icon:"mdi.trash-can",
						order:5,
						fn:() => {
							const vp = editorViewport.value;
							if(!vp){ return; }
							vp.deleteNode(nid);
						}
					});
					AppModal.context(e, options);
				}
			});
			return actions;
		};
		

		const openNodeContext = (e:any, node:string) => {
			const options:ContextOption[] = [];

			if(props.data.startNode !== node){
				options.push({
					name:translate("assets.action.setStart"),
					variant:"light",
					icon:"mdi.flag",
					order:0,
					fn:() => {
						set(props.data, "startNode", node);
					}
				});
			}

			if(props.data.startNode === node){
				options.push({
					name:translate("assets.action.unsetStart"),
					variant:"light",
					icon:"mdi.flag-remove",
					order:0,
					fn:() => {
						set(props.data, "startNode", null);
					}
				});
			}

			options.push({
				name:translate("assets.action.delete"),
				variant:"danger",
				icon:"mdi.trash-can",
				order:5,
				fn:() => {
					const vp = editorViewport.value;
					if(!vp){ return; }
					vp.deleteNode(node);
				}
			});
			AppModal.context(e, options);
		};

		const getNodeRefInfo = (node:string) => {
			return nodeRefs[node] || null;
		};


		const getNodeLabel = (node:string) => {
			const n = props.data.nodes[node];
			return n.name ? n.name : labels.value[node];
		}
		
		const openNode = (nodeId:string) => {
			const n = props.data.nodes[nodeId];
			if(!n){ return; }
			currentWindowId.value = nodeId;
			(context.root.$router as any).replace({
				...context.root.$router.currentRoute,
				query: {
					"n": nodeId
				}
			}).catch(()=>{});
		};

		const canOpenExternal = (nid:string) => false;


		const loadRefInfo = async(nodeId:string) => {
			
			// set(nodeRefs, nodeId, null);

			const node = props.data.nodes[nodeId];
			const info = await checkNodeRefs(node);
			set(nodeRefs, nodeId, info);
		};

		const loadLabel = async(nodeId:string) => {
			set(nodeLabels, nodeId, {
				loading:true,
				text: null
			});
			const node = props.data.nodes[nodeId];
			const label = await computeNodeLabel(node);
			set(nodeLabels, nodeId, {
				loading:false,
				text: label
			});
		};

		const openNodeExternal = (nodeId:string) => {
			// let routeId = this.getExternalAssetId(nodeId);
			// if(routeId){
			// 	openRouteTab(this.$router, "Design.Object", { objectId: routeId });
			// }
		};

		const refreshLabels = () => {
			if(!props.data){ return; }
			for(var key in props.data.nodes){
				loadLabel(key);
			}
		};

		const onRefreshViewport = () => {
			// this.nodeLabels = JSON.parse(JSON.stringify(this.nodeLabels));
		};

		const init = () => {
			// remove unrecognized node types
			Object.keys(props.data.nodes)
			.forEach(k => {
				const n = props.data.nodes[k];
				if(!(NodeTemplates as any)[n.type]){
					delete props.data.nodes[k];
					Object.keys(props.data.transitions)
					.forEach(tk => {
						const t = props.data.transitions[tk];
						if(t.path.includes(k)){
							delete props.data.transitions[tk];
						}
					});
				}
			});
			refreshLabels();
			refreshActions();
			refreshRefs();
		};

		const onModified = (e:any) => context.emit("modified", e);

		const setNodeName = (node:any, v:string) => {
			set(node, "name", v);
		};

		const renameNode = (node:string) => {
			const n = props.data.nodes[node];
	
			AppModal.editText({
				title:translate("assets.action.setNodeLabel"),
				showBackdrop:false,
				variant:"info",
				value:n.name || "",
				confirmText:translate("prompts.action.confirm"),
				cancelText:translate("prompts.action.cancel"),
				validator(v){
					return !v.includes(";");
				},
			}, (newValue:string) => {
				set(n, "name", newValue);
			});
		};

		onMounted(() => {
			if(context.root.$route.query["n"]){
				openNode((context.root.$route.query["n"] as any) as string);
			}
		});

		init();

		return {
			editorViewport,
			graph,
			config,
			currentWindowNode,
			currentWindowId,
			startNode,
			nodeLabels,
			labels,
			nodeActions,
			// nodeContextMenu,
			// renameNodeModal,
			// contextMenu,
			closeNode,
			openNode,
			canOpenExternal,
			onRefreshViewport,
			onModified,
			openNodeExternal,
			setNodeName,
			getNodeLabel,
			getNodeStatusIcons,
			openNodeContext,
			renameNode,
		};
	}

});


type LabelData = {
	text:string,
	loading:boolean
};

type EditorViewport = {
	setNodeLabel(key:string, v:string):void,
	deleteNode(nodeId:string):void
};