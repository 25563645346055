














// vendor
import { defineComponent } from "@vue/composition-api";
// project
import { ListStudySessions } from "@/components.studies/list-study-sessions";

export default defineComponent({
	components: {
		ListStudySessions
	},
	computed:{
		studyId(){
			return this.$route.params["groupId"];
		}
	},
	methods:{
		createQuery(){
			return {
				done: false,
				"expires[gt]": Date.now()
			}
		}
	}
});
