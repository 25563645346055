















import { generateGUID } from "@utils/guid";
import { defineComponent, computed, PropType } from "@vue/composition-api";

type Option = {
	text:string;
	value:any;
};

export default defineComponent({
	emits:[ "input" ],
	props:{
		value:null,
		options:{
			type:Array as PropType<Option[]>,
			default:() => [],
		},
		size:{
			type:String,
			default:"md"
		}
	},
	setup(props, ctx){
		
		const val = computed({
			get:() => props.value,
			set:v => ctx.emit("input", v)
		}); 

		return {
			val,
			generateGUID,
		};
	}
});

