import { VueConstructor, PluginObject } from "vue";
import { Translate } from "./components/Translate";

const components:Record<string,VueConstructor<any>> = {
	Translate,
};

export const LangPlugin:PluginObject<{}> = {
	install(Vue:VueConstructor<any>){
		Object.keys(components).forEach(k => {
			Vue.component(k, components[k]);
			Vue.component(k.toLowerCase(), components[k]);
		});
	}
};