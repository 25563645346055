/** @format */

import { http } from "@/http";

type EditArgs = {
	name?: string;
	color?: string;
};

export const editSessionLabel = async (
	study: string,
	label: string,
	args: EditArgs,
): Promise<void> => {
	await http.patch(`studies/${study}/labels/${label}`, args);
};
