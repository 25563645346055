














// vendor
import { defineComponent, computed, ref, PropType } from "@vue/composition-api";
// project
import { Graph } from "@/psychlab/types/graph";
// local
import { buildDisplayGraph } from "./ts/buildDisplayGraph";
import { default as RenderDisplayGraph } from "./render-display-graph.vue";

export default defineComponent({

	emits:[],
	props:{
		data:{
			type:Object as PropType<Graph>,
			required:true
		},
		version:{
			type:String,
			required:true
		}
	},
	components:{
		RenderDisplayGraph,
	},
	setup(props){

		const displayGraph = computed(() => {
			return buildDisplayGraph(props.data);
		});

		return {
			displayGraph
		};
	}
});

