





































































































import { defineComponent, computed, ref, PropType, watch, inject } from "@vue/composition-api";
import { Gallery } from "@/psychlab/types/gallery";
import * as SessionAPI from "@/services/api/sessions";
import { SessionContext } from "@components/sessions";
import * as Platform from "@/utils/platform";
import { default as RenderPanorama } from "./render-panorama.vue";
import { default as RenderPanoramaMobile } from "./render-panorama-mobile.vue";
import { default as RenderStatic } from "./render-static.vue";
import { GalleryFeedback } from "./feedback";
import { GalleryThumbnails } from "./thumbnails";
import { DefaultNavigation } from "./navigation";
import { FeatureFlags, isFlagEnabled } from "@app";

type DataChannel = {
	key:string;
	data:any;
};

export default defineComponent({

	emits:[
		"back",
		"end",
	],
	props:{
		data:{
			type:Object as PropType<Gallery>,
			required:true
		},
		dataNamespace:{
			type:String,
			required:true
		},
		showBack:{
			type:Boolean,
			default:false
		},
		isLast:{
			type:Boolean,
			default:false
		}
	},
	components:{
		GalleryThumbnails,
		RenderPanorama,
		RenderStatic,
		GalleryFeedback,
		DefaultNavigation,
		RenderPanoramaMobile,
	},
	setup(props, context){

		const getSessionContext = inject<() => SessionContext>("getSessionContext");

		if(!getSessionContext){
			throw Error("Run Gallery: Session context missing!")
		}
		
		const {
			sessionId,
			cache,
			setProgressStep,
			accelerometerEnabled,
			isMobile,
		} = getSessionContext();

		const { data, dataNamespace } = props;

		const menuOpen = ref(true);
		const loading = ref(false);
		const feedbackVal = ref(-1);
		const panoramaLoading = ref(false);
		const activeItemId = ref<string|null>(null);

		const items = computed(() => data.items ? data.items : []);
		const activeItem = computed(() => items.value.find(it => it.id === activeItemId.value));
		const feedback = computed(() => data.feedback);

		const onLast = computed(() => {
			const ai = items.value.findIndex(item => item.id === activeItemId.value);
			if(ai === items.value.length - 1){
				return props.isLast;
			}
			if(props.data.navPerItem){
				return false;
			}
			if(props.data.feedback && props.data.feedback.perItem){
				return false;
			}
			return props.isLast;
		});

		const responseLoading = computed(() => {
			// if(feedback.value && feedback.value.perItem){
			// 	return loading.value || panoramaLoading.value;
			// }
			return loading.value || panoramaLoading.value;
		});

		const experimentalPano = computed(() => {
			return Boolean(props.data.useExperimentalRenderer);
			// return isFlagEnabled(FeatureFlags.ExperimentalPanorama);
		});
		
		// const reverseSources = computed(() => {
		// 	return props.data.reverseMobileSources && Platform.isMobile();
		// });

		const activeSources = computed(() => {
			if(!activeItem.value){ return []; }
			if(Platform.isMobile() && activeItem.value.mobileSources && activeItem.value.mobileSources.length){
				return activeItem.value.mobileSources;
			}
			return activeItem.value.sources || [];
		});

		const canGoBack = computed(() => {

			const ai = items.value.findIndex(item => item.id === activeItemId.value);
			if(ai === 0){
				return props.showBack;
			}

			if(props.data.navPerItem || (props.data.feedback && props.data.feedback.perItem)){
				return ai > 0;
			}
			return props.showBack;
		});

		const itemStates = computed(() => {
			const fb = data.feedback;

			if(!fb){ return []; }

			if(!fb.perItem) { return []; }

			const ai = items.value.findIndex(item => item.id === activeItemId.value);
			return items.value.map((item, i) => {

				const key = `${dataNamespace}/${item.id}`;
				let saved = cache.value[key] !== undefined;
				let optional = Boolean(fb.optional);

				if(saved){
					return {
						locked:false,
						icon:"mdi.check",
						variant:"success",
					}
				}

				if(optional){
					return {
						locked:false,
						icon:null,
						variant:"black",
						borderVariant:"light",
						borderWidth:2
					}
				}

				let prevSaved = true;

				if(i > 0){
					const prevKey = `${dataNamespace}/${data.items[i - 1].id}`;
					prevSaved = cache.value[prevKey] !== undefined;
				}

				if(prevSaved){
					return {
						locked:false,
						icon:null,
						variant:"black",
						borderVariant:"light",
						borderWidth:2
					}
				}
				return {
					locked:true,
					icon:"mdi.lock",
					variant:"light"
				};
			});
		});

		const validateNext = computed(() => {
			const i = items.value.findIndex(it => it.id === activeItemId.value);
			if(i === items.value.length - 1){
				return true;
			}

			if(props.data.feedback && !props.data.feedback.perItem){
				if(props.data.navPerItem){
					return false;
				}
			}
			return true;
		});

		const selectItem = (id:string) => {
			const i = data.items.findIndex(item => item.id === id);
			const state = i > -1 && i < itemStates.value.length ? itemStates.value[i] : null;
			if(state && state.locked){
				return;
			}
			activeItemId.value = id;
		};


		const onPrevious = () => {
	
			const ai = items.value.findIndex(item => item.id === activeItemId.value);

			if(ai === 0 && props.showBack){
				context.emit("back");
				return;
			}

			if((props.data.navPerItem || props.data.feedback) && ai > 0){
				activeItemId.value = items.value[ai - 1].id;
				return;
			}

			if(props.showBack && !props.data.navPerItem && !props.data.feedback){
				context.emit("back");
				return;
			}

			// if(ai > 0 && props.showBack && !props.data.feedback && props.data.navPerItem){
			// 	context.emit("back");
			// }
		};

		const onNext = async () => {
			const i = items.value.findIndex(it => it.id === activeItemId.value);
			// on last, always end
			if(i === items.value.length - 1){
				context.emit("end");
				return;
			}
			// nav per item, not on last
			if(props.data.navPerItem || (props.data.feedback && props.data.feedback.perItem)){
				activeItemId.value = items.value[i + 1].id;
				return;
			}
			// default case, always end
			context.emit("end");
		};

		const saveData = async(channels:DataChannel[]) => {
			let success = true;
			const unsaved = channels.filter(ch => {
				const cached = cache.value[`${ch.key}.saved`];
				if(cached === undefined){ return true; }
				if(cached === ch.data){ return false; }
				return true;
			});

			if(unsaved.length === 0){ return true; }
	
			loading.value = true;
			let error = true;
			try {
				const result = await SessionAPI.saveDataChannels(sessionId.value, unsaved);
				for(var i = 0; i < result.length; i++){
					const success = result[i];
					const c = unsaved[i];
					if(success){
						cache.value[`${c.key}.saved`] = c.data;
						if(sessionId.value){
							SessionAPI.cacheSessionValue(sessionId.value, `${c.key}.saved`, c.data);
							SessionAPI.cacheSessionValue(sessionId.value, c.key, c.data);
						}
					}
				}
				success = result.findIndex(s => !s) < 0;
			}
			catch(err){
				console.error(err);
				error = false;
				success = false;
			}
			loading.value = false;
			return success;
		};

		const currentScale = computed(() => {
			if(!data.feedback){ return null; }
			if(!activeItemId.value){ return null; }
			if(data.feedback.perItem){
				return {
					key:`${dataNamespace}/${activeItemId.value}`
				};
			}
			return {
				key:dataNamespace,
			};
		});

		const refreshProgress = () => {
			if(!activeItemId.value){ return; }
			if(data.feedback && data.feedback.perItem){
				setProgressStep(`${dataNamespace}/${activeItemId.value}`);
				return;
			}
			setProgressStep(dataNamespace);
		};

		const saveValue = (key:string, data:any) => {
			saveData([
				{
					key,
					data,
				}
			]);
		};

		const init = () => {
			const sc = SessionAPI.getSessionCache(sessionId.value);
			if(sc){
				Object.keys(sc).forEach(k => context.root.$store.commit("cache/setValue", { k, v:sc[k] }));
			}

			const v = cache.value[`${dataNamespace}.id`];
			if(v){ activeItemId.value = v; }
			else {
				if(items.value.length > 0){
					activeItemId.value = items.value[0].id;
				}
			}
			refreshProgress();
		};

		// cache page index
		watch(activeItemId, i => {
			cache.value[`${dataNamespace}.id`] = i;
			SessionAPI.cacheSessionValue(sessionId.value, `${dataNamespace}.id`, i);
			refreshProgress();
		});

		init();

		return {
			activeItemId,
			activeItem,
			items,
			panoramaLoading,
			loading,
			feedback,
			feedbackVal,
			onPrevious,
			onNext,
			currentScale,
			cache,
			menuOpen,
			itemStates,
			selectItem,
			saveValue,
			responseLoading,
			canGoBack,
			activeSources,
			onLast,
			accelerometerEnabled,
			isMobile,
			validateNext,
			experimentalPano,
		};
	}
});

