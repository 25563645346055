import { defineComponent } from "@vue/composition-api";
import { default as Router } from "vue-router";
import { store } from "@/store";

const LogoutPage = defineComponent({
	setup(_, context){
		const logout = async () => {
			await clearAuth();
			Redirect(context.root.$router)
		}
		logout();
		return {};
	},
	render(){
		return <div/>;
	}
});

const clearAuth = async () => await store.dispatch("clearAuth");

const Redirect = (r:Router) => r.push("login");

export default LogoutPage;