import { defineComponent, computed, ref } from "@vue/composition-api";
import * as StudyAPI from "@/services/api/studies";
import { useTags } from "@/hooks/useTags";
import { StudyList, IStudyList } from "@components/studies";
import { AssetList, AssetGrid } from "@components/assets";
import { ContextOption } from "@/components.generic/context-menu";
import * as AppModal from "@/AppModal";
import { ContextHeader } from "@components/nav";
import { useTranslate } from "@lang";
import {
	Container,
	Divider,
	Heading,
	AbsBox,
	Icon,
} from "@ui";
import { TagInfo } from "@components/tags";
import { generateGUID } from "@utils/guid";
import { default as Router } from "vue-router";
import { PageFavouriteButton } from "@components/favourites";
import { AssetTypes } from "@api/assets";

enum Translations {
	Blueprints = "assets.label.studyBlueprints",
	Elements = "assets.label.studyElements",
	Studies = "studies.label.studies",
	RenameTag = "tags.action.renameTag",
	SetColor = "action.setColor",
	Rename = "action.rename",
	EditColor = "action.editColor",
	CreateStudy = "studies.action.createStudy",
}

enum ActionIcons {
	Rename = "pencil-box",
	EditColor = "palette",
	CreateStudy = "rocket mdi-rotate-45",
}

enum RouteNames {
	StudyDashboard = "Run.Group",
}

const openStudyDashboard = (id:string, r:Router) => {
	r.push({
		name:RouteNames.StudyDashboard,
		params:{
			groupId:id,
		}
	});
};

export default defineComponent({
	setup(_, context){

		const studyList = ref<IStudyList>();
		const tagHooks = useTags();
		const route = context.root.$route;
		const router = context.root.$router;
		const tag = computed(() => tagHooks.getCachedTag(route.params["tagId"]));
		const { translate } = useTranslate();
		const refreshKey = ref<string>(generateGUID());
		const studyRefreshKey = ref<string>(generateGUID());

		const editName = () => {
			const t = tag.value;
			if(!t){ return; }
			AppModal.editText({
				value:t.name,
				title:translate(Translations.RenameTag),
				validator:v => t.name !== v,
			}, async (v) => tagHooks.editTag(t._id, { name:v }));
		};

		const editColor = () => {
			const t = tag.value;
			if(!t){ return; }
			AppModal.editColor({
				value:t.color,
				title:translate(Translations.SetColor)
			}, async(v) => tagHooks.editTag(t._id, { color:v }));
		};

		const createStudy = (blueprint:string) => {
			AppModal.createStudy({
				blueprint,
			}, async (r) => {
				const { name, study, version, language, openDashboard, auth } = r;
				const s = await StudyAPI.createStudy({ name, blueprint:`${study}@${version}`, language, auth });
				refreshStudies();
				studyList.value?.setSort("created");
				if(openDashboard){ openStudyDashboard(s._id, router); }
			});
		};

		const openContext = (e:Event) => {
			AppModal.context(e, [
				{
					name:translate(Translations.Rename),
					icon:ActionIcons.Rename,
					fn:editName,
				},
				{
					name:translate(Translations.EditColor),
					icon:ActionIcons.EditColor,
					fn:editColor,
				}
			])
		};

		const getContextOptions = (a:{ _id:string }):ContextOption[] => {
			return [
				{
					name:translate(Translations.CreateStudy),
					icon:ActionIcons.CreateStudy,
					fn: () => createStudy(a._id),
					order:-1,
				}
			];
		};

		const refreshStudies = () => studyRefreshKey.value = generateGUID();

		const onStudiesRefreshed = () => refreshKey.value = generateGUID();
		
		const texts = computed(() => ({
			blueprints:translate(Translations.Blueprints),
			elements:translate(Translations.Elements),
			studies:translate(Translations.Studies),
		}));

		return {
			tag,
			studyList,
			refreshKey,
			studyRefreshKey,
			texts,
			editName,
			translate,
			editColor,
			getContextOptions,
			openContext,
			onStudiesRefreshed,
		};
	},
	render(){

		if(!this.tag){ return <div/> }

		const sections = [
			(
				<AssetGrid
				include={[ AssetTypes.Blueprint ]}
				showAdd
				taggingEnabled={false}
				defaultTag={ this.tag._id }
				contextFn={ this.getContextOptions }
				title={ this.texts.blueprints }
				/>
			),
			(
				<AssetList
				exclude={[ AssetTypes.Blueprint ]}
				taggingEnabled={false}
				defaultTag={this.tag._id}
				title={ this.texts.elements }
				/>
			),
			(
				<span>
					<Heading size={4}> { this.texts.studies } </Heading>
					<Divider/>
					<StudyList
					v-on:refreshed={ this.onStudiesRefreshed }
					refreshKey={ this.studyRefreshKey }
					ref="studyList"
					tag={ this.tag._id }
					/>
				</span>

			)
		];

		const dsections = sections.map((s,si) => <Container class="mb-5" key={ si.toString() }>{s}</Container>)

		return (
			<AbsBox class="pt-3" style="overflow:auto">
				<ContextHeader>
					<AbsBox class="d-flex px-3">

						<div class="my-auto mr-2">
							<Icon
							name="tag"
							shadow
							style={ `
							color:${this.tag.color};
							font-size:2em;
							` }
							/>
						</div>
					
						<div class="my-auto">
							<TagHeading
							name={ this.tag.name }
							color={ this.tag.color }
							v-on:ctx={ this.openContext }
							/>
							<TagInfo tag={this.tag} refreshKey={this.refreshKey}/>
						</div>

						<div class="mx-auto"/>
						
						<PageFavouriteButton class="my-auto"/>
					
					</AbsBox>
				</ContextHeader>
				{dsections}
			</AbsBox>
		);
	}
});

const TagHeading = defineComponent({
	emits:[ "ctx" ],
	props: [  "name", "color" ],
	components:{ Icon },
	template: `
	<h4 class="font-weight-light text-light d-flex m-0">
		<span> {{ name }} </span>
		<button
		class="btn btn-sm btn-outline-secondary shadow-none border-0 noglow text-light my-auto"
		@click="$emit('ctx', $event)"
		>
			<Icon name="chevron-down"/>
		</button>
	</h4>
`
});