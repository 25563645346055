

type QueryInfo = {
	[k:string]:(string[]|string|undefined);
};

type UrlInfo = {
	host:string;
	location:string;
	query:QueryInfo;
}

export const getUrlInfo = (url:string):UrlInfo|null => {
	try {
		const location = url.split(/[?#]/)[0];
		const u = new URL(url);
		return {
			location,
			host:u.hostname,
			query:getUrlQuery(url)
		}
	}
	catch{}
	return null;
};

const getUrlQuery = (url:string):QueryInfo => {
	const x = url.split(/[?#]/);

	if(x.length < 1){ return {}; }

	const params = x[1].split("&");

	const r:any = {};
	
	params.forEach(p => {

		const [ k,  v ] = p.split("=");
		
		if(!r[k]){
			r[k] = [];
		}
		r[k].push(v);
	});

	Object.keys(r).forEach(k => {
		if(r[k].length === 1){ r[k] = r[k][0]; }
	});

	return r;
};