






















// vendor
import { defineComponent, computed, inject, ComputedRef } from "@vue/composition-api";
import { format as formatDate } from "date-fns";
// project
import { StudyWidgetGrid } from "@/components.studies/study-widget-grid";
import { StudySessionCalendar } from "@/components.studies/study-session-calendar";
import { StudyContext } from "../StudyContext";
import { default as StudyDescription } from "./study-description.vue"
import { default as StudyAuth } from "./study-auth.vue"

import { Divider } from "@ui";
import { useTranslate } from "@lang";

export default defineComponent({
	components:{
		StudyWidgetGrid,
		StudySessionCalendar,
		StudyDescription,
		Divider,
		StudyAuth,
	},
	setup(_, context){
		const studyId = computed(() => {
			return context.root.$route.params["groupId"];
		});

		const studyContext = inject<ComputedRef<StudyContext>>("studyContext");

		if(!studyContext){ throw Error("Study Dashboard: Study context missing!"); }
		
		const {
			study,
		} = studyContext.value;

		const { translate } = useTranslate();

		const dateLabel = computed(() => {
			if(!study.value){ return ""; }
			return formatDate(new Date(study.value.created), "EEEE MMM. d, y");
		});

		return {
			study,
			studyId,
			dateLabel,
			translate,
		};
	}
});

