










































































// vendor
import { defineComponent, ref, computed, watch, onMounted } from "@vue/composition-api";
// project
import { mdiIcons } from "@/assets/icons";
// local
import { FindIconConfig, FindIconHandler } from "./IFindIcon";

export default defineComponent({

	setup(props){

		const value = ref("");
		const fn = ref<FindIconHandler|null>(null);
		const show = ref(false);
		const oldValue = ref("");
		const modal = ref(null);
		const hovered = ref<string>();

		const searchString = ref("");


		const displayIcons = computed(() => {
			if(searchString.value.length < 3){
				return mdiIcons;
			}
			return mdiIcons.filter(icon => {
				const s = searchString.value.toLowerCase();
				return icon.includes(s);
			});

		});
		
		const ok = () => {
			if(fn.value){ fn.value(value.value); }
			(modal.value as any).hide();
		};

		const hide = () => {
			fn.value = null;
			show.value = false;
			value.value = "";
			oldValue.value = "";
			searchString.value = "";
		};

		const open = (config:FindIconConfig, f:FindIconHandler) => {
			fn.value = f;
			searchString.value = "";
			show.value = true;
			value.value = config.value || "";
			oldValue.value = config.value || "";

			setTimeout(() => {
				scrollToCurrent();
			}, 100);
		};

		const selectIcon = (name:string) => {
			value.value = name;
		};

		const scrollToCurrent = () => {
			const container = document.getElementById("IconWrapper");
			const el = document.getElementById(`Icon_${value.value}`);
			if(!container || !el){ return; }
			var topPos = el.offsetTop;
			container.scrollTop = topPos - (el.clientHeight + 60);
		};

		return {
			value,
			fn,
			show,
			oldValue,
			displayIcons,
			modal,
			searchString,
			hovered,
			ok,
			hide,
			open,
			selectIcon
		};
	}
});
