import * as THREE from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

const getHierarchyRecursive = (ob:THREE.Object3D, result:THREE.Object3D[]) => {
	result.push(ob as THREE.Mesh);
	ob.children.forEach(c => {
		getHierarchyRecursive(c, result);
	});
};

export const getHierarchy = (ob:THREE.Object3D) => {
	const result:THREE.Object3D[] = [];
	getHierarchyRecursive(ob, result);
	return result;
};

export const loadGLTF = (path:string, onProgress?:(v:number) => void):Promise<THREE.Group> => {
	return new Promise<THREE.Group>((resolve, reject) => {
		const l = new GLTFLoader();
		l.load(path, (gltf) => {
			resolve(gltf.scene);
		}, progress => {
			if(onProgress){
				onProgress(progress.loaded);
			}
		}, error => {
			reject(error);
		});
	});
}

export const toggleShadows = (ob:THREE.Object3D) => {
	ob.traverse(n => {
		if(!(n instanceof THREE.Mesh)){ return; }
		n.castShadow = true; 
		n.receiveShadow = true;
		if(n.material.map){
			n.material.map.anisotropy = 32; 
		}
	});
}


type ToneKey = "None"|"Linear"|"Reinhard"|"Cineon"|"ACESFilmic";

const toneMappingOptions:{ [k:string]:THREE.ToneMapping } = {
	"None": THREE.NoToneMapping,
	"Linear": THREE.LinearToneMapping,
	"Reinhard": THREE.ReinhardToneMapping,
	"Cineon": THREE.CineonToneMapping,
	"ACESFilmic": THREE.ACESFilmicToneMapping
};

export const getToneMappingOption = (k:ToneKey):THREE.ToneMapping => {
	return toneMappingOptions[k];
}