


















































































































































// vendor
import { defineComponent, computed, PropType, set, del, ref } from "@vue/composition-api";
import { Form, IFormTheme } from "@psychlab/types/form";
import { useTranslate } from "@lang";
import { AbsBox } from "@ui";
import { default as PlusButton } from "./plus-btn.vue";

import { default as ProfileList } from "./profile-list.vue";
import { default as EditTheme } from "./edit-theme.vue";
import { useAddTheme } from "./useAddTheme";
import { generateGUID } from "@utils/guid";
import { editText } from "@/AppModal";


const copyInputEl = (input:HTMLInputElement) => {
	input.select();
	input.setSelectionRange(0, 99999); /* For mobile devices */
	document.execCommand("copy");
};

const encodeTheme = (theme:IFormTheme) => {
	const d = {
		type:"form-theme",
		theme
	}
	return btoa(JSON.stringify(d));
};

const decodeTheme = (s:string):IFormTheme|null => {
	try {
		const d = JSON.parse(atob(s));
		if(d.type === "form-theme" && d.theme){
			return d.theme;
		}
	}
	catch{}
	return null;
};


export default defineComponent({

	emits:[ "select-theme" ],
	props:{
		data:{
			type:Object as PropType<Form>,
			required:true
		},
		activeTheme:{
			type:String,
			default:"",
		},
	},
	components:{
		AbsBox,
		PlusButton,
		ProfileList,
		EditTheme,
	},
	setup(props, ctx){

		const { add:addProfile } = useAddTheme();

		const copyInput = ref<HTMLInputElement>();
		

		const copy = (s:string) => {
			if(!copyInput.value){ return; }
			copyInput.value.value = s;
			copyInputEl(copyInput.value);
		};


		const profile = computed(() => {
			const profiles = props.data.themeProfiles || [];
			return profiles.find(p => p.id === props.activeTheme);
		});

		const { translate } = useTranslate();

		const setDefault = () => {
			set(props.data, "defaultThemeProfile", props.activeTheme);
		};

		const deleteProfile = () => {
			if(!props.data.themeProfiles){ return; }
			const i = props.data.themeProfiles.findIndex(p => p.id === props.activeTheme);
			if(i < 0){ return; }
			const p = props.data.themeProfiles[i];

			props.data.themeProfiles.splice(i, 1);

			if(p.id === props.data.defaultThemeProfile){
				del(props.data, "defaultThemeProfile");
			}
		};

		const duplicateProfile = () => {

			if(!props.data.themeProfiles) { return; }
			if(!profile.value){ return; }

			const c = JSON.parse(JSON.stringify(profile.value));
			c.id = generateGUID();
			c.label = `${c.label} (2)`
			props.data.themeProfiles.push(c);
			ctx.emit("select-theme", c.id);
			
		};
		

		const addTheme = (e:Event) => {
			addProfile(e, p => {

				let profiles = props.data.themeProfiles;

				if(!profiles){
					set(props.data, "themeProfiles", [ p ])
				}
				else {
					profiles.push(p);
				}

				// set(props.data, "defaultThemeProfile", p.id);
				ctx.emit("select-theme", p.id);

			});
		};

		const renameProfile = () => {

			if(!props.data.themeProfiles) { return; }
			if(!profile.value){ return; }

			const p = profile.value;
		
			editText({
				value:p.label,
				variant:"light",
				title:translate("forms.label.profileName"),
				confirmText:translate("prompts.action.confirm"),
				validator(v){
					return v.length > 0; 
				}
			}, (v) => {
				set(p, "label", v);
			})
		};


		const appendTheme = (theme:IFormTheme) => {
			if(!props.data.themeProfiles) { return; }
			if(!profile.value){ return; }
			const p = profile.value;
			Object.keys(theme)
			.forEach(k => {
				set(p.theme, k, (theme as any)[k])
			});
		}


		const copyTheme = () => {
			if(!props.data.themeProfiles) { return; }
			if(!profile.value){ return; }
			copy(encodeTheme(profile.value.theme));
		};

		const pasteTheme = async () => {

			if(!props.data.themeProfiles) { return; }
			if(!profile.value){ return; }

			try {
				const text = await navigator.clipboard.readText();
				const d = decodeTheme(text);
				if(!d){ return; }
				appendTheme(decodeTheme(text) || {});
			}
			catch{}
		};

		return {
			profile,
			copyInput,
			translate,
			setDefault,
			deleteProfile,
			addTheme,
			duplicateProfile,
			renameProfile,
			copyTheme,
			pasteTheme,
		};
	}
});

