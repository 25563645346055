import { defineComponent, ref, computed } from "@vue/composition-api";
import OptionsList from "../vue/widgets/options-list.vue";
import { useTranslate } from "@lang";

export const ImportModal = defineComponent({
	components: {
		OptionsList
	},
	setup(){
		const modal = ref<Modal>();
		const onDone = ref<DoneFn>();
		const show = ref(false);
		const options = ref<string[]>([]);
		
		const canSubmit = computed(() => true);

		const {
			translate,
		} = useTranslate();

		const getOptionsPlaceholder = () => "https://example.com/my-model.(glb,gltf)";

		const ok = () => {
			if(onDone.value){
				onDone.value({
					paths:options.value.length > 0 ? options.value.join("|") : null
				});
			}
			
			if(modal.value){
				modal.value.hide();
			}
		};

		const hide = () => {
			onDone.value = undefined;
			show.value = false;
			options.value = [];
		};

		const open = (c:ImportData, fn:DoneFn) => {
			onDone.value = fn;
			show.value = true;
			options.value = c.paths ? c.paths.split("|") : [];
		};

		return {
			modal,
			onDone,
			show,
			options,
			canSubmit,
			translate,
			getOptionsPlaceholder,
			ok,
			hide,
			open
		};
	}
});

type ImportData = {
	paths:string|null
};

type DoneFn = (r:ImportData) => void;

type Modal = {
	hide():void
};
