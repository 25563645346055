








import { defineComponent, computed, PropType } from "@vue/composition-api";
import { ColorVariant } from "../bootstrap/ColorVariant";

export default defineComponent({
	props:{
		progress:{
			type:Number,
			default:0,
		},
		v:{
			type:String as PropType<ColorVariant>,
			default:"primary",
		},
	},
	setup(props){
		const max = computed(() => props.progress < 1 ? 1 : 100);
		return {
			max
		};
	}
});

