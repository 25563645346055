import { defineComponent, computed } from "@vue/composition-api";
import { getBuildInfo } from "@config";

const info = {
	title:"psychlab",
};

export const ProductName = defineComponent({
	render(){
		return (
			<span
			class="text-decoration-none"
			style="font-family:'NATS'"
			>
				{info.title}
			</span>
		)
	}
});

export const ProductVersion = defineComponent({
	setup(){

		const buildInfo = getBuildInfo();

		const tag = computed(() => buildInfo.TAG || "");

		const displayVersion = computed(() => {
			const [
				tId,
				tMisc
			] = tag.value.split("-");
			if(!tId){ return "-"; }
			return `v${tId}${ tMisc ? '-dev' : ''}`;
		});

		return {
			displayVersion,
		};
	},
	render(){
		return (
			<small style="font-family:'NATS'">
				{this.displayVersion}
			</small>
		);
	}
});