import { defineComponent, computed, ref, PropType, watch, SetupContext } from "@vue/composition-api";
import { Asset } from "@/psychlab/types";
import { getAssetTypes } from "@/psychlab/meta";
import { pascalToSentenceCase } from "@/utils/text";
import { useAssetListDisplay } from "@/hooks/useAssetListDisplay";
import { useAssets } from "@/hooks/useAssets";
import { useUserPrefs } from "@/hooks/useUserPrefs";
import { useTags } from "@/hooks/useTags";
import * as AppModal from "@/AppModal";
import {
	Divider,
	Pagination,
	Date as DateLabel,
	AbsBox,
	RouteButton,
	ContextOption,
	Heading,
	TipIcon,
	Icon,
	ContextButton,
} from "@ui";
import { CardGrid } from "@components/misc";
import { Tag } from "@psychlab/types/tags";
import { useTranslate } from "@lang";

const getAssetRoute = (aid:string, q?:any) => {
	const query:any = { ...(q || {}) };
	return {
		name:'view-asset', 
		params:{
			assetId:aid,
		},
		query
	}
};

const AssetTypes:any = {};
getAssetTypes().forEach(t => AssetTypes[t.name] = t);

const typeColors:any = {
	"Form":"#9471fe",
	"Graph":"#117a8b",
};

const sortFn:Record<string, ((a:Asset, b:Asset) => number)|undefined> = {
	"name":(b, a) => {
		return b.name.localeCompare(a.name);
	},
	"lastModified":(b, a) => {
		return (new Date(a.lastModified || "")).getTime() - (new Date(b.lastModified || "")).getTime()
	}
};

const createAsset = (type:string, context:SetupContext) => {
	const assetHooks = useAssets();
	const tagHooks = useTags();
	AppModal.createAsset({ type }, async (r) => {
		const { name, tags, openEditor } = r;
		const a = await assetHooks.createAsset(type, name);
		await Promise.all(tags.map(t => tagHooks.tagItem(t, a._id, "asset")));
		if(openEditor){
			context.root.$router.push({
				name:"asset.edit",
				params:{
					assetId:a._id,
				}
			})
		}
	});
};

export const AssetGrid = defineComponent({
	props:{
		taggingEnabled:{
			type:Boolean,
			default:true
		},
		showContext:{
			type:Boolean,
			default:true
		},
		showAdd:{
			type:Boolean,
			default:true
		},
		defaultTag:{
			type:String,
		},
		exclude:{
			type:Array as PropType<string[]>,
			default:() => []
		},
		include:{
			type:Array as PropType<string[]>,
			default:() => []
		},
		contextFn:{
			type:Function as PropType<(a:Asset) => ContextOption[]>,
			default:() => [],
		},
		searchFilter:{
			type:String,
			default:"",
		},
		title:{
			type:String,
			default:"",
		},
		titleTip:{
			type:String,
			default:"",
		},
	},
	setup(props, context){
		const sortProperty = ref(getQueryValue("ag_sort", context, "lastModified"));

		watch(sortProperty, (v) => {
			setQueryValue("ag_sort", v, context);
		});

		const { hasTag } = useTags();

		const assetHooks = useAssets();

		const activeTag = computed(() => props.defaultTag || null);

		const {
			pagination,
			openAssetContext,
			getAssetTags,
		} = useAssetListDisplay({
			activeTag,
			context,
			queryPrefix:"ag",
			contextFn:props.contextFn,
		});

		const { assetGridLimit } = useUserPrefs();

		pagination.limit = assetGridLimit.value;

		watch(() => pagination.limit, () => assetGridLimit.value = pagination.limit);

		const assets = computed(() => {
			const tagged = assetHooks.assets.value.filter(a => (props.defaultTag ? hasTag(props.defaultTag, a._id) : true));
			return tagged.filter(a => displayTypes.value.includes(a.type))
		});

		const displayTypes = computed(() => {
			if(props.include.length > 0){
				return props.include;
			}
			return Object.keys(AssetTypes).filter(t => !props.exclude.includes(t));
		});

		const filterAsset = (a:Asset) => {
			if(!props.searchFilter || props.searchFilter.length < 2){ return true; }
			return a.name.toLowerCase().includes(props.searchFilter.toLowerCase());
		};

		const sortedAssets = computed(() => {
			const cp = assets.value.filter(filterAsset);
			const fn = sortFn[sortProperty.value];
			if(fn){
				cp.sort(fn);
			}
			return cp;
		});
		
		const displayAssets = computed(() => {
			const i = (pagination.page - 1) * pagination.limit;
			let e = i + pagination.limit;
			if(e >= pagination.total){
				e = pagination.total;
			}
			return sortedAssets.value.slice(i, e);
		});

		watch(() => sortedAssets.value.length, v => {
			pagination.total = v;
		})

		pagination.total = sortedAssets.value.length;

		const addColor = computed(() => {
			if(displayTypes.value.length === 1){
				return getTypeColor(displayTypes.value[0]);
			}
			return "white";
		});

		const addType = (type:string) => createAsset(type, context);

		const addAsset = (e:any) => {
			if(!displayTypes.value.length){ return; }

			if(displayTypes.value.length === 1){
				addType(displayTypes.value[0]);
				return;
			}
			const options:any[] = displayTypes.value.map(t => {
				return {
					name:pascalToSentenceCase(t),
					icon:getTypeIcon(t),
					fn: ()=> {
						addType(t);
					}
				}
			});
			AppModal.context(e, options);
		};

		const getTypeIcon = (type:string) => AssetTypes[type].icon || "mdi.circle-outline";

		const getTypeColor = (type:string) => typeColors[type] || "white";

		return {
			addColor,
			displayAssets,
			pagination,
			sortedAssets,
			sortProperty,
			addAsset,
			getAssetTags,
			openAssetContext,
		};
	},
	render(){


		const titleArea = this.title ? (
			<div>
				<div class="d-flex">
					<Heading size={4}>
						{ this.title }
						<TipIcon tip={ this.titleTip }/>
					</Heading>
				</div>
				<Divider/>
			</div>
		) : <span/>

		const acards = this.displayAssets.map(a => (
			<AssetCard
			key={a._id}
			asset={a}
			tags={this.getAssetTags(a._id)}
			v-on:context={ (e:Event) => this.openAssetContext(e, a._id) }
			/>
		));

		return (
			<div>
				{titleArea}

				<div class="d-flex py-1">
					<div class="mx-auto"/>
					<Pagination
					variant="dark"
					size="md"
					v-on:change-page={ (p:number) => this.pagination.page = p }
					v-on:change-limit={ (l:number) => this.pagination.limit = l }
					page={ this.pagination.page }
					limit={ this.pagination.limit }
					rows={ this.sortedAssets.length }
					class="ml-auto my-auto mr-3 text-light"
					/>
					<Sort v-model={ this.sortProperty }/>
				</div>

				<Divider/>
				
				<CardGrid.Grid>
					{acards}
					<AddButton
					v-on:click={ this.addAsset }
					color={ this.addColor }
					/>
				</CardGrid.Grid>
			</div>
		);
	}
});


const AssetCard = defineComponent({
	emits:[ "context" ],
	props:{
		tags:{
			type:Array as PropType<Tag[]>,
			default:() => [],
		},
		asset:{
			type:Object as PropType<Asset>,
			required:true,
		}
	},
	setup(props, ctx){

		const hovered = ref(false);

		const icon = AssetTypes[props.asset.type].icon || "circle-outline";
		const color = typeColors[props.asset.type];

		const route = computed(() => getAssetRoute(props.asset._id, ctx.root.$route.query))

		return {
			icon,
			color,
			hovered,
			route,
			setHovered:(v:boolean) => hovered.value = v,
		};
	},
	render(){

		const overlay = this.hovered ? <HoverOverlay/> : <span/>;

		return (
			<CardGrid.Card class=" bg-dark shadow"
			v-on:hovered={ () => this.setHovered(true) }
			v-on:unhovered={ () => this.setHovered(false) }
			style="border-radius:15%"
			>

				{overlay}

				<AbsBox class="d-flex">
					<Icon
					name={ this.icon }
					class="m-auto"
					shadow
					glow={ this.hovered }
					style={ `font-size:3em; color:${this.color}` }
					/>
				</AbsBox>

				<AbsBox style="pointer-events:none;z-index:2;" class="d-flex">
					<small
					style="font-size:0.6em;opacity:0.75;"
					class="text-light mt-auto mx-auto mb-2">
						<DateLabel date={ this.asset.lastModified } xshort/>
						{this.hovered}

					</small>
				</AbsBox>

				<RouteButton
				route={ this.route }
				/>

				<AbsBox class="p-2" style="pointer-events:none;">
					<DisplayTags tags={this.tags} style="pointer-events:all"/>
				</AbsBox>

				<AbsBox class="d-flex justify-content-end" style="pointer-events:none">
					<ContextButton
					class="m-2"
					variant={ this.hovered ? "light" : "secondary" }
					v-on:click={ (e:Event) => this.$emit("context", e) }
					/>
				</AbsBox>

				<CardLabel>
					{ this.asset.name }
				</CardLabel>
			</CardGrid.Card>
		)
	}

});

const HoverOverlay = defineComponent({
	render(){
		const s = `
			pointer-events:none;
			background:rgba(255,255,255,0.05);
			border:0.05em solid white;
			border-radius:inherit;
		`;
		return (
			<AbsBox style={s}/>
		);
	}
});

const AddButton = defineComponent({
	emits:[ "click" ],
	props:{
		color:{
			type:String,
			required:false,
		},
	},
	render(){

		const bc = `${this.color ? `color:${this.color}` : ""}`;

		return (
			<CardGrid.Card style="background:transparent;border-radius:15%;">
				<button
				v-on:click={ (e:Event) => this.$emit("click", e) }
				class="btn btn-outline-dark w-100 h-100 shadow-none d-flex"
				style={ `border-radius:inherit !important;${bc}` }
				>
					<Icon
					name="plus-thick"
					class="m-auto"
					style="font-size:3em;"
					shadow
					/>
				</button>
			</CardGrid.Card>
		)
	}
});

const CardLabel = defineComponent({
	render(){
		const style = `font-size:0.8em;
		line-height:normal;
		display:-webkit-box;
		-webkit-box-orient:vertical;
		-webkit-line-clamp:2;
		overflow:hidden;`;

		return (
			<AbsBox style="pointer-events:none">
				<div
				class="w-100 p-2"
				style="position:absolute;top:100%;"
				>
					<p
						style={style}
						class="my-auto text-light text-center">
						{ this.$slots.default }
					</p>
				</div>
			</AbsBox>
		);
	}
});

const DisplayTags = defineComponent({
	props:{
		tags:{
			type:Array as PropType<Tag[]>,
			default:() => [],
		},
	},
	render(){
		const dtags = this.tags.map(t => (
			<div
			key={ t._id }
			style={ `background:${t.color};width:0.5em;height:0.5em;pointer-events:all;margin:0.1em` }
			class="rounded d-inline-block float-left"
			v-b-tooltip={{
				title:t.name,
				interactive:false,
				delay:0,
				animation:false,
			}}
			/>
		))
		return <div>{dtags}</div>;
	}
});

const Sort = defineComponent({
	props:{
		value:{
			type:String,
			default:"name",
		},
	},
	setup(props, context){
		
		const selectedLabel = computed(() => {
			return sortOptions.value.find(o => o.value === props.value)?.text || "-";
		});
		
		const sortOptions = computed(() => {
			return [
				{ value: "name", text: translate("label.name") },
				{ value: "lastModified", text: translate("label.modified") },
			]
		});

		const { translate } = useTranslate();

		const openMenu = (e:Event) => {
			AppModal.context(e, sortOptions.value.map(o => {
				return {
					name:o.text,
					icon:props.value === o.value ? "mdi.check" : "",
					fn(){
						context.emit("input", o.value);
					}
				}
			}))
		};

		return {
			sortOptions,
			selectedLabel,
			openMenu,
		};
	},
	render(){
		return (
			<button
			v-on:click={ this.openMenu }
			class="btn btn-outline-secondary text-light border-0 shadow-none d-flex font-weight-light"
			>
				<Icon name="sort" class="mr-1 my-auto"/>
				<span class="my-auto">
					{ this.selectedLabel }
				</span>
			</button>
		);
	}
});

const setQueryValue = (k:string, v:any, context:SetupContext) => {
	const q:any = { ...context.root.$route.query };
	if(v !== undefined && v !== null){
		q[k] = v;
	}
	else {
		delete q[k];
	}
	context.root.$router.push({ query: q})
	.catch(() => {});
};


const getQueryValue = (k:string, context:SetupContext, def:string):string => {
	return ((context.root.$route.query[k] as any) as string) || def;
};