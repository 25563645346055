import { defineComponent } from "@vue/composition-api";

export class CardGrid {
	static get Grid(){ return Grid; }
	static get Card(){ return Card; }
}

const Grid = defineComponent({
	render(){
		const dummies = Array.from(Array(10).keys())
		.map(() => (
			<DummyCard/>
		));

		return (
			<GridWrapper>
				{ this.$slots.default }
				{dummies}
			</GridWrapper>
		);
	}
});

const Card = defineComponent({
	emits:[ "hovered", "unhovered"],
	props:{
		mb:{
			type:Number,
			default:3,
		},
	},
	render(){
		const size = 8;
		const style = `
		position:relative;
		width:${size}em;height:${size}em;
		margin:0 1rem ${this.mb}rem 0rem;
		xbackground:rgba(255,0,0,0.1)
		`;

		return (
			<div style={style}
			v-on:mouseenter={ () => this.$emit("hovered") }
			v-on:mouseleave={ () => this.$emit("unhovered") }
			>
				{ this.$slots.default }
			</div>
		)
	}
});


const GridWrapper = defineComponent({
	render(){
		return (
			<div style={ gridStyle }>
				{ this.$slots.default }
			</div>
		)
	}
});

const DummyCard = defineComponent({
	render(){
		return (
			<Card style="height:0px !important;margin-bottom:0 !important;"/>
		)
	}
});

const gridStyle = `
	display:flex;
	flex-flow: row wrap;
	justify-content: space-between;
	padding-left:1rem;
`;