import { PhraseDictionary } from "../types";
import { mergeDictionaries } from "../mergeDictionaries";

const label:PhraseDictionary  = {};

const action:PhraseDictionary  = {
	"logOut":{
		en:"Log Out",
		is:"Skrá Út"
	},
	"openTutorial":{
		en:"Open Tutorial",
		is:"Opna leiðbeiningar"
	},
};

const message:PhraseDictionary  = {};

export const nav = mergeDictionaries("nav", { label, action, message });