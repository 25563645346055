






import { defineComponent, computed, ref, PropType, onMounted, watch, onUnmounted } from "@vue/composition-api";
import { Gallery } from "@/psychlab/types/gallery";
import { useStaticRender } from "../ts/hooks/useStaticRender";
import { processUrls } from "../ts/utils/processUrls";


export default defineComponent({

	props:{
		gallery:{
			type:Object as PropType<Gallery>,
			required:true,
		},
		sources:{
			type:Array as PropType<string[]>,
			required:true
		},
	},
	setup(props, context){

		const viewRoot = ref<HTMLElement|null>();
		const mounted = ref(false);

		const {
			fadeInDuration,
			fadeOutDuration,
		} = props.gallery;

		const staticRender = useStaticRender({
			// todo: settings
			fadeInDuration,
			fadeOutDuration,
		});

		const sources = computed(() => processUrls(props.sources));

		const {
			loading,
		} = staticRender;

		const loadCurrent = async() => {
			context.emit("load-start");
			try {
				await staticRender.loadSources(sources.value);
			}
			catch(err){}
			context.emit("load-end");
		};

		onMounted(() => {
			mounted.value = true;
			if(!viewRoot.value){ return; }
			staticRender.init(viewRoot.value);
			loadCurrent();
		});

		onUnmounted(() => {
			staticRender.dispose();
		});

		watch(() => props.sources, v => {
			if(!mounted){ return; }
			loadCurrent();
		});

		return {
			viewRoot,
			loading,
		};
	}
});

