import { Ref, ref } from "@vue/composition-api";
import * as StudyAPI from "@/services/api/studies";
import { Study } from "@/psychlab/types/studies";

type Dictionary<T> = { [k:string]:T };

let loaded = false;

let _studies:Ref<Study[]>;


let _loadingStatus:Dictionary<boolean> = {};



export const useStudies = () => {

	if(!loaded){

		_studies = ref<Study[]>([]);
		loaded = true;
	}


	const loadStudy = async(id:string) => {

		if(_loadingStatus[id]){ return; }

		_loadingStatus[id] = true;

		try {
			const s = await StudyAPI.getStudy(id);
			_studies.value.push(s);
		}
		catch(err){}
		_loadingStatus[id] = false;
	}


	const getCachedStudy = (id:string) => {
		
		const s = _studies.value.find(st => st._id === id);

		if(!s){
			loadStudy(id);
		}
		return s;


	};



	return {
		getCachedStudy
	};

};