import { IAssetProvider } from "./interface";
import { AssetProvider } from "./providers";

export class Provide {

	static get assets():IAssetProvider{
		return Provide.getInstance()._assets;
	}

	private constructor(){
		this._assets = new AssetProvider();
	}

	private static getInstance(){
		if(!Provide._instance){
			Provide._instance = new Provide();
		}
		return Provide._instance;
	}
	
	private static _instance:Provide|null = null;

	private _assets:IAssetProvider;

}