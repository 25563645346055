
















// vendor
import { defineComponent, PropType } from "@vue/composition-api";
import { Study } from "@/psychlab/types";

import { default as StudyTitle } from "./study-title.vue";
import { default as ShareLink } from "./share-link.vue";
import { default as OpenToggle } from "./open-toggle.vue";
import { PageFavouriteButton } from "@components/favourites";

export default defineComponent({
	props:{
		study:{
			type:Object as PropType<Study>,
			required:true,
		}
	},
	components:{
		StudyTitle,
		ShareLink,
		OpenToggle,
		PageFavouriteButton,
	},
	setup(){

		return {

		};
	}
});

