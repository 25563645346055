import * as THREE from "three";
import { KeyboardInput } from "./input/KeyboardInput";
import { MouseInput} from "./input/MouseInput";
import { Time } from "../App";
import * as NumUtils from "./Number";

const ROTATION_MULTIPLIER = 20;
const MOVE_MAGNITUDE = 20;

export class FlightControls {

	constructor(target:THREE.Object3D, el:EventTarget){
		this._target = target;
		this._mouse = new MouseInput(el);
		this._keys = new KeyboardInput();
		this._root = new THREE.Object3D();
		this._root2 = new THREE.Object3D();
		this._root.add(this._root2);
		this._root2.add(this._target);
	}

	public setPosition(pos:{ x:number, y:number, z:number }){
		this._root.position.set(pos.x, pos.y, pos.z);
	}

	public setRotationX(v:number){
		const rot = this._root2.rotation;
		rot.x = v * THREE.MathUtils.DEG2RAD;
		this._root2.rotation.setFromVector3(new THREE.Vector3(rot.x, rot.y, rot.z));
	}

	public setRotationY(v:number){
		const rot = this._root.rotation;
		rot.y = v * THREE.MathUtils.DEG2RAD;
		this._root.rotation.setFromVector3(new THREE.Vector3(rot.x, rot.y, rot.z));
	}

	public update(){
		const mDelta = this.getMovement();
		const rDelta = this.getRotation();
		this._root.rotateY(rDelta.y * THREE.MathUtils.DEG2RAD);
		this._root2.rotateX(rDelta.x * THREE.MathUtils.DEG2RAD);
		this._root2.rotation.x = NumUtils.clamp(this._root2.rotation.x * THREE.MathUtils.RAD2DEG, -89, 89) * THREE.MathUtils.DEG2RAD;
	
		if(this._mouse.mouseDown(0)){
			this.applyVector(new THREE.Vector3(1,0,0), mDelta.x);
			this.applyVector(new THREE.Vector3(0,1,0), mDelta.y);
			this.applyVector(new THREE.Vector3(0,0,1), mDelta.z);
		}

		this._target.updateWorldMatrix(true,true);
	}

	public dispose(){
		this._mouse.dispose();
		this._keys.dispose();
	}

	private _root:THREE.Object3D;
	private _root2:THREE.Object3D;
	private _target:THREE.Object3D;
	private _mouse:MouseInput;
	private _keys:KeyboardInput;
	
	applyVector (v:THREE.Vector3, s:number){
		const q = new THREE.Quaternion();
		this._root2.getWorldQuaternion(q);
		v.applyQuaternion(q);
		v.normalize();
		v.multiplyScalar(s);
		this._root.position.add(v);
	}

	getRotation(){
		const rDelta = { x:0, y:0 }
		if(this._mouse.mouseDown(0)){
			rDelta.y += ROTATION_MULTIPLIER * Time.deltaTime * this._mouse.mouseDeltaX * -1;
			rDelta.x += ROTATION_MULTIPLIER * Time.deltaTime * this._mouse.mouseDeltaY * -1;
		}
		return rDelta;
	}

	getMovement(){
		const mDelta = { x:0, y:0, z:0 };
		if(this._keys.getKey("w")){ mDelta.z -= MOVE_MAGNITUDE * Time.deltaTime; }
		if(this._keys.getKey("s")){ mDelta.z += MOVE_MAGNITUDE * Time.deltaTime; }
		if(this._keys.getKey("a")){ mDelta.x -= MOVE_MAGNITUDE * Time.deltaTime; }
		if(this._keys.getKey("d")){ mDelta.x += MOVE_MAGNITUDE * Time.deltaTime; }
		if(this._keys.getKey("q")){ mDelta.y -= MOVE_MAGNITUDE * Time.deltaTime; }
		if(this._keys.getKey("e")){ mDelta.y += MOVE_MAGNITUDE * Time.deltaTime; }
		return mDelta;
	}
}