/** @format */

// vendor
import { defineComponent, ref, computed } from "@vue/composition-api";
// project
import { Widget } from "@/psychlab/types/widget";
import * as StudyAPI from "@/services/api/studies";
import { CreateWizard, ICreateWizard } from "@/components.studies/study-widget-wizard";
import DisplayWidget from "../vue/display-widget.vue";
import GridToolbar from "../vue/grid-toolbar.vue";

import { useTranslate } from "@lang";
import { Divider } from "@ui";


export const WidgetGrid = defineComponent({
	props: {
		studyId: {
			type: String,
			required: true,
		},
	},
	components: {
		DisplayWidget,
		GridToolbar,
		CreateWizard,
		Divider,
	},
	setup(props) {


		const { translate } = useTranslate();

		const displayWidgets = ref<any[]>([]);
		const widgets = ref<Widget[]>([]);
		const createWizard = ref<ICreateWizard>();

		const editing = ref<boolean>(false);

		const loading = ref(false);

		const sortedWidgets = computed(() => {
			return widgets.value.sort((a, b) => Date.parse(b.modified) - Date.parse(a.modified));
		});

		const loadData = async () => {
			loading.value = true;
			try {
				const w =  await StudyAPI.getWidgets(props.studyId);
				widgets.value = w;
			} catch(err){
				console.error(err);
			}
			loading.value = false;
		};

		const onWidgetRefreshed = (id:string, data:any) => {
		};

		const insertWidget = (w:Widget) => {
			widgets.value.push(w);
			setTimeout(() => {
				refreshWidget(w._id);
			},1);
		};


		const refreshWidget = (id:string) => {
			const w = displayWidgets.value.find(dw => dw.widget._id === id);
			if(!w){ return; }
			w.refresh();
		};

		const refreshWidgets = () => {
			displayWidgets.value.forEach(w => w.refresh() );
		};

		const onWidgetDeleted = (id:string) => {
			const i = widgets.value.findIndex(w => w._id === id);
			if(i < 0) { return; }
			widgets.value.splice(i, 1);
		};

		const addWidget = async() => {

			if(!createWizard.value){ return; }

			const dataChannels = await StudyAPI.getStudyDataChannels(props.studyId);

			createWizard.value.open({
				dataChannels
			}, async (result) => {
				
				const w = await StudyAPI.createWidget(props.studyId, {
					name:result.name,
					computeType:result.dataType,
					computeArgs:result.dataArgs,
					template:result.template,
					templateBindings:result.templateBindings,
					templateArgs:result.templateArgs,
					metadata:result.metadata
				});
				insertWidget(w);
			});
		};

		loadData();

		return {
			editing,
			displayWidgets,
			sortedWidgets,
			createWizard,
			loading,
			onWidgetDeleted,
			onWidgetRefreshed,
			refreshWidgets,
			addWidget,
			translate,
		};
	},
});