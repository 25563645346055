






















// vendor
import { defineComponent, computed, ref } from "@vue/composition-api";
import { openContextMenu, ContextOption } from "@ui";
import { useTranslate } from "@lang";

import { default as IconButton } from "./ico-btn.vue";

const shuffleOptions = [
	{ icon:"mdi.shuffle-disabled", label: "forms.label.shuffleOff", value: null, order:0, },
	{ icon:"mdi.shuffle", label: "forms.action.shuffle", value: "shuffle", order:1, },
	{ icon:"mdi.numeric-1-box", label: "forms.action.selectOne", value: "select", order:1, }
];

export default defineComponent({

	emits:[ "input" ],
	props:{
		value:{
			type:String,
			required:false
		}
	},
	components:{
		IconButton,
	},
	setup(props, ctx){


		const { translate } = useTranslate();


		const icon = computed(() => {
			return shuffleOptions.find(o => o.value === props.value)?.icon || "mdi.shuffle-disabled";
		})

		
		const open = (e:Event) => {
			const opts:ContextOption[] = shuffleOptions.map(o => {

				const checked = props.value === o.value;
				const icon = checked ? 'mdi.check' : undefined;

				return {
					name:translate(o.label),
					icon,
					order:o.order,
					fn(){
						ctx.emit("input", o.value)
					}
				}
			})
			openContextMenu(e, opts);
		};

		

		return {
			icon,
			translate,
			open,
		};
	}
});

