import { Graph, GraphNode } from "@/psychlab/types/graph";
import { Form, LinearScale } from "@/psychlab/types/form";
import { Gallery } from "@/psychlab/types/gallery";

type ProgressConfig = {
	graph:Graph;
	path:string[];
}

type FormNode = GraphNode<Form>;

type GalleryNode = GraphNode<Gallery>;

type SceneNode = GraphNode<{
	points:{
		guid:string;
		form:Form|null
	}[];
}>;

export const getProgressPath = (config:ProgressConfig) => {

	let steps:string[] = [];

	const map:Record<string,(id:string, n:GraphNode<any>) => string[]> = {
		"Form":getFormNodeSteps,
		"Scene":getSceneNodeSteps,
		"Gallery":getGalleryNodeSteps,
	};

	forEachNode(config.path, config.graph, (nid, node) => {
		const fn = map[node.type];
		steps = steps.concat(fn ? fn(nid, node) : [ nid ]);
	});
	return steps;

};

const forEachNode = (nodePath:string[], g:Graph, fn:(nid:string, n:GraphNode<any>) => void) => {
	nodePath.forEach(nid => fn(nid, g.nodes[nid]));
};

const getFormNodeSteps = (id:string, n:FormNode):string[] => {

	const steps:string[] = [];

	(n.parameters.sectionOrder || n.parameters.sections.map(s => s.id))
	.forEach(s => {
		steps.push(`${id}/${s}`)
	})
	return steps;
};


const getGalleryNodeSteps = (id:string, n:GalleryNode):string[] => {
	const g = n.parameters;
	if(g.feedback && g.feedback.perItem){
		return g.items.map(item => `${id}/${item.id}`);
	}
	return [ id ];
};

const getSceneNodeSteps = (id:string, n:SceneNode):string[] => {
	
	const steps:string[] = [];

	n.parameters.points
	.forEach(point => {
		const form = point.form;

		if(!form){
			steps.push(`${id}/${point.guid}`);
			return;
		}

		(form.sectionOrder || form.sections.map(s => s.id))
		.forEach(sid => {
			
			const s = form.sections.find(fs => fs.id === sid);

			if(!s){ return; }

			(s.itemOrder || s.items.map(it => it.id))
			.map(iid => {
				const i = s.items.findIndex(it => it.id === iid);
				return s.items[i];
			})
			.forEach(item => {
				if(item.type === "LinearScale"){					
					const ls = item as LinearScale;
					ls.settings.rows.forEach((row, ri) => {
						steps.push(`${id}/${point.guid}/${item.id}/${ri}`);
					});

				}
				else {
					steps.push(`${id}/${point.guid}/${item.id}`);
				}
			});
		});
	});
	return steps;
};