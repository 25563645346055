







// vendor
import { defineComponent, computed, ref } from "@vue/composition-api";
// 
import { Toggle } from "@/components.generic/scriptable-form"

export default defineComponent({
	props:{
		value: {
			type:Boolean,
			default:false,
		},
		config:{
			type:Object,
			default:() => {},
		}
	},
	components:{
		Toggle,
	},
	setup(props, context){

		const val = computed({
			get:() => {
				return props.value;
			},
			set:(v) => {
				context.emit("input", v);
			},
		});

		return {
			val,
		};
	}
});

