

export const videoToCanvas = async(vid:HTMLVideoElement, scale:number = 1):Promise<HTMLCanvasElement> => {
	const canvas = document.createElement("canvas");
	const ctx = canvas.getContext("2d");
	if(!ctx){ throw Error("Canvas context not supported...for some dumb reason.") }
	const iw = vid.videoWidth * scale;
	const ih = vid.videoHeight * scale;
	canvas.width = iw;
	canvas.height = ih;
	ctx.drawImage(vid, 0, 0, iw, ih);
	return canvas;
};

export const imageToCanvas = async(img:HTMLImageElement, scale:number = 1):Promise<HTMLCanvasElement> => {
	const canvas = document.createElement("canvas");
	const ctx = canvas.getContext("2d");
	if(!ctx){ throw Error("Canvas context not supported...for some dumb reason.") }
	const iw = img.naturalWidth * scale;
	const ih = img.naturalHeight * scale;
	canvas.width = iw;
	canvas.height = ih;
	ctx.drawImage(img, 0, 0, iw, ih);
	return canvas;
};