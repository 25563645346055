import axios from "axios";
import { AxiosRequestConfig } from "axios";
import { store } from "@/store";
import { getConfig } from "@config";

const config:AxiosRequestConfig = {
	baseURL:getConfig().apiURL,
}

const http = axios.create(config);

// adds authorization token to request
export const addAuthToRequest = (config:AxiosRequestConfig) => {
	if(!config.headers["Authorization"]){
		let token = store.getters.token;
		if(token){
			config.headers["Authorization"] = `bearer ${token}`;
		}
	}
	return config;
};

http.interceptors.request.use(addAuthToRequest, error => Promise.reject(error)); // apply auth

export { http }