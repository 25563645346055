import { defineComponent } from "@vue/composition-api";
import { AbsBox } from "@ui";

export const Gradient = defineComponent({
	render(){
		const style = "background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);";
		return (
			<AbsBox style={style}/>
		)
	}
});
