





// vendor
import { defineComponent, computed } from "@vue/composition-api";
// project
import { ListStudyTasks } from "@/components.studies/list-study-tasks";

export default defineComponent({
	components:{
		ListStudyTasks
	},
	setup(_, context){
		const studyId = computed(() => context.root.$route.params["groupId"]);
		return {
			studyId
		};
	}
});

