









// vendor
import { defineComponent, computed, ref, PropType } from "@vue/composition-api";

export default defineComponent({

	emits:[ "input" ],
	props:{
		value:{
			type:Boolean,
			default:false,
		},
		type:{
			type:String as PropType<"h"|"v">,
			default:"drag"
		}
	},
	setup(props, ctx){
		
		const val = computed({
			get:() => props.value,
			set:v => ctx.emit("input", v)
		});

		const icon = computed(() => {
			return ({
				"h":"mdi.drag-horizontal",
				"v":"mdi.drag-vertical",
			} as any)[props.type] || "mdi.drag";
		});

		return {
			val,
			icon
		};
	}
});

