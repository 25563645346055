import { useTranslate } from "@lang";
import { AbsBox, Icon } from "@ui";
import { defineComponent, PropType, computed } from "@vue/composition-api";
import { getConfig } from "@config";

import { default as Router } from "vue-router";


enum RouteNames {
	AuthCallback = "verify-survey-auth",
}

// island.is -> redirect -> backend -> callback
const config = {
	logo:"https://innskraning.island.is/Images/sitelogo.png",
	loginURL:"https://innskraning.island.is/?id=envalys&path=$PAYLOAD",
	redirectURL: "/redirect/$PAYLOAD", // island.is will send response here first
	backendURL:"$API_URL/auth/studies?study=$STUDY&cb=$CALLBACK", // backend validation,
};

export const AuthIcelandLink = defineComponent({

	props:{
		study:{
			type:String,
			required:true,
		}
	},
	setup(props, ctx){

		const router = computed(() => ctx.root.$router);

		const loginURL = computed(() => getLoginURL(props.study, router.value));

		return {
			loginURL
		};
	},
	render(){

		const btncls = "btn btn-lg btn-light";
		return (
			<a
			v-on:click={(e:Event) => this.$emit("click", e)}
			class={ `${btncls} btn-light mx-auto border border-dark d-flex shadow-none border-0` }
			style="font-size:1.5em"
			href={ this.loginURL }
			>
				<img src={ config.logo } style="width:5em;margin-bottom:.1em;" class="my-auto"/>
				<Icon name="login-variant"/>

			</a>
		)
	},
});

const getLoginURL = (study:string, r:Router) => {
	const payload = {
		target:getBackendURL(study, r),
	};
	return insertParams(config.loginURL, {
		"PAYLOAD":getRedirectURL(payload)
	});
};

const getBackendURL = (study:string, r:Router) => {
	return insertParams(config.backendURL, {
		"API_URL":getConfig().apiURL,
		"STUDY":study,
		"CALLBACK":encodeURIComponent(getCallbackURL(study,r)),
	});
};

const getCallbackURL = (study:string, r:Router) => {
	const rp = resolveRoute(r, { name:RouteNames.AuthCallback, params:{
		studyId:study,
	}});
	return `${getHost()}${rp}`
};

const getRedirectURL = (pl:any) => {
	const s = encodeURIComponent(Buffer.from(JSON.stringify(pl)).toString("base64"));
	return encodeURIComponent(`/redirect/${s}`);
};

const getHost = () =>  `${location.protocol}//${location.host}`;

const insertParams = (path:string, params:Record<string,any>, prefix:String = "$") => {
	Object.keys(params).forEach(k => path = path.replace(`${prefix}${k}`, params[k]));
	return path;
};

const resolveRoute = (router:Router, route:{ name:string, params:Record<string,string> }) => {
	return router.resolve(route).href;
};