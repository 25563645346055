import { HTTPEndpoint, IGet, IDelete } from "@http";
import { EntityHandler } from "@api";
import { IStudy, IDataChannel, IStudyHandler } from "../interface";

export class StudyHandler extends EntityHandler<IStudy> implements IStudyHandler {

	get name():string{
		return this.entity.name;
	}

	get open():boolean{
		return this.entity.enabled;
	}

	constructor(s:IStudy){
		super(s);
		this._delete = HTTPEndpoint.get(`studies/${s._id};`)
		this._channels = HTTPEndpoint.get(`studies/${s._id}/channels`);
		this._blueprint = HTTPEndpoint.get(`studies/${s._id}/blueprint`);
	}

	async setLanguage(v:string):Promise<void> {
		throw new Error("Method not implemented.");
	}

	async setProgressVisible(v:boolean):Promise<void> {
		throw new Error("Method not implemented.");
	}

	async setOpen(v:boolean):Promise<void> {
		throw new Error("Method not implemented.");
	}

	async delete():Promise<void> {
		await this._delete.send({});
	}

	async readBlueprint():Promise<any> {
		await this._blueprint.send({});
	}

	async readDataChannels():Promise<IDataChannel[]> {
		return await this._channels.send({})
	}

	private _delete:IDelete<{}>;
	private _channels:IGet<{}, IDataChannel[]>;
	private _blueprint:IGet<{}, IDataChannel[]>;

}