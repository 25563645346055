















// vendor
	
import { defineComponent, computed, ref } from "@vue/composition-api";
import { generateGUID } from "@utils/guid";
// import {} from 

import { default as VEdge } from "./vedge.vue";



export default defineComponent({

	emits:[],
	props:{
		cols:{
			type:Number,
			default:0,
		},
		first:{
			type:Boolean,
			default:false,
		},
		last:{
			type:Boolean,
			default:false,
		}
	},
	components:{
		VEdge,
	},
	setup(props){
		
		const width = computed(() => {
			
			if(props.first || props.last){
				return 50;
			}
			return 100;
			
			// if(props.cols < 2){ return 0; }
			// const ew = 100 / props.cols;
			// let w = ew * props.cols;

			// w -= ew;

			// return w;

			// return 50;
		});


		return {
			width,
			generateGUID,
		};
	}
});

