






























































































// vendor
import { defineComponent, ref, computed, watch, PropType } from "@vue/composition-api";
import { format } from "date-fns";
// project
import { ContextMenu, IContextMenu, ContextOption } from "@/components.generic/context-menu";


type PresetOption = {
	name:string;
	value:number|(() => number);
}


type RangeConfig = {
	presets?:PresetOption[];
};

export default defineComponent({
	emits:[
		"input",
	],
	props: {
		value: {
			type: String,
			default:""
		},
		size:{
			type:String,
			default:"sm",
		},
		config:{
			type:Object as PropType<RangeConfig>,
			default: () => {  }
		}
	},
	components:{
		ContextMenu,
	},
	setup(props, context) {

		const contextMenu = ref<IContextMenu>();

		const presetOptions = computed(() => {
			return props.config && props.config.presets ? props.config.presets : [];
		});

		const from = computed({
			get:() => {
				if(!props.value){
					return "";
				}
				return props.value.split(",")[0];
			},
			set:(v) => {
				
				if(v !== undefined && v.length === 0){
					context.emit("input", undefined);
					return;
				}
				emitValue(v, to.value);
			},
		});

		const to = computed({
			get:() => {
				if(!props.value){
					return "";
				}
				return props.value.split(",")[1];
			},
			set:(v) => {
				if(v !== undefined && v.length === 0){
					context.emit("input", undefined);
					return;
				}
				
				emitValue(from.value, v);
			},
		});

		const emitValue = (f:string, t:string) => {
			context.emit("input", f + "," + t);;
		};

		const selectPresent = (e:Event, fn:(v:string) => void) => {
			if(!contextMenu.value){ return; }

			const options:ContextOption[] = [];

			presetOptions.value.forEach(o => {
				options.push({
					name:o.name,
					icon:"mdi.calendar",
					fn(){
						const ts = typeof(o.value) === "function" ? o.value() : o.value;
						fn(format(new Date(ts), "y-MM-dd"));
					}
				});
			});

			contextMenu.value.open(e, options);
		}

		const selectPresetFrom = (e:Event) => {
			selectPresent(e, v => from.value = v);
		};

		const selectPresetTo = (e:Event) => {
			selectPresent(e, v => to.value = v);
		};


		return {
			from,
			to,
			contextMenu,
			presetOptions,
			selectPresetFrom,
			selectPresetTo,
		};
	},
});
