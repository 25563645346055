import { http } from "@/http";
import { Study, StudyAuthMode } from "@/psychlab/types";

type Props = {
	name?:string;
	blueprint:string;
	language?:string;
	auth?:string;
}

export const createStudy = async(config:Props):Promise<Study> => {
	const { data } = await http.post("studies", config);
	return data;
};