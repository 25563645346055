// vendor
import { TweenLite } from "gsap";

type TickCallback = (t:number) => void;

export const tweenTime = (duration:number, onTick:TickCallback) => {
	const start = Date.now();
	return new Promise<void>((resolve) => {
		const tick = () => {
			const t = clamp01((Date.now() - start) / duration);
			onTick(t);
			if(t >= 1){
				resolve();
			}
			else {
				requestAnimationFrame(tick);
			}
		};
		tick();
	});
};

export const tweenTarget = async (target:any, key:string, duration:number, value:number):Promise<void> => {
	return new Promise((resolve) => {
		const config:any = {
			duration,
			onComplete(){
				resolve();
			},
		};
		config[key] = value;
		TweenLite.to(target, config);
	});
};

const clamp01 = (v:number) => {
	if(v < 0){ return 0; }
	if(v > 1){ return 1; }
	return v;
};