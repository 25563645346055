











































































































// vendor
import { defineComponent, computed, ref } from "@vue/composition-api";
// project
import { Asset, AssetVersion } from "@/psychlab/types/assets";
import * as AssetAPI from "@/services/api/assets";
import { formatDateStringRecent } from "@/utils/text";
import { getAssetTypes } from "@/psychlab/meta";
import { useAssets } from "@/hooks/useAssets";
import { useTags } from "@/hooks/useTags";
// local
import { default as AssetListItem } from "./asset-list-item.vue";
import { useTranslate } from "@lang";

export default defineComponent({

	emits:[
		"input"
	],
	props:{
		value:{
			type:String,
		},
		tag:{
			type:String
		},
		type:{
			type:String,
			required:true,
		},
		disabled:{
			type:Boolean,
			default:false,
		},
	},
	components:{
		AssetListItem,
	},
	setup(props, context){


		const filterTag = ref<string|null|undefined>(props.tag);
		const filterString = ref<string>("");

		const {
			translate,
		} = useTranslate();

		const {
			assets
		} = useAssets();

		const {
			hasTag,
			sortedTags,
		} = useTags();

		const displayAssets = computed(() => {

			
			let r = assets.value.filter(a => a.type === props.type);
			// const t = props.tag;
			const t = filterTag.value;
			

			if(t){ r = r.filter(a => hasTag(t, a._id)); }

			if(filterString.value.length > 2){
				r = r.filter(a => a.name.toLowerCase().includes(filterString.value.toLowerCase().trim()))
			}


			r.sort((a, b) => (new Date(b.lastModified || "").getTime() - (new Date(a.lastModified || "").getTime())));

			return r;
		});

		const activeTag = computed(() => {
			return sortedTags.value.find(t => t._id === filterTag.value);
		});

		const tagOptions = computed(() => {

			const options:any[] = [
				{
					text:translate("label.everything"),
					value:null,
					color:"white"
				}
			];

			sortedTags.value.forEach(t => {

				options.push({
					value:t._id,
					text:t.name,
					color:t.color,
				});

			})


			return options;
		});

		const typeIcon = computed(() => {
			const tem = getAssetTypes().find(t => t.name === props.type);
			return tem ? tem.icon : "";
		});

		return {
			displayAssets,
			typeIcon,
			formatDateStringRecent,
			translate,
			filterTag,
			filterString,
			tagOptions,
			activeTag,
		};
	}
});

