var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data && _vm.config)?_c('AbsBox',[_c('EditGraph',{ref:"editorViewport",attrs:{"graph":_vm.data,"config":_vm.config,"canOpenFn":_vm.canOpenExternal},on:{"editParams":function($event){},"openNode":_vm.openNodeExternal,"modified":_vm.onModified,"refresh":_vm.onRefreshViewport},scopedSlots:_vm._u([{key:"node-inspector",fn:function(ref){
var id = ref.id;
var node = ref.node;
var data = ref.data;
var parameters = ref.parameters;
var template = ref.template;
return [_c('NodeInspector',{staticClass:"inspector-wrapper",staticStyle:{"overflow":"auto"},attrs:{"nodeId":id,"nodeName":data.name,"parameters":parameters,"template":template},on:{"open-node":_vm.openNode,"name-changed":function($event){return _vm.setNodeName(data, $event)}}})]}},{key:"node-status-area",fn:function(ref){
var nodeId = ref.nodeId;
return [_c('NodeStatusButtons',{staticClass:"centerabsx",attrs:{"isStart":_vm.startNode === nodeId,"warningCount":0,"actions":_vm.nodeActions[nodeId] || []}})]}},{key:"node-label-area",fn:function(ref){
var nodeId = ref.nodeId;
var nodeData = ref.nodeData;
return [_c('NodeLabel',{attrs:{"text":_vm.getNodeLabel(nodeId)},on:{"rename":function($event){return _vm.renameNode(nodeId)}}})]}}],null,false,1481557941)}),(_vm.currentWindowId)?_c('NodeWindow',{attrs:{"nodeId":_vm.currentWindowId,"node":_vm.currentWindowNode,"type":_vm.currentWindowNode.type,"parameters":_vm.currentWindowNode.parameters},on:{"close":_vm.closeNode}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }