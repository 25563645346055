import { defineComponent, ref } from "@vue/composition-api";
import { CompanySplashScreen } from "@components/misc";

export default defineComponent({
	setup(){

		const show = ref(true);

		setTimeout(() => {
			// show.value = false;
		},3000);

		return {
			show
		};
	},
	render(){

		return (
			<CompanySplashScreen
			v-on:hidden={() => {
				console.log("donesies");
			}}
			show={ this.show }
			/>
		)
	}
});