


type Prop = {
	key:string;
	type:string;
	optional?:boolean;
	noAutoBind?:boolean;
};

export const checkBindingCompatibility = (source:Prop[], target:Prop[]) => {
	let compatible = true;
	
	let count = 0;

	target.forEach(tp => {
		let propSupported = false;
		source.forEach(sp => {
			propSupported = propSupported || (sp.type === tp.type);
		});
		if(!tp.optional){
			compatible = compatible && propSupported;
		}
	});
	const sBound:any = {};
	source.forEach(sp => {
		if(sBound[sp.key] || sp.noAutoBind){
			return;
		}
		target.forEach(tp => {
			if(sp.type === tp.type){
				sBound[sp.key] = true;
				count++;
			}
		});
	});
	

	return { compatible, count };
};