// vendor
import * as THREE from "three";
// local
import { getInverseSphere } from "./getInverseSphere";

export const getFadeSphere = (color:string) => {
	const geometry = getInverseSphere(0.5);
	const material = new THREE.MeshBasicMaterial( { color, opacity:1, transparent:true } );
	const sphere = new THREE.Mesh(geometry, material);
	return sphere;
};