


































// vendor
import { defineComponent, computed, ref, Ref, inject } from "@vue/composition-api";
// project
import { Study } from "@psychlab/types/studies";
import * as StudyAPI from "@/services/api/studies";
// local
import { default as LangSelect } from "./lang-select.vue";
import { default as ToggleProgressVisible } from "./toggle-progress-visible.vue";
import { default as InfoTooltip } from "./info-tooltip.vue";
import { useTranslate } from "@lang";
import { TipIcon } from "@ui";

export default defineComponent({
	components:{
		LangSelect,
		ToggleProgressVisible,
		TipIcon,
	},
	setup(){

		const { translate } = useTranslate();

		const labelCols = ref(3);
		
		const studyRef = inject<Ref<Study>>("study");

		const study = computed(() => {
			return studyRef ? studyRef.value : undefined;
		});

		const studyLanguage = computed<string>({
			get() {
				return study.value ? study.value.target.language : "-";
			},
			set(v) {
				setLanguage(v);
			},
		});

		const showProgress = computed<boolean>({
			get() {

				if(!study.value){ return false; }
				return study.value.target.showProgress !== undefined ? study.value.target.showProgress : true;
			},
			set(v) {
				setProgressVisible(v);
			},
		});

		const setProgressVisible = async (v: boolean) => {
			if (!study.value) {
				return;
			}
			try {
				if (study.value._id) {
					await StudyAPI.setStudyProgressVisible(study.value._id, v);
					study.value.target.showProgress = v;
				}
			} catch (err) {
				console.error(err);
			}
		};

		const setLanguage = async (v: string) => {
		if (!study.value) {
			return;
		}
		try {
			if (study.value._id) {
				await StudyAPI.setStudyLanguage(study.value._id, v);
				study.value.target.language = v;
			}
		} catch (err) {
			console.error(err);
		}
	};
		

		return {
			labelCols,
			study,
			studyLanguage,
			showProgress,
			translate,
		};
	}
});

