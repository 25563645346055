import {defineComponent, ref, PropType, computed, watch } from "@vue/composition-api";
import * as AssetAPI from "@/services/api/assets";
import { getAssetTypes } from "@/psychlab/meta";
import { Asset, AssetPointer, AssetVersion } from "@/psychlab/types";
import { BrowseAssetModal } from "@/components.assets/browse-asset-modal";
import { Icon } from "@ui";
import { BlueprintUpdate } from "./BlueprintUpdate";
import { useAssets } from "@/hooks/useAssets";
import { parseRef } from "./getUpdateReport";
import { default as HoverOverlay } from "./hover-overlay.vue";

const stringifyRef = (v:AssetPointer<any>|null|string) => {
	const ref = parseRef(v);
	if(!ref){ return null; }
	return `${ref.asset}@${ref.version}`;
};

export const FindAsset = defineComponent({
	emits:[ "input" ],
	props:{
		value:null,
		types:{
			type:Array as PropType<string[]>,
			default: () => []
		},
		placeholder:{
			type:String,
			default:"Select Asset..."
		},
		tag:{
			type:String,
			required:false,
		},
	},
	setup(props, context){

		const browseAssetModal = ref<any>("");
		const assetMissing = ref(false);
		const selectedAsset = ref<Asset>();
		const { refreshAsset } = useAssets();
		const versions = ref<AssetVersion<any>[]>([]);

		const label = computed(() => {
			if(assetMissing.value){ return "Asset missing or deleted"; }
			if(selectedAsset.value){
				return selectedAsset.value.name;
			}
			return "";
		});

		const canUpdate = computed(() => {
			return selectedAsset.value && selectedAsset.value.type === "Graph";
		});

		const icon = computed(() => {
			if(selectedAsset.value){
				const type = getAssetTypes().find(t => t.name === (selectedAsset.value as any).type);
				return type ? type.icon : "";
			}
			return "";
		});

		const activeVersion = computed(() => {
			if(!versions.value.length){ return null; }
			const ref = assetRef.value;
			if(!ref){ return null; }
			return versions.value.find(v => v._id === ref.version);
		});

		const usingLatest = computed(() => {
			if(!activeVersion.value){ return false; }
			const max = Math.max(...versions.value.map(v => v.vIndex));
			return max === activeVersion.value.vIndex;
		});

		const assetRef = computed(() => {
			return parseRef(props.value);
		})

		const handleUpdated = (v:AssetPointer<any>) => {
			context.emit("input", stringifyRef(v));
			refreshAsset(v.ref.asset);
		};

		watch(() => props.value, () => refresh(), { deep:true });

		watch(() => props.value, (o, n) => {
			const or = stringifyRef(o);
			const nr = stringifyRef(n);
			if(or === nr){ return; }
			assetMissing.value = false;
		});

		const refresh = async() => {
			if(!assetRef.value){
				selectedAsset.value = undefined;
				return;
			}
			try{
				selectedAsset.value = await AssetAPI.getAsset(assetRef.value.asset);
				versions.value = await AssetAPI.loadVersionList(assetRef.value.asset);
			}catch(err){
				assetMissing.value = true;
			}
		};

		const findAsset = async() => {
			if(!props.types.length){ return; }
			const assetType = props.types[0];
			const value = assetRef.value || { asset:null, version:null }
			const m = browseAssetModal.value;

			m.open({ value, assetType, tag:props.tag }, async(ref:{ asset:string, version:string }) => {
				const rs = stringifyRef({
					ref,
				});
				assetMissing.value = false;
				context.emit("input", rs);
			});
		};

		refresh();

		return {
			usingLatest,
			browseAssetModal,
			assetMissing,
			selectedAsset,
			label,
			icon,
			activeVersion,
			canUpdate,
			handleUpdated,
			findAsset,
		};
	},
	render(){

		const updateBox = this.canUpdate && this.value ? (
			<BlueprintUpdate
			reference={ this.value }
			v-on:updated={ this.handleUpdated }
			/>
		) : <span/>;

		const versionIndex = this.selectedAsset && this.activeVersion && !this.usingLatest ? (
			<span class="px-2 rounded-lg badge badge-dark">
				<small>
					{ this.activeVersion.vIndex + 1 }
				</small>
			</span>
		) : <span/>;
		
		const latestIcon = this.selectedAsset && this.usingLatest ? (
			<span>
				<Icon name="star" class="text-info" style="width:1em;font-size:1em"/>
			</span>
		) : <span/>;

		const missingIcon = this.assetMissing ? (
			<span class="mr-2">
				<Icon name="alert-rhombus" class="text-danger"/>
			</span>
		) : <span/>;


		const assetIcon = this.icon ? (
			<div class="mr-2">
				<Icon name={ this.icon } style="width:1em;font-size:0.75em"/>
			</div>
		) : <span/>;

		return (
			<div>

				<BrowseAssetModal ref="browseAssetModal"/>

				<div style="position:relative;">

					<div class="d-flex form-control">
						
						{ assetIcon }

						{ missingIcon }
						
						<div class="d-flex my-auto">
							<small class="my-auto">
								{ this.label || "..." }
							</small>
						</div>

						<span class="mx-auto"/>

						{ versionIndex }
						{ latestIcon }
					</div>

					<HoverOverlay v-on:click={ this.findAsset }/>
				</div>

				<div class="my-2"/>

				{ updateBox }

			</div>
		)
	}


});