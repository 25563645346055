var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill d-flex flex-column"},[(_vm.title)?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({ title:_vm.title, interactive:false, delay:0, animation:false }),expression:"{ title:title, interactive:false, delay:0, animation:false }",modifiers:{"hover":true}}],staticClass:"flex-fillx bg-infox text-light",staticStyle:{"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}},[_c('small',{staticClass:"font-weight-light text-light m-0 font-nats",staticStyle:{"xfont-size":"0.6rem"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('hr',{staticClass:"my-1 bg-secondary"})]):_vm._e(),_c('div',{staticClass:"flex-fill bg-warningx prel",on:{"mouseleave":function($event){return _vm.onHover(undefined)}}},[_c('div',{staticClass:"abstretch scrollbox thinscroll",staticStyle:{"overflow-y":"auto"}},_vm._l((_vm.items),function(item){return _c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
					title:((item.label) + ": " + (item.value)),
					interactive:false,
					delay:0,
					animation:false,
					placement:'right',
					boundary:'window',
					variant:'light',
					html:true
				}),expression:"{\n\t\t\t\t\ttitle:`${item.label}: ${item.value}`,\n\t\t\t\t\tinteractive:false,\n\t\t\t\t\tdelay:0,\n\t\t\t\t\tanimation:false,\n\t\t\t\t\tplacement:'right',\n\t\t\t\t\tboundary:'window',\n\t\t\t\t\tvariant:'light',\n\t\t\t\t\thtml:true\n\t\t\t\t}",modifiers:{"hover":true}}],staticClass:"d-flex flex-row",staticStyle:{"position":"relative","width":"100%","height":"1.2rem","min-height":"1.2rem","overflow":"hidden"},on:{"mouseenter":function($event){return _vm.onHover(item)}}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"ml-1 mr-1 my-auto roundedx borderx",staticStyle:{"width":"0.5rem","height":"0.5rem"},style:({ background: item.color })})]),_c('div',{staticClass:"flex-fill text-light d-flex",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"bg-warningx d-flex",staticStyle:{"position":"absolute","width":"100%","height":"100%","top":"0","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis","font-size":"0.7rem","xpadding-top":"0.1rem"}},[_c('span',{staticClass:"font-nats my-auto"},[_vm._v(" "+_vm._s(item.label)+" ")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeItem === item.id),expression:"activeItem === item.id"}],staticClass:"bg-light fill pin rounded",staticStyle:{"opacity":"0.1"}})])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }