










import { defineComponent, computed } from "@vue/composition-api";

export default defineComponent({
	props:{
		sessionId:{
			type:String,
			required:true
		},
		backRoute:{
			type:Object,
			required:true
		},
		backLabel:{
			type:String,
			required:true
		}
	},
	setup(props){
		const breadcrumbs = computed(() => {
			return [
				{
					text:props.backLabel,
					to:props.backRoute
				},
				{
					text: `ID: ${props.sessionId}`,
					active: true
				}
			];
		});
		return {
			breadcrumbs
		};

	}
});


