// project
import { http } from "@/http";
import { UserFavourite } from "@/psychlab/types/favourites";

export const getFavourites = async():Promise<UserFavourite[]> => {

	const { data } = await http.get(`favourites`);
	return data;
};

export const addFavourite = async(item:string, type:"asset"|"study"|"tag"):Promise<UserFavourite> => {
	const { data } = await http.post(`favourites?type=${type}&item=${item}`);
	return data;
};

export const removeFavourite = async(item:string):Promise<void> => {
	await http.delete(`favourites?item=${item}`);
};
