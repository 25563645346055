/** @format */

// vendor
import { defineComponent, ref, reactive, computed, provide } from "@vue/composition-api";
// project
import * as StudyAPI from "@/services/api/studies";
import * as AssetAPI from "@/services/api/assets";
import { Asset, Study, AssetVersion } from "@psychlab/types";
import { StudyContext } from "./StudyContext";
import { ContextHeader } from "@components/nav";
import { Studyheader, StudyNavigation } from "./header";
import { AbsBox } from "@ui";

import { Provide, IDataChannel as StudyDataChannel } from "@api/studies";

const studyProvider = Provide.studies;

export type EditState = {
	editing: boolean;
	value: string;
};

export const ManageStudy = defineComponent({
	components: {
		AbsBox,
		StudyNavigation,
		ContextHeader,
		Studyheader,
	},
	setup(_, context) {

		const studyId = computed(() => {
			const r = context.root.$route;
			return r.params["studyId"] || r.params["groupId"];
		});

		const blueprint = ref<Asset>();
		const blueprintVersion = ref<AssetVersion<any>>();
		const study = ref<Study>();

		const channels = ref<StudyDataChannel[]>([]);

		const studyContext = computed<StudyContext>(() => ({
			study,
			channels,
			blueprint,
			blueprintVersion,
		}));

		provide("study", study);
		provide("studyContext", studyContext);

		const init = async () => {
			if(!studyId.value){ return; }
			
			try {

				const handler = await studyProvider.readStudyByID(studyId.value);

				study.value = await StudyAPI.getStudy(studyId.value);

				blueprint.value = await AssetAPI.getAsset(
					study.value.target.study,
				);

				blueprintVersion.value = await AssetAPI.getAssetVersion(
					study.value.target.study,
					study.value.target.version,
					"+data +vIndex +name"
				);

				channels.value = await handler.readDataChannels();
			}
			catch(err:any){
				console.log(err.message);
			}
		};

		init();

		return {
			study,
			studyId,
		};
	},
});
