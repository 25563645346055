// project
import { Graph } from "@/psychlab/types/graph";

export const getLeftMostNode = (data:Graph) => {
	let nks = Object.keys(data.nodes);
	if(nks.length === 0){ return null; }
	let cn = nks[0];
	Object.keys(data.nodes).map((nk) => {
		if(data.nodes[nk].x < data.nodes[cn].x){
			cn = nk;
		}
	});
	return cn;
};