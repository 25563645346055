











import { defineComponent, set } from "@vue/composition-api";
import { EmbedImage } from "@psychlab/types/form";
import { RenderImage } from "@form-ui";
import { getItemProps } from "./getItemProps";

export default defineComponent({
	props:{
		...getItemProps<EmbedImage>()
	},
	components:{
		RenderImage,
	},
	setup(props){

		const setUrl = (url:string) => {
			if(!props.data){ return; } // this is unnecessary, fu vue
			set(props.data.settings, "url", url);
		};

		const setWidth = (w:number) => {
			if(!props.data){ return; } // this is unnecessary, fu vue
			set(props.data.settings, "width", w);
		};

		return {
			setUrl,
			setWidth,
		};
	}
});

