






































// vendor
	
import { defineComponent, computed, ref } from "@vue/composition-api";
import { generateGUID } from "@utils/guid";
// import {} from 

import { default as VEdge } from "./vedge.vue";



export default defineComponent({

	emits:[],
	props:{
		vcount:{
			type:Number,
			default:0,
		},
		height:{
			type:Number,
			default:2,
		},
	},
	components:{
		VEdge,
	},
	setup(props){
		
		
		const edges = computed(() => {
			const edges:any[] = [];

			for(let i = 0; i < props.vcount; i++){
				edges.push({})
			}


			return edges;

		});

		const width = computed(() => {
			if(props.vcount < 2){ return 0; }

			const ew = 100 / props.vcount;

			let w = ew * props.vcount;

			w -= ew;

			return w;
			

			

			// return 50;
		});


		return {
			edges,
			width,
			generateGUID,
		};
	}
});

