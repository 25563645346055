







// vendor
import { defineComponent, computed, ref } from "@vue/composition-api";
import { AbsBox } from "@ui";

export default defineComponent({
	emits:[],
	props:{
	},
	components:{
		AbsBox,
	},
	setup(){
		
		

		return {

		};
	}
});

