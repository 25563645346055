























































































































































import { computed, defineComponent, ref, PropType } from "@vue/composition-api";
import { LabelBadge } from "@ui";
import { CustomCheckbox } from "@/components.generic/custom-checkbox";
import { SessionLabel, Session } from "@/psychlab/types";
import * as StudyAPI from "@/services/api/studies";
// local
import { default as CreateLabel } from "./create-label.vue";

import { useTranslate } from "@lang";


export interface IAssignLabelModal {
	open(c:ModalConfig, fn:DoneFn):void;
};

export type ModalConfig = {
	selection:string[];
};

export type ModalResult = {
	selection:string[];
};

type EditProps = {
	name:string;
	color:string;
};

type Modal = {
	show():void;
};

type OpContext = {
	name:"edit"|"delete";
	target:SessionLabel;
	props?:EditProps;
};

type DoneFn = (r:ModalResult) => void;

export default defineComponent({
	emits: [
		"reload-labels",
	],
	props: {
		labels:{
			type:Array as PropType<SessionLabel[]>,
			default:() => [],
		},
		canEdit:{
			type:Boolean,
			default:true,
		},
		canDelete:{
			type:Boolean,
			default:true,
		},
		canAdd:{
			type:Boolean,
			default:true,
		},
	},
	components: {
		CustomCheckbox,
		CreateLabel,
		LabelBadge,
	},
	setup(props, ctx) {


		const { translate } = useTranslate();



		const modal = ref<Modal|null>(null);
		const selection = ref<string[]>([]);
		const studyId = computed(() => ctx.root.$route.params["groupId"]);
		const currentOp = ref<OpContext|null>(null);
		const loadingOp = ref(false);

		const onDone = ref<DoneFn|null>();

		const isEditing = (lid:string) => {
			return currentOp.value && currentOp.value.target._id === lid;
		};

		const isSelected = (lid:string) => {
			return selection.value.includes(lid);
		};

		const deleteLabel = (lid:string) => {
			const target = props.labels.find(l => l._id === lid);
			if(!target){ return; }
			currentOp.value = {
				name:"delete",
				target,
			}
		};

		const editLabel = (lid:string) => {
			const target = props.labels.find(l => l._id === lid);
			if(!target){ return; }
			currentOp.value = {
				name:"edit",
				target,
				props:{
					name:target.name,
					color:target.color,
				}
			}
		};

		const cancelOp = () => {
			currentOp.value = null;
		};

		const confirmOp = async () => {
			const target = currentOp.value ? currentOp.value.target : null;
			if(!currentOp.value){ return; }
			const props = currentOp.value ? currentOp.value.props : null;
			loadingOp.value = true;

			try {
				if(currentOp.value.name === "edit" && currentOp.value.props){
					await confirmEdit(currentOp.value.target, currentOp.value.props);
				}
				else if(currentOp.value.name === "delete"){
					await confirmDelete(currentOp.value.target);
				}
			}
			catch(err:any){
				console.log(err.message);
			}
			loadingOp.value = false;
			currentOp.value = null;
		}

		const confirmEdit = async (target:SessionLabel, props:EditProps) => {
			await StudyAPI.editSessionLabel(studyId.value, target._id, props);
			target.name = props.name;
			target.color = props.color;
		};

		const confirmDelete = async(target:SessionLabel) => {
			await StudyAPI.deleteStudyLabel(studyId.value, target._id);
			const di = props.labels.findIndex(l => l._id === target._id);
			if(di > -1){
				props.labels.splice(di, 1);
			}
		};

		const onHide = () => {
			selection.value = [];
			currentOp.value = null;
			onDone.value = null;
		};

		const open = (c:ModalConfig, fn:DoneFn) => {
			if(!modal.value){ return; }
			selection.value = [ ...c.selection ];
			onDone.value = fn;
			modal.value.show();
		};

		const onLabelAdded = (l:SessionLabel) => {
			props.labels.push(l);
			selection.value.push(l._id);
		};

		const onOK = async () => {

			if(onDone.value){
				onDone.value({
					selection:selection.value,
				})
			}
		};

		const onAdd = () => {
			cancelOp();
		};

		const toggleSelected = (lid:string) => {
			
			const i = selection.value.findIndex(l => l === lid);

			if(i > -1){
				selection.value.splice(i, 1);
			}
			else {
				selection.value.push(lid);
			}
		};

		return {
			loadingOp,
			confirmOp,
			cancelOp,
			isSelected,
			editLabel,
			deleteLabel,
			open,
			modal,
			selection,
			onOK,
			onLabelAdded,
			onHide,
			isEditing,
			toggleSelected,
			currentOp,
			onAdd,
			translate,
		};
	},
});



