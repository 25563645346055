





























































import { defineComponent, ref, watch, computed } from "@vue/composition-api";
import { insertTab } from "@ui";
import { useTranslate } from "@lang";

type OutputData = {
	args:any;
}

type DoneHandler = (s:OutputData) => void;

type Modal = {
	hide():void
}

export default defineComponent({

	directives:{
		"insert-tab":insertTab as any
	},
	setup(_){

		const { translate } = useTranslate();


		const modal = ref<Modal>();
		const show = ref(false);
		const onDone = ref<DoneHandler>();


		const parseError = ref<string|null>(null);
		const rawArgs = ref("");
		const parsedArgs = ref<any>({});


		const title = computed(() => {
			return `${translate("studies.label.taskArguments")} (${translate("label.optional")})`;
		});

		const ok = () => {
			parsedArgs.value = undefined;
			try {
				const ra = rawArgs.value.trim();
				if(ra.length > 0){
					parsedArgs.value = JSON.parse(rawArgs.value);
					if(typeof(parsedArgs.value) !== "object"){
						throw new Error("Format error: Arguments need to be wrapped in a root object");
					}
				}
			}
			catch(err:any){
				parseError.value = err.message;
				return;
			}

			if(!modal.value){ return; }
			modal.value.hide();
			if(onDone.value){
				onDone.value({ args: parsedArgs.value || {} })
			}
		};

		const hide = () => {
			show.value = false;
			onDone.value = undefined;
			rawArgs.value = "";
			parsedArgs.value = {};
			parseError.value = "";
		};

		const open = async (fn:DoneHandler) => {
			show.value = true;
			onDone.value = fn;
		};

		watch(rawArgs, () => parseError.value = null);

		return {
			title,
			parseError,
			rawArgs,
			modal,
			show,
			ok,
			hide,
			open,
			translate,
		};
	}
	
});
