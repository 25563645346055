




















import { defineComponent, computed, ref, PropType, onMounted } from "@vue/composition-api";
import { capitalize } from "@/utils/text";
import * as Charts from "./charts";

type ChartType = "bar"|"pie"|"line";

export default defineComponent({

	props:{
		title:{
			type:String,
			default:""
		},
		type:{
			type:String as PropType<ChartType>,
			default:"bar"
		},
		ceil:{
			type:Number,
			default:-1
		},
		labels:{
			type:Array as PropType<string[]>,
			default:() => []
		},
		values:{
			type:Array as PropType<number[]>,
			default:() => []
		},
		colors:{
			type:Array as PropType<string[]>,
			default:() => []
		},
		sort: {
			type: String as PropType<"label"|"none"|"value">,
			default: "none",
		},
	},
	setup(props){

		const component = computed(() => {
			return (Charts as any)[capitalize(props.type)];
		});

		return {
			component
		};
	}
});

