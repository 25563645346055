

































































































// vendor
import {
	defineComponent,
	computed,
	ref,
	PropType,
} from "@vue/composition-api";
// project

type DisplayItem = {
	id: string;
	value: number;
	label: string;
	color: string;
};

export default defineComponent({
	emits: ["hovered"],
	props: {
		title: {
			type: String,
			default: "",
		},
		items: {
			type: Array as PropType<DisplayItem[]>,
			default: () => [],
		},
		activeItem: {
			type: String,
		},
	},
	setup(props, context) {
		const onHover = (item?: DisplayItem) => {
			context.emit("hovered", item ? item.id : undefined);
		};

		return {
			onHover,
		};
	},
});
