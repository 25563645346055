












import { defineComponent, computed } from "@vue/composition-api";
import { PreviewAssetForm } from "@/components.assets/preview-asset-form";
import { PreviewAssetGraph } from "@/components.assets/preview-asset-graph";
import { PreviewAssetGallery } from "@/components.assets/preview-asset-gallery";
import { AbsBox } from "@ui";
import { default as FallbackPreview } from "./fallback-preview.vue";

export default defineComponent({
	props:{
		type:{
			type:String,
			required:true
		},
		data:{
			type:Object,
			required:true,
		},
		version:{
			type:String,
			required:true,
		}
	},
	components:{
		AbsBox,
	},
	setup(props){

		const typeComponent = computed(() => {
			return ({
				"Form":PreviewAssetForm,
				"Graph":PreviewAssetGraph,
				"Gallery":PreviewAssetGallery,
			} as any)[props.type] || FallbackPreview;;
		});

		return {
			typeComponent,
		};
	}
});

