





















































































































































// vendor
import { defineComponent, computed, ref, watch, PropType } from "@vue/composition-api";
import { CustomCheckboxState } from "@/components.generic/custom-checkbox";
import { LabelBadge } from "@ui";
import { deleteSessionLabel } from "@/services/api/studies";
import { Session, SessionLabel } from "@/psychlab/types";
import { useStudyRouteParams } from "../hooks/useStudyRouteParams";
import * as Utils from "../utils";
import { default as ProgressBar } from "./progress-bar.vue";
import { AssignLabelModal } from "../labels";
import { useTranslate } from "@lang";
import { Date as DisplayDate, Duration } from "@ui";


const getColumns = (showOpt:boolean) => {
	return [
		{ label: "ID", id: "_id", nonSortable: true },
		{ label: "Request IP", id: "ip", nonSortable: true },
		{ label: "Started", id: "created" },
		{ label: "Completed", id: "completed" },
		{ label: "Duration", id: "duration" },
		{ label: "Progress", id: "progress" },
		...(showOpt ? [ { label: "Opt. Progress", id: "optionalProgress" } ] : []),
		{ label: "Labels", id: "labels", nonSortable: true },
	];
};

const getProgressVariant = (s:Session) => {
	switch(Utils.computeSessionRunState(s)){
		case "running": return "warning";
		case "done": return "success";
		case "expired": return "danger";
	}
};

export default defineComponent({
	emits: [
		"change-sort",
		"row-click",
		"reload",
		"sessions-selected",
	],
	props: {
		sessions: {
			type: Array as PropType<Session[]>,
			required: true,
		},
		sortKey: {
			type: String,
			required: true,
		},
		sortAsc: {
			type: Boolean,
			required: true,
		},
		labels:{
			type: Array as PropType<SessionLabel[]>,
			default:() => [],
		},
		batchEnabled: {
			type: Boolean,
			default:false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		showOptionalProgress: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		AssignLabelModal,
		LabelBadge,
		ProgressBar,
		DisplayDate,
		Duration,
	},
	setup(props, ctx) {

		const { studyId } = useStudyRouteParams(ctx);

		const { translate } = useTranslate();

		const showCheckboxes = computed(() => props.batchEnabled);
		const checked = ref<string[]>([]);

		const columns = computed(() => {
			const so = props.showOptionalProgress;
			const cols = [
				{ label: "ID", id: "_id", nonSortable: true },
				{ label: translate("label.requestIp"), id: "ip", nonSortable: true },
				{ label: translate("label.started"), id: "created" },
				{ label: translate("label.completed"), id: "completed" },
				{ label: translate("label.duration"), id: "duration" },
				{ label: translate("label.progress"), id: "progress" },
				...(so ? [ { label: translate("label.optionalProgress"), id: "optionalProgress" } ] : []),
				{ label: translate("studies.label.labels"), id: "labels", nonSortable: true },
			];
			return cols;
		});

		const selectedAllLocal = ref<CustomCheckboxState>(
			CustomCheckboxState.FALSE,
		);
		const selectedSessionsLocal = ref<string[]>([]);

		const changeSort = (sortKey: string) => {
			const sort = `${props.sortAsc ? "" : "-"}${sortKey}`;
			Utils.setQueryValue("sort", sort, ctx);
			ctx.emit("change-sort", sortKey);
		};

		const onRowClick = (sid: string) => {
			if(!props.batchEnabled){
				ctx.emit("row-click", sid);
				return;
			}
			toggleSessionSelected(sid);
		};

		const toggleSessionSelected = (sid:string) => {
			const i = selectedSessionsLocal.value.findIndex(s => s === sid);
			if(i > -1){
				selectedSessionsLocal.value.splice(i, 1);
			}
			else {
				selectedSessionsLocal.value.push(sid);
			}
		};

		const reload = () => {
			ctx.emit("reload");
		};

		const unassignLabel = async (sessionId:string, labelId:string) => {
			await deleteSessionLabel(studyId.value, sessionId, [labelId]);
			reload();
		};

		const handleSelectedAllChanged = () => {
			if (selectedAllLocal.value === CustomCheckboxState.TRUE) {
				selectedSessionsLocal.value = [];
			} else {
				selectedSessionsLocal.value = props.sessions.map(
					(i) => i._id,
				);
			}
		};

		const handleCheckboxInput = (checked:string[]) => {
			selectedAllLocal.value = {
				[0]:CustomCheckboxState.FALSE,
				[props.sessions.length]:CustomCheckboxState.TRUE
			}[checked.length] || CustomCheckboxState.INTERMEDIATE;
		};

		const getLabels = (ids:string[]) => {
			const lbs = ids.map(id => props.labels.find(l => l._id === id)).filter(id => id !== undefined) as SessionLabel[];
			lbs.sort((a, b) => a.name.localeCompare(b.name));
			return lbs;
		};
		
		watch(selectedSessionsLocal, (nextSelection) => {
			ctx.emit("sessions-selected", nextSelection);
		});

		return {
			columns,
			showCheckboxes,
			checked,
			CustomCheckboxState,
			selectedSessionsLocal,
			selectedAllLocal,
			changeSort,
			onRowClick,
			reload,
			unassignLabel,
			handleCheckboxInput,
			handleSelectedAllChanged,
			getProgressVariant,
			getLabels,
			translate,
		};
	},
});
