





import { defineComponent, computed, PropType } from "@vue/composition-api";
import { EmbedVideo } from "@psychlab/types/form";
import { RenderVideo } from "@form-ui";
import { getBaseProps } from "./getBaseProps";

export default defineComponent({
	props:{
		...getBaseProps<EmbedVideo>(),
		config:{
			type:Object as PropType<EmbedVideo>,
			required:true
		}
	},
	components:{
		RenderVideo,
	},
	setup(props){

		const args = computed(() => ({
			title:!props.config.hideName ? props.config.name : '',
			body:props.config.body,
			theme:props.theme,
			url:props.config.settings.url,
			metadata:props.config.settings.metadata,
			width:props.config.settings.width,
			autoplay:props.config.settings.autoplay,
			loop:props.config.settings.loop,
		}));

		return {
			args,
		}
	}
});

