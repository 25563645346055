
















































// vendor
import { defineComponent, computed, ref } from "@vue/composition-api";
import { Icon } from "@ui";
import { useTranslate } from "@lang";
import { default as FoldButton } from "./fold-btn.vue";
import { usePersistentBool } from "@/hooks/usePersistentBool";

export default defineComponent({

	emits:[],
	props:{
	},
	components:{
		Icon,
		FoldButton,
	},
	setup(){
		
		const {
			translate,
		} = useTranslate();
		
		const {
			val:show,
		} = usePersistentBool("form-editor.flow.showLegend", true);

		const ditems = computed(() => {
			return items.map(it => {

				return {
					...it,
					label:translate(it.label)
				}

			});
		})
		
		

		return {
			show,
			ditems,
			translate,
		};
	}
});


type LegendItem = {
	icon:string;
	label:string;
};

const optional:LegendItem[] = [
	{
		icon:"mdi.lock",
		label:"forms.label.required",
	},
	{
		icon:"mdi.lock-open-variant",
		label:"label.optional",
	},
];

const platforms:LegendItem[] = [
	{
		icon:"mdi.monitor-cellphone",
		label:"forms.label.allPlatforms",
	},
	{
		icon:"mdi.monitor",
		label:"forms.label.desktop",
	},
	{
		icon:"mdi.cellphone",
		label:"forms.label.mobile",
	},
];

const shuffle:LegendItem[] = [
	
	{
		icon:"mdi.shuffle-disabled",
		label:"forms.label.shuffleOff",
	},
	{
		icon:"mdi.shuffle",
		label:"forms.action.shuffle",
	},
	{
		icon:"mdi.numeric-1-box",
		label:"forms.action.selectOne",
	},
];

const items = [
	...optional,
	...platforms,
	...shuffle,
];

