import { Graph, GraphNode } from "@/psychlab/types/graph";
import { Scene } from "@/psychlab/types/scene";
import { parseAssetRef } from "./parseAssetRef";

export const getReferencedAssets = (graph:Graph) => {
	const refs:Record<string, string[]> = {};
	Object.keys(graph.nodes)
	.forEach(k => {
		refs[k] = getReferencedNodeAssets(graph.nodes[k]);
	});

	const assets:string[] = [];

	Object.keys(refs)
	.forEach(k => {
		refs[k].forEach(r => {
			assets.push(r.split("@")[0]);
		});
	});

	return {
		nodes:refs,
		assets:Array.from(new Set(assets))
	};
};

export const getReferencedNodeAssets = (node:GraphNode<any>) => {
	const resolver = resolvers[node.type];
	return resolver ? resolver(node) : [];
};

const resolveFromProp = (v:any) => {
	if(!v){ return []; }
	const ref = parseAssetRef(v);
	if(!ref){ return []; }
	return [ ref ];
}

type Resolver = (n:any) => string[];

const resolvers:Record<string,Resolver> = {
	"Form":node => resolveFromProp(node.parameters["asset"]),
	"Gallery":node => resolveFromProp(node.parameters["asset"]),
	"Scene":node => getSceneRefs(node.parameters)
};

const getSceneRefs = (scene:Scene) => {
	const refs:string[] = [];
	scene.points
	.filter(p => Boolean(p.form))
	.forEach(p => refs.push.apply(refs, resolveFromProp(p.form)));
	return refs;
};

const findGraphDependencies = (graph:Graph, path:string[]) => {
	const nodes = path.map(nid => graph.nodes[nid]);
	const formNodes = nodes.filter(n => n.type === "Form");
	const galleryNodes = nodes.filter(n => n.type === "Gallery");
	const scenes = nodes.filter(n => n.type === "Scene").map(n => n.parameters as Scene);

	const rprops:any[] = [];

	// extract ref props
	[ ...formNodes, ...galleryNodes].forEach(n => rprops.push(n.parameters["asset"]));
	scenes.forEach(s => (s.points || []).forEach(p => rprops.push(p["form"])));

	const ids = rprops.map(rp => parseAssetRef(rp)).filter(ref => ref !== null) as string[];

	return Array.from(new Set(ids));
};