import { defineComponent, computed } from "@vue/composition-api";
import * as TextUtils from "@utils/text";
import { RunForm } from "@/components.run/run-form";
import { getProps } from "./getProps";

export const Fallback = defineComponent({
	emits:[ "end", "previous" ],
	components:{
		RunForm
	},
	props:getProps<null>(),
	setup(props, context){

		const title = computed(() => TextUtils.pascalToSentenceCase(props.node?.type || ""));
		const message = "Type not supported";

		const onEnd = () => context.emit("end");
		const onBack = () => context.emit("previous");

		return {
			title,
			message,
			onEnd,
			onBack
		};
	},
	render(){
		return (
			<b-container class="mt-3">
		
				<div class="text-light font-weight-light bg-danger roundedd p-3 shadow">
					<h1 class="text-center font-weight-light">{this.title}</h1>
					<div class="d-flex" style="width:100%">
						<icon name="icon" style="font-size:5rem" class="m-auto"/>
					</div>
					<h4 class="text-center font-weight-light">{this.message}</h4>
				</div>

				<div style="width:100%;" class="d-flex mt-3">
					<b-button
					v-if="showBack"
					v-on:click={this.onBack}
					variant="light"
					class="font-weight-light rounded-0 shadow"
					size="lg"
					>
						<translate text="back" />
					</b-button>

					<div class="mx-auto"></div>
			
					<b-button
					v-if="!isLast"
					v-on:click={this.onEnd}
					variant="light"
					class="font-weight-light rounded-0 shadow"
					size="lg"
					>
						<translate text="continue" />
					</b-button>
					<b-button
					v-else
					v-on:click={this.onEnd}
					variant="success"
					class="font-weight-light rounded-0 shadow"
					size="lg"
					>
						<translate text="finish"/>
					</b-button>
				</div>

			</b-container>
		);
	}
});