import { AxiosInstance, default as Axios } from "axios";
import { getConfig } from "@config";

type Params = Record<string,any>;
type Body = Record<string,any>;

export class HTTP {

	constructor(basePath:string){

		basePath = getConfig().apiURL;

		this._basePath = basePath;
		this._http = Axios.create({
			baseURL:basePath,
		});

		this._http.interceptors.request.use(config => {
			const token = localStorage.getItem("auth");
			if(!token){ return config; }
			if(!config.headers){ config.headers = {}; }
			config.headers["Authorization"] = `bearer ${token}`;
			return config;
		})
	}

	protected async get(path:string, p?:Params):Promise<any>{
		const { data } = await this._http.get(this.buildPath(path, p || {}));
		return data;
	}

	protected async delete(path:string, p?:Params):Promise<any>{
		await this._http.delete(this.buildPath(path, p || {}));
	}

	protected async post(path:string, p?:Params, body?:Body):Promise<any>{
		const { data } = await this._http.post(this.buildPath(path, p || {}), body || {});
		return data;
	}

	private _basePath:string;
	private _http:AxiosInstance;

	private buildPath(subpath:string, p:Params){
		return flattenParams(subpath, p);
	}

}

const flattenParams = (url:string, p:Params) => {
	return Object.keys(p).reduce((u, k) => u.replace(`:${k}`, p[k]), url)
};

const joinPaths = (paths:string[]) => {
	return paths.map(p => cleanURL(p)).join("/");
}

const cleanURL = (url:string) =>{
	url = url.replace("https://", "$HTTPS");
	url = url.replace("http://", "$HTTP");
	url = url.split("/").filter(p => p.length > 0).join("/");
	url = url.replace("$HTTPS", "https://");
	url = url.replace("$HTTP", "http://");
	return url;
}