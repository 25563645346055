import { useTranslate } from "@lang";
import { openContextMenu, ContextOption } from "@ui";
import { FormItem } from "@psychlab/types/form";

type Config = {
	onDuplicate:() => void;
	onDelete:() => void;
};

const isValueType = (t:string) => [ "Information", "HTML", "Image", "Video" ].indexOf(t) < 0;

export const useItemContext = (c:Config) => {

	const { translate } = useTranslate();

	const open = (e:Event, item:FormItem<any>) => {

		const optional = Boolean(item.optional);
		const hideTitle = Boolean(item.hideName);

		const options:ContextOption[] = [
			{
				name:translate("action.duplicate"),
				icon:"mdi.content-duplicate",
				fn:c.onDuplicate,
				order:3,
			},
			{
				name:translate("action.delete"),
				icon:"mdi.trash-can",
				fn:c.onDelete,
				order:4
			}
		];

		// if(isValueType(item.type)){
		// 	if(optional){
		// 		options.push({
		// 			name:translate("forms.action.setRequired"),
		// 			icon:"mdi.asterisk",
		// 			fn:() => item.optional = false,
		// 		});
		// 	}
		// 	else {
		// 		options.push({
		// 			name:translate("forms.action.setOptional"),
		// 			icon:"mdi.check-circle-outline",
		// 			fn:() => item.optional = true,
		// 		});

		// 	}
		// }

		if(hideTitle){
			options.push({
				name:translate("forms.action.showTitle"),
				icon:"mdi.eye",
				fn:() => item.hideName = false,
			});
		}
		else {
			options.push({
				name:translate("forms.action.hideTitle"),
				icon:"mdi.eye-off",
				fn:() => item.hideName = true,
			});
		}

		openContextMenu(e, options);
	};

	return {
		open
	};
};