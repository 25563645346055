// vendor
import { defineComponent, ref, computed, PropType, reactive, watch, onMounted, onUnmounted, set } from "@vue/composition-api";
import { TweenLite } from "gsap";
// project
// local
import { Editor } from "./editor/Editor";
import { default as AddNodeOverlay } from "../vue/add-node-overlay.vue";
import { default as NodeSettingsOverlay } from "../vue/node-settings-overlay.vue";
import { default as NodeContextMenu } from "../vue/node-context-menu.vue";
import { default as InfoOverlay } from "../vue/info-overlay.vue";
import { GraphData, TransitionValidator, NodeTemplate } from "./types";

type Dictionary<T> = { [k:string]:T };

type Config = {
	templates:NodeTemplate<any>[],
	transitionRules:TransitionValidator[]
}

type CanOpenHandler = (nodeId:string) => boolean;

const round = (v:any, decimals:number) => parseFloat(v).toFixed(decimals);

export const EditGraph = defineComponent({

	components:{
		AddNodeOverlay,
		NodeSettingsOverlay,
		NodeContextMenu,
		InfoOverlay
	},
	emits:[ "modified", "openNode", "refresh" ],
	props:{
		graph:{
			type:Object as PropType<GraphData>,
			required:true
		},
		config:{
			type:Object as PropType<Config>,
			required:true,
			validator:(v:Config) => v && Boolean(v.templates) && Boolean(v.transitionRules)
		},
		canOpenFn:{
			type:Function as PropType<CanOpenHandler>
		}
	},
	setup(props, context){


		let editor:Editor|undefined = undefined;
		// template refs
		const nodeContextMenu = ref<any>();
		const editorContainer = ref<HTMLElement>();
		// state
		const toolbarValues = reactive({ zoom:1 });
		const selectedNodes = ref<string[]>([]);
		const nodeLabels = ref<Dictionary<any>>({});
		const tween = reactive({ zoom:1 });

		const zoom = computed(() => toolbarValues.zoom);
		const templates = computed(() => props.config.templates.filter(t => !t.hide));

		// const dragOffset = reactive({
		// 	x:0,
		// 	y:0
		// });
		const dragOffset = ref({
			x:0,
			y:0
		});

		const nodePositions = ref<Dictionary<any>>((() => {

			const map:Dictionary<any> = {};

			Object.keys(props.graph.nodes)
			.map(nk => {
				const n = props.graph.nodes[nk];
				map[nk] = { x:n.x, y:n.y}
			});
			
			return map;
		})());

		const selectedNodeData = computed(() => {
			const activeNode:string|null = selectedNodes.value.length === 1 ? selectedNodes.value[0] : null;
			const id = activeNode
			if(!id){ return null; }
			const data = props.graph.nodes[id];
			if(!data) { return null; }

			const template = props.config.templates.find(t => t.name === data.type);
			return {
				id: id,
				template,
				parameters: data.parameters,
				data
			};
		});

		const tweenedZoom = computed(() => {
			return tween.zoom;
		});

		watch(tweenedZoom, () => {
			if(!editor){ return; }

			// const pos = editor.getScrollOffset();

			// dragOffset.x = pos.x;
			// dragOffset.y = pos.y;
		})

		watch(tween, () => {
			if(!editor){ return; }
			editor.configure({zoom: tween.zoom});

			const pos = editor.getScrollOffset();

			dragOffset.value.x = pos.x;
			dragOffset.value.y = pos.y;
		}, { deep:true });

		watch(zoom, v => {
			TweenLite.to(tween, 0.2, { "zoom": v });

		});

		watch(() => props.graph, () => {
			refreshEditor();
		}, { deep:true });

		watch(nodeLabels, (v) => {
			const e = editor;
			if(!e){ return; }
			// Object.keys(v).forEach(k => e.setNodeLabel(k, v[k]));
		});

		const scrollZoom = (delta:number) => {
			const current = toolbarValues.zoom + (0.25 * (delta > 0 ? -1 : 1));
			if(current >= 0.25 &&  current <= 2){
				toolbarValues.zoom = current;
			}
		};

		const onAddNode = (args:{ type:string, x:number, y:number }) => {
			if(!editor){ return; }
			const offset = editor.getScrollOffset();
			const t = templates.value.find(t => t.name === args.type);
			if(!t){ return; }
			const n = {
				type: args.type,
				name: "",
				x: args.x - offset.x,
				y: args.y - offset.y,
				parameters: JSON.parse(JSON.stringify(t.parameters))
			};
			n.x *= (1.0 / toolbarValues.zoom);
			n.y *= (1.0 / toolbarValues.zoom);
			editor.createNode(n);
		};

		const setNodeLabel = (node:string, v:string) => {
			nodeLabels.value[node] = v;
			nodeLabels.value = JSON.parse(JSON.stringify(nodeLabels.value));
		};
	
		const onDataModified = (data:any) => {
			context.emit("modified", data);
		};
	
		const refreshEditor = () => {
			if(!editor){ return; }
			editor.loadGraph(props.graph);

			const pos = editor.getScrollOffset();

			
			const map:Dictionary<any> = {};

			Object.keys(props.graph.nodes)
			.map(nk => {
				const n = props.graph.nodes[nk];
				map[nk] = { x:n.x, y:n.y}
			});

			nodePositions.value = map;;

			// refreshLabels();
		};

		// const refreshLabels = () => {
		// 	const e = editor;
		// 	if(!e){ return; }
		// 	Object.keys(nodeLabels.value)
		// 	.forEach(k => e.setNodeLabel(k, nodeLabels.value[k]));
		// };

		const onNodeContext = (config:{ nodeId:string, event:any }) => {
			// const { nodeId, event } = config;
			// if(!nodeContextMenu.value){ return; }
			// nodeContextMenu.value.open(event, nodeId,{
			// 	canOpen: props.canOpenFn ? props.canOpenFn(nodeId) : false
			// });
		};

		const onOpenNode = (nodeId:string) => {
			context.emit("openNode", nodeId);
		};
	
		const deleteNode = (nodeId:string) => {
			if(!editor){ return; }
			editor.deleteNode(nodeId);

			if(props.graph.startNode === nodeId){
				set(props.graph, "startNode", null);
			}
		};

		onMounted(() => {
			if(!editorContainer.value){ return; }
			editor = new Editor("#Editor", props.config);
			editor.on("dataChanged", onDataModified);
			editor.onSelectionChange((items:any[]) => selectedNodes.value = items);
			editor.on("nodeContext", onNodeContext);
			editor.on("openNode", onOpenNode);
			editorContainer.value.addEventListener("wheel", e => {
				scrollZoom(e.deltaY);
				e.preventDefault();
				return false;
			}, false);
			refreshEditor();
			context.emit("refresh");

			editor.on("node-position-updated", e => {
				// nodePositions.value[e.node] = e.pos;
				set(nodePositions.value, e.node, e.pos);
			});

			editor.on("draggedView", (posx) => {
				if(!editor){ return; }
				const pos = editor.getScrollOffset();
				dragOffset.value.x = pos.x;
				dragOffset.value.y = pos.y;
			});

		});

		onUnmounted(() => {
			if(editor){
				editor.dispose();
			}
		});

		return {
			editorContainer,
			nodeContextMenu,
			toolbarValues,
			selectedNodeData,
			templates,
			zoom,
			dragOffset,
			tweenedZoom,
			nodePositions,
			nodeLabels,
			round,
			onAddNode,
			onOpenNode,
			deleteNode,
			setNodeLabel
		};

	}


});

