import { defineComponent } from "@vue/composition-api";
import { AbsBox, Icon, CompanyFooter } from "@ui";
import { Gradient } from "./Backgrounds";

export const ErrorScreen = defineComponent({

	props:{
		text:{
			type:String,
			default:""
		},
		subtext:{
			type:String,
			default:""
		},
		icon:{
			type:String,
			default:"alert"
		},
		backText:{
			type:String,
			default:"",
		},
		backRoute:{
			type:null,
		}
	},
	render(){

		let sub = <span/>;

		if(this.subtext){
			sub = (
				<div>
					{/* <hr/> */}
					<ErrorSubtitle text={this.subtext}/>
				</div>
			)
		}

		const back = (this.backRoute && this.backText) ? <Backlink text={this.backText} link={this.backRoute} class="mx-auto mt-3"/> : <span/>;

		return (
			<PageContainer>

				<Gradient/>

				<AbsBox class="d-flex">

				<CenteredContainer>
					<ErrorIcon icon={this.icon}/>
					<ErrorTitle text={this.text}/>
					{sub}
					{back}
				</CenteredContainer>

				</AbsBox>

				
				<CompanyFooter fixed/>
			</PageContainer>
		)
	}
});


const Backlink = defineComponent({
	props:[ "text", "link" ],
	render(){
		return (
			<router-link
			to={ this.link as any }
			class="btn btn-outline-light">
				<Icon name="arrow-left" class="mr-2"/>
				{ this.text }
			</router-link>
		);
	}
})


const CenteredContainer = defineComponent({
	render(){
		return (
			<div class="m-auto d-flex flex-column">
				{this.$slots.default}
			</div>
		);
	}
});


const PageContainer = defineComponent({
	render(){
		return (
			<AbsBox class="bg-dark d-flex text-light">
				{this.$slots.default}
			</AbsBox>
		);
	}
});

const ErrorIcon = defineComponent({
	props:[ "icon" ],
	render(){
		return (
			<h1 class="font-weight-light text-center">
				<Icon name={this.icon} style="font-size:1.5em"/>
			</h1>
		)
	}
});


const ErrorTitle = defineComponent({
	props:[ "text" ],
	render(){
		return (
			<h1
			style="letter-spacing:0.25em;"
			class="text-center font-weight-bold">{this.text}</h1>
		);
	}
});


const ErrorSubtitle = defineComponent({
	props:[ "text" ],
	render(){
		return (
			<h5
			style="letter-spacing:0.2em;"
			class="text-center font-weight-light">{this.text}</h5>
		);
	}
});