import * as THREE from "three";
import { TransformControls } from "three/examples/jsm/controls/TransformControls";
import { TransformMode } from "../core/TransformMode";

type EventHandler = (e:THREE.Event) => void;

export class TransformHandle {

	public get enabled():boolean{
		return this._control.enabled;
	}

	public set enabled(v:boolean){
		this._control.visible = v;
		this._control.enabled = v;
	}

	public get object(){
		return this._wrapper;
	}

	public get mode():TransformMode{
		return this._control.getMode() as TransformMode;
	}

	public set mode(m:TransformMode){
		this._control.setMode(m);
	}

	public onDraggingChanged: EventHandler|null = null;
	public onObjectChange: EventHandler|null = null;
	public onMouseUp: EventHandler|null = null;

	constructor(camera:THREE.Camera, el:HTMLElement){
		this._wrapper = new THREE.Group();
		this._control = new TransformControls(camera, el);
		this._control.setRotationSnap(THREE.MathUtils.DEG2RAD * 5);
		this._control.setSpace("local");
		this._target = new THREE.Group();
		this._wrapper.add(this._target);
		this._control.attach(this._target);
		this._control.enabled = false;
		this._control.visible = false;
		this._wrapper.add(this._control);
		this._control.addEventListener("dragging-changed", this.draggingChanged.bind(this));
		this._control.addEventListener("objectChange", this.objectChange.bind(this));
		this._control.addEventListener("mouseUp", this.mouseUp.bind(this));
	}

	public dispose(){
		this._control.dispose();
	}

	public getPosition(){
		return {
			x: this._target.position.x,
			y: this._target.position.y,
			z: this._target.position.z,
		}
	}

	public getRotation(){
		const rot = this._target.rotation.toVector3();
		return {
			x:rot.x * THREE.MathUtils.RAD2DEG,
			y:rot.y * THREE.MathUtils.RAD2DEG,
			z:rot.z * THREE.MathUtils.RAD2DEG
		};
	}

	public setPosition(p:{ x:number, y:number, z:number }){
		this._target.position.set(p.x, p.y, p.z);
	}

	public setRotation(r:{ x:number, y:number, z:number }){
		const vv = new THREE.Vector3(r.x * THREE.MathUtils.DEG2RAD, r.y * THREE.MathUtils.DEG2RAD, r.z * THREE.MathUtils.DEG2RAD);
		this._target.quaternion.setFromEuler(new THREE.Euler().setFromVector3(vv));
	}

	private _target:THREE.Object3D;
	private _wrapper:THREE.Object3D;
	private _control:TransformControls;

	private draggingChanged(event:THREE.Event){
		if(this.onDraggingChanged){
			this.onDraggingChanged(event);
		}
	}
	
	private objectChange(event:THREE.Event){
		if(this.onObjectChange){
			this.onObjectChange(event);
		}
	}

	private mouseUp(event:THREE.Event){
		if(this.onMouseUp){
			this.onMouseUp(event);
		}
	}
}