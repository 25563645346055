import { defineComponent, ref, computed, onUnmounted, PropType, onBeforeMount } from "@vue/composition-api";
import { Socket } from "socket.io-client";
import * as SessionAPI from "@/services/api/sessions";
import * as AssetAPI from "@/services/api/assets";
import * as StudyAPI from "@/services/api/studies";
import { Session, SessionState, Study } from "@/psychlab/types";
import { NodeTemplates } from "@/components.edit/edit-blueprint";
import { Icon } from "@ui";
import { formatDuration } from "@utils/text";
import { AbsBox } from "@ui";

export const ViewSessionProgress = defineComponent({
	props:{
		sessionId:{
			type:String,
			required:true
		}
	},
	setup(props, context){
		const session = ref<Session>();
		const state = ref<SessionState>();
		const eventSocket = ref<Socket>();
		const study = ref<Study>();

		const studyId = computed(() => session.value ? session.value.group : undefined);
		const blueprintId = computed(() => session.value ? session.value.asset : undefined);
		
		const nodePath = computed(() => session.value ? session.value.path : undefined);
		const graph = computed(() => session.value ? session.value.graph : undefined);

		const activeNode = computed(() => state.value?.node);

		const breadcrumbs = computed(() => {
			if(!study.value || !session.value){ return []; }
			return [
				{
					text: "Monitor",
					to: {
						name: "Run.Group.Monitor",
						params:{
							groupId:studyId.value
						}
					}
				},
				{
					text: `Session, id: ${session.value._id}`,
					active: true
				}
			];
		});

		onUnmounted(() => eventSocket.value?.close());

		const onSessionDeleted = () => {
			// add logic to handle deletion
		}

		const stateModified = (newState:SessionState) => {
			state.value = newState;
		}

		const init = async() => {
			// session
			try{
				session.value = await SessionAPI.loadSession(props.sessionId, true);
				state.value = session.value.state;
			}
			catch(err){ console.error(err); }
			if(!session.value){ return; }
			
			// study
			try { study.value = await StudyAPI.getStudy(session.value.group); }
			catch(err:any){ console.error(err.message); }
			// blueprint
			try { await AssetAPI.getAsset(session.value.asset) }
			catch(err){ console.error(err); }
			// live updates
			eventSocket.value = SessionAPI.createSessionSocket(props.sessionId, `bearer ${context.root.$store.getters.token}`);
			eventSocket.value.on("error", () => console.log("error!"));
			eventSocket.value.on("disconnect", (msg:any) => console.log("io disconnect"));
			eventSocket.value.on("state", stateModified);
			eventSocket.value.on("deleted", onSessionDeleted);
			eventSocket.value.open();
		};

		init();

		return {
			breadcrumbs,
			session,
			activeNode,
			studyId,
			blueprintId,
			nodePath,
			graph
		};
		
	},
	render(){

		if(!this.session || !this.activeNode || !this.nodePath){
			return <div/>;
		}

		return (
			<div>
				<TimeProgress session={ this.session }/>
				<div class="mt-0 p-0 mt-3">
					<PathProgress
					graph={ this.graph }
					activeNode={ this.activeNode }
					path={ this.nodePath }
					/>
				</div>
			</div>
		);
	}

});

const PathProgress = defineComponent({
	props:{
		graph:{ type:null, required:true, },
		activeNode:{ type:String, },
		path:{ type:Array as PropType<string[]>, required:true, }
	},
	setup(props){
		const displayNodes = computed(() => {
			return props.path.map(id => {
				const node = props.graph.nodes[id];
				const def = NodeTemplates[node.type];
				return {
					id,
					icon: def.icon
				};
			});
		});
		return {
			displayNodes
		};
	},
	render(){
		const dnodes = this.displayNodes.map(node => {
			const vcls = this.activeNode === node.id ? 'bg-info' : 'bg-secondary';
			return (
				<div
				key={ node.id }
				style="width:2.5rem;height:2.5rem"
				class={ `d-inline-block m-1 rounded-circle shadow ${vcls}` }
				>
					<div class="d-flex" style="width:100%;height:100%">
						<Icon name={ node.icon } style="font-size:1.2em;color:white !important" class="m-auto"/>
					</div>
				</div>
			);
		});

		return (
			<div class="bg-dark p-1" style="border-radius:2em;">
				{dnodes}
			</div>
		);
	}
});

const TimeProgress = defineComponent({
	props:{
		session:{
			type:Object as PropType<{
				created:string;
				expires:number;
				completed?:string;
			}>,
			required:true
		}
	},
	setup(props){

		const start = ref(new Date(props.session.created).getTime());
		const end = ref(props.session.expires);

		const stopped = computed(() => {
			return props.session.completed ? new Date(props.session.completed).getTime() : undefined;
		});
		
		return {
			start,
			end,
			stopped
		};
	},
	render(){
		return <LiveTimer start={ this.start } end={ this.end } stopped={this.stopped}/>;
	}
});


const LiveTimer = defineComponent({
	props:{
		start:{ type:Number, required:true, },
		end:{ type:Number, required:true, },
		stopped:{ type:Number, required:false, }
	},
	setup(props){

		const tick = ref<NodeJS.Timeout>();
		const time = ref(Date.now());

		const icon = computed(() => progressStates[state.value].icon);
		const theme = computed(() => progressStates[state.value].theme);
		const totalTime = computed(() => props.end - props.start);
		const done = computed(() => Boolean(props.stopped));

		const expired = computed(() => {
			if(props.stopped && props.stopped < props.end){ return false; }
			return time.value > props.end;
		});

		const state = computed(() => {
			if(expired.value){ return "expired"; }
			if(done.value){ return "done"; }
			return "running"
		});

		const progress = computed(() => {
			let t = time.value - props.start;
			if(props.stopped){
				t = props.stopped - props.start;
			}
			const p = t / totalTime.value;
			return p > 1 ? 1 : p;
		});

		const progressLabel = computed(() => {
			const rm = totalTime.value - (totalTime.value * progress.value);
			return formatDuration(rm);
		})

		const stop = () => {
			if(tick.value){ clearInterval(tick.value); }
			tick.value = undefined;
		};

		const onTick = () => time.value = Date.now();

		onBeforeMount(() => {
			if(done.value || expired.value){ return; }
			tick.value = setInterval(onTick, 1000);
			onTick();
		});

		onUnmounted(stop);

		return {
			progress,
			icon,
			theme,
			progressLabel,
		};
	},
	render(){
		return (
			<div style="position:relative;border-radius:2em;">
			<b-progress
			value={ this.progress }
			max={ 1 }
			style="height:3rem;border-radius:2em;"
			variant={ this.theme }
			class={ `bg-secondary shadow border border-${this.theme}` }
			/>
			<AbsBox class="m-auto d-flex">
				<h4 class="m-auto text-light" style="font-family:'NATS'">
					{ this.progressLabel }
				</h4>
			</AbsBox>
		</div>
		);
	}
})

const progressStates:Record<string, { icon:string, theme:string }> = {
	"running":{ icon:"clock-outline", theme:"warning" },
	"done":{ icon:"clock-check-outline", theme:"success" },
	"expired":{ icon:"clock-alert-outline", theme:"danger" }
};