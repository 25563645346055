

































import { defineComponent, computed, ref, PropType, set } from "@vue/composition-api";
import { FormItem, FormItemType } from "@psychlab/types/form";
import { useFormEditor } from "@form-ui";
import { AbsBox, Icon } from "@ui";
import { useTranslate } from "@lang";
import * as Renderers from "./renderers";
import { editText } from "@/AppModal";
import { default as EditorControls } from "./editor-controls.vue";
import { default as InfoOverlay } from "./info-overlay.vue";
import { useFormTheme, useItemContext } from "../hooks";
const renderers:{ [key in FormItemType]?: any } = Renderers;

export default defineComponent({
	emits:[ "delete", "duplicate" ],
	props:{
		disableDrag:{
			type:Boolean,
			default:false
		},
		dragging:{
			type:Boolean,
			default:false
		},
		data:{
			type:Object as PropType<FormItem<any>>,
			required:true
		},
		showControls:{
			type:Boolean,
			default:true,
		}

	},
	components: {
		AbsBox,
		Icon,
		EditorControls,
		InfoOverlay,
	},
	setup(props, ctx){

		const hovered = ref(false);

		const { translate } = useTranslate();
		const { open: openContext } = useItemContext({
			onDelete:() => ctx.emit("delete"),
			onDuplicate:() => ctx.emit("duplicate")
		});

		const {
			theme
		} = useFormTheme();
		

		const formEditor = useFormEditor({
			onTitle:() => editTitle(),
			onBody:() => editBody(),
		})

		const renderer = computed(() => {
			const component = renderers[props.data.type];
			if(!component){ return null; }

			const pr:any = {
				data:props.data,
			}

			if(props.showControls){
				pr["editor"] = formEditor;
			}



			return {
				component,
				props:pr,
			}
		});

		const editTitle = () => {
			editText({
				variant:"light",
				title:translate("forms.label.title"),
				value:props.data.name,
			}, (newTitle) => {
				props.data.name = newTitle;
			})
		};

		const editBody = () => {
			editText({
				variant:"light",
				title:translate("forms.label.body"),
				value:props.data.body || "",
				size:"lg",
				rows:10,
				textarea:true,
			}, (newTitle) => {
				set(props.data, "body", newTitle);
			})
		};

		const editComment = () => {
			editText({
				variant:"light",
				title:translate("forms.label.comment"),
				value:props.data.comment || "",
				size:"lg",
				rows:10,
				textarea:true,
			}, (newTitle) => {
				props.data.comment = newTitle;
			})
		};

		const openSettings = (e:Event) => {
			openContext(e, props.data);
		};


		return {
			renderer,
			formEditor,
			hovered,
			theme,
			openSettings,
			editComment,
		};
	}
});


