import * as AssetAPI from "@/services/api/assets";
import { parseAssetRef } from "./parseAssetRef";

export const computeNodeLabel = async (node:any) => {
	if(node.name){ return node.name; }
	const resolver = (resolvers as any)[node.type];
	if(typeof(resolver) === "string"){ return resolver; }
	if(!resolver){ return ""; }
	return await resolver(node, node.parameters);
}

type LabelResolver = (n:any, p:any) => Promise<string>

type NodeType = "Form"|"Gallery"|"Scene"|"Select";

const resolveFromAssetRef = async (node:any, parameters:any) => {
	const [ aid ] = (parseAssetRef(parameters["asset"]) || "").split("@");
	if(!aid){ return `dark;${node.type}`; }
	try { return `info;${(await AssetAPI.getAsset(aid)).name}`; }
	catch{}
	return `dark;${node.type}`;
};

const resolvers:Record<NodeType, LabelResolver|string> = {
	"Form":resolveFromAssetRef,
	"Gallery":resolveFromAssetRef,
	"Scene":"dark;Scene",
	"Select":""
};