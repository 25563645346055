

































import { defineComponent, computed } from "@vue/composition-api";
import { RenderMarkdown } from "@ui";
import { ItemBox, getBaseProps } from "../shared";
import { AbsBox } from "@ui";
import { useTranslate } from "@lang";
import { Hoverlay } from "../shared";
import { EmptyContentBox } from "../editor";

export default defineComponent({
	emit:[ "edit-content" ],
	props:{
		content:{
			type:String,
			default:""
		},
		...getBaseProps()
	},
	components:{
		RenderMarkdown,
		ItemBox,
		AbsBox,
		Hoverlay,
		EmptyContentBox,
	},
	setup(props, ctx){

		const { translate } = useTranslate();

		const onEditContent = () => {
			ctx.emit("edit-content");
		};

		const isEmpty = computed(() => {
			return !Boolean(props.content);
			// return !Boolean(props.editor) && !Boolean(props.content);
		});

		const padding = computed(() => {

			return props.content.length > 0 ? 3 : 0;

			// const p = Boolean(props.editor) && !Boolean(props.content) ? 3 : 0;

			// return p;
		})

		return {
			padding,
			isEmpty,
			onEditContent,
			translate,
		};
	},
});
