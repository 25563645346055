









// vendor
import { defineComponent, computed, ref } from "@vue/composition-api";

export default defineComponent({

	emits:[],
	props:{
	},
	components:{
	},
	setup(){
		
		

		return {

		};
	}
});

