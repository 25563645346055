var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"abstretch ofhidden bg-dark d-flex flex-column"},[(_vm.isMobile && _vm.data && _vm.data.panorama && _vm.activeItem)?_c('RenderPanoramaMobile',{attrs:{"gallery":_vm.data,"sources":_vm.activeSources,"accelerometer":_vm.accelerometerEnabled},on:{"load-start":function($event){_vm.panoramaLoading = true},"load-end":function($event){_vm.panoramaLoading = false}}}):_vm._e(),(!_vm.isMobile)?[(_vm.experimentalPano && _vm.data && _vm.data.panorama && _vm.activeItem)?_c('RenderPanorama',{attrs:{"gallery":_vm.data,"sources":_vm.activeSources,"accelerometer":_vm.accelerometerEnabled},on:{"load-start":function($event){_vm.panoramaLoading = true},"load-end":function($event){_vm.panoramaLoading = false}}}):_vm._e(),(!_vm.experimentalPano && _vm.data && _vm.data.panorama && _vm.activeItem)?_c('RenderPanoramaMobile',{attrs:{"gallery":_vm.data,"sources":_vm.activeSources,"accelerometer":_vm.accelerometerEnabled},on:{"load-start":function($event){_vm.panoramaLoading = true},"load-end":function($event){_vm.panoramaLoading = false}}}):_vm._e()]:_vm._e(),(_vm.data && !_vm.data.panorama && _vm.activeItem)?_c('RenderStatic',{attrs:{"gallery":_vm.data,"sources":_vm.activeSources},on:{"load-start":function($event){_vm.panoramaLoading = true},"load-end":function($event){_vm.panoramaLoading = false}}}):_vm._e(),_c('transition',{attrs:{"name":"transfade"}},[(_vm.menuOpen)?_c('GalleryThumbnails',{staticClass:"shadow",attrs:{"activeItem":_vm.activeItemId,"items":_vm.data.items,"loading":_vm.panoramaLoading,"states":_vm.itemStates,"showLabel":_vm.data.showLabels},on:{"select":_vm.selectItem}}):_vm._e()],1),(_vm.currentScale)?_c('GalleryFeedback',{key:_vm.currentScale.key,attrs:{"open":_vm.menuOpen,"showBack":_vm.canGoBack,"loading":_vm.responseLoading || _vm.panoramaLoading,"validateNext":_vm.validateNext,"config":{
		key:_vm.currentScale.key,
		options:_vm.feedback.scale,
		cols:_vm.feedback.scale,
		label:_vm.feedback.label,
		minLabel:_vm.feedback.minLabel,
		maxLabel:_vm.feedback.maxLabel,
		optional:_vm.feedback.optional || false
	},"onLast":_vm.onLast,"showTooltips":!_vm.isMobile},on:{"previous":_vm.onPrevious,"next":_vm.onNext,"toggle-open":function($event){_vm.menuOpen = !_vm.menuOpen},"input":function($event){return _vm.saveValue(_vm.currentScale.key, $event)}},model:{value:(_vm.cache[_vm.currentScale.key]),callback:function ($$v) {_vm.$set(_vm.cache, _vm.currentScale.key, $$v)},expression:"cache[currentScale.key]"}}):_vm._e(),(!_vm.feedback)?_c('DefaultNavigation',{staticStyle:{"bottom":"0","position":"absolute"},attrs:{"showBack":_vm.canGoBack,"open":_vm.menuOpen,"loading":_vm.panoramaLoading,"onLast":_vm.onLast},on:{"previous":_vm.onPrevious,"next":_vm.onNext,"toggle":function($event){_vm.menuOpen = !_vm.menuOpen}}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }