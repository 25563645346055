










































import { defineComponent, computed, ref, PropType } from "@vue/composition-api";
import { SessionLabel } from "@psychlab/types/study";
import { useTranslate } from "@lang";
import { AssignLabelModal } from "../labels";

type Dictionary<T> = { [k:string]:T };

export default defineComponent({
	emits: [
		"toggle",
		"reload",
	],
	props: {
		sessions: {
			type: Array as PropType<string[]>,
			required: true,
		},
		enabled: {
			type: Boolean,
			default:false,
		},
		selectedSessions: {
			type: Array as PropType<string[]>,
			default:() => [],
		},
		labels: {
			type: Array as PropType<SessionLabel[]>,
			default:() => {},
		},
	},
	components: {
		AssignLabelModal,
	},
	setup(props, ctx) {

		const { translate } = useTranslate();

		const assignLabelModal = ref<any>();

		const buttonVariant = computed(() => {
			return props.enabled ? "light" : "outline-light";
		});

		const buttonLabel = computed(() => {
			return "";
		});

		const labelDictionary = computed(() => {
			const d:Dictionary<SessionLabel> = {};
			props.labels.forEach((label: any) => d[label._id] = label);
			return d;
		});

		const actions = computed(() => {
			return [
				{
					icon:"mdi.plus-thick",
					label:translate("studies.label.label"),
					variant:"info",
					disabled:!props.enabled || props.selectedSessions.length === 0,
					fn(){
						if(!assignLabelModal.value){ return; }
						assignLabelModal.value.open({
							sessions:props.selectedSessions
						});
					},
				}
			]
		});

		const toggle = () => ctx.emit("toggle");

		const reload = () => ctx.emit("reload");

		return {
			assignLabelModal,
			buttonLabel,
			buttonVariant,
			toggle,
			reload,
			actions,
			labelDictionary,
		};
	},
});
