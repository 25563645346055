























































	import { defineComponent, computed, ref, PropType, watch, set } from "@vue/composition-api";
	// project
	import { StudyWidget } from "@/components.studies/study-widget";
	import { camelToSentenceCase } from "@/utils/text";
	// local
	import { WizardContext } from "../../ts/types/WizardContext";
	import { parseBinding } from "../../ts/utils/parseBinding";
	import { default as PropertyDrawer } from "../property-drawer.vue";
import { useTranslate } from "@lang";
	
	const getStaticPropValues = (staticArgs:any, bindings:string[]) => {

		const ob:any = {};
		bindings.map(parseBinding)
		.forEach(binding => {
			const v = staticArgs[binding.from];
			if(v !== undefined){
				ob[binding.to] = v;
			}
		});
		return ob;
	};

	export default defineComponent({

		emits:[],
		props:{
			context: {
				type: Object as PropType<WizardContext>,
				required: true,
			},
		},
		components:{
			StudyWidget,
			PropertyDrawer,
		},
		setup(props, context){


			// const overrideArgs = ref<any>({
			// 	color:"red"
			// });

			const {
				translate,
			} = useTranslate();

			const overrideArgs = computed(() => props.context.overrideArgs);

			const displayTemplate = computed(() => {
				
				const t = props.context.renderTemplate;
				if(!t){ return null; }

				const data:any = {};

				Object.keys(t.props)
				.forEach(p => {
					data[p] = t.props[p].default;
				});

				return {
					template:t.template,
					data:{
						...data,
						...getStaticPropValues(props.context.staticArgs, props.context.dataBindings),
						...overrideArgs.value
					}
				};

			});

			const parsedBindings = computed(() => {
				return props.context.dataBindings.map(parseBinding);
			});

			const overrideProps = computed(() => {

				const t = props.context.renderTemplate;

				if(!t){ return []; }


				return Object.keys(t.props)
				.filter(k => t.props[k].optional)
				.filter(k => {
					return parsedBindings.value.findIndex(b => b.to === k) < 0;
				})

				.map(k => {
					
					const prop = t.props[k];
			
					return {
						// label:camelToSentenceCase(k),
						label:translate(`widgets.label.${k}`),
						key:k,
						type:prop.type,
						drawer:prop.drawer || {}
					}
				});
			});

			const init = () => {

				const t = props.context.renderTemplate;
				if(!t){ return []; }

				const args:any = {
					...props.context.overrideArgs
				};

				Object.keys(t.props)
				.filter(k => t.props[k].optional)
				.forEach(k => {
					const prop = t.props[k];
					if(args[k] === undefined){
						args[k] = prop.default;
					}
					
				});
				set(props.context, "overrideArgs", args);

			};
			
			init();

			return {
				overrideArgs,
				overrideProps,
				displayTemplate,
				translate,
			};
		}
	});

