import { PhraseDictionary } from "../types";
import { session } from "./session";
import { assets } from "./assets";
import { prompts } from "./prompts";
import { studies } from "./studies";
import { nav } from "./nav";
import { forms } from "./forms";
import { tags } from "./tags";
import { widgets } from "./widgets";
import { global } from "./global";
import auth from "./auth";
import errors from "./errors";

export const dictionary:PhraseDictionary  = {
	...global,
	...forms,
	...studies,
	...session,
	...assets,
	...prompts,
	...nav,
	...tags,
	...widgets,
	...errors,
	...auth,
};