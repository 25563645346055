





































import { defineComponent, computed, PropType } from "@vue/composition-api";
import { ItemBox, getBaseProps } from "../shared";
import { EditOptions } from "../edit-options";
import { AbsBox } from "@ui";
import { useTranslate } from "@lang";

export default defineComponent({

	emits:[ "input" ],
	props:{
		value:{
			type:Array as PropType<Number[]>,
			default:() => []
		},
		options:{
			type:Array as PropType<string[]>,
			default: () => [],
		},
		...getBaseProps(),
	},
	components:{
		ItemBox,
		AbsBox,
		EditOptions,
	},
	setup(props, ctx){

		const { translate } = useTranslate();
		
		const val = computed({
			get:() => props.value,
			set:v => ctx.emit("input", v)
		});

		const displayOptions = computed(() => {

			const dlabel = `(${translate("label.untitled").toLowerCase()})`;

			return props.options.map((o, i) => {
				return {
					value: i,
					text: o || dlabel,
				}
			});
		});

		const checkOption = (i:number) => {
			const checkIndex = val.value.findIndex(v => v === i);
			if(checkIndex < 0){
				val.value.push(i);
			}
			else {
				val.value.splice(checkIndex, 1);
			}
			val.value = val.value;
		};

		return {
			val,
			displayOptions,
			checkOption,
		};
	}
});

