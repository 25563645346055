























// vendor
import { defineComponent, computed, ref } from "@vue/composition-api";
import { Icon } from "@ui";
import { default as IconButton } from "./ico-btn.vue";

export default defineComponent({

	emits:[ "input" ],
	props:{
		value:{
			type:Boolean,
			default:false,
		},
		disabled:{
			type:Boolean,
			default:false,
		},
	},
	components:{
		Icon,
		IconButton,
	},
	setup(props, ctx){

		const locked = computed({
			get:() => Boolean(props.value),
			set:v => ctx.emit("input", v)
		});

		const toggle = () => locked.value = !locked.value;

		const icon = computed(() => {
			return !locked.value ? 'mdi.lock' : 'mdi.lock-open-variant';

		})

		return {
			toggle,
			locked,
			icon,
		};
	}
});

