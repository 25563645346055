






















import { defineComponent, PropType, set, computed } from "@vue/composition-api";
import { SelectParams } from "../../ts/templates/Select";
import { useTranslate } from "@lang";
import { Container, DropdownInput } from "@ui";

enum Translations {
	Mode = "assets.label.selectionMode",
	ModeSelectOne = "assets.action.selectOne",
	SetMode = "assets.action.setSelectionMode",
}

const selectOptions = [
	{
		tkey:Translations.ModeSelectOne,
		value:"SelectOne",
	}
];

export default defineComponent({

	props:{
		parameters:{
			type:Object as PropType<SelectParams>,
			required:true
		}
	},
	components:{
		Container,
		DropdownInput,
	},
	setup(props){

		const { translate } = useTranslate();

		const mode = computed({
			get:() => props.parameters.mode,
			set:(v) => set(props.parameters, "mode", v)
		});

		const options = computed(() => {
			return selectOptions.map(so => ({
				value:so.value,
				text:translate(so.tkey),
			}));
		});
		
		const phrases = computed(() => ({
			mode:translate(Translations.Mode),
			setMode:translate(Translations.SetMode),
		}));

		return {
			mode,
			options,
			phrases,
		};
	}
});

