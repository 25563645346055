






















import { defineComponent } from "@vue/composition-api";
import { RenderMarkdown } from "@ui";
import { ItemBox, getBaseProps } from "../shared";
import { AbsBox } from "@ui";
import { useTranslate } from "@lang";
import { Hoverlay } from "../shared";
import { EmptyContentBox } from "../editor";

export default defineComponent({
	emit:[ "edit-content" ],
	props:{
		content:{
			type:String,
			default:""
		},
		...getBaseProps()
	},
	components:{
		RenderMarkdown,
		ItemBox,
		AbsBox,
		Hoverlay,
		EmptyContentBox,
	},
	setup(_, ctx){

		const { translate } = useTranslate();

		const onEditContent = () => {
			ctx.emit("edit-content");
		};

		return {
			onEditContent,
			translate,
		};
	},
});
