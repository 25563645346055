import { defineComponent, PropType } from "@vue/composition-api";
import { Icon, Pagination, } from "@ui";
import { Toggle } from "@/components.generic/scriptable-form";
import { useTranslate } from "@lang";

enum HeaderEvents {
	Refresh = "refresh",
	SetPage = "set-page",
	SetLimit = "set-limit",
	SetEnabled = "set-enabled",
}

enum Translations {
	Open = "label.open",
}

// const x = Object.values(HeaderEvents);


export const Header = defineComponent({
	emits:[
		"refresh",
		"set-page",
		"set-limit",
		"set-enabled",
	],
	props:{
		filter:{
			type:Object as PropType<{
				enabled:boolean;
			}>,
			default:() => ({ enabled:false, })
		},
		pagination:{
			type:Object as PropType<{
				page:number;
				limit:number;
				rows:number;
			}>,
			default:() => ({ page:0, limit:5, rows:0 })
		}
	},
	setup(){
		const { translate } = useTranslate();
		return { translate };
	},
	render(){

		const toggleLabel = this.translate(Translations.Open);

		return (
			<div class="bg-dark d-flex">
				<div class="flex-fill d-flex">
					<RefreshButton
					v-on:click={ () => this.$emit(HeaderEvents.Refresh) }
					/>
					<VRule/>
					<Toggle
					size="md"
					value={ this.filter.enabled }
					v-on:input={ (v:boolean) => this.$emit(HeaderEvents.SetEnabled, v) }
					config={ { variant:'info', onLabel: toggleLabel, offLabel: toggleLabel } }
					class="my-auto ml-2 text-light"
					/>
				</div>
				<div class="flex-fill d-flex p-2">
					<Pagination
					v-on:change-page={ (e:Event) => this.$emit(HeaderEvents.SetPage, e) }
					v-on:change-limit={ (e:Event) => this.$emit(HeaderEvents.SetLimit, e) }
					page={ this.pagination.page }
					limit={ this.pagination.limit }
					rows={ this.pagination.rows }
					size="sm"
					class="ml-auto my-auto"
					/>
				</div>
			</div>
		);
	}
});


const VRule = defineComponent({
	render(){
		return <div class="border-right border-secondary"/>
	}
})

const RefreshButton = defineComponent({
	emits:[ "click" ],
	render(){
		return (
			<button
			v-on:click={ (e:Event) => this.$emit("click", e) }
			class="btn btn-md btn-outline-light shadow-none rounded-0 border-0">
				<Icon name="refresh"/>
			</button>
		);
	}
});
