import { TransitionValidator } from "@/components.edit/edit-graph";

export const AllowInputOutput:TransitionValidator = {
	validator: (d) => !(d.from.port.type === "input" && d.to.port.type === "output")
};

export const PreventOutputOutput:TransitionValidator = {
	validator: (d) => !(d.from.port.type === "output" && d.to.port.type === "output")
};

export const PreventInputInput:TransitionValidator = {
	validator: (d) => !(d.from.port.type === "input" && d.to.port.type === "input")
};

export const PreventSelfConnection:TransitionValidator = {
	validator: (d) => !(d.from.node.id === d.to.node.id)
};