// vendor
import { Ref, ref, computed, watch, WritableComputedRef } from "@vue/composition-api";

type UserPrefs = {
	lang?:string;
	menuOpen?:boolean;
	tagsCollapsed?:boolean;
	studyListLimit?:number;
	assetListLimit?:number;
	assetGridLimit?:number;
	sessionListLimit?:number;
}

const defaultPrefs:UserPrefs = { // note: necessary to let vue know props exist
	lang:"en",
	menuOpen:true,
	tagsCollapsed:false,
	studyListLimit:10,
	sessionListLimit:20,
	assetListLimit:10,
	assetGridLimit:10,
};

let loaded = false;

let _prefs:Ref<UserPrefs>;

const toBool = (v:any) => Boolean(v);

const getComputedPref = <T>(ob:any, k:string, fn?:(v:any) => T):WritableComputedRef<T> => {
	const get = () => fn ? fn(ob[k]) : ob[k]
	const set = (v:any) => ob[k] = fn ? fn(v) : v
	return computed({ get, set });
}

const readLS = <T>(key:string, fn:(v:T) => void) =>{
	const d = localStorage.getItem(key);
	if(!d){ return; }
	try {
		fn(JSON.parse(d) as T);
	}
	catch(err){}
}

const savePrefs = () => {
	localStorage.setItem("user.prefs", JSON.stringify(_prefs.value));
};

export const useUserPrefs = () => {

	const init = async () => {
		let old:any = {};
		readLS<UserPrefs>("user.prefs", v => old = v);
		_prefs = ref<UserPrefs>({
			...defaultPrefs,
			...old
		});
		watch(_prefs, savePrefs, {  deep:true }); // save on setting changes
	};

	if(!loaded){
		init();
		loaded = true;
	}

	// const menuOpen = computed({
	// 	get:() => Boolean(_prefs.value.menuOpen),
	// 	set: v => _prefs.value.menuOpen = v
	// });

	const menuOpen = getComputedPref(_prefs.value, "menuOpen", toBool);
	const tagsCollapsed = getComputedPref(_prefs.value, "tagsCollapsed", toBool);
	
	// asset
	const assetListLimit = getComputedPref(_prefs.value, "assetListLimit", (v:any) => v ? v : 10);
	const assetGridLimit = getComputedPref(_prefs.value, "assetGridLimit", (v:any) => v ? v : 10);
	// study
	const studyListLimit = getComputedPref(_prefs.value, "studyListLimit", (v:any) => v ? v : 10);
	const sessionListLimit = getComputedPref(_prefs.value, "sessionListLimit", (v:any) => v ? v : 10);


	const lang = getComputedPref(_prefs.value, "lang", v => v);


	return {
		lang,
		menuOpen,
		tagsCollapsed,
		studyListLimit,
		assetListLimit,
		assetGridLimit,
		sessionListLimit,
	};

};