











































// vendor
import { defineComponent, computed, ref, onMounted, onUnmounted, PropType } from "@vue/composition-api";
// project
import { GalleryItem } from "@/psychlab/types/gallery";
import * as MathUtils from "@/utils/math";
// local
import * as Utils from "../../ts/utils";
import { default as LoadSpinner } from "./load-spinner.vue";

const BGImage = {
	props:[ "url" ],
	template:`
	<div
	class="w-100 h-100 bgimg d-flex noevents rounded-circle"
	:style="{ backgroundImage:'url(' + url + ')' }"
	/>`
};

const badgeSize = 20;

type State = {
	icon:string;
	locked:boolean;
	variant:string;
	borderVariant?:string;
	borderWidth?:string;
};

const variantInverse:{ [k:string]:(string|undefined) } = {
	"light":"dark",
	"warning":"dark",
};

export default defineComponent({

	emits:[
		"select"
	],
	props:{
		src:String,
		state:Object as PropType<State|null|undefined>,
		active:Boolean,
		loading:Boolean,
		w:{
			type:Number,
			default:0,
		},
		h:{
			type:Number,
			default:0,
		},
	},
	components:{
		"bg-image":BGImage,
		LoadSpinner,
	},
	setup(props, context){
		const displayState = computed(() => {
			const state = props.state;
			if(!state){ return null; }

			const dir = MathUtils.getUnitPos(45);
			const statePos = {
				x:props.w * 0.5,
				y:props.h * 0.5,
			};
			const variant = state.variant || "";
			const textVariant = variantInverse[variant] || "light";
			const borderVariant = state.borderVariant || "transparent";
			const borderWidth = state.borderWidth || 1;
			statePos.x += dir.x * props.w * 0.5;
			statePos.y += dir.y * props.h * -0.5;
			statePos.x -= badgeSize * 0.5;
			statePos.y -= badgeSize * 0.5;
			return {
				...state,
				w:badgeSize,
				h:badgeSize,
				x:statePos.x,
				y:statePos.y,
				textVariant,
				borderVariant,
				borderWidth,
			}
		});

		const locked = computed(() => {
			return props.state ? props.state.locked : false;
		});

		const select = () => {
			context.emit("select");
		};

		return {
			displayState,
			locked,
			select,
		};
	}
});

