// vendor
import { AxiosInstance } from "axios";
// project
import { http } from "@/http";
import { stringifyQuery } from "@/utils/http";
const HTTP:AxiosInstance = http;

export const rebuildSessionState = async (query = {}):Promise<void> => {
	let route = `ops/sessions/rebuild-state${stringifyQuery(query)}`;
	await HTTP.post(route);
};