var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modalElement",attrs:{"title":_vm.translate('studies.action.updateLabels'),"size":"sm","button-size":"sm","ok-title":_vm.translate('prompts.action.confirm'),"cancel-title":_vm.translate('prompts.action.cancel'),"lazy":true},on:{"ok":_vm.handleConfirm,"hide":_vm.handleModalHide,"shown":_vm.handleModalShown}},[(_vm.isInitialLoading || _vm.isLoadingUpdate)?[_c('b-spinner',{staticClass:"modal-loading-spinner",attrs:{"type":"border","variant":"success"}})]:_c('div',{staticClass:"labels-modal-content"},[_c('CreateLabel',{on:{"onNewLabel":_vm.handleNewLabel}}),_c('div',{staticClass:"content-header"},[_c('h4',{staticClass:"list-title"},[_vm._v(" "+_vm._s(_vm.translate("studies.label.labels"))+" ")])]),(_vm.allLabels.length)?_vm._l((_vm.allLabels),function(label){return _c('div',{key:("add-label-" + (label._id)),staticClass:"labels-row-wrapper",attrs:{"set":(_vm.intermediate = _vm.intermediateSelection.includes(
							label._id
						))}},[_c('CustomCheckbox',{attrs:{"value":_vm.selection.includes(label._id),"intermediate":_vm.intermediateSelection.includes(label._id)},on:{"click":function (value) { return _vm.handleCheckboxClick(label._id, value); }}},[(!_vm.isEditing(label._id))?_c('div',{staticClass:"checkbox-label-wrapper"},[_c('label-badge',{attrs:{"color":label.color,"label":label.name,"removable":false}})],1):_vm._e()]),(_vm.isEditing(label._id))?_c('div',{staticClass:"editing"},[(_vm.editingErrorMessage !== null)?[_c('div',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.editingErrorMessage)+" ")])]:[(
									_vm.editingOperation ===
									_vm.EditingOperation.CHANGE
								)?[_c('b-input',{attrs:{"type":"text","size":"sm","disabled":_vm.editingLoadingResponse},model:{value:(_vm.editingLabelText),callback:function ($$v) {_vm.editingLabelText=$$v},expression:"editingLabelText"}}),_c('b-input',{staticClass:"colorpicker",attrs:{"type":"color","disabled":_vm.editingLoadingResponse},model:{value:(_vm.editingLabelColor),callback:function ($$v) {_vm.editingLabelColor=$$v},expression:"editingLabelColor"}})]:(
									_vm.editingOperation ===
									_vm.EditingOperation.DELETE
								)?[_c('div',{staticClass:"delete-message"},[_vm._v(" "+_vm._s(_vm.translate("studies.action.deleteStudyLabel"))+" ")])]:_vm._e()]],2):_vm._e(),_c('div',{staticClass:"label-actions"},[(!_vm.isEditing(label._id))?[_c('div',{staticClass:"action-button",on:{"click":function($event){return _vm.setEditing(
										label._id,
										_vm.EditingOperation.CHANGE
									)}}},[_c('Icon',{attrs:{"name":"mdi.pencil"}})],1),_c('div',{staticClass:"action-button",on:{"click":function($event){return _vm.setEditing(
										label._id,
										_vm.EditingOperation.DELETE
									)}}},[_c('Icon',{attrs:{"name":"mdi.delete"}})],1)]:_vm._e(),(_vm.isEditing(label._id))?[(_vm.editingLoadingResponse)?[_c('b-spinner',{attrs:{"small":""}})]:[_c('div',{staticClass:"action-button",on:{"click":_vm.confirmEditing}},[_c('icon',{attrs:{"name":"mdi.check"}})],1),_c('div',{staticClass:"action-button",on:{"click":_vm.discardEditing}},[_c('icon',{attrs:{"name":"mdi.close"}})],1)]]:_vm._e()],2)],1)}):[_c('div',{staticClass:"modal-no-data-wrapper"},[_vm._v(" "+_vm._s(_vm.translate("studies.message.noStudyLabels"))+" ")])],(_vm.hasErrorUpdate)?_c('div',{staticClass:"update-error-message"},[_vm._v(" "+_vm._s(_vm.translate("studies.message.unableToSaveLabels"))+" ")]):_vm._e()],2)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }