






























import { defineComponent, computed } from "@vue/composition-api";
import { useTranslate } from "@lang";
import { Icon, AbsBox } from "@ui";

export default defineComponent({
	props:{
		translateKey:{
			type:String,
			default:""
		}
	},
	components:{
		Icon,
		AbsBox,
	},
	setup(props){
		
		const { translate } = useTranslate();

		const text = computed(() => translate(props.translateKey));

		return {
			text,
		};
	}
});

