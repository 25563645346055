import { http } from "@/http";
import { Study } from "@/psychlab/types";
import { stringifyQuery } from "@/utils/http";

type Dictionary<T> = { [k:string]:T };

export const getStudies = async(q:Dictionary<any> = {}):Promise<Study[]> => {
	// console.log(data);
	const { data } = await http.get(`studies${stringifyQuery(q)}`);
	return data;
};

export const getStudiesPaginated = async(q:Dictionary<any> = {}):Promise<{ studies:Study[], count:number }> => {
	const res = await http.get(`studies${stringifyQuery(q)}`);
	const count = Number(res.headers["pagination-count"]);
	return { studies:res.data, count };
};