/** @format */

import { http } from "@/http";
import { SessionLabel } from "@/psychlab/types";

type CreateArgs = {
	name: string;
	color?: string;
};

export const createSessionLabel = async (
	study: string,
	args: CreateArgs,
): Promise<SessionLabel> => {
	const { data } = await http.post(`studies/${study}/labels`, args);
	return data;
};
