import * as SnapSVG from "snapsvg";
declare let Snap: typeof SnapSVG;

abstract class GUIHandler {
	public abstract created(root:SnapSVG.Paper):void;
}

export class NodeIcon extends GUIHandler {

	constructor(config:{ icon:string, size:string }){
		super();
		this._icon = config.icon;
		this._size = config.size;
	}

	public created(root:SnapSVG.Paper){
		this._element = root.el("svg", {}) as SnapSVG.Paper;
		this._element.node.style["overflow"] = "visible";
		const t = `<svg style="overflow:visible">
			<foreignObject width="1" height="1" style="overflow:visible">
				<div style="position:absolute;transform:translate(-50%, -50%)">
					<span class="mdi mdi-${this._icon} text-lightx" style="font-size:${this._size}"></span>
				</div>
			</foreignObject>
		</svg>`;
		this._element.group().append(Snap.parse(t) as any);
	}

	private _icon:string;
	private _size:string;
	private _element:SnapSVG.Paper|null = null;
}

const nodeTemplate = `
<svg style="overflow:visible" x="-50" y="-50">
	<rect rx="100" ry="100" width="100" height="100" strokeWidth="1" stroke="white" fill="#117a8b"></rect>
</svg>
`;

export class NodeBackground extends GUIHandler {

	constructor(config:{ template?:string }){
		super();
		this._template = config.template || nodeTemplate;

	}

	public created(root:SnapSVG.Paper){
		root.append(Snap.parse(this._template) as any)
	}

	private _template:string;
}