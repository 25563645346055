




























// vendor
import { defineComponent, computed, ref, PropType } from "@vue/composition-api";
// project
import * as AppModal from "@/AppModal";


export default defineComponent({

	emits:[ "input" ],
	props:{
		value:{
			type:String,
			default:"",
		},
		label:{
			type:String
		},
	},
	setup(props, context){

		const val = computed({
			get: () => props.value,
			set:v => context.emit("input", v)
		});

		const findIcon = () => {
			AppModal.findIcon({ value:props.value }, (v:string) => {
				val.value = v;
			});
		};

		return {
			val,
			findIcon,
		};
	}
});

