


















































































// vendor
import {
	defineComponent,
	PropType,
	computed,
	ref,
} from "@vue/composition-api";

export default defineComponent({
	emits:[ "input" ],
	props: {
		options: {
			type: Array as PropType<string[]>,
			required: true,
		},
		value: {
			type: Number,
		},
	},
	setup(props, context) {
		const hovered = ref(-1);

		const setIndex = (v: number) => {
			context.emit("input", v);
		};

		const pickedLabel = computed(() => {
			const i = props.value === undefined ? -1 : props.value;
			if (i < 0) {
				return "";
			}
			return props.options[i] || (i + 1).toString();
		});

		const hasValue = computed(() => {
			return props.value !== undefined && props.value > -1;
		});

		const getClass = (index: number) => {
			return {
				"bg-light": props.value === index,
				"border-secondary": props.value !== index,
				"bg-info border-info":
					hovered.value === index && props.value !== index,
			};
		};

		const getLabel = (index: number) => {
			return props.options[index];
		};

		const getTooltip = (index: number) => {
			return {
				title: props.options[index] || (index + 1).toString(),
				noninteractive: true,
				delay: 0,
			};
		};

		return {
			hasValue,
			setIndex,
			pickedLabel,
			hovered,
			getClass,
			getTooltip,
			getLabel,
		};
	},
});
