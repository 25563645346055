import { defineComponent, computed, ref } from "@vue/composition-api";
import { useTags } from "@/hooks/useTags";
import { getAssetTypes } from "@/psychlab/meta";
import { useTranslate } from "@lang";
import { Date, Heading, Icon } from "@ui";
import { Provide } from "@api/assets";

enum Translations {
	NoActivity = "message.noActivity",
}

enum RouteNames {
	ViewAsset = "view-asset",
}

const config = {
	colors:{
		"Form":"#9471fe",
		"Scene":"#0a9931",
		"Gallery":"#f07705",
		"Graph":"#117a8b"
	} as Record<string,string>
};

const assetTypes = getAssetTypes().reduce((map, t) => {
	map[t.name] = t;
	return map;
}, {} as Record<string,any>)


const getTypeColor = (t:string) => config.colors[t] || "transparent";

const getTypeIcon = (t:string) => assetTypes[t]?.icon || "circle-outline";

const getAssetRoute = (id:string) => ({ name:RouteNames.ViewAsset, params:{ assetId:id } })

export const RecentAssets = defineComponent({
	components:{
		Date
	},
	setup(){

		const { translate } = useTranslate();

		const tagHooks = useTags();

		const days = computed(() => 7);

		const assets = ref<any[]>([]);

		const loading = ref(false);

		const getAssetTags = (id:string) => tagHooks.getItemTags(id);

		const init = async () => {
			loading.value = true;
			try { assets.value = await Provide.assets.getRecentlyModifiedAssets({ days:days.value }) }
			catch{}
			loading.value = false;
		};

		init();

		return {
			days,
			assets,
			loading,
			translate,
			getAssetTags,
		};
	},
	render(){


		if(!this.loading && !this.assets.length){
			return <NoActivityMessage days={this.days}/>
		}

		const dassets = this.assets.map(a => {
			return (
				<AssetItemCard
				key={a._id}
				aid={a._id}
				name={a.name}
				lastModified={a.lastModified}
				icon={ getTypeIcon(a.type)}
				color={ getTypeColor(a.type)}
				tags={ this.getAssetTags(a._id) }
				/>
			);
		});

		return (
			<div class="list-group">
				{dassets}
			</div>
		)
	}
});

const NoActivityMessage = defineComponent({
	props:{
		days:{ type:Number, default:0 },
	},
	setup(props){

		const { translate } = useTranslate();

		const text = computed(() => {
			return translate(Translations.NoActivity).replace("$n", props.days.toString())
		});

		return {
			text
		};
	},
	render(){
		return (
			<div class="bg-dark p-3 d-flex rounded">
				<Heading size={6} class="text-light m-auto">
					{this.text}
				</Heading>
			</div>
		);
	}
});

const AssetItemCard = defineComponent({
	props:{
		aid:String,
		icon:String,
		name:String,
		color:String,
		lastModified:null,
		tags:null,
	},
	setup(props){
		return {
			route: computed(() => getAssetRoute(props.aid || ""))
		};
	},
	render(){

		const dtags = this.tags.map((t:any) => (
			<AssetTag
			key={t._id}
			name={t.name}
			color={t.color}
			/>
		));

		return (
			<router-link
			class="d-flex p-0 bg-dark text-light list-group-item"
			to={this.route}
			style="text-decoration:none;"
			>
				<div class="p-3 d-flex" style={`color:${this.color};`}>
					<Icon name={this.icon} class="m-auto"/>
				</div>
				<div class="flex-fill d-flex flex-column py-1 pr-3 pl-2x">
					<span>
						{ this.name }
					</span>
					<small class="mt-auto" style="font-size:0.6em;opacity:0.5">
						<Date date={this.lastModified}/>
					</small>
				</div>

				<div class="p-3 d-flex">
					{dtags}
				</div>
			</router-link>

		);
	}
});


const AssetTag = defineComponent({
	props:{ name:String, color:String, },
	render(){
		return (
			<div
			style={`width:1em;height:1em; background:${this.color}`}
			class="rounded ml-1"
			v-b-tooltip={{
				title:this.name,
				interactive:false,
				delay:0,
				animation:false,
			}}
			/>
		)
	}
});