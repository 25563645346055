import { useTags } from "@/hooks/useTags";
import * as AppModal from "@/AppModal";
import { useTranslate } from "@lang";

enum Translations {
	NewTag = "tags.label.newTag",
}

export const useTagCreation = () => {

	const { translate } = useTranslate();
	const { sortedTags, createTag } = useTags();

	const create = async() => {
		AppModal.editText({
			title:translate(Translations.NewTag),
			value:translate(Translations.NewTag),
			validator:v => v.length > 2,
		}, (v) => createTag(v));
	};

	return {
		sortedTags,
		createTag:create,
	};

};