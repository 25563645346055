



























import { defineComponent, computed } from "@vue/composition-api";

export default defineComponent({
	emits: ["input"],
	props: {
		value: {
			type: String,
			default: "en",
		},
	},
	setup(props, context) {
		const val = computed({
			get() {
				return props.value;
			},
			set(v) {
				context.emit("input", v);
			},
		});

		const options = computed(() => {
			return [
				{ key: "en", flag:"/icons/flag-gb.svg", tooltip:"English" },
				{ key: "is", flag:"/icons/flag-is.svg", tooltip:"Icelandic" },
			];
		});

		return {
			val,
			options,
		};
	},
});
