import { defineComponent, computed } from "@vue/composition-api";
import { default as Router } from "vue-router";
import { getAssetTypes } from "@/psychlab/meta";
import { useTranslate } from "@lang";
import { Icon } from "@ui";
import { useAssets } from "@/hooks/useAssets";
import { useTags } from "@/hooks/useTags";
import * as AppModal from "@/AppModal";
import * as StudyAPI from "@/services/api/studies";

export const GlobalAdd = defineComponent({
	props:{
		size:{
			type:Number,
			default:1.75,
		}
	},
	components:{
		Icon,
	},
	setup(props, context){

		const { translate } = useTranslate();

		const btnStyle = computed(() => {
			return {
				width:`${props.size}em`,
				height:`${props.size}em`,
				fontSize:`${props.size * 0.8}em`,
				borderRadius:"50%",
				borderWidth:"2px !important",
			};
		});

		const onAdd = (e:Event) => {
			AppModal.context(e, [
				...getAssetOptions(context.root.$router, translate),
				{
					name:translate("studies.label.study"),
					icon:"mdi.rocket mdi-rotate-45",
					fn(){
						createStudy(context.root.$router);
					}
				}
			]);
		};

		return {
			btnStyle,
			onAdd,
		};
	},
	render(){
		return (
			<button
			v-on:click={this.onAdd}
			class="btn btn-info text-dark shadow-none p-0 d-flex"
			style={this.btnStyle}
			>
			<Icon name="plus-thick" class="m-auto"/>
			</button>
		)
	}
});

const assetAlias:Record<string,string|undefined> = {
	"Graph":"Blueprint",
};

const assetOrder:Record<string,number|undefined> = {
	"Graph":1,
};

const getAssetOptions = (router:Router, translate:(v:string) => string) => {
	const types = getAssetTypes().filter(t => !t.disabled);
	return types.map(at => {
		const name = assetAlias[at.name] || at.name;
		return {
			name:translate(`assets.label.${name.toLowerCase()}`),
			icon:at.icon,
			fn:() => createAsset(at.name, router),
			order:assetOrder[at.name] !== undefined ? assetOrder[at.name] : 2,
		};
	});
};

const createStudy = (router:Router) => {
	AppModal.createStudy({}, async (r) => {
		const { name, study, version, language, openDashboard, auth } = r;
		const s = await StudyAPI.createStudy({ name, blueprint:`${study}@${version}`, language, auth });
		if(openDashboard){
			router.push(getStudyRoute(s._id));
		}
	});
};

const createAsset = (type:string, router:Router) => {
	const assetHooks = useAssets();
	const tagHooks = useTags();
	AppModal.createAsset({ type }, async (r) => {
		const { name, tags, openEditor } = r;
		const a = await assetHooks.createAsset(type, name);
		await Promise.all(tags.map(t => tagHooks.tagItem(t, a._id, "asset")));
		if(openEditor){
			router.push(getAssetRoute(a._id));
		}
	});
};

const getAssetRoute = (aid:string) => ({ name:"asset.edit", params:{ assetId:aid }})
const getStudyRoute = (sid:string) => ({ name:"Run.Group", params:{ groupId:sid }})