
















import { defineComponent, PropType, computed, ref } from "@vue/composition-api";
import { ResponseData } from "../ResponseData";
import { default as ErrorOverlay } from "./error-overlay.vue";
import { ErrorBox } from "@ui";
import { RenderCheckboxes } from "@form-ui";
import { Checkboxes } from "@psychlab/types/form";
import { getBaseProps } from "./getBaseProps";
import { useRouteTranslate } from "@lang";

export default defineComponent({

	emits:[ "input" ],
	props:{
		value:{
			type:Array as PropType<number[]>
		},
		...getBaseProps<Checkboxes>(),
		config:{
			type:Object as PropType<Checkboxes>,
			required:true,
		},
	},
	components:{
		RenderCheckboxes,
		ErrorOverlay,
		ErrorBox
	},
	setup(props, context){

		const { translate } = useRouteTranslate(context);
		
		const pristine = ref(true);

		const val = computed({
			get:() => props.value,
			set:v => context.emit("input", v)
		});

		const state = computed(() => val.value && val.value.length > 0);

		const isValid = () => {
			pristine.value = false;
			return state.value;
		};

		const getChannels = (prefix:string):ResponseData<number>[] => {
			const v = val.value;
			if(!v){ return []; }
			return props.config.settings.options.map((option, index) => {
				const toggled = v.findIndex(v => v === index) > -1;
				return {
					key: `${prefix}/${index}`,
					data: toggled ? 1 : 0
				};
			});
		};

		const displayOptions = computed(() => {
			return props.config.settings.options.map((o, i) => {
				return {
					value: i,
					text: o
				}
			});
		});

		const args = computed(() => ({
			title:!props.config.hideName ? props.config.name : '',
			body:props.config.body,
			theme:props.theme,
		}));

		const init = () => {
			if(!val.value){ val.value = []; }
		};

		init();

		return {
			pristine,
			state,
			val,
			displayOptions,
			isValid,
			args,
			getChannels,
			translate,
		};
	}
});
