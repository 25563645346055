import { defineComponent, PropType, ref } from "@vue/composition-api";
import { SceneData } from "./types/SceneData";
import ImportModal from "../vue/import-modal.vue";
import { useTranslate } from "@lang";

type ImportModalResult = {
	paths:string
};

export const Toolbar = defineComponent({
	components:{
		ImportModal
	},
	emits:[ "set-paths" ],
	props:{
		data:{
			type:Object as PropType<SceneData>,
			required:true
		}
	},
	setup(props, context){

		const {
			translate,
		} = useTranslate();

		const importModal = ref<any>();

		const configureImports = () => {
			if(!importModal.value){ return; }
			importModal.value.open({
				paths: props.data.paths
			}, onChangeImports);
		};

		const onChangeImports = (r:ImportModalResult) => {
			context.emit("set-paths", r.paths);
		};

		return {
			importModal,
			translate,
			configureImports
		};
	}
});