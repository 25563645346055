

















































// vendor
import { defineComponent, computed, ref } from "@vue/composition-api";

const actions = [
	{
		name: "add",
		icon: "mdi.plus-thick",
		tooltip: "Add Widget",
		align: "left",
	},
	// {
	// 	name: "export",
	// 	icon: "mdi.download",
	// 	tooltip: "Export widgets...",
	// 	align: "left",
	// },
	// {
	// 	name: "import",
	// 	icon: "mdi.upload",
	// 	tooltip: "Import widgets...",
	// 	align: "left",
	// },
	{
		name: "refresh",
		icon: "mdi.refresh",
		tooltip: "Refresh all widgets",
		align: "right",
	},
];

export default defineComponent({
	emits: actions.map((a) => a.name),
	props:{
		editing:{
			type:Boolean
		}
	},
	setup(props, context) {
		const onClick = (name: string, e: Event) => context.emit(name, e);
		const leftActions = computed(() =>
			actions.filter((a) => a.align === "left"),
		);
		const rightActions = computed(() =>
			actions.filter((a) => a.align === "right"),
		);
		return {
			onClick,
			leftActions,
			rightActions,
		};
	},
});
