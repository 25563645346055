import { WizardContext } from "../types/WizardContext";
import * as Utils from "./utils";

export const ComputeChannelAverages = {
	name: "ComputeChannelAverages",
	description:{
		en:"Computes the average response for every numeric data field in study.",
		is:"Reiknar meðalgildi fyrir hvern einasta talnadálk í rannsókn.",
	},
	isUsable(ctx:WizardContext){
		return ctx.channels.findIndex(Utils.canAverageChannel) > -1;
	},
	parameters: {

	},
	output:{
		count:{
			type:"number"
		},
		labels:{
			type:"string[]"
		},
		values:{
			type:"number[]"
		}
	}
};