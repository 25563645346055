import { PhraseDictionary } from "../types";
import { mergeDictionaries } from "../mergeDictionaries";

const label:PhraseDictionary = {
	"computeChannelAverage":{
		en:"Compute Field Average",
		is:"Reikna meðalgildi dálks",
	},
	"computeGroupAverage":{
		en:"Compute Field Group Average",
		is:"Reikna meðalgildi fyrir dálkahóp",
	},
	"countChannelOptions":{
		en:"Count Unique Values for Field",
		is:"Telja einstök svör fyrir dálk",
	},
	"countChannelValue":{
		en:"Count Value in Field",
		is:"Telja gildi fyrir dálk",
	},
	"countStudySessions":{
		en:"Count Sessions",
		is:"Telja keyrslur",
	},
	"findMaxChannelAverage":{
		en:"Find Max Average in Field Group",
		is:"Finna hæsta meðaltal Í dálkahóp",
	},
	"findMinChannelAverage":{
		en:"Find Min Channel Average",
		is:"Finna lægsta meðaltal Í dálkahóp",
	},
	"computeChannelAverages":{
		en:"Compute Averages for Grouped Fields",
		is:"Reikna meðaltöl fyrir hóp dálk",
	},
	"countGroupBooleans":{
		en:"Count Checkboxes",
		is:"Telja merkt tékkbox",
	},
	"number":{
		en:"Number",
		is:"Tala",
	},
	"string":{
		en:"String",
		is:"Texti",
	},
	"channelGroup":{
		en:"Field Group",
		is:"Dálkshópur",
	},
	"configureDataSource":{
		en:"Data Source",
		is:"Gögn",
	},
	"chooseRenderTemplate":{
		en:"Render Template",
		is:"Útlitssnið",
	},
	"bindDataToTemplate":{
		en:"Bind Data",
		is:"Binda gögn",
	},
	"confirmConfiguration":{
		en:"Confirm",
		is:"Staðfesta",
	},
	"dataSources":{
		en:"Data Sources",
		is:"Gögn",
	},
	"template":{
		en:"Template",
		is:"Snið",
	},
	"widgetName":{
		en:"Widget Name",
		is:"Nafn",
	},
	"textColor":{
		en:"Text Color",
		is:"Textalitur",
	},
	"color":{
		en:"Color",
		is:"Litur",
	},
	"icon":{
		en:"Icon",
		is:"Tákn",
	},
	"iconColor":{
		en:"Icon Color",
		is:"Táknlitur",
	},
	"backgroundColor":{
		en:"Background Color",
		is:"Backgrunnslitur",
	},
	"channel":{
		en:"Channel",
		is:"Rás",
	},
	"dataParameters":{
		en:"Data Parameters",
		is:"Gagnafæribreytur"
	},
	"templateProperties":{
		en:"Template Properties",
		is:"Sniðstillingar"
	},
	"title":{
		en:"Title",
		is:"Titill",
	},
	"borderColor":{
		en:"Border Color",
		is:"Umgjarðarlitur",
	},
	"sort":{
		en:"Sort",
		is:"Röðun",
	},
	"type":{
		en:"Type",
		is:"Tegund",
	},
};

const action:PhraseDictionary = {
	"createWidget":{
		en:"Create Widget",
		is:"Búa til skjától",
	},
	"unbind":{
		en:"Unbind",
		is:"Afbinda",
	},
	"renameWidget":{
		en:"Rename Widget",
		is:"Endurskýra skjától"
	},
	"deleteWidget":{
		en:"Delete Widget",
		is:"Eyða skjátóli"
	}
};

const message:PhraseDictionary = {
	"dataIncompatibleWithTemplate":{
		en:"Data is incompatible with template.",
		is:"Gögn passa ekki við snið."
	}
};

export const widgets = mergeDictionaries("widgets", { label, action, message });