import InApp from "detect-inapp";

const inApp = new InApp(navigator.userAgent || navigator.vendor);

export const isMobile = () => {
	return inApp.isMobile;
};

export const isIOS = () => {
	const ua = getUA();
	const phone = ua.match(/iphone/i) !== null;
	const tablet = ua.match(/ipad/i) !== null;
	return phone || tablet;
};

// https://stackoverflow.com/questions/31569518/how-to-detect-facebook-in-app-browser
export const isFacebookApp = () => {
	const ua = getUA();
	return (ua.indexOf("fban") > -1) || (ua.indexOf("fbav") > -1) || (ua.indexOf('instagram') > -1);
};

const getUA = () => {
	return (navigator.userAgent || navigator.vendor).toLowerCase();
};