
const preservatives = [ "t" ];

export const preserveQuery = async (to:any, from:any, next:any) => {

	if(to.name === from.name){
		return next();
	}
	
	let modified = false;
	preservatives.forEach(qk => {
		if(from.query[qk] !== undefined && to.query[qk] === undefined){
			to.query[qk] = from.query[qk];
			console.log(qk);
			modified = true;
		}
	})

	if(modified){
		return next({ query:to.query, replace:true})
	}

	next();
};