import { defineComponent } from "@vue/composition-api";
import { Icon } from "@ui";
import { renderRaw } from "@/AppModal";

export const ContextButton = defineComponent({
	emits:[ "click" ],
	render(){
		return (
			<button
			v-on:click={ (e:Event) => this.$emit("click", e) }
			variant="outline-secondary"
			style="width:1.75rem;height:1.75rem;display:inline-block;"
			class="btn btn-outline-secondary shadow-none rounded-circle my-1 p-0 border-0 text-light m-auto"
			>
				<Icon name="dots-vertical" class="m-auto" style="font-size:1rem"/>
			</button>
		);
	}
	
});

export const DescriptionButton = defineComponent({
	props:{
		name:{ type:String, default:"", },
		description:{ type:String, default:"", },
	},
	setup(props){
		const openDescription = () => {
			renderRaw({
				title:props.name,
				content:props.description,
				okText:"Close",
				size:"lg",
				variant:"light"
			});
		};
		return { openDescription };
	},
	render(){

		const disabled = !Boolean(this.description);

		const bv = `btn-${disabled ? 'outline-secondary' : 'success'}`;

		const cls = `btn rounded-circle my-1 p-0 border-0 shadow-none text-light ${bv}`;

		return (
			<button
			v-on:click={ this.openDescription }
			class={ cls }
			disabled={ disabled }
			style="width:1.75rem;height:1.75rem;display:inline-block;"
			>
				<Icon name="information-outline" class="m-auto" style="font-size:0.9rem"/>
			</button>
		)
	}
})