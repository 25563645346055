


















// vendor
import { defineComponent, computed, ref } from "@vue/composition-api";

export default defineComponent({
	props:{
		text:{
			type:String,
			default:"",
		},
		opacity:{
			type:Number,
			default:0.5,
		},
		fontSize:{
			type:Number,
			default:1,
		},
		variant:{
			type:String,
			default:"light",
		},
		placement:{
			type:String,
			default:"right",
		},
	},
	setup(props){

		const iconStyle = computed(() => {
			return {
				fontSize:`${props.fontSize}em`,
				opacity:props.opacity,
			};
		});

		return {
			iconStyle,
		};
	}
});

