import { PropMap } from "./PropMap";
import { EventBus } from "./EventBus";
import * as SnapSVG from "snapsvg";
import { Vector2D } from "../types";

export class SnapOffsetDrag {

	constructor(dragTarget:SnapSVG.Element){
		dragTarget.drag(this.move.bind(this), this.start.bind(this), this.stop.bind(this) );
	}

	public onDrag(fn:(v:Vector2D) => void){
		this._listeners.on("drag", fn);
	};

	public configure(ob:{ [k:string]:{} }){
		this._props.configure(ob);
	}

	private _listeners = new EventBus<DragEvents>();
	private _lastx:number = 0;
	private _lasty:number = 0;
	private _moving:boolean = false;

	private _props:PropMap = new PropMap({
		"button": {
			value: 1,
			validator: v => typeof(v) === "number"
		}
	});

	private move(dx:number, dy:number, e:any) {
		
		if(!this._moving) { return; }
		// console.log("fuuuu")
		let diffx = dx - this._lastx;
		let diffy = dy - this._lasty;
		this._listeners.emit("drag", { x: diffx, y: diffy });
		this._lastx = dx;
		this._lasty = dy;
	}

	private start(x:number, y:number, e:any) {
		if(e.buttons === this._props.getValue("button")){
			this._moving = true;
		}
	}

	private stop() {
		this._lastx = 0;
		this._lasty = 0;
		this._moving = false;
	}
}


type EventHandler<T, R> = (p:T) => R;
interface DragEvents {
	"drag":EventHandler<Vector2D,void>
}