import * as FormData from "@/psychlab/types/form";
import * as DisplayData from "../types/DisplayForm";

export const getDisplayForm = (
	prefix: string,
	form: FormData.Form,
): DisplayData.DisplayForm => {
	const items: DisplayData.DisplayItem[] = [];
	flattenForm(form).forEach((item) => convertItem(prefix, item, items));
	return { items };
};

const flattenForm = (form: FormData.Form): FormData.FormItem<any>[] => {
	const items: FormData.FormItem<any>[] = [];
	(form.sectionOrder || form.sections.map(s => s.id))
		.map((sectionId) => form.sections.find((s) => sectionId === s.id))
		.forEach((section) => {
			if (!section) {
				return;
			}
			(section.itemOrder || section.items.map(it => it.id))
				.map((itemId) => section.items.find((it) => it.id === itemId))
				.forEach((item) => {
					if (!item) {
						return;
					}
					items.push(item);
				});
		});
	return items;
};

const convertItem = (
	prefix: string,
	item: FormData.FormItem<any>,
	result: DisplayData.DisplayItem[],
) => {
	const fn = displayFactories[item.type];
	fn(prefix, item, result);
};

type Factory = (
	keyPrefix: string,
	item: FormData.FormItem<any>,
	result: DisplayData.DisplayItem[],
) => void;
const displayFactories: Record<string,Factory> = {};

displayFactories["LinearScale"] = (
	keyPrefix: string,
	data: FormData.FormItem<any>,
	result: DisplayData.DisplayItem[],
) => {
	const item = data as FormData.LinearScale;
	item.settings.rows.forEach((row, index) => {
		result.push({
			title: `${item.name}`,
			subtitle: row,
			key: `${keyPrefix}/${item.id}/${index}`,
			type: "LinearScaleRow",
			optional: item.optional,
			options: item.settings.columns,
		} as DisplayData.LinearScaleRow);
	});
};

displayFactories["Answer"] = (
	keyPrefix: string,
	item: FormData.FormItem<any>,
	result: DisplayData.DisplayItem[],
) => {
	result.push({
		key: `${keyPrefix}/${item.id}`,
		type: "Answer",
		title: item.name,
		optional: item.optional,
	} as DisplayData.Answer);
};

displayFactories["Checkboxes"] = (
	keyPrefix: string,
	data: FormData.FormItem<any>,
	result: DisplayData.DisplayItem[],
) => {
	const item = data as FormData.Checkboxes;
	result.push({
		key: `${keyPrefix}/${item.id}`,
		type: "Checkboxes",
		title: item.name,
		optional: item.optional,
		options: item.settings.options,
	} as DisplayData.Checkboxes);
};

displayFactories["MultipleChoice"] = (
	keyPrefix: string,
	data: FormData.FormItem<any>,
	result: DisplayData.DisplayItem[],
) => {
	const item = data as FormData.Checkboxes;
	result.push({
		key: `${keyPrefix}/${item.id}`,
		type: "MultipleChoice",
		title: item.name,
		optional: item.optional,
		options: item.settings.options,
	} as DisplayData.MultipleChoice);
};

displayFactories["Dropdown"] = (
	keyPrefix: string,
	data: FormData.FormItem<any>,
	result: DisplayData.DisplayItem[],
) => {
	const item = data as FormData.Checkboxes;
	result.push({
		key: `${keyPrefix}/${item.id}`,
		type: "Dropdown",
		title: item.name,
		optional: item.optional,
		options: item.settings.options,
	} as DisplayData.Dropdown);
};

displayFactories["HTML"] = (
	keyPrefix: string,
	data: FormData.FormItem<any>,
	result: DisplayData.DisplayItem[],
) => {
	const item = data as FormData.HTML;
	result.push({
		key: `${keyPrefix}/${item.id}`,
		type: "HTML",
		content: item.settings.content,
	} as DisplayData.HTML);
};

displayFactories["Information"] = (
	keyPrefix: string,
	data: FormData.FormItem<any>,
	result: DisplayData.DisplayItem[],
) => {
	const item = data as FormData.Information;
	result.push({
		key: `${keyPrefix}/${item.id}`,
		type: "Information",
		title: item.settings.title,
		text: item.settings.text,
	} as DisplayData.Information);
};
