




















// vendor
import { defineComponent, computed, ref } from "@vue/composition-api";

export default defineComponent({

	emits:[],
	props:{
		tooltip:{
			type:String,
			default:"",
		},
		value:{
			type:String,
			default:"",
		}
	},
	components:{
	},
	setup(){
		
		const input = ref<HTMLInputElement>()

		const copy = () => {
			if(!input.value){ return; }
			input.value.select();
			input.value.setSelectionRange(0, 99999); /* For mobile devices */
			document.execCommand("copy");
		};

		return {
			input,
			copy,
		};
	}
});

