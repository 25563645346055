import { defineComponent } from "@vue/composition-api";
import { TagsGrid } from "@components/tags";
import { ContextHeader } from "@components/nav";
import { Container, Heading, AbsBox } from "@ui";
import { useTranslate } from "@lang";

enum Translations {
	Tags = "tags.label.tags",
}

const TagsPage = defineComponent({
	setup(){
		const { translate } = useTranslate();
		return { translate };
	},
	render(){
		return (
			<AbsBox class="pt-3">
				<ContextHeader>
					<div class="h-100 d-flex px-3">
						<Heading size={2} class="my-auto">
							{ this.translate(Translations.Tags) }
						</Heading>
					</div>
				</ContextHeader>
				<Container>	
					<TagsGrid/>
				</Container>
			</AbsBox>
		);
	}
});

export default TagsPage;