import { set } from "@vue/composition-api";
import { useTranslate } from "@lang";
import { FormItem } from "@psychlab/types/form";
import { openContextMenu, ContextOption } from "@ui";

const isValueType = (t:string) => [
	"Information", "HTML", "Image", "Video"
].indexOf(t) < 0;


export const useItemFlowContext = () => {

	const { translate } = useTranslate();


	const open = (e:Event, item:FormItem<any>) => {

		const optional = Boolean(item.optional);

		const options:ContextOption[] = [];

		options.push({
			name:translate(`forms.label.optional`),
			icon:optional ? "mdi.check" : undefined,
			fn(){
				item.optional = !optional;
			}
		})


		// if(isValueType(item.type)){
		// 	if(optional){
		// 		options.push({
		// 			name:translate("forms.label.setRequired"),
		// 			icon:"mdi.asterisk",
		// 			fn:() => item.optional = false,
		// 		});
		// 	}
		// 	else {
		// 		options.push({
		// 			name:translate("forms.action.setOptional"),
		// 			icon:"mdi.check-circle-outline",
		// 			fn:() => item.optional = true,
		// 		});

		// 	}
		// }
		

		openContextMenu(e, options);

	};



	return {
		open
	};

};