














import { defineComponent, computed, PropType } from "@vue/composition-api";
import { ColorVariant } from "../bootstrap/ColorVariant";
import { AbsBox } from "../boxes";

export default defineComponent({
	props:{
		v:{
			type:String as PropType<ColorVariant>,
			default:"primary",
		},
		label:{
			type:String,
			default:""
		}
	},
	components:{
		AbsBox,
	}
});

