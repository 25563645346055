
















































// vendor
import { defineComponent, computed, ref, PropType } from "@vue/composition-api";
// project
import { Gallery } from "@/psychlab/types/gallery";
// local
import { default as EditFeedbackSettings } from "./edit-feedback-settings.vue";
import { default as EditGallerySettings } from "./edit-gallery-settings.vue";
import { default as EditItems } from "./edit-items.vue";
import { default as InfoTooltip } from "./info-tooltip.vue";
import { useTranslate } from "@lang";

export default defineComponent({

	emits:[],
	props:{
		data:{
			type:Object as PropType<Gallery>,
			required:true
		}
	},
	components:{
		EditFeedbackSettings,
		EditGallerySettings,
		EditItems,
		InfoTooltip,
	},
	setup(){

		const {
			translate
		} = useTranslate();

		return {
			translate,
		};
	}
});

