


















// vendor
import { defineComponent, computed, ref } from "@vue/composition-api";

export default defineComponent({

	emits:[],
	props:{
		labelPrefix:{
			type:String,
			default:"",
		},
		count:{
			type:Number,
			default:0,
		},
		icon:{
			type:String,
			default:"mdi.link-variant",
		}
	},
	components:{
	},
	setup(){

		return {

		};
	}
});

