





















































































































import { defineComponent, ref, computed, watch } from "@vue/composition-api";
import { default as BGImage } from "./bg-image.vue";
import * as ImageUtils from "../ts/img-utils";
import { useTranslate } from "@lang";

type Dictionary<T> = { [k:string]:T }; 

type ModalConfig = {
	sources:string[];
	thumbnail:string;	
};

type ModalResult = {
	thumbnail:string;
};

type CallbackHandler = (r:ModalResult) => void;


const variantMap:Dictionary<Dictionary<string>> = {
	"text":{
		"light":"dark",
	},
	"border":{
		"dark":"secondary",
		"light":"dark",
	},
	"button":{
		"dark":"secondary",
		"light":"dark",
	},
};

const supportedUploads = [
	"image/png",
	"image/jpg",
	"image/jpeg",
];


export interface IEditThumbnailModal {
	open(c:ModalConfig, f:CallbackHandler):void;
};

export default defineComponent({
	props:{
		showBackdrop:{
			type:Boolean,
			default:true
		}
	},
	components:{
		"bg-image":BGImage,
	},
	setup(props){


		const {
			translate
		} = useTranslate();

		const generated = ref(false);
		const loadingThumbnail = ref(false);

		const modified = ref(false);

		const uploadFiles = ref<FileList>();

		const uploadInput = ref<HTMLInputElement>();

		const fn = ref<CallbackHandler|null>(null);
		const show = ref(false);

		const modal = ref(null);
		const root = ref<any>();

		const sources = ref<string[]>([]);
		const thumbnail = ref<string>("");

		const error = ref("");

		const canSave = computed(() => {
			return modified.value;
		});

		const title = computed(() => translate("label.thumbnail"));

		const ok = () => {
			if(fn.value){
				fn.value({
					thumbnail:thumbnail.value,
				})
			}
			(modal.value as any).hide();
		};

		const hide = () => {
			fn.value = null;
			show.value = false;
			sources.value = [];
			thumbnail.value = "";
			modified.value = false;
			generated.value = false;
			error.value = "";
			loadingThumbnail.value = false;
		};

		const open = (c:ModalConfig, f:CallbackHandler) => {
			fn.value = f;
			thumbnail.value = c.thumbnail || "";
			sources.value = c.sources || [];
			setTimeout(() => {
				show.value = true;
			}, 1);
		};

		const generateThumbnail = async() => {

			error.value = "";

			const m:any = root.value;
			if(!m){ return; }

			loadingThumbnail.value = true;

			const srcs = sources.value.map(s => s.trim());
			
			try {
				const th = await ImageUtils.getUrlThumbnail(srcs, m);
				thumbnail.value = th;
				generated.value = true;
			} catch(err){
				
				console.log(err);
				error.value = "Generating image failed: Unable to read URL";
				loadingThumbnail.value = false;
				
				// console.log(err.message);
			}
			loadingThumbnail.value = false;
		};
		

		watch(uploadFiles, async () => {
			
			error.value = "";

			if(!uploadFiles.value || uploadFiles.value.length == 0){ return; }
			const file = uploadFiles.value.item(0);

			
			if(!file || !supportedUploads.includes(file.type)) { return; }
			
			loadingThumbnail.value = true;
			try {
				thumbnail.value = (await ImageUtils.fileToThumbnail(file)) || "";
			}
			catch(err){
				// console.log(err.message);
				error.value = "Generating image failed";
			}
			generated.value = false;
			loadingThumbnail.value = false;
		});

		watch(thumbnail, () => {
			if(show.value){
				modified.value = true;
			}
			setTimeout(() => {
				if(!uploadInput.value){ return; }
				(uploadInput.value as any).reset();
			}, 1);
		});

		// watch([ uploadFiles, loadingThumbnail ], () => {
		// 	setTimeout(() => {
		// 		error.value = "";
		// 	},100);
		// })

		return {
			thumbnail,
			fn,
			show,
			canSave,
			modal,
			title,
			ok,
			hide,
			open,
			uploadInput,
			uploadFiles,
			generateThumbnail,
			translate,
			root,
			loadingThumbnail,
			sources,
			generated,
			error,
		};
	}
});
