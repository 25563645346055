


















type Dictionary<T> = { [k:string]:T }; 

type RowState = {
	dirty:boolean,
	valid:boolean
};

import { defineComponent, PropType, computed, ref, set } from "@vue/composition-api";
import { ResponseData } from "../ResponseData";
import { ErrorBox } from "@ui";
import { RenderLikert } from "@form-ui";
import { LinearScale } from "@psychlab/types/form";
import { getBaseProps } from "./getBaseProps";
import { useRouteTranslate } from "@lang";

export default defineComponent({

	emits:[ "input" ],
	props:{
		value:{
			type:Array as PropType<number[]>
		},
		...getBaseProps<LinearScale>(),
		config:{
			type:Object as PropType<LinearScale>,
			required:true
		}
	},
	components:{
		RenderLikert,
		ErrorBox
	},
	setup(props, context){

		const { translate } = useRouteTranslate(context);


		const state = ref<Dictionary<RowState>>({});
		const pristine = ref(true);

		const val = computed({
			get:() => props.value,
			set:v => context.emit("input", v)
		});

		const rows = computed(() => props.config.settings.rows);
		const columns = computed(() => props.config.settings.columns);


		const invalidRows = computed(() => {
			if(pristine.value){ return []; }
			return rows.value.map((r, ri) => ri).filter(ri => !state.value[ri].valid);
		});

		const fullState = computed(() => {
			let s = {
				dirty:false,
				valid:true
			};
			Object.keys(state.value).forEach(k => {
				s.valid = s.valid && state.value[k].valid
				s.dirty = s.dirty || state.value[k].dirty;
			});
			return s;
		});

		const args = computed(() => ({
			title:!props.config.hideName ? props.config.name : '',
			body:props.config.body,
			theme:props.theme,
			rows:props.config.settings.rows,
			columns:props.config.settings.columns,
		}));

		const isValid = () => {
			pristine.value = false;
			Object.keys(state.value).forEach(k => {
				state.value[k].dirty = false;
			});
			return fullState.value.valid;
		};

		const getChannels = (prefix:string):ResponseData<number>[] => {
			const v = val.value;
			if(!v){ return []; }
			return props.config.settings.rows.map((row, rIndex) => {
				return {
					key: `${prefix}/${rIndex}`,
					data: v[rIndex]
				};
			});
		};

		const init = () => {
			let arr = val.value
			if(!arr){
				arr = props.config.settings.rows.map(r => -1);
				val.value = arr;
			}
			let s:Dictionary<RowState> = {};
			arr.forEach((col, rowIndex) => {
				s[rowIndex] = {
					dirty:false,
					valid: col > -1
				};
			});
			
			state.value = s;
		};

		const onRowPicked = (ri:number) => {
			if(!val.value){ return; }
			set(state.value, `${ri}`, {
				dirty:true,
				valid:true
			});
		};

		init();

		return {
			pristine,
			rows,
			columns,
			state,
			val,
			fullState,
			isValid,
			getChannels,
			invalidRows,
			onRowPicked,
			args,
			translate,
		};
	}
});

