var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('svg',{staticStyle:{"overflow":"visible"},attrs:{"x":_vm.x,"y":_vm.y}},[_c('circle',{staticClass:"darkfill",attrs:{"r":_vm.r}}),_c('circle',{staticClass:"node",attrs:{"r":_vm.r-5,"fill":_vm.bgColor}}),_c('circle',{staticClass:"node-outline",attrs:{"r":_vm.r,"fill":"transparent"}}),_c('foreignObject',{attrs:{"width":_vm.r * 2,"height":_vm.r * 2,"x":-_vm.r,"y":-_vm.r}},[_c('div',{staticClass:"abstretch d-flex"},[_c('icon',{staticClass:"m-auto ",staticStyle:{"font-size":"1.5em"},attrs:{"name":_vm.icon}})],1)]),_c('circle',{staticClass:"node-overlay",attrs:{"r":_vm.r + 2},on:{"click":_vm.onClick}}),_c('svg',{staticStyle:{"overflow":"visible"},attrs:{"x":-_vm.drawerSize.x * 0.5,"y":_vm.r - _vm.drawerSize.y * 0.5}},[_c('rect',{staticStyle:{"stroke-width":"2"},attrs:{"rx":_vm.drawerSize.y * 0.5,"ry":_vm.drawerSize.y * 0.5,"fill":"white","stroke":"black","width":_vm.drawerSize.x,"height":_vm.drawerSize.y}}),_vm._l((_vm.displayItems),function(item){return _c('svg',{staticStyle:{"overflow":"visible"},attrs:{"x":item.x,"y":item.y}},[_c('circle',{staticClass:"node-item",attrs:{"r":item.r,"fill":item.c}}),(!item.empty)?_c('circle',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
				title:_vm.getTypeTooltip(item.type),
				interactive:false,
				delay:0,
				animation:false,
				xplacement:'bottom',
				variant:'light'
			}),expression:"{\n\t\t\t\ttitle:getTypeTooltip(item.type),\n\t\t\t\tinteractive:false,\n\t\t\t\tdelay:0,\n\t\t\t\tanimation:false,\n\t\t\t\txplacement:'bottom',\n\t\t\t\tvariant:'light'\n\t\t\t}"}],staticClass:"node-overlay",attrs:{"r":item.r,"fill":"transparent"},on:{"click":item.fn}}):_vm._e()])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }