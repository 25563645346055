















































// vendor
import { defineComponent, ref, computed, PropType, ComputedRef, inject, watch } from "@vue/composition-api";
// project
import { Session, SessionLabel } from "@/psychlab/types";
import { useUserPrefs } from "@/hooks/useUserPrefs";
import { StudyContext } from "@/components.studies/manage-study";
// local
import { default as SessionTable } from "./session-table.vue";
import { default as BatchActions } from "./batch-actions.vue";
import { Pagination } from "@ui";

export default defineComponent({
	emits: [
		"change-sort",
		"change-limit",
		"change-page",
		"reload",
		"row-click",
	],
	props: {
		studyId: {
			type: String,
			required: true,
		},
		sessions: {
			type: Array as PropType<Session[]>,
			required: true,
		},
		page: {
			type: Number,
			required: true,
		},
		limit: {
			type: Number,
			required: true,
		},
		rows: {
			type: Number,
			required: true,
		},
		sortKey: {
			type: String,
			required: true,
		},
		sortAsc: {
			type: Boolean,
			required: true,
		},
		labels:{
			type: Array as PropType<SessionLabel[]>,
			default:() => [],
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		BatchActions,
		Pagination,
		SessionTable,
	},
	setup(props, ctx) {

		const studyContext = inject<ComputedRef<StudyContext>>("studyContext");

		if(!studyContext){ throw Error("Session List: Study context missing!"); }

		const { sessionListLimit } = useUserPrefs();
		const { channels, } = studyContext.value;
		const batchEnabled = ref(false);

		const selectedSessions = ref<string[]>([]);

		const showOptionalProgress = computed(() => {
			return channels.value.findIndex(c => c.optional) > -1;
		});

		const toggleBatch = () => batchEnabled.value = !batchEnabled.value;

		const onChangeSort = (sortKey: string) => ctx.emit("change-sort", sortKey);

		const onRowClick = (sessionId: string) => ctx.emit("row-click", sessionId);

		const onChangeLimit = (value: number) => ctx.emit("change-limit", value);

		const onChangePage = (page: number) => ctx.emit("change-page", page);

		const onReload = () => ctx.emit("reload");

		const onSessionsSelected = (selection: string[]) => selectedSessions.value = selection;

		onChangeLimit(sessionListLimit.value);

		watch(() => props.limit, () => sessionListLimit.value = props.limit);

		return {
			onChangeSort,
			onRowClick,
			onChangeLimit,
			onChangePage,
			onReload,
			batchEnabled,
			toggleBatch,
			onSessionsSelected,
			selectedSessions,
			showOptionalProgress,
		};
	},
});
