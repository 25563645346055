
// https://stackoverflow.com/questions/26188882/split-pascal-case-in-javascript-certain-case
export const pascalToSentenceCase = (str:string) => {
	return str
	// Look for long acronyms and filter out the last letter
	.replace(/([A-Z]+)([A-Z][a-z])/g, ' $1 $2')
	// Look for lower-case letters followed by upper-case letters
	.replace(/([a-z\d])([A-Z])/g, '$1 $2')
	// Look for lower-case letters followed by numbers
	.replace(/([a-zA-Z])(\d)/g, '$1 $2')
	.replace(/^./, function(str){ return str.toUpperCase(); })
	// Remove any white space left around the word
	.trim();
}

export const pascalToKebabCase = (s:any) => {
	let r = s.replace(/([A-Z]+[a-z]+)/, ($1:any) => {
		return $1[0].toUpperCase() + $1.slice(1) + "-";
	}).toLowerCase();
	if(r.charAt([r.length - 1]) === "-"){ // hacky fix: slicing off last -
		r = r.substr(0, r.length - 1);
	}
	return r;
}

export const capitalize = (s:string) => {
	if(!s){ return ""; }
	return s[0].toUpperCase() + (s.length > 1 ? s.substr(1) : "");
}

export const camelToSentenceCase = (str:string) => {
	return pascalToSentenceCase(capitalize(str));
}