import { defineComponent, computed, ref, PropType } from "@vue/composition-api";
import { IFormTheme } from "../config";
import { getThemeDefaults } from "../config/getThemeDefaults";
import { IFormEditor } from "../hooks/useFormEditor";

export const getBaseProps = () => {
	return {
		title:{
			type:String,
			default:"",
		},
		body:{
			type:String,
			default:"",
		},
		theme:{
			type:Object as PropType<IFormTheme>,
			// required:false,
			default:getThemeDefaults,
		},
		disabled:{
			type:Boolean,
			default:false,
		},
		invalid:{
			type:Boolean,
			default:false,
		},
		editor:{
			type:Object as PropType<IFormEditor>,
			required:false,
		}
	}
};