






































import { computed, defineComponent, PropType, ComputedRef } from "@vue/composition-api";
import { LabelBadge } from "@ui";
import { default as LabelDropdown } from "./label-dropdown.vue";
import { useTranslate } from "@lang";

type Mod = "all"|"in";

type ValueType = {
	mod:Mod|null;
	include:string[];
	exclude:string[];
};

type LabelOption = {
	id:string;
	name:string;
	color:string;
};

type DrawerConfig = {
	options:LabelOption[]|ComputedRef<LabelOption[]>;
};

export default defineComponent({
	emits: [
		"input",
	],
	props: {
		value: {
			type:Object as PropType<ValueType>,
			default:() => ({
				mod:null,
				include:[],
				exclude:[],
			})
		},
		config:{
			type:Object as PropType<DrawerConfig>,
			default:() => ({ options:[] }),
		},
	},
	components: {
		LabelBadge,
		LabelDropdown,
	},
	setup(props, context) {

		const { translate } = useTranslate();
		
		const options = computed(() => {
			if(Array.isArray(props.config.options)){
				return props.config.options;
			}
			return props.config.options.value;
		});

		const selected = computed(() => {
			const items = Array.from(new Set(props.value.include))
			.map(id => options.value.find(o => o.id === id));
			return items.filter(it => it !== undefined) as LabelOption[];
		});

		const available = computed(() => {
			return options.value.filter(o => selected.value.findIndex(so => so.id === o.id) < 0);
		});

		const mod = computed(() => {
			return props.value.mod;
		});


		const modOptions = computed(() => {
			return [
				{ value:"in", text:translate("label.any") },
				{ value:"all", text:translate("label.all") },
			];
		});

		const toggleInclude = (id:string) => {
			const i = props.value.include.findIndex(v => v === id);
			if(i > -1){ props.value.include.splice(i, 1); }
			else { props.value.include.push(id); }
			context.emit("input", props.value);
		};

		const toggleExclude = (id:string) => {
			const i = props.value.exclude.findIndex(v => v === id);
			if(i > -1){ props.value.exclude.splice(i, 1); }
			else { props.value.exclude.push(id); }
			context.emit("input", props.value);
		};

		const setMode = (m:Mod|null) => {
			props.value.mod = m;
			context.emit("input", props.value);
		};

		return {
			setMode,
			selected,
			available,
			// add,
			mod,
			// remove,
			modOptions,
			options,
			toggleInclude,
			toggleExclude,
			translate,
		};
	},
});
