
























import { defineComponent, PropType } from "@vue/composition-api";

export enum ButtonVariant {
	Primary = "primary",
	PrimaryOutline = "outline-primary",
	Secondary = "secondary",
	SecondaryOutline = "outline-secondary",
	Success = "success",
	SuccessOutline = "outline-success",
	Danger = "danger",
	DangerOutline = "outline-danger",
	Warning = "warning",
	WarningOutline = "outline-warning",
}

export enum ButtonSize {
	Small = "sm",
	Medium = "md",
	Large = "lg",
}

type RouteConfig = {
	/* vue-router config */
};

type ClickHandler = (e: Event) => void;

export default defineComponent({
	emits: ["click"],
	props: {
		label: {
			type: String,
			default: "",
		},
		variant: {
			type: String as PropType<ButtonVariant>,
			default: ButtonVariant.Secondary,
		},
		icon: {
			type: String,
		},
		disabled: {
			type: Boolean,
		},
		to: {
			type: [String, Object] as PropType<string | RouteConfig>,
		},
		size: {
			type: String as PropType<ButtonSize>,
			default: ButtonSize.Medium,
		},
	},
	setup(props, context) {
		const onClick = (e: Event) => {
			context.emit("click", e);
		};

		return {
			onClick,
		};
	},
});
