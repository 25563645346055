
































// vendor
import { defineComponent, computed } from "@vue/composition-api";
import { Icon } from "../icons";

export default defineComponent({

	emits:[ "blur", "cleared", "input" ],
	props:{
		placeholder:{
			type:String,
			default:"",
		},
		value:{
			type:String,
			default:"",
		}
	},
	components:{
		Icon,
	},
	setup(props, ctx){
		
		const searchFilter = computed({
			get: () => props.value,
			set: v => ctx.emit("input", v)
		})

		const clearSearch = () => {
			searchFilter.value = '';
			ctx.emit("cleared");
		};

		const handleBlur = () => ctx.emit("blur");

		return {
			searchFilter,
			clearSearch,
			handleBlur,
		};
	}
});

