export function diff<T1, T2>(a1:T1[], a2:T2[], fn:(i1:T1, i2:T2) => boolean) {
	return a1.filter(i1 => a2.findIndex(i2 => fn(i1, i2)) < 0);
}

export const move = function(arr:any[], from:number, to:number) {
	arr.splice(to, 0, arr.splice(from, 1)[0]);
};

export const shuffle = (a:any[]) => {
	var j, x, i;
	for (i = a.length - 1; i > 0; i--) {
		j = Math.floor(Math.random() * (i + 1));
		x = a[i];
		a[i] = a[j];
		a[j] = x;
	}
	return a;
};


export const sortByProperty = (arr:any[], prop:string, constructorFn?:any) => {
	if(constructorFn){
		arr.sort((a, b) => constructorFn(b[prop]) - constructorFn(a[prop]));
		return;
	}
	arr.sort((a, b) => b[prop] - a[prop]);
}


type PropStringFn<T> = (a:T) => string|undefined;


export const sortByDate = <T>(arr:T[], prop:PropStringFn<T>|string) => {

	const fn:PropStringFn<T> = typeof(prop) === "function" ? prop : (it) => (it as any)[prop] as string 

	arr.sort((a, b) => {
		const da = (new Date(fn(a) || "")).getTime();
		const db = (new Date(fn(b) || "")).getTime();
		return db - da;
	});
};