import { Dictionary } from "./Dictionary";

interface PropDefinition {
	value?:any,
	validator?:(v:any) => boolean,
	onChange?:(v:any) => void,
	proxyFor?:string[]
}

export class PropMap {

	constructor(props:Dictionary<PropDefinition> = {}){
		this._props = props;
	}

	public initialize(props:Dictionary<PropDefinition>){
		this._props = props;
	}

	public set(key:string, value:any){
		let prop = this._props[key];
		if(!prop){ return; }

		if(prop.validator && !prop.validator(value)){ return; }
		prop.value = value;
		if(prop.onChange){ prop.onChange(value); }
	}

	public configure(ob:Dictionary<any>){
		const keys = Object.keys(ob);
		keys.forEach(key => {
			const prop = this._props[key];
			if(!prop){ return; }
			const value = ob[key];
			if(prop.proxyFor){
				const proxied:any = {};
				for(var i = 0; i < prop.proxyFor.length; i++){
					proxied[prop.proxyFor[i]] = value;
					this.configure(proxied);
				}
			}
			else {
				this.set(key, value);
			}
		});
	}

	public forEachValue(fn:(k:string, v:any) => void){
		Object.keys(this._props)
		.forEach(k => {
			if(this._props[k].proxyFor){ return; }
			fn(k, this._props[k].value);
		});
	}

	public useDefaultValues(){
		Object.keys(this._props)
		.forEach(key => {
			const prop = this._props[key];
			if(prop.proxyFor || !prop.onChange){ return; }
			prop.onChange(prop.value);
		});
	}

	public getValue(k:string){
		return this._props[k] ? this._props[k].value : undefined;
	};

	private _props:Dictionary<PropDefinition> = {};

}

