// vendor
import { computed, Ref, ref, SetupContext, watch } from "@vue/composition-api";
import { format as formatDate } from "date-fns";
// project
import { Study, SessionLabel } from "@/psychlab/types/studies";
// import { IContextMenu } from "@/components.generic/context-menu";
import { formatDuration } from "@/utils/text";
import * as AppModal from "@/AppModal";
// local
import * as Utils from "../utils";
import { diff as arrayDiff } from "@utils/array";
import { useTranslate } from "@lang";

type Dictionary<T> = { [k:string]:T };

type Config = {
	study:Study,
	labels:Ref<SessionLabel[]>;
	// contextMenu:Ref<IContextMenu|undefined>;
};

type Filter = {
	key:string;
	label:string;
	type:string;
	icon:string;
	config?:any;
};

export const useSessionFilters = (c:Config, context:SetupContext) => {

	const {
		study,
		labels,
		// contextMenu,
	} = c;
	
	const activeFilters = ref<Filter[]>([]);


	const { translate } = useTranslate();


	const unusedFilters = computed(() => {
		return filters.value.filter(f => activeFilters.value.findIndex(af => af.key === f.key) < 0);
	});

	const labelOptions = computed(() => {
		return labels.value.map(label => {
			return {
				...label,
				id:label._id,
			};
		});
	});

	const activeKeys = computed(() => {
		return activeFilters.value.map(f => f.key);
	});

	const filterKeys = computed(() => {
		return filters.value.map(f => f.key);
	});

	const filters = computed<Filter[]>(() => {

		return [
			{
				label:translate("label.startDate"),
				key:"created",
				type:"daterange",
				icon:"mdi.calendar-range",
				config:{
					presets:[
						{
							name:translate("studies.label.studyCreationDate"),
							value(){
								return (new Date(study.created)).getTime();
							}
						},
						{
							name:translate("label.today"),
							value(){
								return Date.now();
							}
						},
						{
							name:translate("label.monthAgo"),
							value(){
								return (Date.now() - (604800000 * 4))
							}
						},
					]
				}
			},
			{
				label:translate("label.progress"),
				key:"progress",
				type:"interval",
				config:{
					min:0,
					max:100,
					labelFn(min:number, max:number){
						return min !== max ? `${min}% - ${max}%` : `${min}%`;
					}
				},
				icon:"mdi.timer-sand",
			},
			{
				label:translate("label.optionalProgress"),
				key:"optionalProgress",
				type:"interval",
				config:{
					min:0,
					max:100,
					labelFn(min:number, max:number){
						return min !== max ? `${min}% - ${max}%` : `${min}%`;
					}
				},
				icon:"mdi.timer-sand",
			},
			{
				label:translate("studies.label.labels"),
				key:"labels",
				type:"labels",
				config:{
					options:labelOptions,
				},
				icon:"mdi.label-multiple-outline",
			},
			{
				label:translate("label.finished"),
				key:"finished",
				type:"toggle",
				config:{
					variant:"success",
					onLabel:translate("label.yes"),
					offLabel:translate("label.no"),
				},
				icon:"mdi.flag-checkered",
				
			},
			{
				label:translate("label.duration"),
				key:"duration",
				type:"interval",
				config:{
					min:0,
					max:3600000 * 2, // 2h
					step:1000 * 60, // 60s
					labelFn(min:number, max:number){
						return `${formatDuration(min)} - ${formatDuration(max)}`;
					}
				},
				icon:"mdi.clock-outline",
			},
		];
	});

	const confirmAddFilter = (key:string) => {
		const f = filters.value.find(f => f.key === key);
		if(!f){ return; }
		activeFilters.value.push(f);
		const qv = context.root.$route.query[key];
		Utils.setQueryValue(key, qv || getDefaultValue(key, f.type), context);
	};

	const addFilter = (e:Event) => {
		// if(!contextMenu.value){ return; }

		AppModal.context(e, unusedFilters.value.map(filter => {
			return {
				name:filter.label,
				icon:filter.icon || "mdi.filter",
				fn(){
					confirmAddFilter(filter.key);
				},
			}
		}));
	};

	const removeFilter = (key:string) => {
		const i = activeFilters.value.findIndex(f => f.key === key);
		if(i < 0){ return; }
		activeFilters.value.splice(i, 1);
	};

	const clearFilters = () => {
		activeFilters.value = [];
		const q:any = { ...context.root.$route.query };
		filters.value.forEach(filter => delete q[filter.key]);
		context.root.$router.push({ query: q})
		.catch(() => {});
	};

	const init = async () => {
		filters.value.forEach(filter => {
			const qv = context.root.$route.query[filter.key];
			if(qv === undefined || qv === null){ return; }
			activeFilters.value.push(filter);
		});
	};

	watch(() => context.root.$route.query, v => {
		const ks = Object.keys(context.root.$route.query)
		.filter(k => filterKeys.value.includes(k));
		const rm = arrayDiff(activeKeys.value, ks, (a,b) => a === b);
		const add = arrayDiff(ks, activeKeys.value, (a,b) => a === b);
		rm.forEach(fk => removeFilter(fk));
		add.forEach(fk => confirmAddFilter(fk));
	});


	init();

	return {
		activeFilters,
		unusedFilters,
		addFilter,
		removeFilter,
		clearFilters,
	};
};

const getDefaultValue = (k:string, type:string, c:any = {}) => {
	const f = keyDefaults[k] || typeDefaults[type];
	return typeof(f) === "function" ? f(c) : f;
};

const keyDefaults:Dictionary<any> = {
	duration(){
		return [ 0, 3600000 * 2 ].join(",");
	},
	progress(){
		return [ 0, 100 ].join(",");;
	},
	optionalProgress(){
		return [ 0, 100 ].join(",");;
	},
	labels(){
		// return {
		// 	mod:"all",
		// 	include:[],
		// 	exclude:[],
		// };
		return "in;";
	}
};

const typeDefaults:Dictionary<any> = {
	toggle:1,
	range:"0",
	date:() => Date.now().toString(),
	daterange:(c:{ defaultFrom?:number } = {}) => {
		const { defaultFrom } = c;
		const d = defaultFrom || (Date.now() - (604800000 * 4)); // default 4 weeks back
		return  formatDate(d, "yyyy-MM-dd") + "," + formatDate(Date.now(), "yyyy-MM-dd");
	},
};