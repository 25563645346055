import { languages, LangKey } from "../supportedLanguages";

type ToggleLang = {
	title:string;
	name:string;
	flag:string;
};

export const toggleOptions:ToggleLang[] = (Object.keys(languages) as LangKey[])
.map(k => ({
	title:languages[k].label,
	name:k,
	flag:languages[k].icon,
}))