import { IParams, IGet } from "../interfaces";
import { HTTP } from "../HTTP";

export class Get<PT extends IParams,RT> extends HTTP implements IGet<PT,RT> {
	
	constructor(path:string){
		super(""); // todo: base path
		this._path = path;
	}

	async send(p:PT): Promise<RT> {
		return await this.get(this._path, p);
	}
	
	private _path:string;
}