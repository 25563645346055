







// vendor
import { defineComponent, computed, ref } from "@vue/composition-api";
import { formatBytes } from "@utils/text";

export default defineComponent({

	emits:[],
	props:{
		bytes:{
			type:Number,
			default:0,
		}
	},
	setup(props){
		const txt = computed(() => formatBytes(props.bytes))
		
		return {
			txt
		};
	}
});
