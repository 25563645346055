import { defineComponent } from "@vue/composition-api";
import { VideoPanorama } from "@panorama";

const testVideo = "https://ams3.digitaloceanspaces.com/psychlab-assets-public/test_pano_highres.mp4";

export default defineComponent({
	setup(){
		return {};
	},
	render(){
		return (
			<VideoPanorama
			config={{
				sources:[ testVideo ]
			}}
			/>
		);
	}
});