






























































































// vendor
import {
	defineComponent,
	computed,
	ref,
	PropType,
} from "@vue/composition-api";
// project
import { generateGUID } from "@/utils/guid";
// local
import ChartLegend from "./legend.vue";

const steeple = {
	props: ["c", "i", "w", "h"],
	template: `<rect
	:x="w * i"
	y="0"
	:width="w"
	:height="h"
	:fill="c"
	/>`,
};

const maxRadius = 4;

const getNodeRadius = (n: number) => {
	const s = n.toString();
	let r = maxRadius - Math.log(n);
	if (r < 0) {
		r = 0;
	}
	return r;
};

const getRangeColor = (t: number) => {
	const v = 100 + t * 120;
	return `rgb(${0}, ${v}, ${v})`;
};

export default defineComponent({
	props: {
		title: {
			type: String,
			default: "",
		},
		ceil: {
			type: Number,
			default: -1,
		},
		labels: {
			type: Array as PropType<string[]>,
			default: () => [],
		},
		values: {
			type: Array as PropType<number[]>,
			default: () => [],
		},
		colors: {
			type: Array as PropType<string[]>,
			default: () => [],
		},
		sort: {
			type: String as PropType<"label"|"none"|"value">,
			default: "label",
		},
	},
	components: {
		ChartLegend,
		steeple,
	},
	setup(props) {
		const wrapper = ref<HTMLElement>();
		const hovered = ref<string>();

		const canvasSize = computed(() => {
			if (!wrapper.value) {
				return { w: 0, h: 0 };
			}
			return {
				w: wrapper.value.clientWidth,
				h: wrapper.value.clientHeight,
			};
		});

		const itemScale = computed(() => {
			if (props.values.length === 0) {
				return 0;
			}
			return 1 / props.values.length;
		});

		const hoveredItem = computed(() => {
			const i = displayItems.value.findIndex(
				(it) => it.id === hovered.value,
			);
			if (i < 0) {
				return undefined;
			}
			return {
				item: displayItems.value[i],
				index: i,
			};
		});

		const itemRadius = computed(() => {
			return getNodeRadius(displayItems.value.length);
		});

		const linePath = computed(() => {
			const cmds: string[] = [];
			for (var i = 0; i < displayItems.value.length - 1; i++) {
				const c = displayItems.value[i];
				const n = displayItems.value[i + 1];
				cmds.push(`M ${c.x} ${c.y}`);
				cmds.push(`L ${n.x} ${n.y}`);
			}
			return cmds.join(" ");
		});

		const displayLines = computed(() => {
			const lines: any[] = [];
			for (var i = 0; i < displayItems.value.length - 1; i++) {
				const c = displayItems.value[i];
				const n = displayItems.value[i + 1];
				lines.push({
					x1: c.x,
					y1: c.y,
					x2: n.x,
					y2: n.y,
				});
			}
			return lines;
		});

		const displayItems = computed(() => {
			if (props.values.length === 0) {
				return [];
			}
			const n = props.values.length;
			const nt = 1 / n;
			const max = props.values.reduce((a, b) => Math.max(a, b), 0);
			const ceil = props.ceil > 0 ? props.ceil : max;

			const ls = props.values.map((v, i) => {
				// const t = v / ceil;
				const t = ceil > 0 ? v / ceil : 0;
				const color =
					i < props.colors.length
						? props.colors[i]
						: getRangeColor(i / (props.values.length - 1));
				const label =
					i < props.labels.length ? props.labels[i] : "";

				let y = t * 100;
				let x = (itemScale.value * 0.5 + itemScale.value * i) * 100;

				return {
					value: v,
					id: generateGUID(),
					x,
					y,
					color,
					label,
				};
			});

			if(props.sort === "label"){
				ls.sort((a, b) => a.label.localeCompare(b.label));
			}

			if(props.sort === "value"){
				ls.sort((a, b) => a.value - b.value);
			}

			return ls;
		});

		return {
			linePath,
			hoveredItem,
			hovered,
			wrapper,
			itemRadius,
			itemScale,
			displayItems,
			canvasSize,
			displayLines,
		};
	},
});
