













































// vendor
import { defineComponent, computed } from "@vue/composition-api";

const options = [5, 10, 20, 50, 100, 200 ];

export default defineComponent({
	emits: [
		"change-page",
		"change-limit",
	],
	props: {
		page: {
			type: Number,
			required: true,
		},
		rows: {
			type: Number,
			required: true,
		},
		limit: {
			type: Number,
			default:5,
		},
		maxLimit:{
			type:Number,
			default:50,
		},
		size:{
			type:String,
			default:"md",
		},
		variant:{
			type:String,
			default:"light",
		},
		showLimit:{
			type:Boolean,
			default:true,
		},
		maxVisible:{
			type:Number,
			default:5,
		},
	},
	setup(props, ctx) {

		const onChangePage = (p:number|null) => {
			if(!p){ return; }
			ctx.emit("change-page", p);
		};

		const onChangeLimit = (l:number|null) => {
			if(!l){ return; }
			ctx.emit("change-limit", l);
		};


		const textVariant = computed(() => {
			return ({
				"dark":"light",
			} as any)[props.variant] || "dark"
		});

		const limitOptions = computed(() => {

			const opts = options.filter(o => o <= props.maxLimit);

			return opts.map(o => ({
				value:o,
				text:o.toString(),
			}));
		})

		return {
			onChangePage,
			onChangeLimit,
			limitOptions,
			textVariant,
		};
	},
});
