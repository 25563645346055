import { GraphNode } from "@/psychlab/types/graph";
import * as AssetAPI from "@/services/api/assets";
import { parseAssetRef } from "./parseAssetRef";

export const updateNodeReferences = async(node:GraphNode<any>) => {
	const resolver = resolvers[node.type];
	if(!resolver){ return; }
	resolver(node);
};

type Resolver = (node:any) => Promise<void>;

const updateSceneNode = async (node:SceneNode) => {
	const points = (node.parameters.points || []).filter(p => Boolean(p.form)) as any[];
	const refs = points.map(p => parseAssetRef(p.form)) as string[];
	const queries = points.map((p, i) => new Promise<void>(async (res) => {
		const [ asset ] = refs[i].split("@");
		try {
			const v = await AssetAPI.getAssetVersion(asset, "latest");
			p.form = `${asset}@${v._id}`;
		}
		catch{}
		res();
	}));
	await Promise.all(queries);
};

const resolveFromAssetRef = async (node:AssetRefNode) => {
	const [ asset ] = (parseAssetRef(node.parameters["asset"]) || "").split("@");
	if(!asset){ return; }
	const v = await AssetAPI.getAssetVersion(asset, "latest");
	node.parameters.asset = `${asset}@${v._id}`;
};

const resolvers:Record<string,Resolver> = {
	"Form":resolveFromAssetRef,
	"Gallery":resolveFromAssetRef,
	"Scene":updateSceneNode,
};

type SceneNode = {
	parameters:{
		points:{
			form:any
		}[]
	}
};

type AssetRefNode = {
	parameters:{
		asset:any;
	}
};