











































import { defineComponent, computed, PropType } from "@vue/composition-api";
import { Form } from "@psychlab/types/form";
import { Icon, openContextMenu, ContextOption } from "@ui";
import { useFormIcons } from "@form-ui";
import { useTranslate } from "@lang";

export default defineComponent({

	emits:[ "toggle", "select-section", "add-section" ],
	props:{
		value:{
			type:String,
			default:"",
		},
		data:{
			type:Object as PropType<Form>,
			required:true
		}
	},
	components:{
		Icon,
	},
	setup(props, ctx){

		const { translate } = useTranslate();

		const { getItemIcon } = useFormIcons();


		const activeSectionIndex = computed(() => {
			if(!props.value){ return -1; }
			const p = props.value.split(":");
			if(p.length < 2){
				return -1;
			}
			if(p[0] === "s"){
				return props.data.sections.findIndex(s => s.id === p[1]);
			}
			return props.data.sections.findIndex(s => {
				return s.items.findIndex(it => it.id === p[1]) > -1;
			});
		});
		
		const sectionLabel = computed(() => {
			const i = activeSectionIndex.value;
			if(i < 0){ return "--"; }
			const s = props.data.sections[i];
			return s.label || translate("label.untitled");
		});

		const itemLabel = computed(() => {

			const i = activeSectionIndex.value;
			if(i < 0){ return "--"; }
			const s = props.data.sections[i];
			const it = s.items.find(i => `i:${i.id}` === props.value);

			// const s = props.data.sections.find(fs => `s:${fs.id}` === props.value);
			return it ? it.name || translate("label.untitled") : "--";
		});

		const selectSection = (e:Event) => {
			const ul = translate("label.untitled");

			const options = (props.data.sections || []).map(s => {
				return {
					name:s.label || ul,
					// icon:"mdi.file-document",
					icon:`s:${s.id}` === props.value ? 'mdi.check' : undefined,
					// checked:`s:${s.id}` === props.value,
					fn:() => ctx.emit("input", `s:${s.id}`)
				}
			});

			openContextMenu(e, options);
		};

		const selectItem = (e:Event) => {

			const i = activeSectionIndex.value;
			if(i < 0){ return; }
			const s = props.data.sections[i];


			const ul = translate("label.untitled");

			const options:ContextOption[] = (s.items || []).map(it => {
				return {
					name:it.name || ul,
					// icon:"mdi.file-document",
					icon:getItemIcon(it.type),
					// icon:`i:${it.id}` === props.value ? 'mdi.check' : undefined,
					checked:`i:${it.id}` === props.value,
					fn:() => ctx.emit("input", `i:${it.id}`)
				}
			});

			options.push({
				name:"--",
				order:-1,
				fn:() => ctx.emit("input", `s:${s.id}`)
			})

			openContextMenu(e, options);
		};


		return {
			sectionLabel,
			translate,
			selectSection,
			selectItem,
			itemLabel,
		};
	}
});

