







// vendor
import { defineComponent, computed, ref, PropType } from "@vue/composition-api";
import { IFormTheme, } from "../config";

export default defineComponent({
	props:{
		title:{
			type:String,
			default:"",
		},
		theme:{
			type:Object as PropType<IFormTheme>,
			required:true,
		},
	},
	setup(props){

		const size = computed(() => {
			return props.theme["item.title.size"] || 4;
		});

		const tag = computed(() => {
			return `h${size.value}`
		});

		const cls = computed(() => {
			return "m-0";
		});

		const style = computed(() => {

			const { theme } = props;

			const txtcase = ({
				"upper":"uppercase",
				"lower":"lowercase",
			} as any)[theme["item.title.fontCase"] || ""] || "";
		
			return {
				color:theme["item.title.color"],
				fontWeight:theme["item.title.fontWeight"],
				textTransform:txtcase,
				textAlign:theme["item.title.alignment"] || "left",
				fontFamily:theme["item.title.fontFamily"]
			}
		});

		return {
			tag,
			cls,
			style,
		};
	}
});

