import Page from "@pages/open-session";

export const OpenSession = {
	name: "Run.Session",
	path: "/run/sessions/:sessionId",
	component: Page,
	meta: {
		title: "Running Session",
		public: true,
		navHidden:true
	}
};