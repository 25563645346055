import { render, staticRenderFns } from "./confirm.vue?vue&type=template&id=eed8bbc8&scoped=true&"
import script from "./confirm.vue?vue&type=script&lang=ts&"
export * from "./confirm.vue?vue&type=script&lang=ts&"
import style0 from "./confirm.vue?vue&type=style&index=0&id=eed8bbc8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eed8bbc8",
  null
  
)

export default component.exports