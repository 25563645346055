import { defineComponent, ref, PropType } from "@vue/composition-api";
import { useTags } from "@/hooks/useTags";
import { useFavourites } from "@/hooks/useFavourites";
import { Tag } from "@/psychlab/types/tags";
import * as AppModal from "@/AppModal";
import { Icon, RouteButton, AbsBox, ContextOption, ContextButton } from "@ui";
import { useTranslate } from "@lang";
import { Route } from "vue-router";
import { CardGrid } from "@components/misc";

enum Translations {
	NewTag = "tags.action.newTag",
	Rename = "action.rename",
	Delete = "action.delete",
	SetColor = "action.setColor",
	SetTagColor = "tags.action.setTagColor",
	DeleteTag = "tags.action.deleteTag",
	ActionUnderStood = "prompts.action.understood",
	ActionIrreversible = "prompts.message.irreversible",
	AddToFavourites = "action.addToFavourites",
	RemoveFromFavourites = "action.removeFromFavourites",
}

enum ContextIcons {
	AddFavourite = "star",
	RemoveFavourite = "star-outline",
	Rename = "pencil",
	SetColor = "format-color-fill",
	Delete = "trash-can",
}

const TagRouteButton = defineComponent({
	props:[ "tid" ],
	setup(props, ctx){
		return {
			route: getTagRoute(props.tid, ctx.root.$route)
		};
	},
	render(){
		return (
			<AbsBox>
				<RouteButton route={this.route}/>
			</AbsBox>
		)
	},
});

const getTagRoute = (id:string, route:Route) => {
	const query:any = { ...route.query };
	return {
		name:'view-tag', 
		params:{ tagId:id, },
		query,
	};
};

const TagCard = defineComponent({
	emits:[ "ctx" ],
	props:{
		tag:{
			type:Object as PropType<Tag>,
			required:true,
		}
	},
	setup(props, ctx){

		const hovered = ref(false);


		return {
			hovered,
			setHovered:(v:boolean) => hovered.value = v,
		};
	},
	render(){

		const overlay = this.hovered ? <HoverOverlay/> : <span/>;


		return (
			<CardGrid.Card
			v-on:hovered={ () => this.setHovered(true) }
			v-on:unhovered={ () => this.setHovered(false) }
			style="border-radius:15%"
			class="bg-dark"
			>

				{overlay}

				<AbsBox class="d-flex">
					<Icon
					name="tag"
					class="m-auto"
					shadow
					glow={ this.hovered }
					style={ `font-size:3em; color:${this.tag.color}` }
					/>
				</AbsBox>

				<CardLabel>
					{ this.tag.name }
				</CardLabel>

				<TagRouteButton tid={this.tag._id}/>

				<AbsBox class="d-flex justify-content-end" style="pointer-events:none">
					<ContextButton
					class="m-2"
					variant={ this.hovered ? "light" : "secondary" }
					v-on:click={ (e:Event) => this.$emit("ctx", e) }
					/>
				</AbsBox>


				
			</CardGrid.Card>
		)
	},
})

export const TagsGrid = defineComponent({
	setup(){

		const {
			tags,
			editTag,
			createTag,
			deleteTag,
		} = useTags();

		const {
			addFavourite,
			removeFavourite,
			isFavourite
		} = useFavourites();

		const { translate } = useTranslate();

		const onAddTag = () => AppModal.editText({
			title:translate(Translations.NewTag),
			validator: v => v.length > 1,
		},  v => createTag(v));

		const rename = (tag:Tag) => AppModal.editText({
			title:translate(Translations.Rename),
			value:tag.name,
			validator:v => v !== tag.name,
		}, v => editTag(tag._id, { name:v }));

		const setColor = (tag:Tag) => AppModal.editColor({
			title:translate(Translations.SetTagColor),
			value:tag.color
		}, v => editTag(tag._id, { color:v }));

		const delTag = (tag:Tag) => {
			AppModal.confirm({
				title:translate(Translations.DeleteTag),
				variant:"danger",
				okText:translate(Translations.ActionUnderStood),
				description:translate(Translations.ActionIrreversible)
			}, () => deleteTag(tag._id));
		};

		const openContext = (event:Event, tag:Tag) => {

			const options:ContextOption[] = [

				isFavourite(tag._id) ? {
					name:translate(Translations.RemoveFromFavourites),
					icon:ContextIcons.RemoveFavourite,
					fn:() => removeFavourite(tag._id),
				} : {
					name:translate(Translations.AddToFavourites),
					icon:ContextIcons.AddFavourite,
					fn:() => addFavourite(tag._id, "tag"),
				},
				{
					name:translate(Translations.Rename),
					icon:ContextIcons.Rename,
					order:1,
					fn:() => rename(tag),
				},
				{
					name:translate(Translations.SetColor),
					icon:ContextIcons.SetColor,
					order:1,
					fn:() => setColor(tag),
				},
				{
					name:translate(Translations.Delete),
					icon:ContextIcons.Delete,
					order:2,
					fn:() => delTag(tag),
				}
			];
		
			AppModal.context(event, options);
		};

		return {
			tags,
			translate,
			openContext,
			onAddTag,
		};
	},
	render(){

		const cards = this.tags.map(t => {
			return (
				<TagCard
				key={t._id}
				tag={t}
				v-on:ctx={ (e:Event) => this.openContext(e, t) }
				/>
			);
		});

		return (
			<CardGrid.Grid>
				{ cards }
				<AddButton v-on:click={ this.onAddTag }/>
			</CardGrid.Grid>
		);
	},
});

const AddButton = defineComponent({
	emits:[ "click" ],
	props:{
		color:{
			type:String,
			default:"white"
		},
	},
	render(){

		const bc = `${this.color ? `color:${this.color}` : ""}`;

		return (
			<CardGrid.Card style="background:transparent;border-radius:15%;">
				<button
				v-on:click={ (e:Event) => this.$emit("click", e) }
				class="btn btn-outline-dark w-100 h-100 shadow-none d-flex"
				style={ `border-radius:inherit !important;${bc}` }
				>
					<Icon
					name="plus-thick"
					class="m-auto"
					style="font-size:3em;"
					shadow
					/>
				</button>
			</CardGrid.Card>
		)
	}
});

const HoverOverlay = defineComponent({
	render(){
		const s = `
			pointer-events:none;
			background:rgba(255,255,255,0.05);
			border:0.05em solid white;
			border-radius:inherit;
		`;
		return <AbsBox style={s}/>;
	}
});

const CardLabel = defineComponent({
	render(){
		const style = `font-size:0.8em;
		line-height:normal;
		display:-webkit-box;
		-webkit-box-orient:vertical;
		-webkit-line-clamp:2;
		overflow:hidden;`;

		return (
			<AbsBox style="pointer-events:none">
				<div
				class="w-100 p-2"
				style="position:absolute;top:100%;"
				>
					<p
						style={style}
						class="my-auto text-light text-center">
						{ this.$slots.default }
					</p>
				</div>
			</AbsBox>
		);
	}
});