











































import { defineComponent, PropType } from "@vue/composition-api";
export default defineComponent({
	props:{
		open:{
			type:Boolean
		},
		variant:{
			type:String,
			default:"dark"
		},
		title:{
			type:String,
			default:"Menu"
		},
		icon:{
			type:String,
			default:""
		}
	}
});

