















import { defineComponent, computed, ref } from "@vue/composition-api";
import { context } from "@/AppModal";
import { ContextOption } from "@ui";
import { toggleOptions } from "../ts/toggle-options";
import { useUserPrefs } from "@/hooks/useUserPrefs";

export default defineComponent({
	props:{
		size:{
			type:Number,
			default:1.5,
		},
	},
	setup(props){

		const { lang } = useUserPrefs();

		const currentFlag = computed(() => {
			return toggleOptions.find(l => l.name === lang.value)?.flag;
		});
		

		const btnStyle = computed(() => {

			return {
				width:`${props.size}rem`,
				height:`${props.size}rem`,
			}
		});

		const toggle = (e:Event) => {
			const options:ContextOption[] = toggleOptions
			.map((l, i) => ({
				name:l.title,
				order:i,
				icon:lang.value === l.name ? "mdi.checkbox-blank-circle" : "mdi.checkbox-blank-circle-outline",
				fn(){
					lang.value = l.name;
				}
			}));
			context(e, options);
		};

		return {
			currentFlag,
			btnStyle,
			toggle,
		};
	}
});

