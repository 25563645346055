







































import { computed, defineComponent, PropType } from "@vue/composition-api";

import { Study } from "@/psychlab/types";

import { AbsBox, Icon } from "@ui";
import { useTranslate } from "@lang";

import { copyToClipboard } from "@utils/dom";
import { generateGUID } from "@utils/guid";


export default defineComponent({
	props: {
		study: {
			type: Object as PropType<Study>,
			required:true,
		},
	},
	components:{
		AbsBox,
		Icon,
	},
	setup(props, ctx) {
		
		
		const { translate } = useTranslate();

		const inputId = computed(() => generateGUID());

		const studyId = computed(() => {
			const r = ctx.root.$route;
			return r.params["studyId"] || r.params["groupId"];
		});

		const linkDisabled = computed(() => !(props.study?.enabled || false));

		const shareLink = computed(() => `${window.location.origin}/p/s/${studyId.value}`);

		const copyShareLink = () => copyToClipboard(inputId.value)

		return {
			linkDisabled,
			shareLink,
			inputId,
			translate,
			copyShareLink,
		};
	},
});
