







// vendor
import { defineComponent, computed } from "@vue/composition-api";
import { formatDuration } from "@utils/text";

export default defineComponent({
	props:{
		ms:{
			type:Number,
			required:false,
		}
	},
	setup(props){
		const txt = computed(() => props.ms ? formatDuration(props.ms) : "-");
		return { txt };
	}
});

