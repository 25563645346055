









import { defineComponent, computed } from "@vue/composition-api";
import { RoundButton, openContextMenu, ContextOption } from "@ui";
import { getConfig } from "@config";
import { useTranslate } from "@lang";

export default defineComponent({
	components:{
		RoundButton,
	},
	setup(){
		const tutorialURL = computed(() => getConfig().tutorialURL);

		const { translate } = useTranslate();

		const openTutorial = () => {
			window.open(tutorialURL.value, "_");
		};

		const handleClick = (e:Event) => {
			const options:ContextOption[] = [];
			options.push({
				name:translate("nav.action.openTutorial"),
				icon:"mdi.open-in-new",
				fn:openTutorial,
			});
			openContextMenu(e, options);
		};

		return {
			handleClick
		};
	}
});

