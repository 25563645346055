






























import { defineComponent } from "@vue/composition-api";
import * as AssetAPI from "@/services/api/assets";
import { Asset } from "@/psychlab/types";
import * as AppModal from "@/AppModal";
import * as TypeEditors from "./TypeEditors";
import { useTranslate } from "@lang";
import { getProcessor } from "@/services/api/AssetDataProcessors";
import { SubmitBar } from "./SubmitBar";
import { AbsBox } from "@ui";

const confirmReset = false;

const loadVersion = async (type:string, aid:string, vid?:string) => {
	const p = getProcessor(type as any);

	const v = await AssetAPI.getAssetVersion(aid, vid || "latest", "data base");

	if(p.onBeforeUse){
		p.onBeforeUse(v.data);
	}
	return v;
};


const confirmLeave = () => {
	return window.confirm("You have unsaved changes. Are you sure?");
};

export default defineComponent({
	data(){
		return {
			asset:null as Asset|null,
			notFound:false,
			modified:false,
			resetting:false,
			data:null as any,
			latestData:null as any,
			submitting:false,
			latestVersion:null as any,
			isLoading:false
		}
	},
	components:{
		SubmitBar,
		AbsBox,
	},
	computed:{
		translation(){
			return useTranslate();
		},
		editorComponent(){
			const scope = this as any;
			return scope.asset ? (TypeEditors as any)[scope.asset.type] : null;
		}
	},
	watch:{
		modified(){
			this.refreshPageTitle();
		},
		asset:{
			deep:true,
			handler(v){
				if(!v){ return; }
				this.refreshPageTitle();
			}
		},
		data:{
			deep:true,
			handler(v, o){
				if(!o){  return; } 
				if(this.resetting){
					this.resetting = false;
					return;
				}
				this.dataModified(v);	
			}
		}
	},
	methods:{
		translate(s:string){
			return this.translation.translate(s);
		},
		async loadAsset(){
			this.isLoading = true;
			let { assetId } = this.$route.params;
			try{
				let asset = await AssetAPI.getAsset(assetId);
				// this.latestVersion = await AssetAPI.getAssetVersion(assetId, "latest", "data base");
				this.latestVersion = await loadVersion(asset.type, assetId);
				const { data:latestData } = this.latestVersion;
				this.asset = asset;
				this.latestData = latestData;
				this.data = JSON.parse(JSON.stringify(this.latestData));
			}
			catch(err:any){
				this.notFound = true;
				console.error(err.message);
			}
			finally{
				if(!this.asset){
					this.$router.push({name:"Home"});
				}
			}
			this.isLoading = false;
		},
		dataModified(newValue:any){
			var ns = JSON.stringify(newValue);
			if(ns === JSON.stringify(this.latestData)){
				return;
			}
			if(ns === JSON.stringify(this.data)){
				// return;
			}
			this.modified = true;
		},
		modifiedWithin(data:any){
			this.modified = true;
			this.data = JSON.parse(JSON.stringify(data));
		},
		resetChanges(){

			const reset = () => {
				this.resetting = true;
				this.data = JSON.parse(JSON.stringify(this.latestData));
				this.modified = false;
				(document.activeElement as any).blur();
				(document.body as any).focus();
			}

			if(confirmReset){
				AppModal.confirm({
					title:"",
					variant:"warning",
					description:"This will reset your changes. Are you sure?",
					okText:this.translate("prompts.action.understood")
				}, () => {
					reset();
				})
			}
			else {
				reset();
			}


			

			
		},
		async submitVersion(closeTab:boolean = false){
			this.submitting = true;

			

			try{
				
				const p = getProcessor("Form");

				const base = this.latestVersion._id;
				const data = JSON.parse(JSON.stringify(this.data));

				if(p.onBeforeSave){
					p.onBeforeSave(data);
				}

				let { _id } = await AssetAPI.saveAssetVersion(this.$route.params.assetId, { data, base });

				this.latestVersion = await loadVersion(this.asset?.type || "", this.$route.params.assetId, _id);

				// this.latestVersion = await AssetAPI.getAssetVersion(this.$route.params.assetId, _id, "data base");
				this.modified = false;
				this.resetting = false;
				this.latestData = JSON.parse(JSON.stringify(this.data));
				if(closeTab){
					window.close();
				}
			}
			catch(err){
				console.error(err);
			}
			this.submitting = false;
		},
		async publish(){
			return this.submitVersion(false);
		},
		refreshPageTitle(){
			if(!this.asset){ return; }
			document.title = `${this.modified ? '* ' : '' }${this.asset.name} | Edit`;
		},
		onPageUnload(){
			return this.modified ? "There are unsaved changes." : null;
		}
	},
	created(){
		this.loadAsset();
		window.onbeforeunload = this.onPageUnload;
	},
	destroyed(){
		window.onbeforeunload = null;
		//TODO:REMOVE IN VUE3
	},
	beforeRouteUpdate(to:any, from:any, next:any){

		let samePage = to.name === from.name;

		// check params
		Object.keys(to.params)
		.forEach(k => {
			samePage = samePage && (to.params[k] === from.params[k]);
		});

		if(samePage) { return next(); }

		if(!this.modified){ return next(); }
		
		if (confirmLeave()) {
			next()
		} else {
			next(false)
		}

	},
	beforeRouteLeave (to:any, from:any, next:any) {

		if(!this.modified) { return next(); }
		if (confirmLeave()) {
			next()
		} else {
			next(false)
		}
	}
});
