import { defineComponent, computed, PropType, set } from "@vue/composition-api";
import { FindAsset } from "@/components.assets/find-asset";
import { Vector3, ScenePoint } from "@scene-viewer";
import VectorInput from "../vue/widgets/vector-input.vue";
import { useTranslate } from "@lang";

export const EditPoint = defineComponent({
	components:{
		VectorInput,
		FindAsset
	},
	props:{
		value:{
			type:Object as PropType<ScenePoint>,
			required:true
		}
	},
	setup(props){

		const { translate } = useTranslate();

		const name = computed({

			get:() => props.value.name,
			set(v){
				set(props.value, "name", v);
			}
		});


		const rotation = computed({
			get(){
				if(!props.value.rotation){
					set(props.value, "rotation", { x:0, y:0, z:0 });
				}
				return props.value.rotation;
			},
			set(v:Vector3){
				set(props.value, "rotation", v);
			}
		});

		const position = computed({
			get(){
				if(!props.value.position){
					set(props.value, "position", { x:0, y:0, z:0 });
				}
				return props.value.position;
			},
			set(v:Vector3){
				set(props.value, "position", v);
			}
		});

		return {
			name,
			rotation,
			position,
			translate,
		};
	}
});