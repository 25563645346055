import { WizardContext } from "../types/WizardContext";

export const autoBindProps = (context:WizardContext) => {
	const ds = context.dataSource;
	const rt = context.renderTemplate;

	if(!ds || !rt){ return []; }
	
	const bindings:string[] = [];
	const boundTarget:any = {};
	const boundSource:any = {};


	Object.keys(ds.output)
	.forEach(k => {

		const outputProp = ds.output[k];
		
		Object.keys(rt.props)
		.forEach(pk => {
			
			const temProp = rt.props[pk];

			if(boundTarget[pk] || boundSource[k]){ return; } // already bound

			if(outputProp.type === temProp.type && temProp.noAutoBind !== true){
				boundSource[k] = true;
				boundTarget[pk] = true;
				bindings.push(`${k}->${pk}`);
			}
		});


	});

	return bindings;
}