import { defineComponent } from "@vue/composition-api";
import { useTranslate } from "@lang";

enum Translations {
	NoStudies = "studies.message.noExistingStudies",
}

export const EmptyMessage = defineComponent({
	setup(){
		const { translate } = useTranslate();
		return { translate };
	},
	render(){

		return (
			<div class="d-flex text-light">
				<small class="m-auto">
					{ this.translate(Translations.NoStudies) }
				</small>
			</div>
		)
	}
});