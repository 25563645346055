











import { defineComponent } from "@vue/composition-api";
import { useAddFormItem } from "@form-ui";
import { useTranslate } from "@lang";
import { Icon } from "@ui";

export default defineComponent({
	props:{
		disabled:{
			type:Boolean,
			default:false,
		}
	},
	components:{
		Icon,
	},
	setup(_, context){

		const { add } = useAddFormItem(context);
		const { translate } = useTranslate();

		return {
			add,
			translate,
		};
	}
});

