import * as Utils from "../utils";

type Dictionary<T> = { [k:string]:T };

type FilterConverter = {
	path?:string|((v:string) => string);
	convert(v:any, ob:Dictionary<any>, k:string):void;
	default?:any;
};

const getHourMS = (n:number) => 3600000 * n;

export const filterConverters:Dictionary<FilterConverter> = {
	"sort":{
		convert:(v, ob) => ob["sort"] = v,
	},
	"duration":{
		convert(v, ob){
			const [ min, max ] = Utils.parseInterval(v, 0, getHourMS(2));
			ob["duration[gte]"] = min;
			ob["duration[lte]"] = max;
		},
	},
	"finished":{
		path:"done",
		convert(v:string|number, ob){
			const n = typeof(v) === "string" ? Number(v) : v;
			
			ob["done"] = n === 1 ? true : false;
		},
	},
	"created":{
		convert:(v:string, ob) => {
			ob["created[gte]"] = v.split(",")[0];
			ob["created[lte]"] = v.split(",")[1];
		},
	},
	"progress":{
		convert:(v:string, ob) => {
			const [ min, max ] = Utils.parseInterval(v, 0, 100);
			ob["progress[gte]"] = min;
			ob["progress[lte]"] = max;
		},
	},

	"optionalProgress":{
		convert:(v:string, ob) => {
			const [ min, max ] = Utils.parseInterval(v, 0, 100);
			ob["optionalProgress[gte]"] = min;
			ob["optionalProgress[lte]"] = max;
		},
	},

	"labels":{
		convert(v, ob){

			const q = Utils.parseLabelQuery(v);
			try {
				if(q.exclude.length){
					ob["labels[nin]"] = q.exclude;
				}
	
				if(q.include.length){
					const mod = q.mod || "all";
					ob[`labels[${mod}]`] = q.include;
				}
			}
			catch{}
			
			// let mod = "all";
			// let items = v;

			// let mods = v.split(";");

			// if(mods.length > 1){
			// 	if(["all", "in", "nin"].includes(mods[0])){
			// 		mod = mods[0];
			// 		items = mods[1];
			// 	}
			// }

			// let lbs = items.length > 0 ? items.split(",") : [];
		
			// if(lbs.length === 1 && lbs[0].length === 0){
			// 	lbs = [];
			// }

			// if(lbs.length === 0){ return; }
			// ob[`labels[${mod}]`] = lbs;
		}
	},
};