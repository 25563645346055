// vendor
import { Ref, ref, computed } from "@vue/composition-api";
// project
import * as FavAPI from "@/services/api/favourites";
import { UserFavourite } from "@/psychlab/types/favourites";


let loaded = false;

let _favourites:Ref<UserFavourite[]>;

export const useFavourites = () => {

	const init = async () => {
		_favourites.value = await FavAPI.getFavourites();
	};

	if(!loaded){
		init();
		_favourites = ref<UserFavourite[]>([]);
		loaded = true;
	}

	const favourites = computed(() => [ ..._favourites.value ]);

	const addFavourite = async (item:string, type:"asset"|"study"|"tag") => {
		const f = await FavAPI.addFavourite(item, type);
		_favourites.value.push(f);
	};

	const removeFavourite = async(item:string) => {
		await FavAPI.removeFavourite(item);
		const i = _favourites.value.findIndex(f => f.item === item);
		if(i > -1){
			_favourites.value.splice(i, 1);
		}
	};

	const isFavourite = (item:string) => _favourites.value.findIndex(f => f.item === item) > -1;

	return {
		favourites,
		addFavourite,
		removeFavourite,
		isFavourite,
	};

};