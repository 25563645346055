











































































// vendor
import { defineComponent, computed, ref, onMounted, onUnmounted, onBeforeMount, watch, onBeforeUnmount } from "@vue/composition-api";
import { format } from "date-fns";
// project
import * as StudyAPI from "@/services/api/studies";
// import { formatDateStringRecent } from "@/utils/text";
// local
import { useElementResize } from "./hooks/useElementResize";
import { useCalendarGrid, GridCell } from "./hooks/useCalendarGrid";
import { generateGUID } from "@utils/guid";
import { useTranslate } from "@lang";
import { Date as DisplayDate } from "@ui";

const computeHeight = (w:number) => {
	const s = w / 53;
	return s * 7; 
};

const getCellTooltip = (c:GridCell) => {
	const ptr = "eeee MMM d, y";
	return `
	<small>
	${c.count} Session(s)
	<br/>
	${format(new Date(c.time), ptr)}
	</small>`;
};

export default defineComponent({
	props:{
		studyId:{
			type:String,
			required:true,
		},
	},
	components:{
		DisplayDate,
	},
	setup(props, context){

		const canvasRoot = ref<HTMLElement>();
		const calendarCanvas = ref<SVGElement>();

		const timestamps = ref<number[]>([]);

		const cellPadding = ref(1);
		const cellRounding = ref(2);


		const { translate } = useTranslate();


		const {
			width,
			init:initResize,
			dispose:disposeResize,
		} = useElementResize(canvasRoot);

		const {
			cells,
			init:initCalendar,
		} = useCalendarGrid(timestamps);

		const cellIds = computed(() => {
			return cells.value.map(() => generateGUID());
		});

		const height = computed(() => {
			return computeHeight(width.value);
		});

		const cellSize = computed(() => {
			return height.value > 0 ? height.value / 7 : 0;
		});

		const innerCellSize = computed(() => {
			const s = cellSize.value - cellPadding.value * 2;
			return s >= 0 ? s : 0;
		})


		const openCell = (cell:GridCell, tab?:boolean) => {

			const r = getCellRoute(cell);

			if(tab){
				window.open((context.root.$router.resolve(r)).href, "_blank");
			}
			else {
				context.root.$router.push(getCellRoute(cell));
			}

		};
		
		const getCellRoute = (cell:GridCell) => {
			const start = format(new Date(cell.time), "y-MM-dd");
			const end = format(new Date(cell.time + 86400000), "y-MM-dd");
			return {
				name:"Run.Group.Sessions",
				params:{
					groupId:props.studyId,
				},
				query:{
					created:`${start},${end}`,
				}
			}
		};

		const init = async() => {
			timestamps.value = await StudyAPI.getSessionCalendar(props.studyId);
			initCalendar();
		};

		onMounted(() => {
			initResize();
			
		});
		onBeforeMount(init);
		onBeforeUnmount(disposeResize);
		

		return {
			cells,
			width,
			height,
			canvasRoot,
			calendarCanvas,
			// displayCells,
			cellSize,
			getCellTooltip,
			cellPadding,
			cellRounding,
			innerCellSize,
			getCellRoute,
			openCell,
			translate,
			cellIds,
		};
	}
});

