// vendor
import { defineComponent, PropType, computed, watch, set, ref, onBeforeUpdate, reactive } from "@vue/composition-api";
import Draggable from "vuedraggable";
// project
import { generateGUID  } from "@/utils/guid";
import { move as moveItem } from "@/utils/array";
// local
import { ReorderableConfig as Config } from "./ReorderableConfig";

type PlaceholderFn = (index:number) => string;

const defaults:Config = {
	options:[],
	handleVariant:"none",
	handleTextVariant:"light",
	borderVariant:"secondary",
	addVariant:"outline-secondary",
	deleteVariant:"outline-secondary",
	deleteTextVariant:"none",
	addText:"Add Option",
	inputVariant:"light",
	inputBoxVariant:"default",
};

const dragSettings = {
	animation: 200,
	disabled: false,
	ghostClass: "ghost",
};

export const ReorderableList = defineComponent({
	
	props:{
		config:{
			type:Object as PropType<Config>,
			required:true
		},
		addText:{
			type:String,
			default:"Add Option"
		},
		size:{
			type:String,
			default:"sm"
		},
		placeholderFn:{
			type:Function as PropType<PlaceholderFn>
		}
	},
	components: {
		Draggable
	},
	setup(props){

		const inputs = ref([]);

		const displayItems = computed({
			get:() => props.config.options,
			set:v => { } // handled by drag
		});
		
		const conf = computed(() => {

			const v:any = {
				...defaults,
			};

			if(props.addText){
				v["addText"] = props.addText;
			}

			if(props.placeholderFn){
				v["placeholder"] = props.placeholderFn;
			}

			return {
				...v,
				...props.config
			}
		});

		const markupId = computed(() => generateGUID());

		watch(() => displayItems.value.length, (v, o) => {
			if(v > o){ focusInput(v-1); }
		});

		const getControlId = (index:number) => {
			return `${markupId.value}_${index}`;
		}

		const onInputChange = (index:number, $event:any) => {
			set(props.config.options, index, $event);
		};

		const moveToOption = (index:number, $event:any) => {
			if(index >= 0 && index < displayItems.value.length){
				focusInput(index);
			}
		};

		const onBackspace = (index:number, $event:any) => {
			let v = displayItems.value[index];
			if(!v || v.length === 0){
				setTimeout(() => {
					deleteOption(index);
					if(index > 0){
						focusInput(index - 1);
					}
				}, 1);
				return;
			}
		};

		const confirmInput = (index:number, $event:any) => {
			if(index === displayItems.value.length - 1){
				addOption();
			}else{
				focusInput(index + 1);
			}
		};

		const getPlaceholder = (index:number) => {
			// return props.placeholderFn ? props.placeholderFn(index) : "";
			const fn = conf.value["placeholder"];
			return fn ? fn(index) : "";
		};

		const focusInput = (index:number) => {
			setTimeout(() => {
				const e = document.getElementById(getControlId(index)) as HTMLInputElement;
				if(!e){ return; }
				e.focus();
			}, 1);
		};

		const addOption = () => {
			props.config.options.push("");
		};

		const deleteOption = (index:number) => {
			props.config.options.splice(index, 1);
		};

		const onDragged = (state:{ moved:any }) => {
			const { moved } = state;
			if(moved){
				moveItem(props.config.options, moved.oldIndex, moved.newIndex);
			}
		};


		return {
			inputs,
			dragSettings,
			displayItems,
			markupId,
			conf,
			addOption,
			onInputChange,
			moveToOption,
			onBackspace,
			confirmInput,
			getPlaceholder,
			deleteOption,
			onDragged,
			getControlId
		};
	}


});