import { defineComponent, ref, computed, PropType, watch, inject } from "@vue/composition-api";
import * as SessionAPI from "@/services/api/sessions";
import { Form } from "@/psychlab/types/form";
import RunFormItem from "../../vue/run-form-item.vue";
import { getDisplayForm } from "../utils/getDisplayForm";

export const RunForm = defineComponent({

	props:{
		form:{
			type:Object as PropType<Form>,
			required:true
		},
		dataNamespace:{
			type:String,
			required:true
		},
		showBack:{
			type:Boolean,
			default:true
		}

	},
	components: {
		RunFormItem
	},
	setup(props, context){

		const currentIndex = ref(0);
		const loading = ref(false);

		const sessionId = computed(() => context.root.$route.params["sessionId"]);

		const displayForm = computed(() => {
			return getDisplayForm(props.dataNamespace, props.form);
		});

		const items = computed(() => displayForm.value.items);

		const cache = computed(() => context.root.$store.state.cache["storage"]);

		const setProgressStep = inject<(s:string) => void>("setProgressStep");

		watch(currentIndex, (i) => {
			SessionAPI.cacheSessionValue(sessionId.value, `${props.dataNamespace}.index`, i);
			cache.value[`${props.dataNamespace}.index`] = i;
			refreshProgress();
		});

		const previous = (index:number, itemKey:string) => {
			if(index - 1 < 0){
				context.emit("previous");
				return;
			}
			currentIndex.value = index - 1;
		};

		const next = async(index:number, itemKey:string, data:any) => {
			if(loading.value){ return; }
			loading.value = true;
			try {
				await SessionAPI.saveDataChannels(sessionId.value, data.channels);
				data.channels.forEach((ch:any) => {
					cache.value[`${ch.key}.saved`] = ch.data;
					SessionAPI.cacheSessionValue(sessionId.value, `${ch.key}.saved`, ch.data);
				});
				if(index + 1 >= items.value.length){
					context.emit("next");
					return;
				}
				currentIndex.value = index + 1;
			}
			catch(err){
				console.error(err);
			}
			loading.value = false;
		};

		const refreshProgress = () => {
			if(!setProgressStep){ return; }
			if(currentIndex.value < 0){ return; }
			const item = displayForm.value.items[currentIndex.value];
			setProgressStep(item.key);
		};

		const init = () => {
			const sessionId = context.root.$route.params["sessionId"];
			const v = cache.value[`${props.dataNamespace}.index`];
			if(v){ currentIndex.value = v; }
			refreshProgress();
		};

		init();

		return {
			items,
			currentIndex,
			cache,
			previous,
			next
		};
	}


});