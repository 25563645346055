





import { defineComponent, computed } from "@vue/composition-api";

export default defineComponent({
	props: {
		name: {
			type: String,
			default: "square"
		},
		spin:{
			type: Boolean,
			default:false,
		},
		shadow:{
			type: Boolean,
			default:false,
		},
		glow:{
			type: Boolean,
			default:false,
		}
	},
	setup(props){

		const info = computed(() => {
			const [ p1, p2 ] = props.name.split(".");
			return p1 ? {
				icon:p2 ? p2 : p1,
				family:p2 ? p1 : "mdi"
			} : { icon:"square", family:"mdi" }
		});

		const cls = computed(() => {
			const { icon, family } = info.value;
			const spin = props.spin ? "mdi-spin" : "";
			const glow = props.glow ? "glow" : "";
			return `${family} ${family}-${icon} ${spin} ${glow}`;
		});

		const style = computed(() => {
			const shadow = props.shadow ? "text-shadow: 1px 1px 2px rgb(32, 32, 32)" : "";
			return `${shadow}`;
		});

		return {
			cls,
			style,
		}
	}
});

