







import { defineComponent, computed } from "@vue/composition-api";
export default defineComponent({
	props:{
		fluid:{
			type:Boolean,
			default:false,
		}
	},
	setup(props){
		
		const cls = computed(() => {
			let r = `container${props.fluid ? '-fluid' : ''}`;
			return r;
		});

		return {
			cls
		};
	}
});

