import { computed, ref, watch, SetupContext, Ref } from "@vue/composition-api";
import { getQueryValue, setQueryValue, clampNumber } from "./utils";
import { useTranslate } from "@lang";
import * as AppModal from "@/AppModal";
import { ContextOption } from "@ui";
import { Study } from "@/psychlab/types/studies";
import * as StudyAPI from "@/services/api/studies";
import { useFavourites } from "@/hooks/useFavourites";
import { default as Router } from "vue-router";

enum RouteNames {
	StudyDashboard = "Run.Group",
}

enum Translations {
	ShowingPagination = "label.showingPagination",
	OpenStudyDashboard = "studies.action.openDashboard",
	Rename = "action.rename",
	RenameStudy = "studies.action.renameStudy",
	SetFavourite = "action.favourite",
	RemoveFavourite = "action.unfavourite",
	BlueprintVersion = "studies.label.blueprintVersion",
	ClosingStudy = "studies.message.onClosingStudy",
	OpeningStudy = "studies.message.onOpeningStudy",
	CloseStudy = "studies.action.closeStudy",
	OpenStudy = "studies.action.openStudy",
	Understood = "prompts.action.understood",
	Cancel = "prompts.action.cancel",
	Sharing = "label.sharing",
	Sessions = "studies.label.sessions",
	Close = "action.close",
	Open = "action.open",
	Name = "label.name",
	Created = "label.created",
	LastVisited = "label.lastVisited",
}


type Config = {
	context:SetupContext;
	queryPrefix:string;
};

const limitOptions = [ 5, 10, 20, 50 ] as const;

export const usePagination = (c:Config) => {

	const { translate } = useTranslate();

	const { context, queryPrefix } = c;

	const pageOptions = computed(() => limitOptions.map(v => ({ value:v, text:v.toString() })));
	
	const limit = ref(Number(getQueryValue(`${queryPrefix}_limit`, context, 10)));

	const total = ref(0);

	const currentPage = ref(Number(getQueryValue(`${queryPrefix}_page`, context, 1)));

	const footerText = computed(() => {
		const mnl = ((currentPage.value - 1) * limit.value + 1).toString();
		const mxl = (clampNumber((currentPage.value - 1) * limit.value + limit.value, 0, total.value)).toString();
		const tl = total.value.toString();

		return translate(Translations.ShowingPagination)
		.replace("$x", mnl)
		.replace("$y", mxl)
		.replace("$z", tl);
	});

	watch(limit, v => setQueryValue(`${queryPrefix}_limit`, v, context));

	watch(currentPage, v => setQueryValue(`${queryPrefix}_page`, v, context));

	return {
		pageOptions,
		total,
		perPage:limit,
		currentPage,
		footerText,
	};
};

const openStudyRoute = (studyId:string, router:Router) => {
	router.push({
		name:RouteNames.StudyDashboard,
		params:{ groupId:studyId }
	});
};

export const useStudyContextMenu = (cfg:{
	sort:{
		field:string;
	};
	router:Router;
	refresh:() => void;
}) => {

	const { translate } = useTranslate();
	const { isFavourite, addFavourite, removeFavourite } = useFavourites();

	const { sort, refresh, router } = cfg;


	const renameStudy = async (study:Study, name:string) => {
		try {
			await StudyAPI.setStudyName(study._id, name);
			study.name = name;
		}
		catch(err:any){
			console.log(err.message);
		}
		if((sort.field || "").includes("name")){
			refresh();
		}
	};

	const toggleStudyEnabled = (study:Study) => {
		const title = translate(study.enabled ? Translations.CloseStudy : Translations.OpenStudy);
		const description = translate(study.enabled ? Translations.ClosingStudy : Translations.OpeningStudy);
	
		const variant = study.enabled ? "danger" : "info";
		AppModal.confirm({
			title,
			variant,
			okText:translate(Translations.Understood),
			description,
			cancelText:translate(Translations.Cancel),
		}, async () => {
			try { await StudyAPI.setStudyEnabled(study._id, !study.enabled); }
			catch(err:any){ console.log(err.message); }
			refresh();
		});
	};

	const open = (e:any, study:Study) => {
		const options:ContextOption[] = [];
		options.push({
			icon:"home-analytics",
			name:translate(Translations.OpenStudyDashboard),
			fn:() => openStudyRoute(study._id, router),
			order:-1,
		});
		options.push({
			icon:"pencil",
				name:translate(Translations.Rename),
				fn(){
					AppModal.editText({
						title:translate(Translations.RenameStudy),
						value:study.name,
						validator:v => v.length > 1 && v !== study.name,
					}, (r) => renameStudy(study, r))
				},
		});

		if(!isFavourite(study._id)){
			options.push({
				icon:"star",
				name:translate(Translations.SetFavourite),
				fn:() => addFavourite(study._id, "study"),
				order:3,
			});
		}
		else {
			options.push({
				icon:"star-outline",
				name:translate(Translations.RemoveFavourite),
				fn:() => removeFavourite(study._id),
				order:3,
			});
		}

		options.push({
			icon:study.enabled ? "toggle-switch-off-outline" : "toggle-switch",
			name:translate(study.enabled ? Translations.Close : Translations.Open),
			fn:() => toggleStudyEnabled(study),
		});
		AppModal.context(e, options);
	};


	return {
		open
	};
};