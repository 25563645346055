// vendor
import * as THREE from "three";
// local
import { getUrlType } from "./getUrlType";
import { videoToCanvas } from "./toCanvas";
import { createImageSphere } from "./createImageSphere";
import { createVideoSphere } from "./createVideoSphere";
import { IPanoramaScene } from "../types";

export const createScene = async(sources:string[], parent:HTMLElement, time:number) => {
	switch(getUrlType(sources)){
		case "vid": return await createVideoScene(sources, parent, time);
		case "img": return await createImageScene(sources, parent);
	}
	return null;
};

// local
export const createImageScene = async(sources:string[], parent:HTMLElement):Promise<IPanoramaScene|null> => {
	const {
		img,
		sphere,
		canvas,
		texture,
	} = await createImageSphere(sources, parent);

	const captureFrame = async (s:number) => {
		return texture;
	};

	const toCanvas = async (s:number) => {
		return canvas;
	};

	return {
		domElement:img,
		sceneObject:sphere,
		setPlayRate:() => {},
		getTime:() => 0,
		captureFrame,
		toCanvas,
	};
};

export const createVideoScene = async(sources:string[], parent:HTMLElement, time:number):Promise<IPanoramaScene> => {
	const {
		video,
		videoRoot,
		sphere,
		videoSources
	} = await createVideoSphere(sources, parent, time);

	const toCanvas = async (s:number) => {
		return await videoToCanvas(video, s);
	};

	const captureFrame = async (s:number) => {
		return new THREE.CanvasTexture(await toCanvas(s));
	};

	const setPlayRate = (t:number) => {
		const pbt = 1 - t;
		video.playbackRate = 0.1 + (1 - 0.1) * pbt;
	};

	const getTime = () => video.currentTime;

	const dispose = () => {
		video.pause();
		video.setAttribute("src", "");
		videoSources.forEach(vs => {
			vs.src = "";
			vs.remove();
		});
		video.remove();
		// video.offlz
		videoRoot.remove();
	};

	return {
		domElement:videoRoot,
		sceneObject:sphere,
		getTime,
		setPlayRate,
		captureFrame,
		toCanvas,
		dispose,
	};
};