





























import { defineComponent, computed } from "@vue/composition-api";
import { RenderMarkdown } from "@ui";
import { ItemBox, getBaseProps } from "../shared";
import { AbsBox } from "@ui";
import { useTranslate } from "@lang";
import { Hoverlay } from "../shared";
import { EmptyContentBox } from "../editor";
import { EditVideo } from "../edit-video";
import { useVideoEmbed, VideoConfig } from "./useVideoEmbed";

export default defineComponent({
	emit:[ "edit" ],
	props:{
		url:{
			type:String,
			default:""
		},
		loop:{
			type:Boolean,
			default:false,
		},
		autoplay:{
			type:Boolean,
			default:false,
		},
		width:{
			type:Number,
			default:50,
		},
		...getBaseProps()
	},
	components:{
		RenderMarkdown,
		ItemBox,
		AbsBox,
		Hoverlay,
		EmptyContentBox,
		EditVideo,
	},
	setup(props){

		const { translate } = useTranslate();

		const bgColor = computed(() => {
			return props.theme["media.video.background.color"] || "#000";
		});

		const videoConfig = computed<VideoConfig>(() => {
			return {
				url:props.url,
				loop:props.loop,
				autoplay:props.autoplay,
				width:props.width,
				background:"transparent",
			};
		});

		const {
			embedContent:content,
		} = useVideoEmbed(videoConfig);

		const contentStyle = computed(() => {
			return `background:${bgColor.value}`;
		})

		return {
			content,
			contentStyle,
			translate,
		};
	},
});
