
export const generateGUID = () => SN(8);

const S4 = () => (((1+Math.random())*0x10000)|0).toString(16).substring(1);

const SN = (n:number) => {
	var s = "";
	for(var i = 0; i < n; i++){
		s += S4();
	}
	return s.toLowerCase();
};
