




























// vendor
import { computed, defineComponent } from "@vue/composition-api";
import { default as tinycolor } from "tinycolor2";
import { Icon } from "../icons";

export default defineComponent({
	emits: [ "click", "remove" ],
	props: {
		label: {
			type: String,
			required: true,
		},
		color: {
			type: String,
			default:"gold",
		},
		disabled:{
			type: Boolean,
			default: false,
		},
		removable: {
			type: Boolean,
			default: true,
		},
	},
	components:{
		Icon
	},
	setup(props, ctx) {

		const textColor = computed(() => isDark() ? "#f8f9fa" : "#22262b");

		const isDark = () => tinycolor(props.color).isDark();

		const onClick = (e:Event) => {
			if(props.disabled){ return; }
			e.stopPropagation();
			ctx.emit("click");
		};

		const onRemove = (e:Event) => {
			e.stopPropagation();
			ctx.emit("remove");
		};

		return {
			onClick,
			onRemove,
			textColor,
		};
	},
});
