import { defineComponent } from "@vue/composition-api";
import { Portal } from "portal-vue";

export const ContextHeader = defineComponent({
	render(){
		return (
			<Portal to="context-header">
				{this.$slots.default}
			</Portal>
		)
	}
});