import * as AssetAPI from "@/services/api/assets";

export const getLatestAssetVersions = async (assets:string[]) => {
	const result:Record<string, string|null> = {};
	await Promise.all(assets.map(a => {
		return new Promise<void>(async (resolve, reject) => {
			try {
				result[a] = await getLatestVersion(a);
				resolve();
			}
			catch(err){
				result[a] = null;
				resolve();
			}

		})
	}));
	return result;
};


const getLatestVersion = async (asset:string) => {
	const v = await AssetAPI.getAssetVersion(asset, "latest");
	return v._id;
}