









































































































































































































// vendor
import { defineComponent, computed, ref, set, PropType } from "@vue/composition-api";
import { DisplayJSON } from "@ui";
import { Form } from "@/psychlab/types/form";
import { AbsBox } from "@ui";
import { useTranslate } from "@lang";

const icons = {
	"Answer":"mdi.card-text",
	"Checkboxes":"mdi.card-text",
	"MultipleChoice":"mdi.radiobox-marked",
	"Dropdown":"mdi.chevron-down-box",
	"LinearScale":"mdi.electric-switch-closed",
	"Information":"mdi.information-outline",
	"HTML":"mdi.code-tags",
};

export default defineComponent({

	emits:[],
	props:{
		data:{
			type:Object as PropType<Form>,
			required:true,
		}
	},
	components:{
		DisplayJSON,
		AbsBox,
	},
	setup(props){

		const {
			translate,
		} = useTranslate();

		const foldouts = ref<{ [k:string]:boolean }>({});

		const showSettings = computed(() => {
			if(!props.data || !props.data.settings){ return false; }
			return Boolean(getSectionShuffleTooltip(props.data.settings.sectionSelection || ""));
		});

		const getShuffleIcon = (m:string) => {
			return (({
				"shuffle":"mdi.shuffle",
				"select":"mdi.numeric-1-box"
			} as any)[m] || "mdi.shuffle-disabled");
		};

		const getItemShuffleTooltip = (m:string) => {
			return (({
				"shuffle":translate("forms.action.shuffle"),
				"select":translate("forms.action.selectOne")
			} as any)[m] || "");
		};

		const getSectionShuffleTooltip = (m:string) => {
			return (({
				"shuffle":translate("forms.action.shuffle"),
				"select":translate("forms.action.selectOne")
			} as any)[m] || "");
		};

		const toggleFoldout = (k:string) => {
			set(foldouts.value, k, !Boolean(foldouts.value[k]));
		};

		const isEmptyObject = (ob:any) => {
			return Object.keys(ob).length === 0;
		};

		return {
			icons,
			showSettings,
			getShuffleIcon,
			foldouts,
			toggleFoldout,
			getItemShuffleTooltip,
			getSectionShuffleTooltip,
			isEmptyObject,
			translate,
		};
	}
});

