/** @format */

// project
import { http } from "@/http";

export const refreshWidget = async (study: string, widget: string) => {
	const { data } = await http.post(
		`studies/${study}/analytics/widgets/${widget}`,
	);
	return data;
};