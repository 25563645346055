import { inject, ComputedRef } from "@vue/composition-api";
import { IFormTheme } from "@psychlab/types/form";

export const useFormTheme = () => {

	const theme = inject<ComputedRef<IFormTheme>>("formTheme")

	if(!theme) {
		throw new Error("Error: Form theme not set");
	}

	return {
		theme,
	};
};