





































import { defineComponent, computed, ref, watch, PropType, reactive, onMounted, onBeforeUnmount, inject } from "@vue/composition-api";
import { TweenLite } from "gsap";
import * as SessionAPI from "@/services/api/sessions";
import {
	SceneViewer,
	ScenePoint,
	Viewer,
	useDeviceCamera,
	IDeviceCamera,
} from "@scene-viewer";
import { AbsBox } from "@ui";
import { RunForm } from "@/components.run/run-form-docked";
import { SessionContext } from "@components/sessions";
import { DefaultNav } from "./DefaultNav";
import { FadeOverlay } from "./FadeOverlay";

const doFade = (time:number, fade:{ opacity:number }, fn:() => void) => {
	TweenLite.to(fade, time * 0.5, { "opacity": 1 });
	setTimeout(() => {
		TweenLite.to(fade, time * 0.5, { "opacity": 0 });
		fn();
	}, (fadeTime * 1000) + 1);
}

const fadeTime = 0.5;

type FormController = {
	skipEnd:() => void,
	skipStart:() => void
}

type SceneParameters = {
	points:ScenePoint[],
	paths:string,
}

export default defineComponent({

	props:{
		dataNamespace:{
			type:String,
			required:true
		},
		parameters:{
			type:Object as PropType<SceneParameters>,
			required:true
		},
		isLast:{
			type:Boolean,
			default:false
		},
		showBack:{
			type:Boolean,
			default:false
		}
		
	},
	components: {
		SceneViewer,
		RunForm,
		DefaultNav,
		FadeOverlay,
		AbsBox,
	},
	setup(props, context){

		const getSessionContext = inject<() => SessionContext>("getSessionContext");

		if(!getSessionContext){
			throw Error("Run Scene: Session context missing!");
		}

		const {
			accelerometerEnabled,
			setProgressStep,
		} = getSessionContext();


		const sceneViewer = ref<Viewer>();

		const loadedScenes = ref<string[]>([]);
		const stopIndex = ref(0);
		const fade = reactive({
			opacity:0,
			color:"#000000"
		});

		const camInit = ref(false);

		let viewCamera!:IDeviceCamera;

		const cache = computed(() => context.root.$store.state.cache["storage"])
		const onLast = computed(() => props.isLast);

		const stops = computed(() => {
			const points:ScenePoint[] = props.parameters.points || [];
			return points.map(point => {
				return {
					guid:point.guid,
					dataNamespace:`${props.dataNamespace}/${point.guid}`,
					form:point.form
				};
			});
		});


		const assetPaths = computed(() => {
			return props.parameters.paths || "";
		});

		// const setProgressStep = inject<(s:string) => void>("setProgressStep");
		const setProgressReady = inject<(v:boolean) => void>("setProgressReady");

		watch(stopIndex, (v) => {
			SessionAPI.cacheSessionValue(context.root.$route.params["sessionId"], `${props.dataNamespace}.stopIndex`, v);
			cache.value[`${props.dataNamespace}.stopIndex`] = v;
			refreshProgress();
		});

		watch(camInit, (v) => {
			if(!setProgressReady){ return; }
			setProgressReady(v);
		});

		const getForm = (index:number) => {
			// VUE3TODO: Use function refs
			const x:any = context.root.$refs[`Form/${index}`];
			return x && x.length > 0 ? x[0] as FormController : null;
		};

		const next = (index:number) => {
			const i = index + 1;
			if(i < stops.value.length){
				doFade(fadeTime, fade, () => {
					setIndex(i);
					setTimeout(() => {
						const form = getForm(i);
						if(form){ form.skipStart(); }
					}, 1);
				});
			}
			else {
				context.emit("end");
			}
		};

		const setIndex = (i:number) => {
			if(!viewCamera){ return; }
			stopIndex.value = i;
			const p = props.parameters.points[i];
			viewCamera.setPosition(p.position);
			viewCamera.setRotation(p.rotation);
		};

		const previous = (index:number) => {
			const i = index - 1;
			if(i < 0){
				context.emit("previous");
				return;
			}
			doFade(fadeTime, fade, () => {
				setIndex(i);
				setTimeout(() => {
					const form = getForm(i);
					if(form){ form.skipEnd(); }
				}, 1);
			});
		};


		const initCam = async() => {

			if(!sceneViewer.value){ return; }

			try {
				const camConfig = { accelerometer:accelerometerEnabled.value };
				// if(DeviceUtils.isMobile()){
				// 	camConfig.accelerometer = await DeviceUtils.hasDeviceOrientation();
				// }
				viewCamera.init(camConfig);
				sceneViewer.value.setCamera(viewCamera.camera);
				setIndex(stopIndex.value);
			}
			catch(err){
				console.error(err);
			} 
			camInit.value = true;
		};

		const refreshScene = () => {
			loadedScenes.value = assetPaths.value.split("|");
		};
		
		const refreshProgress = () => {
			if(!setProgressStep){ return; }
			if(stopIndex.value < 0){ return; }
			const s = stops.value[stopIndex.value];
			const f = s.form;
			if(!f){
				setProgressStep(`${props.dataNamespace}/${s.guid}`);
			}
		};

		const init = () => {
			const sessionId = context.root.$route.params["sessionId"];
			const sc = SessionAPI.getSessionCache(sessionId);
			if(sc){
				Object.keys(sc).forEach(k => context.root.$store.commit("cache/setValue", { k, v:sc[k] }));
			}
			const v = cache.value[`${props.dataNamespace}.stopIndex`];
			if(v){ stopIndex.value = v; }
			refreshProgress();

			if(setProgressReady){
				setProgressReady(camInit.value);
			}
		};

		onMounted(async() => {
			if(!sceneViewer.value){ return; }
			const el = sceneViewer.value.getDOMElement();
			if(!el){ return; }
			if(assetPaths.value){
				loadedScenes.value = assetPaths.value.split("|");
			}
			// viewCamera = new DeviceCamera(el);
			viewCamera = useDeviceCamera(el);

			setIndex(stopIndex.value);

			initCam();
		});

		onBeforeUnmount(() => {
			if(viewCamera && viewCamera.destroy){
				viewCamera.destroy({});
			}
		});
		
		
		init();

		return {
			sceneViewer,
			loadedScenes,
			stopIndex,
			fade,
			camInit,
			onLast,
			stops,
			next,
			previous,
			initCam,
			refreshScene
		};
	}

});

