












import { defineComponent } from "@vue/composition-api";
import { EmbedVideo } from "@psychlab/types/form";
import { RenderVideo } from "@form-ui";
import { getItemProps } from "./getItemProps";

export default defineComponent({
	props:{
		...getItemProps<EmbedVideo>()
	},
	components:{
		RenderVideo,
	},
	setup(props){

		const setUrl = (url:string) => {
			if(!props.data){ return; } // this is unnecessary, fu vue
			props.data.settings.url = url;
		};

		const setWidth = (w:number) => {
			if(!props.data){ return; } // this is unnecessary, fu vue
			props.data.settings.width = w;
		};

		return {
			setUrl,
			setWidth,
		};
	}
});

