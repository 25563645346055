








import { defineComponent } from "@vue/composition-api";
import { AbsBox } from "@ui";

export default defineComponent({
	components:{
		AbsBox,
	}
});

