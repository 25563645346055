
export const parseAssetRef = (v:any) => {
	if(!v){ return null; }
	if(typeof(v) === "string"){
		const [ asset, version ] = v.split("@");
		if(asset && version){ return v; }
	}

	if(v.ref){
		const { asset, version } = v.ref;
		if(typeof(asset) === "string" && typeof(version) === "string"){
			return `${asset}@${version}`;
		}
	}
	return null;
}