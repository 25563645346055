

















































	import { defineComponent, computed, ref } from "@vue/composition-api";
	import { useTranslate } from "@lang";
	import { isMobile } from "@utils/platform";
import { generateGUID } from "@utils/guid";

	enum Translations {
		Back = "session.action.back",
		Continue = "session.action.continue",
		Finish = "session.action.finish",
		Hide = "session.action.hide",
		Show = "session.action.show",
	}

	const DockButton = {
		props:[ "i", "t", "d", "v", "tv" ],
		template:`
		<button
		@click="!d ? $emit('click', $event) : () => {}"
		:disabled="d"
		class="btn dock-btn shadow"
		:class="'btn-' + v + ' text-' + tv"
		v-b-tooltip="{
			disabled:d,
			title:t,
			interactive:false,
			delay:0,
			animation:false,
			variant:v,
		}"
		>
			<span class="mdi"  :class="'mdi-' + i"></span>
		</button>
		`
	};

	export default defineComponent({
		props:{
			showBack: {
				type: Boolean,
				default: true,
			},
			loading: {
				type: Boolean,
				default: false,
			},
			onLast: {
				type: Boolean,
				default: false,
			},
			open: {
				type: Boolean,
				default: true,
			},
		},
		components:{
			DockButton,
		},
		setup(props, context){
			const { translate } = useTranslate();
			
			const mobile = isMobile();

			const toggleKey = ref(generateGUID());


			const toggleBtn = computed(() => {
				const icon = props.open ? 'eye-off' : 'eye';

				const tooltip = mobile ? "" : translate(props.open ? Translations.Hide : Translations.Show);

				return {
					key:toggleKey,
					icon,
					tooltip,
					click(){
						toggleKey.value = generateGUID();
						context.emit("toggle");
					},
				};
			});

			const phrases = computed(() => {

				const base = {
					hide:translate(Translations.Hide),
					show:translate(Translations.Show),
				};

				if(mobile){
					return {
						...base,
					};
				}

				return {
					back: translate(Translations.Back),
					next: translate(!props.onLast ? Translations.Continue : Translations.Finish),
					...base,
				}
			});

			return {
				phrases,
				toggleBtn,
			};
		}
	});

