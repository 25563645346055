




























import { defineComponent, computed, ref, inject, PropType } from "@vue/composition-api";
import { Gallery } from "@/psychlab/types/gallery";
import { move } from "@/utils/array";
import { default as ListItem } from "./list-item.vue";
import { createGalleryItem } from "../ts";

export default defineComponent({
	props:{
		data:{
			type:Object as PropType<Gallery>,
			required:true,
		}
	},
	components:{
		ListItem
	},
	setup(props){
		const displayItems = computed(() => {
			return props.data.items
			.map((item, index) => {

				return {
					id:item.id,
					data:item,
					delete(){
						props.data.items.splice(index, 1);
					},
					up(){
						move(props.data.items, index, index - 1);
					},
					down(){
						move(props.data.items, index, index + 1);
					},
				}

			});
		});

		const addItem = () => {
			props.data.items.push(createGalleryItem());
		};

		return {
			addItem,
			displayItems,
		};
	}
});

