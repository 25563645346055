import { PhraseDictionary } from "../types";
import { mergeDictionaries } from "../mergeDictionaries";

const label:PhraseDictionary = {
	deviceSetup:{
		en:"Device Setup",
		is:"Uppsetning snjalltækis"
	},
};

const action:PhraseDictionary = {
	continue:{
		en:"Continue",
		is:"Áfram"
	},
	resume:{
		en:"Resume",
		is:"Halda áfram"
	},
	start:{
		en:"Start",
		is:"Byrja",
	},
	back:{
		en:"Back",
		is:"Til baka"
	},
	hide:{
		en:"Hide",
		is:"Fela"
	},
	show:{
		en:"Show",
		is:"Sýna"
	},
	rerun:{
		en:"Re-Run",
		is:"Endurkeyra"
	},
	finish:{
		en:"Finish",
		is:"Ljúka"
	},
	openStudy:{
		en:"Open Survey",
		is:"Opna könnun"
	},
};

const message:PhraseDictionary = {
	procedureHasFinished:{
		en:"Procedure has finished",
		is:"Keyrslu er lokið",
	},
	thanksForParticipating:{
		en:"Thank you for participating",
		is:"Takk fyrir þátttökuna",
	},
	answersSaved:{
		en:"Answers have been saved in database",
		is:"Svör við spurningum hafa verið vistuð í gagnagrunni"
	},
	pleaseSelectOneOption:{
		en:"Please select at least one option",
		is:"Vinsamlegast veldu að minnsta kosti einn valkost"
	},
	pleaseSelectOption:{
		en: "Please select an option",
		is: "Vinsamlegast veldu valkost",
	},
	pleaseEnterValue:{
		en:"Please enter a value",
		is:"Vinsamlegast fylltu inn gildi"
	},
	pleaseFillOptions:{
		en:"Please fill out options",
		is:"Vinsamlegast fylltu inn valkosti"
	},
	formIsInvalid:{
		en:"To continue, please fill out required information above",
		is:"Til að halda áfram, vinsamlegst fyllið inn upplýsingar sem vantar hér að ofan"
	},
	surveyHasBeenClosed:{
		en:"This link has been closed",
		is:"Þessum tengli hefur verið lokað"
	},
	sessionInProgress:{
		en:"Session in progress",
		is:"Keyrsla í gangi"
	},
	pleaseEnableMotion:{
		en:"Please continue to enable motion controls on your device.",
		is:"Vinsamlegast haltu áfram til að virkja hreyfivirkni."
	},
	facebookWarningTitle:{
		en:"...hang on!",
		is:"...bíddu aðeins!"
	},
	facebookWarningInfo:{
		en:`You appear to be visiting through <span class="font-weight-bold">facebook</span>.`,
		is:`For our site to work correctly please switch to a normal browser window.`
	},
	facebookWarningSwitch:{
		en:`For our site to work correctly please switch to a normal browser window.`,
		is:`Til að síðan okkar virki rétt vinsamlegast skiptu yfir í venjulegan vafraglugga.`
	},
};

export const session = mergeDictionaries("session", { label, action, message });