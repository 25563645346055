import { defineComponent, watch, ref } from "@vue/composition-api";
import { AbsBox, CompanyLogo, CompanySlogan } from "@ui";

export const CompanySplashScreen = defineComponent({
	props:{
		show:{
			type:Boolean,
			default:true,
		},
	},
	setup(props){

		const delay = 2000;
		const fadeTime = 300;
		const close = ref(false);
		const blocking = ref(true);

		const reset = () => {
			close.value = false;
			blocking.value = true;
		};

		const hide = () => setTimeout(() => {
			blocking.value = false;
			close.value = true;
		}, delay);

		watch(() => props.show, v => {
			if(!v){ return; }
			reset();
		});

		watch(() => props.show, v => {
			if(v){ return; }
			hide();
		});

		return {
			close,
			fadeTime,
			blocking,
		};
	},
	render(){
		return (
			<FadeBox
			fade={this.close}
			block={this.blocking}
			time={this.fadeTime}
			class="d-flex"
			>
				<BackgroundLayer/>
				<AbsBox class="d-flex" style="overflow:hidden">
					<div class="m-auto" style="position:relative;">
						<CompanyLogo style="position:relative;width:10em;height:10em;"/>
						<Spinner/>
					</div>
				</AbsBox>
				<SloganLayer/>
			</FadeBox>
		)
	}
});

const FadeBox = defineComponent({
	props:{
		time:{ type:Number, default:500, },
		block:{ type:Boolean, default:false, },
		fade:{ type:Boolean, default:false, },
	},
	render(){
		const cls = `${this.fade ? `a-fade a-${this.time}ms a-stay` : ""}`;
		const css = `${ !this.block ? 'pointer-events:none;' : ''}`
		return (
			<AbsBox class={ cls } style={ css }>
				{ this.$slots.default }
			</AbsBox>
		);
	}
});

const Spinner = defineComponent({

	render(){

		const rootcss = `
			transform:scale(1.2);
		`;

		const aniwrappercss = `
			position:relative;
			width:100%;
			height:100%;
		`;

		const dotcss = `
			background:white;
			border-radius:50%;
			position:absolute;
			top:0;
			left:50%;
			width:1em;
			height:1em;
			transform:translate(-50%, -50%);
		`;

		const circlecss = `
			border-radius:50%;
			border:2px solid white;
			position:absolute;
			width:100%;
			height:100%;
		`;

		return (
			<AbsBox style={rootcss}>
				<div class="a-rotate-360 a-loop a-1250ms a-linear" style={aniwrappercss}>
					<div style={circlecss}/>
					<div style={dotcss}/>
				</div>
			</AbsBox>
		);
	}
});



const SloganLayer = defineComponent({
	render(){
		return (
			<AbsBox class="d-flex p-3">
				<SloganFooter class="mt-auto mx-auto">
					<CompanySlogan/>
				</SloganFooter>
			</AbsBox>
		);
	}
});


const SloganFooter = defineComponent({
	render(){
		return (
			<h3
			class="font-weight-light text-light"
			style="letter-spacing:0.1em;"
			>
				{this.$slots.default}
			</h3>
		);
	}
});


const gradientcss = `
background: #005C97;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #363795, #005C97);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #363795, #005C97); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`

const BackgroundLayer = defineComponent({
	render(){
		return (
			<AbsBox class="bg-dprimary" style={gradientcss}/>
		);
	}
});