



















// vendor
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
	emits:[ "click" ],
	props:{
		label:{
			type:String,
			default:"",
		},
		active:{
			type:Boolean,
			default:false,
		},
	},
});

