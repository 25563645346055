// project
import { GalleryItem, GalleryFeedback } from "@/psychlab/types/gallery";
import { generateGUID } from "@/utils/guid";

export const createGalleryItem = ():GalleryItem => {
	return {
		id:generateGUID(),
		url:"",
		label:"",
		thumbnail:"",
		sources:[],
		mobileSources:[],
	};
};

export const copyGalleryItem = (item:GalleryItem):GalleryItem => {
	const c:GalleryItem = JSON.parse(JSON.stringify(item));
	c.id = generateGUID();
	return c;
};

export const initGalleryFeedback = ():GalleryFeedback => {
	return {
		label:"",
		minLabel:"",
		maxLabel:"",
		optional:false,
		scale:[
			"",
			"",
			"",
			"",
			"",
		]
	}
};