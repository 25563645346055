





// vendor
import { defineComponent, computed, ref } from "@vue/composition-api";

import { AbsBox } from "@ui";


const isImageUrl = (u:string) => Boolean(u);

export default defineComponent({

	props:{
		url:{
			type:String,
			default:""
		},
		opacity:{
			type:Number,
			default:0,
		},
		repeat:{
			type:Number,
			default:0,
		},
		size:{
			type:String,
			default:"cover"
		}
	},
	components:{
		AbsBox,
	},
	setup(props){
		
		const style = computed(() => {
			
			if(!isImageUrl(props.url)){ return "opacity:0;"; }

			let s = `opacity:${props.opacity};`;

			s += `background-image: url("${props.url}");`

			if(props.repeat > 1){
				s += `background-repeat: repeat;`
				s += `background-size:${(1 / props.repeat) * 100}%;`
				s += "background-position: left center;"
			}
			else {
				s += `background-size:${props.size};`
				// s += `background-size:auto;`
				s += "background-position: center center;"
			}
			// s += `background-size:${props.size};`
			// s += `background-size:20%;`


			return s;
		});
		

		return {
			style,
		};
	}
});

