import { Connector2D } from "../utils/Connector2D";
import { PropMap } from "../utils/PropMap";
import { getPathString } from "../utils/Path2D";
import { computeMidPoint } from "../utils/Math2D";
import { Vector2D, ViewportTransform } from "../types";
import * as SnapSVG from "snapsvg";

export class DragTransition {

	constructor(viewport:SnapSVG.Paper, viewportTransform:ViewportTransform){
		this._viewportTransform = viewportTransform;
		this._root = viewport.group();
		this._startCircle = this._root.circle(0, 0, 8) as any;
		this._endCircle = this._root.circle(0, 0, 8) as any;

		this._connector = new Connector2D(this._root);
		this._connector.configure({
			useEvents: false,
			width: 6,
			color: "orange"
		});
		this._iconGroup = this._root.group();
		this._iconCircle = this._iconGroup.circle(0, 0, 15) as SnapSVG.Paper;
		this._iconText = this._iconGroup.text(0, 1, "-") as SnapSVG.Paper;
		this._iconText.attr({
			"dominant-baseline": "middle",
			fill: "white",
			stroke: "white"
		});
		(this._iconText.node.style as any)["text-anchor"] = "middle";
		(this._iconText.node.style as any)["font-size"] = "16pt";
		(this._iconText.node.style as any)["font-weight"] = "bold";
		(this._iconText.node.style as any)["font-family"] = "Lucida Console";


		const scope = this;

		this._props.initialize({
			"normalColor": {
				value: "orange",
				validator: v => typeof(v) === "string",
				onChange: v => scope._connector.configure({ color: v })
			},
			"completeColor": {
				value: "#34cc00",
				validator: v => typeof(v) === "string",
				onChange: v => scope._connector.configure({ color: v })
			}
		});
		this.clear();
	}

	public setError(state:boolean){
		this._isError = state;
	};

	public setStart(x:number, y:number){
		let scaledRadius = this._viewportTransform.zoom * this._circleRadius;
		let scaledWidth = this._viewportTransform.zoom * 6;
		this.setWidth(scaledWidth);
		this.setCirclePosition(this._startCircle, x, y);
		this.setCircleRadius(this._startCircle, scaledRadius);
		this.setCircleRadius(this._endCircle, scaledRadius);
		this._startPoint.x = x;
		this._startPoint.y = y;
		this.refreshPath();
		this.toggleShow(true);
	};

	public setEnd(x:number, y:number){
		this.setCirclePosition(this._endCircle, x, y);
		this._endPoint.x = x;
		this._endPoint.y = y;
		this.refreshPath();
	};

	public setComplete(state:boolean){
		let d1 = (15 * this._viewportTransform.zoom);
		let d2 = (10 * this._viewportTransform.zoom);
		this._connector.configureAttributes({
			"stroke-dasharray": !state ? `${d1},${d2}` : undefined
		});
		let color = this._props.getValue(state ? "completeColor" : "normalColor");
		if(state && this._isError){ color = "red"; }
		this.setColor(color);
		this.setConnectorType(state && !this._isError ? "elbow" : "straight");

		this._iconText.attr({
			text: !this._isError ? "✓" : "✖"
		});

		this.toggleCircleVisible(this._endCircle, state);
		this.toggleCircleVisible(this._iconGroup, state);
	};

	public clear(){
		this.setError(false);
		this.setComplete(false);
		this.toggleShow(false);
		this.toggleCircleVisible(this._endCircle, false);
	};

	private _viewportTransform:ViewportTransform;
	private _root:SnapSVG.Paper;
	private _props = new PropMap();
	private _connector:Connector2D;
	private _connectorType = "straight";
	private _startPoint:Vector2D = { x: 0, y: 0 };
	private _endPoint:Vector2D = { x: 0, y: 0 };
	private _circleRadius:number = 8;
	private _startCircle:SnapSVG.Paper;
	private _endCircle:SnapSVG.Paper;
	private _isError:boolean = false;
	private _iconGroup:SnapSVG.Paper;
	private _iconCircle:SnapSVG.Paper;
	private _iconText:SnapSVG.Paper;

	private setColor(c:string){
		this._connector.configure({
			color: c
		});
		this._startCircle.attr({
			fill: c
		});
		this._endCircle.attr({
			fill: c
		});
		this._iconCircle.attr({
			fill: c
		});
	};

	private setCirclePosition(circle:SnapSVG.Paper, x:number, y:number){
		circle.attr({
			cx: x,
			cy: y
		});
	};

	private setCircleRadius(circle:SnapSVG.Paper, r:number){
		circle.attr({
			r: r
		});
	}

	private refreshPath(){
		let path = getPathString(this._connectorType, this._startPoint, this._endPoint);
		this._connector.setPoints(path);
		let mid = computeMidPoint(this._startPoint.x, this._startPoint.y, this._endPoint.x, this._endPoint.y);
		this._iconGroup.attr({
			transform: "t" + [mid.x, mid.y]
		});
	};

	private setConnectorType(t:string){
		this._connectorType = t;
		this.refreshPath();
	};

	private toggleShow(state:boolean){
		this._connector.toggleVisible(state);
		this.toggleCircleVisible(this._startCircle, state);
	};

	private toggleCircleVisible(circle:SnapSVG.Paper, state:boolean){
		circle.attr({
			style: "display:" + (state ? "?" : "none")
		});
	}

	private setWidth(w:number){
		this._connector.configure({
			width: w
		});
	};

}
