
import { PropType } from "@vue/composition-api";
import { FormItem } from "@psychlab/types/form";
import { IFormEditor } from "@form-ui";
import { IFormTheme } from "@psychlab/types/form";

export const getItemProps = <T extends FormItem<any>>() => {
	return {
		data:{
			type:Object as PropType<T>,
			required:true,
		},
		editor:{
			type:Object as PropType<IFormEditor>,
			required:false,
		},
		theme:{
			type:Object as PropType<IFormTheme>,
			default:() => {},
		}
	}
}