import { defineComponent, ref } from "@vue/composition-api";
import * as AssetAPI from "@/services/api/assets";
import { FullscreenModal, AbsBox, Icon } from "@ui";
import { ViewAssetHistory } from "@/components.assets/view-asset-history";

// type ModalConfig = {
// 	asset:string;
// 	version?:string;
// };

// export interface IAssetHistoryModal {
// 	open(config:ModalConfig):void;
// };

export const ViewAssetHistoryModal = defineComponent({

	emits:[
		"closed"
	],
	components:{
		FullscreenModal,
		ViewAssetHistory,
		AbsBox,
		Icon,
	},
	setup(_, context){
		
		const modal = ref<any>();
		const asset = ref<string>();
		const version = ref<string>();

		const open = async (config:{ asset:string, version?:string }) => {
			
			if(!modal.value){ return; }

			version.value = config.version;

			if(!config.version){
				const versions = await AssetAPI.loadVersionList(config.asset);
				version.value = versions[0]._id;
			}
		
			asset.value = config.asset;
			modal.value.open();
		};

		const hide = () => {
			if(!modal.value){ return; }
			modal.value.close();
			asset.value = undefined;
			version.value = undefined;
			context.emit("closed");
		};
		

		return {
			asset,
			version,
			modal,
			hide,
			open,
		};
	},
	render(){

		const history = (this.asset && this.version) ? (
			<ViewAssetHistory
			v-model={this.version}
			key={this.asset}
			assetId={this.asset}
			/>
		) : <div/>

		return (
			<FullscreenModal ref="modal">
				<AbsBox class="d-flex flex-column">
					<div class="bg-dark d-flex border-bottom border-secondary">
						<button
						class=" btn btn-lg btn-outline-secondary rounded-0 border-bottom-0 border-top-0 border-right-0 ml-auto text-light"
						v-on:click={this.hide}
						>
							<Icon name="close"/>
						</button>
					</div>

					<div class="flex-fill" style="position:relative;">
						<AbsBox style="overflow:hidden;">
							{history}
						</AbsBox>
					</div>
				</AbsBox>
			</FullscreenModal>
		);
	}
});