

































// vendor
import { computed, defineComponent, PropType, ref, set } from "@vue/composition-api";
// project
import * as AppModal from "@/AppModal";
import { Study } from "@psychlab/types";
import * as StudyAPI from "@/services/api/studies";
import { useTranslate } from "@lang";
import { AbsBox } from "@ui";


export default defineComponent({
	props: {
		study: {
			type: Object as PropType<Study>,
			required:true,
		},
	},
	components:{
		AbsBox,
	},
	setup(props, ctx) {

		const toggling = ref(false);

		const { translate } = useTranslate();
		
		const studyId = computed(() => {
			const r = ctx.root.$route;
			return r.params["studyId"] || r.params["groupId"];
		});

		const statusLabel = computed(() => {
			return translate(`studies.label.${props.study.enabled ? 'live' : 'closed'}`);
		});

		const toggle = async () => {

			if (toggling.value) { return; }
			const variant = (props.study.enabled ? "danger" : "info");

			const title = translate(`studies.action.${props.study.enabled ? 'close' : 'open'}Study`)
			const description = translate(`studies.message.${props.study.enabled ? 'onClosing' : 'onOpening'}Study`)

			AppModal.confirm({
				title,
				variant,
				okText:translate("prompts.action.understood"),
				description,
				cancelText:translate("prompts.action.cancel"),

			}, async () => {
				toggling.value = true;
				const v = !props.study.enabled;
				try {
					if (studyId.value) {
						await StudyAPI.setStudyEnabled(studyId.value, v);
						set(props.study, "enabled", v);
					}
				} catch (err) {
					console.log(err);
				}
				toggling.value = false;
			});
		};

		return {
			statusLabel,
			toggle,
			translate,
		};
	},
});
