import { Dictionary } from "./Dictionary";

export class EventBus<T> {
	public emit<N extends keyof T>(name:N, data?:any){
		
		const arr:Function[]|undefined = this._map[name as string];
		if(!arr){ return; }
		arr.forEach(l => l(data));
	}

	public on<N extends keyof T>(name:N, fn:T[N]){
		if(typeof(fn) !== "function") { return; }
		let arr:Function[]|undefined = this._map[name as string];
		if(!arr){
			arr = [];
			this._map[name as string] = arr;
		}
		arr.push(fn);
	}

	public off<N extends keyof T>(name:N, fn:T[N]){
		const arr:Function[]|undefined = this._map[name as string];
		if(!arr){ return; }
		const index = arr.findIndex(h => (h as any) === fn);
		if(index > -1) { arr.splice(index, 1); }
	}
	private _map:Dictionary<Function[]> = {};
}
