

















































































// vendor
import { defineComponent, computed, ref } from "@vue/composition-api";

// local
import * as config from "../../ts/config";

export default defineComponent({

	emits:[ "press", "release", "delete", "edit" ],
	props:{
		label:{
			type:String,
			default:""
		},
		x:{
			type:Number,
			default:0
		},
		y:{
			type:Number,
			default:0
		},
		stroke:{
			type:String,
			default:"white"
		},
		fill:{
			type:String,
			default:"grey"
		},
		mirror:{
			type:Boolean,
			default:false
		},
		static:{
			type:Boolean,
			default:false
		},
		bound:{
			type:Boolean,
			default:false
		},
		labelColor:{
			type:String,
			default:"white"
		},
		optional:{
			type:Boolean,
			default:false
		}
	},
	setup(props, context){
		
		const hovered = ref(false);
		
		const h = computed(() => config.propNodeHeight);
		const w = computed(() => config.propNodeWidth);

		const portPosition = computed(() => getUnitPosition(props.mirror ? -0.5 : 0.5, 0));

		const deletePosition = computed(() => getUnitPosition(props.mirror ? 0.5 : -0.5, 0));

		const portRadius = computed(() => config.propPortRadius);

		const onPress = () => context.emit("press");

		const onRelease = () => context.emit("release");

		const onDelete = () => context.emit("delete");

		const onEdit = () => {
			if(props.static){
				context.emit("edit");
			}
		}

		const getUnitPosition = (x:number, y:number) => {
			return {
				x:w.value * x,
				y:h.value * y
			}
		};


		const isStatic = computed(() => {
			return true;
		})

		return {
			h,
			w,
			isStatic,
			hovered,
			portPosition,
			portRadius,
			onPress,
			onRelease,
			onDelete,
			onEdit,
			deletePosition,
		};
	}
});

