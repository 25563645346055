













































// vendor
import { defineComponent, computed, ref, PropType } from "@vue/composition-api";
// project
import { SessionLabel } from "@/psychlab/types";
import { LabelBadge } from "@ui";
// local
import { default as AssignLabelModal, IAssignLabelModal } from "./assign-label-modal.vue";

import { useTranslate } from "@lang";

export default defineComponent({
	emits:[
		"selected",
	],
	props:{
		labels:{
			type:Array as PropType<SessionLabel[]>,
			default:() => [],
		},
		value:{
			type:Array as PropType<string[]>,
			default:() => [],
		},
	},
	components:{
		AssignLabelModal,
		LabelBadge,
	},
	setup(props, ctx){

		const labelModal = ref<IAssignLabelModal>();

		const { translate } = useTranslate();

		const label = computed(() => {

			if(props.value.length === 0){
				return "no labels";
			}
			const len = props.value.length;
			const ss = len > 1 ? `+${len - 1}` : "";

			const l = `${findLabelName(props.value[0])} ${ss}`;
			return l;
		});

		const displayLabels = computed(() => {
			return props.value.map(lid => props.labels.find(l => l._id === lid)).filter(l => l !== undefined);
		});
		
		const findLabelName = (id:string) => {
			const l = props.labels.find(l => l._id === id);
			return l ? l.name : "?";
		};

		const assignLabels = () => {
			if(!labelModal.value){ return; }
			labelModal.value.open({
				selection:props.value,
			}, ({ selection }) => {
				ctx.emit("selected", selection);
			});
		};

		return {
			label,
			labelModal,
			assignLabels,
			displayLabels,
			translate,
		};
	}
});

