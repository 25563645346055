import { defineComponent, ref, computed, PropType } from "@vue/composition-api";
import { TipIcon } from "@ui";
import { useTranslate } from "@lang";
import { SelectAuthMode } from "@components/auth";
import { generateGUID } from "@utils/guid";
import { FindBlueprint } from "@components/assets";
import { SelectLang } from "@components/language";

enum Translations {
	Name = "label.name",
	AboutStudyName = "studies.message.aboutStudyName",
	Blueprint = "assets.label.blueprint",
	AboutBlueprint = "studies.message.aboutStudyBlueprint",
	Language = "label.language",
	AboutLanguage = "studies.message.aboutStudyLanguage",
	Auth = "studies.label.studyAuth",
	AboutAuth = "studies.message.aboutStudyAuth"
}

export type NewStudyFormData = {
	name:string;
	blueprint:string;
	language:string;
	auth?:string;
};

export const CreateStudyForm = defineComponent({
	props:{
		data:{
			type:Object as PropType<NewStudyFormData>,
			required:true,
		},
	},
	setup(){

		const { translate } = useTranslate();

		const nameInput = ref<HTMLInputElement>();

		return {
			nameInput,
			translate,
			focus(){
				if(!nameInput.value){ return; }
				focusInput(nameInput.value);
			},
		};
	},
	render(){

		return (
			<div>
				<PaddedBox>
					<Control label={ this.translate(Translations.Name) } tooltip={ this.translate(Translations.AboutStudyName) }>
						<input
						ref="nameInput"
						v-model={ this.data.name }
						class="form-control"
						spellcheck="false"
						/>
					</Control>

					<div class="my-2"/>

					<Control label={ this.translate(Translations.Blueprint) } tooltip={ this.translate(Translations.AboutBlueprint) }>
						<FindBlueprint
						v-model={ this.data.blueprint }
						updateable
						/>
					</Control>

				</PaddedBox>

				<hr class="m-0"/>

				<PaddedBox>
					<Control label={ this.translate(Translations.Language) } tooltip={ this.translate(Translations.AboutLanguage) }>
						<SelectLang v-model={ this.data.language }/>
					</Control>

					<div class="my-2"/>

					<Control label={ this.translate(Translations.Auth) } tooltip={ this.translate(Translations.AboutAuth) }>
						<SelectAuthMode v-model={ this.data.auth }/>
					</Control>
				</PaddedBox>

			</div>
		)
	}
	
});


const focusInput = (el:HTMLInputElement, delay:number = 50) => {
	if(delay === 0){ el.select(); }
	else { setTimeout(() => el.select(), delay); }
};

const PaddedBox = defineComponent({
	render(){
		return (
			<div class="p-3">
				{ this.$slots.default }
			</div>
		);
	}
});

const Control = defineComponent({
	props:{
		label:String,
		tooltip:String,
	},
	setup(){
		return {
			key:computed(() => generateGUID())
		};
	},
	render(){
		return (
			<div class="form-group m-0 row d-flex">
				<label for={ this.key } class="text-secondary col-md-6 mb-auto">
					<span class="mr-1">
						{ this.label }
					</span>
					<TipIcon tip={ this.tooltip }/>
				</label>
				<div class="col-md-6 p-0" id={ this.key }>
					{ this.$slots.default }
				</div>
			</div>
		)
	}
})