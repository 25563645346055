import { defineComponent, ref, computed, watch } from "@vue/composition-api";
import { useTags } from "@/hooks/useTags";
import { Icon } from "@ui";

export const TagRouteToggle = defineComponent({

	beforeRouteUpdate(to:any, _, next:any){ // note: hack until vue-router uses composition api
		console.log("moo");
		const t = to.query["t"];
		if(this.currentTagId !== t){
			this.setCurrentTag(t);	
		}
		next();
	},
	setup(props, ctx){

		const currentTagId = ref<string|null>(null);

		const { sortedTags, } = useTags();

		const route = computed(() => ctx.root.$route);
		const router = computed(() => ctx.root.$router);

		const setCurrentTag = (v:string) => {
			currentTagId.value = v;
		};

		const currentTag = computed(() => {
			return sortedTags.value.find(t => t._id === currentTagId.value);
		});


		const refreshRouteTag = () => {
			const t = route.value.query["t"];
			if(currentTagId.value !== t){
				setCurrentTag(t as any);	
			}
		};

		const setRouteTag = (tag:string|null|undefined) => {
			const q:any = { ...ctx.root.$route.query };
			if(tag){ q["t"] = tag; }
			else { delete q["t"]; }
			router.value.push({ query: q})
			.catch(() => {});
		};

		const clearTag = () => {
			setRouteTag(null);
		};

		watch(() => ctx.root.$route.query, refreshRouteTag, { deep:true });

		refreshRouteTag();

		return {
			currentTag,
			currentTagId,
			setCurrentTag,
			clearTag,
		};
	},
	render(){

		if(!this.currentTag){
			return <span/>;
		}
		
		const txtvariant = ColorUtils.isDark(this.currentTag.color) ? "light" : "dark";

		return (
			<span class="d-flex">
				{/* <span class="text-light my-auto mr-1 font-weight-bold">
					tag:
				</span> */}
				<ToggleWrapper
				color={ this.currentTag.color }
				class="d-flex"
				>

					<span class="px-2">
						<small class={ `font-weight-bold text-${txtvariant}` }
						>
							{ this.currentTag.name }
						</small>
					</span>

					<button
					v-on:click={ this.clearTag }
					class="btn btn-sm p-0 px-1 btn-light rounded-circle shadow-none"
					>
						<Icon name="close-thick"/>
						
					</button>



					{/* <div
					class="rounded border border-dark my-auto mr-2"
					style={ `width:1em;height:1em;background:${this.currentTag?.color}` }
					>
					</div>
					<small>{ this.currentTag?.name }</small> */}
				</ToggleWrapper>
			</span>

		);
	}
});


const ToggleWrapper = defineComponent({

	props:{
		color:{
			type:String,
			default:"red",
		},
	},
	render(){

		return (
			<div
			style={ `
			xborder:1px solid ${this.color};
			background: ${this.color}
			` }
			class="p-d1 rounded-pill px-2x p-0"
			>
				{ this.$slots.default }
			</div>
		);
	}
});





class ColorUtils {

	static hexColorToRGB(v:string){
		if(v[0] === "#"){
			v = v.substr(1, v.length - 1);
		}
		var parsed = v.match(/.{1,2}/g);
		if(!parsed){ return null; }
		return [
			parseInt(parsed[0], 16),
			parseInt(parsed[1], 16),
			parseInt(parsed[2], 16)
		]
	};
	
	static getColorBrightnessRGB(r:number, g:number, b:number){
		return Math.sqrt(
			r * r * .241 +
			g * g * .691 +
			b * b * .068
		) / 255;
	};
	
	static isDark(v:string){
		const c = ColorUtils.hexColorToRGB(v);
		if(!c){ return false; }
		const b = ColorUtils.getColorBrightnessRGB(c[0], c[1], c[2]);
		if(b > 0.5){
			return false;
		}
		return true;
	};

}