import { getThemePreset } from "@form-ui";
import { useTranslate } from "@lang";
import { ThemeProfile } from "@psychlab/types/form";
import { openContextMenu } from "@ui";
import { generateGUID } from "@utils/guid";


type Receiver = (p:ThemeProfile) => void;


type ProfileOption = {
	label:string;
	value:string;
	order?:number;
	icon?:string;
}

const profiles:ProfileOption[] = [
	{ label:"forms.label.lightMinimal", value:"light-minimal", icon:"mdi.brush", order:-1, },
	{ label:"forms.label.light", value:"light", icon:"mdi.palette-outline" },
	{ label:"forms.label.dark", value:"dark", icon:"mdi.palette" },
];

export const useAddTheme = () => {

	const { translate } = useTranslate();


	const add = (e:Event, r:Receiver) => {

		const setProfile = (name:"dark"|"light") => {
			r({
				id:generateGUID(),
				label:"New Theme",
				theme:getThemePreset(name),
			});
		};
		
		openContextMenu(e, profiles.map(o => ({
			name:translate(o.label),
			icon:o.icon,
			order:o.order || 0,
			fn(){
				setProfile(o.value as any);
			}
		})))
	}


	return {
		add
	}
};