


























































import { defineComponent, computed, ref, PropType, reactive, watch, UnwrapRef } from "@vue/composition-api";
import { useTranslate } from "@lang";

enum Translations {
	Progress = "label.progress",
	Finished = "label.finished",
	Yes = "label.yes",
	No = "label.no",
}

type StringConfig = {};

const propTransform:any = {
	"progress":{
		from(v:{ gte:Number }|null){
			if(!v){ return null; }
			return v.gte;
		},
		to(v:number){
			return ({
				gte:v
			});
		}
	}
};

const initFilter = (to:any, from:any):UnwrapRef<any> => {
	Object.keys(to)
	.forEach(k => {
		to[k] = propTransform[k] ? propTransform[k].from(from[k]) : (from[k] !== undefined) ? from[k] : null;
	});
	return reactive<any>(to);
};

export default defineComponent({

	emits:[ "input" ],
	props:{
		value:{
			type:Object
		},
		label:{
			type:String
		},
		config:{
			type:Object as PropType<StringConfig>,
			required:true
		}
	},
	setup(props, context){


		const {
			translate,
		} = useTranslate();


		const filter = initFilter({
			progress:null,
			done:null,
			...props.value || {}
		}, props.value);

		const useDone = computed<boolean>({
			get(){
				return filter.done !== null;
			},
			set(v){
				filter.done = v ? false : null;
			}
		});

		const useProgress = computed<boolean>({
			get(){
				return filter.progress !== null;
			},
			set(v){
				filter.progress = v ? 0 : null;
			}
		});

		const emitValue = () => {
			const v:any = {};
			const rename:any = {
				"progress":"progress[gte]"
			};
			const mutate:any = {
				progress(v:any){
					return ({
						"gte":v
					});
				}
			};
			[ "done", "progress" ]
			.forEach(k => {
				if(filter[k] !== null){
					const kv = mutate[k] ? mutate[k](filter[k]) : filter[k];
					v[k] = kv;
				}
			});
			context.emit("input", v);
		};

		watch(filter, emitValue);

		const phrases = computed(() => ({
			progress:translate(Translations.Progress),
			finished:translate(Translations.Finished),
			yes:translate(Translations.Yes),
			no:translate(Translations.No),
		}));

		return {
			filter,
			useDone,
			useProgress,
			phrases,
		};
	}
});

