



































































import { defineComponent, ref, computed, watch } from "@vue/composition-api";
import { StringList, StringListConfig } from "@/components.generic/scriptable-form";
import { default as BGImage } from "./bg-image.vue";
import { getUrlTypes } from "../ts/getUrlType";
import { useTranslate } from "@lang";

// const infoText = `
// The first working source starting from top of the list will be used.
// `.trim();

type ModalConfig = {
	title:string;
	infoText?:string;
	sources:string[];
};

type ModalResult = {
	sources:string[];
};

type CallbackHandler = (r:ModalResult) => void;

export interface IEditSourcesModal {
	open(c:ModalConfig, f:CallbackHandler):void;
};

export default defineComponent({
	props:{
		showBackdrop:{
			type:Boolean,
			default:true
		}
	},
	components:{
		"bg-image":BGImage,
		StringList,
	},
	setup(props){

		const error = ref("");

		const title = ref("");
		const infoText = ref<string>();

		const modified = ref(false);

		const fn = ref<CallbackHandler|null>(null);
		const show = ref(false);

		const modal = ref(null);
		const root = ref<any>();

		const sources = ref<string[]>([]);
		
		const {
			translate,
		} = useTranslate();

		const canSave = computed(() => {
			return modified.value;
		});

		// const title = computed(() => "Sources");

		const listConfig = computed<StringListConfig>(() => {
			return {
				options:[],
				handleVariant:"light",
				handleTextVariant:"dark",
				borderVariant:"light",
				addVariant:"outline-light",
				deleteVariant:"outline-light",
				deleteTextVariant:"none",
				addText:"Add Option",
				inputVariant:"light",
				inputBoxVariant:"default",
				placeholder(){
					return "*.png, *.jpg, *.mp4";
				}
			};
		});

		const validate = () => {
			
			if(sources.value.length === 0){
				return true;
			}

			const empty = sources.value.findIndex(s => s.trim().length === 0);

			if(sources.value.findIndex(s => s.trim().length === 0) > -1){
				error.value = "List contains empty path(s)";
				return false;
			}

			const types = getUrlTypes(sources.value);


			if(types.findIndex(t => t === null) > -1){
				error.value = "List contains unsupported types";
				return false;
			}

			if(types.length !== 1){
				error.value = "List contains mixed types";
				return false;
			}
			return true;
		};

		const ok = () => {

			if(!validate()){ return; }

			if(fn.value){

				const s = sources.value.filter(src => {
					return src.trim().length > 0;
				}).map(ss => ss.trim());

				fn.value({
					sources:s
				})
			}
			(modal.value as any).hide();
		};

		const hide = () => {
			fn.value = null;
			show.value = false;
			sources.value = [];
			modified.value = false;
			error.value = "";
			title.value = "";
		};

		const open = (c:ModalConfig, f:CallbackHandler) => {
			fn.value = f;
			title.value = c.title;
			infoText.value = c.infoText;
			sources.value = JSON.parse(JSON.stringify(c.sources || []));
			setTimeout(() => {
				show.value = true;
			}, 1);
		};

		watch(sources, () => {
			if(show.value){
				modified.value = true;
			}
			error.value = "";
		});

		return {
			error,
			show,
			canSave,
			modal,
			title,
			ok,
			hide,
			open,
			translate,
			root,
			sources,
			listConfig,
			infoText,
		};
	}
});
