






















































// vendor
import { defineComponent, computed, ref, onMounted, onUnmounted, PropType } from "@vue/composition-api";
// project
import { GalleryItem } from "@/psychlab/types/gallery";
import * as MathUtils from "@/utils/math";
// local
import * as Utils from "../../ts/utils";
import { default as LoadSpinner } from "./load-spinner.vue";
import { default as Thumbnail } from "./thumbnail.vue";

type State = {
	icon:string;
	locked:boolean;
	variant:string;
};

const variantInverse:any = {
	"light":"dark",
	"warning":"dark",
};

export default defineComponent({

	emits:[
		"select"
	],
	props:{
		loading:{
			type:Boolean,
			default:false,
		},
		activeItem:{
			type:String,
		},
		items:{
			type:Array as PropType<GalleryItem[]>,
			required:true,
		},
		states:{
			type:Array as PropType<(State|null)[]>,
			default:() => [],
		},
		showLabel:{
			type:Boolean,
			default:false,
		}
	},
	components:{
		Thumbnail,
	},
	setup(props, context){
		
		const rootContainer = ref<HTMLElement>();
		const canvas = ref<HTMLElement>();

		const padding = 10;
		const rootWidth = ref(0);
		const rootHeight = ref(0);

		const maxHeight = computed(() => rootHeight.value);

		const itemOffset = computed(() => {
			const x = rootWidth.value - canvasWidth.value;
			if(x < 0){ return 0; }
			return x * 0.5;
		});

		const currentLabel = computed(() => {
			const item = props.items.find(it => it.id === props.activeItem);
			if(!item){ return null; }
			return item.label;
		});

		const canvasItems = computed(() => {
			return props.items.map((item, i) => {
				const w = itemSize.value;
				const h = w;
				const x = itemOffset.value + padding + i * (w + padding);
				const y = (maxHeight.value * 0.5) - h * 0.5;
				return {
					...item,
					w, h,
					x, y,
					style:{
						left:`${x}px`,
						top:`${y}px`,
						width:`${w}px`,
						height:`${h}px`,
					},
					select(){
						context.emit("select", item.id);
					},
				}
			});
		});

		const itemSize = computed(() => maxHeight.value - padding * 2);

		const canvasWidth = computed(() => padding + (itemSize.value + padding) * props.items.length);

		const getItemState = (i:number) => {
			return i < props.states.length && props.states.length > 0 ? props.states[i] : null;
		};

		onMounted(() => {
			const el = rootContainer.value;
			const cel = canvas.value;
			if(!el || !cel){ return; }

			el.addEventListener("wheel", e => {
				el.scrollLeft  += Math.sign(e.deltaY) * maxHeight.value * 0.5;
				e.preventDefault();
				return false;
			}, false);

			const onResize = () => {
				rootWidth.value = el.clientWidth;
				rootHeight.value = el.offsetHeight;
			};

			window.addEventListener("resize", onResize);

			onUnmounted(() => {
				window.removeEventListener("resize", onResize);
			});
			onResize();
		});

		return {
			canvasItems,
			rootContainer,
			canvasWidth,
			canvas,
			maxHeight,
			getItemState,
			currentLabel,
		};
	}
});

