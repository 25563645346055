
export const hasDeviceOrientation = async ():Promise<boolean> => {
	if(!isMobile()){ return false; }

	const requestPermission = (window.DeviceOrientationEvent as any).requestPermission;
	
	if(window.DeviceOrientationEvent !== undefined && typeof requestPermission === "function"){
		try {
			const r = await requestPermission();
			return r === "granted";
		}
		catch(err){
			console.error(err);
			return false;
		}
	}
	return true;
};

export const isMobile = () => {
	return /Mobi|Android/i.test(navigator.userAgent);
};