import { FeatureFlags } from "./FeatureFlags";
import { ISingleFlagLoader } from "./types";
import { loadVar } from "./helpers";

export class ConfigVarLoader implements ISingleFlagLoader {

	get flag():FeatureFlags {
		return this._flag;
	}

	get active():boolean {
		const v = loadVar(this._varName);
		return v === "true" || v === 1 || v === true;
	}
	
	constructor(varName:string, flag:FeatureFlags){
		this._varName = varName;
		this._flag = flag;
	}

	private _varName:string;
	private _flag:FeatureFlags;

}