import { IPost } from "../interfaces";
import { HTTP } from "../HTTP";

export class Post<PT, BT, RT> extends HTTP implements IPost<PT, BT, RT> {
	
	constructor(path:string){
		super(""); // todo: base path
		this._path = path;
	}

	async send(p:PT, body:BT): Promise<RT> {
		return await this.post(this._path, p, body);
	}
	
	private _path:string;
}