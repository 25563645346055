<template>

	<div class="m-0">
		<b-form-group class="my-0">
			<draggable @change="onDragged" v-model="displayItems" handle=".drag-handle"  v-bind="dragSettings">
				<b-input-group size="sm" v-for="(option, index) in config.options" :key="index" class="d-flex my-1">
					<!-- Drag -->
					<b-input-group-prepend>
						<b-button variant="none" class="move drag-handle alert-secondary nohighlight">
							<icon name="mdi.drag-horizontal d-block"/>
						</b-button>
					</b-input-group-prepend>
					<b-form-input
					:ref="getControlId(index)"
					:placeholder="getPlaceholder(index)"
					:tabindex="2"
					:id="getControlId(index)"
					:value="config.options[index]"
					@input="onInputChange(index,$event)"
					size="sm"
					v-on:keyup.enter="confirmInput(index, $event)"
					v-on:keydown.backspace="onBackspace(index, $event)"
					v-on:keydown.up="moveToOption(index-1, $event)"
					v-on:keydown.down="moveToOption(index+1, $event)"
					class="option-input"
					/>
					<!-- Delete -->
					<b-input-group-append>
						<b-button @click="deleteOption(index)"  variant="outline-secondary" class="pointer ghost-btn">
							<icon name="close"/>
						</b-button>
					</b-input-group-append>
				</b-input-group>
			</draggable>
			<!-- Add option -->
			<b-input-group size="sm" class="d-flex my-1 rounded align-content-stretch justify-content-center ghost-btn">
				<b-button :tabindex="2"  style="width:100%" @click="addOption" variant="outline-secondary" class="rounded d-flex justify-content-center ">
					<icon name="plus" class="text-seconddaary"/>
				</b-button>
			</b-input-group>
		</b-form-group>
	</div>
</template>

<script >

	import Draggable from 'vuedraggable';
	import { isFunction } from 'util';
	import { generateGUID  } from "@/utils/guid";
	import { move as moveItem } from "@/utils/array";

	export default {
		props:{
			"config":{
				type:Object
			},
			"addText":{
				type:String,
				default: "Add Option"
			},
			"placeholderFn":{
				type:Function
			}
		},
		components:{
			Draggable
		},
		data(){
			return {
				savedInput:null
			}
		},
		computed:{
			dragSettings() {
				return {
					animation: 200,
					disabled: false,
					ghostClass: "ghost",
				};
			},
			displayItems:{
				get(){ return this.config.options },
				set(v){  } // do nothing - changes are managed by onDragged handler
			}, 
			markupId(){ return generateGUID(); } // random root id
		},
		methods:{
			onInputChange(index, $event){
				this.$set(this.config.options, index, $event);
			},
			moveToOption(index, $event){
				
				if(index >= 0 && index < this.displayItems.length){
					this.focusInput(index);
				}
			},
			onBackspace(index, $event){
				let v = this.displayItems[index];
				if(!v || v.length === 0){
					

					setTimeout(() => {
						this.deleteOption(index);
					
						if(index > 0){
							this.focusInput(index - 1);
						}
					}, 1);
					return;
				}
			},
			confirmInput(index, $event){
				if(index === this.displayItems.length - 1){
					this.addOption();
				}else{
					this.focusInput(index + 1);
				}
			},
			getPlaceholder(index){
				if(isFunction(this.placeholderFn)){
					return String(this.placeholderFn(index));
				}
				return "";
			},
			focusInput(index){
				let id = this.getControlId(index);
				setTimeout(() => {
					this.$refs[id][0].focus();
				}, 1);
			},
			getControlId(index){
				return `${this.markupId}_${index}`;
			},
			addOption(){
				this.config.options.push("");
			},
			deleteOption(index){
				this.config.options.splice(index, 1);
			},
			onDragged({ moved }){
				if(moved){
					moveItem(this.config.options, moved.oldIndex, moved.newIndex);
				}
			}
		},
		watch:{
			"displayItems.length"(v, o){
				if(v > o){ this.focusInput(v-1); }
			}
		}
	}
</script>

<style scoped lang="scss">

	@mixin disableHighlight() {
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
	}


	$drag-opacity:0.3;

	.nohighlight{ @include disableHighlight(); }
	.ghost { opacity: $drag-opacity; }
	.move { cursor:move; }
	.ghost-btn{ opacity:0.5; }


	.option-input::placeholder{
		opacity:0.3;
	}
	

</style>