// local
import { AssetType } from "../AssetType";

const Graph:AssetType = require("./Graph.json");
const Form:AssetType = require("./Form.json");
const Gallery:AssetType = require("./Gallery.json");
const CognitiveTest:AssetType = require("./CognitiveTest.json");

export {
	Graph,
	Form,
	Gallery,
	CognitiveTest,
};