
export class Selection {

	public add(item:Selectable){
		item.setHighlighted(true);
		this._activeItems.push(item);
		this.invokeListeners();
	};

	public remove(item:Selectable){
		const i = this._activeItems.findIndex(i => i === item);
		if(i > -1){
			this.highlight(this._activeItems[i], false);
			this._activeItems.splice(i, 1);
		}
		this.invokeListeners();
	}

	public removeID(id:string){
		const i = this._activeItems.findIndex(it => it.getID() === id);
		if(i > -1){
			this._activeItems.splice(i, 1);
		}
	}

	public removeAll(predicate:(it:Selectable) => boolean){
		
		this._activeItems.forEach(it => {
			if(predicate(it)){
				this.remove(it);
			}
		});

	}

	public forEachItem(predicate:(it:Selectable) => boolean, fn:(it:Selectable) => void){
		
		this._activeItems.forEach(it => {
			if(predicate(it)){
				fn(it);
			}
		});
	}

	public isSelected(item:Selectable){
		// return this._activeItems.findIndex(it => it === item) > -1;
		return this._activeItems.findIndex(it => it.getID() === item.getID()) > -1;
	};


	public clear(){
		this._activeItems.forEach(item => item.setHighlighted(false));
		this._activeItems = [];
		this.invokeListeners();
	};

	public getItems(){
		return this._activeItems.slice();
	}

	public onChange(fn:ChangeListener){
		this._changeListeners.push(fn);	
	};

	private _activeItems:Selectable[] = [];
	private _changeListeners:ChangeListener[] = [];

	private highlight(item:Selectable, state:boolean){
		item.setHighlighted(state);
	};

	private invokeListeners(){
		const items = this._activeItems.map(it => it.getID())
		this._changeListeners.forEach(l => l(items));
	};

}

export type Selectable = {
	getID():string,
	setHighlighted(v:boolean):void,
	addMovement(x:number, y:number):void
}


type ChangeListener = (items:string[]) => void;