













































































// vendor
import { defineComponent, computed, ref, PropType } from "@vue/composition-api";
import { insertTab } from "@ui";
import * as StudyAPI from "@/services/api/studies";
import { Divider, RenderMarkdown, Icon } from "@ui";
import { useTranslate } from "@lang";
import { Study } from "@psychlab/types";

export default defineComponent({
	props:{
		study:{
			type: Object as PropType<Study>,
			required:true,
		}
	},
	directives:{
		"insert-tab":(insertTab as any)
	},
	components:{
		Divider,
		RenderMarkdown,
		Icon,
	},
	setup(props){

		const { translate } = useTranslate();
		
		const study = computed(() => props.study);
		
		const editing = ref(false);
		const editText = ref("");

		const previewing = ref(false);

		const loading = ref(false);

		const description = computed(() => study.value.description || "");

		const placeholder = computed(() => translate("label.description"));


		const tabButtons = computed(() => {
			return [
				{
					text:translate("action.edit"),
					activeFn: () => !previewing.value,
					fn(){
						previewing.value = !previewing.value;
					}
				},
				{
					text:translate("action.preview"),
					activeFn: () => previewing.value,
					fn(){
						previewing.value = !previewing.value;
					}
				},
				
			]
		});

		const footerButtons = computed(() => {
			return [

				{
					icon:"mdi.close",
					fn: cancel,
					variant:"dark"
				},
				{
					icon:"mdi.check",
					fn: save,
					variant:"success"
				},
			];
		});

		const edit = () => {
			previewing.value = false;
			editText.value = description.value;
			editing.value = true;
		};

		const save = async () => {
			loading.value = true;
			try {
				await StudyAPI.setStudyDescription(study.value._id, editText.value);
				study.value.description = editText.value;
			}
			catch(err:any){
				console.log(err.message);
			}
			loading.value = false;
			editing.value = false;
		};

		const cancel = () => {
			editing.value = false;
		};

		return {
			editText,
			editing,
			previewing,
			description,
			placeholder,
			tabButtons,
			footerButtons,
			edit,
			translate,
		};
	}
});

