






















































// vendor
import {
	defineComponent,
	PropType,
	ref,
	computed,
} from "@vue/composition-api";
// project
import { GalleryFeedback } from "@/psychlab/types/gallery";
// local
import Scale from "./scale.vue";
import DockedFoldout from "./docked-foldout.vue";
import ErrorOverlay from "./error-overlay.vue";

type Config = {
	key:string;
	cols:string[];
	label:string;
	minLabel?:string;
	maxLabel?:string;
	optional?:boolean;
};

export default defineComponent({
	emits:[
		"previous",
		"next",
		"toggle-open",
	],
	props: {
		value: {
			type: Number,
		},
		showBack: {
			type: Boolean,
			default: true,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		config: {
			type: Object as PropType<Config>,
			required: true,
		},
		open: {
			type:Boolean,
			default:true,
		},
		onLast: {
			type:Boolean,
		},
		showTooltips: {
			type: Boolean,
			default: false,
		},
		validateNext:{
			type:Boolean,
			default:true,
		},
		
	},
	components: {
		Scale,
		DockedFoldout,
		ErrorOverlay,
	},
	setup(props, context) {

		const error = ref(false);

		const previous = () => context.emit("previous");

		const next = () => {
			if(!canContinue.value){
				error.value = true;
				return;
			}
			context.emit("next");
		};

		const val = computed({
			get: () => props.value,
			set: (v) => context.emit("input", v),
		});

		const title = computed(() => props.config.label);

		const isValid = () => {
			if(props.config.optional){
				return true;
			}
			return val.value !== undefined && val.value > -1;
		};

		const canContinue = computed(() => {
			if(props.validateNext){
				return props.config.optional ? true : isValid();
			}
			return true;

		});

		const pickedLabel = computed(() => {
			const i = props.value === undefined ? -1 : props.value;
			if (i < 0) {
				return "";
			}
			return props.config.cols[i] || (i + 1).toString();
		});

		const hasValue = computed(() => props.value !== undefined && props.value > -1);

		return {
			error,
			title,
			previous,
			next,
			val,
			isValid,
			canContinue,
			pickedLabel,
			hasValue,
		};
	},
});
