


































































// vendor
import { defineComponent, ref, computed } from "@vue/composition-api";
// local
import { EditTextConfig } from "./IEditText";
import { useTranslate } from "@lang";

type CallbackHandler = (v:string) => void;

export default defineComponent({
	directives:{

	},
	setup(){

		const { translate } = useTranslate();

		const value = ref("");
		const fn = ref<CallbackHandler|null>(null);
		const show = ref(false);
		const oldValue = ref("");
		const config = ref<EditTextConfig|null>(null);

		const modal = ref(null);
		const input = ref<HTMLInputElement|null>(null);

		const isTextarea = computed(() => Boolean(config?.value?.textarea));
		const size = computed(() => config?.value?.size || "sm");

		const variant = computed(() => {
			return config?.value?.variant || "dark";
		});

		const textVariant = computed(() => {
			return ({
				"light":"dark"
			} as any)[variant.value] || "light";
		});

		const confirmText = computed(() => config?.value?.confirmText || translate("prompts.action.confirm"));

		const cancelText = computed(() => config?.value?.cancelText || translate("prompts.action.cancel"));
		
		const canSave = computed(() => {
			if(config.value && config.value.validator){
				return config.value.validator(value.value);
			}
			return true;
		});

		const title = computed(() => config?.value?.title || translate("action.editText"));
		const placeholder = computed(() => config?.value?.placeholder || "");
		const showBackdrop = computed(() => config?.value?.showBackdrop || true);

		const ok = () => {
			if(fn.value){ fn.value(value.value); }
			(modal.value as any).hide();
		};

		const hide = () => {
			fn.value = null;
			show.value = false;
			value.value = "";
			oldValue.value = "";
			config.value = null
		};

		const open = (c:EditTextConfig, f:CallbackHandler) => {
			config.value = c;
			fn.value = f;
			show.value = true;
			value.value = c.value;
			oldValue.value = c.value;
			setTimeout(() => {
				if(input.value){ input.value.select(); }
			}, 50);
		};

		return {
			value,
			fn,
			show,
			oldValue,
			canSave,
			variant,
			textVariant,
			modal,
			input,
			title,
			placeholder,
			showBackdrop,
			confirmText,
			cancelText,
			isTextarea,
			size,
			config,
			ok,
			hide,
			open,
		};
	}
});
