





















// vendor
import { defineComponent, computed, ref } from "@vue/composition-api";
import { AbsBox } from "@ui";
import { default as Stripes } from "./stripes.vue";

export default defineComponent({
	emits:[],
	props:{
		text:{
			type:String,
			default:""
		}
	},
	components:{
		AbsBox,
		Stripes,
	},
	setup(){
		

		return {

		};
	}
});

