















































import { useTranslate } from "@lang";
import { computed, defineComponent } from "@vue/composition-api";

const tabs = [
	{ name: "label.overview", r:"overview", icon: "mdi.information" },
	{ name: "assets.label.blueprint", r:"blueprint", icon: "mdi.state-machine" },
	{ name: "studies.label.live", r:"live", icon: "mdi.progress-clock" },
	{ name: "studies.label.sessions", r:"sessions", icon: "mdi.chart-timeline-variant" },
	{ name: "studies.label.tasks", r:"tasks", icon: "mdi.hexagon-multiple-outline" },
	{ name: "label.settings", r:"settings", icon: "mdi.cogs" },
];

export default defineComponent({
	setup(props, ctx) {

		const { translate } = useTranslate();


		const studyId = computed(() => {
			const r = ctx.root.$route;
			return r.params["studyId"] || r.params["groupId"];
		});


		const getTabLabel = (tab: any) => {
			return tab.label;
		};

		const displayTabs = computed(() => {
			return tabs.map((t) => {
				const name = t.r.charAt(0).toUpperCase() + t.r.slice(1);
				const label = translate(t.name);
				return {
					...t,
					label,
					route: `Run.Group.${name}`,
				};
			});
		});

		const isActive = (tab: any) => {
			const res = (ctx.root.$route as any).name.match(
				`${tab.route}*`,
			);
			return Boolean(res);
		};

		const openTab = (tab: any) => {
			if (ctx.root.$route.name === tab.route) {
				return;
			}
			if (studyId) {
				ctx.root.$router.push({
					name: tab.route,
					params: {
						groupId: studyId.value,
					},
				}).catch(() => {})
			}
		};

		return {
			displayTabs,
			studyId,
			getTabLabel,
			isActive,
			openTab,
		};
	},
});
