















import { defineComponent, computed, ref, PropType, set } from "@vue/composition-api";
import * as AppModal from "@/AppModal";
import { ContextOption } from "@/components.generic/context-menu";
import { capitalize } from "@/utils/text";
import { default as IconButton } from "./ico-btn.vue";


export default defineComponent({
	emits:[ "input", ],
	props:{
		// data:{
		// 	type:Object as PropType<FormItem<any>>,
		// 	required:true,
		// },
		value:null,
	},
	components:{
		IconButton,
	},
	setup(props, ctx){

		
		const all = computed(() => !platforms.value.length);
		const mobile = computed(() => includesPlatform("mobile"));
		const desktop = computed(() => includesPlatform("desktop"));

		
		const icon = computed(() => {

			if(!platforms.value.length){
				return "mdi.monitor-cellphone"
			}

			if(includesPlatform("desktop")){
				return "mdi.monitor";
			}

			if(includesPlatform("mobile")){
				return "mdi.cellphone";
			}
			
			return "mdi.monitor-cellphone";
		});

		
		const platforms = computed(() => {
			const p:string[] = props.value || [];
			return p;
		})

		
		const includesPlatform = (name:string) => {
			return platforms.value.includes(name);
		};

		const toggleOption = (name:string) => {
			const p = platforms.value;
			let i = p.indexOf(name);
			if(i >= 0){ return; }
			ctx.emit("input", [ name ]);
		};

		const toggleAll = () => {
			if(!props.value){ return; }
			ctx.emit("input", []);
		};

		const toggle = (e:Event) => {
			const useAll = (platforms.value).length === 0;
			const options:ContextOption[] = [
				{
					name:"All",
					icon: useAll ? "mdi.check" : undefined,
					order:-1,
					fn:toggleAll
				}
			];

			[ "desktop", "mobile"]
			.forEach(pn => {
				options.push({
					name:capitalize(pn),
					icon: includesPlatform(pn) ? "mdi.check" : undefined,
					fn:() => toggleOption(pn),
				})
			});

			AppModal.context(e, options);

		};

		return {
			icon,
			toggle,
			all,
			desktop,
			mobile,
		};
	}
});


