import { Ref, ComputedRef, computed } from "@vue/composition-api";
import { isVimeoLink, isYouTubeLink } from "@utils/url";
import { getVimeoEmbed, getYoutubeEmbed, getDefaultVideoEmbed } from "./utils";

type RRef<T> = Ref<T>|ComputedRef<T>;

export type VideoConfig = {
	url:string;
	width:number;
	loop?:boolean;
	autoplay?:boolean;
	hideControls?:boolean;
	background?:string;
}

export const useVideoEmbed = (config:RRef<VideoConfig>) => {

	const embedContent = computed(() => {

		const url = config.value.url;
		const width = config.value.width;
		const bg = config.value.background;

		if(!url){
			return "";
		}

		let v:string = "";

		if(isYouTubeLink(config.value.url)){
			v = getYoutubeEmbed(url, {
				hideControls:config.value.hideControls,
				width
			})
		}
		else if(isVimeoLink(url)){
			v = getVimeoEmbed(url, {
				width
			})
		}
		else {
			v = getDefaultVideoEmbed({
				url,
				width
			});
		}

		if(!v){
			return "";
		}
	
		const cstyle = `background:${bg};`;
		return `<div style="${cstyle}">${v}</div>`
	});


	return {
		embedContent,
	}

};