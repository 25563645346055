

































































































import { defineComponent, computed, ref, PropType } from "@vue/composition-api";
import { DisplayJSON} from "@ui";
import { Gallery } from "@psychlab/types/gallery";
import { default as SourceList } from "./source-list.vue";
import { useTranslate } from "@lang";


export default defineComponent({

	emits:[],
	props:{
		data:{
			type:Object as PropType<Gallery>,
			required:true,
		}
	},
	components:{
		"display-json":DisplayJSON,
		SourceList,
	},
	setup(props){

		const {
			translate,
		} = useTranslate();
		
		const settings = computed(() => {
			const cp:any = {};
			Object.keys(props.data)
			.forEach(k => {
				if([ "items" ].indexOf(k) > -1){ return; }
				cp[k] = (props.data as any)[k];

			});
			return cp;
		});
		

		return {
			settings,
			translate,
		};
	}
});

