













































import { defineComponent, ref, computed } from "@vue/composition-api";

import { ReorderableList } from "@ui";
import { useTranslate } from "@lang";

export interface IEditOptionsModal {
	open:(c:Config, fn:DoneFn) => void;
}

type Config = {
	options:string[];
};

type DoneFn = (options:string[]) => void;

type Modal = {
	hide():void
};

export default defineComponent({
	components: {
		ReorderableList,
	},
	setup(){

		const { translate } = useTranslate();
		
		const modal = ref<Modal>();
		const onDone = ref<DoneFn>();
		const show = ref(false);
		const options = ref<string[]>([]);

		const ok = () => {

			options.value.forEach((o,i) => options.value[i] = o.trim());

			if(onDone.value){
				onDone.value(options.value);
			}
			
			if(modal.value){
				modal.value.hide();
			}
		};

		const hide = () => {
			onDone.value = undefined;
			show.value = false;
			options.value = [];
		};

		const open = (c:Config, fn:DoneFn) => {
			onDone.value = fn;
			show.value = true;
			options.value = [ ...c.options ];
		};

		return {
			modal,
			onDone,
			show,
			options,
			ok,
			hide,
			open,
			translate,
		};
	}
});



