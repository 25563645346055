

const regex = /^https:\/\/(www.)?vimeo.com\/(?<id>[0-9]+)$/;


type Output = {
	id:string;
}

export const isVimeoLink = (url:string):boolean => {
	return Boolean(url.split(/[?#]/)[0].match(regex));
}

export const parseVimeoLink = (url:string):Output|null => {
	const location = url.split(/[?#]/)[0];
	const x = location.match(regex);
	const id = x?.groups?.id;
	return id ? {
		id
	} : null;
};