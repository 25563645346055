import { defineComponent, computed, PropType, ref } from "@vue/composition-api";
import { useUserPrefs } from "@/hooks/useUserPrefs";
import { useTagCreation } from "./useTagCreation";
import { ContextButton, AbsBox, Icon } from "@ui";
import { useTags } from "@/hooks/useTags";
import { useTagContextMenu } from "./useTagContextMenu";
import { Tag } from "@/psychlab/types/tags";
import { useTranslate } from "@lang";


enum Translations {
	Tags = "tags.label.tags",
}

export const TagSidebar = defineComponent({
	props:{
		showAdd:{
			type:Boolean,
			default:true
		}
	},
	setup(_, context){

		const { tagsCollapsed:collapsed } = useUserPrefs();
		const { createTag } = useTagCreation();
		const { openContext:openTagContext } = useTagContextMenu();
		const { sortedTags } = useTags();

		const route = computed(() => context.root.$route);
		const router = computed(() => context.root.$router);

		const activeTag = computed(() => route.value.query["t"]);

		const hovered = ref(false);

		const ghostOpen = ref(false);

		const { translate } = useTranslate();

		const selectTag = (id:string) => {
			if(activeTag.value === id){ return; }
			setRouteTag(id);
		};

		const setRouteTag = (tag:string|null|undefined) => {
			const q:any = { ...context.root.$route.query };
			if(tag){ q["t"] = tag; }
			else { delete q["t"]; }
			router.value.push({ query: q})
			.catch(() => {});
		};

		const togglePinned = () => {
			collapsed.value = !collapsed.value;
		};

		return {
			pinned:collapsed,
			activeTag,
			sortedTags,
			hovered,
			ghostOpen,
			translate,
			createTag,
			selectTag,
			openTagContext,
			togglePinned,
		};
	},
	render(){

		const dtags = this.sortedTags.map(t => (
			<TagListItem
			key={t._id} tag={t}
			v-on:select={ () => this.selectTag(t._id) }
			v-on:ctx={ (e:Event) => this.openTagContext(e,t._id) }
			active={ this.activeTag === t._id }
			/>
		));

		const slide = this.pinned ? "show" : (this.hovered ? "show" : "");

		const hdelay = 150;

		return (

			<div
			style="position:relative;"
			v-on:mouseleave={ () => {
				this.hovered = false;
				this.ghostOpen = false;
			}}
			>

				<div
				v-on:mouseenter={ () => {

					this.ghostOpen = true;

					setTimeout(() => {

						if(this.ghostOpen){
							this.hovered = true;
						}

						
					},hdelay);
				} }
				v-on:mouseleave={ () => {

					this.ghostOpen = false;

				}}
				style={`
				position:absolute;
				height:100%;
				xbackground:#f7f6f3;
				background:rgba(255,255,255,0.1);
				width:1.5em;
				pointer-events:all;
				cursor:pointer;
				z-index:10;
				`}
				class="d-flex p-1 border-rigdht bordder-dark"
				
				>
					<Icon
					name="tag-multiple"
					class="m-auto text-light"
					/>
				</div>
				
				<Wrapper
				class={ `border-right border-dark flex-column d-flex panel shadow ${slide}` }
				pinned={ this.pinned }
				v-on:enter={ () => this.hovered = true }
				v-on:leave={ () => this.hovered = false }
				style="overflow:hidden"
				>
					<div class="p-2 d-flex">
						<h6 class="flex-fill my-auto">
							{ this.translate(Translations.Tags) }
						</h6>
						
						<IconButton
						icon="plus"
						v-on:click={ this.createTag }
						/>
						<IconButton
						icon={ this.pinned ? "pin" : "pin-outline" }
						v-on:click={ this.togglePinned }
						rot={ this.pinned ? "45" : "" }
						/>
					</div>

					<div class="flex-fill" style="position:relative">
						
						<AbsBox style="overflow:auto" class="d-flex">
							<TagList>
								{dtags}
								
							</TagList>
						</AbsBox>
					</div>

					


				</Wrapper>

				
			</div>
		)
	}
});


const Wrapper = defineComponent({
	emits:[ "enter", "leave" ],
	props:{
		pinned:{
			type:Boolean,
			default:false,
		},
		color:{
			type:String,
			default:"#f7f6f3"
		},
	},
	render(){

		const s = `
			position:${ this.pinned ? "relative" : "absolute" };
			width:13rem;
			height:100%;
			background:${this.color};
			z-index:10;
		`;

		return (
			<div
			style={ s }
			class=""
			v-on:mouseenter={ (e:Event) => this.$emit("enter", e) }
			v-on:mouseleave={ (e:Event) => this.$emit("leave", e) }
			>
				{ this.$slots.default }
			</div>

		);
	}
});

const TagList = defineComponent({
	render(){
		return (
			<div class="w-100 h-100">
				{ this.$slots.default }
			</div>
		);
	}
});


const TagListItem = defineComponent({
	emit:[ "select", "ctx" ],
	props:{
		tag:{
			type:Object as PropType<Tag>,
			required:true,
		},
		active:{
			type:Boolean,
			default:false,
		},
	},
	setup(){

		const hovered = ref(false);

		return {
			hovered,
		}
	},
	render(){

		const icon = (
			<span
			class="my-auto mr-2 px-1 rounded"
			style="background:rgba(0,0,0,0.05)"
			>
				<Icon name="folder"
				shadow
				style={ `color:${this.tag.color};` }
				/>
			</span>

		);
		
		const label = (
			<div class="flex-fill d-flex" style="position:relative;">
				<AbsBox>
					<ItemLabel class="my-auto ml-1">
						{ this.tag.name }
					</ItemLabel>
				</AbsBox>
			</div>
		);

		const ctx = this.hovered ? (
			<ContextButton
			size="sm"
			type="vertical"
			variant="dark"
			class="my-auto"
			v-on:click={ (e:Event) => this.$emit("ctx", e) }
			/>
		) : <span/>;

		return (
			<ItemButton
			v-on:click={ () => this.$emit("select") }
			class={ `${this.active ? "active font-weight-bold" : ""}` }
			v-on:mouseenter={ () => this.hovered = true }
			v-on:mouseleave={ () => this.hovered = false }
			>
				{ icon }
				{ label }
				{ ctx }
			</ItemButton>
		);
	}
});


const IconButton = defineComponent({
	emits:[ "click" ],
	props:{
		icon:{
			type:String,
			default:"pirate",
		},
		rot:{
			type:String,
			default:""
		}
	},
	render(){
		return (
			<button
			v-on:click={ (e:Event) => this.$emit("click",e) }
			class={ `btn shadow-none d-dflex hoverable dark border-0 p-1 px-2` }
			>
				<Icon name={ this.icon } class={ `m-auto mdi-rotate-${this.rot}` }/>
			</button>
		);
	}
});

const ItemButton = defineComponent({
	emits:[ "click" ],
	render(){
		return (
			<button
			class={ `btn w-100 shadow-none hoverable dark d-flex p-1 px-2` }
			style="cursor:pointer;"
			v-on:click={ (e:Event) => this.$emit("click",e) }
			v-on:mouseenter={ (e:Event) => this.$emit("mouseenter",e) }
			v-on:mouseleave={ (e:Event) => this.$emit("mouseleave",e) }
			>
				{ this.$slots.default }
			</button>
		);
	}
});


const ItemLabel = defineComponent({

	
	render(){

		const s = `
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: block;
		font-size:0.8em;
		text-align:left;
		`;

		return (
			<span style={s}>
				{ this.$slots.default }
			</span>
		);
	}
});