







import { defineComponent, computed, PropType } from "@vue/composition-api";

type Sizes = 1|2|3|4|5|6|7|8|9|10|11|12;

export default defineComponent({
	props:{
		md:{
			type:Number as PropType<Sizes>,
			default:12,
		},
		sm: {
			type:Number as PropType<Sizes>,
			required:false,
		},
		xs: {
			type:Number as PropType<Sizes>,
			required:false,
		},
		lg: {
			type:Number as PropType<Sizes>,
			required:false,
		},
		xl: {
			type:Number as PropType<Sizes>,
			required:false,
		},
		cols: {
			type:Number as PropType<Sizes>,
			required:false,
		},
	},
	setup(props){
		const cls = computed(() => {
			if(props.cols){
				return `col-${props.cols}`;
			}
			const { xs, sm, md, lg, xl } = props;
			let cls = "";
			if(xs) { cls += ` col-xs-${xs}`; }
			if(sm) { cls += ` col-sm-${sm}`; }
			if(md) { cls += ` col-md-${md}`; }
			if(lg) { cls += ` col-lg-${lg}`; }
			if(xl) { cls += ` col-lg-${xl}`; }
			if(!cls.length){ cls += "col" };
			return cls;
		});

		return {
			cls
		};
	}
});

