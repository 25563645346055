import * as THREE from "three";


export class DeviceOrientationControls extends THREE.EventDispatcher{

	public get enabled(){
		return this._enabled;
	}

	constructor(object:THREE.Object3D){
		super();
		this._object = object;
		this._object.rotation.reorder("YXZ");
		this._onDeviceOrientation = this.onDeviceOrientation.bind(this);
		this._onScreenOrientation = this.onScreenOrientation.bind(this);
		window.addEventListener("orientationchange", this._onScreenOrientation, false)
		window.addEventListener("deviceorientation", this._onDeviceOrientation, false);
	}

	public update(){
		const device = this._deviceOrientation;
		if(!device){ return; }
		const a = device.alpha ? THREE.MathUtils.degToRad(device.alpha) + this._alphaOffset : 0; // Z
		const b = device.beta ? THREE.MathUtils.degToRad(device.beta) : 0; // X'
		const g = device.gamma ? THREE.MathUtils.degToRad(device.gamma) : 0; // Y''
		const o = this._screenOrientation ? THREE.MathUtils.degToRad(this._screenOrientation) : 0; // O
		this.setObjectQuaternion(this._object.quaternion, a, b, g, o);
		if (8*(1-this._lastQuaternion.dot(this._object.quaternion)) > this._EPS ) {
			this._lastQuaternion.copy(this._object.quaternion);
			this.dispatchEvent(this._changeEvent);
		}
	}

	public dispose(){
		// this.disconnect();
		window.removeEventListener("orientationchange", this._onScreenOrientation, false);
		window.removeEventListener("deviceorientation", this._onDeviceOrientation, false);
	};

	private _changeEvent = { type: "change" };
	private _object:THREE.Object3D;
	private _EPS = 0.000001;
	private _deviceOrientation:any = {};
	private _screenOrientation:any = 0;
	private _alphaOffset = 0; // radians
	private _lastQuaternion = new THREE.Quaternion();

	private _enabled = false;

	private _onDeviceOrientation:(e:any) => void;
	private _onScreenOrientation:(e:any) => void;

	private onDeviceOrientation(e:any){
		this._deviceOrientation = e;
	}

	private onScreenOrientation(e:any){
		this._screenOrientation = window.orientation || 0;
	}

	private _state = {
		zee:new THREE.Vector3(0,0,1),
		euler:new THREE.Euler(),
		q0:new THREE.Quaternion(),
		q1:new THREE.Quaternion(-Math.sqrt(0.5), 0, 0, Math.sqrt(0.5))
	}

	private setObjectQuaternion(q:THREE.Quaternion, a:number, b:number, g:number, o:number){
		this._state.euler.set(b, a, -g, "YXZ"); // 'ZXY' for the device, but 'YXZ' for us
		q.setFromEuler(this._state.euler); // orient the device
		q.multiply(this._state.q1); // camera looks out the back of the device, not the top
		q.multiply(this._state.q0.setFromAxisAngle(this._state.zee, - o ) ); // adjust for screen orientation
	}
}