import { WizardContext } from "../types/WizardContext";
import * as Utils from "./utils";

export const ComputeChannelAverage = {
	name: "ComputeChannelAverage",
	description:{
		en:"Computes the average of all responses in a numerical data field.",
		is:"Reiknar meðaltal allra svara í talnadálki."
	},
	isUsable(ctx:WizardContext){
		return ctx.channels.findIndex(Utils.canAverageChannel) > -1;
	},
	parameters: {
		channel: {
			type:"string",
			title:{
				en:"Field",
				is:"Dálkur",
			},
			drawer(context:WizardContext){

				const options = 
				context.channels
				.filter(Utils.canAverageChannel)
				.map((channel) => {
					return {
						text: channel.name,
						value: channel.key,
						group: channel.group,
						groupName: channel.groupName
					};
				});

				return {
					type:"dropdown",
					options
				}
			},
			default:null
		}
	},
	output:{
		avg:{
			type:"number"
		}
	}
};