














import { defineComponent } from "@vue/composition-api";
import { DisplayJSON, AbsBox } from "@ui";

export default defineComponent({
	emits:[ "input" ],
	props:{
		data:{
			type:Object,
			required:true,
		},
	},
	components:{
		DisplayJSON,
		AbsBox,
	},
});

