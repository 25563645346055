
























































import { ref, computed, watch, defineComponent, inject, ComputedRef } from "@vue/composition-api";
import { StudyContext } from "@/components.studies/manage-study";
import * as StudyAPI from "@/services/api/studies";
import { default as PageHeader } from "./header.vue";
import { FilterSidebar } from "./filters";
import { useSessionList } from "./hooks/useSessionList";

const SELECTION_TAB_NAME = "Selection";
const TAB_ROUTE_PREFIX = "Run.Group.Sessions";

export default defineComponent({
	props: {
		studyId: {
			type: String,
			required: true,
		},
	},
	components: {
		PageHeader,
		FilterSidebar,
	},
	setup(props, ctx) {

		const studyContext = inject<ComputedRef<StudyContext>>("studyContext");

		if(!studyContext){ throw Error("Filter Sidebar: Study context missing!"); }

		const {
			study,
		} = studyContext.value;

		const sessionId = ref<string|undefined>(ctx.root.$route.params["sessionId"]);

		const currentRoute = computed(() => ctx.root.$route.name);
		const activeTab = computed<string>(() => {
			if (!currentRoute.value) {
				return SELECTION_TAB_NAME;
			}
			const routeParts = currentRoute.value.split(".");
			return routeParts.length > 0
				? routeParts[routeParts.length - 1]
				: SELECTION_TAB_NAME;
		});

		const selecting = computed(() => {
			return activeTab.value === SELECTION_TAB_NAME;
		});


		const {
			sessions,
			page,
			rows,
			limit,
			sortKey,
			sortAsc,
			labels,
			loading,
			onSortChange,
			onPerPageChange,
			onPageChange,
			onReloadData,
			watchNextReload,
		} = useSessionList(ctx, props.studyId);


		const sessionIndex = computed(() => {
			const i = sessions.value.findIndex(s => s._id === sessionId.value);
			if(i < 0){ return -1; }
			const offset = (page.value - 1) * limit.value;
			return offset + i;
		});

		const currentSession = computed(() => {
			return sessions.value.find(s => s._id === sessionId.value);
		});

		const openSelection = () => {
			const tabRoute = `${TAB_ROUTE_PREFIX}.${SELECTION_TAB_NAME}`;
			if (ctx.root.$route.name === tabRoute) {
				return;
			}
			if (props.studyId) {
				ctx.root.$router.push({
					name: tabRoute,
					params: {
						groupId: props.studyId as string,
					},
					query:{
						...ctx.root.$route.query,
					}
				});
			}
		};

		const openSession = (sid:string) => {
			ctx.root.$router.push({
				name: `${TAB_ROUTE_PREFIX}.SingleSession`,
				params: {
					groupId: props.studyId,
					sessionId: sid,
				},
				query:{
					...ctx.root.$route.query,
				}
			});
		};

		const updateSessionLabels = async (labels:string[]) => {
			if(!currentSession.value || !study.value){ return; }

			try {
				await StudyAPI.updateSessionLabels(study.value._id, currentSession.value._id, labels);
				currentSession.value.labels = labels;
			}
			catch(err:any){
				console.log(err.message);
			}
		};
		
		const openPreviousSession = () => {
			const offset = (page.value - 1) * limit.value;
			const prevIndex = sessionIndex.value - 1;

			const pageIndex = offset - prevIndex;
			if(prevIndex < offset){
				watchNextReload(() => {
					const sid = sessions.value[sessions.value.length - 1]._id;
					openSession(sid);
				});
				onPageChange(page.value-1);
			}
			else {
				const i = sessions.value.findIndex(s => s._id === sessionId.value);
				const sid = sessions.value[i - 1]._id;
				openSession(sid);
			}
		};

		const openNextSession = () => {
			const offset = (page.value) * limit.value;
			const nextIndex = sessionIndex.value + 1;

			if(nextIndex >= offset){
				watchNextReload(() => {
					const sid = sessions.value[0]._id;
					openSession(sid);
				});
				onPageChange(page.value + 1);
			}
			else {
				const i = sessions.value.findIndex(s => s._id === sessionId.value);
				const sid = sessions.value[i + 1]._id;
				openSession(sid);
			}
		};

		watch(() => ctx.root.$route.params["sessionId"], (v) => {
			sessionId.value = v;
		});

		return {
			study,
			activeTab,
			rows,
			openSelection,
			sessions,
			page,
			limit,
			sortKey,
			sortAsc,
			labels,
			loading,
			onSortChange,
			onPerPageChange,
			onPageChange,
			onReloadData,
			selecting,
			sessionIndex,
			openPreviousSession,
			openNextSession,
			currentSession,
			updateSessionLabels,
		};
	},
});
