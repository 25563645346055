




























import { defineComponent, computed, PropType, reactive, set } from "@vue/composition-api";
import { ImageInput, ColorInput, DropdownInput, Icon, Container, Row, Col } from "@ui";
import { ThemeProfile } from "@psychlab/types/form";
import { useThemeDrawers } from "../hooks";
import { default as FoldoutGroup } from "./foldout-group.vue";
import { generateGUID } from "@utils/guid";

export default defineComponent({

	emits:[],
	props:{
		profile:{
			type:Object as PropType<ThemeProfile>,
			required:true,
		}
	},
	components:{
		Icon,
		Container,
		Row,
		Col,
		ImageInput,
		ColorInput,
		DropdownInput,
		FoldoutGroup,
	},
	setup(props){

		const foldouts = reactive<{ [k:string]:boolean }>({})

		const theme = computed(() => {
			return props.profile.theme;
		});

		const {
			groups:propGroups,
		} = useThemeDrawers(theme);

		
		const toggleFoldout = (k:string, v:boolean) => {
			set(foldouts, k, v);
		};

		return {
			propGroups,
			foldouts,
			toggleFoldout,
			generateGUID,
		};
	}
});

