import { defineComponent } from "@vue/composition-api";
import { Icon } from "@ui";

export const SortableHeader = defineComponent({
	props: {
		label: { type: String, required: true, },
		desc: { type: Boolean, default: false, },
		active: { type: Boolean, default: false, },
	},
	render() {
		let iconName = "";

		if (this.active) {
			iconName = this.desc ? "sort-descending" : "sort-ascending";
		}

		const icon = iconName ? <Icon name={ iconName } class="my-auto ml-2"/> : <span/>;

		return (
			<span class="d-flex">
				{this.label}
				{icon}
			</span>
		);
	},
});
