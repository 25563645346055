









































import { defineComponent, computed, ref, inject, ComputedRef, set } from "@vue/composition-api";
import { PreviewAssetGraph } from "@/components.assets/preview-asset-graph";
import { StudyContext } from "@/components.studies/manage-study";
import { DisplayJSON} from "@ui";
import { default as FoldoutCard } from "./foldout-card.vue";

type Dictionary<T> = { [k:string]:T }; 

export default defineComponent({

	emits:[],
	props:{
	},
	components:{
		PreviewAssetGraph,
		FoldoutCard,
		"display-json":DisplayJSON,
	},
	setup(){

		const studyContext = inject<ComputedRef<StudyContext>>("studyContext");

		if(!studyContext){ throw Error("Channels Preview: Study context missing!"); }


		const {
			blueprint,
			blueprintVersion,
			channels,
		} = studyContext.value;

		const foldouts = ref<Dictionary<boolean>>({});

		channels.value.forEach(channel => {
			set(foldouts.value, channel.key, false);
		})

		const toggleFoldout = (k:string) => {
			set(foldouts.value, k, !foldouts.value[k]);
		};

		const getIcon = (channel:any) => {
			// return "mdi.code-braces"
			return iconMap[channel.type] || "mdi.code-braces";
		};
		

		return {
			blueprint,
			blueprintVersion,
			channels,
			foldouts,
			toggleFoldout,
			getIcon,
		};
	}
});

	
const iconMap:Dictionary<string> = {
	"form":"mdi.clipboard-text",
	"form/answer":"mdi.card-text",
	"form/checkboxes/option":"mdi.check-box-outline",
	"form/multiplechoice":"mdi.radiobox-marked",
	"form/dropdown":"mdi.chevron-down-box",
	"form/linearscale/row":"mdi.electric-switch-closed",
	"CognitiveTest":"head-cog mdi-flip-h",
	"Form":"file-document-edit",
	"Scene":"nature-people",
	"Gallery":"image-multiple",
	"gallery/feedback/scale":"mdi.electric-switch-closed",
};

