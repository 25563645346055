import { PhraseDictionary } from "../types";
import { mergeDictionaries } from "../mergeDictionaries";

const label:PhraseDictionary  = {
	"default":{
		en:"Default",
		is:"Sjálfvalið",
	},
	"url":{
		en:"URL",
		is:"Slóð"
	},
	"width":{
		en:"Width",
		is:"Vídd"
	},
	"untitled":{
		en:"Untitled",
		is:"Ótitlað"
	},
	"today":{
		en:"Today",
		is:"Í dag"
	},
	"monthAgo":{
		en:"One Month Ago",
		is:"Fyrir mánuði síðan"
	},
	"description":{
		en:"Description",
		is:"Lýsing"
	},
	"expires":{
		en:"Expires",
		is:"Rennur út"
	},
	"requestIp":{
		en:"Request IP",
		is:"IP tala"
	},
	"started":{
		en:"Started",
		is:"Byrjað"
	},
	"completed":{
		en:"Completed",
		is:"Klárað"
	},
	"activity":{
		en:"Activity",
		is:"Virkni"
	},
	"type":{
		en:"Type",
		is:"Týpa"
	},
	"modified":{
		en:"Modified",
		is:"Breytt"
	},
	"lastModified":{
		en:"Last Modified",
		is:"Síðast breytt"
	},
	"upToDate":{
		en:"Up to Date",
		is:"Uppfært"
	},
	"updating":{
		en:"Updating",
		is:"Uppfæri"
	},
	"sharing":{
		en:"Sharing",
		is:"Deiling"
	},
	"lastVisited":{
		en:"Last Visited",
		is:"Síðast heimsótt"
	},
	"created":{
		en:"Created",
		is:"Búið til"
	},
	"open":{
		en:"Open",
		is:"Opið"
	},
	"more":{
		en:"More",
		is:"Meira"
	},
	"startDate":{
		en:"Start Date",
		is:"Byrjunardagur"
	},
	"progress":{
		"en": "Progress",
		"is": "Framvinda"
	},
	"optionalProgress":{
		"en": "Opt. Progress",
		"is": "Valkvæm framvinda"
	},
	"finished":{
		en:"Finished",
		is:"Klárað"
	},
	"duration":{
		en:"Duration",
		is:"Lengd"
	},
	"filters":{
		en:"Filters",
		is:"Síur"
	},
	"any":{
		en:"Any",
		is:"Eitthvað"
	},
	"all":{
		en:"All",
		is:"Allt"
	},
	"exclude":{
		en:"Exclude",
		is:"Útiloka"
	},
	"home":{
		en:"Home",
		is:"Heim"
	},
	"favourites":{
		en:"Favourites",
		is:"Uppáhalds"
	},
	"settings":{
		en:"Settings",
		is:"Stillingar",
	},
	"overview":{
		en:"Overview",
		is:"Yfirlit",
	},
	"copyOf":{
		en:"Copy of $n",
		is:"Afrit af $n"
	},
	"yes":{
		en:"Yes",
		is:"Já",
	},
	"no":{
		en:"No",
		is:"Nei",
	},
	"paginationSelection":{
		en:"Showing $min-$max of $rows",
		is:"Sýnir $min-$max af $rows"
	},
	"indexOfLength":{
		en:"$i of $l",
		is:"$i af $l"
	},
	"name":{
		en:"Name",
		is:"Nafn"
	},
	"language":{
		en:"Language",
		is:"Tungumál"
	},
	"on":{
		en:"On",
		is:"Kveikt"
	},
	"off":{
		en:"Off",
		is:"Slökkt"
	},
	"showingPagination":{
		en:"$x-$y of $z",
		is:"$x-$y af $z",
	},
	"size":{
		en:"Size",
		is:"Stærð"
	},
	"results":{
		en:"Results",
		is:"Niðurstöður"
	},
	"status":{
		en:"Status",
		is:"Staða"
	},
	"optional":{
		en:"Optional",
		is:"Valfrálst"
	},
	"thumbnail":{
		en:"Thumbnail",
		is:"Smámynd"
	},
	"or":{
		en:"Or",
		is:"Eða"
	},
	"perItem":{
		en:"Per Item",
		is:"Fyrir hvert stak"
	},
	"everything":{
		en:"Everything",
		is:"Allt"
	},
	"color":{
		en:"Color",
		is:"Litur"
	},
	"updated":{
		en:"Updated",
		is:"Uppfært"
	},
};

const action:PhraseDictionary  = {
	"browse":{
		"en": "Browse",
		"is": "Finna"
	},
	"enable":{
		"en": "Enable",
		"is": "Virkja"
	},
	"disable":{
		"en": "Disable",
		"is": "Afvirkja"
	},
	"preview":{
		"en": "Preview",
		"is": "Forskoðun"
	},
	"edit":{
		en:"Edit",
		is:"Breyta"
	},
	"viewHistory":{
		en:"View History",
		is:"Skoða sögu"
	},
	"cancel":{
		en:"Cancel",
		is:"Hætta við"
	},
	"rename":{
		en:"Rename",
		is:"Endurnefna"
	},
	"duplicate":{
		en:"Duplicate",
		is:"Afrita"
	},
	"addToFavourites":{
		en:"Add to Favourites",
		is:"Seta í uppáhalds"
	},
	"removeFromFavourites":{
		en:"Remove from Favourites",
		is:"Fjarlægja úr uppáhalds"
	},
	"delete":{
		en:"Delete",
		is:"Eyða"
	},
	"remove":{
		en:"Remove",
		is:"Fjarlægja"
	},
	"back":{
		en:"Back",
		is:"Til baka",
	},
	"setColor":{
		en:"Set Color",
		is:"Seta lit"
	},
	"close":{
		en:"Close",
		is:"Loka"
	},
	"open":{
		en:"Open",
		is:"Opna"
	},
	"download":{
		en:"Download",
		is:"Hlaða niður"
	},
	"favourite":{
		en:"Favourite",
		is:"Í uppáhalds"
	},
	"reset":{
		en:"Reset",
		is:"Endurstilla"
	},
	"clear":{
		en:"Clear",
		is:"Hreinsa"
	},
	"unfavourite":{
		en:"Unfavourite",
		is:"Úr uppáhalds"
	},
	"viewDescription":{
		en:"View Description",
		is:"Skoða lýsingu"
	},
	"editColor":{
		en:"Edit Color",
		is:"Breyta lit"
	},
	"editText":{
		en:"Edit Text",
		is:"Breyta texta"
	},
	"save":{
		en:"Save",
		is:"Vista"
	},
	"refresh":{
		"en": "Refresh",
		"is": "Uppfæra"
	},
	"refreshAll":{
		"en": "Refresh All",
		"is": "Uppfæra allt"
	},
	"create":{
		en:"Create",
		is:"Búa til"
	},
	"autoGenerate":{
		en:"Auto-Generate",
		is:"Sjálfvirkt búa til"
	},
	"uploadFile":{
		en:"Upload File",
		is:"Hlaða upp skrá"
	},
	"filterName":{
		en:"Filter Name",
		is:"Sía eftir nafni"
	},
	"apply":{
		en:"Apply",
		is:"Nota"
	},
};

const message:PhraseDictionary  = {
	"noActivity":{
		en:"No activity in the last $n days",
		is:"Engin virkni síðustu $n daga"
	},
	"noResults":{
		en:"No results",
		is:"Engar niðurstöður"
	},
	"errorOccurred":{
		en:"An error occurred",
		is:"Villa kom upp",
	},
};

export const global = mergeDictionaries("", { label, action, message });