
export const useWindowResize = () => {

	const listeners:Function[] = [];

	const onWindowChange = () => listeners.forEach((l) => l());

	const onResized = (fn:Function) => listeners.push(fn);

	const dispose = () => {
		window.removeEventListener("resize", onWindowChange);
		window.removeEventListener("orientationchange", onWindowChange);
	}

	window.addEventListener("resize", onWindowChange);
	window.addEventListener("orientationchange", onWindowChange, false);

	return {
		onResized,
		dispose
	};
};