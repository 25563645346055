





























import { defineComponent, computed, PropType } from "@vue/composition-api";
import { ItemBox, getBaseProps } from "../shared";
import { EditOptions } from "../edit-options";

export default defineComponent({
	emits:[ "input" ],
	props:{
		value:{
			type:Number,
			default:-1,
		},
		options:{
			type:Array as PropType<string[]>,
			default: () => [],
		},
		...getBaseProps(),
	},
	components:{
		ItemBox,
		EditOptions,
	},
	setup(props, ctx){

		const displayOptions = computed(() => {
			return props.options.map((o, i) => {
				return {
					value: i,
					text: o
				}
			});
		});

		const val = computed({
			get:() => props.value,
			set:v => ctx.emit("input", v)
		});

		return {
			val,
			displayOptions
		};
	}
});

