import { NodeTemplate } from "@/components.edit/edit-graph";

export type SelectParams = {
	mode:"SelectOne"
}

export const Select:NodeTemplate<SelectParams> = {
	name: "Select",
	// icon: "/img/graph/select.png",
	// icon: "crosshairs-question",
	// icon:"help-rhombus mdi-rotate-90",
	icon:"arrow-decision mdi-rotate-90 text-black-50",

	parameters:{
		mode: "SelectOne"
	},
	ports: {
		start: {
			type: "input",
			label: {
				text: "",
				attributes: {
					x: 10
				}
			},
			x: -50
		},
		exit: {
			type: "output",
			label: {
				text: "",
				style: {
					"text-anchor": "end"
				},
				attributes: {
					x: -10
				}
			},
			x: 50
		}
	},
	template:`
	<svg style="overflow:visible" x="-50" y="-50">
	<polygon points="50,0 100,50 50,100 0,50" style="fill:white; xfill:#4a4a4a;stroke:white;stroke-width:3"/>
	</svg> `
};


// const template = `
// <svg style="overflow:visible" x="-50" y="-50">
// 	<rect rx="100" ry="100" width="100" height="100" stroke-width="5" stroke="white" fill="#292929"></rect>
// </svg>`;