import * as THREE from "three";
import { MouseInput} from "./input/MouseInput";
import { TouchInput } from "./input/TouchInput";
import { Time } from "../App";

import * as NumUtils from "./Number";

const ROTATION_MULTIPLIER = 20;

export class PanningControls {

	constructor(target:THREE.Object3D, el:EventTarget){
		this._target = target;
		this._mouse = new MouseInput(el);
		this._touch = new TouchInput(el);
		this._root = new THREE.Object3D();
		this._root2 = new THREE.Object3D();
		this._root.add(this._root2);
		this._root2.add(this._target);
	}

	public setPosition(pos:{ x:number, y:number, z:number }){
		this._root.position.set(pos.x, pos.y, pos.z);
	}

	public setRotationX(v:number){
		const rot = this._root2.rotation;

		const z = this._root.position.z;

		if(v < -135){
			v += 135;
		}
		// console.log(v);

		const nx = (v) * THREE.MathUtils.DEG2RAD;
		// console.log(v);
		this._root2.rotation.setFromVector3(new THREE.Vector3(nx, rot.y, rot.z));
		this._refresh = true;
	}

	public setRotationY(v:number){
		
		const rot = this._root.rotation;
		const z = this._root.position.z;

		

		// console.log(v);
		if(v < 0){
			// console.log("meh");
			// v += 180;
			
			// v -= 90;
		}
		else {
			// v += 90;
		}

		if(z < 0 && v < 0){
			v -= 180;
		}
		else if(z > 0){
			v -= 90;
		}

		v += 90;
		const ny = v * THREE.MathUtils.DEG2RAD;
		this._root.rotation.setFromVector3(new THREE.Vector3(rot.x,ny, rot.z));
		this._refresh = true;
	}

	public update(){
		const rDelta = this.getRotation();
		if(rDelta.y && !this._refresh){
			this._root.rotateY(rDelta.y * THREE.MathUtils.DEG2RAD);
			// this._root.updateMatrixWorld();
		}

		if(rDelta.x && !this._refresh){
			this._root2.rotateX(rDelta.x * THREE.MathUtils.DEG2RAD);
			// this._root2.rotation.x = NumUtils.clamp(this._root2.rotation.x * THREE.MathUtils.RAD2DEG, -89, 89) * THREE.MathUtils.DEG2RAD;
		}

		if(rDelta.x || rDelta.y || this._refresh){
			this._target.updateWorldMatrix(true,true);
			// this._target.updateMatrixWorld(true);
			this._refresh = false;
		}
		// console.log(this._root2.rotation.z);
	}

	public dispose(){
		this._mouse.dispose();
		this._touch.dispose();
	}

	private _root:THREE.Object3D;
	private _root2:THREE.Object3D;
	private _target:THREE.Object3D;
	private _mouse:MouseInput;
	private _touch:TouchInput;

	private _refresh:boolean = false;

	getRotation(){
		const rDelta = { x:0, y:0 }
		if(this._mouse.mouseDown(0)){
			rDelta.y += ROTATION_MULTIPLIER * Time.deltaTime * this._mouse.mouseDeltaX * -1;
			rDelta.x += ROTATION_MULTIPLIER * Time.deltaTime * this._mouse.mouseDeltaY * -1;
		}
		return rDelta;
	}
}