




























































// vendor
import { defineComponent, computed, ref, onMounted, watch, reactive, onUnmounted, PropType } from "@vue/composition-api";
// project
import { generateGUID } from "@/utils/guid";
// local
import * as config from "../../ts/config";
import { DataProp } from "../../ts/types";
import { default as PropNode } from "./prop-node.vue";
import { default as PropConnector } from "./prop-connector.vue";
import { parseBinding } from "../../ts/utils/parseBinding";

type DisplayProp = DataProp&{
	guid:string;
	x:number;
	y:number;
	mirror:boolean;
	stroke:string;
	fill:string;
	bound?:boolean;
	labelColor:string;
	optional?: boolean;
};

const validateBinding = (from:DisplayProp, to:DisplayProp, bindings:string[]) => {
	if(from.mirror === to.mirror){ return false; } // same node type
	if(from.guid === to.guid){ return false; } // same node
	if(from.type !== to.type){ return false; } // different value types
	if(from.mirror || !to.mirror){ return false; } // invalid order
	if(bindings.findIndex(b => b === `${from.name}->${to.name}`) > -1){ return false; } // binding exists
	return true;
};

const calculateNodePosition = (cw:number, ci:number, ph:number, pi:number):{ x:number, y:number } => {
	const x = (cw * 0.5) + cw * ci;
	const y = (ph * 0.5) + ph * pi;
	return { x, y };
};


export default defineComponent({

	emits:[ "delete-prop", "edit-prop" ],
	props:{
		sourceProps:{
			type:Array as PropType<DataProp[]>,
			default:() => []
		},
		targetProps:{
			type:Array as PropType<DataProp[]>,
			default:() => []
		},
		bindings:{
			type:Array as PropType<string[]>,
			default:() => []
		}
	},
	components:{
		PropNode,
		PropConnector,
	},
	setup(props, context){
		
		const wrapper = ref<HTMLElement>();
		const columnWidth = ref(1);
		const canvasHeight = ref(0);
		const mousePos = reactive({ x:0, y:0 });

		// guid -> guid
		const propDrag = reactive<{ from:string|null, to:string|null }>({
			from:null,
			to:null
		});

		const getPortPosition = (guid:string) => {
			const p = displayProps.value.find(p => p.guid === guid);
			if(!p){ return null; }
			const x = p.x + (config.propNodeWidth * 0.5) * (p.mirror ? -1 : 1);
			return { x, y:p.y };
		};

		const hasBinding = (name:string, mirror:boolean = false) => {
			const prop = mirror ? "to" : "from";
			return parsedBindings.value.findIndex(pb => pb[prop] === name) > -1;
		};

		
		const getDisplayProps = (props:DataProp[], ci:number, mirror:boolean = false):DisplayProp[] => {
			const sy = (canvasHeight.value - (props.length * config.propRowHeight)) * 0.5;
			return props.map((prop, i) => {
				const p = calculateNodePosition(columnWidth.value, ci, config.propRowHeight, i);

				const bound = hasBinding(prop.name, mirror);

				const boundColor = "white";

				const color = bound ? boundColor : "#343a40";
				const outline = bound ? boundColor : "white";
				const labelColor = bound ? "black" : "white";

				return {
					...prop,
					guid:generateGUID(),
					x:p.x,
					y:p.y + sy,
					mirror,
					stroke:outline,
					fill:color,
					bound,
					labelColor,
					optional:prop.optional
				}
			});
		};

		const findPropByName = (name:string, mirror:boolean = false) => {
			const prop = displayProps.value.find(p => p.name === name && p.mirror === mirror);
			if(!prop){ throw new Error("Prop not found"); }
			return prop;
		};

		const findPropByGUID = (guid:string) => {
			const prop = displayProps.value.find(p => p.guid === guid);
			if(!prop){ throw new Error("Prop not found"); }
			return prop;
		};

		const parsedBindings = computed(() => {
			return props.bindings.map(b => parseBinding(b));
		});

		const displayConnectors = computed(() => {

			if(displayProps.value.length === 0){ return []; }
			return props.bindings.map(b => {

				const pb = parseBinding(b);
				
				const fromProp = findPropByName(pb.from, false);
				const toProp = findPropByName(pb.to, true);
				const from = getPortPosition(fromProp.guid);
				const to = getPortPosition(toProp.guid);

				return {
					guid:generateGUID(),
					binding:b,
					color:"white",
					from,
					to,
				};
			});
		});

		const displayProps = computed(() => {
			if(canvasHeight.value === 0) { return []; }
			return [
				...getDisplayProps(props.sourceProps, 0),
				...getDisplayProps(props.targetProps, 1, true),
			];
		});

		const dragConnector = computed(() => {

			if(!propDrag.from){ return null; }

			return {
				from: getPortPosition(propDrag.from),
				to: {
					x: mousePos.x,
					y: mousePos.y
				}
			};
		});

		const refreshSize = () => {
			const el = document.getElementById("Wrapper");
			if(!el){ return; }
			columnWidth.value = el.clientWidth * 0.5;;
			canvasHeight.value = el.clientHeight;
		};

		const onMouseMove = (e:any) => {
			mousePos.x = e.layerX;
			mousePos.y = e.layerY;
		};

		const onMouseUp = () => {
			setTimeout(() => {
				propDrag.from = null;
				propDrag.to = null;
			}, 0);
		};

		const beginDrag = (guid:string) => {
			propDrag.from = guid;
		};

		const endDrag = (guid:string) => {
			if(!propDrag.from){ return; }
			let from = findPropByGUID(propDrag.from);
			let to = findPropByGUID(guid);

			if(from.mirror && !to.mirror){ // swap if mirrored
				let t = from;
				from = to;
				to = t;
			}

			if(!validateBinding(from, to, props.bindings)){ return; }
			const binding = `${from.name}->${to.name}`;
			props.bindings.push(binding);
		};

		const deleteBinding = (binding:string) => {
			const i = props.bindings.findIndex(b => b === binding);
			if(i > -1){ props.bindings.splice(i, 1); }
		};

		const deleteProp = (name:string) => {
			context.emit("delete-prop", name);
		};

		const editProp = (name:string) => {
			context.emit("edit-prop", name);
		};

		const init = () => {
			const el = document.getElementById("Wrapper");
			refreshSize();
			if(!el){ return; }
			el.addEventListener("resize", refreshSize);
		};

		onMounted(() => {
			setTimeout(init, 0);
			window.addEventListener("mouseup", onMouseUp);
		});

		onUnmounted(() => {
			window.removeEventListener("mouseup", onMouseUp);
		});

		return {
			mousePos,
			columnWidth,
			canvasHeight,
			wrapper,
			displayProps,
			displayConnectors,
			dragConnector,
			onMouseMove,
			beginDrag,
			endDrag,
			deleteBinding,
			deleteProp,
			editProp,
		};
	}
});

