import { FormItemType } from "@psychlab/types/form";

export const useFormIcons = () => {

	const getItemIcon = (type:FormItemType) => {
		return (itemIcons)[type] || "";
	};

	return {
		getItemIcon
	};
};

const itemIcons:{ [key in FormItemType]?: string } = {
	Answer:"mdi.card-text",
	Checkboxes:"mdi.check-box-outline",
	MultipleChoice:"mdi.radiobox-marked",
	Dropdown:"mdi.chevron-down-box",
	LinearScale:"mdi.electric-switch-closed",
	Information:"mdi.information",
	HTML:"mdi.code-tags",
	Image:"mdi.image",
	Video:"mdi.play-box",
};