import { http } from "@/http";
import { AxiosInstance } from "axios";
import { addAuthToRequest} from "@/http";
import axios from "axios";
import { Task, TaskCreateOptions, TaskType } from "@/psychlab/types";
import { getConfig } from "@config";


const HTTP:AxiosInstance = http;

const DownloadHttp = axios.create({
	responseType:"blob"
});

DownloadHttp.interceptors.request.use(config => {
	config.baseURL = getConfig().apiURL;
	return config;
});

DownloadHttp.interceptors.request.use(addAuthToRequest);

export const getTasks = async(study:string):Promise<Task[]> => {
	const q:any = {
		"select":"%2Boutput"
	};
	const qstr = buildQueryString(q);
	const { data } = await HTTP.get(`studies/${study}/tasks${qstr}`);
	return data as Task[];
};

export const getTaskTypes = async(study:string):Promise<TaskType[]> => {
	const { data } = await HTTP.get(`studies/${study}/tasks/types`);
	return data;
};

export const downloadFile = async(study:string, taskId:string, index:number) => {
	return await DownloadHttp.get(`studies/${study}/tasks/${taskId}/files/${index}`);
}

export const createTask = async(study:string, options:TaskCreateOptions):Promise<Task> => {
	const { data } = await HTTP.post(`studies/${study}/tasks`, options);
	return data as Task;
};

export const deleteTask = async(study:string, taskId:string):Promise<void> => {
	return await HTTP.delete(`studies/${study}/tasks/${taskId}`);
};

const buildQueryString = (queryObject:any) => {
	let r = "";
	let i = 0;
	Object.keys(queryObject).forEach((k) => {
		let v = queryObject[k];
		if(v === undefined) { return; }
		let prefix = i === 0 ? "?" : "&";
		r += `${prefix}${k}=${v}`;
		i++;
	});
	return r;
};