















import { defineComponent, computed, ref, PropType } from "@vue/composition-api";
import { Container, Row, Col, ErrorBox } from "@ui";
import { IFormTheme } from "@psychlab/types/form";

export default defineComponent({

	emits:[],
	props:{
		theme:{
			type:Object as PropType<IFormTheme>,
			default:() => {},
		},
		invalid:{
			type:Boolean,
			default:false,
		},
		error:{
			type:String,
			default:"",
		}
	},
	components:{
		Container,
		Row,
		Col,
		ErrorBox,
	},
	setup(){
		

		return {

		};
	}
});

