import { WizardContext } from "../types/WizardContext";
import * as Utils from "./utils";

export const CountChannelOptions = {
	name: "CountChannelOptions",
	description: {
		en:"Counts how often a field option appears in responses.",
		is:"Telur hve oft hver svarmöguleiki í dálk kemur fyrir í svörum.",
	},
	isUsable(ctx:WizardContext){
		return ctx.channels.findIndex(Utils.isCountableChannel) > -1;
	},
	parameters: {
		channel: {
			type:"string",
			title:{
				en:"Field",
				is:"Dálkur"
			},
			drawer(context:WizardContext){
				return {
					type:"dropdown",
					options:Utils.getOptions(context.channels, Utils.isCountableChannel)
				}
			},
			default:null
		}
	},
	output:{
		values:{
			type:"number[]"
		},
		labels:{
			type:"string[]"
		}
	}
};