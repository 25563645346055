import { NodeTemplate } from "@/components.edit/edit-graph";
import { AssetPointer } from "@/psychlab/types";

export type FormParams = {
	asset:AssetPointer<any>|null
}

export const Form:NodeTemplate<FormParams> = {
	name: "Form",
	// icon: "clipboard-text",
	icon: "file-document-edit text-black-50",
	// icon: "/img/graph/form.png",
	parameters:{
		asset: null
	},
	ports: {
		start: {
			type: "input",
			label: {
				text: "",
				attributes: {
					x: 10
				}
			},
			x: -50
		},
		exit: {
			type: "output",
			label: {
				text: "",
				style: {
					"text-anchor": "end"
				},
				attributes: {
					x: -10
				}
			},
			x: 50
		}
	},
	template: `
	<svg style="overflow:visible" x="-50" y="-50">
		<rect rx="40" ry="40" width="100" height="100" stroke-width="1" stroke="white" fill="#9471fe" ></rect>
	</svg>`
};