



















































// vendor
import { defineComponent, PropType, computed } from "@vue/composition-api";
// local
import { Information } from "../../ts/types/DisplayForm";

export default defineComponent({

	props:{
		showBack:{
			type:Boolean,
			default:true
		},
		config:{
			type:Object as PropType<Information>,
			required:true
		}
	},
	setup(props, context){
		
		const title = computed(() => props.config.title);
		const text = computed(() => props.config.text);

		const onPrevious = () => context.emit("previous");
		const onNext = () => context.emit("next");

		return {
			onPrevious,
			onNext,
			title,
			text
		};
	}
});

