























































// vendor
import { defineComponent, computed, ref, PropType } from "@vue/composition-api";
// project
import { Icon } from "@ui";
// local
import { WizardStep } from "../ts/types";

export default defineComponent({

	emits:[],
	props:{
		activeStep: {
			type: Number,
			default: 0,
		},
		steps: {
			type: Array as PropType<WizardStep[]>,
			default: 1,
		},
	},
	components:{
		Icon
	},
	setup(props){
		
		const displaySteps = computed(() => {

			return props.steps;
		});
		

		return {
			displaySteps
		};
	}
});

