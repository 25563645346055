import { computed, ref, watch, SetupContext } from "@vue/composition-api";
import * as Utils from "../utils";
import { useLazyValue } from "../hooks/useLazyValue";

type Dictionary<T> = { [k:string]:T };

export const useSessionRouteFilters = (studyId:string, context:SetupContext) => {

	const filters = ref<Dictionary<any>>({ "group":studyId });

	const {
		lazyValue:lazyFilter,
	} = useLazyValue(10, filters);

	const queryFilters = computed(() => lazyFilter.value);
	
	const lastEdit = ref(0);

	const addQueryValue = (k:string, ob:Dictionary<any>) => {
		const cvt = Utils.filterConverters[k];
		let v:any = context.root.$route.query[k] as any;
		if(cvt && cvt.path){ k = typeof(cvt.path) === "function" ? cvt.path(v) : cvt.path; }
		if(!v && cvt && cvt.default){
			v = typeof(cvt.default) === "function" ? cvt.default() : cvt.default;
		}
		if(v === null || v === undefined){ return; }
		if(cvt && cvt.convert) { cvt.convert(v, ob, k); }
		else {
			ob[k] = v;
		}
	};

	const refreshFilters = () => {
		lastEdit.value = Date.now();
		const f:Dictionary<any> = {
			"group":studyId,
		};
		const ks = Object.keys(Utils.filterConverters);
		ks.forEach(k => addQueryValue(k, f));
		filters.value = f;
	};

	Object.keys(Utils.filterConverters)
	.forEach(k => {
		watch(() => context.root.$route.query[k], (v, o) => {
			// console.log(o, v);
			refreshFilters();
		});

	});

	watch(() => context.root.$route.query, (v) => {
		// console.log("filters");
		// console.log(v);
		// refreshFilters();
	});

	refreshFilters();

	return {
		queryFilters,
	};
};