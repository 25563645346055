export * from "./studies";
export * from "./auth";
export * from "./tags";
export * from "./home";
export * from "./assets";
export * from "./debug";
export * from "./error";
export * from "./open-session";
export * from "./open-survey";
export * from "./verify-survey-auth";

// catch-all
export const FallbackRoute = {
	path: "*",
	redirect: "/home"
};
