import { defineComponent } from "@vue/composition-api";
import { RunScene } from "@/components.run/run-scene";
import { getProps } from "./getProps";
import { AbsBox } from "@ui";

type SceneParams = {};

export const Scene = defineComponent({
	emits:[ "end", "previous" ],
	components:{
		RunScene,
		AbsBox,
	},
	props:getProps<SceneParams>(),
	setup(_, context){
		return {
			onEnd:() => context.emit("end"),
			onBack:() => context.emit("previous")
		};
	},
	render(){
		const data = this.node?.parameters as any;

		return (
			<AbsBox>
				<RunScene
				dataNamespace={this.nodeId || ""}
				parameters={data}
				showBack={this.showBack}
				isLast={this.isLast}
				v-on:previous={this.onBack}
				v-on:end={this.onEnd}
				/>
			</AbsBox>
			
		);
	}
});