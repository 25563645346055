// import * as SnapSVG from "snapsvg"

import * as SnapType from "snapsvg";
declare var Snap: typeof SnapType;




// export { SnapSVG }

// const v = SnapSVG as any;

// export { v as SnapSVG };


// export { SnapType };

// export const getSnap = () => SnapSVG;



// export const Snap = require(`imports-loader?this=>window,fix=>module.exports=0!snapsvg/dist/snap.svg.js`);

export const findSnap = (el:any) => (Snap as any)(el) as SnapType.Paper;