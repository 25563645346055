

type Result = {
	mod:Mod|null;
	include:string[];
	exclude:string[];
}

type Mod = "all"|"nin";


export const parseLabelQuery = (q:string):Result => {

	try {
		const [
			p1,
			p2,
		] = q.split("-");
	
		const exclude = p2 ? parseCommaList(p2) : [];
	
		const [
			mod,
			commaList
		] = p1.split(";");
		
		const include = commaList ? parseCommaList(commaList) : [];
		return {
			mod:mod as Mod,
			include,
			exclude
		};
	}
	catch(err:any){
		console.log(err.message);
	}
	return {
		mod:null,
		include:[],
		exclude:[]
	};
};


const parseCommaList = (v:string) => {
	return v.split(",");
};