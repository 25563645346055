import { Dictionary } from "./Dictionary";

export const flattenObject = (ob:Dictionary<any>, propValidator?:(v:any) => boolean) => {
	let res = "";
	for(var key in ob){
		if(propValidator && !propValidator(ob[key])){ continue; }
		res += key + ":" + ob[key] + ";";
	}
	return res;
};

export const getDeepObjectValue = (ob:any, p:string, defaultValue:any) => {
	for (var i = 0, path = p.split('.'), len = path.length; i < len; i++){
		if(!ob){
			return defaultValue;
		}
		ob = ob[path[i]];
	};
	return ob;
};

export const appendOtherObject = function(target:any, other:any, propValidator?:(v:any) => boolean){
	if(!propValidator){ return; }
	for(var key in other){
		let val = other[key];
		if(propValidator(val)){
			target[key] = val;
		}
	}
};