











// vendor
import { defineComponent, computed, ref } from "@vue/composition-api";

export default defineComponent({

	emits:[ "input" ],
	props:{
		value:{
			type:Boolean,
			default:false,
		}
	},
	setup(props, ctx){
		
		const val = computed({
			get:() => props.value,
			set:v => ctx.emit("input", v)
		});

		return {
			val,
		};
	}
});

