
















// vendor
import { defineComponent, computed, ref, PropType } from "@vue/composition-api";

type StringConfig = {

};

export default defineComponent({

	emits:[ "input" ],
	props:{
		value:{
			// type:String
		},
		label:{
			type:String
		},
		config:{
			type:Object as PropType<StringConfig>,
			required:true
		}
	},
	components:{
	},
	setup(props, context){
		
		const val = computed({
			get: () => props.value,
			set:v => context.emit("input", v)
		});

		return {
			val
		};
	}
});

