













// vendor
import { defineComponent, PropType, computed } from "@vue/composition-api";
import { Icon } from "../icons";

export default defineComponent({
	emits:[ "click" ],
	components:{ Icon },
	props:{
		icon:{
			type:String,
			required:false
		},
		label:String,
		variant:{
			type:String as PropType<"dark"|"light">,
			default:"light"
		},
		disabled:{
			type:Boolean,
			default:false,
		},
		block: {
			type:Boolean,
			default:false,
		},
		size:{
			type:String as PropType<"xl"|"sm"|"md"|"lg">,
			default:"md"
		},
		dashed:{
			type:Boolean,
			default:false,
		},
		noBorder:{
			type:Boolean,
			default:false,
		}
	},
	setup(props, ctx){

		const cls = computed(() => {
			const { variant, block, size, dashed, noBorder } = props;
			return `v-${variant} ${block ? 'block' : ''} s-${size} ${dashed ? 'dashed' : ''} ${noBorder ? 'no-border' : ''}`;
		})

		return {
			cls,
			handleClick:(e:Event) => ctx.emit("click", e)
		};
	}
});

