import { PropMap } from "./PropMap";
import { EventBus } from "./EventBus";
import { flattenObject } from "./Object"

// import { Dictionary } from "@/utils/types";

type Dictionary<T> = { [k:string]:T };

import * as SnapType from "snapsvg";
declare let Snap: typeof SnapType;

export class Connector2D {

	constructor(root:SnapType.Paper){
		
		this._group = root.group();
		this._line = this._group.polyline([ 0, 0, 0, 0 ]);

		this._markerLine = this._group.polyline([ 0, 0, 0, 0 ]);
		this._markerLine.attr({
			"fill": "transparent",
			"strokeWidth": 4,
			"stroke": "transparent"
		});
		(this._markerLine.node.style as any)["pointer-events"] = "none";

		this._group.node.style["overflow"] = "visible";
		this._line.attr({
			fill: "transparent"
		});

		// console.log(this.setLineAttribute);
		const scope = this;

		const setLineAttribute = this.setLineAttribute.bind(this);

		this._props.initialize({
			"width": {
				value: 1,
				validator: v => typeof(v) === "number",
				onChange: v => setLineAttribute("strokeWidth", v)
			},
			"color": {
				value: "black",
				validator: v => typeof(v) === "string",
				onChange: this.setColor.bind(this)
			},
			"useEvents": {
				value: false,
				validator: v => typeof(v) === "boolean",
				onChange: this.setPointerEvents.bind(this)
			},
			"show": {
				value: true,
				validator: v => typeof(v) === "boolean"
			},
			"blur": {
				value: 0,
				validator: v => typeof(v) === "number" && v >= 0,
				onChange: this.setBlur.bind(this)
			},
			"useEndArrow": {
				value: false,
				validator: v => typeof(v) === "boolean",
				onChange: this.toggleMarker.bind(this)
			}
		});
	
		this._props.useDefaultValues();

		this._line.mousedown(e => {
			this._events.emit("press", e)
		});
	
		this._line.mouseup(e => {
			this._events.emit("release", e)
		});
	
		this._line.click(e => {
			this._events.emit("click", e);
		});
	
		this._line.hover(e => {
			this._events.emit("hover", e);
		}, e => {
			this._events.emit("unhover", e);
		});
	}

	public toggleVisible(state:boolean){
		this._props.configure({
			show: state
		});
		this.refreshGroup();
	};

	public setPoints(pathstring:string){
		this._line.attr({
			points: pathstring
		});
		this._markerLine.attr({
			points: pathstring
		});
	};

	public configureAttributes(attr:Dictionary<any>){
		this._line.attr(attr);
		if(this._arrowMarker){
			this._arrowMarker.attr(attr);
		}
	}

	public configure(ob:Dictionary<any>){
		this._props.configure(ob);
	};

	public on<N extends keyof ConnectorEvents>(name:N, fn:ConnectorEvents[N]){
		this._events.on(name, fn);
	}

	public off<N extends keyof ConnectorEvents>(name:N, fn:ConnectorEvents[N]){
		this._events.off(name, fn);
	}

	public intersectsBBox(bbox:SnapType.BBox){
		return Snap.path.isBBoxIntersect(bbox, this._line.getBBox());
	};

	private _props:PropMap = new PropMap();
	private _events = new EventBus<ConnectorEvents>();
	private _group:SnapType.Paper;
	private _line:SnapType.Element;
	private _markerLine:SnapType.Element;
	private _blurFilter?:SnapType.Element;
	private _arrowMarker?:SnapType.Element;

	private refreshGroup(){
		setElementStyle(this._group, {
			"display": (this._props.getValue("show") ? "?" : "none"),
			"pointer-events": (this._props.getValue("useEvents") ? "all" : "none")
		});
	};

	private setColor(c:string){
		this.setLineAttribute("stroke", c);
		if(this._arrowMarker){
			this._arrowMarker.node.style["fill"] = c;
		}
	};

	private setPointerEvents(state:boolean){
		(this._group.node.style as any)["pointer-events"] = (state ? "all" : "none");
	};

	private setLineAttribute(key:string, value:any){
		const args:any = {};
		args[key] = value;
		this._line.attr(args);
	};

	private setBlur(v:number){
		if(v > 0){
			if(this._blurFilter){ this._blurFilter.remove(); }
			this._blurFilter = this._group.filter(Snap.filter.blur(v, v));
			this._group.attr({
				filter: this._blurFilter
			});
		}
	};

	private toggleMarker(state:boolean){
		if(this._arrowMarker){ this._arrowMarker.remove(); }
		if(state){
			this._arrowMarker = createArrowMarker(this._group);
			this._arrowMarker.node.style["fill"] = this._line.node.getAttribute("stroke") || "white";
		}
		this._markerLine.attr({
			"marker-end": this._arrowMarker
		});
	};

}

const setElementStyle = (element:SnapType.Element, style:Dictionary<string>) => {
	element.attr({
		style: flattenObject(style, v => typeof(v) === "string")
	});
};

const createArrowMarker = (parent:SnapType.Paper) => {
	var size = 4;
	var shape = parent.path(`M 0 0 L ${size} ${size*0.5} L 0 ${size} z`);
	var marker = shape.marker(0, 0, 30, 30, size + 0.7, size*0.5);
	marker.node.setAttribute("strokeWidth", "0");
	(marker.node.style as any)["pointer-events"] = "none";
	return marker;
};

type EventHandler<T, R> = (p:T) => R;
interface ConnectorEvents {
	"press":EventHandler<any,void>,
	"release":EventHandler<any,void>,
	"click":EventHandler<any,void>,
	"hover":EventHandler<any,void>,
	"unhover":EventHandler<any,void>,
}