import { store } from "@/store";
import { getDeepProperty as getDeepObjectProperty } from "@utils/object";

export const checkAuth = async (to:any, from:any, next:any) => {
	let isPublic = getDeepObjectProperty(to, "meta.public", false);
	if (isPublic) {
		next();
	}
	else {
		if(store.getters.authenticated){
			next();
		}else{
			next({ name: "Login" })
		}
	}
};