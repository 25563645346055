import { PropMap } from "../utils/PropMap";
import { EventBus } from "../utils/EventBus";
import { SnapOffsetDrag } from "../utils/SnapOffsetDrag";
import { Dictionary } from "../utils/Dictionary";
import * as SnapSVG from "snapsvg";

export class ScrollGrid {

	public constructor(viewport:SnapSVG.Paper){

		this._element = viewport;

		const scope = this;


		this._props.initialize({
			"opacity": {
				value: 1,
				validator: v => typeof(v) === "number" && v <= 1 && v >= 0,
				onChange: v => scope.setOpacity(v)
			},
			"image": {
				value: "/img/graph/grid.png",
				onChange: v => scope.setImage(v)
			},
			"zoom": {
				value: 1,
				onChange: v => scope.setSize(scope.computeTileSize())
			},
			"xOffset": {
				value: 0,
				validator: v => typeof(v) === "number",
				onChange: v => scope.setOffsetX(v)
			},
			"yOffset": {
				value: 0,
				validator: v => typeof(v) === "number",
				onChange: v => scope.setOffsetY(v)
			},
			"tileSize": {
				value: 100,
				validator: v => typeof(v) === "number" && v > 0,
				onChange: v => scope.setSize(scope.computeTileSize())
			}
		});
	
		const viewportDrag = new SnapOffsetDrag(viewport);
	
		viewportDrag.onDrag((e) => {
			scope._events.emit("drag", e);
		});
	
		viewportDrag.configure({
			button: 4
		});
	
		this._props.useDefaultValues();
	}


	public on<N extends keyof GridEvents>(name:N, fn:GridEvents[N]){
		this._events.on(name, fn);
	}

	public off<N extends keyof GridEvents>(name:N, fn:GridEvents[N]){
		this._events.off(name, fn);
	}

	public configure(ob:Dictionary<any>){
		this._props.configure(ob);
	}

	public addOffset(x:number, y:number){
		this.setOffset(this._dragOffset.x + x, this._dragOffset.y + y);
	};

	public setOffset(x:number, y:number){
		this._dragOffset.x = x;
		this._dragOffset.y = y;
		this._props.configure({
			xOffset: this._dragOffset.x,
			yOffset: this._dragOffset.y
		});
	};

	private _element:SnapSVG.Paper;
	private _props = new PropMap();
	private _events = new EventBus<GridEvents>();
	private _dragOffset = { x: 0, y: 0 };

	private setStyle(key:string, value:any){
		(this._element.node.style as any)[key] = value;
	}

	private setOpacity(value:number){
		this.setStyle("opacity", value);
	}

	private setSize(value:number){
		this.setStyle("background-size", value + "px");
	}

	private setOffsetX(v:number){
		this.setStyle("background-position-x", `${v}px`);
	}

	private setOffsetY(v:number){
		this.setStyle("background-position-y", `${v}px`);
	}

	private setImage(url:string){
		this.setStyle("background-image", `url(${url})`)
	}

	private computeTileSize(){
		return this._props.getValue("tileSize") * this._props.getValue("zoom");
	}

}

type EventHandler<T, R> = (p:T) => R;
interface GridEvents {
	"drag":EventHandler<any,void>
}