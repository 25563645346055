








































import { defineComponent, computed, PropType } from "@vue/composition-api";
import { generateGUID } from "@utils/guid";
import { ContextOption } from "@ui";
import * as AppModal from "@/AppModal";
import { ContextButton, AbsBox } from "@ui";

export type ListItem = {
	icon:string;
	name:string;
	iconColor?:string;
	fn:() => void;
	contextFn:() => ContextOption[];
	activeFn?:() => boolean;
	routeFn?:() => any;
};

export default defineComponent({
	props:{
		options:{
			type:Array as PropType<ListItem[]>,
			default:() => []
		}
	},
	components:{
		ContextButton,
		AbsBox,
	},
	setup(props){
		
		const loopKeys = computed(() => props.options.map(o => generateGUID()));

		const openContext = (e:any, index:number) => {
			const option = props.options[index];
			AppModal.context(e, option.contextFn());
		};
		
		const getIconColor = (index:number) => {
			const option = props.options[index];
			return option.iconColor ? option.iconColor : "white";
		};

		const getItemRoute = (index:number) => {
			const fn = props.options[index].routeFn;
			return fn ? fn() : null;
		};

		const isActive = (index:number) => {
			const fn = props.options[index].activeFn;
			return fn ? fn() : false;
		};

		return {
			loopKeys,
			openContext,
			generateGUID,
			isActive,
			getItemRoute,
			getIconColor,
		};
	}
});

