

import * as THREE from "three";
import { getApp, killApp } from "../App";
import { IBehaviour } from "../core/IBehaviour";
import * as TextUtils from "../utils/Text";
import * as ThreeUtils from "../utils/Three";
import { useSceneRendering } from "./useSceneRendering";

export interface ISceneManager extends IBehaviour {
	getCamera():THREE.Camera|null;
	setCamera(c:THREE.Camera|null):void;
	add(o:THREE.Object3D):void;
	remove(pattern:string):void;
	dispose():void;
};

export const useSceneManager = (canvas:HTMLElement):ISceneManager => {

	const rendering = useSceneRendering(canvas);
	const scene = new THREE.Scene();
	const objects:THREE.Object3D[] = [];

	let camera:THREE.Camera|null = null;


	const hemiLight = new THREE.HemisphereLight(0xffeeb1, 0x080820, 4);
	scene.add(hemiLight);

	const light = new THREE.SpotLight(0xffa95c,4);
	light.position.set(-50,50,50);
	light.castShadow = true;
	light.shadow.bias = -0.0001;
	light.shadow.mapSize.width = 1024*4;
	light.shadow.mapSize.height = 1024*4;
	scene.add( light );

	const processors:ObProcessor[] = [
		useVideoProcessor(canvas),
		useMeshShadowProcessor(),
	];

	const getCamera = () => camera;

	const setCamera = (c:THREE.Camera|null) => camera = c;

	const update = () => {
		if(camera){
			rendering.render(scene, camera);
		}
	};

	const add = (ob:THREE.Object3D) => {
		ThreeUtils.getHierarchy(ob).forEach(o => {
			processors.forEach(p => {
				if(p.canProcess(o)){ p.beforeAdd(o); }
			});
		});
		scene.add(ob);
		objects.push(ob);
	};

	const remove = (pattern:string) => {
		find(pattern)
		.forEach(ob => {
			ThreeUtils.getHierarchy(ob).forEach(o => {
				processors.forEach(p => {
					if(p.canProcess(o)){ p.beforeRemove(o); }
				});
			});
			const i = objects.findIndex(o => o === ob);
			objects.splice(i, 1);
			scene.remove(ob);
		});
	}

	const find = (p:string) => {
		return objects.filter(o => TextUtils.matchWildcard(o.name, p));
	};

	const dispose = () => {
		killApp();
	};

	const r:ISceneManager = {
		enabled:true,
		type:"SceneManager",
		getCamera,
		setCamera,
		update,
		add,
		remove,
		dispose,
	};
	
	getApp().registerBehaviour(r);
	return r;
};



type ObProcessor = {
	canProcess(o:THREE.Object3D):boolean;
	beforeAdd(o:THREE.Object3D):void;
	beforeRemove(o:THREE.Object3D):void;
};


const useVideoProcessor = (el:HTMLElement):ObProcessor => {
	const canProcess = (o:THREE.Object3D) => {
		const name:string|undefined = o.userData["name"];
		return o.type === "Mesh" && name !== undefined && TextUtils.matchWildcard(name, "[video]*");
	};

	const beforeAdd = (o: THREE.Mesh) => {
		o.userData["videoElement"] = loadVideoObject(o, el);
	}
	const beforeRemove = (o: THREE.Mesh) => {
		const el:HTMLElement|undefined = o.userData["videoElement"];
		if(el){ el.remove(); }
	}

	return {
		canProcess,
		beforeAdd,
		beforeRemove,
	};
};


const useMeshShadowProcessor = ():ObProcessor => {

	const canProcess = (o:THREE.Object3D) => {
		return o.type === "Mesh";
	};

	const beforeAdd = (o: THREE.Mesh) => {
		o.castShadow = true;
		o.receiveShadow = true;
		const m = o.material as any;
		if(m.map) m.map.anisotropy = 16; 
	};

	const beforeRemove = (o: THREE.Mesh) => {}

	return {
		canProcess,
		beforeAdd,
		beforeRemove,
	};
};


type VideoParams = {
	url:string
};

const loadVideoObject = (ob:THREE.Mesh, el:HTMLElement) => {
	const params = ob.userData as VideoParams;
	const video = document.createElement("video");
	el.appendChild(video);
	video.setAttribute("muted", "true");
	video.setAttribute("autoplay", "true");

	video.style.cssText = `
	position:absolute;
	opacity:0;
	width:0px;
	height:0px;
	display:none;
	`

	video.src = params.url;
	// video.defaultMuted = true;
	video.muted = true;
	video.load();
	video.play();
	(video as any).playsInline = true;
	video.crossOrigin = "*";
	video.loop = true;
	const tex = new THREE.VideoTexture(video);
	// tex.encoding = THREE.RGBEEncoding;
	tex.encoding = THREE.sRGBEncoding;
	tex.flipY = false;
	tex.mapping = THREE.UVMapping;
	
	ob.material = new THREE.MeshBasicMaterial({ map: tex, side:THREE.FrontSide, fog:false, dithering:true });

	// console.log(ob.material);
	return video;
}