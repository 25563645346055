












































import { defineComponent, computed, ref, PropType, set, watch } from "@vue/composition-api";
import { pascalToSentenceCase } from "@/utils/text";
import { generateGUID } from "@/utils/guid";
import { SessionChannel } from "@/psychlab/types";
import { computeSources } from "../ts/compute-sources";
import { WizardContext } from "../ts/types/WizardContext";
import { default as PropertyDrawer } from "./property-drawer.vue";
import { useTranslate } from "@lang";

type Dictionary<T> = { [k:string]:T }; 


const getSourceArgs = (source:any) => {
	const params:Dictionary<any>|null = source ? source.parameters : null;
	if(!params){ return; }
	const args:any = { };
	Object.keys(params).forEach(k => {
		const p:any = params[k];

		args[k] = p.default !== undefined ? JSON.parse(JSON.stringify(p.default)) : null;
	});
	return args;
};

export default defineComponent({
	emits: [],
	props: {
		state:{
			type:Boolean,
			default:false
		},
		computeType:{
			type:String,
			required:true
		},
		computeArgs:{
			type: Object as PropType<Dictionary<any>>,
			required:true
		},
		dataChannels:{
			type: Array as PropType<SessionChannel[]>,
			default:() => []
		}
	},
	components: {
		PropertyDrawer
	},
	setup(props, context) {

		const drawers = ref<any>({});


		const {
			translate
		} = useTranslate();

		// console.log(props.computeArgs);

		const computeBlueprint = computed(() => {
			return computeSources.find(cs => cs.name === props.computeType);
		});

		const computeParameters = computed<Dictionary<any>>(() => {
			if(!computeBlueprint.value){ return {}; }
			return computeBlueprint.value.parameters;
		});

		const fakeContext = computed<WizardContext>(() => {
			const ctx:any = {
				dataType:props.computeType,
				dataArgs:props.computeArgs,
				channels:props.dataChannels
			};
			return ctx;
		});

		const parameters = computed(() => {
			const params:Dictionary<any>|null = computeBlueprint.value ? computeBlueprint.value.parameters : null;
			if(!params){ return []; }
			return Object.keys(params)
			.map(k => {
				const p = params[k];
				let drawer:any = getPropertyDrawer(p);

				return {
					label:translate(`widgets.label.${k}`),
					name:k,
					type:p.type,
					key:generateGUID()
				}
			});
		});


		const getTypeLabel = (t:string) => {
			return translate(`widgets.label.${t.charAt(0).toLowerCase() + t.slice(1)}`);
		};


		const refreshDrawers = () => {
			const ob:any = {};
			Object.keys(computeParameters.value)
			.forEach(k => {
				ob[k] = getPropertyDrawer(computeParameters.value[k]);
			});
			drawers.value = ob;
		};

		const getPropertyDrawer = (p:any) => {
			if(p.drawer){
				if(typeof(p.drawer) === "object"){
					return p.drawer;
				}
				if(typeof(p.drawer) === "function"){
					return p.drawer(fakeContext.value);
				}
			}
			return null;
		};

		const emitState = () => {
			let s = true;
			
			const bp = computeBlueprint.value;
			if(!bp){ return; }

			const ks = Object.keys(props.computeArgs);
			for(var i = 0; i < ks.length; i++){
	
				const d = drawers.value[ks[i]];

				const v = props.computeArgs[ks[i]]
				if(v === undefined || v === null){
					s = false;
					break;
				}

				if(d && d.options){
					const vi = (d.options as any[]).findIndex(o => o.value === v);
					if(vi < 0){
						s = false;
						break;
					}
				}
			}
			context.emit("state", s);
		};
		
		watch(() => props.computeArgs, v => {
			refreshDrawers();
			emitState();
			// console.log(props.computeArgs);
		}, { deep: true });

		refreshDrawers();

		emitState();

		return {
			translate,
			parameters,
			computeSources,
			drawers,
			generateGUID,
			pascalToSentenceCase,
			getTypeLabel,
		};
	},
});
