





import { defineComponent } from "@vue/composition-api";
import { ExploreStudySessions } from "@/components.studies/explore-study-sessions";

export default defineComponent({
	props: {
		studyId: {
			type: String,
			required: true,
		},
	},
	components: {
		ExploreStudySessions,
	},
});
