import { generateGUID } from "@utils/guid";
import { defineComponent } from "@vue/composition-api";
import { AbsBox } from "@ui";

export const GridBackground = defineComponent({
	props:{
		cellSize:{
			type:Number,
			default:25,
		},
	},
	setup(){
		return { key:generateGUID(), }
	},
	render(){

		const cs = this.cellSize;
		return (
			<AbsBox class="bg-dark" style="opacity:0.75">
				<StretchedCanvas>
					<defs>
						<pattern id={ this.key } x="0" y="0" width={cs} height={cs} patternUnits="userSpaceOnUse">
							<rect x="0" y="0" width={cs} height={cs} fill="transparent"
							stroke="white"
							stroke-width="1"
							style="opacity:0.05"
							/>
						</pattern>
					</defs>
					<rect fill={ `url(#${this.key})` } width="100%" height="100%"/>
				</StretchedCanvas>
			</AbsBox>
		)
	}
});

const StretchedCanvas = defineComponent({
	render(){
		const s = `position:absolute;width:100%;height:100%;top:0;left:0;`;
		return (
			<svg ref="abstretch" style={s}>
				{ this.$slots.default }
			</svg>
		)
	}
})