









import { defineComponent, computed, PropType } from "@vue/composition-api";

export default defineComponent({
	props:{
		text:{
			type:String,
			required:true
		},
		icon:{
			type:String,
			default:"mdi.alert-outline"
		},
		variant:{
			type:String,
			default:"danger"
		},
		size:{
			type:Number as PropType<1|2|3|4|5|6>,
			default:5,
		},
		outline:{
			type:Boolean,
			default:false,
		},
		round:{
			type:Boolean,
			default:false,
		},
		sm:{
			type:Boolean,
			default:false,
		}
	},
	setup(props){

		const txtVariant = computed(() => {
			if(props.outline){
				return props.variant;
			}
			return ({
				"light":"dark",
			} as any)[props.variant] || "light";
		});

		const headingClass = computed(() => {
			return `font-weight-light m-0 text-${txtVariant.value}`;
		});

		const tag = computed(() => {
			return `h${props.size}`
		});

		const boxClass= computed(() => {
			
			const round = `${props.round ? 'rounded-lg' : ''}`;
			const sm = `${props.sm ? 'sm' : ''}`;

			if(props.outline){
				return `${sm} ${round} border border-${props.variant}`;
			}
			return `${sm} ${round} bg-${props.variant}`;
		});

		return {
			txtVariant,
			headingClass,
			tag,
			boxClass,
		}
	}
})
