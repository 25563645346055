import { format } from "date-fns";
import { parseInterval } from "./parseInterval";
import { parseLabelQuery } from "./parseLabelQuery";

type Dictionary<T> = { [k:string]:T };

type QueryTransformer = {
	from(v:RouteValueType):any;
	to(v:any):string;
};

type LabelsMod = "all"|"in";

type LabelsConfig = {
	mod:LabelsMod|null;
	include:string[];
	exclude:string[];
};

type RouteValueType = string|(string|null)[];

export const routeConverters:Dictionary<QueryTransformer|undefined> = {
	"range":{
		from:(v:string) => Number(v),
		to:(v:number) => v.toString(),
	},
	"date":{
		from(v:string){
			return format(new Date(Number(v)), "yyyy-MM-dd");
		},
		to(v:string){
			return new Date(v).getTime().toString();
		},
	},
	"labels":{
		from(v:string){
			return parseLabelQuery(v);
		},
		to(v:LabelsConfig){
			try {
				let s = "";
				s += v.mod ? v.mod : "all";
				s += ";" + v.include.join(",");
				if(v.exclude.length){
					s+= "-" + v.exclude.join(",");
				}
				return s;
			}catch{}
			return "";
		},
	},
	"toggle":{
		from(v:string){
			return Number(v) === 1 ? true : false;
		},
		to(v:boolean){
			return v ? "1" : "0";
		},
	},
	"daterange":{
		from(v:string){
			return v;
		},
		to(v:string){
			return v;
		}
	},
	"interval":{
		from(v:string){
			return parseInterval(v, 0, 100);
		},
		to(v:number[]){
			return v.join(",");
		}
	}
};