





import { defineComponent, computed, PropType } from "@vue/composition-api";
import { HTML } from "@psychlab/types/form";
import { RenderHTML } from "@form-ui";
import { getBaseProps } from "./getBaseProps";

export default defineComponent({
	props:{
		...getBaseProps<HTML>(),
		config:{
			type:Object as PropType<HTML>,
			required:true
		}
	},
	components:{
		RenderHTML,
	},
	setup(props){

		const args = computed(() => ({
			title:!props.config.hideName ? props.config.name : '',
			body:props.config.body,
			theme:props.theme,
			content:props.config.settings.content,
		}));

		return {
			args,
		}
	}
});

