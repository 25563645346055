import { defineComponent } from "@vue/composition-api";
import { Icon } from "./icons";

const info = {
	title:"ENVALYS",
	logo:"/envralys.svg",
	homepage:"https://envalys.is/"
};

export const CompanyLink = defineComponent({
	render(){
		return (
			<a 
			target="_"	
			style="all:unset;cursor:pointer;text-decoration:none;"
			href={info.homepage}
			>
				{this.$slots.default}
			</a>
		);
	}
});

export const CompanyName = defineComponent({
	render(){
		return <span>{info.title}</span>
	}
});


export const CompanyLogo = defineComponent({
	render(){
		return <img src={info.logo}/>;
	}
});

export const CompanyFooter = defineComponent({
	props:{
		fixed:{
			type:Boolean,
			default:false,
		}
	},
	render(){

		let style = "";

		if(this.fixed){
			style="position:absolute;bottom:0";
		}

		return (
			<FooterBox style={style} class="d-flex">
				<div class="flex-filld d-flex" style="position:relative;">
					<CompanyLogo class="my-auto" style="max-height:2rem"/>

					<div class="mx-1"/>
					<FooterSlogan class="mr-autox my-auto"/>
				</div>
				<div class="mx-auto"/>
				<FooterLink class="my-auto"/>
			</FooterBox>
		);
	}
});


export const CompanySlogan = defineComponent({
	render(){
		return (
			<span class="font-weight-light">
				<span>Powered by </span><span class="font-weight-bold">{info.title}</span>
			</span>
		)
	}
})

const FooterSlogan = defineComponent({
	render(){
		return (
			<h5>
				<CompanySlogan class="my-auto text-light"/>
			</h5>
		);
	}
});

const FooterLink = defineComponent({
	render(){
		return (
			<a 
			target="_"	
			class="rounded-0 btn btn-outline-light btn-sm shadow-none"
			href={info.homepage}
			>
				<Icon name="open-in-new"/> About
			</a>
		);
	}
});

const FooterBox = defineComponent({
	props:{
		background:{
			type:String,
			default:"#526e99"
		}
	},
	render(){

		const style = `
		background: ${this.background};
		position:relative;
		`;

		return (
			<div class="shadow-lg d-flex p-2 py-2 w-100 border-top"
			style={style}
			>
				{this.$slots.default}
			</div>
		)
	}
})