import { defineComponent, PropType, computed } from "@vue/composition-api";
import { AbsBox } from "../@ui";
import { getConfig } from "@config";

const trimPath = (p:string) => {
	const r = p.split("").reverse();
	const i = r.findIndex(x => x !== "/");
	return r.slice(i).reverse().join("");
};

const viewerPath = trimPath(getConfig().panoramaURL);

export const VideoPanorama = defineComponent({
	emits:[ "loaded" ],
	props:{
		config:{
			type:Object as PropType<{
				sources:string[];
			}>,
			required:true,
		},
	},
	setup(props, ctx){

		const url = computed(() => {
			const pl = encodeURIComponent(Buffer.from(JSON.stringify({
				src:props.config.sources,
			})).toString("base64"));
			return `${viewerPath}?q=${pl}`
		});

		const handleLoaded = () => {
			ctx.emit("loaded");
		};

		return {
			url,
			handleLoaded,
		};
	},
	render(){
		return (
			<AbsBox>
				<iframe
				src={ this.url }
				class="w-100 h-100 border-0"
				// allow="<f1>;<f2>;..."
				eager
				v-on:load={ this.handleLoaded }
				/>
			</AbsBox>
		);
	}
});


/*
useful iframe flags
- allowFullscreen
- eager
- accelerometer
- gyroscope
- autoplay
- fullscreen
*/