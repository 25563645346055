





import { defineComponent, computed } from "@vue/composition-api";
import { useFavourites } from "@/hooks/useFavourites";
import { useTags } from "@/hooks/useTags";
import { useAssets } from "@/hooks/useAssets";
import { useStudies } from "@/hooks/useStudies";
import { matchWildcard } from "@/utils/regex";
import { getAssetTypes } from "@/psychlab/meta"
import { useTranslate } from "@lang";
import { default as NavItemList } from "./nav-item-list.vue";

const iconMap:Record<string,string> = {
	"tag":"mdi.tag",
	"study":"mdi.rocket mdi-rotate-45",
	"asset":"mdi.circle-outline",
};

const colorMap:Record<string,string> = {
	"study":"white",
};

getAssetTypes().forEach(at => {
	iconMap[`asset.${at.name}`] = at.icon;
	colorMap[`asset.${at.name}`] = at.color;
});

export default defineComponent({
	components:{
		NavItemList
	},
	setup(_, context){

		const {
			favourites,
			removeFavourite
		} = useFavourites();

		const { getCachedAsset } = useAssets();
		const { getCachedTag } = useTags();
		const { getCachedStudy } = useStudies();
		const { translate } = useTranslate();

		const navOptions = computed(() => {
			const r = favourites.value.map((favourite, i) => {

				let name = favourite.item;
				let icon = "mdi.alert";
				let iconColor = "white";

				if(favourite.type === "tag"){
					const t = getCachedTag(favourite.item);
					if(t){
						iconColor = t.color;
						icon = "mdi.tag";
						name = t.name;
					}
				}
				else if(favourite.type === "asset"){
					const a = getCachedAsset(favourite.item);
					if(a){
						iconColor = colorMap[`asset.${a.type}`];
						icon = iconMap[`asset.${a.type}`];
						name = a.name;
					}
				}
				else if(favourite.type === "study"){
					const t = getCachedStudy(favourite.item);
					if(t){
						iconColor = colorMap["study"];
						icon = iconMap["study"];
						name = t.name;
					}
				}

				return {
					type:favourite.type,
					name,
					icon: icon || "mdi.circle-outline",
					iconColor,
					fn(){
					},
					contextFn(){
						return [
							{
								icon:"mdi.star-outline",
								name:translate("action.removeFromFavourites"),
								fn(){
									removeFavourite(favourite.item);
								}
							}
						];
					},
					activeFn(){
						if(favourite.type === "asset"){
							return (context.root.$route.name === "view-asset" || context.root.$route.name === "asset.edit") && 
							context.root.$route.params["assetId"] === favourite.item;
						}
						if(favourite.type === "tag"){
							return context.root.$route.name === "view-tag" && 
							context.root.$route.params["tagId"] === favourite.item;
						}
						if(favourite.type === "study"){
							return matchWildcard(context.root.$route.name || "", "Run.Group*") && 
							context.root.$route.params["groupId"] === favourite.item;
						}
						return false;
					},
					routeFn(){
						if(favourite.type === "asset"){
							return {
								name:'view-asset', 
								params:{
									assetId:favourite.item
								}
							}
						}
						if(favourite.type === "tag"){
							return {
								name:'view-tag', 
								params:{
									tagId:favourite.item
								}
							}
						}
						if(favourite.type === "study"){
							return {
								name:'Run.Group', 
								params:{
									groupId:favourite.item
								}
							}
						}
						return null;


					}
				}

			});
			r.sort((a, b) => b.type.localeCompare(a.type));
			return r;
		});

		
		

		return {
			navOptions
		};
	}
});

