













// vendor
import { defineComponent, ref, toRefs, watch } from "@vue/composition-api";
// local
import { CustomCheckboxState } from "./CustomCheckboxState";

export default defineComponent({
	emits: [
		"click",
	],
	props: {
		value: {
			type: Boolean,
		},
		intermediate: {
			type: Boolean,
			default: () => false,
		},
		size: {
			type: String,
			default: () => "md",
		},
	},
	setup(props, ctx) {
		const { value: valueProp, intermediate: intermediateProp } = toRefs(
			props,
		);

		const getState = (value: boolean, intermediate: boolean) =>
			intermediate
				? CustomCheckboxState.INTERMEDIATE
				: value
				? CustomCheckboxState.TRUE
				: CustomCheckboxState.FALSE;

		const state = ref<CustomCheckboxState>(
			getState(props.value || false, props.intermediate),
		);

		watch(
			[valueProp, intermediateProp],
			([nextValue, nextIntermediate]) => {
				state.value = getState(nextValue as any, nextIntermediate as any);
			},
		);

		const handleClick = () => {
			const nextState =
				state.value === CustomCheckboxState.TRUE
					? CustomCheckboxState.FALSE
					: CustomCheckboxState.TRUE;
			ctx.emit("click", nextState);
		};

		return {
			handleClick,
			CustomCheckboxState,
			state,
		};
	},
});
