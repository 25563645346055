






































































































































































// vendor
import { defineComponent, computed, ref, PropType, onMounted } from "@vue/composition-api";

// local
import { default as BrowseGalleryModal } from "./browse-gallery-modal.vue";


const isValidAbsoluteUrl = (s:string) => {
	var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
	'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
	'((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
	'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
	'(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
	'(\\#[-a-z\\d_]*)?$','i'); // fragment locator
	return !!pattern.test(s);
};

const isValidRelativeUrl = (s:string) => {
	var pattern = new RegExp('((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
	'((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
	'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
	'(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
	'(\\#[-a-z\\d_]*)?$','i'); // fragment locator
	return !!pattern.test(s);
};

const isValidUrl = (s:string) => {
	return isValidAbsoluteUrl(s) || isValidRelativeUrl(s);
};

export default defineComponent({

	emits:[],
	props:{
		urls:{
			type:Array as PropType<string[]>,
			default:() => []
		},
		labels:{
			type:Array as PropType<any[]>,
			default:() => []
		},
	},
	components:{
		BrowseGalleryModal
	},
	setup(props){
		

		const imageElements = ref<HTMLElement[]>([]);

		const imgWrapper = ref<HTMLElement>();

		const galleryModal = ref<any>();

		const currentIndex = ref(0);

		const displayItems = computed(() => {
			let items = props.urls.map((url, i) => ({
				url,
				label: i < props.labels.length ? props.labels[i].toString() : ""
			}));
			items = items.filter(it => isValidUrl(it.url));
			return items;
		});

		const currentItem = computed(() => {
			if(currentIndex.value < 0 || currentIndex.value >= displayItems.value.length){
				return null;
			}
			return displayItems.value[currentIndex.value];
		});

		const setIndex = (i:number, scroll:boolean = true) => {
			currentIndex.value = i;
			if(scroll){
				scrollToElement(imageElements.value[i]);
			}
		};

		const openLargeViewer = () => {
			if(!galleryModal.value){ return; }
			galleryModal.value.open({
				index:currentIndex.value,
				urls:props.urls,
				labels:props.labels
			});
		};

		const scrollToElement = (el:HTMLElement) => {
			const container = el.parentElement;

			if(!container || !el){ return; }
			var pos = el.offsetLeft;
			container.scrollLeft = pos;
		};

		onMounted(() => {
			const el = imgWrapper.value;
			if(!el){ return; }
			el.addEventListener("wheel", e => {
				el.scrollLeft  += Math.sign(e.deltaY) * 50 * 0.5;
				e.preventDefault();
				return false;
			}, { passive:false });
		});


		return {
			currentIndex,
			displayItems,
			currentItem,
			setIndex,
			openLargeViewer,
			galleryModal,
			imageElements,
			imgWrapper,
		};
	}
});

