







// vendor
import { computed, defineComponent, PropType } from "@vue/composition-api";
// project
import { ViewSession } from "@/components.studies/view-session";
import { SessionLabel } from "@psychlab/types";

export default defineComponent({
	props:{
		labels: {
			type: Array as PropType<SessionLabel[]>,
			default:() => {},
		},
	},
	components: {
		ViewSession,
	},
	setup(_, ctx) {

		const sessionId = computed(() => {
			return ctx.root.$route.params["sessionId"];
		});

		return {
			sessionId,
		};
	},
});
