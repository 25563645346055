






















// vendor
import { defineComponent, ref } from "@vue/composition-api";
// project
import { capitalize } from "@/utils/text";

export default defineComponent({

	setup(props, context){

		const contextMenu = ref<ContextMenu>();

		const open = (e:any, nodeId:string, config:Config) => {
			if(!contextMenu.value){ return; }
			contextMenu.value.open(event, { nodeId, config });
		};
		
		const pickOption = (name:string, nid:string) => context.emit(name, nid);

		return {
			contextMenu,
			open,
			contextOptions,
			pickOption,
			capitalize
		};
	}
});

type Config = {
	canOpen:boolean
};

type ContextData = {
	nodeId:string,
	config:Config
};

type ContextMenu = {
	open(e:any, data:ContextData):void
};

type ShowFn = (c:Config) => boolean;

type ContextOption = {
	name:string,
	showFn:ShowFn,
	theme:string,
	icon:string
};

const contextOptions:ContextOption[] = [
	// {
	// 	name:"open",
	// 	showFn:c => c.canOpen,
	// 	theme:"dark",
	// 	icon:"external-link"
	// },
	{
		name:"delete",
		showFn:c => true,
		theme:"danger",
		icon:"mdi.trash-can"
	},
	
];


