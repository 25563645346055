


























































































































































































































import { defineComponent, computed, ref, reactive, set } from "@vue/composition-api";
import { AbsBox, Icon, SearchFilter } from "@ui";
import { useDebug } from "@lang";
import { generateGUID } from "@utils/guid";
import { default as FoldButton } from "./fold-btn.vue";
import { capitalize, camelToSentenceCase } from "@utils/text";
import { default as CopyButton } from "./copy-btn.vue";
import { default as DownloadButton } from "./download-btn.vue";
import { default as Toggle } from "./toggle.vue";

const lskey = "debug.translations.foldout";
const persistdelay = 2000;

const loadFoldout = ():{ [k:string]:boolean } => {
	const v = localStorage.getItem(lskey);
	if(!v){ return {}; }
	const keys:string[] = v.split(";");
	const d:any = {};
	keys.forEach(k => d[k] = true);
	return d;
};

const persistFoldout = (v:{ [k:string]:boolean }) => {
	let r:string[] = [];


	Object.keys(v)
	.forEach(k => {
		if(v[k]){
			r.push(k);
		}
	});

	localStorage.setItem(lskey, r.join(";"));
};


export default defineComponent({

	emits:[],
	props:{
	},
	components:{
		AbsBox,
		FoldButton,
		CopyButton,
		DownloadButton,
		Toggle,
		Icon,
		SearchFilter,
	},
	setup(){
		
		
		const lastTouch = ref(0);

		const foldouts = reactive<{ [k:string]:boolean }>(loadFoldout());
		

		const filterEmpties = ref(false);

		const search = ref("");

		const {
			items,
			groups,
			langKeys,
			hasEmpties,
		} = useDebug({
			filterEmpties,
			search,
		});


		const touch = () => {
			lastTouch.value = Date.now();
			
			setTimeout(() => {
				if((Date.now() - lastTouch.value) < persistdelay){ return; }
				persistFoldout(foldouts);
			}, persistdelay)
		};


		const toggleFoldout = (k:string) => {
			touch();
			set(foldouts, k, !Boolean(foldouts[k]));
		};

		const toggleAll = (v:boolean) => {
			Object.keys(foldouts)
			.forEach(k => {
				foldouts[k] = v;
				// set(foldouts, k, v);
			});
		};
		
		const getCSV = () => {

			let s = "key";
			langKeys.forEach(lk => s += `;${lk}`);

			s += "\n";

			items.value.forEach(it => {
				let l = `${it.key}`;
				langKeys.forEach(lk => l += `;${it.translations[lk]}`);;
				s += `${l}\n`;
			})
			

			return s;
		};

		const getJSON = () => {

			const d:any = {};

			// let s = "key";
			// langKeys.forEach(lk => s += `;${lk}`);

			// s += "\n";

			items.value.forEach(it => {

				d[it.key] = it.translations;


				// let l = `${it.key}`;
				// langKeys.forEach(lk => l += `;${it.translations[lk]}`);;
				// s += `${l}\n`;
			})

			return JSON.stringify(d, null, "\t");
		};


		return {
			groups,
			foldouts,
			filterEmpties,
			search,
			generateGUID,
			toggleFoldout,
			capitalize,
			hasEmpties,
			getCSV,
			getJSON,
			camelToSentenceCase,
			toggleAll,
		};
	}
});

