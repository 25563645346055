









import { defineComponent, computed, ref } from "@vue/composition-api";
import { AbsBox } from "@ui";

export default defineComponent({
	emits:[ "click" ],
	components:{
		AbsBox,
	},
	setup(_, ctx){
		
		const onclick = (e:Event) => {
			ctx.emit("click", e)
		}
		return {
			onclick
		};
	}
});

