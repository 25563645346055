import { Graph, GraphNode } from "@/psychlab/types/graph";
import { Form } from "@/psychlab/types/form";
import { AssetPointer } from "@/psychlab/types/assets";
import { getLeftMostNode } from "./getLeftMostNode";
import { getNodeExits } from "./getNodeExits";
import { DisplayGraph, DisplayNode, NodeItem } from "./DisplayGraph";

type Dictionary<T> = { [k:string]:T };

export type BuildInfo = {
	nodes:Dictionary<DisplayNode>;
	edges:Dictionary<string[]>;
};

export const buildDisplayGraph = (data:Graph):DisplayGraph|null => {
	const sn = data.startNode || getLeftMostNode(data);
	if(!sn){ return null; }

	const buildInfo:BuildInfo = {
		nodes:{},
		edges:{}
	};

	build(null, sn, 0, buildInfo, data);

	computeGraphWidth(sn, buildInfo);

	addStartNode(buildInfo);

	return {
		nodes:Object.keys(buildInfo.nodes).map(nk => buildInfo.nodes[nk]),
		edges:buildInfo.edges
	};
};

const build = (owner:string|null, nid:string, depth:number, info:BuildInfo, data:Graph, ) => {
	info.nodes[nid] = {
		id:nid,
		type:data.nodes[nid].type,
		depth,
		items:getNodeItems(data.nodes[nid]),
		owner,
		x:0,
	};

	const exits = getNodeExits(nid, data);
	info.edges[nid] = exits;

	exits.forEach(ne => {
		const clevel = depth + 1;
		
		if(info.nodes[ne]){
			if(info.nodes[ne].depth < clevel){
				info.nodes[ne].depth = clevel;
				bumpChildren(ne, info);
			}
		}
		else {
			build(nid, ne, clevel, info, data);
		}
	})
};

const bumpChildren = (nid:string, info:BuildInfo) => {
	const node = info.nodes[nid];
	const edges = info.edges[nid];

	edges.forEach(e => {
		const child = info.nodes[e];
		if(child.depth < node.depth + 1){
			child.depth = node.depth + 1;
		}
	});
};

const addStartNode = (info:BuildInfo) => {

	const nks = Object.keys(info.nodes);
	
	if(nks.length === 0){ return; }

	nks.forEach(k => {
		info.nodes[k].depth++;
	});

	const start = {
		id:"start",
		type:"Start",
		depth:0,
		items:[],
		owner:null,
		x:info.nodes[nks[0]].x,
	};

	info.nodes = {
		start,
		...info.nodes
	};

	info.edges = {
		"start":[
			nks[0]
		],
		...info.edges
	}

	info.nodes[nks[0]].owner = "start";
};

const computeGraphWidth = (start:string, info:BuildInfo) => {
	setX(0, start, info);
	alignStraight(start, info);
};

const setX = (v:number, nid:string, info:BuildInfo) => {
	const nedges = info.edges[nid].filter(ne => info.nodes[ne].owner === nid);
	const widths = nedges.map(ne => 0 + computeWidth(ne, info));
	const node = info.nodes[nid];
	node.x = v;
	let w = v;
	nedges.forEach((ne, index) => {
		setX(w, ne, info);
		w += widths[index];
	});
};

const computeWidth = (nid:string, info:BuildInfo) => {
	const nedges = info.edges[nid].filter(ne => info.nodes[ne].owner === nid);
	if(nedges.length === 0){ return 1; }
	let w = 0;
	nedges.forEach(ne => w += computeWidth(ne, info));
	return w;
};

const alignStraight = (start:string, info:BuildInfo) => {
	let current = info.nodes[start];
	let edges = info.edges[start].filter(ne => info.nodes[ne].owner === start);
	while(edges.length === 1){
		current = info.nodes[edges[0]];
		edges = info.edges[current.id].filter(ne => info.nodes[ne].owner === current.id);
	}
	if(edges.length === 0){ return; }
	const mid = edges[Math.floor(edges.length * 0.5)];
	const midNode = info.nodes[mid];
	while(current.owner){
		current.x = midNode.x;
		current = info.nodes[current.owner];
	}
	current.x = midNode.x;
};

const getNodeItems = (n:GraphNode<any>):NodeItem[] => {
	return itemFactories[n.type] ? itemFactories[n.type](n) : [];
};

type FormNode = GraphNode<{
	asset?:AssetPointer<Form>;
}>;

type SceneNode = GraphNode<{
	points:{
		form?:AssetPointer<Form>
	}[]
}>;

type CognitiveTestNode = GraphNode<{
	asset?:AssetPointer<any>;
	testType:string;
	testParameters:any;
	useAsset:boolean;
}>;

type GalleryNode = GraphNode<any>;

const itemFactories:Dictionary<(n:GraphNode<any>) => NodeItem[]> = {
	// Form Node
	Form(n:FormNode):NodeItem[]{
		if(!n.parameters.asset || !n.parameters.asset.ref){
			return [];
		}
		const asset = `${n.parameters.asset.ref.asset}@${n.parameters.asset.ref.version}`;
		return [
			{
				asset,
				data:n.parameters.asset.data,
				type:"Form"
			},
		]
	},
	// Scene Node
	Scene(n:SceneNode):NodeItem[]{
		const items:NodeItem[] = [];
		n.parameters.points.forEach(point => {
			if(!point.form){
				items.push({
					type:"empty",
					asset:null,
					data:null
				});
				return;
			}
			
			if(!point.form.ref || !point.form.data){
				return;
			}
			const asset = `${point.form.ref.asset}@${point.form.ref.version}`;
			items.push({
				type:"Form",
				asset,
				data:point.form.data
			});
		});
		return items;
	},
	CognitiveTest(n:CognitiveTestNode):NodeItem[]{

		if(!n.parameters.useAsset && n.parameters.asset && n.parameters.asset.data && n.parameters.asset.ref){

			const asset = `${n.parameters.asset.ref.asset}@${n.parameters.asset.ref.version}`;

			return [
				{
					type:"CognitiveTest",
					data:{
						testType:n.parameters.testType,
						testParameters:n.parameters.testParameters
					},
					asset
				}
			]
		}

		if(!n.parameters.asset || !n.parameters.asset.data || !n.parameters.asset.ref){
			return [];
		}
		
		return [
			{
				type:"CognitiveTest",
				data:{
					testType:n.parameters.asset.data.testType,
					testParameters:n.parameters.asset.data.testParameters
				},
				asset:null
			}
		];

	},
	Gallery(n:GalleryNode):NodeItem[]{

		// return [];

		if(!n.parameters.asset || !n.parameters.asset.data || !n.parameters.asset.ref){
			return [];
		}

		const asset = `${n.parameters.asset.ref.asset}@${n.parameters.asset.ref.version}`;
		
		return [
			{
				type:"Gallery",
				data:n.parameters.asset.data,
				asset,
			}
		];

	}
};