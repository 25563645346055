





import { defineComponent, computed, ref, PropType } from "@vue/composition-api";
import { IFormTheme, } from "../config";
import { RenderMarkdown } from "@ui";

export default defineComponent({
	props:{
		body:{
			type:String,
			default:"",
		},
		theme:{
			type:Object as PropType<IFormTheme>,
			required:true,
		},
	},
	components:{
		RenderMarkdown,
	},
	setup(){
		
		

		return {

		};
	}
});

