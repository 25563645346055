import { defineComponent, PropType, computed, ref } from "@vue/composition-api";
import Draggable from "vuedraggable";
import { generateGUID  } from "@/utils/guid";
import { move as moveItem } from "@/utils/array";
import { ScenePoint } from "@scene-viewer";
import { useTranslate } from "@lang";

const dragSettings = {
	animation: 200,
	disabled: false,
	ghostClass: "ghost",
};

export const PointList = defineComponent({

	emits:[ "edit-point" ],
	props:{
		value:{
			type:Array as PropType<ScenePoint[]>,
			required:true,
		}
	},
	components: {
		Draggable
	},
	setup(props, context){

		const {
			translate,
		} = useTranslate();

		const activeId = ref<string>();
		const markupId = ref(generateGUID());

		const displayItems = computed({
			get:() => props.value,
			set:() => {  } // managed by drag
		});

		const addOption = () => {
			props.value.push(createPoint(translate("assets.label.newPoint")));
		};

		const deleteOption = (index:number) => {
			props.value.splice(index, 1);
		};

		const onDragged = (s:{ moved:any }) => {
			const { moved } = s;
			if(moved){
				moveItem(props.value, moved.oldIndex, moved.newIndex);
			}
		};

		const toggleActive = (id:string) => {
			activeId.value = id;
			context.emit("edit-point", id);
		};

		return {
			activeId,
			markupId,
			dragSettings,
			displayItems,
			translate,
			addOption,
			deleteOption,
			onDragged,
			toggleActive
		};
	}
});


const createPoint = (name:string):ScenePoint => {
	return {
		guid: generateGUID(),
		name,
		position: { x:0, y:0, z:0 },
		rotation: { x:0, y:0, z:0 },
		form:undefined
	}
}