








// vendor
import { defineComponent } from "@vue/composition-api";
import { RenderCheckboxes } from "@form-ui";
import { Checkboxes } from "@psychlab/types/form"
import { getItemProps } from "./getItemProps";

export default defineComponent({
	props:{
		...getItemProps<Checkboxes>()
	},
	components:{
		RenderCheckboxes
	}
});

