/** @format */

// project
import { http } from "@/http";

type PropData = {
	name?: string;
	renderTemplate?: string;
};

export const editWidget = async (
	studyId: string,
	widgetId: string,
	props: PropData,
) => {
	await http.patch(`studies/${studyId}/analytics/widgets/${widgetId}`, props);
};
