// vendor
import { Ref, ref, computed } from "@vue/composition-api";
// project
import * as AssetAPI from "@/services/api/assets";
import { Asset } from "@/psychlab/types/assets";
import { useTags } from "@/hooks/useTags";

let loaded = false;

let fetched = false;

let _assets:Ref<Asset[]>;

const init = async () => {
	try {
		_assets.value = await AssetAPI.getAssets();
	}
	catch(err:any){
		console.log(err.message);
	}
	fetched = true;
};

export const useAssets = () => {
	if(!loaded){
		_assets = ref<Asset[]>([]);
		init();
		loaded = true;
	}


	const {
		sortedTags,
		tagItem,
		untagItem,
	} = useTags();


	const assets = computed(() => [ ..._assets.value ]);
	
	const isLoaded = () => fetched;

	const renameAsset = async(id:string, name:string) => {
		await AssetAPI.setAssetName(id, name);
		const a = assets.value.find(aa => aa._id === id);
		if(a){
			a.name = name;
		}
	};

	const refreshAsset = async(id:string) => {
		const a = await AssetAPI.getAsset(id);
		let asset = getCachedAsset(id);
		if(!asset){
			asset = a;
			_assets.value.push(a);
		}
		asset.name = a.name;
		asset.lastModified = a.lastModified;
	};

	const deleteAsset = async(id:string) => {
		await AssetAPI.deleteAsset(id);
		const i = assets.value.findIndex(b => b._id === id);
		_assets.value.splice(i, 1);
	};

	const getCachedAsset = (id:string):Asset => {
		const i = _assets.value.findIndex(a => a._id === id);
		return _assets.value[i];
	};


	const duplicateAsset = async (id:string, name:string, tag:string|null = null) => {
		const asset = _assets.value.find(a => a._id === id);
		if(!asset){ return; }
		const latestVersion = await AssetAPI.getAssetVersion(asset._id, "latest", "+data");
		let copy = await AssetAPI.createAsset(asset.type, name);
		// copy = await AssetAPI.getAsset(copy._id);
		_assets.value.push(copy);
		await AssetAPI.saveAssetVersion(copy._id, { data: latestVersion.data });
		
		if(tag){
			tagItem(tag, copy._id, "asset");
		}
	};


	const createAsset = async(type:string, name:string, tag:string|null = null) => {
		const a = await AssetAPI.createAsset(type, name);
		_assets.value.push(a);
		if(tag){
			tagItem(tag, a._id, "asset");
		}
		return a;
	};


	return {
		assets,
		getCachedAsset,
		renameAsset,
		deleteAsset,
		duplicateAsset,
		createAsset,
		isLoaded,
		refreshAsset,
	};

};