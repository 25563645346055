















// vendor
import { defineComponent, computed, ref, PropType } from "@vue/composition-api";
import { IFormTheme, } from "../config";
import { AbsBox } from "@ui";
import { default as BGImage } from "./bg-image.vue";
import { isDropboxLink, parseDropboxLink } from "@utils/url";

type BackgroundStyle = {
	color?:string;
};

export default defineComponent({
	props:{
		theme:{
			type:Object as PropType<IFormTheme>,
			required:true,
		},
	},
	components:{
		AbsBox,
		BGImage,
	},
	setup(props){

		const image = computed(() => {
			return {
				opacity:props.theme["window.background.image.opacity"] || 0,
				url:imageUrl.value,
				repeat:props.theme["window.background.image.repeat"] || 0,
				size:props.theme["window.background.image.fit"] || "contain"
			};
		});

		const imageUrl = computed(() => {
			const u = props.theme["window.background.image.url"] || "";
			if(isDropboxLink(u)){
				return parseDropboxLink(u);
			}
			return u;
		});

		const style = computed(() => {
			
			return {
				"background-color":props.theme["window.background.color"] || "white"
			}
		});

		return {
			style,
			image,
		};
	}
});

