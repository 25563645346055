



































































































































import { defineComponent, computed, ref, reactive } from "@vue/composition-api";
import { Widget } from "@/psychlab/types/widget";
import { StudyWidget } from "@/components.studies/study-widget";
import { pascalToSentenceCase } from "@/utils/text";
import { SessionChannel } from "@/psychlab/types";
import { WizardResult, WizardConfig, ResultReceiver, WizardStep } from "../ts/types";
import { WizardContext } from "../ts/types/WizardContext";
import { default as EditDataParameters } from "./edit-data-parameters.vue";
import * as Pages from "./pages";
import { default as PropertyDrawer } from "./property-drawer.vue";
import { parseBinding } from "./../ts/utils/parseBinding";
import { ButtonBase } from "@ui";
import { useTranslate } from "@lang";

type Dictionary<T> = { [k:string]:T }; 


type EditConfig = {
	computeType:string;
	computeArgs:Dictionary<any>;
	computeResult:Dictionary<any>;
	template:string;
	templateArgs:Dictionary<any>;
	templateBindings:string[];
	metadata:Dictionary<string>;
	dataChannels:SessionChannel[]
};

// const tabs:any[] = [

// 	{
// 		name:"Data Parameters"
// 	},
// 	// {
// 	// 	name:"Template Bindings"
// 	// },
// 	{
// 		name:"Template Settings"
// 	}
// ];


// const typeDefaults:any = {
// 	"string[]":[]
// };



// const getContext = async(config:WizardConfig):Promise<WizardContext> => {
// 	return {
// 		dataArgs:{},
// 		dataBindings:[],
// 		channels:config.dataChannels,
// 		staticProps:[],
// 		staticArgs:{},
// 		overrideArgs:{}
// 	};
// };

export default defineComponent({

	components:{
		ButtonBase,
		EditDataParameters,
		StudyWidget,
		PropertyDrawer,
	},
	setup(){


		const tabIndex = ref(0);

		const wizardContext = ref<WizardContext|null>(null);
		
		const show = ref(false);
		const config = ref<EditConfig|null>(null);
		const receiver = ref<any>(null);

		const templateArgs = ref<Dictionary<any>>({});
		const computeArgs = ref<Dictionary<any>>({});

		const computeArgsState = ref(false);
		
		const foldouts = ref({
			dataArgs:true,
			templateArgs:true
		});

		const {
			translate,
		} = useTranslate();


		const computeType = computed(() => {
			if(!config.value){ return null; }
			return config.value.computeType;
		});

		// const computeArgs = computed(() => {
		// 	if(!config.value){ return null; }
		// 	return config.value.computeArgs;
		// });

		const dataChannels = computed(() => {
			if(!config.value){ return []; }
			return config.value.dataChannels;
		});
		

		const templateProps = computed(() => {
			
			if(!config.value){ return []; }
			
			const props:any[] = [];

			const md = config.value.metadata;

			Object.keys(config.value.templateArgs)
			.forEach(k => {


				const label = k.charAt(0).toLowerCase() + k.slice(1);

				const prop:any = {
					key:k,
					// label:pascalToSentenceCase(k)
					label:translate(`widgets.label.${label}`)

				};
				prop["type"] = md[`template/${k}/type`];
				prop["drawer"] = {
					type:md[`template/${k}/drawer`],
					options:md[`template/${k}/drawer/options`],
				};
				props.push(prop);
			})

			return props;
		});

		const previewWidget = computed(() => {
			if(!config.value){ return null; }
			
			const cr = config.value.computeResult;

			const data:Dictionary<any> = {
				...templateArgs.value
			};

			const bindings = config.value.templateBindings.map(parseBinding);

			bindings
			.forEach(b => {
				data[b.to] = cr[b.from];
			});

			return {
				template:config.value.template,
				data
			};
		})

		const open = async (c:EditConfig, onResult:any) => {
			// wizardContext.value = await getContext(c);
			// activeStepIndex.value = 0;
			config.value = c;
			receiver.value = onResult;

			templateArgs.value = JSON.parse(JSON.stringify(config.value.templateArgs || {}));

			computeArgs.value = JSON.parse(JSON.stringify(config.value.computeArgs || {}));

			show.value = true;
		};

		const onHide = () => {
			wizardContext.value = null;
			show.value = false;
			config.value = null;
			receiver.value = null;

			computeArgs.value = {};
			templateArgs.value = {};
		};

		const onSubmit = () => {

			if(!receiver.value){
				onHide();
				return;
			}


			receiver.value({
				templateArgs:templateArgs.value,
				computeArgs:computeArgs.value
			});
	
			onHide();
		};
		

		return {
			wizardContext,
			show,
			open,
			onHide,
			onSubmit,
			// tabs,
			tabIndex,
			templateProps,
			templateArgs,
			previewWidget,
			computeType,
			computeArgs,
			dataChannels,
			computeArgsState,
			foldouts,
			translate,
		};
	}
});

