










import { defineComponent } from "@vue/composition-api";
import { RenderLikert } from "@form-ui";
import { LinearScale } from "@psychlab/types/form";
import { getItemProps } from "./getItemProps";

export default defineComponent({
	props:{
		...getItemProps<LinearScale>()
	},
	components:{
		RenderLikert,
	}
});

