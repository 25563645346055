import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import * as Modules from "@/store-modules";

const store = new Vuex.Store({
	modules: {
		localization:Modules.Localization,
		authentication:Modules.Auth,
		cache:Modules.Cache
	},
	actions:{
		initialize(){
			console.info("Initializing store");
		}
	}
});

store.dispatch("initialize");

export { store }