import { IFormTheme } from "./IFormTheme";

// #bdc6d0

enum BootstrapColor {
	Primary = "#007bff",
	Secondary = "#6c757d",
	Success = "#28a745",
	Danger = "#dc3545",
	Warning = "#ffc107",
	Info = "#17a2b8",
	Light = "#f8f9fa",
	Dark = "#343a40",
	White = "#fff",
};

const anyPreset:IFormTheme = {
	"input.likert.hover.color":BootstrapColor.Secondary,
	"item.box.shadow":true,
	"item.title.size":4,
	"item.title.fontWeight":300,
	"item.text.fontWeight":300,
	"item.box.border.color":"#ffffff00",
	"item.box.border.width":1,
	"window.background.color":"#bdc6d0",
	"media.video.background.color":"#000",
	"window.background.image.opacity":1,
	// formBackgroundStyle:JSON.stringify({
	// 	color:"#bdc6d0",
	// })
};


type PresetName = "light"|"dark"|"light-minimal";


const presets:{ [k:string]:IFormTheme } = {

	"light-minimal":{
		...anyPreset,
		"item.title.size":4,
		"item.title.fontWeight":400,
		"item.title.color":"#343A40",
		"item.text.color":"#343A40",
		"item.text.fontWeight":400,
		"item.box.shadow":false,
		"window.background.color":"#ffffff",
		"item.box.divider.color":"#ffffff00",
		"item.box.border.color":"#ffffff00",
		"item.box.background.color":"#ffffff00",
		"input.likert.selected.color":BootstrapColor.Dark,
		"input.likert.border.color":BootstrapColor.Dark,
		"input.likert.hover.color":"#6C757D"
	},
	dark:{
		...anyPreset,
		"item.box.background.color":BootstrapColor.Dark,
		"item.box.divider.color":BootstrapColor.Secondary,
		"item.title.color":BootstrapColor.Light,
		"item.text.color":BootstrapColor.Light,
		"input.likert.border.color":BootstrapColor.Light,
		"input.likert.selected.color":BootstrapColor.Light,
	},
	light:{
		...anyPreset,
		"item.box.background.color":BootstrapColor.Light,
		"item.box.divider.color":BootstrapColor.Secondary,
		"item.title.color":BootstrapColor.Dark,
		"input.likert.selected.color":BootstrapColor.Dark,
		"input.likert.border.color":BootstrapColor.Dark,

	},
};



export const getThemeDefaults = ():IFormTheme =>{
	return {
		// ...presets.light,
		...presets["light-minimal"]
	};
};


export const getThemePreset = (name?:PresetName):IFormTheme => {

	if(!name){
		return {
			...presets.light,
		}
	}
	
	return {
		...presets[name],
	}

}

// $white:#fff;
