













import { defineComponent, computed } from "@vue/composition-api";
import { ViewSessionProgress } from "@components/sessions";
import { default as SessionBreadcrumbs } from "./session-breadcrumbs.vue";
import { useTranslate } from "@lang";
import { Divider } from "@ui";


export default defineComponent({
	components:{
		SessionBreadcrumbs,
		ViewSessionProgress,
		Divider,
	},
	setup(_, context){

		const { translate } = useTranslate();

		const sessionId = computed(() => context.root.$route.params["sessionId"]);
		const studyId = computed(() => context.root.$route.params["groupId"]);
		const backRoute = computed(() => {
			return {
				name: "Run.Group.Live",
				params:{
					groupId:studyId.value
				}
			};
		});
		return {
			sessionId,
			studyId,
			backRoute,
			translate,
		};
	}
	
});

