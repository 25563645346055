











// vendor
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
	props:{
		// translateKey:{
		// 	type:String,
		// 	required:true
		// }
		text:{
			type:String,
			required:true
		}
	},

});
