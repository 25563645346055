
















import { defineComponent, PropType, computed, ref } from "@vue/composition-api";
import { ResponseData } from "../ResponseData";
import { default as ErrorOverlay } from "./error-overlay.vue";
import { ErrorBox } from "@ui";
import { RenderDropdown } from "@form-ui";
import { Dropdown } from "@psychlab/types/form";
import { getBaseProps } from "./getBaseProps";
import { useRouteTranslate } from "@lang";

export default defineComponent({

	emits:[ "input" ],
	props:{
		value:{
			type:Number
		},
		...getBaseProps<Dropdown>(),
		config:{
			type:Object as PropType<Dropdown>,
			required:true
		}
	},
	components:{
		RenderDropdown,
		ErrorOverlay,
		ErrorBox
	},
	setup(props, context){

		const { translate } = useRouteTranslate(context);

		const pristine = ref(true);

		const val = computed({
			get:() => props.value,
			set:v => context.emit("input", v)
		});

		const state = computed(() => val.value !== undefined && val.value > -1);

		const isValid = () => {
			pristine.value = false;
			return state.value;
		};

		const getChannels = (prefix:string):ResponseData<number>[] => {
			const v = val.value;
			return v !== undefined ? [
				{
					key: prefix,
					data:v
				}
			] : [];
		};

		const displayOptions = computed(() => {
			return [
				{ value: -1, text: "--", disabled: !props.config.optional },
				...props.config.settings.options.map((o, i) => { return { value:i, text:o }; })
			]
		});

		const args = computed(() => ({
			title:!props.config.hideName ? props.config.name : '',
			body:props.config.body,
			theme:props.theme,
		}));

		const init = () => {
			if(val.value === undefined){ val.value = -1; }
		};

		init();

		return {
			pristine,
			state,
			val,
			displayOptions,
			isValid,
			getChannels,
			args,
			translate,
		};
	}
});
