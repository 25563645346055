// vendor
import { ref, reactive, watch } from "@vue/composition-api";
// project
import { getDelay } from "@/utils/time";
// local
import * as Utils from "../utils";


type Config = {
	fadeInDuration?:number;
	fadeOutDuration?:number;
	blurFactor?:number;
};

const processConfig = (c:Config) => {
	return {
		blurFactor:c.blurFactor || 0,
		fadeInDuration:c.fadeInDuration || 1,
		fadeOutDuration:c.fadeOutDuration || 1,
	};
};

export const useStaticRender = (c:Config) => {

	const loading = ref(false);
	
	let current:DisplayItem|null = null;
	let viewRoot:HTMLElement|null = null;

	const {
		blurFactor,
		fadeInDuration,
		fadeOutDuration,
	} = processConfig(c);

	const tween = reactive({ fade:0 });

	const setPlayRate = (t:number) => {
		if(!current){ return null; }
		if(current.setPlayRate){
			current.setPlayRate(t);
		}
	};

	const setFadeOpacity = (t:number) => {
		if(!current){ return; }
		// console.log(t);
		current.domElement.style.opacity = t.toString();
	};

	const setCanvasBlur = (t:number) => {
		if(!viewRoot){ return; }
		const grs = 1 - (0.4 + 0.6 * (1 - t));
		viewRoot.style.filter = `blur(${t * (blurFactor)}px) grayscale(${grs})`;
	};

	const doFade = async (duration:number, value:number) => {
		if(!current){ return; }
		tween.fade = 1 - value;
		await getDelay(10);
		await Utils.tweenTarget(tween, "fade", duration, value);
	};

	// const fadeCurrent = (t:number) => {
	// 	if(!current){ return; }
	// 	const grs = 1 - (0.4 + 0.6 * (1 - t));
	// 	current.domElement.style.filter = `blur(${t * (1)}px) grayscale(${grs})`;
	// };

	const loadSources = async (sources:string[]) => {
		loading.value = true;

		try {

			let cs:DisplayItem|null = current;

			let ns:any = null;

			const targetTime = cs && cs.getTime ? cs.getTime() : 0;

			if(cs){
				cs.domElement.style.zIndex = "5";
			}

			await Promise.all([
				doFade(fadeOutDuration, 1),
				new Promise<void>(async (resolve, reject) => {
					try {
						ns = await getItem(sources, targetTime);
						resolve();
					}
					catch(err){ reject(err); }

				})
			]);

			await Utils.delay(10);

			await clearLoadedItem();
			await Utils.delay(10);

			current = ns;

			await Utils.delay(10);
			// await doFade(1, 0);

		}
		catch(err:any){
			console.log(err.message);
		}
		loading.value = false;

	};

	const clearLoadedItem = async () => {
		if(!current){ return; }

		await doFade(fadeInDuration, 0);
		current.domElement.remove();
		current = null;
	};

	const init = (el:HTMLElement) => {
		viewRoot = el;
	};

	const dispose = () => {
		if(current){
			current.domElement.remove();
			current = null;
		}
	};

	const getItem = async(sources:string[], time:number):Promise<DisplayItem|null> => {
		if(!viewRoot){ return null; }
		let item:DisplayItem|null = null;
		
		const type = Utils.getUrlType(sources);
		
		switch(type){
			case "img":
				item = await getImageItem(sources, viewRoot);
				break;
			case "vid":
				item = await getVideoItem(sources, time, viewRoot);
				break;
			
		}

		if(!item){ return null; }

		viewRoot.appendChild(item.domElement);

		return item;
	};

	watch(() => tween.fade, (v, ov) => {
		if(v < ov){
			setFadeOpacity(v);
		}
		setPlayRate(v);
		setCanvasBlur(v);
	});

	return {
		loading,
		loadSources,
		init,
		dispose,
	}
};

type DisplayItem = {
	domElement:HTMLElement;
	setPlayRate?(t:number):void;
	setTime?(t:number):void;
	getTime?():number;
};

const getImageItem = async(sources:string[], el:HTMLElement):Promise<DisplayItem> => {
	const root = document.createElement("div");
	root.style.cssText = `
	position:absolute;
	width:100%;
	height:100%;
	top:0;
	left:0;
	display:flex;
	background:black;
	`;
	const image = await loadImage(sources);
	image.style.cssText = `
	max-width:100%;
	max-height:100%;
	height:auto;
	width:auto;
	margin:auto;
	pointer-events:none;
	user-select: none;
	display: block;
	`;
	root.appendChild(image);
	return {
		domElement:root
	};
};

const loadImage = async(sources:string[]):Promise<HTMLImageElement> => {
	let i = 0;
	const img = new Image();
	img.crossOrigin = "anonymous";

	return new Promise((resolve, reject) => {
		img.onerror = () => {
			i++;
			if(i >= sources.length){ return reject(); }
			img.src = sources[i];
		};
		img.onload = async () => {
			resolve(img);
		};
		img.src = sources[0];
	});
};


const getVideoItem = async(sources:string[], time:number, el:HTMLElement):Promise<DisplayItem|null> => {
	
	const {
		root,
		video,
	} = await loadVideo(sources, el, time);

	const getTime = () => {
		return video.currentTime;
	}

	return {
		domElement:root,
		getTime,
	};
};



const loadVideo = (sources:string[], el:HTMLElement, start:number):Promise<{ root:HTMLElement, video:HTMLVideoElement }> => {

	const root = document.createElement("div");

	root.style.cssText = `
		position:absolute;
		pointer-events:none;
		width:100%;
		height:100%;
		overflow:hidden;
		background:black;
	`;

	const video = document.createElement("video");

	// video.style.cssText = `
	// min-width: 100%; 
	// min-height: 100%;
	// width: auto;
	// height: auto;
	// position: absolute;
	// top: 50%;
	// left: 50%;
	// transform: translate(-50%,-50%);
	// `;
	video.style.cssText = `
	position: absolute;
	top: 0;
	left: 0;
	width: 100%; 
	height: 100%;
	object-fit: contain;
	`;

	video.setAttribute("muted", "true");
	video.setAttribute("autoplay", "true");

	const vidAttr:any = {
		muted:"true",
		autoplay:"true",
	};

	Object.keys(vidAttr).forEach(k => video.setAttribute(k, vidAttr[k]));

	sources.forEach(src => {
		const stag = document.createElement("source");
		stag.src = src;
		video.append(stag);
	});

	el.appendChild(root);
	root.appendChild(video);

	const vidConfig:any = {
		muted:true,
		playsInline:true,
		crossOrigin:"anonymous",
		loop:true,
		currentTime:start,
		autoplay:true,
	};

	Object.keys(vidConfig).forEach(k => (video as any)[k] = vidConfig[k]);

	video.load();
	video.play();

	return new Promise((resolve, reject) => {
		video.onloadeddata = () => {
			video.currentTime = start;
			video.onseeked = () => {
				resolve({
					root,
					video
				});
			};
		};
		video.onerror = reject;
	});
};