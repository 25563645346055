



































import { defineComponent, computed, inject, ComputedRef } from "@vue/composition-api";
import { PreviewAssetGraph } from "@/components.assets/preview-asset-graph";
import { StudyContext } from "@/components.studies/manage-study";
import { Date as DisplayDate, AbsBox } from "@ui";
import { useTranslate } from "@lang";
import { GridBackground } from "@components/misc";

const getHistoryRoute = (aid:string, vid:string) => {
	return {
		name:"asset-history",
		params:{ assetId:aid, },
		query:{ version:vid, },
	};
};

export default defineComponent({
	components:{
		PreviewAssetGraph,
		DisplayDate,
		GridBackground,
		AbsBox,
	},
	setup(){

		const { translate } = useTranslate();

		const studyContext = inject<ComputedRef<StudyContext>>("studyContext");

		if(!studyContext){ throw Error("Blueprint Preview: Study context missing!"); }

		const {
			blueprint,
			blueprintVersion,
		} = studyContext.value;


		const blueprintId = computed(() => {
			return blueprint.value ? blueprint.value._id : "";
		});
		
		const versionId = computed(() => {
			return blueprintVersion.value ? blueprintVersion.value._id : "";
		});

		const blueprintLabel = computed(() => {
			return blueprint.value ? blueprint.value.name : "";
		});

		const versionLabel = computed(() => {
			if(!blueprintVersion.value){ return ""; }
			const name = blueprintVersion.value.name;
			const vindex = blueprintVersion.value.vIndex;
			if(name){
				return `${name} (v${vindex})`
			}
			return `${translate("assets.label.version")} ${vindex + 1}`;
		});


		return {
			blueprint,
			blueprintVersion,
			blueprintLabel,
			versionLabel,
			blueprintId,
			versionId,
			historyRoute:computed(() => getHistoryRoute(blueprintId.value, versionId.value))
		};
	}
});

