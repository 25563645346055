




















































































// vendor
import { defineComponent, ref } from "@vue/composition-api";
// project
import { createSessionLabel } from "@/services/api/studies";
// local
import { useStudyRouteParams } from "../hooks/useStudyRouteParams";
import { useTranslate } from "@lang";
import { Icon, Button } from "@ui";

const DEFAULT_COLOR = "#007bff";

export default defineComponent({
	emits: [
		"onNewLabel",
	],
	components:{
		Icon,
		Button,
	},
	setup(props, ctx) {
		const { studyId } = useStudyRouteParams(ctx);

		if(!studyId){ throw Error("Create Label: Study ID missing!"); }

		const newLabelActive = ref(false);
		const name = ref("");
		const color = ref(DEFAULT_COLOR);

		const isLoading = ref(false);
		const hasError = ref(false);

		const { translate } = useTranslate();

		const toggleNewLabelActive = () => {
			newLabelActive.value = !newLabelActive.value;
			name.value = "";
		};

		const handleSave = async () => {
			isLoading.value = true;
			try {
				const res = await createSessionLabel(studyId.value, {
					name: name.value,
					color: color.value,
				});
				ctx.emit("onNewLabel", res);
				newLabelActive.value = false;
			} catch {
				hasError.value = true;
			}
			isLoading.value = false;
		};

		return {
			newLabelActive,
			toggleNewLabelActive,
			name,
			color,
			isLoading,
			hasError,
			handleSave,
			translate,
		};
	},
});
