







import { defineComponent, computed, ref, PropType, onMounted, watch, onUnmounted } from "@vue/composition-api";
import { Gallery } from "@/psychlab/types/gallery";
import { usePanoramaRender } from "../ts/hooks/usePanoramaRender";
import { processUrls } from "../ts/utils/processUrls";

export default defineComponent({

	props:{
		gallery:{
			type:Object as PropType<Gallery>,
			required:true,
		},
		sources:{
			type:Array as PropType<string[]>,
			required:true
		},
		accelerometer:{
			type:Boolean,
			default:false,
		},
	},
	setup(props, context){

		const viewerRoot = ref<HTMLElement|null>();
		const mounted = ref(false);

		const {
			fadeColor,
			fadeInDuration,
			fadeOutDuration,
		} = props.gallery;

		const panorama = usePanoramaRender({
			fadeColor:fadeColor,
			fadeInDuration:fadeInDuration||0.5,
			fadeOutDuration:fadeOutDuration||0,
			blurFactor:0,
			useAccelerometer:props.accelerometer,
		});

		const {
			loading:panoramaLoading,
		} = panorama;


		const sources = computed(() => processUrls(props.sources));


		const loadCurrent = async() => {
			context.emit("load-start");
			try {
				await panorama.loadVideo(sources.value);
			}
			catch(err){
				console.log(err);
			}
			context.emit("load-end");
		};

		onMounted(() => {
			mounted.value = true;
			if(!viewerRoot.value){ return; }
			panorama.init(viewerRoot.value);
			loadCurrent();
		});

		onUnmounted(() => {
			panorama.dispose();
		});

		watch(() => props.sources, v => {
			if(!mounted){ return; }
			loadCurrent();
		});

		return {
			viewerRoot,
			panoramaLoading,
		};
	}
});

