import * as THREE from "three";

export class Line3D {

	get object():THREE.Line{ return this._object; }
	get color(){
		return this._material.color.getHexString();
	}
	set color(v:string){
		this._material.color = new THREE.Color(v);
	}

	constructor(points:THREE.Vector3[] = [], config:LineConfig = {}){
		const geometry = new THREE.BufferGeometry().setFromPoints(points);
		this._material = new THREE.LineBasicMaterial({ color: config.color || "black", linewidth:5 });
		this._object = new THREE.Line(geometry, this._material);
	}

	public setPoints(points:THREE.Vector3[]){
		const geometry = new THREE.BufferGeometry().setFromPoints(points);
		this._object.geometry = geometry;
	}

	private _material:THREE.LineBasicMaterial;
	private _object:THREE.Line;
}

type LineConfig = {
	color?:string
}

// const g = new THREE.SphereBufferGeometry(0.05, 32, 16);
// const m = new THREE.MeshStandardMaterial({ color:"black", opacity:1, transparent:true });
// const ob = new THREE.Mesh(g, m);