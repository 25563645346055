// vendor
import { defineComponent, computed, PropType } from "@vue/composition-api";
// project
import { pascalToSentenceCase } from "@/utils/text";
// local
import { default as InfoTooltip } from "../vue/info-tooltip.vue";
import * as PropertyDrawers from "../vue/property-drawers";
import { FormProperty } from "./FormProperty";

export const ScriptableForm = defineComponent({
	props:{
		props:{
			type:Array as PropType<FormProperty[]>,
			default:() => [],
		},
		labelCols:{
			type:Number,
			default:2,
		},
		tooltipFontSize:{
			type:Number,
			default:0.8,
		},
		tooltipOpacity:{
			type:Number,
			default:1,
		},
		labelSize:{
			type:String,
			default:"md",
		},
		drawerSize:{
			type:String,
			default:"md",
		},
	},
	components:{
		InfoTooltip,
	},
	setup(){
		const drawers = computed(() => {
			const v:any = {};
			Object.keys(PropertyDrawers).forEach(pd => {
				const k = pascalToSentenceCase(pd).split(" ").map(k => k.toLowerCase()).join("-");
				v[k] = (PropertyDrawers as any)[pd]
			});
			return v;
		});

		return {
			drawers,
		};
	}
});