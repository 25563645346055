import { computed, inject } from "@vue/composition-api";
import { useUserPrefs } from "@/hooks/useUserPrefs";
import { translateKey } from "../ts/translateKey";
import { isLanguageSupported } from "../ts/isLanguageSupported";

interface ILanguageResolver {
	getLanguage():string;
}

type Options = {
	
}

export const useTranslate = (cfg?:Options) => {
	
	const {
		lang:prefLang,
	} = useUserPrefs();

	let resolver:ILanguageResolver|null = null;

	try {
		resolver = inject<ILanguageResolver|null>("lang-resolver", null);
	}
	catch{}
	
	const lang = computed(() => {
		const rl = resolver ? resolver.getLanguage() : "";
		return isLanguageSupported(rl) ? rl : prefLang.value;
	});

	const translate = (key:string) => {
		return translateKey(key, lang.value);
	};

	return {
		currentLang:lang,
		translate
	};
};