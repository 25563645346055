import { IEntityHandler } from "./IEntityHandler";
import { IEntity } from "./IEntity";



export class EntityHandler<T extends IEntity> implements IEntityHandler<T> {
	
	get id(){
		return this._entity._id;
	}

	constructor(e:T){
		this._entity = e;
	}

	toJSON():T {
		return JSON.parse(JSON.stringify(this.entity));
	}

	protected get entity(){
		return this._entity;
	}
	
	private _entity:T;
}