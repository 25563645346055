































































	import { defineComponent, computed, ref, watch } from "@vue/composition-api";
	import { TitleHeader } from "../TitleHeader";
	import { useTranslate } from "@lang";
	import { default as ItemFoldout } from "./item-foldout.vue";
	import { default as ListFavourites } from "./list-favourites.vue";
	import { default as ListAssets } from "./list-assets.vue";
	import { default as ListCategories } from "./list-categories.vue";
	import { AbsBox } from "@ui";

	const getSavedFoldout = (name:string, defValue:boolean):boolean => {
		const v = localStorage.getItem(`nav.foldout.${name}`);
		return v ? JSON.parse(v) : defValue;
	};

	const saveFoldout = (name:string, v:boolean):void => {
		localStorage.setItem(`nav.foldout.${name}`, v.toString());
	};


	const colors = {
		// background:"#69354b",
		// background:"linear-gradient(to top, #09203f 0%, #537895 100%)",
// 		background:`linear-gradient(to bottom, #323232 0%, #3F3F3F 40%, #1C1C1C 150%), linear-gradient(to top, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.25) 200%);
//  background-blend-mode: multiply;`,
// 		background:`#41295a; /* fallback for old browsers */
//   background: -webkit-linear-gradient(to right, #41295a, #2f0743); /* Chrome 10-25, Safari 5.1-6 */
//   background: linear-gradient(to right, #41295a, #2f0743); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */`,
		background:`#141E30;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #243B55, #141E30);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #243B55, #141E30); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`,
		border:"#ffffff15"
	}

	const Divider = defineComponent({
		template:`<hr class="m-0" style="background:${colors.border}"/>`
	});

	const options = [
		{
			title: "Home",
			route:"home",
			icon:"mdi.home",
		},
		{
			title: "Assets",
			route:"assets",
			icon:"mdi.layers",
		},
		{
			title: "Studies",
			route:"studies",
			icon:"mdi.rocket",
		}
	];

	export default defineComponent({
		emits:[
			"hide"
		],
		components:{
			TitleHeader,
			ItemFoldout,
			ListFavourites,
			ListAssets,
			ListCategories,
			AbsBox,
			Divider,
		},
		setup(_, context){

			const {
				translate
			} = useTranslate();


			const foldouts = ref<Record<string,boolean>>({
				"favourites":getSavedFoldout("favourites", false),
				"assets":getSavedFoldout("assets", false),
			});

			const navOptions = computed(() => {
				return options;
			});

			const getRoute = (index:number) => {
				const option = navOptions.value[index];
				const q:any = {};
				[ "t" ].forEach(qk => {
					if(context.root.$route.query[qk]){
						q[qk] = context.root.$route.query[qk];
					}
				});
				return {
					name:option.route,
					query:q
				};
			};

			const getNavClass = (option:any) => {
				var isActive = isRoute(option);
				var ic = option.class ? option.class : '';
				var c = (isActive ? 'text-white' : 'text-muted pointer') + ' ' + ic;
				return c;
			};
		
			const isRoute = (option:any) => {
				if(!context.root.$route.name){ return false; }
				let res = context.root.$route.name.match(`${option.route}*`);
				return Boolean(res);
			};
		
			const isExactRoute = (option:any) => {
				return option.route === context.root.$route.name;
			};

			const hideMenu = () => {
				context.emit("hide");
			};
			
			Object.keys(foldouts.value)
			.forEach(k => watch(() => foldouts.value[k], (v) => saveFoldout(k, v)));

			return {
				colors,
				navOptions,
				foldouts,
				translate,
				getNavClass,
				isRoute,
				isExactRoute,
				hideMenu,
				getRoute,
			};

		}
	});
