/** @format */

// vendor
import Vue from "vue";
import { compile } from "vue-template-compiler";
import { defineComponent, h } from "@vue/composition-api";
// local
import { default as WidgetChart } from "../vue/widget-chart.vue";
import { default as WidgetImageGallery } from "../vue/widget-img-gallery/widget-image-gallery.vue";

const errorTemplate = `
<div style='color:white;background:red;width:100%;height:100%;display:flex'>
	<span style='margin:auto;font-family:consolas; font-size:0.8rem'>ERROR</span>
</div>`;

const compileTemplate = (t: string, d: any = {}) => {
	const r = compile(t);
	if (r.errors.length > 0) {
		return h(Vue.compile(errorTemplate));
	}

	try {
		return h({
			template: t,
			components: {
				"chart": WidgetChart,
				"gallery": WidgetImageGallery
			},
			data() {
				return {
					...d,
				};
			},
			errorCaptured(err) {
				console.log(err);
			},
		});
	} catch (err) {
		console.log(err);
	}
};

export const RenderWidget = defineComponent({
	props: {
		data: {
			type: Object,
		},
		template: {
			type: String,
			required: true,
		},
	},
	errorCaptured(err) {
		console.log(err);
	},
	setup(props) {
		return () => {
			return compileTemplate(props.template, props.data);
		};
	},
});
