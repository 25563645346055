import { PhraseDictionary } from "./types";

export const mergeDictionaries = (rootName:string, map:Record<string,PhraseDictionary>) => {
	const r:PhraseDictionary = {};
	Object.keys(map)
	.forEach(name => {
		const d = map[name];
		const prefix = `${rootName.length ? `${rootName}.` : ""}${name}.`;
		Object.keys(d).forEach(k => r[`${prefix}${k}`] = d[k]);
	});
	return r;
};