




























import { defineComponent, PropType, computed } from "@vue/composition-api";
import { HTML } from "../../ts/types/DisplayForm";
import { Icon, AbsBox } from "@ui";

export default defineComponent({
	props:{
		showBack:{
			type:Boolean,
			default:true
		},
		config:{
			type:Object as PropType<HTML>,
			required:true
		}
	},
	components:{
		Icon,
		AbsBox,
	},
	setup(props, context){

		const content = computed(() => props.config.content);

		const onPrevious = () => context.emit("previous");
		const onNext = () => context.emit("next");

		return {
			onPrevious,
			onNext,
			content
		};
	}
});

