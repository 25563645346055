import { Dictionary } from "./Dictionary";
import * as SnapSVG from "snapsvg";

import * as SnapUtils from "../utils/Snap";

// const Snap = require(`imports-loader?this=>window,fix=>module.exports=0!snapsvg/dist/snap.svg.js`);

export const setDOMAttributes = (el:HTMLElement, ob:Dictionary<string>) => {
	Object.keys(ob).forEach(k => el.setAttribute(k, ob[k]));
};

export const setDOMStyle = (el:HTMLElement, ob:Dictionary<string>) => {
	Object.keys(ob).forEach(k => (el.style as any)[k] = ob[k]);
};

export const createDOMSVG = (id:string, parent:HTMLElement) => {
	const canvas:any = document.createElementNS("http://www.w3.org/2000/svg", "svg");
	canvas.id = id;
	parent.appendChild(canvas);
	setDOMStyle(canvas, {
		"width": "100%",
		"height": "100%",
		"position": "absolute",
		"top": "0",
		"left": "0",
		"pointer-events": "none"
	});
	return canvas;
};

export const createSnapSVG = (parent:SnapSVG.Element) => {
	let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
	let canvas = SnapUtils.findSnap(svg);
	setDOMStyle(canvas.node, {
		"width": "100%",
		"height": "100%",
		"position": "absolute",
		"top": "0",
		"left": "0"
	}); 
	parent.node.appendChild(canvas.node);
	return canvas;
};