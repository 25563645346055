import OpenSurveyPage from "@pages/open-survey";

export const OpenSurvey = {
	name: "Run.StartStudy",
	path: "/p/s/:proxyId",
	component: OpenSurveyPage,
	meta: {
		title: "Study",
		public: true,
		navHidden:true
	}
};