

































































import { defineComponent, computed, PropType } from "@vue/composition-api";
import { Icon } from "../icons";
import { useTranslate } from "@lang";

type Option = {
	id:string;
	color:string;
	name:string;
};

export default defineComponent({
	emits:[ "add", "remove", "clear", ],
	props:{
		value:{
			type:Array as PropType<string[]>,
			default:() => [],
		},
		options:{
			type:Array as PropType<Option[]>,
			default:() => [],
		},
		size:{
			type:String,
			default:"md",
		}
	},
	components:{
		Icon
	},
	setup(props, context){


		const { translate } = useTranslate();


		const label = computed(() => {
			if(!props.value.length){ return "-"; }
			const s1 = props.options.find(o => o.id === props.value[0]);
			if(!s1){ return ""; }
			let s = s1.name;
			if(props.value.length > 1){
				s += ` +${props.value.length-1}`;
			}
			return s;
		});
		
		const toggleOption = (id:string) => {
			const i = props.value.findIndex(v => v === id);
			if(i > -1){
				props.value.splice(i, 1);
			} else {
				props.value.push(id);
			}
		};

		const isActive = (id:string) => {
			return props.value.includes(id);
		};

		const clear = () => {
			props.value.length = 0;
			context.emit("clear");
		};

		return {
			toggleOption,
			isActive,
			label,
			clear,
			translate,
		};
	}
});

