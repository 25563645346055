





// vendor
import { defineComponent, computed, ref, PropType } from "@vue/composition-api";
import { IFormTheme, } from "../config";

export default defineComponent({
	props:{
		title:{
			type:String,
			default:"",
		},
		theme:{
			type:Object as PropType<IFormTheme>,
			required:false,
		},
	},
	setup(props){
		
		const cls = computed(() => {
			return "m-0";
		});

		const style = computed(() => {
			if(!props.theme){ return {}; }
			return {
				// borderColor:"red",
				borderColor:props.theme["item.box.divider.color"],
				backgroundColor:props.theme["item.box.divider.color"],
			};
		});

		return {
			cls,
			style,
		};
	}
});

