import { Icon } from "@ui";
import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import { Route } from "vue-router";
import { useFavourites } from "@/hooks/useFavourites";

const getFavInfo = (route:Route):{
	id:string;
	type:"asset"|"study"|"tag"
}|null => {
	const aid = route.params["assetId"];
	const sid = route.params["studyId"] || route.params["groupId"];
	const tid = route.params["tagId"];
	if(aid){ return { type:"asset", id:aid, } }
	if(sid){ return { type:"study", id:sid }; }
	if(tid){ return { type:"tag", id:tid }; }
	return null;
}



export const PageFavouriteButton = defineComponent({

	props:{
		variant:{ type:String, default:"light" }
	},
	setup(_, ctx){

		const hooks = useFavourites();

		const route = computed(() => ctx.root.$route);

		const isFavourite = computed(() => {
			if(!info.value){ return false; }
			return hooks.isFavourite(info.value.id);
		})

		const info = computed(() => getFavInfo(route.value));

		const toggle = async () => {
			if(!info.value){ return; }
			if(isFavourite.value){
				await hooks.removeFavourite(info.value.id);
			}
			else {
				await hooks.addFavourite(info.value.id, info.value.type)
			}
		};

		// watch(() => route.value.name, v => {
			
		// 	const info = getFavInfo(route.value);

		// });


		return {
			isFavourite,
			info,
			toggle
		};
	},
	render(){

		const icon = this.isFavourite ? "star" : "star-outline";
		const variant = `text-${this.variant}`;

		return (
			<button
			v-on:click={ this.toggle }
			class={ `btn btn-md ${variant} shadow-none hoverable light p-1` }
			style="font-size:1.5em"
			disabled={ !Boolean(this.info) }
			>
				<Icon name={ icon }/>
			</button>
		);
	}
});