import { defineComponent, PropType, set } from "@vue/composition-api";
import { Vector3 } from "@scene-viewer";

export const VectorInput = defineComponent({

	emits:[ "input" ],
	props:{
		value:{
			type:Object as PropType<Vector3>
		}
	},
	setup(props, context){

		const onChange = (v:any, p:"x"|"y"|"z") => {
			const n = parseNumber(v)
			if(n === undefined){ return; }
			if(!props.value){
				const nv = { x:0, y:0, z:0 };
				nv[p] = n;
				context.emit("input", nv);
			}
			else {
				set(props.value, p, n);
				context.emit("input", props.value);
			}
		};

		return {
			onChange
		};
	}
});

const parseNumber = (v:string):number|undefined => {
	const n = parseInt(v);
	if(isNaN(n)){ return undefined; }
	return n;
};