import { Module } from "vuex";
import * as JWT from "@/utils/jwt";

type State = {
	token:string|null
}

type AuthTokenBody = {
	user:{
		email:string
	}
}

export const Auth:Module<State, {}> = {
	namespaced:false,
	state: {
		token:null
	},
	getters: {
		token(state:any){
			return state.token;
		},
		authenticated(state:any){
			return Boolean(state.token);
		},
		email(state:any){
			if(!state.token){ return ""; }
			const payload = JWT.parse<AuthTokenBody>(state.token)
			return payload.user.email;
		}
	},
	mutations:{
		setToken(state:any, token:any){
			state.token = token;
			if(token){
				localStorage.setItem("auth", token);
			}else{
				localStorage.removeItem("auth");
			}
		}
	},
	actions:{
		initialize({ commit }:any){
			const saved = localStorage.getItem("auth");
			if(saved) {
				commit("setToken", saved)
			}
		},
		saveAuth({ commit }, auth:{ token:string }){
			const { token } = auth;
			commit("setToken", token);
		},
		clearAuth({ commit }){
			commit("setToken", null);
		},
	}

}