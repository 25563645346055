import { WizardContext } from "../types/WizardContext";
import * as Utils from "./utils";

export const ComputeGroupAverage = {
	name: "ComputeGroupAverage",
	description:{
		en:"Computes the average value for every numeric field that belongs to the same group. For ex., rows in a linear scale.",
		is:"Reiknar meðaltal fyrir hvern talnadálk sem tilheyrir sama hóp. T.d. hver röð í línulegum skala.",
	},
	isUsable(ctx:WizardContext){
		return Utils.hasNumericChannels(ctx);
	},
	parameters: {
		group: {
			type:"string",
			title:{
				en:"Field Group",
				is:"Dálkahópur",
			},
			drawer(context:WizardContext){
				return {
					type:"dropdown",
					options:Utils.getGroupOptions(context.channels, Utils.canAverageChannel)
				}
			},
			default:null
		}
	},
	output:{
		name:{
			type:"string",
		},
		values:{
			type:"number[]",
		},
		labels:{
			type:"string[]",
		}
	}
};