



























import { defineComponent, computed } from "@vue/composition-api";
import { RenderMarkdown } from "@ui";
import { ItemBox, getBaseProps } from "../shared";
import { AbsBox } from "@ui";
import { useTranslate } from "@lang";
import { Hoverlay } from "../shared";
import { EmptyContentBox } from "../editor";
import { EditImage } from "../edit-image";
import { isDropboxLink, parseDropboxLink } from "@utils/url";

export default defineComponent({
	emit:[ "set-url", "set-width" ],
	props:{
		url:{
			type:String,
			default:""
		},
		width:{
			type:Number,
			default:100,
		},
		...getBaseProps()
	},
	components:{
		RenderMarkdown,
		ItemBox,
		AbsBox,
		Hoverlay,
		EmptyContentBox,
		EditImage,
	},
	setup(props, ctx){

		const { translate } = useTranslate();

		const content = computed(() => {

			let url = props.url;

			const bg = props.theme["media.image.background.color"] || "#00000000";

			if(!url){ return ""; }

			if(isDropboxLink(url)){
				url = parseDropboxLink(url);
			}

			let style = `width:${props.width}%;margin:auto;`
			
			let boxstyle = "display:flex;";
			boxstyle += `background:${bg}`;
			
			const img = `<img src="${url}" style="${style}"`;
			return `<div style="${boxstyle}">${img}</div>`
		});

		return {
			content,
			translate,
		};
	},
});
