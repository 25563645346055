// vendor
import { defineComponent, PropType, ref, reactive, computed, onMounted, onUnmounted, watch, set } from "@vue/composition-api";
import * as THREE from "three";

import {
	SceneViewer,
	TransformMode,
	Viewer,
	Line3D,
	TransformHandle,
	useFlightCamera,
	IFlightCamera,
} from "@scene-viewer";


import { SceneData } from "../ts/types/SceneData";
import * as Utils from "../ts/utils";
import Toolbar from "../vue/toolbar.vue";
import Inspector from "../vue/inspector.vue";
import ImportModal from "../vue/import-modal.vue";
import TransformToolbar from "../vue/transform-toolbar.vue";

export const EditScene = defineComponent({

	props:{
		data:{
			type:Object as PropType<SceneData>,
			required:true
		}
	},
	components: {
		SceneViewer,
		ImportModal,
		Toolbar,
		Inspector,
		TransformToolbar
	},
	setup(props){

		let moveHandle:TransformHandle;
		let editCam:IFlightCamera;
		let pathLine:Line3D;

		const viewer = ref<Viewer>();
		const editing = reactive({
			point:undefined as string|undefined
		});
		const transformMode = ref<TransformMode>("translate");
		const loadedScenes = ref<string[]>([]);

		const points = computed(() => props.data.points);
		const controlPoint = computed(() => points.value.find(p => p.guid === editing.point));

		watch(transformMode, v => {
			if(moveHandle){
				moveHandle.mode = v;
			}
		});

		watch(props.data, v => {
			refreshScene();
		}, { deep:true });

		watch(controlPoint, v => {
			if(!v){ return; }
			if(!moveHandle){ return; }
			moveHandle.setPosition(v.position);
			updateLine();
		}, { deep:true });

		watch(controlPoint, v => {
			if(!v){ return; }
			setControlRotation();
		}, { deep:true });

		watch(() => points.value.length, (v) => updateLine());

		watch(controlPoint, v => {
			if(!moveHandle){ return; }
			moveHandle.enabled = Boolean(v);
		});

		const updateLine = () => {
			const r:THREE.Vector3[] = [];
			points.value.forEach(sp => {
				const p = sp.position;
				r.push(new THREE.Vector3(p.x, p.y, p.z));
			});
			if(pathLine){
				pathLine.setPoints(r);
			}
		};

		const alignView = () => {
			if(!editCam){ return; }
			const p = (props.data.points || []).find(p => p.guid === editing.point);
			if(!p){ return; }
			const mat = editCam.getLookMatrix();
			p.rotation = Utils.getRotationFromMatrix(mat);
			p.position = (new THREE.Vector3()).setFromMatrixPosition(mat);
		};

		const setPaths = (v:string) => {
			set(props.data, "paths", v);
			refreshScene();
		};

		const editPoint = (pId:string) => {
			// set(editing, "point", pId);
			editing.point = pId;
		};

		const refreshScene = () => {
			const paths = props.data.paths;
			loadedScenes.value = paths ? paths.split("|") : [];
		};

		const onMoveEnd = () => {
			if(!controlPoint.value || !moveHandle){ return; }
			controlPoint.value.position = moveHandle.getPosition();
			controlPoint.value.rotation = moveHandle.getRotation();
		};

		const onMoveChange = (event:THREE.Event) => {
			// this.updateLine()
		};

		const onMoveStateChange = (event:THREE.Event) => {
			if(!editCam){ return; }
			editCam.enabled = !event.value;
		};

		const setControlRotation = () => {
			if(!controlPoint.value || !moveHandle){ return; }
			moveHandle.setRotation(controlPoint.value.rotation);
		};

		onMounted(() => {
			if(props.data.paths){ refreshScene(); }
			const v = viewer.value;
			if(!v){ return; }
			const el = v.getDOMElement();
			if(!el){ return; }
			// editCam = new FlightCamera(el);
			editCam = useFlightCamera(el);
			v.setCamera(editCam.camera);
			// move handle
			moveHandle = new TransformHandle(editCam.camera, el);
			v.add(moveHandle.object);
			moveHandle.mode = "translate";
			moveHandle.onDraggingChanged = onMoveStateChange;
			moveHandle.onObjectChange = onMoveChange;
			moveHandle.onMouseUp = onMoveEnd;
			pathLine = new Line3D([], { color:"black" });
			v.add(pathLine.object);
			updateLine();
		});

		onUnmounted(() => {
			if(moveHandle){
				moveHandle.dispose();
			}
		});

		return {
			viewer,
			transformMode,
			loadedScenes,
			points,
			controlPoint,
			editing,
			alignView,
			editPoint,
			setPaths
		};

	}

});