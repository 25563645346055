// vendor
import * as THREE from "three";
import { computed, ref, watch, reactive } from "@vue/composition-api";
// local
import * as Utils from "../utils";
import { useWindowResize } from "./useWindowResize";
import { IPanoramaScene, ViewerParams } from "../types"
import { getDelay } from "@/utils/time";

const camConfig = {
	fov:75,
	aspect:2,
	near:0.001,
	far:1000,
};

const processConfig = (c:ViewerParams) => {
	return {
		blurFactor:c.blurFactor || 0,
		fadeColor:c.fadeColor || "white",
		fadeInDuration:c.fadeInDuration || 1,
		fadeOutDuration:c.fadeOutDuration || 1,
		syncTime:c.syncTime || true,
		dampingFactor:c.dampingFactor || 0,
	};
};

export const usePanoramaRender = (c:ViewerParams) => {

	const { useAccelerometer } = c;

	const {
		blurFactor,
		fadeColor,
		fadeInDuration,
		fadeOutDuration,
		syncTime,
		dampingFactor,
	} = processConfig(c);

	// 
	const renderer:THREE.WebGLRenderer = new THREE.WebGLRenderer();
	const camera = new THREE.PerspectiveCamera(camConfig.fov, camConfig.aspect, camConfig.near, camConfig.far);
	const panningCamera =  new THREE.PerspectiveCamera(camConfig.fov, camConfig.aspect, camConfig.near, camConfig.far);
	const scene = new THREE.Scene();
	const fadeSphere = (() => {
		const s = Utils.getFadeSphere(fadeColor);
		scene.add(s);
		return s;
	})();

	const isLoading = ref(false);
	const tween = reactive({ fade:0, });
	const loading = computed(() => isLoading.value);
	const canvas = computed(() => renderer.domElement);
	const resizeHook = useWindowResize();

	// misc
	let panningControls:Utils.OrbitControls|null = null;
	let motionControls:Utils.DeviceOrientationControls|null = null;
	let parent:HTMLElement|null = null;
	let currentScene:IPanoramaScene|null = null;

	const getParentSize = () => {
		return parent ? { w:parent.offsetWidth, h:parent.offsetHeight } : { w:0, h:0 };
	}

	const setFadeOpacity = (t:number) => {
		fadeSphere.material.opacity = t;
	};

	const setPlayRate = (t:number) => {
		if(!currentScene){ return null; }
		currentScene.setPlayRate(t);
	};

	const setCanvasBlur = (t:number) => {
		const grs = 1 - (0.4 + 0.6 * (1 - t));
		renderer.domElement.style.filter = `blur(${t * (blurFactor)}px) grayscale(${grs})`;
	};

	const doFade = async (duration:number, value:number) => {
		tween.fade = 1 - value;
		await getDelay(10);
		await Utils.tweenTarget(tween, "fade", duration, value);
	};

	const loadVideo = async(sources:string[]) => {
		if(!parent){ return; }
		isLoading.value = true;

		let cs:IPanoramaScene|null = currentScene;
		let ns:any = null;

		const targetTime = syncTime && cs ? cs.getTime() : 0;

		try {
			await Promise.all([
				doFade(fadeOutDuration, 1),
				new Promise<void>(async (resolve, reject) => {
					try {
						ns = await getScene(sources, targetTime);
						resolve();
					}
					catch(err){ reject(err); }

				})
			]);
			await Utils.delay(10);
			await setFadeTexture(cs ? await cs.captureFrame(0.5) : null);
			clearLoadedScene();
			currentScene = ns;
			if(currentScene){
				scene.add(currentScene.sceneObject);
			}
			await Utils.delay(10);
			await doFade(fadeInDuration, 0);

		}
		catch(err:any){
			console.log(err.message);
		}
		fadeSphere.material.map = null;
		isLoading.value = false;
	};

	const getScene = async (sources:string[], time:number) => {
		return parent ? await Utils.createScene(sources, parent, time) : null;
	};

	const setFadeTexture = async (t:THREE.Texture|null) => {
		fadeSphere.material.needsUpdate = true;
		fadeSphere.material.map = t;
		fadeSphere.material.opacity = 1;
	};

	const render = () => {
		camera.setRotationFromQuaternion(panningCamera.quaternion);
		if(motionControls){
			motionControls.update();
		}
		if(panningControls && dampingFactor >= 0){
			panningControls.update();
		}
		renderer.render(scene, camera);
		requestAnimationFrame(render);
	};

	const refreshCanvasSize = () => {
		if(!parent){ return; }
		const { w, h } = getParentSize();
		renderer.domElement.style.width = `${w}px`;
		renderer.domElement.style.height = `${h}px`;
		renderer.setSize(w, h);
		camera.aspect = w / h;
		camera.fov = Utils.computeCameraFOV(camera.aspect);
		camera.updateProjectionMatrix();
	};

	const init = (canvas:HTMLElement) => {
		panningCamera.position.z = 0.1;
		parent = canvas;
		if(panningControls || motionControls){ return; }
		canvas.appendChild(renderer.domElement);

		if(useAccelerometer){
			motionControls = new Utils.DeviceOrientationControls(camera);
		}
		else {
			panningControls = new Utils.OrbitControls(panningCamera, canvas);
			panningControls.target.set(0, 0, 0);
			panningControls.enableZoom = false;
			
			if(dampingFactor > 0){
				panningControls.enableDamping = true;
				panningControls.dampingFactor = dampingFactor;
			}
			panningControls.update()
		}
		refreshCanvasSize();
		requestAnimationFrame(render);
	};

	const clearLoadedScene = () => {
		if(!currentScene){ return; }
		
		if(currentScene.dispose){
			currentScene.dispose();
		}

		currentScene.domElement.remove();
		scene.remove(currentScene.sceneObject);
		currentScene = null;
	};

	const dispose = () => {
		clearLoadedScene();
		if(motionControls){
			motionControls.dispose();
		}
		if(panningControls){
			panningControls.dispose();
		}
		resizeHook.dispose();
	};

	resizeHook.onResized(refreshCanvasSize)

	watch(() => tween.fade, (v, ov) => {
		if(v < ov){
			setFadeOpacity(v);
		}
		setPlayRate(v);
		setCanvasBlur(v);
	});

	return {
		loading,
		canvas,
		init,
		dispose,
		loadVideo,
	};
};