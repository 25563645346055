

export const computeScaleSizing = (rows:string[], columns:string[]) =>{
	const r = {
		buttons:computeButtonSize(columns),
		colLabel:computeColumnFontSize(columns),
		label:computeLabelFontSize(columns),
		labelCols: computeLabelCols(rows, columns),
		colTooltips:false
	};
	return r;
};

const button = {
	default:1,
	min:0.4,
};

const colLabel = {
	default:1,
	min:0.6,
};

const label = {
	default:1,
	min:0.6,
};

// const defaultColLabelSize = 1;
// const minColFontSize = 0.6;

const computeLengthFormula = (sv:number, l:number, ml:number, mul:number, minv:number) => {
	let base = sv;
	if(l < ml){ return base; }
	let s = (l - 2) * mul;
	base -= (s);
	return base > minv ? base : minv;
};

const computeButtonSize = (columns:string[]):number => {
	if(columns.length < 5){ return button.default; }
	const x = columns.length - (columns.length % 2);
	const y = button.default - (0.025 * x);
	return y > button.min ? y : button.min;
};

const computeColumnFontSize = (columns:string[]):number => {
	return computeLengthFormula(colLabel.default, columns.length, 3, 0.03, colLabel.min);
};

const computeLabelFontSize = (columns:string[]):number => {
	return computeLengthFormula(label.default, columns.length, 3, 0.02, label.min);
};


const splitRows = (rows:string[]) => {
	return rows.map(r => {
		const p = r.split(";");
		return { l:(p.length ? p[0] : ""), r:(p.length > 1 ? p[1] : "") }
	});
};


const computeLabelCols = (rows:string[], columns:string[]):{ l:number, r:number } => {
	// const i = rows.findIndex(r => r.length);


	const r = splitRows(rows);

	const ml = r.reduce((m, c) => Math.max(m, (c.l.trim()).length), 0);
	const mr = r.reduce((m, c) => Math.max(m, (c.r.trim()).length), 0);

	const max = Math.max(ml, mr);

	// const split = rows.map(r => r.split(";"));

	let cols = 2;

	switch(columns.length){
		case 2: cols = 4; break;
		case 3: case 4: case 5: case 6: cols = 3; break;
	}

	if(max && max < 10) {
		cols = 1;
	}
	else if(max && max < 20){
		cols = 2;
	}

	const rr = {
		l:ml ? cols : 0,
		r:mr ? cols : 0,
	};




	return rr;
	// return 2;
};


