








import { defineComponent, ref, } from "@vue/composition-api";
import { AbsBox } from "@ui";
import { default as Hoverlay } from "../shared/hoverlay.vue";
import { default as EditVideoModal } from "./edit-video-modal.vue";
import { IEditVideoModal } from "./edit-video-modal.vue";
import { useTranslate } from "@lang";


export default defineComponent({

	emits:[ "set-url", "set-width" ],
	props:{
		url:{
			type:String,
			default:"",
		},
		width:{
			type:Number,
			default:100,
		},
	},
	components:{
		AbsBox,
		Hoverlay,
		EditVideoModal,
	},
	setup(props, ctx){

		const { translate } = useTranslate();

		const modal = ref<IEditVideoModal>();
		
		const edit = () => {
			if(!modal.value){ return; }

			modal.value.open({
				url:props.url,
				width:props.width
			}, ({ url, width }) => {
				if(url){ ctx.emit("set-url", url); }
				if(width){ ctx.emit("set-width", width); }
			})
		};
		

		return {
			modal,
			translate,
			edit
		};
	}
});

