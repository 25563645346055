import { defineComponent, computed, ref } from "@vue/composition-api";
import { useAssets } from "@/hooks/useAssets";
import { formatDateStringRecent } from "@/utils/text";
import { useTranslate } from "@lang";
import { Date, Heading, Icon } from "@ui";
import { Provide } from "@api/studies";

enum Translations {
	Session = "studies.label.session",
	Sessions = "studies.label.sessions",
	NoActivity = "message.noActivity",
}

const getStudyRoute = (id:string) => ({ name:"Run.Group", params:{ groupId:id } })

export const RecentStudies = defineComponent({
	setup(_){

		const loaded = ref(false);
		const assetHooks = useAssets();
		const studies = ref<any[]>([]);
		const days = computed(() => 7);

		const getBlueprintName = (s:any) => {
			const [ aid ] = s.target.blueprint.split("@");
			if(!aid){ return "?"; }
			const a = assetHooks.assets.value.find(a => a._id === aid);
			return a ? a.name : "";
		};

		const loadData = async () => {
			try {
				studies.value = await Provide.studies.readRecentStudies({ days:days.value });
			}
			catch(err){}
			loaded.value = true;
		};

		loadData();

		return {
			days,
			loaded,
			studies,
			formatDateStringRecent,
			getBlueprintName,
		};
	},
	render(){

		if(!this.studies.length){
			return <NoActivityMessage days={this.days}/>
		}
		
		const dstudies = this.studies.map(s => {
			return (
				<StudyItemCard
				key={s._id}
				name={s.name}
				blueprint={this.getBlueprintName(s)}
				route={getStudyRoute(s._id)}
				lastVisited={s.lastVisited}
				visits={s.visits}
				/>
			);
		});

		return (
			<div class="list-group">
				{dstudies}
			</div>
		);
	}
});

const VisitsLabel = defineComponent({
	props:{
		visits:{ type:Number, default:0 },
	},
	setup(props){

		const { translate } = useTranslate();

		const label = computed(() => {
			const txt = translate(props.visits === 1 ? Translations.Session : Translations.Sessions);
			return `${props.visits} ${txt}`
		});

		return {
			label,
		};
	},
	render(){
		return (
			<small >{this.label}</small>
		);
	}
});


const NoActivityMessage = defineComponent({
	props:{
		days:{ type:Number, default:0 },
	},
	setup(props){

		const { translate } = useTranslate();

		const text = computed(() => {
			return translate(Translations.NoActivity).replace("$n", props.days.toString())
		});

		return {
			text
		};
	},
	render(){
		return (
			<div class="bg-dark p-3 d-flex rounded">
				<Heading size={6} class="text-light m-auto">
					{this.text}
				</Heading>
			</div>
		);
	}
});


const StudyItemCard = defineComponent({
	props:{
		name:String,
		blueprint:String,
		visits:Number,
		lastVisited:null,
		route:null,
	},
	render(){
		return (
			<router-link
			class="d-flex p-0 bg-dark text-light list-group-item"
			to={this.route}
			style="text-decoration:none;"
			>
				<div class="flex-fill d-flex flex-column p-1 px-3">
					<span>{this.name}</span>
					<small class="my-auto" style="font-size:0.6em;opacity:1">
						<Icon name="state-machine" class="my-auto mr-1 text-info"/>
						<span class="my-auto text-muted">
							{ this.blueprint }
						</span>
					</small>
				</div>
				<div class="d-flex flex-column px-3 pt-2">
					<VisitsLabel
					visits={this.visits || 0}
					class="ml-auto"
					/>

					<small style="font-size:0.6em;opacity:0.5">
						<Date date={this.lastVisited || 0}/>
					</small>
				</div>
			</router-link>

		);
	}
});