








// vendor
import { defineComponent, computed, ref } from "@vue/composition-api";
// project
import { useLang } from "@lang";

export default defineComponent({
	props:{
		translateKey:{
			type:String,
			default:""
		},
	},
	setup(props, context){

		const {
			translate,
		} = useLang(context);

		const title = computed(() => translate(props.translateKey));

		return {
			title,
		};
	}
});

