import { defineComponent, computed } from "@vue/composition-api";
import { FindAsset } from "@/components.assets/find-asset";
import { useTranslate } from "@lang";
import { Route } from "vue-router";

enum Translations {
	SelectBlueprint = "assets.action.selectBlueprint",
}

const grabRouteTag = (r:Route) => {
	if(r.name === "view-tag"){ return r.params["tagId"]; }
	if(r.query["t"]){ return r.query["t"] as string; }
	if(r.query["tag"]){ return r.query["tag"] as string; }
	return null;
};

export const FindBlueprint = defineComponent({

	props:{
		value:null,
		updateable:{
			type:Boolean,
			default:false,
		}
	},
	setup(props, ctx){

		const { translate } = useTranslate();

		const val = computed({
			get:() => props.value,
			set:v => ctx.emit("input", v),
		});

		const activeTag = grabRouteTag(ctx.root.$route);

		return {
			val,
			activeTag,
			translate
		}
	},
	render(){

		return (
			<FindAsset
			placeholder={ `${this.translate(Translations.SelectBlueprint)}...` }
			v-model={ this.val }
			types={ [ "Graph" ] }
			tag={ this.activeTag || undefined }
			size="md"
			can-update={ this.updateable }
			/>
		);
	}
})

