
export const formatDateString = (v:string) => {
	let date = new Date(v);
	let m = formatDigit(date.getMinutes());
	let h = formatDigit(date.getHours());
	let dates = `${formatDigit(date.getDate())}/${formatDigit(date.getMonth() + 1)}/${date.getFullYear()}`;
	let ds = `${dates} ${h}:${m}`;
	return ds;
};

export const formatDateStringRecent = (v:string|number) => {
	let date = new Date(v);
	if(isJustNow(v)){ return "Just now" }
	let m = formatDigit(date.getMinutes());
	let h = formatDigit(date.getHours());
	if(isToday(date)){ return `Today • ${h}:${m}`; }
	let dates = `${formatDigit(date.getDate())}/${formatDigit(date.getMonth() + 1)}/${date.getFullYear()}`;
	let ds = `${dates} • ${h}:${m}`;
	return ds;
};

export const formatDuration = (ms:number) => {
	if(ms < 1000){ return `${ms}ms`; }
	var secNum = (parseInt(ms + "", 10)) / 1000;
	secNum = Number(secNum.toFixed(0));
	var hours   = Math.floor(secNum / 3600);
	var minutes = Math.floor((secNum - (hours * 3600)) / 60);
	var seconds = secNum - (hours * 3600) - (minutes * 60);
	let base = "";
	if(hours > 0){ base += `${hours}h`; }
	if(minutes > 0){ base += ` ${minutes}m`; }
	if(seconds > 0){ base += ` ${seconds}s`; }
	return base;
}

const formatDigit = (num:any) => {
	num = num + "";
	return num.length === 1 ? "0" + num : num;
};

const isToday = (date:Date) => {
	let today = new Date();
	return date.getDate() == today.getDate() &&
	date.getMonth() == today.getMonth() &&
	date.getFullYear() == today.getFullYear()
}

const isJustNow = (v:string|number) => {
	let date = new Date(v);
	let diff = Date.now() - date.valueOf();
	if(diff < 0){ return false; }
	if(diff > 59 * 1000){ return false; }
	return true;
};


// import { format, formatDuration } from "date-fns";

// export const formatDateDefault = (date: Date | number): string => {
// 	// todo improve - add string variant for 'today', etc..
// 	return format(date, "d/L/Y");
// };

// export const formatDateAndTimeDefault = (date: Date | number): string => {
// 	// todo improve - add string variant for 'today', etc..
// 	return format(date, "d/L/Y • hh:mm");
// };

// export const formatDurationDefault = (lengthInMs: number): string => {
// 	return formatDuration({ seconds: Math.round(lengthInMs / 1000) });
// };
