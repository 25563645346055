







import { defineComponent, computed } from "@vue/composition-api";

export default defineComponent({
	props:{
		noGutters:{
			type:Boolean,
			default:false,
		}
	},
	setup(props){
		const cls = computed(() => {4
			return `row ${props.noGutters ? 'no-gutters' : ''}`;
		});
		return {
			cls
		};
	}
});

