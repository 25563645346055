import { defineComponent, ref, computed, SetupContext } from "@vue/composition-api";
import { TagSidebar, TagRouteToggle } from "@components/tags";
import { sortByDate } from "@utils/array";
import { useTags } from "@/hooks/useTags";
import { useAssets } from "@/hooks/useAssets";
import { StudyList, BlueprintGrid } from "@components/studies";
import { useTranslate } from "@lang";
import { AbsBox, Heading, Container, Divider, SearchFilter } from "@ui";
import { ContextHeader } from "@components/nav";

export default defineComponent({
	beforeRouteUpdate(to:any, _, next:any){ // note: hack until vue-router uses composition api
		const t = to.query["t"];
		if(this.currentTagId !== t){
			this.setCurrentTag(t);
		}
		next();
	},
	setup(_, context){

		const studiesList = ref<any>();

		const searchFilter = ref(getQueryValue("filter", context, ""));

		const { assets } = useAssets();

		const { translate } = useTranslate();

		const { sortedTags, hasTag } = useTags();

		const blueprints = computed(() => {
			const tag = currentTagId.value;
			const blueprints = assets.value.filter(a => a.type === "Graph");
			sortByDate(blueprints, a => a.lastModified);
			if(!tag){ return blueprints; }
			return blueprints.filter(bp => hasTag(tag, bp._id));
		});

		const currentTagId = ref<string|null>(null);

		const currentTag = computed(() => sortedTags.value.find(t => t._id === currentTagId.value));

		const setCurrentTag = (v:string) => currentTagId.value = v;

		const init = async() => {
			const t = context.root.$route.query["t"];
			if(t && typeof(t) === "string"){
				currentTagId.value = t;
			}
		};

		const saveSearch = () => {
			const sv = searchFilter.value.length > 0 ? searchFilter.value : undefined;
			setQueryValue("filter", sv, context);
		};

		const clearSearch = () => {
			searchFilter.value = '';
			setQueryValue("filter", undefined, context);
		};

		init();

		return {
			searchFilter,
			blueprints,
			currentTagId,
			currentTag,
			studiesList,
			translate,
			setCurrentTag,
			clearSearch,
			saveSearch,
			onStudyCreated(){
				console.log(studiesList);
				studiesList.value?.setSort("created", true);
				studiesList.value?.refresh();
			}
		};

	},
	render(){
		return (
			<AbsBox class="d-flex" style="overflow:hidden;">
				<TagSidebar showAdd={ false }/>

				<ContextHeader>
					<AbsBox class="d-flex">
						<div class="flex-fill" style="position:relative;">
							<AbsBox class="d-flex pl-3">
								<Heading size={3} class="my-auto">
									{ this.translate("studies.label.studies") }
								</Heading>
							</AbsBox>

						</div>
						<div class="flex-fill" style="position:relative;">
							<AbsBox class="d-flex justify-content-end" >

								<TagRouteToggle class="my-auto mr-2"/>

								<SearchFilter
								placeholder={ `${this.translate('assets.action.filterBlueprints')}...` }
								v-model={ this.searchFilter }
								v-on:blur={ this.saveSearch }
								v-on:cleared={ this.clearSearch }
								class="my-auto mr-3"
								/>
							</AbsBox>
						</div>
					</AbsBox>
				</ContextHeader>


				<div class="flex-fill"
				style="position:relative;"
				>
					<AbsBox class="py-3" style="overflow:auto">
						<Container class="mb-3">
							<Heading size={4}>
								{ this.translate("assets.label.availableBlueprints") }
							</Heading>
							<Divider/>
							<BlueprintGrid
							searchFilter={ this.searchFilter }
							v-on:created={ this.onStudyCreated }
							/>
						</Container>

						<div class="mb-3"/>

						<Container class="mb-3">
							<Heading size={4}>
								{ this.translate("studies.label.existingStudies") }
							</Heading>
							<Divider/>
							<StudyList
							ref="studiesList"
							tag={  this.currentTag?._id }/>
						</Container>
					</AbsBox>
				</div>
			</AbsBox>
		);
	}

});

const setQueryValue = (k:string, v:any, context:SetupContext) => {
	const q:any = { ...context.root.$route.query };
	if(v !== undefined && v !== null){
		q[k] = v;
	}
	else {
		delete q[k];
	}
	context.root.$router.push({ query: q})
	.catch(() => {});
};


const getQueryValue = (k:string, context:SetupContext, def:string):string => {
	return ((context.root.$route.query[k] as any) as string) || def;
};