// vendor
import { Ref, ref, computed, set } from "@vue/composition-api";
// project
import * as TagAPI from "@/services/api/tags";
import { Tag, TaggedItem } from "@/psychlab/types/tags";

let loaded = false;

let tags:Ref<Tag[]>;
let taggedItems:Ref<TaggedItem[]>;

export const useTags = () => {

	const init = async () => {
		tags.value = await TagAPI.getTags();
		taggedItems.value = await TagAPI.getAllTaggedItems();
	};

	if(!loaded){
		tags = ref<Tag[]>([]);
		taggedItems = ref<TaggedItem[]>([]);
		loaded = true;
		init();
	}

	const sortedTags = computed(() => {
		const dt = tags.value.map(t => t);
		dt.sort((a, b) => a.name.localeCompare(b.name));
		return dt;
	});

	const getTaggedItems = (id:string) => {
		return taggedItems.value.filter(ti => ti.tag === id);
	};

	const deleteTag = async(id:string) => {
		await TagAPI.deleteTag(id);
		tags.value = tags.value.filter(t => t._id !== id);
		taggedItems.value = taggedItems.value.filter(t => t.tag !== id);
	};

	const createTag = async(name:string) => {
		const t = await TagAPI.createTag({
			name
		});
		tags.value.push(t);
	};

	const hasAnyTag = (item:string):boolean => {
		return taggedItems.value.findIndex(ti => ti.item === item) > -1;	
	};

	const hasTag = (tag:string, item:string):boolean => {
		return taggedItems.value.findIndex(ti => ti.item === item && ti.tag === tag) > -1;	
	};

	const tagItem = async(tag:string, item:string, type:"asset"|"study") => {
		// already tagged
		if(taggedItems.value.findIndex(ti => ti.tag === tag && ti.item === item) > -1){
			return;
		}
		const ti = await TagAPI.assignTag(tag, item, type);
		taggedItems.value.push(ti);
	};

	const untagItem = async(tag:string, item:string) => {
		await TagAPI.unassignTag(tag, item)
		taggedItems.value = taggedItems.value.filter(ti => !(ti.item === item && ti.tag === tag));
	};

	const editTag = async(tag:string, args:{ name?:string, color?:string }) => {
		await TagAPI.editTag(tag, args);
		const tt = tags.value.find(t => t._id === tag);
		if(!tt){ return; }
		Object.keys(args).forEach(k => set(tt, k, (args as any)[k]))
	};

	const getCachedTag = (tag:string):Tag => {
		const i = tags.value.findIndex(t => t._id === tag);
		return tags.value[i];
	};

	const getItemTags = (id:string):Tag[] => {
		return taggedItems.value.filter(ti => ti.item === id)
		.map(ti => tags.value.find(t => t._id === ti.tag)) as Tag[];
	}

	return {
		tags:sortedTags,
		sortedTags,
		deleteTag,
		createTag,
		hasTag,
		hasAnyTag,
		tagItem,
		untagItem,
		editTag,
		getCachedTag,
		getTaggedItems,
		getItemTags,
	};

};