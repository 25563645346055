












import { defineComponent, computed, PropType } from "@vue/composition-api";
import { IFormTheme } from "@psychlab/types/form";

type ButtonType = "none"|"submit"

export default defineComponent({

	emits:[ "click" ],
	props:{
		label:{
			type:String,
			default:"",
		},
		disabled:{
			type:Boolean,
			default:false,
		},
		type:{
			type:String as PropType<ButtonType>,
			default:"none"
		},
		theme:{
			type:Object as PropType<IFormTheme>,
			default:() => {},
		}
	},
	setup(props){
		
		const btnClass = computed(() => {
			const v = ({
				"submit":"success"
			} as any)[props.type] || "light";
			return `v-${v}`
		});

		return {
			btnClass
		};
	}
});

