import { generateGUID } from "@/utils/guid";
import { FormSection } from "@psychlab/types/form"

export const getFormSection = ():FormSection => {
	return {
		id: `s-${generateGUID()}`,
		items: [],
		label:"",
		settings:{
			itemSelection:null
		}
	};
};