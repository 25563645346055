


























































































































// vendor
import { defineComponent, ref, computed } from "@vue/composition-api";
// project
import * as StudyAPI from "@/services/api/studies";

import { useTranslate } from "@lang";

// local
// import { useStudyRouteParams } from "../hooks/useStudyRouteParams";

type EditProps = {
	name:string;
	color:string;
}

const DEFAULT_COLOR = "#007bff";

export default defineComponent({
	emits: [
		"add",
		"label-added",
	],
	setup(props, ctx) {

		const studyId = computed(() => ctx.root.$route.params["groupId"]);

		const { translate } = useTranslate();

		// const newLabelActive = ref(false);
		// const name = ref("");
		// const color = ref(DEFAULT_COLOR);
		

		const loading = ref(false);
		// const hasError = ref(false);

		const canSave = computed(() => {
			return editProps.value && editProps.value.name.length && !loading.value;
		})

		const editProps = ref<EditProps|null>(null);

		const add = () => {
			ctx.emit("add");
			editProps.value = {
				name:"",
				color:DEFAULT_COLOR,
			};
		}

		const cancel = () => {
			editProps.value = null;
		};

		const confirm = async () => {
			if(!editProps.value){ return; }

			loading.value = true;
			try {
				const l = await StudyAPI.createSessionLabel(studyId.value, editProps.value);
				ctx.emit("label-added", l);
			} catch {
				// hasError.value = true;
			}
			editProps.value = null;
			loading.value = false;
		};

		return {
			loading,
			canSave,
			editProps,
			add,
			cancel,
			confirm,
			translate,
		};
	},
});
