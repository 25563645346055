// vendor
import * as THREE from "three";
// local
import * as ThreeUtils from "../utils/Three";
import { getApp } from "../App";
import { IBehaviour } from "../core/IBehaviour";

export interface ISceneRendering extends IBehaviour {
	render(ob:THREE.Object3D, cam:THREE.Camera):void;
};

export const useSceneRendering = (el:HTMLElement):ISceneRendering => {

	const renderer:THREE.Renderer = createRenderer(el);

	el.appendChild(renderer.domElement);

	const render = (ob:THREE.Object3D, cam:THREE.Camera) => {
		renderer.render(ob, cam);
	};

	const resize = () => {
		const size = getSize(el);
		renderer.setSize(size.x, size.y);
	};

	const r:ISceneRendering = {
		type:"SceneRendering",
		enabled:true,
		render,
		resize,
	};

	getApp().registerBehaviour(r);
	
	return r;
};

const getSize = (el:HTMLElement) => {
	let x:number = el.offsetWidth;
	let y:number = el.offsetHeight;
	return { x, y };
};

const createRenderer = (el:HTMLElement) => {
	const r = new THREE.WebGLRenderer({ antialias:true });
	// r.setClearColor(0xa4c4db);
	r.setClearColor("#5e5e5e");
	// r.outputEncoding = THREE.sRGBEncoding;
	r.shadowMap.enabled = true;
	r.toneMapping = ThreeUtils.getToneMappingOption("Reinhard");
	// r.toneMappingExposure = 1;
	const size = getSize(el);
	r.setSize(size.x, size.y);
	return r;
};