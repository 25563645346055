import VerifySurveyAuthPage from "@pages/verify-survey-auth";

export const VerifyStudyAuth = {
	name: "verify-survey-auth",
	path: "/r/s/:studyId/auth",
	component: VerifySurveyAuthPage,
	meta: {
		title: "Verify Survey Auth",
		public: true,
		navHidden:true
	}
};