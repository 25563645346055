


















































// vendor
import { defineComponent, ref, computed } from "@vue/composition-api";
// local
import { RenderRawConfig } from "./IRenderRaw";

type CallbackHandler = () => void;

const defaults:RenderRawConfig = {
	title:"",
	content:"",
	variant:"dark",
	okText:"Confirm",
	size:"sm"
};

const colorMap:any = {
	"light":"dark",
}

const getValue = (c:RenderRawConfig|null, k:keyof(RenderRawConfig)) => {
	return c ? c[k] || defaults[k]: defaults[k];
};

export default defineComponent({

	setup(){

		const modal = ref<any>();

		const fn = ref<CallbackHandler|null>(null);
		const show = ref(false);

		const config = ref<RenderRawConfig|null>(null);

		const ok = () => {
			if(fn.value){ fn.value(); }
			if(modal.value){
				modal.value.hide();
			}
		};

		const cancel = () => {
			if(!modal.value){ return; }
			modal.value.hide();
		};

		const hide = () => {
			fn.value = null;
			show.value = false;
			config.value = null;
		};

		const open = (c:RenderRawConfig, f:CallbackHandler) => {
			console.log(config);
			fn.value = f;
			config.value = c;
			show.value = true;
		};

		const textVariant = computed(() => {
			return config.value ? colorMap["danger"] || "light" : "light";
		});

		const content = computed(() => config?.value?.content || "");

		return {
			modal,
			show,
			config,
			title:computed(() => getValue(config.value, "title")),
			variant:computed(() => getValue(config.value, "variant")),
			size:computed(() => getValue(config.value, "size")),
			textVariant,
			content,
			ok,
			cancel,
			hide,
			open,
		};
	}
});
