var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-dark",staticStyle:{"position":"absolute","width":"100%","bottom":"0"}},[_c('docked-foldout',{attrs:{"showPrevious":_vm.showBack,"canContinue":_vm.canContinue},on:{"previous":_vm.previous,"next":_vm.next}},[_c('div',{staticClass:"border-bottom d-flex p-1 text-center"},[_c('span',{staticClass:"m-auto text-light font-weight-light",domProps:{"innerHTML":_vm._s(_vm.config.title)}})]),_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticClass:"p-1 grid-container"},_vm._l((_vm.config.options),function(option,index){return _c('div',{key:index,staticClass:"p-0"},[_c('b-button',{staticClass:"m-0 d-block shadow-none p-1 stretch",attrs:{"variant":"none","dblock":""},on:{"click":function($event){return _vm.toggleIndex(index)}}},[_c('div',{staticClass:"border stretch rounded-lg",class:{
								'bg-light border-light text-dark shadow': _vm.isToggled(
									index
								),
								'border-secondary text-light checkbox': !_vm.isToggled(
									index
								),
							},staticStyle:{"position":"relative"}},[_c('div',{staticClass:"stretch d-flex text-lightd p-2"},[_c('span',{staticClass:"m-auto font-weight-light",staticStyle:{"font-size":"0.8em"}},[_vm._v(" "+_vm._s(option)+" ")])])])])],1)}),0),(_vm.error)?_c('error-overlay',{attrs:{"translateKey":"session.message.pleaseSelectOneOption"}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }