







import { defineComponent, PropType } from "@vue/composition-api";
import { Study } from "@psychlab/types";
import { Date } from "@ui";
import { useTranslate } from "@lang";

export default defineComponent({
	props:{
		study:{
			type:Object as PropType<Study>,
			required:true,
		},
	},
	components:{
		Date,
	},
	setup(){
		const { translate } = useTranslate();
		return {
			translate,
		};
	},
});

