
























	// vendor
	import { defineComponent, PropType } from "@vue/composition-api";
	import { Study } from "@psychlab/types";
	import * as StudyAPI from "@/services/api/studies";
	import * as AppModal from "@/AppModal";
	import { default as StudyCreationDate } from "./study-creation-date.vue";
	import { useTranslate } from "@lang";
	import { Icon } from "@ui";
import { AnySrvRecord } from "dns";

	export default defineComponent({
		props:{
			study:{
				type:Object as PropType<Study>,
				required:true,
			}
		},
		components:{
			StudyCreationDate,
			Icon,
		},
		setup(props){

			const { translate } = useTranslate();

			const rename = () => {
				AppModal.editText({
					title:translate("studies.action.renameStudy"),
					value:props.study.name,
					confirmText:translate("action.save"),
					validator(v:string){
						return v.length > 1 && v !== props.study.name;
					}
				}, async (nv) => {
					try {
						await StudyAPI.setStudyName(props.study._id, nv);
						props.study.name = nv;
					} catch (err:any) {
						console.log(err.message);
					}
				});
			};

			const openContext = (e:Event) => {
				AppModal.context(e, [
					{
						name:translate("action.rename"),
						icon:"mdi.pencil-box",
						fn:rename,
					}
				]);
			};

			return {
				translate,
				openContext,
			};
		}
	});

