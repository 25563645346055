// vendor
import { defineComponent, PropType, ref, computed, watch } from "@vue/composition-api";
// project
import * as SessionAPI from "@/services/api/sessions";
import { ChannelData } from "@/psychlab/types";
// local
import { DisplayItem } from "../types/DisplayForm";
import * as Components from "../../vue/items";

export type ChannelProvider<T> = {
	getChannels():ChannelData<T>[]
}

export const RunFormItem = defineComponent({
	props:{
		showBack:{
			type:Boolean,
			default:true
		},
		value:{
			type:null
		},
		config:{
			type:Object as PropType<DisplayItem>,
			required:true
		},
		cacheKey:{
			type:String,
			required:true
		}
	},
	setup(props, context){

		const item = ref<ChannelProvider<any>>();

		const sessionId = computed(() => context.root.$route.params["sessionId"]);

		const component = computed(() => (Components as any)[props.config.type]);

		const cache = computed(() => context.root.$store.state.cache["storage"]);

		const val = computed({
			get:() => props.value,
			set:v => context.emit("input", v)
		});

		watch(() => props.value, (v) => {
			if(sessionId.value){
				SessionAPI.cacheSessionValue(sessionId.value, props.cacheKey, v);
			}
		});

		const next = (e:any) => {
			const p = item.value;
			if(!p){ return; }
			const c = p.getChannels();
			const channels = c.filter(ch => {
				const cached = cache.value[`${ch.key}.saved`];
				if(cached === undefined){ return true; }
				if(cached === ch.data){ return false; }
				return true;
			});
			context.emit("next", {
				channels
			});
		};

		const previous = (e:any) => {
			context.emit("previous", e);
		};

		return {
			item,
			component,
			val,
			cache,
			next,
			previous
		};

	}

});