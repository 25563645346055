









import { defineComponent } from "@vue/composition-api";
import { Information } from "@psychlab/types/form";
import { RenderInformation } from "@form-ui";
import { getItemProps } from "./getItemProps";
import { useTranslate } from "@lang";
import { editText } from "@/AppModal";


export default defineComponent({
	props:{
		...getItemProps<Information>()
	},
	components:{
		RenderInformation,
	},
	setup(props){

		const { translate } = useTranslate();
		
		const editContent = () => {
			editText({
				title:translate("forms.label.text"),
				variant:"light",
				value:props.data?.settings.text || "",
				size:"lg",
				rows:10,
				textarea:true,
			}, (v) => {
				if(!props.data){ return; }
				props.data.settings.text = v;
			})
		};

		return {
			editContent
		};
	}
});

