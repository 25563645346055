




































































// vendor
import { defineComponent, computed, ref } from "@vue/composition-api";
const FileDownload = require("js-file-download");
// project
import * as TaskAPI from "@/services/api/studies/tasks";
import { Task } from "@psychlab/types";

import { FileSize } from "@ui";
import { useTranslate } from "@lang";

export default defineComponent({

	components:{
		FileSize,
	},
	setup(){
		
		const show = ref(false);
		const task = ref<Task>();


		const { translate } = useTranslate();

		const files = computed(() => {
			if(!task.value){ return []; }
			return task.value.output.files ? task.value.output.files.map((f, i) => {
				return {
					size:f.size,
					name:f.name,
					mime:f.mime,
					index:i
				}
			}) : [];
		});

		const hide = () => {
			show.value = false;
			task.value = undefined;
		};

		const open = (t:Task) => {
			show.value = true;
			task.value = t;
		};
	
		const downloadFile = async(file:any) => {
			if(!task.value){ return; }
			let res = await TaskAPI.downloadFile(task.value.study, task.value._id, file.index);
			FileDownload(res.data, file.name);
		};

		return {
			show,
			task,
			files,
			tableFields,
			translate,
			hide,
			open,
			downloadFile
		};
	}

}); 


const tableFields = [

	{
		key: "name",
		thClass:"font-weight-light",
		tdClass:"consolas align-middle txt-80",
	},
	{
		key: "mime",
		thClass:"font-weight-light ",
		tdClass:"consolas tcol-xl align-middle txt-80"
	},
	{
		key: "size",
		thClass:"font-weight-light",
		tdClass:"consolas tcol-sm align-middle txt-80"
	},
	{
		key: "buttons",
		label:"",
		thClass:"tcol-smx",
		tdClass:"d-flex"
	}

];


