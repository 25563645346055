import {  defineComponent, ref, computed, reactive } from "@vue/composition-api";
import { Icon } from "@ui";
import { useTranslate } from "@lang";
import { useTags } from "@/hooks/useTags";

export interface ITagAssetModal {
	open(assetId:string):void;
}

enum Translations {
	Save = "action.save",
	Cancel = "action.cancel",
	EditTags = "tags.action.editTags",
}

export const TagAssetModal = defineComponent({

	setup(){

		const modal = ref<any>();
		const show = ref(false);

		const { translate } = useTranslate();

		const assetId = ref<string|null>(null);

		const hideModal = () => modal.value?.hide();

		const handleHidden = () => {
			assetId.value = null;
			show.value = false;
		};

		const handleDone = () => {
			hideModal();
		};

		const handleCancel = () => {
			hideModal();
		};

		const open = (aid:string) => {
			assetId.value = aid;
			show.value = true;
		};


		return {
			modal,
			show,
			assetId,
			translate,
			open,
			handleHidden,
			handleDone,
			handleCancel,
		};
	},
	render(){

		const content = this.assetId ? (
			<EditTags
			v-on:done={ this.handleDone }
			v-on:cancel={ this.handleCancel }
			asset={ this.assetId }
			/>
		) : <span/>;

		return (
			<Modal
			ref="modal"
			title={ this.translate(Translations.EditTags) }
			v-on:hidden={ this.handleHidden }
			v-model={ this.show }
			>
				{ content }
			</Modal>
		);
	}
});


const applyAssetTags = async (asset:string, add:string[], remove:string[]) => {
	const th = useTags();
	await Promise.all([
		...add.map(tid => th.tagItem(tid, asset, "asset")),
		...remove.map(tid => th.untagItem(tid, asset)),
	]);
};

const EditTags = defineComponent({
	emits:[ "done", "cancel" ],
	props:{
		asset:{
			type:String,
			required:true,
		},
	},
	setup(props, ctx){

		const tagHooks = useTags();

		const { translate } = useTranslate();
		
		const { tags } = tagHooks;

		const initState = () => {
			const state:Record<string,boolean> = {};
			tags.value.forEach(t => state[t._id] = false);
			tagHooks.getItemTags(props.asset).forEach(t => state[t._id] = true);
			return state;
		}

		const state = reactive(initState());

		const initialState:Record<string,boolean> = JSON.parse(JSON.stringify(state));

		


		const save = async () => {
			const changed = Object.keys(state).filter(k => state[k] !== initialState[k]);
			const add = changed.filter(k => state[k]);
			const remove = changed.filter(k => !state[k]);
			await applyAssetTags(props.asset, add, remove);
			ctx.emit("done");
		};

		return {
			state,
			tags,
			translate,
			save,
			cancel:() => ctx.emit("cancel"),
		};
	},
	render(){

		const rows = this.tags.map(t => {

			const icon = (
				<Icon name="folder" shadow style={ `color:${t.color}` } class="mr-2"/>
			);

			return (
				<button class="list-group-item list-group-item-action p-1 px-2"
				v-on:click={ () => this.state[t._id] = !this.state[t._id] }
				>
					<div class="custom-control custom-checkbox"
					style="pointer-events:none;"
					>
						<input type="checkbox" class="custom-control-input shadow-none" id={ t._id } v-model={ this.state[t._id] }/>
						<label class="custom-control-label" for={ t._id }>
							{icon}
							{ t.name }
						</label>
					</div>
				</button>
			);
		});

		return (

			<div>

				<div class="p-3">
					<div class="list-group p-0 m-0">
						{ rows }
					</div>
				</div>

				<ModalRule/>

				<Footer>
					<FooterButton
					v-on:click={ () => this.$emit("cancel") }
					variant="dark"
					label={ this.translate(Translations.Cancel) }
					/>
					<FooterButton
					v-on:click={ this.save }
					variant="outline-primary"
					label={ this.translate(Translations.Save) }
					/>
				</Footer>
			</div>

		);
	}

});

const Footer = defineComponent({
	render(){
		return (
			<div class="p-3 d-flex justify-content-between">
				{ this.$slots.default }
			</div>
		);
	}
})

const FooterButton = defineComponent({
	emits:[ "click" ],
	props:[ "label", "variant" ],
	render(){
		return (
			<button
			v-on:click={ (e:Event) => this.$emit("click",e) }
			class={ `btn btn-${this.variant} shadow-none` }
			style="font-family:'NATS'"
			>
				{ this.label }
			</button>
		)
	}
});

const Modal = defineComponent({
	emits:[ "input" ],
	props:{
		value:{ type:Boolean, default:false, },
		title:{
			type:String,
			default:"-title-",
		}
	},
	setup(props, ctx){

		const modal = ref<any>();
		
		return {
			modal,
			val:computed({
				get:() => props.value,
				set:v => ctx.emit("value", v),
			}),
			hide:() => modal.value?.hide()
		};
	},
	render(){
		return (
			<div>
				<b-modal
				ref="modal"
				v-on:hidden={ () => this.$emit("hidden") }
				v-model={ this.val }
				content-class="p-0 rounded-0"
				body-class="p-0 rounded-0 border shadow"
				class="p-0"
				size="md"
				centered
				hide-header
				hide-footer
				>
					<ModalHeader>
						{ this.title }
					</ModalHeader>
					<ModalRule/>
					<ModalBody>
						{ this.$slots.default }
					</ModalBody>
				</b-modal>
			</div>
		);
	}
});


const ModalRule = defineComponent({
	render(){
		return <hr class="bg-light m-0"/>;
	}
});

const ModalHeader = defineComponent({
	render(){
		return (
			<div class="p-1 d-flex text-dark">
				<h2 style="font-family:'NATS'" class="m-auto">
					{ this.$slots.default }
				</h2>
			</div>
		);
	}
})

const ModalBody = defineComponent({
	render(){
		return (
			<div class="p-0">
				{ this.$slots.default }
			</div>
		);
	}
})