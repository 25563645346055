


export const getBaseProps = () => {
	return {
		disabled:{
			type:Boolean,
			default:false,
		},
	}
};

export const getBaseEmits = () => {
	return [ "click" ];
}