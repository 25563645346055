import { SetupContext } from "@vue/composition-api";
// import { translateKey } from "../ts/translateKey";
import { useTranslate } from "./useTranslate";

export const useLang = (context: SetupContext) => {
	// const translate = (key: string): string => {
	// 	const lang: string =
	// 		context.root.$store.getters["localization/preferredLanguage"];
	// 	return translateKey(key, lang);
	// };

	// return {
	// 	translate,
	// };
	return useTranslate();
};


export const useRouteTranslate = (context: SetupContext) => {
	// const translate = (key: string): string => {
	// 	const lang: string =
	// 		context.root.$store.getters["localization/preferredLanguage"];
	// 	return translateKey(key, lang);
	// };

	// return {
	// 	translate,
	// };

	return useTranslate();
	
};
