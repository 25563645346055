














































import { defineComponent, computed, PropType, set } from "@vue/composition-api";
import * as AssetAPI from "@/services/api/assets";
import { AssetVersion } from "@psychlab/types/assets";
import * as AppModal from "@/AppModal";
import { ContextOption, Date as DateLabel } from "@ui";
import { useTranslate } from "@lang";

export default defineComponent({
	emits:[
		"click",
		"restore",
	],
	props:{
		version:{
			type:Object as PropType<AssetVersion<any>>,
			required:true,
		},
		active:{
			type:Boolean,
			default:false,
		},
		latest:{
			type:Boolean,
			default:false,
		},
		disabled:{
			type:Boolean,
			default:false,
		},
		showContext:{
			type:Boolean,
			default:true,
		},
	},
	components:{
		DateLabel,	
	},
	setup(props, context){

		const { translate } = useTranslate();

		const versionName = computed(() => {
			return props.version.name || `${translate('assets.label.version')} ${props.version.vIndex + 1}`;
		});

		const onClick = (e:Event) => {
			context.emit("click", e);
		};

		const restore = () => {
			AppModal.confirm({
				title:translate("assets.action.restoreVersion"),
				// title:"Restore Asset Version",
				description:translate("assets.message.restoreVersion"),
				okText:translate("prompts.action.understood"),
				cancelText:translate("prompts.action.cancel"),
				variant:"info"
			}, () => {
				context.emit("restore");
			});
		};

		const rename = () => {
			AppModal.editText({
				title:translate("assets.action.setName"),
				value:props.version.name || "",
				validator(v){
					return v !== props.version.name;
				}
			}, async(v) => {
				await AssetAPI.setAssetVersionName(props.version.asset, props.version._id, v);
				// props.version.name = v;
				set(props.version, "name", v);
			});
		};

		const openContext = (e:Event) => {

			e.stopPropagation();

			const options:ContextOption[] = [];

			options.push({
				name:translate("assets.action.setName"),
				icon:"mdi.pencil-box",
				fn(){
					rename();
				}
			});

			if(!props.latest){
				options.push({
					name:translate("assets.action.restoreVersion"),
					icon:"mdi.restore",
					fn(){
						restore();
					}
				})
			}

			AppModal.context(e, options);
		};

		return {
			versionName,
			onClick,
			openContext,
			translate,
		};
	}
});

