export class MouseInput {

	get mouseDeltaX(){
		const v = this._mouseDelta.x;
		this._mouseDelta.x = 0;
		return v;
	}

	get mouseDeltaY(){
		const v = this._mouseDelta.y;
		this._mouseDelta.y = 0;
		return v;
	}

	get scrollDelta(){
		const v = this._scrollDelta;
		this._scrollDelta = 0;
		return v;
	}

	constructor(el:EventTarget){
		this._el = el;
		this._mouseMoveFn = this.onMouseMove.bind(this) as EventListener;
		this._mouseUpFn = this.onMouseUp.bind(this) as EventListener;
		this._mouseDownFn = this.onMouseDown.bind(this) as EventListener;
		this._mouseScrollFn = this.onMouseScroll.bind(this) as EventListener;
		window.addEventListener("mousemove", this._mouseMoveFn);
		window.addEventListener("mouseup", this._mouseUpFn);
		this._el.addEventListener("mousedown", this._mouseDownFn);
		this._el.addEventListener("wheel", this._mouseScrollFn);
	}

	public mouseDown(b:number):boolean{
		return Boolean(this._keys[`mouse${b}`]);
	}

	public dispose(){
		window.removeEventListener("mousemove", this._mouseMoveFn);
		window.removeEventListener("mouseup", this._mouseUpFn);
		this._el.removeEventListener("mousedown", this._mouseDownFn);
		this._el.removeEventListener("wheel", this._mouseScrollFn);
	}

	private _el:EventTarget;
	private _keys:Dictionary<boolean> = {};

	private _mouseMoveFn:EventListener;
	private _mouseUpFn:EventListener;
	private _mouseDownFn:EventListener;
	private _mouseScrollFn:EventListener;

	private _cachedMouse = { x:0, y:0 };
	private _mouseDelta = { x:0, y:0 };
	private _scrollDelta:number = 0;

	private onMouseUp(e:MouseEvent){ this._keys[`mouse${e.button}`] = false; }
	private onMouseDown(e:MouseEvent){ this._keys[`mouse${e.button}`] = true; }

	private onMouseMove(e:MouseEvent){
		this._mouseDelta.x = e.clientX - this._cachedMouse.x;
		this._mouseDelta.y = e.clientY - this._cachedMouse.y;
		this._cachedMouse.x = e.clientX;
		this._cachedMouse.y = e.clientY;
	}

	private onMouseScroll(e:WheelEvent){
		this._scrollDelta = Math.sign(e.deltaY);
	}
}

interface Dictionary<T> { [k:string]:T }