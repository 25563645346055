var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"conxtrols ",staticStyle:{"position":"absolute","left":"0","top":"0","height":"100%","transform":"translate(-100%,0)","padding-right":"0.4em"}},[_c('div',{staticClass:"d-flex flex-column controls",class:{ active: _vm.active }},[_c('button',{staticClass:"drag-btn drag-knob editor-btn d-flex",attrs:{"disabled":_vm.disableDrag}},[_c('Icon',{staticClass:"m-auto",attrs:{"name":"mdi.drag"}})],1),_c('div',{staticClass:"mt-2"}),_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
			xtitle:_vm.comment||_vm.translate('forms.action.setComment') + '...',
			title:_vm.comment,
			interactive:false,
			delay:0,
			animation:false,
			html:true,
			variant:_vm.comment ? 'success' : 'none'


		}),expression:"{\n\t\t\txtitle:comment||translate('forms.action.setComment') + '...',\n\t\t\ttitle:comment,\n\t\t\tinteractive:false,\n\t\t\tdelay:0,\n\t\t\tanimation:false,\n\t\t\thtml:true,\n\t\t\tvariant:comment ? 'success' : 'none'\n\n\n\t\t}",modifiers:{"hover":true}}],staticClass:"comment-btn editor-btn d-flex",class:{
			empty:!Boolean(_vm.comment)
		},on:{"click":_vm.openComment}},[(_vm.comment)?_c('Icon',{staticClass:"m-auto",attrs:{"name":"mdi.chat"}}):_c('Icon',{staticClass:"m-auto",attrs:{"name":"mdi.comment-plus-outline"}})],1),_c('button',{staticClass:"settings-btn editor-btn d-flex",on:{"click":_vm.openSettings}},[_c('Icon',{staticClass:"m-auto",attrs:{"name":"mdi.dots-vertical"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }