/** @format */

import { http } from "@/http";

export const deleteStudyLabel = async (
	study: string,
	label: string,
): Promise<void> => {
	await http.delete(`studies/${study}/labels/${label}`);
};
