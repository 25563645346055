import { defineComponent, computed } from "@vue/composition-api";
import { DropdownInput } from "@ui";
import { useTranslate } from "@lang";

const modes = [
	{ value:"AuthIS", text:"innskraning.island.is" }
]

enum Translations {
	NoAuthSet = "auth.label.noAuthSet",
}

export const SelectAuthMode = defineComponent({
	emits:[ "input" ],
	props:{
		value:{ type:String, }
	},
	setup(props, ctx){

		const { translate } = useTranslate();
		
		const val = computed({
			get:() => props.value,
			set:v => ctx.emit("input", v),
		});

		const emptyOption = computed(() => ({
			value:undefined,
			text:translate(Translations.NoAuthSet),
		}));

		const options = computed(() => {
			return [
				emptyOption.value,
				...modes,
			];
		});

		return { options, val, };
	},
	render(){
		return (
			<DropdownInput
			v-model={ this.val }
			options={ this.options }
			size="md"
			/>
		)
	}
});