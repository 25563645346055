import { IParams, IGet, IDelete, IPost } from "./interfaces";
import { Delete, Get, Post, } from "./handlers";

export class HTTPEndpoint {

	static get<PT extends IParams,RT>(path:string):IGet<PT, RT> {
		return new Get(path);
	}

	static delete<PT extends IParams>(path:string):IDelete<PT> {
		return new Delete(path);
	}

	static post<PT, BT, RT>(path:string):IPost<PT,BT,RT> {
		return new Post(path);
	}

}