import { defineComponent, provide, computed } from "@vue/composition-api";
import { Route } from "vue-router";

export const ProvideRouteLang = defineComponent({

	render(){
		return (
			<span>
				{this.$slots.default}
			</span>
		)
	},
	setup(_, ctx){

		const route = computed<Route>(() => ctx.root.$route)

		provide("lang-resolver", {
			getLanguage(){
				const l = route.value.query["l"];
				return Array.isArray(l) ? l[0] : l;
			}
		});

		return {};
		
	},
})