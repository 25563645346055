





















// vendor
import { defineComponent, computed, ref } from "@vue/composition-api";

const colors:any = {
	"warning":"dark",
	"light":"dark",
};

export default defineComponent({
	props:{
		value:{
			type:Number,
			default:0,
		},
		variant:{
			type:String,
			default:"secondary",
		},
	},
	setup(props){
		const label = computed(() => {
			return `${Math.floor(props.value)}%`;
		});

		const textVariant = computed(() => {
			return colors[props.variant] || "light";
		});

		return {
			label,
			textVariant,
		};
	}
});

