// import * as Config from "../config";
import { Port as PortSettings } from "../config/Port";
import { EventBus } from "../utils/EventBus";
import { setDOMAttributes } from "../utils/DOM";
import { getDeepObjectValue } from "../utils/Object";
import { Dictionary } from "../utils/Dictionary";
import * as SnapSVG from "snapsvg";
import { Node } from "./Node";
import { PortDefinition } from "../types/NodeTemplate";

export class Port {

	public getGlobalX(){ return this._x + this._node.getX(); }
	public getGlobalY(){ return this._y + this._node.getY(); }

	public getNodeID(){ return this._node.getID(); }
	public getPath(){ return this._node.getID() + "." + this._portKey; }
	public getKey(){ return this._portKey; }
	public getType(){ return this._type; }
	public getNodeType(){ return this._node.getTemplate().name; }

	public on<N extends keyof PortEvents>(name:N, fn:PortEvents[N]){
		this._events.on(name, fn);
	}

	public off<N extends keyof PortEvents>(name:N, fn:PortEvents[N]){
		this._events.off(name, fn);
	}

	constructor(rootElement:SnapSVG.Paper, node:Node, portKey:string, config:PortDefinition){
		this._node = node;
		this._portKey = portKey;
		this._type = config.type;
		this._x = config.x || 0;
		this._y = config.y || 0;
		
		this._rootElement = rootElement;
		this._element = this._rootElement.group();
		this._element.attr({
			transform: "t" + [ this._x, this._y ]
		});

		this._background = this._element.circle(0,0, 1);
		this._collider = this._element.circle(0,0,1);
		(this._background.node.style as any)["pointer-events"] = "none";
		this._background.attr(getDeepObjectValue(PortSettings, "background.shape.attributes", {}));
		this._collider.attr(getDeepObjectValue(PortSettings, "background.collider.attributes", {}));

		if(config.label){
			let lx = getDeepObjectValue(config, "label.x", 0);
			let ly = getDeepObjectValue(config, "label.x", 0);
			let label = this._element.text(lx, ly, config.label.text || "");
			setStyle(label, getDeepObjectValue(PortSettings, "label.text.style", {}));
			setStyle(label, config.label.style || {});
			setDOMAttributes(label.node, getDeepObjectValue(PortSettings, "label.text.attributes", {}));
			setDOMAttributes(label.node, config.label.attributes || {});
		}

		this._collider.mousedown(this.onMousedown.bind(this));
		this._collider.mouseup(this.onMouseup.bind(this));
		this._collider.hover(this.onHover.bind(this), this.onUnhover.bind(this));

		node.on("move", this.emitMoved.bind(this));
	}

	private _type:string;
	private _portKey:string;
	private _node:Node;
	private _rootElement:SnapSVG.Paper;
	private _element:SnapSVG.Paper|null = null;
	private _x:number = 0;
	private _y:number = 0;
	private _background:SnapSVG.Element|null = null;
	private _collider:SnapSVG.Element|null = null;
	private _events = new EventBus<PortEvents>();

	private emitMoved(){
		this._events.emit("nodeMoved");
	}

	private onMousedown = (e:any) => {
		if(e.button !== 0){ return; }
		e.stopPropagation();
		this._events.emit("press", e)
	};

	private onMouseup = (e:any) => {
		if(e.button !== 0){ return; }
		e.stopPropagation();
		this._events.emit("release");
	};

	private onHover(e:any){
		this._events.emit("hover");
	}

	private onUnhover(e:any){
		this._events.emit("unhover");
	}
}

const setStyle = (el:SnapSVG.Element, ob:Dictionary<any>) => {
	Object.keys(ob).forEach(k => (el.node.style as any)[k] = ob[k]);
};

type EventHandler = () => void;
interface PortEvents {
	"press":EventHandler,
	"release":EventHandler,
	"hover":EventHandler,
	"unhover":EventHandler,
	"nodeMoved":EventHandler,
}