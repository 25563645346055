import * as AssetAPI from "@/services/api/assets";
import { parseAssetRef } from "./parseAssetRef";

export const checkNodeRefs = async (node:any) => {
	const resolver = resolvers[node.type];
	if(!resolver){ return { valid:true, }; }
	return await resolver(node);
}

type RefInfo = {
	missing?:boolean;
	valid?:boolean;
}

type Resolver = (node:any) => Promise<RefInfo>

const resolveFromAssetRef = async (node:any) => {
	const [ asset ] = (parseAssetRef(node.parameters["asset"]) || "").split("@");
	if(!asset){ return { } }
	try {
		await AssetAPI.getAsset(asset);
		return { valid:true };
	}
	catch{}
	return { missing:true, }
};

const resolvers:Record<string, Resolver> = {
	"Form":resolveFromAssetRef,
	"Gallery":resolveFromAssetRef,
};