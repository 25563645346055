
export const CountStudySessions = {
	name: "CountStudySessions",
	description: {
		en:"Count sessions that match a filter.",
		is:"Telur keyrslur sem tilheyra síu."
	},
	parameters: {
		filter:{
			type:"object",
			title:{
				en:"Filter",
				is:"Sía",
			},
			drawer:{
				type:"session-filter"
			},
			default:{}
		}
	},
	output:{
		n:{
			type:"number"
		}
	},
};