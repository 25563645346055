/** @format */

/// types
export enum FormItemType {
	Answer = "Answer",
	Checkboxes = "Checkboxes",
	LinearScale = "LinearScale",
	MultipleChoice = "MultipleChoice",
	Dropdown = "Dropdown",
	HTML = "HTML",
	Information = "Information",
	Image = "Image",
	Video = "Video",
};

// export type ItemType = "Answer"|"Checkboxes"|"LinearScale"|"MultipleChoice"|"Dropdown"|"HTML"|"Information"|"Video"|"Image"