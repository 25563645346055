




























































// vendor
import {
	defineComponent,
	PropType,
	computed,
	ref,
} from "@vue/composition-api";
// local
import { Checkboxes } from "../../ts/types/DisplayForm";
import DockedFoldout from "../shared/docked-foldout.vue";
import ErrorOverlay from "../shared/error-overlay.vue";

export default defineComponent({
	props: {
		value: {
			type: Array as PropType<number[]>,
		},
		showBack: {
			type: Boolean,
			default: true,
		},
		config: {
			type: Object as PropType<Checkboxes>,
			required: true,
		},
	},
	components: {
		DockedFoldout,
		ErrorOverlay,
	},
	setup(props, context) {

		const error = ref(false);

		const previous = () => context.emit("previous");

		const next = () => {
			if(!canContinue.value){
				error.value = true;
				return;
			}
			context.emit("next");
		};

		const val = computed({
			get: () => props.value,
			set: (v) => context.emit("input", v),
		});

		const isValid = () => {
			return val.value && val.value.length > 0;
		};

		const getChannels = () => {
			return props.config.options.map((option, index) => {
				const toggled =
					(val.value || []).findIndex((v) => v === index) > -1;
				return {
					key: `${props.config.key}/${index}`,
					data: toggled ? 1 : 0,
				};
			});
		};

		const toggleIndex = (index: number, v: boolean) => {
			error.value = false;
			let arr = val.value ? val.value : [];
			const i = arr.findIndex((v) => v === index);
			if (i < 0) {
				arr.push(index);
			} else {
				arr.splice(i, 1);
			}
			val.value = arr;
		};

		const isToggled = (index: number) => {
			return val.value
				? val.value.findIndex((v) => v === index) > -1
				: false;
		};

		const canContinue = computed(() =>
			props.config.optional ? true : isValid(),
		);

		return {
			error,
			previous,
			next,
			val,
			isValid,
			getChannels,
			toggleIndex,
			isToggled,
			canContinue,
		};
	},
});
