






















// vendor
import { defineComponent, computed, ref, PropType } from "@vue/composition-api";

export default defineComponent({

	emits:[],
	props:{
		label:{
			type:String,
			default:"Sources",
		},
		sources:{
			type:Array as PropType<string[]>,
			default:() => [],
		}
	},
	components:{
	},
	setup(){
		
		

		return {

		};
	}
});

