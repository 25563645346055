// vendor
import * as THREE from "three";
// local
import { getInverseSphere } from "./getInverseSphere";

const TIMEOUT_SECONDS = 30;

type Result = {
	video:HTMLVideoElement;
	videoRoot:HTMLElement;
	videoSources:HTMLSourceElement[];
	sphere:THREE.Object3D;
};

const sphereGeometry = getInverseSphere();

export const createVideoSphere = (sources:string[], el:HTMLElement, start:number):Promise<Result> => {

	const root = document.createElement("div");

	const videoSources:HTMLSourceElement[] = [];

	root.style.cssText = `
		position:absolute;
		pointer-events:none;
		width:1px;
		height:1px;
		opacity:0;
	`;

	const video = document.createElement("video");

	video.setAttribute("muted", "true");
	// video.setAttribute("autoplay", "true");

	const vidAttr:any = {
		muted:"true",
		// autoplay:"true",
	};

	Object.keys(vidAttr).forEach(k => video.setAttribute(k, vidAttr[k]));
	
	const key = Date.now();

	sources.forEach((src, i) => {
		const stag = document.createElement("source");
		// console.log(`${src.trim()}?k=${key}-${i}`);
		// stag.src = `${src.trim()}?t=${key}-${i}`;
		stag.src = `${src.trim()}`;
		// stag.src = src;
		video.append(stag);
		videoSources.push(stag);
	});

	root.appendChild(video);

	const vidConfig:any = {
		muted:true,
		playsInline:true,
		crossOrigin:"anonymous",
		loop:true,
		// currentTime:start,
		// autoplay:true,
	};

	Object.keys(vidConfig).forEach(k => (video as any)[k] = vidConfig[k]);

	const tex = new THREE.VideoTexture(video);
	// tex.offset.x = 10;
	const geometry = sphereGeometry;
	const material = new THREE.MeshBasicMaterial( { map: tex } );
	const sphere = new THREE.Mesh(geometry, material);
	

	let failed = false;
	let played = false;

	return new Promise(async (resolve, reject) => {
		video.onloadeddata = () => {
			video.play();
			video.onseeked = () => {
				if(failed){ return; }
				played = true;
				resolve({
					video,
					videoRoot:root,
					sphere,
					videoSources,
				});
			};
			video.currentTime = start;
		};
		video.onerror = reject;

		el.appendChild(root);

		video.load();
		
		// video.onseeked = () => {
		// };
		
		// video.onplaying = () => {
		// 	if(failed){ return; }
		// 	played = true;
		// 	resolve({
		// 		video,
		// 		videoRoot:root,
		// 		sphere,
		// 		videoSources,
		// 	});
		// }
		

		
		// resolve({
		// 	video,
		// 	videoRoot:root,
		// 	sphere,
		// 	videoSources,
		// });

		// video.currentTime = start;

		setTimeout(() => {
			if(played){ return; }
			failed = true;
			reject();
		}, 1000 * TIMEOUT_SECONDS);
		

		
	});
};
