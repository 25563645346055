






import { defineComponent } from "@vue/composition-api";
import { RenderAnswer } from "@form-ui";
import { Answer } from "@psychlab/types/form"
import { getItemProps } from "./getItemProps";

export default defineComponent({
	props:{
		...getItemProps<Answer>()
	},
	components:{
		RenderAnswer,
	},
});
