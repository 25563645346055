import { defineComponent, computed, PropType } from "@vue/composition-api";
import * as Types from "./node-types";
import { getProcessor } from "@/services/api/AssetDataProcessors";

type NodeData<T>= {
	x:number,
	y:number,
	type:string,
	parameters:T
};

export const RunNode = defineComponent({

	emits:[ "next", "previous" ],
	props:{
		showPrevious:{
			type:Boolean,
			default:false
		},
		nodeId:{
			type:String,
			required:true
		},
		node:{
			type:Object as PropType<NodeData<any>>,
			required:true
		},
		isLast:{
			type:Boolean,
			default:false
		},
	},
	setup(props, context){

		const p = getProcessor(props.node.type as any);

		if(p && p.onBeforeUse){ p.onBeforeUse(props.node.parameters); };

		const component = computed(() => (Types as any)[props.node.type] || Types.Fallback);

		return {
			component,
			onEnd: () => context.emit("next"),
			onPrevious: () => context.emit("previous"),
		};
	},
	render(){

		const Component = this.component;

		return (
			<Component
			nodeId={this.nodeId}
			node={this.node}
			showBack={this.showPrevious}
			isLast={this.isLast}
			v-on:previous={this.onPrevious}
			v-on:end={this.onEnd}
			/>
		);
	}
});