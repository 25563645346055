var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CreateTaskModal',{ref:"createTaskModal"}),_c('TaskFilesModal',{ref:"taskFilesModal"}),_c('div',{staticClass:"bg-dark",class:{ loading: _vm.loading }},[_c('div',{staticClass:"bg-dark d-flex"},[_c('div',{staticClass:"flex-fill d-flex"},[_c('b-button',{staticClass:"noselect rounded-0 border-left-0 border-top-0 border-bottom-0 border-right border-secondary noround-bl noround-br noround-tr",attrs:{"variant":"outline-light","size":"md"},on:{"click":_vm.refresh}},[_c('icon',{staticStyle:{"font-size":"0.9edm"},attrs:{"name":"mdi.refresh"}})],1)],1),_c('div',{staticClass:"mx-auto"}),_c('b-button',{staticClass:"text-lightx p-0 rounded-x0 shadow-none ml-auto px-2 d-flex noround-br noround-tl noround-bl border-bottom-0 border-top-0 border-right-0 border-left border-secondary",attrs:{"variant":"outline-light","size":"md"},on:{"click":_vm.addTask}},[_c('span',{staticClass:"m-auto"},[_c('icon',{attrs:{"name":"mdi.plus"}}),_vm._v(" "+_vm._s(_vm.translate("studies.label.task"))+" ")],1)])],1),_c('hr',{staticClass:"bg-secondary m-0"}),_c('div',[_c('b-table',{staticClass:"rounded outline-danger m-0",attrs:{"fields":_vm.tableFields,"items":_vm.displayTasks,"table-variant":"dark","smallx":"","borderless":"","show-empty":"","empty-text":"","tbody-tr-class":"pointer","sort-desc":"","sort-by":"lastUpdated","hover":"","no-sort-reset":"","thead-class":"border-bottom border-secondary"},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"d-flex"},[_c('small',{staticClass:"m-auto"},[_vm._v(" "+_vm._s(_vm.translate("studies.message.noTasks"))+" ")])])]},proxy:true},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('icon',{class:'text-' + _vm.getStatusTheme(item.status),staticStyle:{"font-size":"1.2em"},attrs:{"name":_vm.getStatusIcon(item.status)}})]}},_vm._l(([ 'status', 'type', 'created', 'updated', 'duration' ]),function(prop){return {key:("head(" + prop + ")"),fn:function(ref){
var label = ref.label;
return [_c('span',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(_vm.translate(("label." + prop)))+" "),_c('icon',{staticClass:"sort-asc my-auto ml-2",attrs:{"name":"mdi.sort-ascending"}}),_c('icon',{staticClass:"sort-desc my-auto ml-2",attrs:{"name":"mdi.sort-descending"}}),_c('icon',{staticClass:"sort-none my-auto ml-2",attrs:{"name":"mdi.sort"}})],1)]}}}),_vm._l(([ 'created', 'updated' ]),function(prop){return {key:("cell(" + prop + ")"),fn:function(ref){
var item = ref.item;
return [_c('small',{staticClass:"d-flex"},[_c('DisplayDate',{attrs:{"date":item[prop],"short":""}})],1)]}}}),{key:"cell(duration)",fn:function(ref){
var item = ref.item;
return [_c('small',[_c('Duration',{attrs:{"ms":item.duration}})],1)]}},{key:"head(files)",fn:function(){return [_vm._v(" "+_vm._s(_vm.translate("label.results"))+" ")]},proxy:true},{key:"cell(files)",fn:function(ref){
var item = ref.item;
return [(item.output && item.output.files && item.output.files.length > 0)?_c('b-button',{staticClass:"d-flex rounded-lg shadow-none",attrs:{"size":"sm","variant":"light"},on:{"click":function($event){return _vm.viewFiles(item)}}},[_c('span',{staticClass:"m-auto"},[_c('span',{staticClass:"font-nats"},[_vm._v(" "+_vm._s(_vm.translate("action.open"))+" ")]),_c('span',{staticClass:"badge badge-pill badge-primary"},[_vm._v(" "+_vm._s(item.output.files.length)+" ")])])]):_vm._e()]}},{key:"cell(controls)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{staticClass:"p-0 d-flex rounded-circle shadow-none ml-2 border-0",staticStyle:{"width":"2em","height":"2em"},attrs:{"size":"sm","variant":"outline-light"},on:{"click":function($event){return _vm.openContext($event, item)}}},[_c('icon',{staticClass:"m-auto",staticStyle:{"font-size":"1em"},attrs:{"name":"mdi.dots-vertical"}})],1)]}}],null,true)})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }