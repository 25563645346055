import { useTranslate } from "@lang";
import { AbsBox, Icon, CompanyLogo } from "@ui";
import { defineComponent } from "@vue/composition-api";
import { AuthIcelandLink } from "./AuthIcelandLink";

enum Translations {
	AuthenticationRequired = "studies.message.authenticationRequired",
	PressButton = "studies.message.pressButtonToAuthenticate",
	PleaseAuthenticate = "studies.message.pleaseAuthenticateSurvey",
	AboutIcelandAuth = "studies.message.aboutIcelandAuth",
	SurveyOpenableOnce = "studies.message.surveyCanBeOpenedOnce",
	Attention = "studies.label.attention",
}

export const StudyAuth = defineComponent({
	props:{
		study:{ type:String, required:true, },
		mode:{ type:String, required:true, },
	},
	render(){
		if(this.mode === "AuthIS" || this.mode === "island.is"){
			return <AuthIcelandScreen study={this.study}/>;
		}
		return <div/>;
	}
});

export const AuthIcelandScreen = defineComponent({
	props:{
		study:{ type:String, required:true, },
	},
	setup(){
		const { translate } = useTranslate();
		return { translate };
	},
	render(){
		

		const about = highlightKeywords(this.translate(Translations.AboutIcelandAuth), ["island.is"]);

		return (
			<AbsBox>
				<Background/>

				{/* <AbsBox class="d-flex p-3">
					<CompanyLogo
					style="width:4.5em"
					class="ml-auto mb-auto"
					/>
				</AbsBox> */}

				<AbsBox class="bg-lighxt d-flex" style="overflow:auto">
					<div class="container m-auto d-flex flex-column text-light">

						<InfoIcon icon="account"/>
						<InfoTitle text={ this.translate(Translations.PleaseAuthenticate) }/>

						<VSpace size={3}/>

						<InfoBox>
							<InfoParagraph text={ about }/>
							<InfoParagraph text={ this.translate(Translations.SurveyOpenableOnce) }
							class="mb-0"
							/>
						</InfoBox>

						<VSpace size={3}/>

						<InfoSubtitle text={ this.translate(Translations.PressButton) }/>

						<VSpace size={2}/>

						<VSpace size={2}/>
						<AuthIcelandLink
						study={ this.study }
						class="mx-auto"
						/>
					</div>
				</AbsBox>
			</AbsBox>
		)
	},
});

const VSpace = defineComponent({
	props:[ "size" ],
	template:`<div :class="'my-'+size"/>`
});

const highlightStyle = `
	font-weight:bold;
	padding:0 0.25em;
	background:rgba(0,0,0,0.1);
	border-radius:0.25em;
`;

const highlightKeywords = (v:string, words:string[]) => {
	const highlight = (v:string) => `<strong style="${highlightStyle}">${v}</strong>`;
	words.forEach(w => v = v.replaceAll(w, highlight(w)));
	return v;
};


const InfoBox = defineComponent({
	render(){
		return (
			<div class="p-3 border rounded-dlg"
			style="border-radius:0.5em;background:rgba(255,255,255,0.05);border-color:rgba(255,255,255,0.1) !important"
			>
				{ this.$slots.default }
			</div>
		);
	}
});


const InfoIcon = defineComponent({
	props:[ "icon" ],
	render(){
		return (
			<h1 class="font-weight-light text-center">
				<Icon name={this.icon} style="font-size:1.5em"/>
			</h1>
		)
	}
});

const InfoTitle = defineComponent({
	props:[ "text" ],
	render(){
		return (
			<h2
			style="letter-spacing:0.25em;"
			class="text-center font-weight-bold">{this.text}</h2>
		);
	}
});

const InfoParagraph = defineComponent({
	props:[ "text" ],
	render(){
		return (
			<p
			style="letter-spacing:0.15em;font-size:1.2em;"
			domPropsInnerHTML={this.text}
			class="text-center"></p>
		);
	}
});


const InfoSubtitle = defineComponent({
	props:[ "text" ],
	render(){
		return (
			<h4
			style="letter-spacing:0.2em;"
			class="text-center font-weight-light">{this.text}</h4>
		);
	}
});


const Background = defineComponent({
	render(){
		return <AbsBox style={gradientcss}/>;
	}
});


const gradientcss = `
background-image: linear-gradient( 96.5deg,  rgba(39,103,187,1) 10.4%, rgba(16,72,144,1) 87.7% );

`