

const HANDLER = "_vue_clickaway_handler";

const unbind = (el) => {
	document.documentElement.removeEventListener("click", el[HANDLER], false);
	delete el[HANDLER];
}


const bind = (el, binding, vnode) => {
	unbind(el);

	const vm = vnode.context;

	const callback = binding.value;
	if (typeof callback !== 'function') {
		return;
	}

	let initialMacrotaskEnded = false;
	setTimeout(function() {
		initialMacrotaskEnded = true;
	}, 0);

	el[HANDLER] = function(ev) {
		const path = ev.path || (ev.composedPath ? ev.composedPath() : undefined);
		if (initialMacrotaskEnded && (path ? path.indexOf(el) < 0 : !el.contains(ev.target))) {
		return callback.call(vm, ev);
	}
	};
	document.documentElement.addEventListener("click", el[HANDLER], false);
}


export const directive = {
	bind:bind,
	unbind:unbind,
	update(el, binding){
		if (binding.value === binding.oldValue){
			return
		};
		bind(el, binding);
	},
	
};

export const mixin = {
	directives: {
		onClickaway: directive
	}
};